import {Component, HostListener, OnInit} from '@angular/core';
import {ThemeOptions} from '../../../theme-options';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;

  constructor(public globals: ThemeOptions, private route: Router, private activatedRoute: ActivatedRoute) {

  }

  showDropdown: boolean = false;
  showDropdowncontrolpannel: boolean = false;
  showConsignmentTypecontrolpannel: boolean = false;
  showDropdownAirway: boolean = false;
  showDropdownLocations: boolean = false;
  showDropdownInvoicing: boolean = false;
  showDropdownFileSection: boolean = false;

  @select('config') public config$: Observable<any>;

  public config: PerfectScrollbarConfigInterface = {};
  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboards';

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }
  toggleDropdowncontrolpannel() {
    this.showDropdowncontrolpannel = !this.showDropdowncontrolpannel;
  }
  toggleDropdownConsignmentTypes() {
    this.showConsignmentTypecontrolpannel = !this.showConsignmentTypecontrolpannel;
  }
  toggleDropdownAirway() {
    this.showDropdownAirway = !this.showDropdownAirway;
  }
  toggleDropdownLocations() {
    this.showDropdownLocations = !this.showDropdownLocations;
  }
  toggleDropdownInvoicing() {
    this.showDropdownInvoicing = !this.showDropdownInvoicing;
  }
  toggleDropdownFileSection() {
    this.showDropdownFileSection = !this.showDropdownFileSection;
  }

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
    this.globals.sidebarHover = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  sidebarHoverMouseOut() {
    this.globals.sidebarHover = false;
  }

  sidebarHoverMouseIn() {
    this.globals.sidebarHover = true;
  }

  username;
  role;
  async ngOnInit() {
    const user_details :any=JSON.parse( localStorage.getItem('userDetails'));
    this.username= user_details.first_name + ' ' + user_details.last_name;
    this.role = user_details.userTypes[0].name;
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.extraParameter = this.activatedRoute.snapshot.firstChild.routeConfig.path;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }
  }

//  async refreshPage(){
//     await this.route.navigate(['/consignment'])
//     window.location.reload();
//   }
}
