import {Component, OnInit} from '@angular/core';
import {API_URL} from '../../../../../Global_API_URL';
import Swal from 'sweetalert2';
import {getConsignmentSubType, getConsignmentType} from '../../getConsignmentType.js';
import {AuthService} from '../../../auth.service';

@Component({
  selector: 'app-consignment-subtype',
  templateUrl: './consignment-subtype.component.html',
  styleUrls: ['./consignment-subtype.component.sass'],
})
export class ConsignmentSubtypeComponent implements OnInit {
  constructor(private authService : AuthService) {
    this.authService.getAuthToken();
  }
  loading = false;
  pageview = 'table';
  consignment_sub_types = getConsignmentSubType();
  consignment_types = getConsignmentType();
  ngOnInit(): void {
  }
  viewchange(val) {
    this.pageview = val;
  }
  new_subtype = {
    name: null,
    description: null,
    consignmnet_type_id: null,
  }

  async createOffice() {
    const TOKEN = this.authService.getAuthToken();
    if (this.new_subtype.name == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter the SubType name',
        showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else if (this.new_subtype.description == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter the SubType Description',
        showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else if (this.new_subtype.consignmnet_type_id == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please Select the Conisgnment Type',
        showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else {
      await fetch(API_URL + '/consignmnet_sub_types',
          {
            method: 'POST',
            body: JSON.stringify(this.new_subtype),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+TOKEN,
            },
          })
          .then((response) => response.json())
          .then((result) => {
          // console.log(result);
            this.new_subtype = {
              name: null,
              description: null,
              consignmnet_type_id: null,
            };
            this.consignment_sub_types= getConsignmentSubType();
            this.viewchange('table');
          })
          .catch((error) => {
            console.error('Error', error);
            alert(error);
          });
    }
  }
}
