import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import Swal from "sweetalert2";
import {HttpService} from "../../../services/http.service";
import {CustomerVerificationService} from "../../../shared/customer-verification/customer-verification.service";
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from "ngx-loading";
import {API_URL} from "../../../../../Global_API_URL";
import {ActivatedRoute} from "@angular/router";
import {getCitiesById, getPincodesById, getStatesById} from "../../getCities.js";

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';
@Component({
  selector: 'app-representative-edit',
  templateUrl: './representative-edit.component.html',
  styleUrls: ['./representative-edit.component.sass']
})
export class RepresentativeEditComponent implements OnInit {

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  representativeId;

  constructor(private httpService: HttpService, public customerVerification: CustomerVerificationService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(params => {
      this.representativeId = params['id'];
    })
  }

  ngOnInit(): void {
    this.getRepresentativeById();
    this.customerVerification.getCompaniesList();
    setTimeout(() => {
      this.loading = false; // Set loading to false after 2 seconds
    }, 2000);
  }

userEmail;

  customer_shipper = {
    id: null,
    firstName: '',
    middleName: '',
    lastName: '',
    avatarUrl: '',
    email: '',
    phone: '',
    whatsapp: '',
    designation: null,
    billingAddress: {
      pincode: {
        pin_number: ''
      },
      address: ''
    },
    employmentNo: null,
    isSuperUser: null,
    userName: '',
    passwordHash: null,
    lastLoggedInAt: null,
    lastLoggedInIp: null,
    orgCustomerId: {id: null},
    aadhaarNumber: null,
    drivingLicense: null,
    panNumber: null,
    isActive: true,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
  }

  getRepresentativeById(){
    this.httpService.get(API_URL + "/representativeUser/" + this.representativeId, null,null)
      .subscribe(response => {
        this.customer_shipper = response.data[0];

        if(this.customer_shipper.billingAddress==null) this.customer_shipper.billingAddress = {
          pincode: {
            pin_number: ''
          },
          address: ''
        };

        this.userEmail = this.customer_shipper.email;

        this.customerVerification.getPincode(this.customer_shipper.billingAddress?.pincode?.pin_number)
          .subscribe(response =>{
            this.customerVerification.pincodeData = response.pincodeData
            this.customerVerification.user_sel_country = this.customerVerification.pincodeData.city.state_id.country.id;
            this.customerVerification.user_sel_state = this.customerVerification.pincodeData.city.state_id.id;
            this.customerVerification.user_sel_city = this.customerVerification.pincodeData.city.id;
            this.customer_shipper.billingAddress.pincode.pin_number = this.customerVerification.pincodeData.pin_number;
            this.customerVerification.user_states = getStatesById(this.customerVerification.user_sel_country);
            this.customerVerification.user_cities = getCitiesById(this.customerVerification.user_sel_state);
            this.customerVerification.user_pincodes = getPincodesById(this.customerVerification.user_sel_city);
          });
      })
  }

  emailExistAppuser(){
    if (this.userEmail != this.customer_shipper.email) {
      this.customerVerification.emailExistAppuser(this.customer_shipper.email);
    } else{
      this.customerVerification.emailExist = false;
    }
  }


  editRepresentative() {
    if (this.customer_shipper.orgCustomerId.id == null || this.customer_shipper.orgCustomerId.id == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Company Name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.firstName == null || this.customer_shipper.firstName == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The First Name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.firstName.length != this.customer_shipper.firstName.trim().length || this.customer_shipper.firstName.trim().length == 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Remove the spaces at beginning or end of the first name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.lastName == null || this.customer_shipper.lastName == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Last Name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.lastName.length != this.customer_shipper.lastName.trim().length || this.customer_shipper.lastName.trim().length == 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Remove the spaces at beginning or end of the last name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.email == null || this.customer_shipper.email == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Email',
        timer: 5000,
      });
      return;
    } else if (!(this.customerVerification.emailregex.test(this.customer_shipper.email))) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter A Valid Email',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.emailExist) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Email Already Exists',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.phone == null || this.customer_shipper.phone == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Phone No.',
        timer: 5000,
      });
      return;
    } else if ((this.customer_shipper.phone).length < 10 || isNaN(parseInt(this.customer_shipper.phone)) == true) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Phone No.',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.whatsapp.length > 0 && this.customer_shipper.whatsapp.length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Whatsapp No.',
      });
    } else if (this.customer_shipper.billingAddress.address == '' || this.customer_shipper.billingAddress.address == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Address',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_country == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Country',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_state == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The State',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_city == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The City',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.billingAddress.pincode.pin_number == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Zip Code',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper?.aadhaarNumber?.length > 0 && this.customer_shipper?.aadhaarNumber?.length < 12) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Aadhaar No. must be 12 digits',
        timer: 5000,
      });
    } else if (this.customer_shipper?.panNumber?.length > 0 && !this.customerVerification.panregex.test(this.customer_shipper.panNumber)) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter Valid PAN No. ',
        timer: 5000,
      });
    } else {
      this.loading = true;
      this.httpService.patch(API_URL + '/representativeUser/' + this.representativeId, this.customer_shipper, null, null)
        .subscribe(response => {
          this.loading = false;
          Swal.fire({
            icon: 'success',
            title: 'Representative Updated',
            timer: 5000,
          }).then(() => {
            this.goBackToRepresentativeTable();
          });
        }, error => {
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: 'Failed to update representative',
            timer: 5000,
          });
        })
    }
  }


  goBackToRepresentativeTable() {
    history.back();
  }

  transformPANoUpperCaseForCreate(){
    this.customer_shipper.panNumber = this.customer_shipper.panNumber.toUpperCase();
  }


}
