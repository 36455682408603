import {Injectable} from '@angular/core';
import {API_URL} from "../../../../Global_API_URL";
import {HttpService} from "../../services/http.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Router} from "@angular/router";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(private httpService: HttpService, private router: Router, private http: HttpClient) { }

  paginatorSizeOptions = [5, 10, 20, 30];

  private paginatorDetails = {
    pageNo: 0,
    pageSize: this.paginatorSizeOptions[0],
    totalPages: 0,
    totalElements: 0,
  }

   private savePaginatorState: Boolean = false;

  getPaginatorDetails() {
    return this.paginatorDetails;
  }

  getInitialPaginatorDetails() {
    if(this.savePaginatorState==false) {
      return {
        pageNo: 0,
        pageSize: this.paginatorSizeOptions[0],
        totalPages: 0,
        totalElements: 0,
      }
    }
    this.savePaginatorState = false;
    return this.paginatorDetails;
  }
  getAllContractsAPI(pageNo, pageSize, sortBy, direction) {
    let params: HttpParams = new HttpParams();
    if (pageNo!=null) {
      params = params.set('pageNo', pageNo);
    }

    if (pageSize!=null) {
      params =params.set('pageSize', pageSize);
    }

    if (sortBy!=null) {
      params =params.set('sortBy', sortBy);
    }

    if (direction!=null) {
      params = params.set('direction', direction);
    }

    return this.httpService.get(API_URL +'/contracts', params, null);
  }

  postContracts(body) {
    return this.httpService.post(API_URL +'/contracts', body, null, null);
  }

  patchContracts(id, body) {
    return this.httpService.patch(API_URL +'/contracts'+id, body, null, null);
  }

  async getAllContracts(pageNo, pageSize, sortBy, direction) {
    try {
      let contractsTableDataSource;
      const response = await this.getAllContractsAPI(pageNo, pageSize, sortBy, direction).toPromise();


      contractsTableDataSource = response.data.content;
      this.paginatorDetails.pageNo = response.data.page.number;
      this.paginatorDetails.totalPages = response.data.page.totalPages;
      this.paginatorDetails.totalElements = response.data.page.totalElements;

      return contractsTableDataSource;
    } catch (error) {
      console.error('Error fetching contracts:', error);
      throw error;
    }
  }

  getCustomers(){
    return this.httpService.get(`${API_URL}/dropdown/customers`, null, null);
  }


  goToCreateNew() {
    this.savePaginatorState = true;
    this.router.navigate(['contracts','create'])
  }

  async getContractualPricesByContract(id: number, priceType: string, pageNo, pageSize, sortBy, direction, origin, destination) {
    try {
      let cityPricesDataSource;
      const response = await this.getContractualPricesByContractAPI(id, priceType, pageNo, pageSize, sortBy, direction, origin, destination).toPromise();

      cityPricesDataSource = response.data.content[0];
      this.paginatorDetails.pageNo = cityPricesDataSource.cityPrices.page.number;
      this.paginatorDetails.totalPages = cityPricesDataSource.cityPrices.page.totalPages;
      this.paginatorDetails.totalElements = cityPricesDataSource.cityPrices.page.totalElements;

      return cityPricesDataSource;
    } catch (error) {
      console.error('Error fetching city prices:', error);
      throw error;
    }
  }

  getContractualPricesByContractAPI(id, priceType, pageNo, pageSize, sortBy, direction, origin, destination) {
    let params: HttpParams = new HttpParams();

    if (pageNo!=null) {
      params = params.set('pageNo', pageNo);
    }

    if (pageSize!=null) {
      params =params.set('pageSize', pageSize);
    }

    if (sortBy!=null) {
      params =params.set('sortBy', sortBy);
    }

    if (direction!=null) {
      params = params.set('direction', direction);
    }

    if (origin!=null) {
      params =params.set('originId', origin);
    }

    if (destination!=null) {
      params = params.set('destinationId', destination);
    }
    const headers = new HttpHeaders({ 'X-priceType': priceType });
    return this.httpService.get(API_URL + '/contracts/' + id + '/prices', params, headers);
  }

  getContractualPricesByContractRegion(id: number, priceType: string): Observable<any> {
    const headers = new HttpHeaders({ 'X-priceType': priceType });
    return this.http.get<any>(`${API_URL}/contracts/${id}/prices`, { headers });
  }

}
