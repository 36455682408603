import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CSVFileFormatDTO } from '../../golbalInterfaces';
import { CsvFileFormatsService } from './csv-file-formats.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../../Components/confirmDialog/confirmDialog.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-csv-file-formats',
  templateUrl: './csv-file-formats.component.html',
  styleUrls: ['./csv-file-formats.component.sass']
})
export class CsvFileFormatsComponent implements OnInit {

  constructor(private router: Router, private CSVFileFormatService: CsvFileFormatsService, private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.getActiveCsvFileFormats();
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name === 'Customer') {
      this.displaytoCustomer = false;
    }
  }

  userDetails;
  displaytoCustomer = true;
  csvFileFormats: any[] = [];
  activeCSVFileFormats: any[] = [];
  newCSVFileFormat: CSVFileFormatDTO = new CSVFileFormatDTO();
  selectedFile: File | undefined;
  errorMessage: string = '';

  getAllCsvFileFormats(): void {
    this.CSVFileFormatService.getAllCsvFileFormats()
      .subscribe(
        (csvFileFormats) => {
          this.csvFileFormats = csvFileFormats;
        },
        (error) => {
          console.error('Error fetching CSV Files:', error);
          this.errorMessage = 'Failed to load CSV Files.';
        },
      );
  }

  getActiveCsvFileFormats(): void {
    this.CSVFileFormatService.getActiveCsvFileFormats()
      .subscribe(
        (activeCSVFileFormats) => {
          this.activeCSVFileFormats = activeCSVFileFormats;
        },
        (error) => {
          console.error('Error fetching active CSV Files:', error);
          this.errorMessage = 'Failed to load active CSV Files.';
        },
      );
  }

  createCsvFileFormat(): void {
    this.CSVFileFormatService.createCsvFileFormat(this.newCSVFileFormat, this.selectedFile!)
      .subscribe((response) => {
        this.getAllCsvFileFormats(); // Refresh data after creation
      });
  }

  deleteFile(id) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to delete?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deactivateCSVFileFormat(id);
      }
    });
  }

  deactivateCSVFileFormat(id: number): void {
    this.CSVFileFormatService.deactivateCSVFileFormat(id)
      .subscribe(() => {
        Swal.fire('Success', 'File Deleted Successfully', 'success');
        this.getActiveCsvFileFormats();
      }, (error) => {
        Swal.fire('Error', 'Failed to delete the file', 'error');
      });
  }

  downloadFileById(id: number): void {
    this.CSVFileFormatService.downloadFileById(id).subscribe(
      (response) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const matches = contentDisposition && contentDisposition.match(/filename="(.+)"/);
        const filename = matches ? matches[1] : 'file.csv';

        const blob = new Blob([response.body], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename; // Use the filename from the Content-Disposition header
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Error downloading CSV File:', error);
        this.errorMessage = 'Failed to download CSV File.';
      },
    );
  }

  showDescription(description: string): void {
    Swal.fire({
      title: 'Description',
      html: description,
      showCloseButton: true,
    });
  }

  isPdfFile(fileType: string): boolean {
    return fileType === 'application/pdf';
  }

  isDocxOrDocFile(fileType: string): boolean {
    return fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || // DOCX
      fileType === 'application/msword'; // DOC
  }

  isImageFile(fileType: string): boolean {
    return fileType.startsWith('image/');
  }

  isCsvFile(fileType: string): boolean {
    return fileType === 'text/csv' || fileType === 'application/vnd.ms-excel';
  }

  isOtherFile(fileType: string): boolean {
    return !this.isPdfFile(fileType) && !this.isDocxOrDocFile(fileType) && !this.isImageFile(fileType);
  }

  navigateToAddCSVFileFormat(): void {
    this.router.navigate(['/addCSVFileFormat']);
  }

}
