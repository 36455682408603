<div class="card">
  <div class="card-header">

    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
      <b style="color: blue;">CORPORATE CUSTOMERS</b>
    </div>
    <div class="btn-actions-pane-right p-2">
      <div class="mb-2 me-2 btn btn-primary" (click)="gotoCreateCustomerPage()">Add</div>
    </div>
  </div>

  <div class="row p-4">
    <div class="col-md-2 row">
      <mat-form-field appearance="standard">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="search2" (keyup)="searchByCons2(search2)" placeholder="Name" #input>
      </mat-form-field>
    </div>

    <div class="col-md-2 row">
      <mat-form-field appearance="standard">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="search" (keyup)="searchByCons(search)" placeholder="Email Id" #input>
      </mat-form-field>
    </div>
    <div class="col-md-2 row">
      <mat-form-field appearance="standard">
        <mat-label>Phone No.</mat-label>
        <input matInput [(ngModel)]="search1" (keyup)="searchByCons1(search1)" (keydown)="onKeyDown($event)"
               placeholder="Phone No." #input>
      </mat-form-field>
    </div>
    <div class="col-md-2 row">
      <mat-form-field appearance="standard">
        <mat-label>State</mat-label>
        <input matInput placeholder="Search State" [(ngModel)]="corporatefilters.stateid"
               [matAutocomplete]="autoState">
        <mat-autocomplete #autoState="matAutocomplete" [displayWith]="displayStateFn">
          <mat-option *ngFor="let state of states | filter: corporatefilters.stateid" [value]="state"
                      (click)="getCities(state.id)">{{ state.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-md-2 row">
      <mat-form-field appearance="standard">
        <mat-label>City</mat-label>
        <input matInput placeholder="Search City" [(ngModel)]="corporatefilters.cityid"
               [matAutocomplete]="autoCity">
        <mat-autocomplete #autoCity="matAutocomplete" [displayWith]="displayCityFn">
          <mat-option *ngFor="let city of (cities | async) | filter: corporatefilters.cityid"
                      [value]="city">{{ city.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="col-md-2 row" style="width: 153px; margin-top: 12.5px">
      <mat-form-field>
        <mat-label>Select</mat-label>
        <mat-select [(ngModel)]="corporatefilters.status" (selectionChange)="applyFilter()">
          <mat-option value="all">All</mat-option>
          <mat-option value="verified">Verified</mat-option>
          <mat-option value="notVerified">Not Verified</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-1" style="width: 6%">
      <button class="btn btn-primary mt-3" (click)="find()">Find</button>
    </div>
    <div class="col-md-1">
      <button class="btn btn-secondary mt-3" (click)="reset()">Reset</button>
    </div>
  </div>

  <div class="">
    <div *ngIf="!loading" style="overflow-x: auto">
      <ng-container *ngIf="dataSource?.data?.length > 0">
        <table class="w-100" mat-table [dataSource]="dataSource" matSort>
          <!-- Position Column -->
          <ng-container matColumnDef="companyName">
            <th class="text-center" mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by companyName" style="width: 16%;">Company Name
            </th>
            <td class="text-center" mat-cell *matCellDef="let element">{{ element?.firstName }}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th class="text-center" mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by email" style="width: 16%;">Email
            </th>
            <td class="text-center" mat-cell *matCellDef="let element">{{ element?.email_1 }}</td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th class="text-center" mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by phone" style="width: 16%;">Phone No.
            </th>
            <td class="text-center" mat-cell *matCellDef="let element">{{ element?.phone_1 }}</td>
          </ng-container>

          <ng-container matColumnDef="state">
            <th class="text-center" mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by state">State
            </th>
            <td class="text-center" mat-cell *matCellDef="let element">
              {{ element?.city_1?.state_id?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="city">
            <th class="text-center" mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by city">City
            </th>
            <td class="text-center" mat-cell *matCellDef="let element"> {{ element?.city_1?.name }}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th class="text-center" mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by status">Status
            </th>
            <td class="text-center" mat-cell *matCellDef="let element" [ngStyle]="element?.gstVerified ? {'color': 'green'} : {'color': 'red'}"> {{ element?.gstVerified ? "Verified" : "Not Verified" }}</td>
          </ng-container>

          <ng-container matColumnDef="Active">
            <th mat-header-cell *matHeaderCellDef> Action</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex">
                <div role="group" *ngIf="(element.is_active != false)" class="btn-group-sm btn-group m-1">
                  <button class="btn-shadow btn btn-primary" matTooltip="Edit"
                          (click)="gotoEditCustomerPage(element.id)"><i class="lnr-pencil"></i></button>
                </div>
                <div role="group" *ngIf="(element.is_active != false) && !displaytoAccountant"
                     class="btn-group-sm btn-group m-1">
                        <span class="material-icons btn-shadow btn btn-danger" matTooltip="Deactivate Customer"
                              (click)="DeleteCustomer(element)">person_remove</span>
                </div>
                <div role="group" *ngIf="(element.is_active == false) && !displaytoAccountant"
                     class="btn-group-sm btn-group m-1">
                        <span class="material-icons btn-shadow btn btn-success" matTooltip="Activate Customer"
                              (click)="ActivateCustomer(element)"> person_add_alt_1 </span>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              [ngClass]="row.is_active == false ? 'red':'white'"></tr>
        </table>
      </ng-container>
    </div>
    <mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
    <ngx-loading [show]="loading"
                 [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                 [template]="loadingTemplate"></ngx-loading>
  </div>

  <!-- <div class="container">
    <div class="row">
      <div class="">
        <div class="table-responsive" *ngIf="customerlist">
          <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
            <thead>
              <tr>
                <th class="text-center">User Id</th>
                <th class="text-center">Company Name</th>
                <th class="text-center">Email</th>
                <th class="text-center">Phone</th>
                <th class="text-center">State</th>
                <th class="text-center">City</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of customerlist.content">

                <td class="text-center">
                  {{user.id}}
                </td>
                <td class="text-center" style="max-width: 100px; overflow:hidden"  matTooltip="{{user.firstName}}">
                  {{user.firstName}}
                </td>
                <td class="text-center" style="max-width: 100px; overflow:hidden" matTooltip="{{user.email1}}">
                  {{user.email1}}
                </td>
                <td class="text-center">
                  {{user.phone1}}
                </td>
                <td class="text-center" style="max-width: 100px; overflow:hidden" matTooltip="{{user.address1}}">
                  {{user.city_1.state_id.name}}

                </td>
                <td class="text-center" style="max-width: 100px; overflow:hidden">
                  {{user.city_1.name}}
                </td>
                <td class="text-center">
                  <div role="group" class="btn-group-sm btn-group">
                    <button class="btn-shadow btn btn-primary" (click)="viewchange('edit',user)" matTooltip="Edit"><i
                        class="lnr-pencil"></i></button>

                    <button class="btn-shadow btn btn-primary" (click)="viewchange('edit')"><i class="lnr-pencil"></i></button>
                    <button class="btn-shadow btn btn-primary">Delete</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <mat-paginator (page)="handlePageEvent($event)" [length]="customerlist.totalElements"
            [pageSize]="customerlist.numberOfElements"
            [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
            [pageIndex]="customerlist.number" aria-label="Select page">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div> -->
</div>
