<div>
  <ngx-loading [show]="loading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>
<div class="dialogBody">
  <div class="modal-header">
    <h4 class="modal-title">Create Representative</h4>
    <button (click)="onNoClick()" aria-label="Close" class="btn-close"
            type="button">
    </button>
  </div>

  <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="standard" class="py-0">
        <mat-label>
          First Name<span class="text-danger">*</span>
        </mat-label>
        <input [(ngModel)]="customer_shipper.firstName" aria-label="Number" matInput placeholder="First Name"
               type="text">
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="standard" class="py-0">
        <mat-label>
          Middle Name
        </mat-label>
        <input [(ngModel)]="customer_shipper.middleName" aria-label="Number" matInput
               placeholder="Middle Name" type="text">
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="standard" class="py-0">
        <mat-label>
          Last Name<span class="text-danger">*</span>
        </mat-label>
        <input [(ngModel)]="customer_shipper.lastName" aria-label="Number" matInput placeholder="Last Name"
               type="text">
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>
          E-mail<span class="text-danger">*</span>
        </mat-label>
        <input (blur)="customerVerification.emailExistAppuser(customer_shipper.email)" [(ngModel)]="customer_shipper.email" [formControl]="customerVerification.emailFormControl" aria-label="Number"
               matInput placeholder="Email"
               type="text">
        <!-- <mat-hint class="text-danger fw-normal" *ngIf="emailFormControl.hasError('required')">Email is required</mat-hint> -->
        <mat-hint *ngIf="customerVerification.emailFormControl.hasError('pattern')" class="text-danger fw-normal">Invalid
          email address
        </mat-hint>
        <mat-hint *ngIf="customerVerification.emailExist" class="text-danger fw-bold">Email Exists</mat-hint>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>
          Phone No.<span class="text-danger">*</span>
        </mat-label>
        <input #phone
               [(ngModel)]="customer_shipper.phone" aria-label="Number"
               matInput maxlength="10" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
               placeholder="Phone No." type="text">
        <mat-hint align="end">{{ phone.value.length }}/10
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>
          Whatsapp No.
        </mat-label>
        <input #whatsappnum [(ngModel)]="customer_shipper.whatsapp"
               aria-label="Number" matInput
               maxlength="10" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
               placeholder="Whatsapp No." type="text">
        <mat-hint align="end">{{ whatsappnum.value.length }}/10</mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="standard" class="example-full-width">
        <mat-label>Address<span class="text-danger">*</span></mat-label>
        <textarea [(ngModel)]="customer_shipper.billingAddress.address" matInput
                  placeholder="Address"></textarea>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>Country<span class="text-danger">*</span></mat-label>
        <mat-select [(ngModel)]="customerVerification.user_sel_country"
                    matNativeControl>
          <mat-option selected>SELECT</mat-option>
          <mat-option (click)="customerVerification.getIndividaulStates(customer_shipper, 'representative')"
                      *ngFor="let con of customerVerification.countries |async"
                      [value]="con.id">
            {{ con.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>
          State<span class="text-danger">*</span>
        </mat-label>
        <mat-select [(ngModel)]="customerVerification.user_sel_state"
                    matNativeControl>
          <mat-option selected>SELECT</mat-option>
          <mat-option (click)="customerVerification.getIndividaulCities(customer_shipper, 'representative')"
                      *ngFor="let sta of customerVerification.user_states |async"
                      [value]="sta.id">
            {{ sta.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>
          City<span class="text-danger">*</span>
        </mat-label>
        <mat-select [(ngModel)]="customerVerification.user_sel_city"
                    matNativeControl>
          <mat-option selected>SELECT</mat-option>
          <mat-option (click)="customerVerification.getIndividaulPincodes(customer_shipper, 'representative')"
                      *ngFor="let cit of customerVerification.user_cities |async"
                      [value]="cit.id">
            {{ cit.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>
          Zip Code<span class="text-danger me-auto">*</span>
        </mat-label>
        <mat-select [(ngModel)]="customer_shipper.billingAddress.pincode.pin_number"
                    matNativeControl>
          <mat-option selected>SELECT</mat-option>
          <mat-option *ngFor="let cit of customerVerification.user_pincodes |async"
                      [value]="cit.pinNumber">
            {{ cit.pinNumber }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="standard" class="py-0">
        <mat-label>
          Aadhaar No.
        </mat-label>
        <input #aadhaarnum1 [(ngModel)]="customer_shipper.aadhaarNumber"
               aria-label="Number" matInput
               maxlength="12" onkeypress="return event.charCode >= 48 && event.charCode <= 57" placeholder="Aadhaar No."
               type="text">
        <mat-hint align="end">{{ aadhaarnum1.value.length }}/12</mat-hint>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="standard">
          <mat-label>
            Driving License
          </mat-label>
          <input [(ngModel)]="customer_shipper.drivingLicense" aria-label="text" matInput placeholder="Driving License" #drivingLicense
                 type="text" maxlength="16">
                 <mat-hint>{{drivingLicense.value.length}}/16</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="standard" class="py-0">
          <mat-label>
            PAN No.
          </mat-label>
          <input #pan2 [(ngModel)]="customer_shipper.panNumber" aria-label="Number" matInput placeholder="PAN No."
                 type="text" (input)="transformPANoUpperCaseForCreate()" maxlength="10">
          <mat-hint align="end">{{ pan2.value.length }}/10
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button (click)="onNoClick()" class="btn btn-secondary" type="button">Close
    </button>
    <div>
      <button (click)="createRepresentative()" class="btn btn-primary"
              type="button">Create
      </button>
    </div>
  </div>
</div>
