import {Component, OnInit} from '@angular/core';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';
import {ThemeOptions} from '../../../../../theme-options';
import {Router} from '@angular/router';
import {AuthService} from '../../../../../auth.service';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {
  faCalendar = faCalendar;
  username = '--'
  role = '--'
  customer = '--'
  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  constructor(public globals: ThemeOptions, private router: Router, private authService: AuthService) {
  }
  user_details: any;
  async ngOnInit() {
    const user_details: any = JSON.parse(localStorage.getItem('userDetails'));
    this.username = user_details.firstName + ' ' + user_details.lastName;
    this.customer = user_details?.org_customer_id?.firstName;
    this.role = user_details.userTypes[0].name;
    if (this.user_details?.customer_type == 2) {
      this.customer = user_details?.org_customer_id?.firstName;
    }
  }
  logout() {
    this.authService.logout();
  }

  // @HostListener('window:unload',['$event'])
  // onWindowUnload(event){
  //   this.logout();
  // }

  goToEditDetailsPage() {
    this.router.navigateByUrl('editdetails');
  }
  goToUpdatePasswordPage() {
    this.router.navigateByUrl('updatepassword');
  }
}
