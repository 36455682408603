<div class="main-card mb-3 card">
    <div class="card-header">
      <div class="card-header-title font-size-lg text-capitalize fw-normal">
        Consignments
      </div>
      <div class="btn-actions-pane-right p-2">
        <a [routerLink]="['/iclproducts']" class="mb-2 me-2 btn btn-primary">Add Consignment</a>
        <!-- <button type="button" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm m-1">
            Actions Menu
            <span class="ps-2 align-middle opactiy-7">
              <i class="fa fa-angle-down"></i>
            </span>
          </button> -->
      </div>
    </div>
    <div class="table-responsive" *ngIf="consignmentlists!= null">
      <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
        <thead>
          <tr>
            <th class="text-center">Consignment No.</th>
            <!-- <th class="text-center">Avatar</th> -->
            <th class="text-center">Customer</th>
            <th class="text-center">No of Packages</th>
            <th class="text-center">Chargeable Weight</th>
            <th class="text-center">Origin Location</th>
            <th class="text-center">Destination Location</th>
            <th class="text-center">Status</th>
            <th class="text-center">Consignment Created At</th>
            <th class="text-center">Transit TIme</th>
            <th class="text-center">Delivered At</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let con of consignmentlists.content" [ngClass]="con.isActive == false ? 'red':'white'">
            <td class="text-center text-muted" style="width: 80px;">{{con.consignmentNo}}</td>
            <!-- <td class="text-center" style="width: 80px;">
                <img width="40" class="rounded-circle" src="./assets/images/avatars/4.jpg" alt="" />
              </td> -->
            <td class="text-center" style="max-width: 120px; overflow:hidden"><a *ngIf="con.senderId" [routerLink]=""
                matTooltip="{{con.senderId.firstName}}">{{con.senderId.firstName}}</a></td>
            <td class="text-center">{{con.totalNoOfPackages}}</td>
            <td class="text-center">{{con.totalChargableWeight}} kg</td>
            <td class="text-center">
              {{con.originLocation?.iataCode}}
            </td>
            <td class="text-center">
              {{con.destinationLocation?.iataCode}}
            </td>
            <td class="text-center">
              <div *ngIf="con.trackingId">
  
                <div class="badge bg-pill bg-primary">{{con.trackingId?.status.name}}</div>
              </div>
            </td>
            <td class="text-center">
              <span class="pe-2 opacity-6">
                <i class="fa fa-business-time"></i>
              </span>
              {{formatDate(con.createdAt)}}
            </td>
            <td class="text-center">
              10 Hrs
            </td>
            <td class="text-center">
              <span class="pe-2 opacity-6">
                <i class="fa fa-business-time"></i>
              </span>
              12 Dec 2022 11:45 AM
            </td>
            <!-- <td class="text-center" style="width: 200px;">
                <div class="widget-content p-0">
                  <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left pe-2">
                        <div class="widget-numbers fsize-1 text-info">88%</div>
                      </div>
                      <div class="widget-content-right w-100">
                        <div class="progress-bar-xs progress">
                          <div class="progress-bar bg-info" role="progressbar" aria-valuenow="88" aria-valuemin="0"
                            aria-valuemax="100" style="width: 88%;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td> -->
            <td class="text-center">
              <!-- <div role="group" class="btn-group-sm btn-group">
                <button class="btn-shadow btn btn-primary"><i class="lnr-pencil"></i></button>
                <button class="btn-shadow btn btn-primary">Delete</button>
              </div> -->
              <!-- <div role="group" *ngIf="con.isActive!=false" class="btn-group-sm btn-group m-1">
                <button class="btn-shadow btn btn-danger" (click)="Deletedata(con.id)"><i
                    class="pe-7s-trash"></i></button>
              </div> -->
            </td>
          </tr>
        </tbody>
      </table>
      <mat-paginator (page)="handlePageEvent($event)" [length]="consignmentlists.totalElements"
        [pageSize]="consignmentlists.numberOfElements" [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="pageSizeOptions" [pageIndex]="consignmentlists.number" aria-label="Select page">
      </mat-paginator>
    </div>
  
    <!-- <div class="d-block p-4 text-center card-footer">
        <button class="btn-pill btn-shadow btn-wide fsize-1 btn btn-dark btn-lg">
          <span class="me-2 opacity-7">
            <i class="fa fa-cog fa-spin"></i>
          </span>
          <span class="me-1">View Complete Report</span>
        </button>
      </div> -->
  </div>