<div class="col-md-10">
	<container-element [ngSwitch]="pageview">
		<some-element *ngSwitchCase="'table'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						Flight Schedules
					</div>
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-primary" (click)="viewchange('add')">Add</div>
					</div>
				</div>

				<div class="container">
					<div class="row">
						<div class="">
							<div class="table-responsive">
								<table class="align-middle text-truncate mb-0 table table-borderless table-hover">
									<thead>
										<tr>
											<th class="text-center">Airline</th>
											<th class="text-center">Flight No</th>
											<th class="text-center">Transit Time</th>
											<th class="text-center">Arrival At</th>
											<th class="text-center">Arrival Time</th>
											<th class="text-center">Departure At</th>
											<th class="text-center">Departure Time</th>
										</tr>
									</thead>
									<tbody>
										<!-- *ngFor="let air of airports | async" -->
										<tr *ngFor="let fli of flightSchedules | async">
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{fli.airlineID.name}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{fli.flightNo}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{fli.transitTime}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{fli.arrivalAirportID.iataCode}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{fli.transitTime}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{fli.departureAirportID.iataCode}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{fli.transitTime}}
												</a>
											</td>
											<td class="text-center">
												<div role="group" class="btn-group-sm btn-group">
													<button class="btn-shadow btn btn-primary" (click)="viewchange('edit')"><i
															class="lnr-pencil"></i></button>
													<!-- <button class="btn-shadow btn btn-primary">Delete</button> -->
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
									[showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
									[pageIndex]="pageIndex" aria-label="Select page">
								</mat-paginator>
							</div>
						</div>
					</div>
				</div>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'add'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						Add Flight Schedules
					</div>

				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Airline</mat-label>
								<select matNativeControl id=""  [(ngModel)]="newFlightSch.airlineID">
									<option *ngFor="let air of airlines |async" [value]="air.id">
										{{air.name}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Arrival Airport</mat-label>
								<select matNativeControl id="" [(ngModel)]="newFlightSch.arrivalAirportID">
									<option *ngFor="let airport of airports |async" [value]="airport.id">
										{{airport.iataCode}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Departure Airport</mat-label>
								<select matNativeControl id="" [(ngModel)]="newFlightSch.departureAirportID">
									<option *ngFor="let airport of airports |async" [value]="airport.id">
										{{airport.iataCode}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Flight No
								</mat-label>
								<input type="text" placeholder="Flight No" [(ngModel)]="newFlightSch.flightNo" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Arrival Time
								</mat-label>
								<input type="time" placeholder="Arrival Time" [(ngModel)]="newFlightSch.arrivalTime" aria-label="Number" matInput>

							</mat-form-field>
						</div>
							<div class="col-md-4">
								<mat-form-field appearance="standard">
									<mat-label>
										Departure Time
									</mat-label>
									<input type="time" placeholder="Departure Time" [(ngModel)]="newFlightSch.departureTime" aria-label="Number" matInput>
	
								</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Transit Time
								</mat-label>
								<input type="text" placeholder="Transit Time" [(ngModel)]="newFlightSch.transitTime"  aria-label="Number" matInput>

							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="createFlightSch()">Add</div>
					</div>
				</div>
			</div>
		</some-element>

		<some-element *ngSwitchCase="'edit'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						Edit Flight Schedules
					</div>

				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Airport</mat-label>
								<select matNativeControl id="">
									<option *ngFor="let con of airports |async" [value]="con.id">
										{{con.name}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Tariff Category</mat-label>
								<select matNativeControl id="">
									<option *ngFor="let airport of airports |async" [value]="airport.id">
										{{airport.name}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">


						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Outbound Rate
								</mat-label>
								<input type="text" placeholder="Outbound Rate" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Outbound Minimum Charge
								</mat-label>
								<input type="text" placeholder="Outbound Minimum Charge" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Inbound Rate
								</mat-label>
								<input type="text" placeholder="Inbound Rate" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Inbound Minimum Charge
								</mat-label>
								<input type="text" placeholder="Inbound Minimum Charge" aria-label="Number" matInput>

							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="viewchange('table')">Save</div>
					</div>
				</div>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'view'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						View Flight Schedules
					</div>

				</div>

				<div class="container m-2">
					<div class="row ">
						<div class="col-md-3 fw-bold">Airport</div>
						<div class="col-md-3"> HYD </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">Tariff Category</div>
						<div class="col-md-9"> Cargo Handling Charges (General Cargo) </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-2 fw-bold">
							Outbound
						</div>
						<div class="col-md-10">

							<div class="row ">
								<div class="col-md-3 fw-bold">Rate</div>
								<div class="col-md-3"> 1.37 /KG </div>
							</div>
							<div class="row ">
								<div class="col-md-3 fw-bold">Minimum Charge</div>
								<div class="col-md-3"> 38.71 /KG </div>
							</div>
						</div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-2 fw-bold">
							Inbound
						</div>
						<div class="col-md-10">

							<div class="row ">
								<div class="col-md-3 fw-bold">Rate</div>
								<div class="col-md-3"> 0.99 /KG </div>
							</div>
							<div class="row ">
								<div class="col-md-3 fw-bold">Minimum Charge</div>
								<div class="col-md-3"> 37.81 /KG </div>
							</div>
						</div>
					</div>


				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Back</div>
					</div>
				</div>
			</div>
		</some-element>
	</container-element>
</div>