<div class="col-md-8">
  <div class="card p-4">
    <div class="card-header">

      <div class="card-header-title font-size-lg text-capitalize fw-Bold">
        Change Password
      </div>
    </div>
    <div>
      <div class="col-md-12">
        <mat-label>
          <b>Old Password</b>
        </mat-label>
        <br>
        <p-password placeholder="Enter Old Password" [(ngModel)]="password"
                    [feedback]="false" [toggleMask]="true" (input)="checkOldNewPassword()"
                    [style]="{'width':'300px'}" [inputStyle]="{'width':'300px'}"></p-password>
      </div>
      <br>

      <div class="col-md-12">
        <mat-label>
          <b>New Password</b>
        </mat-label>
        <br>
        <p-password placeholder="Enter New Password" [(ngModel)]="newpassword"
                    [feedback]="false" [toggleMask]="true" (input)="checkOldNewPassword(); checkPassword()"
                    [style]="{'width':'300px'}" [inputStyle]="{'width':'300px'}"></p-password>&nbsp;&nbsp;&nbsp;
        <span class="text-danger" *ngIf="newpasswordFlag">New Password and Old Password should not match</span><br>
        <span class="text-danger" *ngIf="passLenCheck">Password should have a minimum of 6 characters</span>
      </div>
      <br>

      <div class="col-md-12">
        <mat-label>
          <b>Confirm New Password</b>
        </mat-label>
        <br>
        <p-password placeholder="Enter Confirm Password" [(ngModel)]="retypePassword"
                    [feedback]="false" [toggleMask]="true" (input)="checkOldNewPassword(); checkPassword()"
                    [style]="{'width':'300px'}" [inputStyle]="{'width':'300px'}"></p-password>&nbsp;&nbsp;&nbsp;
        <span class="text-danger" *ngIf="!passwordFlag && retypePassword.length >0">New Password and Confirm Password should match</span>
      </div>
      <div class="mt-4 d-flex align-items-center">
        <div class="ms-auto">
          <button class="mb-2 me-3 mt-2 btn btn-danger" (click)="Cancel()">Cancel</button>
          <button type="button" class="btn btn-primary btn-lg" (click)="updatepassword()">Change
            Password
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
