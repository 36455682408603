
import {ComingSoonComponent} from './Layout/Components/QuickStart/coming-soon/coming-soon.component';
import {QuickStartComponent} from './Layout/Components/QuickStart/quick-start/quick-start.component';

import {AddConsignmentComponent} from './Layout/Consignment/add-consignment/add-consignment.component';

import {NgModule, Component, OnInit} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';

// // Pages

import {ForgotPasswordComponent} from './DemoPages/UserPages/forgot-password/forgot-password.component';
import {ForgotPasswordBoxedComponent} from './DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component';
import {LoginBoxedComponent} from './DemoPages/UserPages/login-boxed/login-boxed.component';
import {LoginComponent} from './DemoPages/UserPages/login/login.component';
import {RegisterBoxedComponent} from './DemoPages/UserPages/register-boxed/register-boxed.component';
import {RegisterComponent} from './DemoPages/UserPages/register/register.component';

// DashBoard
import {IclDashboardComponent} from './Layout/Components/icl-dashboard/icl-dashboard.component';


import {ConsignmentTableComponent} from './Layout/Consignment/consignment-table/consignment-table.component';
import {NotificationComponent} from './Layout/Notifications/notification/notification.component';
import {SendNotificationComponent} from './Layout/Notifications/send-notification/send-notification.component';


//

import {TableCountriesComponent} from './Layout/Countries/table-countries/table-countries.component';


import {TableStatesComponent} from './Layout/States/table-states/table-states.component';
import {TableCitiesComponent} from './Layout/Cities/table-cities/table-cities.component';
import {TableAirportComponent} from './Layout/Airport/table-airport/table-airport.component';
import {TableAirlinesComponent} from './Layout/Airlines/table-airlines/table-airlines.component';
import {AirlinePricingScheduleComponent} from './Layout/Airlines/airline-pricing-schedule/airline-pricing-schedule.component';
import {AirportPricingScheduleComponent} from './Layout/Airport/airport-pricing-schedule/airport-pricing-schedule.component';
import {FlightScheduleComponent} from './Layout/Flights/flight-schedule/flight-schedule.component';
import {UserMangementComponent} from './Layout/AppUser/user-mangement/user-mangement.component';
import {ManifestationCreateComponent} from './Layout/Manifestation/manifestation-create/manifestation-create.component';
import {OfficeLocationComponent} from './Layout/OfficeLocations/office-location/office-location.component';
import {ConsignmentSubtypeComponent} from './Layout/consignmentSubtype/consignment-subtype/consignment-subtype.component';

import {ExpenseGuard} from './expense.guard';
import {NotfoundComponent} from './DemoPages/notfound/notfound.component';
import {BulkUploadComponent} from './Layout/BulkUpload/bulk-upload/bulk-upload.component';
import {TrackingViewComponent} from './Layout/Tracking/tracking-view/tracking-view.component';
import {IclProductsComponent} from './Layout/IclProducts/icl-products/icl-products.component';
import {EditUserComponent} from './Layout/AppUser/edit-user/edit-user.component';
import {UpdateTrackingStatusComponent} from './Layout/Tracking/update-tracking-status/update-tracking-status.component';
import {CorporateBookingComponent} from './Layout/Consignment/corporate-booking/corporate-booking.component';
import {CorporateListComponent} from './Layout/Consignment/corporate-list/corporate-list.component';
import {ManifestationListComponent} from './Layout/Manifestation/manifestation-list/manifestation-list.component';
import {TotalconsignmentsComponent} from './Layout/Consignment/totalconsignments/totalconsignments.component';
import {CustomerTableComponent} from './Layout/AppUser/customer-table/customer-table.component';
import {TablePincodesComponent} from './Layout/AppUser/table-pincodes/table-pincodes.component';
import {UpdatePasswordComponent} from './Layout/AppUser/update-password/update-password.component';
import {RepresentativeCustomerComponent} from './Layout/AppUser/representative-customer/representative-customer.component';
import {BookingTypesComponent} from './Layout/Components/QuickStart/booking-types/booking-types.component';
import {QuickOnlineComponent} from './Layout/Components/QuickStart/quick-online/quick-online.component';
import {QuickProductsComponent} from './Layout/Components/QuickStart/quick-products/quick-products.component';
import {OrdersComponent} from './Layout/orders/orders.component';
import {CalendarComponent} from './DemoPages/Components/calendar/calendar.component';
import {DoorPricingComponent} from './Layout/BulkUpload/door-pricing/door-pricing.component';
import {ATDConsignmentComponent} from './Layout/Consignment/atd-consignment/atd-consignment.component';
import {DTAConsignmentComponent} from './Layout/Consignment/dta-consignment/dta-consignment.component';
import {DTDConsignmentComponent} from './Layout/Consignment/dtd-consignment/dtd-consignment.component';
import {ControlPanelComponent} from './Layout/control-panel/control-panel.component';
import {OperationaltimingspanelComponent} from './Layout/operationaltimingspanel/operationaltimingspanel.component';
import {QuickATDComponent} from './Layout/Components/QuickStart/quick-atd/quick-atd.component';
import {QuickDTAComponent} from './Layout/Components/QuickStart/quick-dta/quick-dta.component';
import {QuickDTDComponent} from './Layout/Components/QuickStart/quick-dtd/quick-dtd.component';
import {CostCenterComponent} from './Layout/Consignment/cost-center/cost-center.component';
import {BillingInfoEditComponent} from './Layout/Consignment/billing-info-edit/billing-info-edit.component';
import {
  BillingInfoPageComponent,
  BillingOfflineDialog,
  CostCenterOfflineDialog,
} from './Layout/Consignment/billing-info-page/billing-info-page.component';
import {CostCenterEditComponent} from './Layout/Consignment/cost-center-edit/cost-center-edit.component';
import {CreateInvoiceComponent, InvoicePreview} from './Layout/Invoice/create-invoice/create-invoice.component';
import {InvoiceHistoryComponent} from './Layout/Invoice/invoice-history/invoice-history.component';
import {BookingsUploadComponent} from './Layout/Consignment/bookings-upload/bookings-upload.component';
import {ProductTypeComponent} from './Layout/product-type/product-type.component';
import {
  ConsignmentSubtypesComponent,
  ConsignmentTypesComponent,
  ProductTypesComponent,
} from './Layout/consignment-types-subtypes/consignment-types-subtypes.component';
import {ViewconsignmentComponent} from './Layout/Consignment/viewconsignment/viewconsignment.component';
import {CustomersComponent} from './Layout/AppUser/customers/customers.component';
import {TableRegionsComponent} from './Layout/Regions/table-regions/table-regions.component';
import {GroundservicesComponent} from './Layout/Ground-Services/groundservices/groundservices.component';
import {ShipmentIngestionComponent} from './Layout/shipment-ingestion/shipment-ingestion.component';
import {TallyIrnGenerateComponent} from './Layout/Invoice/tally-irn-generate/tally-irn-generate.component';
import {
  CustomerConsignmentViewPageComponent,
} from './Layout/AppUser/customer-consignment-view-page/customer-consignment-view-page.component';
import {ConsignmentEditComponent} from './Layout/Consignment/consignment-edit/consignment-edit.component';
import {CreateNewCustomerComponent} from './shared/create-new-customer/create-new-customer.component';
import {CreateNewRepresentativeComponent} from './shared/create-new-representative/create-new-representative.component';
import {AirlinePromotionsComponent} from './Layout/airline-promotions/airline-promotion-list/airline-promotions.component';
import {
  CreateAirlinePromotionComponent,
} from './Layout/airline-promotions/create-airline-promotion/create-airline-promotion.component';

import { ViewAirlinePromotionComponent } from './Layout/airline-promotions/view-airline-promotion/view-airline-promotion.component';

import {PaperWorkComponent} from './Layout/paper-work/paper-work.component';
import {PaperWorkAddComponent} from './Layout/paper-work-add/paper-work-add.component';
import { BillingCostBulkUploadComponent } from './Layout/Consignment/billing-cost-bulk-upload/billing-cost-bulk-upload.component';
import {CustomerCreateComponent} from "./Layout/AppUser/customer-create/customer-create.component";
import {CustomerEditComponent} from "./Layout/AppUser/customer-edit/customer-edit.component";
import {RetailCustomerCreateComponent} from "./Layout/AppUser/retail-customer-create/retail-customer-create.component";
import {RetailCustomerTableComponent} from "./Layout/AppUser/retail-customer-table/retail-customer-table.component";
import {RetailCustomerEditComponent} from "./Layout/AppUser/retail-customer-edit/retail-customer-edit.component";
import {RepresentativeCreateComponent} from "./Layout/AppUser/representative-create/representative-create.component";
import {RepresentativeEditComponent} from "./Layout/AppUser/representative-edit/representative-edit.component";
import {UserManagementCreateComponent} from "./Layout/AppUser/user-management-create/user-management-create.component";
import {UserManagementEditComponent} from "./Layout/AppUser/user-management-edit/user-management-edit.component";
import {ContractsComponent} from "./Layout/contracts/contracts.component";
import {CreateContractComponent} from "./Layout/contracts/create-contract/create-contract.component";
import {CreateRegionComponent} from "./Layout/Regions/create-region/create-region.component";
import {RegionsEditComponent} from "./Layout/Regions/regions-edit/regions-edit.component";
import { RegionPricesComponent } from './Layout/contracts/region-prices/region-prices.component';
import { CityPricesComponent } from './Layout/contracts/city-prices/city-prices.component';

import {InfoComponent} from './Layout/info/info.component';
import { CsvFileFormatsComponent } from './Layout/file-formats/csv-file-formats/csv-file-formats.component';
import { CsvFileFormatsAddComponent } from './Layout/file-formats/csv-file-formats-add/csv-file-formats-add.component';
import { TallyIrnGenerateViewComponent } from './Layout/Invoice/tally-irn-generate-view/tally-irn-generate-view.component';
import { AirlinePricingViewComponent } from './Layout/BulkUpload/airline-pricing-view/airline-pricing-view.component';
import { AirlinePricingEditComponent } from './Layout/BulkUpload/airline-pricing-edit/airline-pricing-edit.component';


const routes_origin: Routes = [
  {
    path: '',
    component: LoginComponent,
    children: [
      {
        path: '',
        redirectTo: 'pages/login',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'register',
    component: RegisterComponent,
    pathMatch: 'full',
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'reset_password',
    component: ForgotPasswordBoxedComponent,
  },
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [],
    children: [
      {
        path: 'apps',
        loadChildren: () => import('./DemoPages/Applications/Applications.module').then((m) => m.ApplicationModule),
      },
      {
        path: 'charts',
        loadChildren: () => import('./DemoPages/Charts/Charts.module').then((m) => m.ChartModule),
      },
      {
        path: 'components',
        loadChildren: () => import('./DemoPages/Components/Components.module').then((m) => m.ComponentsDrawerModule),
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./DemoPages/Dashboards/Dashboards.module').then((m) => m.DashboardsModule),
      },
      {
        path: 'elements',
        loadChildren: () => import('./DemoPages/Elements/Elements.module').then((m) => m.ElementsModule),
      },
      {
        path: 'form-elements',
        loadChildren: () => import('./DemoPages/Forms/Elements/form-elements.module').then((m) => m.FormElementModule),
      },
      {
        path: 'form-widgets',
        loadChildren: () => import('./DemoPages/Forms/Widgets/forms-widgets.module').then((m) => m.FormWidgetsModule),
      },
      {
        path: 'button-indicators',
        loadChildren: () => import('./DemoPages/Material/ButtonsIndicators/buttonsIndicators.module').then((m) => m.MateriaButoonIndicatorslModule),
      },
      {
        path: 'layout',
        loadChildren: () => import('./DemoPages/Material/Layout/layout.module').then((m) => m.MaterialLayoutModule),
      },
      {
        path: 'form-controls',
        loadChildren: () => import('./DemoPages/Material/FormControls/formcontrols.module').then((m) => m.MaterialFormControlModule),
      },
      {
        path: 'material',
        loadChildren: () => import('./DemoPages/Material/Material.module').then((m) => m.MaterialModule),
      },
      {
        path: 'widgets',
        loadChildren: () => import('./DemoPages/Widgets/Widgets.module').then((m) => m.WidgetsModule),
      },
      {
        path: 'tables/bootstrap',
        loadChildren: () => import('./DemoPages/Tables/tables-main/tables-main.module').then((m) => m.TablesMainModule),
      },
      {
        path: 'tables/bootstrap',
        loadChildren: () => import('./DemoPages/Tables/tables-main/tables-main.module').then((m) => m.TablesMainModule),
      },
      // {
      //   path: '',
      //   redirectTo: 'pages/login',
      //   pathMatch: 'full'
      // },

      {
        path: 'consignment', component: ConsignmentTableComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Supervisor', 'Accountant']},
      },
      {
        path: 'viewconsignment/:cosigno', component: ViewconsignmentComponent, data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Supervisor', 'Accountant']},
      },
      {
        path: 'consignmentEdit/:cosigno', component: ConsignmentEditComponent, data: {roles: ['Admin', 'Processing Agent', 'Supervisor']},
      },
      {
        path: 'billingInfoPage/:id', component: BillingInfoPageComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor', 'Customer', 'Accountant']},
      },
      {
        path: 'billingInfoEdit/:id', component: BillingInfoEditComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor', 'Accountant']},
      },
      {
        path: 'costCenter/:id', component: CostCenterComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor', 'Accountant']},
      },
      {
        path: 'costCenterEdit/:id', component: CostCenterEditComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor', 'Accountant']},
      },
      {
        path: 'billingOfflineDialog', component: BillingOfflineDialog, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Supervisor']},
      },
      {
        path: 'costCenterOfflineDialog', component: CostCenterOfflineDialog, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Supervisor']},
      },
      {
        path: 'notifications', component: NotificationComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Supervisor']},
      },
      {
        path: 'representative', component: RepresentativeCustomerComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Customer', 'Supervisor', 'Accountant']},
      },
      {
        path: 'representativeCreate', component: RepresentativeCreateComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Customer', 'Supervisor', 'Accountant']},
      },
      {
        path: 'representativeEdit/:id', component: RepresentativeEditComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Customer', 'Supervisor', 'Accountant']},
      },
      {
        path: 'notifications/sendnotification', component: SendNotificationComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Supervisor']},
      },
      {
        path: 'consignment/addconsignment', component: AddConsignmentComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Customer', 'Supervisor', 'Accountant']},
      },
      {
        path: 'dashboard', component: IclDashboardComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Customer', 'Supervisor']},
      },
      {
        path: 'countries', component: TableCountriesComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Accountant']},
      },
      {
        path: 'states', component: TableStatesComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Accountant']},
      },
      {
        path: 'cities', component: TableCitiesComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Accountant']},
      },
      {
        path: 'pincodes', component: TablePincodesComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Accountant']},
      },
      {
        path: 'regions', component: TableRegionsComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Accountant']},
      },
      {
        path: 'groundservices', component: GroundservicesComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin',, 'Accountant']},
      },
      {
        path: 'airports', component: TableAirportComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor', 'Accountant']},
      },
      {
        path: 'airlines', component: TableAirlinesComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor', 'Accountant']},
      },
      {
        path: 'airlinepricingschedule', component: AirlinePricingScheduleComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor']},
      },
      {
        path: 'airportpricingschedule', component: AirportPricingScheduleComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor']},
      },
      {
        path: 'flightschedule', component: FlightScheduleComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor']},
      },
      {
        path: 'usermanagement', component: UserMangementComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin']},
      },
      {
        path: 'usermanagementCreate', component: UserManagementCreateComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin']},
      },
      {
        path: 'usermanagementEdit/:id', component: UserManagementEditComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin']},
      },
      {
        path: 'createmanifest', component: ManifestationCreateComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Processing Agent', 'Supervisor', 'Accountant']},
      },
      {
        path: 'officelocations', component: OfficeLocationComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Accountant']},
      },
      {
        path: 'consignmentsubtype', component: ConsignmentSubtypeComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor']},
      },
      {
        path: 'airlinePricing', component: BulkUploadComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor', 'Accountant']},
      },
      {
        path: 'airlinePricingView/:id', component: AirlinePricingViewComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor', 'Accountant']},
      },
      {
        path: 'airlinePricingEdit/:id', component: AirlinePricingEditComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor', 'Accountant']},
      },
      {
        path: 'airlinePromotions', component: AirlinePromotionsComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor']},
      },
      {
        path: 'createAirlinePromotions', component: CreateAirlinePromotionComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor']},
      },
      {
        path: 'viewAirlinePromotions/:id', component: ViewAirlinePromotionComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Supervisor']},
      },
      {
        path: 'doorpricing', component: DoorPricingComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Accountant']},
      },
      {
        path: 'trackingview', component: TrackingViewComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Customer', 'Supervisor', 'Accountant']},
      },
      {
        path: 'iclproducts', component: IclProductsComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Customer', 'Supervisor', 'Accountant']},
      },
      {
        path: 'editdetails', component: EditUserComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Supervisor', 'Accountant', 'Customer']},
      },
      {
        path: 'updatepassword', component: UpdatePasswordComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Customer', 'Supervisor', 'Accountant']},
      },
      {
        path: 'updatetracking', component: UpdateTrackingStatusComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Supervisor']},
      },
      {
        path: 'corporatebooking/:productType', component: CorporateBookingComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Supervisor', 'Accountant']},
      },
      {
        path: 'ATDbooking', component: ATDConsignmentComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Supervisor', 'Customer', 'Accountant']},
      },
      {
        path: 'DTDbooking', component: DTDConsignmentComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Supervisor', 'Customer', 'Accountant']},
      },
      {
        path: 'DTAbooking', component: DTAConsignmentComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Supervisor', 'Customer', 'Accountant']},
      },
      {
        path: 'customers', component: CustomersComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Supervisor', 'Accountant']},
      },
      {
        path: 'customerCreate', component: CustomerCreateComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Supervisor', 'Accountant']},
      },
      {
        path: 'customerEdit/:id', component: CustomerEditComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Supervisor', 'Accountant']},
      },
      {
        path: 'retailCustomers', component: RetailCustomerTableComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Processing Agent', 'Destination Agent', 'Supervisor', 'Accountant']},
      },
      {
        path: 'retailCustomerCreate', component: RetailCustomerCreateComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Processing Agent', 'Destination Agent', 'Supervisor', 'Accountant']},
      },
      {
        path: 'retailCustomerEdit/:id', component: RetailCustomerEditComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Processing Agent', 'Destination Agent', 'Supervisor', 'Accountant']},
      },
      {
        path: 'contracts',
        component: ContractsComponent,
        canActivate: [ExpenseGuard],
        data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Supervisor', 'Accountant']}
      },
      {
        path: 'contracts/create',
        component: CreateContractComponent,
        canActivate: [ExpenseGuard],
        data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Supervisor', 'Accountant']}
      },
      {
        path: 'corporatelist', component: CorporateListComponent, canActivate: [ExpenseGuard], data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Destination Agent', 'Supervisor']},
      },
      {
        path: 'manifestation', component: ManifestationListComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Processing Agent', 'Supervisor', 'Accountant']},
      },
      {
        path: 'totalconsignments', component: TotalconsignmentsComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Processing Agent', 'Supervisor']},
      },
      {
        path: 'consignmentTable', component: CustomerTableComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Customer', 'Supervisor']},
      },
      {
        path: 'consignmentView/:id', component: CustomerConsignmentViewPageComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Customer', 'Supervisor']},
      },
      {
        path: 'quickstart', component: QuickStartComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Processing Agent', 'Pickup Delivery Agent', 'Customer', 'Supervisor', 'Accountant']},
      },
      {
        path: 'comingsoon', component: ComingSoonComponent, data: {roles: ['Admin', 'Processing Agent', 'Pickup Delivery Agent', 'Supervisor']},
      },
      {
        path: 'bookingtypes/:productType', component: BookingTypesComponent, data: {roles: ['Admin', 'Processing Agent', 'Pickup Delivery Agent', 'Supervisor']},
      },
      {
        path: 'quickonline', component: QuickOnlineComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Processing Agent', 'Pickup Delivery Agent', 'Customer', 'Supervisor', 'Accountant']},
      },
      {
        path: 'quickATDonline', component: QuickATDComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Processing Agent', 'Pickup Delivery Agent', 'Customer', 'Supervisor', 'Accountant']},
      },
      {
        path: 'appInfo', component: InfoComponent, canActivate: [ExpenseGuard], data: { roles: ["Admin", "Processing Agent", "Pickup Delivery Agent", "Customer", "Supervisor"] }
      },
      {
        path: 'quickDTAonline', component: QuickDTAComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Processing Agent', 'Pickup Delivery Agent', 'Customer', 'Supervisor', 'Accountant']},
      },
      {
        path: 'quickDTDonline', component: QuickDTDComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Processing Agent', 'Pickup Delivery Agent', 'Customer', 'Supervisor', 'Accountant']},
      },
      {
        path: 'quickproducts', component: QuickProductsComponent, canActivate: [ExpenseGuard], data: {roles: ['Admin', 'Processing Agent', 'Pickup Delivery Agent', 'Customer', 'Supervisor', 'Accountant']},
      },
      {
        path: 'orders', component: OrdersComponent, data: {roles: ['Admin', 'Processing Agent', 'Supervisor']},
      },
      {
        path: 'controlpanel', component: ControlPanelComponent, data: {roles: ['Admin']},
      },
      {
        path: 'operationaltimings', component: OperationaltimingspanelComponent, data: {roles: ['Admin']},
      },
      {
        path: 'createinvoice', component: CreateInvoiceComponent, data: {roles: ['Admin']},
      },
      {
        path: 'invoicehistory', component: InvoiceHistoryComponent, data: {roles: ['Admin']},
      },
      {
        path: 'tallyIrnGenerate/:id', component: TallyIrnGenerateComponent, data: {roles: ['Admin']},
      },
      {
        path: 'tallyIrnGenerateView/:id', component: TallyIrnGenerateViewComponent, data: {roles: ['Admin']}
      },
      {
        path: 'invoicePreview', component: InvoicePreview, data: {roles: ['Admin']},
      },
      {
        path: 'bookingsupload', component: BookingsUploadComponent, data: {roles: ['Admin', 'Supervisor', 'Pickup Delivery Agent', 'Processing Agent']},
      },
      {
        path: 'linking', component: ProductTypeComponent, data: {roles: ['Admin']},
      },
      {
        path: 'productTypes', component: ProductTypesComponent, data: {roles: ['Admin']},
      },
      {
        path: 'consignment-types', component: ConsignmentTypesComponent, data: {roles: ['Admin']},
      },
      {
        path: 'consignment-subtypes', component: ConsignmentSubtypesComponent, data: {roles: ['Admin']},
      },
      {
        path: 'consignmentUpload', component: ShipmentIngestionComponent, data: {roles: ['Admin']},
      },
      {
        path: 'regions/create', component: CreateRegionComponent, data: {roles: ['Admin']},
      },
      {
        path: 'regions/edit', component: RegionsEditComponent, data: {roles: ['Admin']},
      },
      {
        path: 'consignment/createNewCustomer', component: CreateNewCustomerComponent, data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Supervisor']},
      },
      {
        path: 'consignment/createNewRepresentative', component: CreateNewRepresentativeComponent, data: {roles: ['Pickup Delivery Agent', 'Admin', 'Processing Agent', 'Supervisor']},
      },
      {
        path: 'paperWork', component: PaperWorkComponent, data: {roles: ['Admin']},
      },
      {
        path: 'addPaperWork', component: PaperWorkAddComponent, data: {roles: ['Admin']},
      },
      {
        path: 'billingAndCostUpload', component: BillingCostBulkUploadComponent, data: {roles: ['Admin']},
      },
      {
        path: 'fileFormats', component: CsvFileFormatsComponent, data: {roles: ['Admin']},
      },
      {
        path: 'addCSVFileFormat', component: CsvFileFormatsAddComponent, data: {roles: ['Admin']},
      },
      {
        path: 'regionPrice', component: RegionPricesComponent, data: {roles: ['Admin', 'Processing Agent', 'Supervisor']},
      },
      {
        path: 'cityPrice', component: CityPricesComponent, data: {roles: ['Admin', 'Processing Agent', 'Supervisor']},
      },
      {
        path: '404', component: NotfoundComponent,
      },
      {path: '**', redirectTo: 'dashboard'},
    ],
  },
];


const routes: Routes = get_routes();


function get_routes() {
  // console.log( kc.getUsername());

  // let roles =kc.getUserRoles();
  // if(roles.includes("ORIGIN_AGENT")){
  //   alert("user_role")
  // }
  return routes_origin;
  //   re = AppRoutingModule.get_routes_class()
}


@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy',
    })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
