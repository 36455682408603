import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { HttpService } from 'src/app/services/http.service';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { API_URL } from '../../../../../Global_API_URL';
import Swal from 'sweetalert2';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-tally-irn-generate-view',
  templateUrl: './tally-irn-generate-view.component.html',
  styleUrls: ['./tally-irn-generate-view.component.sass']
})
export class TallyIrnGenerateViewComponent implements OnInit {

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  generatedAt;
  invoiceId;
  TOKEN;
  userDetails;
  displayToAdmin = false;
  displayToSupervisor = false;

  constructor(private ActivatedRoute: ActivatedRoute, private route: Router, private authService: AuthService, private http: HttpClient, private httpService: HttpService) {
    this.TOKEN = this.authService.getAuthToken();
    this.ActivatedRoute.params.subscribe((params) => {
      this.invoiceId = params['id'];
    });
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0] == 'Admin') {
      this.displayToAdmin = true;
    }
    if (this.userDetails.userTypes[0] == 'Supervisor') {
      this.displayToSupervisor = true;
    }
    this.getInvoiceById(this.invoiceId);

    // setTimeout(() => {
    //   this.loading = true;
    // }, 2000);
  }

  invoiceData;

  getInvoiceById(id) {
    this.loading = true;
    this.httpService.get(API_URL + '/getInvoice/' + id, null, null)
      .subscribe((response) => {
        this.invoiceData = response.data[0];
        this.loading = false;
      }, (error) => {
        console.error('Error:', error);
        this.loading = false; 
      });
  }


  IrnData = {
    'invoiceNo': '',
    'irnNumber': '',
    'acknowledgeNo': '',
    'qrCode': '',
    'createdAt': null,
    'remarks': ''
  }

  validateInput(event: KeyboardEvent): void {
    const inputChar = String.fromCharCode(event.charCode);

    // Allow only digits and a dot (for decimal numbers)
    if (!/^[0-9a-zA-Z]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  backToInvoiceHistory() {
    this.route.navigate(['/invoicehistory']);
  }

  goToIRNGenerateEdit() {
    this.route.navigate(['/tallyIrnGenerate', this.invoiceId]);
  }

}
