<div class="app-footer">
  <div class="app-footer__inner">
    <div class="app-footer-left">
      <app-footer-dots></app-footer-dots>
    </div>
    <div class="app-footer-left">
      <div class="text-primary fw-bold m-auto">
        
        {{server}}
      </div>
    </div>
    <div class="offset-4 mat-button-toggle-appearance-standard">
      <h6 class="col-md-12 m-3" style="text-align: left; color: rgb(48, 48, 218);">ICL© All Rights Reserved.</h6>
    </div>
    <!-- <div class="app-footer-right">
      <app-footer-menu></app-footer-menu>
    </div> -->
  </div>
</div>
