import { Component, OnInit } from '@angular/core';
import { ConsignmentPaperWorkDTO } from '../golbalInterfaces';
import { PaperWorkService } from '../paper-work/paper-work.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paper-work-add',
  templateUrl: './paper-work-add.component.html',
  styleUrls: ['./paper-work-add.component.sass'],
})
export class PaperWorkAddComponent implements OnInit {
  constructor(private paperWorkService: PaperWorkService, private router: Router) { }

  ngOnInit(): void {
  }

  newPaperWork: ConsignmentPaperWorkDTO = new ConsignmentPaperWorkDTO();
  selectedFile: File | undefined;
  isSubmitting: boolean = false;

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      const currentTime = new Date().getTime();
      const fileNameParts = this.selectedFile.name.split('.');
      const newFileName = `${fileNameParts[0]}_${currentTime}.${fileNameParts[1]}`;
      this.selectedFile = new File([this.selectedFile], newFileName, { type: this.selectedFile.type });
    }
  }

  // onSubmit(): void {
  //   if (!this.newPaperWork.contentDisposition) {
  //     Swal.fire('Warning', 'Please Enter the File Name', 'warning');
  //     return;
  //   }
  //   if (!this.selectedFile) {
  //     Swal.fire('Warning', 'No file selected', 'warning');
  //     return;
  //   }
  //   this.paperWorkService.createPaperWork(this.newPaperWork, this.selectedFile)
  //     .subscribe((response) => {
  //       Swal.fire('Success', 'File uploaded successfully', 'success');
  //       this.router.navigate(['/paperWork']);
  //     }, (error) => {
  //       const errorMessage = error.error.errorMessage || 'Error uploading paper work';
  //       Swal.fire('Error', errorMessage, 'error');
  //     });
  // }

  onSubmit(): void {
    if (this.isSubmitting) return;
    if (!this.newPaperWork.contentDisposition) {
      Swal.fire('Warning', 'Please Enter the File Name', 'warning');
      return;
    }
    if (!this.selectedFile) {
      Swal.fire('Warning', 'No file selected', 'warning');
      return;
    }
    this.isSubmitting = true;

    // Check if content disposition already exists
    this.paperWorkService.existsByContentDisposition(this.newPaperWork.contentDisposition)
      .subscribe((exists) => {
        if (exists) {
          Swal.fire('Warning', 'File Name you given is already existing', 'warning');
          this.isSubmitting = false;
        } else {
          // If content disposition doesn't exist, proceed with creating paper work
          this.paperWorkService.createPaperWork(this.newPaperWork, this.selectedFile)
            .subscribe((response) => {
              Swal.fire('Success', 'File uploaded successfully', 'success');
              this.router.navigate(['/paperWork']);
            }, (error) => {
              const errorMessage = error.error.errorMessage || 'Error uploading paper work';
              Swal.fire('Error', errorMessage, 'error');
            })
            .add(() => {
              this.isSubmitting = false; // Reset submitting state
            });
        }
      }, (error) => {
        Swal.fire('Error', 'Failed to check content disposition existence', 'error');
        this.isSubmitting = false;
      });
  }

  onCancel(): void {
    this.router.navigate(['/paperWork']);
  }
}
