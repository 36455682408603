import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {tap, delay} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {API_URL} from '../../Global_API_URL';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {HttpService} from './services/http.service';
import {TokenService} from './services/token.service';
import {HttpClient, HttpParams} from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private jwtHelper: JwtHelperService, private router: Router, private httpService: HttpService, private tokenService: TokenService, private httpClient: HttpClient) {
    this.start();
  }

  isUserLoggedIn: Boolean = false;


  login(userName: string, password: string) {
    const user_cred = {
      username: '',
      password: '',
    };
    user_cred.username = userName;
    user_cred.password = password;
    this.httpService.post(API_URL + '/authenticate',user_cred, null, null)
        .subscribe((result) => {
          if (result.message == "Success"){
            if (!result.status) {
              if (result?.user?.userTypes[0]?.name == 'Customer') {
                if (result?.user?.isActive == true) {
                  if (!result?.user?.orgCustomerId || result?.user?.orgCustomerId?.is_active== true) {
                    this.isUserLoggedIn = true;
                    localStorage.clear();
                    localStorage.setItem('isUserLoggedIn', 'true');
                    sessionStorage.setItem('accessToken', result.accessToken);
                    localStorage.setItem('refreshToken', result.refreshToken);
                    localStorage.setItem('userDetails', JSON.stringify(result.user));
                  } else {
                    Swal.fire({
                      position: 'center',
                      icon: 'error',
                      title: 'ERROR',
                      html: 'Please Contact Admin To Activate Your Account' +
                        ' </br> Mail: <a href="mailto:balajiks@iclscm.com">balajiks@iclscm.com</a>' +
                        '</br> Phone : <a href="tel:+91 9121006802">+91 9121006802</a> ',
                      timer: 5000,
                    });
                  }
                } else {
                  Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'ERROR',
                    text: 'User Not Found',
                    timer: 5000,
                  });
                }
              } else {
                if (!result.status) {
                  if (result?.user?.isActive == true) {
                    this.isUserLoggedIn = true;
                    localStorage.clear();
                    localStorage.setItem('isUserLoggedIn', 'true');
                    sessionStorage.setItem('accessToken', result.accessToken);
                    localStorage.setItem('refreshToken', result.refreshToken);
                    localStorage.setItem('userDetails', JSON.stringify(result.user));
                  } else {
                    Swal.fire({
                      position: 'center',
                      icon: 'error',
                      title: 'ERROR',
                      text: 'User Not Found',
                      timer: 5000,
                    });
                  }
                } else {
                  this.isUserLoggedIn = false;
                  Swal.fire({
                    icon: 'error',
                    title: 'Invalid Credentials',
                    timer: 5000,
                  });
                }
              }
            } else {
              this.isUserLoggedIn = false;
              Swal.fire({
                icon: 'error',
                title: 'Invalid Credentials',
                timer: 5000,
              });
            }
          } else{
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'ERROR',
              html: 'Please Contact Admin To Activate Your Account' +
                ' </br> Mail: <a href="mailto:balajiks@iclscm.com">balajiks@iclscm.com</a>' +
                '</br> Phone : <a href="tel:+91 9121006802">+91 9121006802</a> ',
              timer: 5000,
            });
          }
          if(this.isUserLoggedIn==true) this.router.navigate(['/quickstart']);
        },(error) => {
          console.error('Error', error);
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'ERROR',
            text: 'Invalid Credentials',
            timer: 5000
          });
        })
  }

  public isTokenExpired(token: string) {
    return this.jwtHelper.isTokenExpired(token);
  }

  public getAuthToken() {
    const accessToken = sessionStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const body = {'refreshToken': refreshToken};
    if (this.isTokenExpired(accessToken)) {
      if (!this.isTokenExpired(refreshToken)) {
        this.getAccessFromRefreshToken()
            .subscribe((result) => {
              sessionStorage.setItem('accessToken', result.accessToken);
              localStorage.setItem('refreshToken', result.refreshToken);
              localStorage.setItem('userDetails', JSON.stringify(result.user));
            });
      }
    } else return accessToken;
  }

  public getAccessFromRefreshToken() {
    const body = {'refreshToken': this.tokenService.getRefreshToken()};
    return this.httpService.post(API_URL + '/refreshAuth', body, null, null);
  }

  logout(): void {
    this.isUserLoggedIn = false;
    localStorage.setItem('logout-event', Math.random().toString());
    localStorage.removeItem('isUserLoggedIn');
    sessionStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userDetails');
    this.router.navigate(['/']);
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  // Logout only when key is 'logout-event'
  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event?.key && event.key == 'logout-event') {
        // console.log("🔥 ~ storageEventListener ~ event", event.newValue)
        this.tokenService.signOut();
      }
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  ngOnDestroy() {
    this.stop();
  }

}
