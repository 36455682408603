<div *ngIf="loading" class="loader-container">
    <ngx-loading [show]="loading"
        [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
        [template]="loadingTemplate"></ngx-loading>
</div>
<div class="col-md-12">
    <div class="card">
        <div class="card-header">
            <div class="col-lg-6" style="display: flex">
                <div class="mr-5 pr-5">
                    <mat-icon (click)="goToContractPage()" style="cursor: pointer; margin: right 200px;">arrow_back</mat-icon>
                </div>
                <div class="card-header-title font-size-lg text-capitalize fw-bold ml-5 mr-5 pl-5">
                    <b style="color: blue;">VIEW REGION PRICE</b>
                </div>
            </div>
            <div class="col-lg-11" style="display: flex">
                <button class="btn btn-success m-auto" (click)="exportToCSV()">
                    Report
                </button>
            </div>
        </div>
        <div class="card-body">
            <div class="container m-2">
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field appearance="standard">
                            <mat-label>Product</mat-label>
                            <mat-select [(value)]="selectedProduct" (selectionChange)="onFilterChange()">
                                <mat-option *ngFor="let product of products" [value]="product">{{ product
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="standard">
                            <mat-label>Mode</mat-label>
                            <mat-select [(value)]="selectedMode" (selectionChange)="onFilterChange()">
                                <mat-option *ngFor="let mode of modes" [value]="mode">{{ mode }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="standard">
                            <mat-label>Consignment Type</mat-label>
                            <mat-select [(value)]="selectedConsignmentType" (selectionChange)="onFilterChange()">
                                <mat-option *ngFor="let type of consignmentTypes" [value]="type">{{ type }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="standard">
                            <mat-label>Consignment Subtype</mat-label>
                            <mat-select [(value)]="selectedConsignmentSubtype" (selectionChange)="onFilterChange()">
                                <mat-option *ngFor="let subtype of consignmentSubtypes" [value]="subtype">{{ subtype
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <br>
                    <div class="row">
                        <div class="col-md-3 fw-bold">{{ prices?.data?.content[0].contract?.customer?.firstName}}</div>
                    </div>
                    <br>
                <div *ngIf="matrixData">
                    <!-- <div class="table-responsive">
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mat-table-sticky">
                            <ng-container matColumnDef="destination">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span style="color: green;"><strong>Destination</strong></span> /
                                    <span style="color: blue;"><strong>Origin</strong></span>
                                </th>
                                <td mat-cell *matCellDef="let element" style="color: green;"><strong>{{
                                        element.destination }}</strong></td>
                            </ng-container>

                            <ng-container *ngFor="let origin of origins" [matColumnDef]="origin">
                                <th mat-header-cell *matHeaderCellDef style="color: blue;">{{ origin }}</th>
                                <td mat-cell *matCellDef="let element">{{ element[origin] }}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div> -->
                    <div class="table-responsive">
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mat-table-sticky">
                            <ng-container matColumnDef="origin">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span style="color: green;"><strong>Origin</strong></span> /
                                    <span style="color: blue;"><strong>Destination</strong></span>
                                </th>
                                <td mat-cell *matCellDef="let element" style="color: green;"><strong>{{ element.origin
                                        }}</strong></td>
                            </ng-container>

                            <ng-container *ngFor="let destination of destinations" [matColumnDef]="destination">
                                <th mat-header-cell *matHeaderCellDef style="color: blue;">{{ destination }}</th>
                                <td mat-cell *matCellDef="let element">{{ element[destination] }}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>

                </div>
            </div>
            <div class="card-footer">
                <div class="btn-actions-pane-right p-2">
                    <div class="mb-2 me-2 btn btn-danger" (click)="goToContractPage()">Back</div>
                </div>
            </div>
        </div>
    </div>
</div>
