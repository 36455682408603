<div class="tree-container">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <!-- There is inline padding applied to this node using styles.
    This padding value depends on the mat-icon-button width. -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <!-- {{node.name}} - {{ node.description}} -->
      <div class="mat-tree-node">
        <div>{{ node.name }}</div>
        <div class="node-description">{{ node.description }}</div>
      </div>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div class="mat-tree-node">
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <div>{{ node.name }}</div>
        <div class="node-description">{{ node.description }}</div>
        <!-- {{node.name}} - {{node.description}} -->
      </div>
      <!-- There is inline padding applied to this div using styles.
        This padding value depends on the mat-icon-button width.  -->
      <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
</div>