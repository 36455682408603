<div class="col-md-6">
	<container-element [ngSwitch]="pageview">
		<some-element *ngSwitchCase="'table'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						<b style="color: blue;">STATES</b>
					</div>
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-success" (click)="exportToCSV()">Report</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="viewchange('add')" *ngIf="!displaytoAccountant">Add</div>
					</div>

				</div>

				<div class="">
					<div *ngIf="!loading" style="overflow-x: auto">
						<ng-container *ngIf="dataSource?.data?.length > 0; else noRecordsMessage">
							<table class="w-100" mat-table [dataSource]="dataSource" matSort
								(matSortChange)="announceSortChange($event)">
								<!-- Position Column -->
								<ng-container matColumnDef="Name">
									<th class="text-center" mat-header-cell *matHeaderCellDef
										sortActionDescription="Sort by Name">State Name</th>
									<td class="text-center" mat-cell *matCellDef="let element">{{element.name}}</td>
								</ng-container>
								<ng-container matColumnDef="Country">
									<th class="text-center" mat-header-cell *matHeaderCellDef
										sortActionDescription="Sort by UserRole">Country </th>
									<td class="text-center" mat-cell *matCellDef="let element">{{element.country.name}}
									</td>
								</ng-container>
								<ng-container matColumnDef="gstPrefixCode">
									<th class="text-center" mat-header-cell *matHeaderCellDef
										sortActionDescription="Sort by UserRole">Gst-prefix-code </th>
									<td class="text-center" mat-cell *matCellDef="let element">{{element.gstPrefixCode}}
									</td>
								</ng-container>
								<ng-container matColumnDef="Active" >
									<th mat-header-cell *matHeaderCellDef  [style.display]="displaytoAccountant ? 'none' : 'table-cell'"> Action </th>
									<td mat-cell *matCellDef="let element" >
										<div class="d-flex" *ngIf="!displaytoAccountant">
											<div role="group" class="btn-group-sm btn-group m-1">
												<button class="btn-shadow btn btn-primary" matTooltip="Edit"
													(click)="viewchange1('edit',element)"><i
														class="lnr-pencil"></i></button>
											</div>
											<div role="group" *ngIf="(element?.is_active != false)"
												class="btn-group-sm btn-group m-1">
												<span class="material-icons btn-shadow btn btn-danger"
													matTooltip="DeactivateState"
													(click)="DeactivateState(element)">remove_circle</span>
											</div>
											<div role="group" *ngIf="(element?.is_active == false)"
												class="btn-group-sm btn-group m-1">
												<span class="material-icons btn-shadow btn btn-success"
													matTooltip="ActivateState" (click)="ActivateState(element)">
													add_circle </span>
											</div>
										</div>
									</td>
								</ng-container>
								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
							</table>
						</ng-container>
					</div>
					<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
					</mat-paginator>
				</div>
				<ngx-loading [show]="loading"
					[config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
					[template]="loadingTemplate"></ngx-loading>

				<!-- <div class="container">
					<div class="row">
						<div class="">
							<div class="table-responsive">
								<table class="align-middle text-truncate mb-0 table table-borderless table-hover">
									<thead>
										<tr>
											<th class="text-center">State Name</th>
											<th class="text-center">Country</th>
											<th class="text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let con of states | async">
											<td class="text-center">
												<a [routerLink]="" (click)="viewsection('view',con)">
													{{con.name}}
												</a>
											</td>
											<td class="text-center">
												{{con.country.name}}
											</td>
											<td class="text-center">
												<div role="group" class="btn-group-sm btn-group">
													<button class="btn-shadow btn btn-primary" matTooltip="Edit"
														(click)="viewchange1('edit',con)"><i class="lnr-pencil"></i></button>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
									[showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
									[pageIndex]="pageIndex" aria-label="Select page">
								</mat-paginator>
							</div>
						</div>
					</div>
				</div> -->
			</div>
		</some-element>
		<some-element *ngSwitchCase="'add'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						<b style="color: blue;">ADD STATE</b>
					</div>

				</div>

				<div class="container">
					<div style="display: flex">
						<div style="margin-right: 15px">
							<mat-form-field appearance="standard">
								<mat-label>
									State Name <span class="text-danger">*</span>
								</mat-label>
								<input type="text" placeholder="State Name" aria-label="Number" matInput
									(keypress)="validateInput1($event)" [(ngModel)]="addstate.name">
							</mat-form-field>
						</div>
						<div style="margin-right: 15px">
							<mat-form-field appearance="standard">
								<mat-label>Country <span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl [(ngModel)]="addstate.country">
									<mat-option *ngFor="let con of countries |async" [value]="con.id">
										{{con.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div>
							<mat-form-field appearance="standard">
								<mat-label>
									Gst prefix code <span class="text-danger">*</span>
								</mat-label>
								<input type="text" placeholder="Gst prefix code" maxlength="2" aria-label="Number"
									matInput (keypress)="validateInput($event)" [(ngModel)]="addstate.gstPrefixCode">
							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="Createstate()">Add</div>
					</div>
				</div>
			</div>
		</some-element>

		<some-element *ngSwitchCase="'edit'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						<b style="color: blue;">EDIT STATE DETAILS</b>
					</div>

				</div>

				<div class="container">
					<div style="display: flex">
						<div style="margin-right: 15px">
							<mat-form-field appearance="standard">
								<mat-label>
									State Name <span class="text-danger">*</span>
								</mat-label>
								<input type="text" placeholder="State Name" aria-label="Number" matInput
									(keypress)="validateInput1($event)" [(ngModel)]="updatestate.name">

							</mat-form-field>
						</div>
						<div style="margin-right: 15px">
							<mat-form-field appearance="standard">
								<mat-label>Country <span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl id="" [(ngModel)]="updatestate.country">
									<mat-option *ngFor="let con of countries |async" [value]="con.id">
										{{con.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div>
							<mat-form-field appearance="standard">
								<mat-label>
									Gst prefix code <span class="text-danger">*</span>
								</mat-label>
								<input type="text" placeholder="Gst prefix code" maxlength="2" aria-label="Number"
									matInput (keypress)="validateInput($event)" [(ngModel)]="updatestate.gstPrefixCode">
							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="UpdateDState()">Save</div>
					</div>
				</div>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'view'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						View State
					</div>

				</div>

				<div class="container m-2">
					<div class="row">
						<div class="col-md-3 fw-bold">State Name</div>
						<div class="col-md-4"> {{viewstates.name}} </div>
					</div>
					<div class="row ">
						<div class="col-md-3 fw-bold">Country Name</div>
						<div class="col-md-4"> {{viewstates.country}} </div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Back</div>
					</div>
				</div>
			</div>
		</some-element>
	</container-element>
</div>
