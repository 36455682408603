import { HttpHeaders } from '@angular/common/http';
import {API_URL} from '../../../Global_API_URL'

export async function getCities(){
      const TOKEN = sessionStorage.getItem('accessToken')
    let cities=[];
    await fetch(API_URL+"/cities",{method: "GET",headers:{
      "Accept": "application/json",
      "Authorization":"Bearer "+TOKEN
     }})

          // Converting received data to JSON
          .then(response => response.json())
          .then(json => {
            cities =json;
            
      });
      return cities.data.sort((a, b) => a.name.localeCompare(b.name));
}

export async function getDropDownCities(){
  const TOKEN = sessionStorage.getItem('accessToken')
let cities=[];
await fetch(API_URL+"/dropdown/cities",{method: "GET",headers:{
  "Accept": "application/json",
  "Authorization":"Bearer "+TOKEN
 }})

      // Converting received data to JSON
      .then(response => response.json())
      .then(json => {
        cities =json;
  });
  return cities.data.content;
}

export async function getDropDownCustomers(){
  const TOKEN = sessionStorage.getItem('accessToken')
  let customers=[];
  await fetch(API_URL+"/dropdown/customers",{method: "GET",headers:{
  "Accept": "application/json",
  "Authorization":"Bearer "+TOKEN
  }})
      // Converting received data to JSON
      .then(response => response.json())
      .then(json => {
        customers =json;
  });
  return customers.data.content;
}

export async function getCityByState(id){
  const TOKEN = sessionStorage.getItem('accessToken')
  let cities=[];
  await fetch(API_URL+"/getCityByState/"+id,{method: "GET",headers:{
      "Accept": "application/json",
      "Authorization":"Bearer "+TOKEN,
      "X-Active":"true"
    }})

    // Converting received data to JSON
    .then(response => response.json())
    .then(json => {
      cities =json;
    });
  return cities.data;
}
export async function getCitiesById(id){
  // console.log("cities----->",id)
      const TOKEN = sessionStorage.getItem('accessToken')
      let cities=[];
      if(id!=null){
      await fetch(API_URL+"/state/"+id+"/cities",{method: "GET",headers:{
        "Accept": "application/json",
      //   "Authorization":"Bearer "+TOKEN
      "X-Active":"true"
       }})

            // Converting received data to JSON
            .then(response => response.json())
            .then(json => {
              cities =json;
        });
      }
        return cities.data;
  }

export async function getStates(){
      const TOKEN = sessionStorage.getItem('accessToken')
    let states=[];
    await fetch(API_URL+"/states",{method: "GET",headers:{
      "Accept": "application/json",
       "Authorization":"Bearer "+TOKEN,
       "X-Active":"true"
     }})

          // Converting received data to JSON
          .then(response => response.json())
          .then(json => {
            states =json;
      });
      return states.data;

}
export async function getStatesById(id){
      const TOKEN = sessionStorage.getItem('accessToken')
      let states=[];
      if(id!=null){

            await fetch(API_URL+"/country/"+id+"/states",{method: "GET",headers:{
                  "Accept": "application/json",
                  // "Authorization":"Bearer "+TOKEN
                  "X-Active":"true"

            }})

            // Converting received data to JSON
            .then(response => response.json())
            .then(json => {
                  states =json;
            });
      }
        return states.data;

  }

export async function getCountries(){
      const TOKEN = sessionStorage.getItem('accessToken')
    let countries=[];
    await fetch(API_URL+"/countries",{
          method: "GET",
          headers:{
            "Accept": "application/json",
            // "Authorization":"Bearer "+TOKEN
           "X-Active":"true"
      }})

          // Converting received data to JSON
          .then(response => response.json())
          .then(json => {
            countries =json;
      });
      return countries.data;
}

export async function getPincodesById(id){
      const TOKEN = sessionStorage.getItem('accessToken')
      let pincode=[];
      // console.log("Pincode id---->", id)
      if(id!=null){
      await fetch(API_URL+"/pincode/cities/"+id,{method: "GET",headers:{
        "Accept": "application/json",
        "Content-Type": "application/json",
      //   "Authorization":"Bearer "+TOKEN
      "X-Active":"true"
       }})

            // Converting received data to JSON
            .then(response => response.json())
            .then(json => {
              pincode =json;
        });
      }
  // console.log("Pincode data---->", pincode.data)
        return pincode.data;
  }

  export async function getPincodes(){
      const TOKEN = sessionStorage.getItem('accessToken')
    let pincodes=[];
    await fetch(API_URL+"/AllPincodes",{method: "GET",headers:{
      "Accept": "application/json",
       "Authorization":"Bearer "+TOKEN
     }})

          // Converting received data to JSON
          .then(response => response.json())
          .then(json => {
            pincodes =json;
      });
      return pincodes.data;

}
