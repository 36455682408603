import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';

import {BillingService} from '../../../modules/billingInfo/service/billing.service';
import {Location} from '@angular/common';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-cost-center1',
  templateUrl: './cost-center.component.html',
  styleUrls: ['./cost-center.component.sass'],
})
export class CostCenterComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  consignment_id;

  constructor(
    private ActivatedRoute: ActivatedRoute,
    private route: Router,
    public commonApi: BillingService,
    private locationService: Location,
  ) {
    this.ActivatedRoute.params.subscribe((params) => {
      this.consignment_id = params['id'];
    });
  }

  userDetails;
  displayToAdmin = false;
  displayToSupervisor = false;

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0] == 'Admin') {
      this.displayToAdmin = true;
    }
    if (this.userDetails.userTypes[0] == 'Supervisor') {
      this.displayToSupervisor = true;
    }
    this.commonApi.getBillingLineItems();
    this.commonApi.getBillingPriceByConsignmentId(this.consignment_id)
        .subscribe((response) => {
          // this.commonApi.ModeBasedBillingLineItems();
          this.commonApi.calculateGrandTotal();

          this.commonApi.getCostCenterPriceByConsignmentId(this.consignment_id)
              .subscribe((response) => {
                this.commonApi.calculateTaxAndInsurance();
                this.commonApi.calculateCostGrandTotal();
              });
        });


    setTimeout(() => {
      this.loading = false; // Set loading to false after 2 seconds
    }, 2000);
  }

  backToConsignmentView() {
    this.locationService.back();
    // this.route.navigate(['/viewconsignment', this.commonApi.BillingPriceLists.consignment?.consignment_no])
  }

  costCenterEditPage() {
    this.route.navigate(['/costCenterEdit/', this.consignment_id]);
  }
}
