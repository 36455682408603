import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Router, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ExpenseGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;

    return this.checkLogin(route, url);
  }
  checkLogin(route: ActivatedRouteSnapshot, url: string): true | UrlTree {
    // console.log("Url: " + url);
    const val: string = localStorage.getItem('isUserLoggedIn');
    let user:any = localStorage.getItem('userDetails');
    user = JSON.parse(user);
    const userRole= user?.userTypes[0].name;
    if (val != null && val == 'true') {
      if (url == '') {
        this.router.parseUrl('/dashboard');
      } else {
        if (route.data.roles && route.data.roles.indexOf(userRole)=== -1) {
          // console.log(route.data.roles+" inside expense guarde");
          // console.log("user role :"+ userRole);
          this.router.navigate(['/404']);
        }
        // let loc =localStorage.getItem("kc-callback-6fadd863-9643-45b0-b78d-1bee7209e846");
        // let locf= JSON.parse(loc)
        // window.location.href = locf.redirectUri;
        return true;
      }
    } else {
      return this.router.parseUrl('');
    }
  }
}
