import {Component, OnInit, ViewChild} from '@angular/core';
import {API_URL} from 'Global_API_URL.js';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import Swal from 'sweetalert2';
import {AuthService} from '../../auth.service';


@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.sass'],
})
export class ControlPanelComponent implements OnInit {
  LeadLagList = null;
  constructor(private _liveAnnouncer: LiveAnnouncer, private authService : AuthService) {
    this.authService.getAuthToken();
  }

  pageview = 'table';
  leadlagtimes2 = this.getLeadLagTimes();

  newleadlagtimes = {
    id: null,
    productType: null,
    mode: null,
    type: null,
    origin_city: null,
    time: null,
    is_active: null,
  }

  viewchange(val) {
    this.pageview = val;
    this.newleadlagtimes = {
      id: null,
      productType: null,
      mode: null,
      type: null,
      origin_city: null,
      time: null,
      is_active: 1,
    };
  }

  viewchange1(val, time) {
    this.newleadlagtimes.id = time.id;
    this.newleadlagtimes.mode = time.mode;
    this.newleadlagtimes.origin_city = time.origin_city;
    this.newleadlagtimes.productType = time.productType;
    this.newleadlagtimes.time = time.time;
    this.newleadlagtimes.type = time.type;
    this.pageview = val;
  }

  ngOnInit(): void {
    // this.LeadLagList = this.getLeadLagTimes();
    this.leadlagtimes2 = this.getLeadLagTimes2();
  }
  productType:any='';
  mode:any='';
  type:any='';
  origin_city:any='';
  viewleadlagtimes = {
    productType: null,
    mode: null,
    type: null,
    time: null,
  }
  async reset() {
    this.productType='';
    this.mode='';
    this.type='';
    this.origin_city='';
    this.tablefilter();
  }
  viewsection(val, time) {
    this.viewleadlagtimes.mode = time.mode;
    this.viewleadlagtimes.productType = time.productType;
    this.viewleadlagtimes.time = time.time;
    this.viewleadlagtimes.type = time.type;
    this.pageview = val;
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  showFirstLastButtons;

  filterParams='';
  pagenation= {
    col_name: 'id',
    page_size: 20,
    page_no: 0,
    sort_dir: 'ASC',
  }

  pageSizeOptions=[20, 30];

  tablefilter() {
    this.pagenation.page_no=0;
    this.pagenation.sort_dir='ASC';
    this.getLeadLagTimes2();
  }
  filter() {
    this.filterParams='?';
    if (this.productType!='') {
      this.filterParams = this.filterParams + 'product_type=' + this.productType + '&';
    }
    if (this.mode!='') {
      this.filterParams = this.filterParams + 'mode=' + this.mode + '&';
    }
    if (this.type!='') {
      this.filterParams = this.filterParams + 'type=' + this.type + '&';
    }
    if (this.origin_city!='') {
      this.filterParams = this.filterParams + 'origin_city=' + this.origin_city + '&';
    }
    // this.filterParams= this.filterParams+"page_no="+this.pagenation.page_no+"&page_size="+this.pagenation.page_size+"&col_name="+this.pagenation.col_name+"&sort_dir="+this.pagenation.sort_dir
    return this.filterParams;
  }
  handlePageEvent(event: PageEvent) {
    this.pagenation.page_no = event.pageIndex;
    this.pagenation.page_size=event.pageSize;
    this.getLeadLagTimes2();
  }
  displayedColumns = ['product_type', 'mode', 'type', 'origin_city', 'time', 'updated_at', 'Active']
  getLeadLagTimes() {
    const TOKEN = this.authService.getAuthToken();
    this.LeadLagList = [];
    // console.log(API_URL)
    const api= '/lead_lag_times2';
    const params = this.filter();
    fetch(API_URL + api+params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          this.LeadLagList = json;
        });
    return this.LeadLagList;
  }

  dataSource
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  array=[]
  async getLeadLagTimes2() {
    const TOKEN = await this.authService.getAuthToken();

    // console.log(TOKEN)
    // console.log(API_URL)

    const api = '/leadLagTimesfilters';
    const params = await this.filter();
    await fetch(API_URL + api + params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })

        // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          if (json.status == 400) {
            this.LeadLagList = [];
          } else {
            this.LeadLagList = json.data;
            this.array = Array.from(json.data);
            this.dataSource = new MatTableDataSource(json.data);
            // this.paginator =
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        });
    return this.LeadLagList;
    // return loads;
  }

  editLeadLagTimes() {
    const TOKEN = this.authService.getAuthToken();
    const users={
      'id': this.newleadlagtimes.id,
      'product_type': this.newleadlagtimes.productType,
      'mode': this.newleadlagtimes.mode,
      'type': this.newleadlagtimes.type,
      'origin_city': this.newleadlagtimes.origin_city,
      'time': this.newleadlagtimes.time,
      'is_active': true,

    };
    //  console.log("this is city"+this.newleadlagtimes.id)

    fetch(API_URL +'/editleadlagtimes/'+this.newleadlagtimes.id, {
      method: 'POST',
      body: JSON.stringify(users),
      headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
        .then((response) => response.json())
        .then((result) =>{
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Updated successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.viewchange('table');
          this.getLeadLagTimes2();
        })

        .catch((error) => console.error('error', error));
  }
}
