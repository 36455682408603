import {Component, OnInit} from '@angular/core';
import {API_URL} from '../../../../../Global_API_URL';
import {FormControl} from '@angular/forms';
import Swal from 'sweetalert2';
import {Router} from "@angular/router";
import {HttpService} from "../../../services/http.service";

@Component({
  selector: 'app-table-regions',
  templateUrl: './table-regions.component.html',
  styleUrls: ['./table-regions.component.sass'],
})
export class TableRegionsComponent implements OnInit {
  constructor(private httpService: HttpService, private router: Router) {}


  selectedStatesArray: FormControl[] = [];
  userDetails;
  displaytoAccountant= false;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name=='Accountant') {
      this.displaytoAccountant=true;
    }
    // this.getStatesData();
    this.getState();
    this.getRegions();
  }

  pageview = 'table';

  regions;

  state: any[] = [];
  state_id: any;
  citiesData = [
    {region: 'North', cities: ['Andaman and Nicobar Islands', 'Andhra Pradesh', 'Arunachal Pradesh']},
    {region: 'East', cities: ['Assam', 'Bihar', 'Chandigarh']},
    {region: 'West', cities: ['Chhattisgarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Delhi']},
    {region: 'South', cities: ['Goa', 'Gujarat', 'Haryana']},
    {region: 'North-East', cities: ['Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand']},
    {region: 'South-East', cities: ['Karnataka', 'Kerala', 'Ladakh']},
    {region: 'South-West', cities: ['Lakshadweep', 'Madhya Pradesh', 'Maharashtra']},
    {region: 'North-West', cities: ['Manipur', 'Rajasthan', 'Uttarakhand']},
  ];

  selectedStates = this.citiesData;

  isEditMode = false;
  toggleEditMode() {
    this.router.navigate(['/regions/edit']);
  }

  getState() {
    return this.httpService
        .get(API_URL + '/states', null, null)
        .subscribe((data: any) => {
        // console.log(data.data);
          if (data) {
            for (const i of data.data) {
              this.state.push(i.name);
            // console.log(this.state);
            }
          }
        });
  }

  getRegions() {
    this.httpService.get(API_URL + '/regions', null, null).subscribe(response => {
      this.regions = response.data;
    })
  }

  getStatesData() {
    this.citiesData.forEach((con) => {
      const citiesForRegion = con.cities;
      this.selectedStatesArray.push(new FormControl(citiesForRegion));
    });
  }


  removeState(regionIndex: number, state: string): void {
    const selectedStatesControl = this.selectedStatesArray[regionIndex];

    if (selectedStatesControl) {
      const index = selectedStatesControl.value.indexOf(state);

      if (index !== -1) {
        const updatedStates = [...selectedStatesControl.value];
        updatedStates.splice(index, 1);
        selectedStatesControl.setValue(updatedStates);
        const citiesDataIndex = this.citiesData[regionIndex]?.cities?.indexOf(state);
        if (citiesDataIndex !== -1) {
          this.citiesData[regionIndex].cities.splice(citiesDataIndex, 1);
        }
      }
    }
  }


  saveData() {
    const selectedData = [];
    this.citiesData.forEach((con, regionIndex) => {
      const selectedStates = this.selectedStatesArray[regionIndex]?.value || [];
      selectedData.push({
        region: con.region,
        selectedStates: [...selectedStates],
      });
    });
    // console.log("Selected Data:", selectedData);
  }

  discardChanges() {
    this.selectedStatesArray.forEach((control) => {
      control.reset();
    });
  }


  checkAndSelectOption(event: any, regionIndex: number, selectedState: string): void {
    debugger;
    const selectedStatesControl = this.selectedStatesArray[regionIndex];
    let isStateDuplicate = false;
    this.citiesData.forEach((region, index) => {
      if (region.cities.includes(selectedState) && index !== regionIndex) {
        isStateDuplicate = true;
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Selected state is already present in another region.',
          position: 'center',
          timer: 5000,


        });
      }
    });

    if (!isStateDuplicate) {
      const isStateInCitiesData = this.citiesData[regionIndex]?.cities?.includes(selectedState);

      if (isStateInCitiesData) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Selected state is already present in the region.',
          position: 'center',
          timer: 5000,
        });

        event.source.deselect();
      } else {
        this.citiesData[regionIndex].cities.push(selectedState);
        // console.log(selectedStatesControl.value,'third')
      }
    } else {
      const updatedStates = selectedStatesControl.value.filter((state) => state !== selectedState);
      selectedStatesControl.setValue(updatedStates);
      event.source.deselect();
    }
  }
}
