import {Component, OnInit, ViewChild} from '@angular/core';
import {getCities, getCitiesById, getCountries, getPincodesById, getStatesById,getDropDownCities} from '../../getCities.js';
import {API_URL} from '../../../../../Global_API_URL';
import Swal from 'sweetalert2';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatTableDataSource} from '@angular/material/table';
import {Cities} from '../../golbalInterfaces.js';
import {FormControl, Validators} from '@angular/forms';
import {AuthService} from '../../../auth.service';
import {HttpService} from "../../../services/http.service";
import {HttpHeaders} from "@angular/common/http";

@Component({
  selector: 'app-office-location',
  templateUrl: './office-location.component.html',
  styleUrls: ['./office-location.component.sass'],
})
export class OfficeLocationComponent implements OnInit {
  panregex = /^[a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][0-9][0-9][0-9][0-9][a-zA-Z]$/
  PanFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.panregex),
  ]);

  gstregex = /^[0-9][0-9][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][0-9][0-9][0-9][0-9][a-zA-Z][0-9a-zA-Z][a-zA-Z][0-9a-zA-Z]$/
  gstFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.gstregex),
  ]);

  constructor(private _liveAnnouncer: LiveAnnouncer, private authService: AuthService, private httpService: HttpService) {
    this.authService.getAuthToken();
  }

  office_locations = this.getOfficeLocations();
  loading = false;
  pageview = 'table';
  states = getStatesById();
  countries = getCountries();
  cities = getDropDownCities();
  // filtercities = getCities();

  OfficesList = null;

  displayedColumns = ['name', 'address', 'location', 'billingcode', 'gstnumber', 'Active']
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  showFirstLastButtons;
  pageSizeOptions = [10, 20, 30];
  userDetails;
  displaytoAccountant = false;

  ngOnInit(): void {
    this.getOfficeLocations();
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name == 'Accountant') {
      this.displaytoAccountant = true;
    }
  }

  updateoffice = {
    id: null,
    name: null,
    address: null,
    city: null,
    state_id: null,
    country: null,
    is_active: null,
    gstNumber: null,
    panNumber: null,
    billingCode: null,
    branchCode: null,
  }

  viewchange(val) {
    this.pageview = val;
    this.getOfficeLocations();
  }

  initialBillingCode;

  async viewchange1(val, off) {
    await this.getOfficeLocations();
    this.updateoffice = off;
    this.business_sel_country = off.city.state_id.country.id;
    this.business_sel_state = off.city.state_id.id;
    this.new_office.city.id = off.city?.id;
    this.initialBillingCode = off.billingCode;
    this.business_states = getStatesById(this.business_sel_country);
    this.business_cities = getCitiesById(this.business_sel_state);
    this.business_pincodes = getPincodesById(this.new_office.city.id);


    this.pageview = val;
  }

  new_office = {
    address: null,
    is_active: true,
    name: null,
    city: {id: null},
    state: null,
    country: null,
    gst_number: '',
    pan_number: '',
    billing_code: '',
    branch_code: '',

  }

  business_cities
  business_states
  business_pincodes
  business_sel_country
  business_sel_state
  business_sel_pincodes


  async getBusinessStates() {
    this.business_states = getStatesById(this.business_sel_country);
    this.business_sel_state = null;
    this.new_office.city.id = null;
  }

  async getBusinessCities() {
    this.business_cities = getCitiesById(this.business_sel_state);
    this.new_office.city.id = null;
  }

  async getBusinessPincodes() {
    this.business_pincodes = getPincodesById(this.new_office.city.id);
  }

  async createOffice() {
    const TOKEN = this.authService.getAuthToken();
    if (this.new_office.name == null || this.new_office.name == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter the Branch name',
        // showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else if (this.new_office.branch_code == null || this.new_office.branch_code == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter the Branch Code',
        // showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else if (this.billingCodeExist) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'BillingCode Already Exists',
        timer: 5000,
      })
      this.loading = false;
    } else if (this.new_office.address == null || this.new_office.address == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter the Office Address',
        // showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else if (this.business_sel_country == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select the Country',
        timer: 5000,

      });
    } else if (this.business_sel_state == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select the State',
        timer: 5000,

      });
    } else if (this.new_office.city.id == null || this.new_office.city.id == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select the Office City',
        timer: 5000,


      });
    } else if (this.gstFormControl.hasError('pattern')) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter a Valid GST Number',
        timer: 5000,

      });
    } else if (this.PanFormControl.hasError('pattern')) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter a Valid PAN Number',
        timer: 5000,

      });
    }
      // else if(this.new_office.gstNumber == null || this.new_office.gstNumber == ""){
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'ERROR',
      //     text: "Please Enter the GST NUMBER"
      //   })
      // }
      // else if(this.new_office.pan_number == null || this.new_office.pan_number == ""){
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'ERROR',
      //     text: "Please Enter the PAN Number"
      //   })
      // }

      // else if (!(this.gstregex.test(this.new_office.gst_number))) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'ERROR',
      //     text: "Please Enter Valid GST No"
      //   });
      // }
      // else if (!(this.panregex.test(this.new_office.pan_number))) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'ERROR',
      //     text: "Please Enter Valid PAN No"
      //   });
      // }

      // const billingCodeExists = this.OfficesList.some(office => office.billing_code !== this.new_office.billing_code);
      // // console.log("billing_code",billingCodeExists);
      // if (billingCodeExists) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'ERROR',
      //     text: 'Billing code already exists',
      //   });
      //   this.loading = false;
      //
    // }

    else {
      await fetch(API_URL + '/office_locations',
        {
          method: 'POST',
          body: JSON.stringify(this.new_office),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Office Location Created successfully',
            timer: 5000,
          }).then(() => {
            this.new_office = {
              address: null,
              is_active: true,
              name: null,
              city: {id: null},
              state: null,
              country: null,
              gst_number: '',
              pan_number: '',
              billing_code: '',
              branch_code: '',

            };
            this.business_sel_country = null;
            this.business_sel_state = null;
            this.business_cities = null;
            this.office_locations = this.getOfficeLocations();
            this.viewchange('table');
          });
        })
        .catch((error) => {
          console.error('Error', error);
          alert(error);
        });
    }
  }

  // billingCodeExist = false;
  // async billingCodeExisForOffice() {
  //   const TOKEN = this.authService.getAuthToken();
  //   if (this.initialBillingCode != this.new_office.billing_code){
  //     await fetch(API_URL + '/office_locations/billingCode/' + this.new_office.billing_code,
  //       {
  //         method: 'GET',
  //         headers: {
  //           'Accept': 'application/json',
  //           'Authorization': 'Bearer ' + TOKEN,
  //           'Content-Type': 'application/json',
  //         },
  //       })
  //       .then((response) => response.json())
  //       .then((result) => {
  //         if (result?.data[0] == null) {
  //           this.billingCodeExist = false;
  //         } else {
  //           this.billingCodeExist = true;
  //           Swal.fire({
  //             icon: 'error',
  //             title: 'ERROR',
  //             text: 'BillingCode Already Exists',
  //             timer: 5000,
  //           });
  //         }
  //         console.log("User Exist");
  //         // alert("CustomerExist "+this.emailExist);
  //       })
  //       .catch((error) => {
  //         this.billingCodeExist = false;
  //         console.log('Error' + error);
  //       });
  //   }else{
  //     this.billingCodeExist = false;
  //   }
  //
  // }

  billingCodeExist = false;

  async billingCodeExistForOfficeEdit() {
    if (this.initialBillingCode != this.updateoffice.billingCode) {
      const headers = new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      this.httpService.get(API_URL + '/office_locations/billingCode/' + this.updateoffice.billingCode, null, headers)
        .subscribe(response => {
          this.billingCodeExist = true;
          Swal.fire({
            icon: 'error',
            title: 'ERROR',
            text: 'BillingCode Already Exists',
            timer: 5000,
          });
        }, error => {
          this.billingCodeExist = false;
        })
    } else {
      this.billingCodeExist = false;
    }

  }

  async billingCodeExisForOfficeCreate() {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    })
    this.httpService.get(API_URL + '/office_locations/billingCode/' + this.new_office.billing_code, null, headers)
      .subscribe(response => {
        this.billingCodeExist = true;
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'BillingCode Already Exists',
          timer: 5000,
        });
      }, error => {
        this.billingCodeExist = false;
      })

  }

  refreshPage() {
    window.location.reload();
  }

  array = []

  async getOfficeLocations() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/office_locationfilters',
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
      .then((response) => response.json())
      .then((json) => {
        if (json.status == 400) {
          this.OfficesList = [];
        } else {
          this.OfficesList = json.data.reverse();
          this.array = Array.from(json.data);
          this.dataSource = new MatTableDataSource(json.data);
          // this.paginator =
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      });
    return this.OfficesList;
  }

  async UpdateOffice() {
    const TOKEN = this.authService.getAuthToken();
    const office = {
      'name': this.updateoffice.name,
      'address': this.updateoffice.address,
      'country': this.updateoffice.country,
      'state_id': this.updateoffice.state_id,
      'city': {id: this.new_office.city.id},
      'is_active': 1,
      'gst_number': this.updateoffice.gstNumber,
      'pan_number': this.updateoffice.panNumber,
      'billing_code': this.updateoffice.billingCode,
      'branch_code': this.updateoffice.branchCode,
    };
    if (this.updateoffice.name == null || this.updateoffice.name == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter the Office name',
        // showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else if (this.updateoffice.address == null || this.updateoffice.address == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter the Office Address',
        // showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else if (this.updateoffice.branchCode == null || this.updateoffice.branchCode == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter the Branch Code',
        // showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else if (this.billingCodeExist) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'BillingCode Already Exists',
        timer: 5000,
      })
      this.loading = false;
    }

      // else if (this.updateoffice.billingCode == null || this.updateoffice.billingCode == "") {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'ERROR',
      //     text: 'Please Enter the Billing Code',
      //     // showConfirmButton: false,
      //     // timer: 1500
      //   })
      //   this.loading = false;
      // }
      // else if (this.updateoffice.country == null || this.updateoffice.country == "") {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'ERROR',
      //     text: 'Please Select the Office Country',
      //     // showConfirmButton: false,
      //     // timer: 1500
      //   })
      //   this.loading = false;
      // }
      // else if (this.updateoffice.state_id == null || this.updateoffice.state_id == "") {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'ERROR',
      //     text: 'Please Select the Office State',
      //     // showConfirmButton: false,
      //     // timer: 1500
      //   })
      //   this.loading = false;
    // }

    else if (this.new_office.city.id == null || this.new_office.city.id == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select the Office State and City',
        // showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else if (this.gstFormControl.hasError('pattern')) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter a Valid GST Number',
        timer: 5000,

      });
    } else if (this.PanFormControl.hasError('pattern')) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter a Valid PAN Number',
        timer: 5000,

      });
    }
      // else if (!(this.gstregex.test(this.updateoffice.gstNumber))) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'ERROR',
      //     text: "Please Enter Valid GST No"
      //   });
      // }
      // else if (!(this.panregex.test(this.updateoffice.panNumber))) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'ERROR',
      //     text: "Please Enter Valid PAN No"
      //   });
      // }
      // const billingCodeExists = this.OfficesList.some(office => office.billing_code !== this.new_office.billing_code);
      // // console.log("billing_code",billingCodeExists);
      // if (billingCodeExists) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'ERROR',
      //     text: 'Billing code already exists',
      //   });
      //   this.loading = false;
      //
    // }
    else {
      await fetch(API_URL + '/office_locationsupdate/' + this.updateoffice.id, {
        method: 'PATCH',
        body: JSON.stringify(office),
        headers: {
          'Authorization': 'Bearer ' + TOKEN,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((result) => {
          // console.log(result)
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Office Location Updated successfully',
            timer: 5000,
          }).then(() => {
            this.viewchange('table');
            // this.refreshPage();
          })

          // this.updateoffice.name="";
          // this.updateoffice.address ="";
          // this.updateoffice.city = null
        })
        .catch((error) => console.error('error', error));
      this.getOfficeLocations();
    }
  }

  async billingCodeExistsOffice() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/office_locations/billingCode/' + this.updateoffice.billingCode,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((result) => {
        // console.log("User Exist");
        if (result.data[0] == null) {
          this.billingCodeExist = false;
        } else {
          this.billingCodeExist = true;
          Swal.fire({
            icon: 'error',
            title: 'ERROR',
            text: 'BillingCode Already Exists',
            timer: 5000,

          });
        }
        // alert("CustomerExist "+this.emailExist);
      })
      .catch((error) => {
        console.log('Error' + error);
      });
  }

  // async getCities() {
  //   try {
  //     this.filtercities=  getCities();
  //   } catch (error) {
  //     console.error('Error fetching cities:', error);
  //   }
  // }


  filterUsersList = [];
  corporate = null;
  filterParams = '';
  corporatefilters: any = {
    name: '',
    billingCode: '',
    cityid: '',
  }
  pagenation = {
    col_name: 'id',
    page_size: 10,
    page_no: 0,
    sort_dir: 'DESC',
  }


  filter() {
    const params = [];

    if (this.corporatefilters.name.toLowerCase()) {
      params.push(`name=${this.corporatefilters.name}`);
    }

    if (this.corporatefilters.billingCode) {
      params.push(`billingCode=${this.corporatefilters.billingCode}`);
    }


    if (this.corporatefilters.cityid) {
      // console.log(this.corporatefilters.cityid.id);
      params.push(`city=${this.corporatefilters.cityid.id}`);
    }

    return params.length > 0 ? '?' + params.join('&') : '';
  }


  find() {
    this.filterscorporate();
  }

  reset() {
    this.search = '';
    this.search2 = '';
    this.corporatefilters.name = '';
    this.corporatefilters.billingCode = '';
    this.corporatefilters.cityid = '';
    this.filterscorporate();
  }

  displayCityFn(city: Cities): String {
    return city?.name ? city?.name : '';
  }


  async filterscorporate() {
    const TOKEN = this.authService.getAuthToken();
    const params = this.filter(); // Ensure this method correctly forms the query parameters

    // console.log("params",params);
    const api = '/office_locationfilters' + params; // Update to append query params to the API URL

    try {
      const response = await fetch(API_URL + api, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      });

      if (response.ok) {
        const json = await response.json();
        if (json.status === 400) {
          this.corporate = [];
        } else {
          this.corporate = json.data;
          this.filterUsersList = json.data.reverse();

          this.dataSource = new MatTableDataSource(this.filterUsersList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  search

  searchByCons(con) {
    // console.log("billingCode",con.billingCode);
    this.dataSource = new MatTableDataSource(this.filterUsersList.filter((cons) => cons.billingCode.includes(con)));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search2

  searchByCons2(con) {
    // console.log(con,this.filterUsersList)
    this.dataSource = new MatTableDataSource(this.filterUsersList.filter((cons) => cons.cityid.includes(con)));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}


