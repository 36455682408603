<div class="h-100">
  <div class="row h-100 g-0">
    <div class="row">
      <div class="d-none d-lg-block col-4 col-md-2 col-lg-4 ">
        <div class="slider-light">
          <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig2">
            <div ngxSlickItem>

              <div class="bg-plum-plate" style="height: 100vh;">
                <div class="p-5 d-flex flex-column justify-content-between h-100">
                  <div class="h4 m-1 text-center fw-bold text-white mt-3">
                    ICL AIR CARGO
                  </div>
                  <div class="m-3 d-flex flex-row align-items-center">

                    <div class="" style="width: 25%;">
                      <img class="m-auto" src="./assets/images/Time.png" style="width: 80px" />
                      <div class="text-center fw-bold text-white">
                        ICL-QUICK
                      </div>
                    </div>
                    <div class="p-1" style="width: 75%; height: 15vh;">
                      <div class="slide p-2 d-flex justify-content-center align-items-center"
                        style="border-radius: 10px; height:inherit; background: linear-gradient(284.01deg, rgba(255, 255, 255, 0.4) 27.45%, rgba(255, 255, 255, 0.6) 100.05%);">
                        <div class="" style="color: black;">
                          A NFO product offering flavors of Door pickup and delivery services.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="m-3 d-flex flex-row align-items-center">

                    <div class="" style="width: 25%;">
                      <img class="m-auto" src="./assets/images/Flight.png" style="width: 80px" />
                      <div class="text-center fw-bold text-white">
                        ICL-ACE
                      </div>
                    </div>
                    <div class="p-1" style="width: 75%; height: 15vh;">
                      <div class="slide-i p-2 d-flex justify-content-center align-items-center"
                        style="border-radius: 10px; height:inherit; background: linear-gradient(284.01deg, rgba(255, 255, 255, 0.4) 27.45%, rgba(255, 255, 255, 0.6) 100.05%);">
                        <div class="" style="color: black;">
                          A 24-to-48-hour air express service
                          offering reliable transportation of
                          shipments by commercial airlines.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="m-3 d-flex flex-row align-items-center">

                    <div class="" style="width: 25%;">
                      <img class="m-auto" src="./assets/images/Cube.png" style="width: 80px" />
                      <div class="text-center fw-bold text-white">
                        ICL-PRUDENT
                      </div>
                    </div>
                    <div class="p-1" style="width: 75%; height: 15vh;">
                      <div class="slide-img p-2 d-flex justify-content-center align-items-center"
                        style="border-radius: 10px; height:inherit; background: linear-gradient(284.01deg, rgba(255, 255, 255, 0.4) 27.45%, rgba(255, 255, 255, 0.6) 100.05%);">
                        <div class="" style="color: black;">
                          A multimodal service offering superior
                          transit times at economical prices using
                          a combination of Ground and Air Networks.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="m-3 d-flex flex-row align-items-center">

                    <div class="" style="width: 25%;">
                      <img class="m-auto" src="./assets/images/Flight1.png" style="width: 80px" />
                      <div class="text-center fw-bold text-white">
                        ICL-GEM
                      </div>
                    </div>
                    <div class="p-1" style="width: 75%; height: 15vh;">
                      <div class="slide-im p-2 d-flex justify-content-center align-items-center"
                        style="border-radius: 10px; height:inherit; background: linear-gradient(284.01deg, rgba(255, 255, 255, 0.4) 27.45%, rgba(255, 255, 255, 0.6) 100.05%);">
                        <div class="" style="color: black;">
                          An air express offering for high value
                          shipments such as gem & jewelry with
                          real time tracking and tech-secured
                          delivery.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="heading">
                <p>ICL AIR CARGO</p>
              </div>
              <div class="icons ">
                <img src="./assets/images/Cube.png" style="width: 90px;" />
              </div>
              <div class="icons1 ">
                <img src="./assets/images/Flight.png" style="width: 90px;" />
              </div>
              <div class="icons2 ">
                <img src="./assets/images/Time.png" style="width: 90px;" />
              </div>
              <div class="icons3 ">
                <img src="./assets/images/Flight1.png" style="width: 90px;" />
              </div>
              <div class="text">
                <p>ICL-QUICK</p>
              </div>
              <div class="text1">
                <p>ICL-ACE</p>
              </div>
              <div class="text2">
                <p>ICL-PRUDENT</p>
              </div>
              <div class="text3">
                <p>ICL-GEM</p>
              </div>
              <div class="slide-img-bg"></div>
              <div class="slide-img-bgs"></div>
              <div class="slide-img-bgss"></div>
              <div class="slide-img-bgsss"></div>

              <div class="slider-content text-bold" style="color: black;">
                <p>
                  A NFO product offering flavors of Door
                  pickup and delivery services.
                </p>
              </div>
              <div class="slider-contents text-bold" style="color: black;">
                <p>
                  A 24-to-48-hour air express service
                  offering reliable transportation of
                  shipments by commercial airlines.
                </p>
              </div>
              <div class="slider-contentss text-center" style="color: black;">
                <p>
                  A multimodal service offering superior
                  transit times at economical prices using
                  a combination of Ground and Air Networks.
                </p>
              </div>
              <div class="slider-contentsss text-center" style="color: black;">
                <p>
                  An air express offering for high value
                  shipments such as gem & jewelry with
                  real time tracking and tech-secured
                  delivery.
                </p>
              </div> -->
              </div>
            </div>
            <div ngxSlickItem>
              <div class="position-relative h-100  justify-content-center align-items-center bg-premium-dark">
                <div class="p-5 d-flex flex-column justify-content-between h-100">
                  <div class="h4 m-1 text-center fw-bold text-white mt-3">
                    ICL GROUND
                  </div>
                  <div class="m-3 d-flex flex-row align-items-center">

                    <div class="" style="width: 25%;">
                      <img class="m-auto" src="./assets/images/FTL.png" style="width: 100px" />
                      <div class="text-center fw-bold text-white">
                        FTL
                      </div>
                    </div>
                    <div class="p-1" style="width: 75%; height: 15vh;">
                      <div class="slide p-2 d-flex justify-content-center align-items-center"
                        style="border-radius: 10px; height:inherit; background: linear-gradient(284.01deg, rgba(255, 255, 255, 0.4) 27.45%, rgba(255, 255, 255, 0.6) 100.05%);">
                        <div class="" style="color: black;">
                          Dedicated transportation faster than PTL
                          coupled with an assured transit time.
                          Serving offering also has a choice of highly secured operation at a premium.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="m-3 d-flex flex-row align-items-center">

                    <div class="" style="width: 25%;">
                      <img class="m-auto" src="./assets/images/PTL.png" style="width: 100px" />
                      <div class="text-center fw-bold text-white">
                        PTL
                      </div>
                    </div>
                    <div class="p-1" style="width: 75%; height: 15vh;">
                      <div class="slide-im p-2 d-flex justify-content-center align-items-center"
                        style="border-radius: 10px; height:inherit; background: linear-gradient(284.01deg, rgba(255, 255, 255, 0.4) 27.45%, rgba(255, 255, 255, 0.6) 100.05%);">
                        <div class="" style="color: black;">
                          A service offering of reliable transportation for your small parcels by ground.
                          The service includes a door pickup and door delivery.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <div></div>
                </div>
                <!-- </div>
                </div> -->
                <!-- <div class="heading1">
                <p>ICL GROUND</p>
              </div>
              <div class="slide-img-bg-2"></div>
              <div class="slide-img-bg-3"></div>
              <div class="icons4 ">
                <img src="./assets/images/FTL.png" style="width: 100px;" />
              </div>
              <div class="text4">
                <p>FTL</p>
              </div>
              <div class="icons5 ">
                <img src="./assets/images/PTL.png" style="width: 100px;" />
              </div>
              <div class="text5">
                <p>PTL</p>
              </div>
              <div class="slider-content-2 text-center" style="color: black;">
                <p>
                  Dedicated transportation faster than PTL
                  coupled with an assured transit time.
                  Serving offering also has a choice of highly secured operation at a premium.
                </p>
              </div>
              <div class="slider-content-3 text-center" style="color: black;">
                <p>
                  A service offering of reliable transportation for your small parcels by ground.
                  The service includes a door pickup and door delivery.
                </p>
              </div> -->
              </div>
            </div>
            <div ngxSlickItem>
              <div class="position-relative h-100  justify-content-center align-items-center bg-sunny-morning">
                <div class="p-5 d-flex flex-column justify-content-between h-100">
                  <div class="h4 m-1 text-center fw-bold text-white mt-3">
                    ICL KELVIN
                  </div>
                  <div class="m-3 d-flex flex-row align-items-center">

                    <div class="" style="width: 25%;">
                      <img class="m-auto" src="./assets/images/CCP.png" style="width: 100px" />
                      <div class="text-center fw-bold text-white">
                        CCP
                      </div>
                    </div>
                    <div class="p-1" style="width: 75%; height: inherit;">
                      <div class="slide p-2 d-flex justify-content-center align-items-center"
                        style="border-radius: 10px; height:inherit; background: linear-gradient(284.01deg, rgba(255, 255, 255, 0.4) 27.45%, rgba(255, 255, 255, 0.6) 100.05%);">
                        <div class="" style="color: black;">
                          All our packing solutions are validated and ensure the cold chain integrity is maintained
                          without any compromise.These packing solutions are backed with air transportation to ensure your
                          cold chain cargo reaches the customers in the shortest possible time.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="m-3 d-flex flex-row align-items-center">

                    <div class="" style="width: 25%;">
                      <img class="m-auto" src="./assets/images/Reefe.png" style="width: 100px" />
                      <div class="text-center fw-bold text-white">
                        REEFER
                      </div>
                    </div>
                    <div class="p-1" style="width: 75%; height: inherit;">
                      <div class="slide-im p-2 d-flex justify-content-center align-items-center"
                        style="border-radius: 10px; height:inherit; background: linear-gradient(284.01deg, rgba(255, 255, 255, 0.4) 27.45%, rgba(255, 255, 255, 0.6) 100.05%);">
                        <div class="" style="color: black;">
                          Our reefer vehicle operations are designed to meet the pharmaceutical standards. Data loggers
                          used are backed by calibration certificate and most importantly shipment is tracked with images at
                          every point of human interface to ensure highly reliable and transparent operation.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <div></div>
                </div>
                <!-- <div class="heading2">
                  <p>ICL KELVIN</p>
                </div>
                <div class="icons6 ">
                  <img src="./assets/images/CCP.png" style="width: 100px;" />
                </div>
                <div class="text6">
                  <p>CCP</p>
                </div>
                <div class="icons7 ">
                  <img src="./assets/images/Reefe.png" style="width: 100px;" />
                </div>
                <div class="text7">
                  <p>REEFER</p>
                </div>
                <div class="slide-img-bg-4"></div>
                <div class="slide-img-bg-5"></div>
                <div class="slider-content-4 text-center" style="color: black;">
                  <p>
                    All our packing solutions are validated and ensure the cold chain integrity is maintained without
                    any
                    compromise.
                    These packing solutions are backed with air transportation to ensure your
                    cold chain cargo reaches the customers in the shortest possible time.
                  </p>
                </div>
                <div class="slider-content-5 text-center" style="color: black;">
                  <p>
                    Our reefer vehicle operations are designed to meet the pharmaceutical standards. Data loggers used
                    are
                    backed by calibration certificate and most importantly shipment is tracked with images at every
                    point
                    of human interface to ensure highly reliable and transparent operation.

                  </p>
                </div> -->
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
      <div class="h-100 bg-white col-md-12 col-lg-8">
        <div class="row">
          <div class="col-lg-2 col-md-2">
            <div class="app-logo">
              <img src="./assets/images/icl-logo.png" style="width: 200px;" />
            </div>
          </div>
          <div class="col-lg-10 col-md-10">
            <div class="row mt-4" style="margin-left: 60px;">
              <div class="col">
                <label for="exampleEmail" class="form-label">Email <span class="text-danger">*</span></label>
                <!-- <input id="exampleEmail" name="email" type="email" placeholder="Email here..." class="form-control"
                  [(ngModel)]="userName"> -->
                  <input id="exampleEmail" name="email" type="email" placeholder="Email here..." class="form-control"
													[(ngModel)]="userName" [formControl]="emailFormControl">
									<!-- <mat-hint class="text-danger fw-normal" *ngIf="emailFormControl.hasError('required')">Email is required</mat-hint> -->
									<!-- <mat-hint  class="text-danger fw-normal" *ngIf="emailFormControl.hasError('pattern')">Invalid email address</mat-hint> -->
              </div>
              <div class="col">
                <label class="form-label">Password <span
                    class="text-danger">*</span></label>
<!--                <input id="examplePassword" name="password" type="password"-->
<!--                  placeholder="Password here..." class="form-control" [(ngModel)]="password"-->
<!--                  (keyup.enter)="onClickSubmit()">-->
                <div>
                  <p-password placeholder="Password here..." [(ngModel)]="password"
                              [feedback]="false" [toggleMask]="true" (keyup.enter)="onClickSubmit()"
                              [style]="{'width':'232px', 'height':'34.71px'}" [inputStyle]="{'width':'232px', 'height':'34.71px'}"></p-password>
                </div>

              </div>
              <div class="col mt-4">
                <button type="button" class="btn btn-primary btn-lg" (click)="onClickSubmit()">Login
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="row mt-3" style="margin-left: 20px;">
            <div class="col-lg-2 col-md-2">Welcome back,</div>
            <div class="col-lg-4 col-md-4 px-5" style="margin-left: 25px;">
              No account?
              <a [routerLink]="" class="text-primary" (click)="goToRegister()">Sign up now</a>
            </div>
            <div class="col-lg-2 col-md-2">
              <a [routerLink]="" (click)=" goToForgotPassword()">Forgot Password?</a>
            </div>
          </div>
        </div>
        <div class="row" style="margin-left: 20px;">
          <span class="text-bold "><b>Please sign in to your account.</b></span>
        </div>
        <hr />
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="row" style="margin-left: 30px;">
              <div class="col-lg-6 col-md-6">
                <div class="row">
                  <h3 style="color: rgb(87, 87, 228);"><b>Track your shipment here</b></h3>
                  <div class="col-md-6">
                    <div class="text-bold"><b> Consignment No.</b><span class="text-danger">*</span></div>
                    <input placeholder="Enter Consignment No." #consignmentnumber onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="11" [(ngModel)]="consignment_id" class="form-control mt-2"
                      (keyup.enter)="getTrack()">
                      <mat-hint align="end">{{consignmentnumber.value.length}}/11</mat-hint>
                  </div>
                  <div class="col-md-1 center-elem pl-2 mt-auto">
                    <button class="btn btn-primary m-4 my-0" (click)="getTrack()">Track</button>
                  </div>
                </div>
                <div class="row" *ngIf="consignment_track">
                  <div class="col-md-6">
                    <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                      <div class="vertical-timeline-item vertical-timeline-element"
                        *ngFor="let tra of consignment_track;let i=index">
                        <div>
                          <span class="vertical-timeline-element-icon bounce-in">
                            <i class="badge badge-dot badge-dot-xl bg-success"
                              [ngClass]="tra.updatedAt == null?'bg-light':'bg-primary'"></i>
                          </span>
                          <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">{{tra.status.name}}</h4>
                            <!-- <p>{{tra.comment}}</p> -->
                            <input matInput readonly [(ngModel)]="origin" *ngIf="tra.status.id>0 && tra.status.id<11">
                            <input matInput readonly [(ngModel)]="destination"
                              *ngIf="tra.status.id>10 && tra.status.id<17">
                            <p class="">{{ tra.updatedAt | date:'dd-MM-yyyy HH:mm:ss' }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
