import {API_URL} from '../../../Global_API_URL'

const TOKEN = sessionStorage.getItem('accessToken')

export async function getAirports() {
  const TOKEN = sessionStorage.getItem('accessToken')
  let airports = [];
  let array=[];
  await fetch(API_URL + "/airport", {
    method: "GET", headers: {
      "Accept": "application/json",
      "Authorization":"Bearer "+TOKEN
    }
  })

    // Converting received data to JSON
    .then(response => response.json())
    .then(json => {
      array=Array.from(json.data);
      array.sort((a, b) => {
        if (a.city.name < b.city.name) {
          return -1;
        }
        if (a.city.name > b.city.name) {
          return 1;
        }
        return 0;
      });
      json.data = []
      json.data=array
      airports = json;
    });
  return airports.data;
}


export async function getAirportsSortOrder() {
  const TOKEN = sessionStorage.getItem('accessToken')
  let airports = [];
  let array=[];
  await fetch(API_URL + "/airport", {
    method: "GET", headers: {
      "Accept": "application/json",
      "Authorization":"Bearer "+TOKEN
    }
  })

    // Converting received data to JSON
    .then(response => response.json())
    .then(json => {
      airports = json;
    });
  return airports.data.sort((a, b) => a.name.localeCompare(b.name));
}

export async function getAirportsib() {
  const TOKEN = sessionStorage.getItem('accessToken')
  let airportsib = [];
  let array=[];
  await fetch(API_URL + "/airportib", {
    method: "GET", headers: {
      "Accept": "application/json",
      "Authorization":"Bearer "+TOKEN
    }
  })

    // Converting received data to JSON
    .then(response => response.json())
    .then(json => {
      array=Array.from(json.data);
      array.sort((a, b) => {
        if (a.city.name < b.city.name) {
          return -1;
        }
        if (a.city.name > b.city.name) {
          return 1;
        }
        return 0;
      });
      json.data = []
      json.data=array
      airportsib = json;
    });
  return airportsib.data;
}

export async function getAirportsob() {
  const TOKEN = sessionStorage.getItem('accessToken')
  let airportsob = [];
  let array=[];
  await fetch(API_URL + "/airportob", {
    method: "GET", headers: {
      "Accept": "application/json",
      "Authorization":"Bearer "+TOKEN
    }
  })

    // Converting received data to JSON
    .then(response => response.json())
    .then(json => {
      array=Array.from(json.data);
      array.sort((a, b) => {
        if (a.city.name < b.city.name) {
          return -1;
        }
        if (a.city.name > b.city.name) {
          return 1;
        }
        return 0;
      });
      json.data = []
      json.data=array
      airportsob = json;
    });
  return airportsob.data;
}

export async function getAirlines() {
  const TOKEN = sessionStorage.getItem('accessToken')

  let airlines = [];
  await fetch(API_URL + "/airline",
    {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Authorization":"Bearer "+TOKEN
      }
    })

    // Converting received data to JSON
    .then(response => response.json())
    .then(json => {
      airlines = json;
    });
  return airlines.data;
}
