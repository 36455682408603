import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BillingService} from '../../../modules/billingInfo/service/billing.service';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import Swal from 'sweetalert2';
import {forkJoin} from 'rxjs';
import {Location} from '@angular/common';


const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-cost-center-edit',
  templateUrl: './cost-center-edit.component.html',
  styleUrls: ['./cost-center-edit.component.sass'],
})
export class CostCenterEditComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  consignment_id;

  constructor(
    private ActivatedRoute: ActivatedRoute,
    private route: Router,
    public commonApi: BillingService,
    private locationService: Location,
  ) {
    this.ActivatedRoute.params.subscribe((params) => {
      this.consignment_id = params['id'];
    });
    this.selected = '0';
  }

  userDetails;
  displayToAdmin = false;
  displayToSupervisor = false;
  billPriceId;
  costPriceId;
  count;
  limit;
  count2 = 0;
  selected;

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0] == 'Admin') {
      this.displayToAdmin = true;
    }
    if (this.userDetails.userTypes[0] == 'Supervisor') {
      this.displayToSupervisor = true;
    }

    this.selectedBillingLineItems = [];
    this.commonApi.getBillingLineItems();

    this.commonApi.getBillingPriceByConsignmentId(this.consignment_id)
        .subscribe((response) => {
          this.billPriceId = this.commonApi.BillingPriceLists?.id;
          if (this.commonApi.BillingPriceLists.discountedAmount === null) {
            this.commonApi.BillingPriceLists.discountedAmount = 0;
          }
          if (this.commonApi.BillingPriceLists.insurancePercentage === null) {
            this.commonApi.BillingPriceLists.insurancePercentage = 0;
          }
          if (this.commonApi.BillingPriceLists.taxablePercentage === null) {
            this.commonApi.BillingPriceLists.taxablePercentage = 18;
          }
          // this.commonApi.ModeBasedBillingLineItems();
          this.commonApi.calculateGrandTotal();

          this.commonApi.getCostCenterPriceByConsignmentId(this.consignment_id)
              .subscribe((response) => {
                this.costPriceId = this.commonApi.CostCenterPricesList?.id;
                if (this.commonApi.CostCenterPricesList?.discountedAmount === null) {
                  this.commonApi.CostCenterPricesList.discountedAmount = 0;
                }
                if (this.commonApi.CostCenterPricesList?.insurancePercentage === null) {
                  this.commonApi.CostCenterPricesList.insurancePercentage = 0;
                }
                if (this.commonApi.CostCenterPricesList?.taxablePercentage === null) {
                  this.commonApi.CostCenterPricesList.taxablePercentage = 18;
                }

                this.previousBillingList();
                this.calculateSubTotal1();
                this.calculateBillGrandTotal();
                this.calculateSubTotal2();
                this.calculateCostGrandTotal();
                this.commonApi.calculateTaxAndInsurance();
                this.count = this.selectedBillingLineItems.length;
                this.selectedBillingLineItems.forEach((item) => {
                  if (item.costPrice === null) {
                    item.costPrice = 0;
                  }
                });
              });
        });

    setTimeout(() => {
      this.loading = false; // Set loading to false after 2 seconds
    }, 2000);
  }


  selectedBillingLineItems: { name: string, billPrice: number, costPrice: number }[] = [];
  duplicateSelectedBillingLineItems: { name: string, billPrice: number, costPrice: number }[] = [];
  plusList: any;

  // previousBillingList() {
  //   this.commonApi.BillingLineItemsLists.forEach((item) => {
  //     if (this.commonApi.BillingPriceLists.consignment?.product_mode === 'ATA') {
  //       if (this.commonApi.BillingPriceLists[item.value] != null && this.commonApi.BillingPriceLists[item.value] >= 0 && item.value != 'pickupCharges' && item.value != 'deliveryCharges' && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
  //         this.selectedBillingLineItems.push({
  //           name: item.name,
  //           billPrice: this.commonApi.BillingPriceLists[item.value],
  //           costPrice: this.commonApi.CostCenterPricesList[item.value],
  //         });
  //       }
  //       if (this.commonApi.BillingPriceLists[item.value] != null && (this.commonApi.BillingPriceLists[item.value] > 0 || this.commonApi.CostCenterPricesList[item.value] > 0) && item.value != 'pickupCharges' && item.value != 'deliveryCharges' && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
  //         this.duplicateSelectedBillingLineItems.push({
  //           name: item.name,
  //           billPrice: this.commonApi.BillingPriceLists[item.value],
  //           costPrice: this.commonApi.CostCenterPricesList[item.value],
  //         });
  //       }
  //       this.count = this.selectedBillingLineItems.length;
  //       this.limit = 15;
  //     } else if (this.commonApi.BillingPriceLists.consignment?.product_mode === 'ATD') {
  //       if (this.commonApi.BillingPriceLists[item.value] != null && this.commonApi.BillingPriceLists[item.value] >= 0 && item.value != 'pickupCharges' && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
  //         this.selectedBillingLineItems.push({
  //           name: item.name,
  //           billPrice: this.commonApi.BillingPriceLists[item.value],
  //           costPrice: this.commonApi.CostCenterPricesList[item.value],
  //         });
  //       }
  //       if (this.commonApi.BillingPriceLists[item.value] != null && (this.commonApi.BillingPriceLists[item.value] > 0 || this.commonApi.CostCenterPricesList[item.value] > 0) && item.value != 'pickupCharges' && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
  //         this.duplicateSelectedBillingLineItems.push({
  //           name: item.name,
  //           billPrice: this.commonApi.BillingPriceLists[item.value],
  //           costPrice: this.commonApi.CostCenterPricesList[item.value],
  //         });
  //       }
  //       this.count = this.selectedBillingLineItems.length;
  //       this.limit = 16;
  //     } else if (this.commonApi.BillingPriceLists.consignment?.product_mode === 'DTA') {
  //       if (this.commonApi.BillingPriceLists[item.value] != null && this.commonApi.BillingPriceLists[item.value] >= 0 && item.value != 'deliveryCharges' && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
  //         this.selectedBillingLineItems.push({
  //           name: item.name,
  //           billPrice: this.commonApi.BillingPriceLists[item.value],
  //           costPrice: this.commonApi.CostCenterPricesList[item.value],
  //         });
  //       }
  //       if (this.commonApi.BillingPriceLists[item.value] != null && (this.commonApi.BillingPriceLists[item.value] > 0 || this.commonApi.CostCenterPricesList[item.value] > 0) && item.value != 'deliveryCharges' && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
  //         this.duplicateSelectedBillingLineItems.push({
  //           name: item.name,
  //           billPrice: this.commonApi.BillingPriceLists[item.value],
  //           costPrice: this.commonApi.CostCenterPricesList[item.value],
  //         });
  //       }
  //       this.count = this.selectedBillingLineItems.length;
  //       this.limit = 16;
  //     } else {
  //       if (this.commonApi.BillingPriceLists[item.value] != null && this.commonApi.BillingPriceLists[item.value] >= 0 && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
  //         this.selectedBillingLineItems.push({
  //           name: item.name,
  //           billPrice: this.commonApi.BillingPriceLists[item.value],
  //           costPrice: this.commonApi.CostCenterPricesList[item.value],
  //         });
  //       }
  //       if (this.commonApi.BillingPriceLists[item.value] != null && (this.commonApi.BillingPriceLists[item.value] > 0 || this.commonApi.CostCenterPricesList[item.value] > 0) && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
  //         this.duplicateSelectedBillingLineItems.push({
  //           name: item.name,
  //           billPrice: this.commonApi.BillingPriceLists[item.value],
  //           costPrice: this.commonApi.CostCenterPricesList[item.value],
  //         });
  //       }
  //       this.count = this.selectedBillingLineItems.length;
  //       this.limit = 17;
  //     }
  //   });

  //   this.plusList = this.selectedBillingLineItems;

  //   this.selectedBillingLineItems.forEach((item) => {
  //     if (item['billPrice'] > 0 || item['costPrice'] > 0) {
  //       this.count2 += 1;
  //     }
  //   });
  // }

  previousBillingList() {
    this.commonApi.BillingLineItemsLists.forEach((item) => {
      if (this.commonApi.BillingPriceLists[item.value] != null && this.commonApi.BillingPriceLists[item.value] >= 0 && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
        this.selectedBillingLineItems.push({
          name: item.name,
          billPrice: this.commonApi.BillingPriceLists[item.value],
          costPrice: this.commonApi.CostCenterPricesList[item.value],
        });
      }
      if (this.commonApi.BillingPriceLists[item.value] != null && (this.commonApi.BillingPriceLists[item.value] > 0 || this.commonApi.CostCenterPricesList[item.value] > 0) && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
        this.duplicateSelectedBillingLineItems.push({
          name: item.name,
          billPrice: this.commonApi.BillingPriceLists[item.value],
          costPrice: this.commonApi.CostCenterPricesList[item.value],
        });
      }
      this.count = this.selectedBillingLineItems.length;
      this.limit = 17;
    });

    this.plusList = this.selectedBillingLineItems;

    this.selectedBillingLineItems.forEach((item) => {
      if (item['billPrice'] > 0 || item['costPrice'] > 0) {
        this.count2 += 1;
      }
    });
  }

  addDropdown() {
    this.duplicateSelectedBillingLineItems.push({name: '', billPrice: null, costPrice: null});
    this.count2 += 1;
  }

  addColour() {
    if (this.count2 > this.limit) {
      return 'rgba(0, 0, 0, 0.12)';
    } else {
      return '#3f6ad8';
    }
  }


  getAvailableOptions(index: number): any[] {
    // Get the names of already selected items in previous dropdowns
    const selectedNames = this.duplicateSelectedBillingLineItems.slice(0, index).map((item) => item.name);

    // Filter out the selected items for the specific dropdown
    return this.commonApi.BillingLineItemsLists.filter((item) =>
      (!selectedNames.includes(item.name) &&
        !['Insurance Percentage', 'Insurance', 'Discounted Amount', 'Tax Amount', 'Taxable Amount', 'Taxable Percentage'].includes(item.name)) ||
      this.duplicateSelectedBillingLineItems[index].name === item.name,
    );
  }


  subTotal1;

  calculateSubTotal1() {
    this.subTotal1 = 0;
    this.duplicateSelectedBillingLineItems.forEach((item) => {
      this.subTotal1 += item.billPrice;
      this.subTotal1 = parseFloat(this.subTotal1.toFixed(2));
    });
  }

  // ****************************************************** Cost Center *****************************************************************


  costSubTotal1;

  calculateSubTotal2() {
    this.costSubTotal1 = 0;
    this.duplicateSelectedBillingLineItems.forEach((item) => {
      this.costSubTotal1 += item.costPrice;
    });
    this.costSubTotal1 = parseFloat(this.costSubTotal1.toFixed(2));
  }

  subTotal2
  BillInsuranceAmount;
  BillInsurancePercentage;
  BillTaxableAmount;
  BillTax;
  BillTaxAmount;
  BillGrandTotal;

  async calculateBillGrandTotal() {
    if (this.commonApi.BillingPriceLists?.consignment?.senderId != null) {
      if (this.commonApi.BillingPriceLists?.igst == 0) {
        this.BillTax = this.commonApi.BillingPriceLists?.cgst + this.commonApi.BillingPriceLists?.sgst;
      } else {
        this.BillTax = this.commonApi.BillingPriceLists?.igst;
      }
    } else {
      if (this.commonApi.BillingPriceLists?.igst == 0) {
        this.BillTax = this.commonApi.BillingPriceLists?.cgst + this.commonApi.BillingPriceLists?.sgst;
      } else {
        this.BillTax = this.commonApi.BillingPriceLists?.igst;
      }
    }

    this.subTotal2 = this.subTotal1 - this.commonApi.BillingPriceLists.discountedAmount;
    // this.BillInsuranceAmount = (this.commonApi.BillingPriceLists?.insurancePercentage * this.commonApi.BillingPriceLists.consignment?.consignment_value) / 100

    this.BillInsurancePercentage = this.commonApi.BillingPriceLists?.insurancePercentage;
    this.BillInsuranceAmount = parseInt( this.commonApi.BillingPriceLists?.insurance || 0);

    if (this.commonApi.isSuffix === true) {
      this.BillInsuranceAmount = (this.commonApi.BillingPriceLists?.insurancePercentage * this.commonApi.BillingPriceLists.consignment?.consignment_value)/100;
    } else {
      this.BillInsurancePercentage = (this.BillInsuranceAmount / this.commonApi.BillingPriceLists.consignment?.consignment_value)*100;
    }

    this.BillTaxableAmount = this.subTotal2 + this.BillInsuranceAmount;

    this.BillTaxAmount = (this.BillTaxableAmount * this.BillTax) / 100;
    this.BillGrandTotal = this.BillTaxableAmount + this.BillTaxAmount;

    this.subTotal2 = parseFloat(this.subTotal2.toFixed(2));
    this.BillTaxableAmount = parseFloat(this.BillTaxableAmount.toFixed(2));
    this.BillInsuranceAmount = parseFloat(this.BillInsuranceAmount.toFixed(2));
    // this.BillInsurancePercentage = parseFloat(this.BillInsurancePercentage.toFixed(2))
    this.BillTaxAmount = parseFloat(this.BillTaxAmount.toFixed(2));
    this.BillGrandTotal = parseFloat(this.BillGrandTotal.toFixed(2));
  }

  costSubTotal2;
  CostInsuranceAmount;
  CostInsurancePercentage;
  CostTaxableAmount;
  CostTax;
  CostTaxAmount;
  CostGrandTotal;

  async calculateCostGrandTotal() {
    this.costSubTotal2 = this.costSubTotal1 - this.commonApi.CostCenterPricesList?.discountedAmount;

    if (this.commonApi.BillingPriceLists?.consignment?.senderId != null) {
      if (this.commonApi.BillingPriceLists?.igst == 0) {
        this.CostTax = this.commonApi.BillingPriceLists?.cgst + this.commonApi.BillingPriceLists?.sgst;
      } else {
        this.CostTax = this.commonApi.BillingPriceLists?.igst;
      }
    } else {
      if (this.commonApi.BillingPriceLists?.igst == 0) {
        this.CostTax = this.commonApi.BillingPriceLists?.cgst + this.commonApi.BillingPriceLists?.sgst;
      } else {
        this.CostTax = this.commonApi.BillingPriceLists?.igst;
      }
    }

    this.CostInsurancePercentage = this.commonApi.CostCenterPricesList?.insurancePercentage;
    this.CostInsuranceAmount = parseInt( this.commonApi.CostCenterPricesList?.insurance || 0);

    if (this.commonApi.isSuffix === true) {
      this.CostInsuranceAmount = (this.commonApi.CostCenterPricesList?.insurancePercentage * this.commonApi.BillingPriceLists.consignment?.consignment_value)/100;
    } else {
      this.CostInsurancePercentage = (this.commonApi.CostCenterPricesList?.insurance / this.commonApi.BillingPriceLists.consignment?.consignment_value)*100;
    }

    // this.CostInsuranceAmount = (this.commonApi.CostCenterPricesList?.insurancePercentage * this.commonApi.BillingPriceLists.consignment?.consignment_value) / 100
    this.CostTaxableAmount = ((this.costSubTotal1 - this.commonApi.CostCenterPricesList?.discountedAmount) + this.CostInsuranceAmount);
    this.CostTaxAmount = (this.CostTaxableAmount * this.CostTax) / 100;
    this.CostGrandTotal = this.CostTaxableAmount + this.CostTaxAmount;

    this.costSubTotal2 = parseFloat(this.costSubTotal2.toFixed(2));
    this.CostTaxableAmount = parseFloat(this.CostTaxableAmount.toFixed(2));
    this.CostInsuranceAmount = parseFloat(this.CostInsuranceAmount.toFixed(2));
    // this.CostInsurancePercentage = parseFloat(this.CostInsurancePercentage.toFixed(2))
    this.CostTaxAmount = parseFloat(this.CostTaxAmount.toFixed(2));
    this.CostGrandTotal = parseFloat(this.CostGrandTotal.toFixed(2));
  }

  difference;
  changeColor = 'red';
  ProfitOrLoss = 'loss';
  ProfitOrLossPercentage;

  calculateDifference() {
    this.difference = this.BillTaxableAmount - this.CostTaxableAmount;
    this.difference > 0 ? (this.changeColor = 'green', this.ProfitOrLoss = 'profit') : (this.changeColor = 'red', this.ProfitOrLoss = 'loss');
    this.difference = parseFloat(this.difference.toFixed(2));
    this.ProfitOrLossPercentage = ((this.difference)/this.BillTaxableAmount) * 100;
    this.ProfitOrLossPercentage = parseFloat(this.ProfitOrLossPercentage.toFixed(2));
    if (this.CostTaxableAmount <= 0) {
      this.ProfitOrLossPercentage = 0;
    }
    return this.difference;
  }


  onPriceChange() {
    this.calculateSubTotal1();
    this.calculateSubTotal2();
    this.commonApi.calculateGrandTotal();
    this.calculateBillGrandTotal();
    this.calculateCostGrandTotal();
  }

  clearBillUnitPriceZero(i) {
    if (this.duplicateSelectedBillingLineItems[i].billPrice === 0) {
      this.duplicateSelectedBillingLineItems[i].billPrice = null;
    }
  }

  clearCostUnitPriceZero(i) {
    if (this.duplicateSelectedBillingLineItems[i].costPrice === 0) {
      this.duplicateSelectedBillingLineItems[i].costPrice = null;
    }
  }

  clearBillDiscountZero() {
    if (this.commonApi.BillingPriceLists.discountedAmount === 0) {
      this.commonApi.BillingPriceLists.discountedAmount = null;
    }
  }

  clearCostDiscountZero() {
    if (this.commonApi.CostCenterPricesList.discountedAmount === 0) {
      this.commonApi.CostCenterPricesList.discountedAmount = null;
    }
  }

  clearBillInsuranceZero() {
    if (this.commonApi.BillingPriceLists.insurancePercentage === '0' || this.commonApi.BillingPriceLists.insurancePercentage === 0) {
      this.commonApi.BillingPriceLists.insurancePercentage = null;
    }
  }

  clearBillInsuranceValueZero() {
    if (this.commonApi.BillingPriceLists.insurance === '0' || this.commonApi.BillingPriceLists.insurance === 0) {
      this.commonApi.BillingPriceLists.insurance = null;
    }
  }

  clearCostInsuranceZero() {
    if (this.commonApi.CostCenterPricesList.insurancePercentage === '0' || this.commonApi.CostCenterPricesList.insurancePercentage === 0) {
      this.commonApi.CostCenterPricesList.insurancePercentage = null;
    }
  }

  clearCostInsuranceValueZero() {
    if (this.commonApi.CostCenterPricesList.insurance === '0' || this.commonApi.CostCenterPricesList.insurance === 0) {
      this.commonApi.CostCenterPricesList.insurance = null;
    }
  }

  clearBillTaxZero() {
    if (this.commonApi.BillingPriceLists.taxablePercentage === '0' || this.commonApi.BillingPriceLists.taxablePercentage === 0) {
      this.commonApi.BillingPriceLists.taxablePercentage = null;
    }
  }

  clearCostTaxZero() {
    if (this.commonApi.CostCenterPricesList.taxablePercentage === '0' || this.commonApi.CostCenterPricesList.taxablePercentage === 0) {
      this.commonApi.CostCenterPricesList.taxablePercentage = null;
    }
  }


  validateInput(event: KeyboardEvent): void {
    const inputChar = String.fromCharCode(event.charCode);

    // Allow only digits and a dot (for decimal numbers)
    if (!/^\d*\.?\d*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  saveBillingPrices() {
    // Check for empty name in selectedBillingLineItems
    const hasEmptyName = this.duplicateSelectedBillingLineItems.some((item) => item.name.trim() === '');
    // Filter out items with billingPrice zero
    const hasNullBillingPrice = this.duplicateSelectedBillingLineItems.some((item) => item.billPrice === null);
    // Filter out items with billingPrice zero
    const hasNullCostPrice = this.duplicateSelectedBillingLineItems.some((item) => item.costPrice === null);

    if (hasEmptyName || hasNullBillingPrice || hasNullCostPrice) {
      Swal.fire({
        title: 'Warning',
        text: hasEmptyName ? 'Please select line items' : 'Please enter the price for line item',
        icon: 'warning',
        timer: 5000,
      });
    } else if (this.BillInsurancePercentage > 100) {
      Swal.fire({
        title: 'Warning',
        text: 'Billing Fov percentage must be less than 100.',
        icon: 'warning',
        timer: 5000,
      });
    } else if (this.CostInsurancePercentage > 100) {
      Swal.fire({
        title: 'Warning',
        text: 'Cost Fov percentage must be less than 100.',
        icon: 'warning',
        timer: 5000,
      });
    } else if (this.BillGrandTotal < 0) {
      Swal.fire({
        title: 'Warning',
        text: 'Billing Grand Total must be greater than zero.',
        icon: 'warning',
        timer: 5000,

      });
    } else if (this.CostGrandTotal < 0) {
      Swal.fire({
        title: 'Warning',
        text: 'Cost Grand Total must be greater than zero.',
        icon: 'warning',
        timer: 5000,

      });
    } else {
      const patchObservables = [
        this.commonApi.patchBillingPrices(this.billPriceId, this.duplicateSelectedBillingLineItems, this.BillInsuranceAmount, this.BillInsurancePercentage, this.BillTaxableAmount, this.BillTaxAmount, this.BillGrandTotal),
        this.commonApi.patchCostPrices(this.costPriceId, this.duplicateSelectedBillingLineItems, this.CostInsuranceAmount, this.CostInsurancePercentage, this.CostTaxableAmount, this.CostTaxAmount, this.CostGrandTotal, this.difference),
      ];

      // Use forkJoin to wait for both observables to complete
      forkJoin(patchObservables).subscribe((responses) => {
        // Both API calls are complete here
        // // Navigate to the desired page
        // this.locationService.back();
        this.route.navigate(['/costCenter/', this.consignment_id], {replaceUrl: true});
      });
    }
  }

  backToCostCenter() {
    this.commonApi.isSuffix = true;
    this.locationService.back();
    // this.route.navigate(['/costCenter/', this.consignment_id])
  }

  removeLineItem(index: number): void {
    this.duplicateSelectedBillingLineItems.splice(index, 1);
    this.count2 -= 1;
    // Recalculate totals or perform any other necessary actions
    this.calculateSubTotal1();
    this.calculateBillGrandTotal();
    this.calculateSubTotal2();
    this.calculateCostGrandTotal();
    // You can also perform additional calculations or updates here
  }
}
