<form [formGroup]="regionForm"  (ngSubmit)="onSubmit()">
  <mat-card class="shipping-card">
    <mat-card-header>
      <mat-card-title class="title-container">
        <div class="left-section">
          <button type="button" (click)="goBack()" mat-icon-button>
            <mat-icon>arrow_back</mat-icon>
          </button>
          <h4 class="title-text">CREATE REGION</h4>
        </div>
      </mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content class="form-content">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Region Name</mat-label>
            <input matInput placeholder="Enter the region name" formControlName="name">
            <mat-error *ngIf="regionForm.controls['name'].hasError('required')">
              Region name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Enter the description" formControlName="description">
            <mat-error *ngIf="regionForm.controls['description'].hasError('required')">
              Description is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="actions-buttons">
        <button mat-raised-button color="primary" type="submit">Submit</button>
        <button mat-raised-button color="warn" style="color: white">Discard</button>
      </div>
    </mat-card-content>
  </mat-card>
</form>
