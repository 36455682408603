<mat-card ><div class="card-header-title font-size-lg text-capitalize fw-normal" style="margin-left: 1rem">
  <b style="color: blue;">PRODUCTS</b>
</div>
  <table mat-table [dataSource]="productTypeList" style="margin-top: 1rem; width: 100%" class="mat-elevation-z8">
    <!-- Name Column -->
    <ng-container matColumnDef="Name">
      <th style="padding-right: 1rem" mat-header-cell *matHeaderCellDef>Product name</th>
      <td style="padding-right: 1rem" mat-cell *matCellDef="let element">{{element?.name}}</td>
    </ng-container>
    <ng-container matColumnDef="Created">
      <th style="padding-right: 1rem" mat-header-cell *matHeaderCellDef>Created At</th>
      <td style="padding-right: 1rem" mat-cell *matCellDef="let element">{{element?.created_at | date:'dd-MM-yyyy HH:mm:ss'}}</td>
    </ng-container>
    <ng-container matColumnDef="Updated">
      <th mat-header-cell *matHeaderCellDef>Updated At</th>
      <td mat-cell *matCellDef="let element">{{element?.updated_at | date:'dd-MM-yyyy HH:mm:ss'}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="productTypeTableColumns"></tr>
    <tr mat-row
        *matRowDef="let row; columns: productTypeTableColumns;"></tr>
  </table>
</mat-card>
