import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private httpClient: HttpClient) { }

  get(url, params, headers) {
    return this.httpClient.get<any>(url, {
      params: params,
      headers: headers,
    });
  }
  getBlob(url, params, headers) {
    return this.httpClient.get<any>(url, {
      params: params,
      headers: headers,
      responseType: 'blob' as 'json',
    });
  }

  post(url, body, params, headers) {
    return this.httpClient.post<any>(url, body, {
      params: params,
      headers: headers,
    });
  }

  patch(url, body, params, headers) {
    return this.httpClient.patch<any>(url, body, {
      params: params,
      headers: headers,
    });
  }

  put(url, body, params, headers) {
    return this.httpClient.put<any>(url, body, {
      params: params,
      headers: headers,
    });
  }
}
