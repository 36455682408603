import {Injectable} from '@angular/core';
import {API_URL} from '../../../../../Global_API_URL';
import {AuthService} from '../../../auth.service';
import {HttpService} from '../../../services/http.service';
import Swal from 'sweetalert2';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(
    private authService: AuthService,
    private httpService: HttpService,
  ) {
    this.authService.getAuthToken();
  }

  // ****************************************************Billing Info************************************************************

  BillingLineItemsLists = []

  getBillingLineItems() {
    this.httpService.get(API_URL + '/billingItems', null, null)
        .subscribe((response) => {
          this.BillingLineItemsLists = response.data;
        }, (error) => {
          Swal.fire({
            position: 'center',
            title: 'Billing line items are not fetching',
            text: error.message,
            icon: 'error',
            timer: 5000,
          });
        });
  }


  BillingPriceLists: any = {}
  isBillingCreated: boolean

  getBillingPriceByConsignmentId(id) {
    return this.httpService.get(API_URL + '/consignmentBilling/' + id, null, null)
        .pipe(map((response) => {
          this.BillingPriceLists = response.data[0];
          this.isBillingCreated = this.BillingPriceLists != null;
          return this.BillingPriceLists;
        }));
  }


  createBillingPrice(id) {
    const OfflineBillingPrices = {
      freightCharges: 0,
      ahcCharges: 0,
      mawbCharges: 0,
      iclCnCharges: 0,
      pickupCharges: 0,
      deliveryCharges: 0,
      fuelSurcharges: 0,
      packingMaterialCharges: 0,
      dataLoggerCharges: 0,
      dgCharges: 0,
      discountedAmount: 0,
      insurance: 0,
      taxableAmount: 0,
      taxAmount: 0,
      taxablePercentage: 18,
      insurancePercentage: 0,
      // isInvoiceGenerated: false,
      consignmentId: id,
      iclHandlingCharges: 0,
      peakHandlingCharges: 0,
      loadingCharges: 0,
      unloadingCharges: 0,
      warehouseCharges: 0,
      detentionCharges: 0,
      demurrageCharges: 0,
      miscellaneousCharges: 0,

    };
    return this.httpService.post(API_URL + '/consignmentBilling', OfflineBillingPrices, null, null);
  }


  ModeBasedBillingLineItems() {
    if (this.BillingPriceLists.consignment?.product_mode === 'ATA') {
      this.BillingLineItemsLists = this.BillingLineItemsLists.filter((item) => item.value !== 'pickupCharges' && item.value !== 'deliveryCharges');
    } else if (this.BillingPriceLists.consignment?.product_mode === 'ATD') {
      this.BillingLineItemsLists = this.BillingLineItemsLists.filter((item) => item.value !== 'pickupCharges');
    } else if (this.BillingPriceLists.consignment?.product_mode === 'DTA') {
      this.BillingLineItemsLists = this.BillingLineItemsLists.filter((item) => item.value !== 'deliveryCharges');
    }
  }

  calculateTotalBillingInfoSum(): number {
    let totalSum = 0;
    for (const item of this.BillingLineItemsLists) {
      const value = this.BillingPriceLists[item.value];
      if (value != null && value > 0 && item.value !== 'discountedAmount' && item.value !== 'insurancePercentage' && item.value != 'insurance' && item.value !== 'taxableAmount' && item.value !== 'taxAmount' && item.value !== 'taxablePercentage') {
        totalSum += value;
      }
    }
    totalSum = parseFloat(totalSum.toFixed(2));
    return totalSum;
  }

  // ********************************************************** Cost Center *****************************************************************************

  CostCenterPricesList: any = {
    discountedAmount: 0,
  }

  getCostCenterPriceByConsignmentId(id) {
    return this.httpService.get(API_URL + '/costCenter/' + id, null, null)
        .pipe(map((response) => {
          this.CostCenterPricesList = response.data[0];
          return this.CostCenterPricesList;
        }));
  }

  calculateTotalCostCenterSum(): number {
    let totalSum = 0;
    for (const item of this.BillingLineItemsLists) {
      const value = this.CostCenterPricesList[item.value];
      if (value != null && value > 0 && item.value !== 'discountedAmount' && item.value !== 'insurancePercentage' && item.value != 'insurance' && item.value !== 'taxableAmount' && item.value !== 'taxAmount' && item.value !== 'taxablePercentage') {
        totalSum += value;
      }
    }
    return totalSum;
  }


  // ********************************************************************** BillingInfo Edit ******************************************************8***************


  totalPrice;

  patchBillingPrices(id, selectedBillingLineItems: any[], BillInsuranceAmount, BillInsurancePercentage, BillTaxableAmount, BillTaxAmount, BillGrandTotal) {
    const unitPrices = {};

    selectedBillingLineItems.forEach((item) => {
      unitPrices[item.name] = item.billPrice;
    });
    this.totalPrice = unitPrices;


    const updateBillingPrice = {
      freightCharges: unitPrices['Freight Charges'] || 0,
      ahcCharges: unitPrices['AHC Charges'] || 0,
      mawbCharges: unitPrices['MAWB Charges'] || 0,
      iclCnCharges: unitPrices['ICL CN Charges'] || 0,
      pickupCharges: unitPrices['Pickup Charges'] || 0,
      deliveryCharges: unitPrices['Delivery Charges'] || 0,
      fuelSurcharges: unitPrices['Fuel Surcharges'] || 0,
      packingMaterialCharges: unitPrices['Packing Material Charges'] || 0,
      dataLoggerCharges: unitPrices['Data Logger Charges'] || 0,
      dgCharges: unitPrices['DG Charges'] || 0,
      discountedAmount: this.BillingPriceLists?.discountedAmount || 0,
      // insurancePercentage : this.BillingPriceLists?.insurancePercentage || 0,
      insurancePercentage: BillInsurancePercentage || 0,
      insurance: BillInsuranceAmount,
      taxableAmount: BillTaxableAmount,
      taxAmount: BillTaxAmount,
      taxablePercentage: this.BillingPriceLists?.taxablePercentage || 0,
      remarks: this.BillingPriceLists?.remarks || '',
      iclHandlingCharges: unitPrices['ICL Handling Charges'] || 0,
      peakHandlingCharges: unitPrices['Peak Handling Charges'] || 0,
      loadingCharges: unitPrices['Loading  Charges'] || 0,
      unloadingCharges: unitPrices['Unloading Charges'] || 0,
      warehouseCharges: unitPrices['Warehouse Charges'] || 0,
      detentionCharges: unitPrices['Detention Charges'] || 0,
      demurrageCharges: unitPrices['Demurrage Charges'] || 0,
      miscellaneousCharges: unitPrices['Miscellaneous Charges'] || 0,
      grandTotal: BillGrandTotal,
    };
    return this.httpService.patch(API_URL + '/consignmentBilling/' + id, updateBillingPrice, null, null);
  }


  // ********************************************************************** CostCenter Edit ******************************************************8***************

  totalCostPrice;

  patchCostPrices(id, selectedBillingLineItems: any[], CostInsuranceAmount, CostInsurancePercentage, CostTaxableAmount, CostTaxAmount, CostGrandTotal, difference) {
    const costIncurredPrices = {};

    // Extract unit prices from selectedBillingLineItems
    selectedBillingLineItems.forEach((item) => {
      costIncurredPrices[item.name] = item.costPrice;
    });

    this.totalCostPrice = costIncurredPrices;


    const updateCostPrice = {
      freightCharges: costIncurredPrices['Freight Charges'] || 0,
      ahcCharges: costIncurredPrices['AHC Charges'] || 0,
      mawbCharges: costIncurredPrices['MAWB Charges'] || 0,
      iclCnCharges: costIncurredPrices['ICL CN Charges'] || 0,
      pickupCharges: costIncurredPrices['Pickup Charges'] || 0,
      deliveryCharges: costIncurredPrices['Delivery Charges'] || 0,
      fuelSurcharges: costIncurredPrices['Fuel Surcharges'] || 0,
      packingMaterialCharges: costIncurredPrices['Packing Material Charges'] || 0,
      dataLoggerCharges: costIncurredPrices['Data Logger Charges'] || 0,
      dgCharges: costIncurredPrices['DG Charges'] || 0,
      discountedAmount: this.CostCenterPricesList?.discountedAmount || 0,
      // insurancePercentage : this.CostCenterPricesList?.insurancePercentage || 0,
      insurancePercentage: CostInsurancePercentage || 0,
      insurance: CostInsuranceAmount,
      taxableAmount: CostTaxableAmount,
      taxAmount: CostTaxAmount,
      taxablePercentage: this.CostCenterPricesList?.taxablePercentage || 0,
      remarks: this.CostCenterPricesList?.remarks || '',
      iclHandlingCharges: costIncurredPrices['ICL Handling Charges'] || 0,
      peakHandlingCharges: costIncurredPrices['Peak Handling Charges'] || 0,
      loadingCharges: costIncurredPrices['Loading  Charges'] || 0,
      unloadingCharges: costIncurredPrices['Unloading Charges'] || 0,
      warehouseCharges: costIncurredPrices['Warehouse Charges'] || 0,
      detentionCharges: costIncurredPrices['Detention Charges'] || 0,
      demurrageCharges: costIncurredPrices['Demurrage Charges'] || 0,
      miscellaneousCharges: costIncurredPrices['Miscellaneous Charges'] || 0,
      grandTotal: CostGrandTotal,
      profitOrLoss: difference,
    };
    return this.httpService.patch(API_URL + '/costCenter/' + id, updateCostPrice, null, null);
  }


  BillingTaxPercentage;
  CostTaxPercentage;

  BillingInsurancePercentage;
  CostInsurancePercentage;

  calculateTaxAndInsurance() {
    this.BillingTaxPercentage = (this.BillingPriceLists?.taxablePercentage != null && this.BillingPriceLists?.taxablePercentage > 0) ? this.BillingPriceLists?.taxablePercentage : 18;
    this.CostTaxPercentage = (this.CostCenterPricesList?.taxablePercentage != null && this.CostCenterPricesList?.taxablePercentage > 0) ? this.CostCenterPricesList?.taxablePercentage : 18;

    this.BillingInsurancePercentage = (this.BillingPriceLists?.insurancePercentage != null && this.BillingPriceLists?.insurancePercentage > 0) ? this.BillingPriceLists?.insurancePercentage : 1;
    this.CostInsurancePercentage = (this.CostCenterPricesList?.insurancePercentage != null && this.CostCenterPricesList?.insurancePercentage > 0) ? this.CostCenterPricesList?.insurancePercentage : 1;
  }


  BillInsuranceAmount;
  BillInsurancePercentage;
  BillTaxableAmount;
  BillTax;
  BillTaxAmount;
  GrandTotal;

  isSuffix = true;

  onFovOptionChange(event: any): void {
    if (event.value === '1') {
      this.isSuffix = false;
    } else {
      this.isSuffix = true;
    }
    this.BillingPriceLists.insurance = 0;
    this.BillingPriceLists.insurancePercentage = 0;
    this.CostCenterPricesList.insurance = 0;
    this.CostCenterPricesList.insurancePercentage = 0;
  }

  async calculateGrandTotal() {
    if (this.BillingPriceLists?.consignment?.senderId != null) {
      await this.getCustomerById();
      if (this.BillingPriceLists?.igst == 0) {
        this.BillTax = this.BillingPriceLists?.cgst + this.BillingPriceLists?.sgst;
      } else {
        this.BillTax = this.BillingPriceLists?.igst;
      }
    } else {
      if (this.BillingPriceLists?.igst == 0) {
        this.BillTax = this.BillingPriceLists?.cgst + this.BillingPriceLists?.sgst;
      } else {
        this.BillTax = this.BillingPriceLists?.igst;
      }
    }


    this.BillInsurancePercentage = this.BillingPriceLists?.insurancePercentage;
    this.BillInsuranceAmount = parseInt(this.BillingPriceLists?.insurance || 0);

    if (this.isSuffix === true) {
      this.BillInsuranceAmount = (this.BillingPriceLists?.insurancePercentage * this.BillingPriceLists.consignment?.consignment_value) / 100;
    } else {
      this.BillInsurancePercentage = (this.BillingPriceLists?.insurance / this.BillingPriceLists.consignment?.consignment_value) * 100;
    }
    this.BillTaxableAmount = ((this.calculateTotalBillingInfoSum() - this.BillingPriceLists?.discountedAmount) + this.BillInsuranceAmount);
    if (isNaN(this.BillTaxableAmount)) {
      this.BillTaxableAmount = 0;
    }
    this.BillTaxAmount = (this.BillTaxableAmount * this.BillTax) / 100;
    if (isNaN(this.BillTaxAmount)) {
      this.BillTaxAmount = 0;
    }
    this.GrandTotal = this.BillTaxableAmount + this.BillTaxAmount;
    this.BillInsuranceAmount = parseFloat(this.BillInsuranceAmount.toFixed(2));
    this.BillTaxableAmount = parseFloat(this.BillTaxableAmount.toFixed(2));
    this.BillTaxAmount = parseFloat(this.BillTaxAmount.toFixed(2));
    this.GrandTotal = parseFloat(this.GrandTotal.toFixed(2));
  }

  CostInsuranceAmount;
  CostTaxableAmount;
  CostTax;
  CostTaxAmount;
  CostGrandTotal;

  async calculateCostGrandTotal() {
    if (this.BillingPriceLists?.consignment?.senderId != null) {
      await this.getCustomerById();
      if (this.BillingPriceLists?.igst == 0) {
        this.CostTax = this.BillingPriceLists?.cgst + this.BillingPriceLists?.sgst;
      } else {
        this.CostTax = this.BillingPriceLists?.igst;
      }
    } else {
      if (this.BillingPriceLists?.igst == 0) {
        this.CostTax = this.BillingPriceLists?.cgst + this.BillingPriceLists?.sgst;
      } else {
        this.CostTax = this.BillingPriceLists?.igst;
      }
    }

    this.CostInsurancePercentage = this.CostCenterPricesList?.insurancePercentage;
    this.CostInsuranceAmount = parseInt(this.CostCenterPricesList?.insurance);

    if (this.isSuffix === true) {
      this.CostInsuranceAmount = (this.CostCenterPricesList?.insurancePercentage * this.BillingPriceLists.consignment?.consignment_value) / 100;
    } else {
      this.CostInsurancePercentage = (this.CostCenterPricesList?.insurance / this.BillingPriceLists.consignment?.consignment_value) * 100;
    }

    // this.CostInsuranceAmount = (this.CostCenterPricesList?.insurancePercentage * this.BillingPriceLists.consignment?.consignment_value)/100
    this.CostTaxableAmount = ((this.calculateTotalCostCenterSum() - this.CostCenterPricesList?.discountedAmount) + this.CostInsuranceAmount);
    this.CostTaxAmount = (this.CostTaxableAmount * this.CostTax) / 100;
    this.CostGrandTotal = this.CostTaxableAmount + this.CostTaxAmount;

    this.CostInsuranceAmount = parseFloat(this.CostInsuranceAmount.toFixed(2));
    this.CostTaxAmount = parseFloat(this.CostTaxAmount.toFixed(2));
    this.CostGrandTotal = parseFloat(this.CostGrandTotal.toFixed(2));
  }

  difference;
  changeColor = 'red';
  ProfitOrLoss = 'loss';
  ProfitOrLossPercentage;


  calculateDifference() {
    this.difference = this.BillTaxableAmount - this.CostTaxableAmount;
    this.difference > 0 ? (this.changeColor = 'green', this.ProfitOrLoss = 'profit') : (this.changeColor = 'red', this.ProfitOrLoss = 'loss');
    this.difference = parseFloat(this.difference.toFixed(2));
    this.ProfitOrLossPercentage = ((this.difference) / this.BillTaxableAmount) * 100;
    this.ProfitOrLossPercentage = parseFloat(this.ProfitOrLossPercentage.toFixed(2));
    if (this.CostTaxableAmount <= 0) {
      this.ProfitOrLossPercentage = 0;
    }
    return this.difference;
  }

  // ***********************************************************************************************************************88

  customerData

  getCustomerById() {
    const id = this.BillingPriceLists?.consignment?.senderId;
    this.httpService.get(API_URL + '/customer/' + id, null, null)
        .subscribe((response) => {
          this.customerData = response?.data[0];
        }, (error) => {
          Swal.fire({
            icon: 'error',
            title: error.message,
            showConfirmButton: false,
            timer: 5000,
          });
        });
  }
}

