import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {API_URL} from '../../../../../Global_API_URL';
import {getAirports} from '../../getAirportAirlines.js';
import {Airport} from '../../golbalInterfaces.js';
import Swal from 'sweetalert2';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {AuthService} from '../../../auth.service';
import {ProductTypeService} from '../../../modules/productTypes/services/product-type.service';
// import { resolve } from 'dns';

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#D92550';
const SecondaryBlue = '#3F6AD8';

@Component({
  selector: 'app-manifestation-create',
  templateUrl: './manifestation-create.component.html',
  styleUrls: ['./manifestation-create.component.sass'],
})
export class ManifestationCreateComponent implements OnInit {
  listConsignments = []
  flightSchedule = []
  manifestConsignemnts = []
  // flightList = []
  user = JSON.parse(localStorage.getItem('userDetails'));
  role = this.user.userTypes[0].id
  manifest
  processign_time = 24
  // selectedManifest
  menifestationDetails = [];

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading_left = false;
  public loading_right = false;
  public loading = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = null;
  public coloursEnabled = true;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };

  async get_flight(id) {
    const TOKEN = this.authService.getAuthToken();
    this.loading_left = true;
    await fetch(API_URL + '/manifestation/' + this.origin.id + '/' + this.destination.id + '/' + id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
      // Converting received data to JSON
      .then((response) => response.json())
      .then(async (json) => {
        this.listConsignments = json.csdata;
        // this.flightList = json.fsdata;
        this.menifestationDetails = json.medata;
        // this.loading = false;
        if (this.menifestationDetails.length > 0) {
          if (this.manifest == null) {
            this.manifest = this.menifestationDetails[0];
          } else if (this.origin.id!= this.manifest.source.id || this.destination.id!= this.manifest.destination.id) {
            this.manifest = this.menifestationDetails[0];
          }
          await this.getManifestAPI(this.manifest);
        } else {
          this.manifest = null;
          this.manifestConsignemnts = [];
          this.loading_left = false;
        }
      });
    this.loading_left = false;
  }
  getManifestConsignments(event: MatTabChangeEvent) {
    const tab = event.index;
    this.manifest = this.menifestationDetails[tab];
    // for (let i = 0; i < this.menifestationDetails.length; i++) {
    //   if (this.menifestationDetails[i].isStopover == true) {
    //     let manifestId = this.menifestationDetails[i].flightScheduleLeg1.flightNo + " / " + this.menifestationDetails[i].flightScheduleLeg2.flightNo+'_'+ this.menifestationDetails[i].productType.name+'_'+ this.menifestationDetails[i].consignmentType.name+'_'+ this.menifestationDetails[i].consignmentSubtype.name
    //     if (manifestId == tab) {
    //       this.manifest = this.menifestationDetails[i];
    //       break;
    //     }
    //   }
    //   else {
    //     if (this.menifestationDetails[i].flightScheduleLeg1.flightNo+'_'+ this.menifestationDetails[i].productType.name+'_'+ this.menifestationDetails[i].consignmentType.name+'_'+ this.menifestationDetails[i].consignmentSubtype.name == tab) {
    //       this.manifest = this.menifestationDetails[i];
    //       break;
    //     }
    //   }
    // }

    this.getManifestAPI(this.manifest);
  }

  async getManifestAPI(manifest) {
    const TOKEN = this.authService.getAuthToken();
    this.loading_right = true;
    if (manifest == null) {
      this.loading_right = false;
      return;
    }
    // let departure_time_parsed = manifest.flightSchedulesEntity.departureTime
    if (this.manifest?.freezed == false) {
      await fetch(API_URL + "/getFinalManifestations/" + manifest.id,
        {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + TOKEN
          }
        })
        .then((response) => response.json())
        .then((result) => {
          if(result.status==="BAD_REQUEST" && result.message!=null){
            Swal.fire({
              title: 'Airline Pricing not Available',
              text: result.message,
              icon: 'error'
            })
          }
          else this.manifestConsignemnts = result.data.consignments;
        })
        .catch((error) => {
          console.error("Error", error.message);
          alert(error)
        });
      // for (let i = 0; i < this.menifestationDetails.length; i++) {
      //   if (this.menifestationDetails[i].id == manifest.id) {
      //     this.manifestConsignemnts = this.menifestationDetails[i].consignments;
      //     // alert(JSON.stringify(this.manifestConsignemnts));
      //   }
      // }
    } else {
      await fetch(API_URL + '/consignments/finalmanifest/' + manifest.manifestIdLeg1 + '/' + manifest.manifestIdLeg2,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          this.manifestConsignemnts = result.consignment;
        })
        .catch((error) => {
          console.error('Error', error);
          alert(error);
        });
    }
    this.getTotalManifestWeight();

    this.loading_right = false;
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async addToManifest(con) {
    const TOKEN = this.authService.getAuthToken();
    // alert(JSON.stringify(con));
    const addManifest = {
      manifestId: this.manifest.id,
      consignmentId: con.id,
      frozen: this.manifest.freezed,
    };
    if (!this.manifest.freezed || this.role == 4) {
      await fetch(API_URL + '/updateManifestations',
        {
          method: 'POST',
          body: JSON.stringify(addManifest),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .catch((error) => {
          console.error('Error', error);
          alert(error);
        });

      await this.get_flight(this.processign_time);
      await this.getManifestAPI(this.manifest);
      this.getTotalManifestWeight();
    } else {
      alert('Manifest Is Frozen');
    }
  }

  async removeFromManifest(cons) {
    const TOKEN = this.authService.getAuthToken();
    // this.loading=true;
    const removemanifest = {
      manifestId: this.manifest.id,
      consignmentId: cons.id,
    };
    // this.loading=false;
    if (!this.manifest.freezed || this.role == 4) {// HERE 4 is role id for admin
      await fetch(API_URL + '/removeConsignment',
        {
          method: 'POST',
          body: JSON.stringify(removemanifest),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          this.get_flight(this.processign_time);
        })
        .catch((error) => {
          console.error('Error', error);
          alert(error);
        });

      await this.getManifestAPI(this.manifest);
      this.getTotalManifestWeight();
    } else {
      alert('Manifest Is Frozen');
    }
  }
  async viewConsignment(consNo) {
    open(consNo);
  }


  total_manifest_weight = 0
  getTotalManifestWeight() {
    this.total_manifest_weight = 0;
    if (this.manifestConsignemnts.length != 0) {
      for (let i = 0; i < this.manifestConsignemnts.length; i++) {
        this.total_manifest_weight += this.manifestConsignemnts[i].totalChargableWeight;
      }
    }
    // this.loading=false;
  }
  constructor(private modalService: NgbModal, private authService : AuthService, private productTypeService: ProductTypeService) { }

  private _filter(name: String): Airport[] {
    const filterValue = name.toLowerCase();

    return this.airports.filter((option) => option.city.name.toLowerCase().includes(filterValue));
  }
  private _filter2(name: String): Airport[] {
    const filterValue = name.toLowerCase();

    return this.airports.filter((option) => option.city.name.toLowerCase().includes(filterValue));
  }
  displayFn(airport: Airport): String {
    return airport && airport.city.name ? airport.city.name : '';
  }
  displayFn2(airport: Airport): String {
    return airport && airport.city.name ? airport.city.name : '';
  }
  async ngOnInit() {
    // await this.get_flight(12);

    const airportList = await getAirports();
    this.airports = airportList;
    this.airports2 = airportList;
    this.filteredAirports = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter(name) : this.airports.slice())),
    );
    this.filteredAirports2 = this.myControl2.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter2(name) : this.airports2.slice())),
    );

    this.productTypeService.getProductTypes().subscribe((result) => {
      this.productsList = result.data;
    });
  }

  myControl = new FormControl();
  myControl2 = new FormControl();
  origin;
  destination;
  filteredAirports: Observable<Airport[]>
  filteredAirports2: Observable<Airport[]>
  airports: Airport[];
  airports2: Airport[];
  PendingList = []
  async getPendingCons() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getPendingConsignmentByCurrDate/',
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
      .then((response) => response.json())
      .then((result) => {
        this.PendingList = result;
      })
      .catch((error) => {
        console.error('Error', error);
        alert(error);
      });
  }
  selectPendingManifest(pending) {
    this.origin = pending.source;
    this.destination = pending.dest;
    this.get_flight(this.processign_time);
    this.modalService.dismissAll();
  }
  async freezmanifest(id) {
    const TOKEN = this.authService.getAuthToken();
    // alert(id);
    const cons = [];
    for (let i = 0; i < this.manifestConsignemnts.length; i++) {
      cons.push(this.manifestConsignemnts[i].id);
    }
    const freezManifest = {
      manifestID: id,
      consignmentID: cons,
    };
    await fetch(API_URL + '/freezeFinalManifest',
      {
        method: 'POST',
        body: JSON.stringify(freezManifest),
        headers: {
          'Authorization': 'Bearer ' + TOKEN,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then(async (result) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Manifestation Is freezed',
          showConfirmButton: false,
          timer: 5000,
        });
      })
      .catch((error) => {
        console.error('Error', error);
        alert(error);
      });
    this.manifest.freezed = true;
    this.getManifestAPI(this.manifest);
    // this.get_flight(24)
  }

  selectedManualManifest;
  productsList;
  selectedConsignmentTypesList;
  selectedConsignmentSubtypeList;
  selectedProductType;
  selectedConsignmentType;
  selectedConsignmentSubtype;

  selectManualManifestPLan(manifest) {
    this.selectedManualManifest = manifest;
  }

  selectProductType(productType) {
    this.selectedConsignmentTypesList = productType.consignment_types;
    this.selectedConsignmentSubtypeList = [];
    this.selectedConsignmentType = null;
  }

  selectConsignmentType(consignmentType) {
    this.selectedConsignmentSubtypeList = consignmentType.consignment_sub_types_list;
    this.selectedConsignmentSubtype = null;
  }

  selectConsignmentSubtype(consignmentSubtype) {

  }

  async createmanifest(manifest) {
    const TOKEN = this.authService.getAuthToken();
    // alert(id);

    await fetch(API_URL + `/createCustomeManifest?productType=${this.selectedProductType.id}&consignmentType=${this.selectedConsignmentType.id}&consignmentSubtype=${this.selectedConsignmentSubtype.id}`,
      {
        method: 'POST',
        body: JSON.stringify(manifest),
        headers: {
          'Authorization': 'Bearer ' + TOKEN,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        this.selectedConsignmentSubtype = null;
        this.selectedProductType = null;
        this.selectedConsignmentType = null;
        if (response.status == 409) {
          Swal.fire({
            icon: 'error',
            title: 'Manifest already exist',
            showConfirmButton: false,
            timer: 5000,
          });
        } else {
          const result = response.json();
          this.get_flight(this.processign_time);
          this.modalService.dismissAll();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Manifestation Is Created',
            showConfirmButton: false,
            timer: 5000,
          });
          // await this.getManifestAPI(this.manifest)
        }
      })
      .catch((error) => {
        console.error('Error', error);
        alert(error);
      });
  }

  open(con) {
    this.modalService.open(con, {
      size: 'lg',
    });
  }
  formatDate(date) {
    const date2 = new Date(date*1000);
    return (
      [
        this.padTo2Digits(date2.getDate()),
        this.padTo2Digits(date2.getMonth() + 1),
        date2.getFullYear(),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date2.getHours()),
        this.padTo2Digits(date2.getMinutes()),
      ].join(':')
    );
  }
  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }


  formatDate1(date) {
    const date2 = new Date(date);
    return (
      [
        this.padTo2Digits(date2.getDate()),
        this.padTo2Digits(date2.getMonth() + 1),
        date2.getFullYear(),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date2.getHours()),
        this.padTo2Digits(date2.getMinutes()),
      ].join(':')
    );
  }
  timeleft(time) {
    const date2 = new Date((time-150*60)*1000);
    return (
      [
        this.padTo2Digits(date2.getDate()),
        this.padTo2Digits(date2.getMonth() + 1),
        date2.getFullYear(),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date2.getHours()),
        this.padTo2Digits(date2.getMinutes()),
      ].join(':')
    );
  }
  array=[]
  async createManifestService() {
    this.selectedConsignmentSubtype = null;
    this.selectedProductType = null;
    this.selectedConsignmentType = null;
    const TOKEN = this.authService.getAuthToken();
    this.loading=true;
    this.flightSchedule=[];

    await fetch(API_URL + '/flight_schedules/manifest/' + this.origin.id + '/' + this.destination.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
      // Converting received data to JSON
      .then((response) => response.json())
      .then((json) => {
        this.array = Array.from(json.data.bestRoutes);
        this.array= this.array.sort((low, high) => low.totalTranistTime - high.totalTranistTime);
        json.data.bestRoutes=[];
        json.data.bestRoutes=this.array;
        this.flightSchedule = json.data;
      });
    this.loading=false;
  }


  getTranisitTime(time) {
    const hrs = Math.floor(time / 60);
    let mins: any = time % 60;
    mins = Math.round(mins);
    if (mins < 10) {
      mins = '0' + mins;
    }
    return (hrs + ' Hrs ' + mins + ' Mins');
  }
}
