import {Injectable} from '@angular/core';
import {HttpService} from "../../services/http.service";
import {API_URL} from "../../../../Global_API_URL";

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private httpService: HttpService) { }

  saveRegionsWithPatch(requestBody){
    return this.httpService.patch(` ${API_URL}/regions/saveRegions`, requestBody, null, null);
  }

  getStates() {
    return this.httpService.get(API_URL + '/states', null, null);
  }

  getRegions() {
    return this.httpService.get(API_URL + '/regions', null, null);
  }
}
