<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px', animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: '#3f6ad8', secondaryColour: '#ccc' }"
></ngx-loading>
<mat-card style="width: 100%;">
  <!--  <div style="margin-left: 1rem; display: flex; flex-direction: row">-->
  <b class="card-header-title font-size-lg" style="color: blue;">CONSIGNMENT TYPES</b>
  <div style="float: right" *ngIf="!displaytoAccountant"><button mat-raised-button color="primary" style="float: right" (click)="createNewConsignmentType()">Create</button></div>
  <!--  </div>-->
  <table mat-table [dataSource]="consignmentTypes" style="margin-top: 1rem; width: 100%;" class="mat-elevation-z8">
    <!-- Name Column -->
    <ng-container matColumnDef="Name">
      <th style="padding-right: 1rem" mat-header-cell *matHeaderCellDef>Name</th>
      <td style="padding-right: 1rem" mat-cell *matCellDef="let element">{{element?.name}}</td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="Description">
      <th style="padding-right: 1rem" mat-header-cell *matHeaderCellDef>Description</th>
      <td style="padding-right: 1rem" mat-cell *matCellDef="let element">{{element?.description}}</td>
    </ng-container>
    <ng-container matColumnDef="Created">
      <th style="padding-right: 1rem" mat-header-cell *matHeaderCellDef>Created At</th>
      <td style="padding-right: 1rem" mat-cell *matCellDef="let element">{{element?.created_at | date:'dd-MM-yyyy HH:mm:ss'}}</td>
    </ng-container>
    <ng-container matColumnDef="Updated">
      <th style="padding-right: 1rem" mat-header-cell *matHeaderCellDef>Updated At</th>
      <td style="padding-right: 1rem" mat-cell *matCellDef="let element">{{element?.updated_at | date:'dd-MM-yyyy HH:mm:ss'}}</td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef [style.display]="displaytoAccountant ? 'none' : 'table-cell'">Actions</th>
      <td mat-cell *matCellDef="let element">
        <button class="btn-shadow btn btn-primary" matTooltip="Edit" *ngIf="element.is_active && !displaytoAccountant" (click)="patchConsignmentType(element)"><i class="lnr-pencil"></i></button>
        <button class="btn btn-shadow btn-danger" style="margin-left: 1rem;" *ngIf="element.is_active && !displaytoAccountant" matTooltip="Deactivate" (click)="deactivateConsignmentType(element)"><i class="pe-7s-trash"></i></button>
        <button class="btn btn-shadow " style="margin-left: 1rem;  background: #0ba360; color: whitesmoke;" *ngIf="!element.is_active && !displaytoAccountant" matTooltip="Restore" (click)="deactivateConsignmentType(element)"><i class="fa fa-undo"></i></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="productTypeTableColumns"></tr>
    <tr mat-row
        *matRowDef="let row; columns: productTypeTableColumns;let i = index"></tr>
  </table>
</mat-card>
