import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {API_URL} from '../../../../../Global_API_URL';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatTableDataSource} from '@angular/material/table';
import {saveAs} from 'file-saver';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthService} from '../../../auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from '../../../services/http.service';
import {map} from 'rxjs/operators';
import Swal from 'sweetalert2';
import moment from "moment-timezone";
import { ConfirmDialog } from '../../Components/confirmDialog/confirmDialog.component';
import {MatDialog} from "@angular/material/dialog";

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-invoice-history',
  templateUrl: './invoice-history.component.html',
  styleUrls: ['./invoice-history.component.sass'],
})
export class InvoiceHistoryComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loader = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient, private authService: AuthService, private ActivatedRoute: ActivatedRoute, private route: Router, private httpService: HttpService, private matDialog: MatDialog) {
    this.authService.getAuthToken();
    this.filterForm = this.formBuilder.group({
      fromDate: [''],
      toDate: [''],
    });
  }

  userDetails;
  displaytoAccountant=false;
  displaytoAdmin=false;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name == 'Accountant') {
      this.displaytoAccountant=true;
    }
    if (this.userDetails.userTypes[0].name == 'Admin') {
      this.displaytoAdmin=true;
    }
    this.fetchInvoices()
        .subscribe((response) => {
          this.loader = false;
        });
    this.getAllCustomers();
    this.getIndividualList();
  }

  pageview = 'table';
  sender_id = '';
  customers = []
  customerType: string = 'customer';
  filterParams = '';
  showFirstLastButtons;
  filterForm: FormGroup;
  dataSource = new MatTableDataSource<any>([]);
  pageSizeOptions = [5, 10, 20];


  displayedColumns: string[] = [
    'invoiceNo',
    'customerName',
    'consignmentNo',
    'type',
    'mode',
    'createdAt',
    'updatedAt',
    'Active',
  ];

  onCustomerTypeChange() {
    this.pageNo = 0;
    this.filterForm.get('fromDate').reset('');
    this.filterForm.get('toDate').reset('');
    this.sender_id = '';
    if (this.customerType === 'customer') {
      this.getAllCustomers();
    } else if (this.customerType === 'retail') {
      this.getIndividualList();
    }
    this.sender_id = '';
    this.loader = true;
    this.fetchInvoices()
        .subscribe((response) => {
          this.loader = false;
        });
  }

  corporateCustomers: any[] = [];
  individualCustomers: any[] = [];

  getAllCustomers() {
    this.httpService.get(API_URL + '/totalcustomers/', null, null)
        .subscribe((response) => {
          this.corporateCustomers = response.map((customer: any) => ({
            id: customer.id,
            firstName: customer.firstName, // Adjust property names if needed
          }));
        }, (error) => {
          this.corporateCustomers = []; // Reset array in case of an error
        });
  }

  getIndividualList() {
    this.httpService.get(API_URL + '/individual_user', null, null)
        .subscribe((response) => {
          this.individualCustomers = response.map((user: any) => ({
            id: user.id,
            firstName: user.firstName, // Adjust property names if needed
          })).sort((a, b) => a.firstName.localeCompare(b.firstName)); 
        }, (error) => {
          this.individualCustomers = []; // Reset array in case of an error
        });
  }

  async onCustomerSelected(): Promise<void> {
    this.loader = true;
    this.pageNo = 0;
    const fromDate = this.filterForm.get('fromDate').value;
    const toDate = this.filterForm.get('toDate').value;
    this.fetchInvoices(this.pageNo, this.pageSize, 'desc', 'created_at', fromDate, toDate).subscribe();
  }

  getConsignmentList(invoice: any): string {
    if (invoice?.billingPrices && invoice.billingPrices.length > 0) {
      const consignmentNos = invoice.billingPrices.map((billingPrice) => billingPrice?.consignment?.consignment_no);
      return consignmentNos.join(', ');
    }
    return '';
  }

  onPaginateChange(event: PageEvent): void {
    this.loader = true;
    this.pageNo = event.pageIndex; // Update the pageIndex from the emitted event
    this.pageSize = event.pageSize; // Update the pageSize as well if needed
    this.fetchInvoices(event.pageIndex, event.pageSize, 'desc', 'created_at', this.filterForm.get('fromDate').value, this.filterForm.get('toDate').value).subscribe(); // Fetch invoices based on the updated pagination values
  }

  invoices: any[] = [];
  totalInvoices: number = 0;
  pageSize: number = 10; // Set your default page size
  pageNo: number = 0;

  fetchInvoices(pageNo = 0, pageSize = 10, sortOrder = 'desc', sortOn = 'created_at', fromDate?: Date, toDate?: Date) {
    let url = `${API_URL}/invoicesgenerated`;
    const queryParams = new URLSearchParams();
    queryParams.set('pageNo', pageNo.toString());
    queryParams.set('pageSize', pageSize.toString());
    queryParams.set('sortOn', 'created_at');
    queryParams.set('sortOrder', 'desc');

    if (fromDate) {
      const fromDate = moment(this.filterForm.get('fromDate').value).utc(true).toISOString();
      queryParams.set('from', fromDate);
    }
    if (toDate) {
      const toDate = moment(this.updateEndDate(this.filterForm.get('toDate').value)).utc(true).toISOString();
      queryParams.set('to', toDate);
    }
    if (this.customerType === 'customer') {
      const selectedCustomerId = this.sender_id;
      queryParams.set('senderId', selectedCustomerId);
      queryParams.set('isRetail', '0');
    } else if (this.customerType === 'retail') {
      const selectedCustomerId = this.sender_id;
      queryParams.set('senderId', selectedCustomerId);
      queryParams.set('isRetail', '1');
    }
    if (this.filterParams !== '') {
      queryParams.append('filterParams', this.filterParams);
    }
    url += `?${queryParams.toString()}`;
    return this.httpService.get(url, null, null)
        .pipe(map(((response) => {
          if ('data' in response && Array.isArray(response['data']['content'])) {
            this.invoices = response['data']['content'];
            this.totalInvoices = response['data']['page']['totalElements'] ?? 0; // Use the provided totalItems or default to 0
          }
          this.loader = false;
          return this.invoices;
        })));
  }

  onDateChange(dateType: 'fromDate' | 'toDate') {
    this.loader = true;
    this.pageNo = 0;
    const fromDate = this.filterForm.get('fromDate').value;
    const toDate = this.filterForm.get('toDate').value;
    this.fetchInvoices(0, 10, 'desc', 'created_at', fromDate, toDate).subscribe();
  }


  downloadPDFByKey(invoiceKey: string, invoiceNo: string) {
    this.httpService.getBlob(API_URL + `/getInvoicesByKey?key=${invoiceKey}`, null, null)
        .subscribe((response: Blob) => {
          const pdfBlob = new Blob([response], {type: 'application/pdf'});
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          const a = document.createElement('a');
          a.href = pdfUrl;
          a.download = `invoice_${invoiceNo}.pdf`;
          a.click();
          window.URL.revokeObjectURL(pdfUrl);
        });
  }
  getConsignmentCount(invoice: any): number {
    return invoice?.billingPrices?.length || 0;
  }

  getConsignmentTooltip(invoice: any): string {
    if (invoice?.billingPrices && invoice.billingPrices.length > 0) {
      const consignmentNos = invoice.billingPrices.map((billingPrice) => billingPrice?.consignment?.consignment_no);
      return consignmentNos.join(', ');
    }
    return '';
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date) {
    const date2 = new Date(date);
    return (
      [
        date2.getFullYear(),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date2.getHours()),
        this.padTo2Digits(date2.getMinutes()),
      ].join(':')
    );
  }

  exportToCSV() {

    const fromDate = moment(this.filterForm.get('fromDate').value).utc(true).toISOString();
    const toDate = moment(this.updateEndDate(this.filterForm.get('toDate').value)).utc(true).toISOString();
    this.loader = true;
    let params = new HttpParams();
    if (this.customerType === 'customer') {
      const selectedCustomerId = this.sender_id;
      params = params.set('senderId', selectedCustomerId);
      params = params.set('isRetail', '0');
    } else if (this.customerType === 'retail') {
      const selectedCustomerId = this.sender_id;
      params = params.set('senderId', selectedCustomerId);
      params = params.set('isRetail', '1');
    }
    if(fromDate!=null) params = params.set('from', fromDate)
    if(toDate!=null) params = params.set('to', toDate)
    let headers = new HttpHeaders({
      'Accept': 'text/csv'
    })
    this.httpClient.get(API_URL + "/invoices/csvReport", {
      'params': params,
      'responseType': 'text'
    }).subscribe(response => {
      this.downloadCsv(response, "invoiceHistory.csv")
      this.loader = false;
    });
  }

  resetForm() {
    this.loader = true;
    this.onCustomerTypeChange();
  }

  gotoIrnPage(id) {
    this.route.navigate(['/tallyIrnGenerate', id]);
  }

  goToInvoiceViewPage(id) {
    this.route.navigate(['/tallyIrnGenerateView', id]);
  }

  downloadCsv(csvData: string, fileName: string): void {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  // Method to update the end date with time set to 23:59:59
  updateEndDate(event: Date)  {
      const endDate = new Date(event);
      endDate.setHours(23, 59, 59, 0); // Set the time to 23:59:59.999
    return endDate;
  }


  // Method to update the start date
  updateStartDate(event: Date): void {
    if (event) {
      const startDate = new Date(event);
      startDate.setHours(0, 0, 0, 0);
      // this.selectedStartDate = startDate;
    }
  }


  generateIRN(invoice) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to Generate IRN ' + invoice.invoiceNo + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.generateIRNAPI(invoice);
      }
    });
  }


  customerData;
  getCustomerById(invoiceId, custId) {
    this.httpService.get(API_URL + "/customer/" + custId, null, null)
      .subscribe(response => {
        this.customerData = response.custData[0];
        if (response.custData[0].gst == null || response.custData[0].gst == ""){
          Swal.fire({
            title: 'Warning',
            icon: 'warning',
            text: "Enter GST No. for this customer",
            timer: 5000,
          })
        }else{
          this.generateIRNAPI(invoiceId)
        }
      })
  }


  generateIRNAPI(invoice){
    this.loader = true;
    let params = new HttpParams();
    params = params.set('invoice_id', invoice.id);
    this.httpService.post(API_URL + "/mastergst/irnGeneration", null, params, null)
      .subscribe((response) => {
        this.loader = false;
        invoice.key = response.key;
        invoice.isIrnGenerated =response.isIrnGenerated;
        Swal.fire({
          title: 'Success',
          icon: 'success',
          text: 'IRN Generated Successfully',
          timer: 5000,
        })
      }, error => {
        this.loader = false;
        
        // Check if error has a message from backend
        let errorMessage = 'Something went wrong';
        if (error.error && error.error.message) {
          errorMessage = error.error.message; // Custom backend error message
        } else if (error.message) {
          errorMessage = error.message; // Default error message
        }
  
        Swal.fire({
          title: 'Warning',
          icon: 'warning',
          text: errorMessage,
          timer: 5000,
        });
      });
  }
  }

