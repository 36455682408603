import {Component, OnInit} from '@angular/core';

// import { faRefresh } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dots',
  templateUrl: './dots.component.html',
})
export class DotsComponent implements OnInit {
  // faRefresh = faRefresh;

  constructor() { }

  ngOnInit() {
  }
}
