<div class="col-md-12">
	<container-element [ngSwitch]="pageview">
		<some-element *ngSwitchCase="'table'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-Bold">
						<b style="color: blue;">MANIFESTATIONS</b>
					</div>
					<div class="btn-actions-pane-right p-2">
						<a [routerLink]="['/createmanifest']" class="mb-2 me-2 btn btn-primary">Create</a>
					</div>
				</div>
				<div class="row p-4">
					<div class="col-md-2 row">
						<mat-form-field appearance="standard">
							<mat-label>
								Origin Location
							</mat-label>
							<input type="text" placeholder="Origin Location" aria-label="Number" matInput
								[formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="source">
							<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn2">
								<mat-option *ngFor="let cit of filteredAirports | async" [value]="cit">
									{{cit.city.name}} - {{cit.iataCode}}
								</mat-option>
							</mat-autocomplete>
						</mat-form-field>
						<mat-hint class="text-bold text-success" *ngIf="source?.name != null"
							style="position: absolute; margin-top: 55px; font-size: smaller;">
							{{source?.name}} - {{source?.iataCode}}
						</mat-hint>
					</div>
					<div class="col-md-2 row">
						<mat-form-field appearance="standard">
							<mat-label>
								Destination Location
							</mat-label>
							<input type="text" placeholder="Destination Location" aria-label="Number" matInput
								[formControl]="myControl1" [matAutocomplete]="auto1" [(ngModel)]="destination">
							<mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn3">
								<mat-option *ngFor="let cit of filteredAirports1 | async" [value]="cit">
									{{cit.city.name}} - {{cit.iataCode}}
								</mat-option>
							</mat-autocomplete>
						</mat-form-field>
						<mat-hint class="text-bold text-success" *ngIf="destination?.name != null"
							style="position: absolute; margin-top: 55px; font-size: smaller;">
							{{destination?.name}} - {{destination?.iataCode}}
						</mat-hint>
					</div>
					<div class="col-2 col-md-2">
						<mat-form-field appearance="standard">
							<mat-label>AWB Number</mat-label>
							<input matInput [(ngModel)]="awb_numbers" placeholder="AWB Number" #input>
						</mat-form-field>
					</div>
					<div class="col-2 col-md-2">
						<mat-form-field appearance="standard">
							<mat-label>Flight Number</mat-label>
							<input matInput [(ngModel)]="flight_number" placeholder="Flight Number" #input>
						</mat-form-field>
					</div>
					<div class="col-lg-2 col-md-2 row">
						<button class="btn btn-primary m-auto" (click)="tablefilter()">
							Find
							<i class="pe-7s-search">
							</i>
						</button>
					</div>
					<div class="col-lg-2 col-md-1  row m-1">
						<button class="btn btn-secondary m-auto" (click)="resetForm()">
							Reset
						</button>
					</div>
				</div>

				<!-- <div class="container">
					<div class="row">
						<div class="" *ngIf="manifestations!=[]">
							<div class="table-responsive">
								<table class="align-middle text-truncate mb-0 table table-borderless table-hover"
									*ngIf="manifestations">
									<thead>
										<tr>
											<th class="text-center">Id</th>
											<th class="text-center">Awb Number</th>
											<th class="text-center">Consignments</th>
											<th class="text-center">Flight</th>
											<th class="text-center">Origin</th>
											<th class="text-center">Destination</th>
											<th class="text-center">Created Date</th>
											<th class="text-center">Manifest Leg1</th>
											<th class="text-center">Manifest Leg2</th>
											<th class="text-center">Action</th>



										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let manifest of manifestations.content">
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view',manifest)">
													{{manifest.id}}
												</a>
											</td>
											<td class="text-center">
												{{manifest.awb_number}}
											</td>
											<td class="text-center">
												{{manifest.consignments.length}}
											</td>
											<td class="text-center">
												{{manifest.flightScheduleLeg1.flightNo}}
											</td>
											<td class="text-center">
												{{manifest.source.iataCode}}
											</td>
											<td class="text-center">
												{{manifest.destination.iataCode}}
											</td>
											<td class="text-center">
												<div *ngIf="manifest.createdAt!=null">
													{{formatDate(manifest?.createdAt)}}
												</div>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view',manifest)">
													{{manifest.manifestIdLeg1}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view',manifest)">
													{{manifest.manifestIdLeg2}}
												</a>
											</td>
											<td class="text-center">
												<div class="d-flex">
													<div role="group" class="btn-group-sm btn-group me-2">
														<button class="btn-shadow btn btn-primary" matTooltip="Edit"
															(click)="viewchange('edit',manifest)"><i
																class="lnr-pencil"></i></button>
													</div>
													<div role="group"
														*ngIf="manifest.flightScheduleLeg1.airlineID.id==1 "
														class="btn-group-sm btn-group">
														<button class="btn-shadow btn btn-primary"
															*ngIf="manifest.awb_number == null"
															(click)="viewchange('MAWB',manifest)">MAWB</button>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<mat-paginator (page)="handlePageEvent($event)" [length]="manifestations?.totalElements"
									[pageSize]="manifestations?.numberOfElements"
									[showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
									[pageIndex]="manifestations?.number" aria-label="Select page">
								</mat-paginator>
							</div>
						</div>
					</div>
				</div> -->
				<div class="" *ngIf="manifestations!=[]">
					<div class="table-responsive">
						<table class="w-100" mat-table [dataSource]="dataSource" *ngIf="manifestations" matSort
							(matSortChange)="announceSortChange($event)">
							<ng-container matColumnDef="id">
								<th class="text-center" mat-header-cell *matHeaderCellDef
									sortActionDescription="Sort by id" style="width: 16%;">Id</th>
								<td class="text-center" mat-cell *matCellDef="let element">
									<a [routerLink]="" (click)="viewchange('view',element)">
										{{element.id}}
									</a>
								</td>
							</ng-container>
							<ng-container matColumnDef="awbnumber">
								<th class="text-center" mat-header-cell *matHeaderCellDef
									sortActionDescription="Sort by awbnumber">AWB Number</th>
								<td class="text-center" mat-cell *matCellDef="let element">{{element.awbNumber}}</td>
							</ng-container>
							<ng-container matColumnDef="consignments">
								<th class="text-center" mat-header-cell *matHeaderCellDef
									sortActionDescription="Sort by consignments">Consignments</th>
								<td class="text-center" mat-cell *matCellDef="let element">
									{{element.consignments.length}} </td>
							</ng-container>
							<ng-container matColumnDef="flight">
								<th class="text-center" mat-header-cell *matHeaderCellDef
									sortActionDescription="Sort by flight">Flight</th>
								<td class="text-center" mat-cell *matCellDef="let element">
									{{element.flightScheduleLeg1.flightNo}} </td>
							</ng-container>
							<ng-container matColumnDef="source">
								<th class="text-center" mat-header-cell *matHeaderCellDef
									sortActionDescription="Sort by source">Source</th>
								<td class="text-center" mat-cell *matCellDef="let element">
									{{element.source.iataCode}}</td>
							</ng-container>
							<ng-container matColumnDef="destination">
								<th class="text-center" mat-header-cell *matHeaderCellDef
									sortActionDescription="Sort by destination">Destination</th>
								<td class="text-center" mat-cell *matCellDef="let element">
									{{element.destination.iataCode}}</td>
							</ng-container>
              <ng-container matColumnDef="manifestationleg1">
                <th class="text-center" mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by manifestationleg1">Manifest Leg 1</th>
                <td class="text-center" mat-cell *matCellDef="let element">
                  <a [routerLink]="" (click)="viewchange('view',element)" matTooltip="{{element.manifestIdLeg1}}">
                    {{element?.manifestIdLeg1 | slice:0:22}}{{element?.manifestIdLeg1?.length > 22 ? '...' : ''}}
                  </a>
                </td>
              </ng-container>
              <ng-container matColumnDef="manifestationleg2">
                <th class="text-center" mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by manifestationleg2">Manifest Leg 2</th>
                <td class="text-center" mat-cell *matCellDef="let element">
                  <a [routerLink]="" (click)="viewchange('view',element)" matTooltip="{{element.manifestIdLeg2}}">
                    {{element?.manifestIdLeg2 | slice:0:22}}{{element?.manifestIdLeg2?.length > 22 ? '...' : ''}}
                  </a>
                </td>
              </ng-container>
              <ng-container matColumnDef="Active">
								<th mat-header-cell *matHeaderCellDef > Action </th>
								<td mat-cell *matCellDef="let element">
									<div class="d-flex">
										<div role="group" class="btn-group-sm btn-group me-2">
											<button class="btn-shadow btn btn-primary" matTooltip="Edit"
												(click)="viewchange('edit',element)"><i class="lnr-pencil"></i></button>
										</div>
										<div role="group" *ngIf="element.flightScheduleLeg1.airlineID.id==1 "
											class="btn-group-sm btn-group">
											<button class="btn-shadow btn btn-primary"
												*ngIf="element.awb_number == null"
												(click)="viewchange('MAWB',element)">MAWB</button>
										</div>
									</div>
								</td>
							</ng-container>
							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"
								[ngClass]="row.awb_number == false ? 'red':'white'"></tr>
						</table>
						<!-- <mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
						</mat-paginator> -->
						<mat-paginator (page)="handlePageEvent($event)" [length]="manifestations?.totalElements"
							[pageSize]="manifestations?.numberOfElements" [showFirstLastButtons]="showFirstLastButtons"
							[pageSizeOptions]="pageSizeOptions" [pageIndex]="manifestations?.number"
							aria-label="Select page">
						</mat-paginator>
					</div>
				</div>
				<ngx-loading [show]="loading"
					[config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
					[template]="loadingTemplate"></ngx-loading>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'add'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						Add Manifest
					</div>

				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>
									Manifest Name
								</mat-label>
								<input type="text" placeholder="Manifest Name" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>Country</mat-label>
								<select matNativeControl id="">
									<option *ngFor="let manifest of countries |async" [value]="manifest.id">
										{{manifest_view.manifest_id}}
									</option>
								</select>
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>State</mat-label>
								<select matNativeControl id="">
									<option *ngFor="let manifest of states |async" [value]="manifest.id">
										{{manifest_view.flight_schedule_id.departureAirportID.iataCode}}
									</option>
								</select>
							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');tablefilter()">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="viewchange('table')">Add</div>
					</div>
				</div>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'MAWB'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						<b style="color: blue;">GET MAWB</b>
					</div>

				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-4">

							<mat-form-field class="py-0" appearance="standard" style="width: 250px;">
								<mat-label>Shipper Name<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl (selectionChange)="getUserDetails($event)">
									<mat-option *ngFor="let cous of MawbUsers.data" [value]="cous.id">
										{{cous.agentCode}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>

						<div class="col-md-4" [style.filter]="showReceiverForm ? 'blur(5px)' : 'none'">
							<mat-form-field class="py-0" appearance="standard" style="width: 250px;">
								<mat-label>Receiver Name<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl (selectionChange)="getReceiverDetails($event)">
									<mat-option *ngFor="let cous of MawbReceivers.data" [value]="cous.id">
										{{cous.agentCode}}
									</mat-option>
								</mat-select>
							</mat-form-field>
							<button mat-icon-button (click)="showReceiverForm = true">
								<mat-icon>person_add</mat-icon>
							</button>
							<!-- <button mat-button (click)="loadReceivers($event)">Load</button> -->
						</div>

						<div *ngIf="showReceiverForm" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); z-index: 9999;">
							<div class="receiver-form-container" style="position: absolute; top: 35%; left: 50%; transform: translate(-50%, -50%); background-color: white; padding: 20px; border: 1px solid #ccc; width: 1000px; height: 600px;">
							<h2 style="text-align: center; color: blue;"><strong>Create MAWB Receiver</strong></h2>
							<button mat-icon-button style="position: absolute; top: 5px; right: 5px;"
								(click)="cancelAddingReceiver()">
								<mat-icon>close</mat-icon>
							</button>
							<div class="row">
								<!-- <div class="col-md-3">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Login ID<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.loginId" name="loginId"
											placeholder="Login ID">
									</mat-form-field>
								</div> -->
								<!-- <div class="col-md-4">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Name of the Receiver<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.userName" name="userName"
											placeholder="User Name">
									</mat-form-field>
								</div> -->
								<!-- <div class="col-md-4">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Role Name<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.roleName" name="roleName"
											placeholder="Role Name">
									</mat-form-field>
								</div> -->
								<!-- <div class="col-md-4">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Base Station<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.baseStation" name="baseStation"
											placeholder="Base Station">
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Agent Code<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.agentCode" name="agentCode"
											placeholder="Agent Code">
									</mat-form-field>
								</div> -->
								<!-- <div class="col-md-3 d-flex align-items-center">
									<mat-slide-toggle [(ngModel)]="newReceiver.isAllStn"
										style="margin-right: 5px;"></mat-slide-toggle>
									<mat-label>Is All STN<span class="text-danger">*</span></mat-label>
								</div> -->
							</div>
							<div class="row">
								<div class="col-md-4">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Email<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.emailId" name="emailId"
											placeholder="Email Id" [formControl]="emailFormControl">
										<mat-hint  class="text-danger fw-normal" *ngIf="emailFormControl.hasError('pattern')">Invalid email address</mat-hint>
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Mobile Number<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.mobileNumber" name="mobileNumber"
											onkeypress="return event.charCode >= 48 && event.charCode <= 57"
											#mobilenumber maxlength="10" placeholder="Mobile Number">
										<mat-hint align="end">{{mobilenumber.value.length}}/10</mat-hint>
									</mat-form-field>
								</div>
								<!-- <div class="col-md-3">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Station Code<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.stationCode" name="stationCode"
											placeholder="Station Code">
									</mat-form-field>
								</div> -->
								<!-- <div class="col-md-4">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Agent Code<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.agentCode" name="agentCode"
											placeholder="Agent Code">
									</mat-form-field>
								</div> -->
							</div>
							<div class="row">
								<!-- <div class="col-md-4">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Base Station<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.baseStation" name="baseStation"
											placeholder="Base Station">
									</mat-form-field>
								</div> -->
								<!-- <div class="col-md-3">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Customer Code<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.customerCode" name="customerCode"
											placeholder="Customer Code">
									</mat-form-field>
								</div>
								<div class="col-md-3">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>IATA Agent Code<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.iataAgentCode" name="iataAgentCode"
											placeholder="IATA Agent Code">
									</mat-form-field>
								</div> -->
								<!-- <div class="col-md-4">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Contact Person<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.contactPerson" name="contactPerson"
											placeholder="Contact Person">
									</mat-form-field>
								</div> -->
							</div>
							<div class="row">
								<h5><strong>DELIVERY ADDRESS - </strong></h5>
								<div class="col-md-4">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Address Line 1<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.address" name="address"
											placeholder="Address">
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Country<span class="text-danger">*</span></mat-label>
										<mat-select [(ngModel)]="newReceiver.country" name="country"
											placeholder="Country" (ngModelChange)="onCountrySelected($event)">
											<mat-option *ngFor="let country of countries" [value]="country.id">{{
												country.name }}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>State<span class="text-danger">*</span></mat-label>
										<mat-select [(ngModel)]="newReceiver.state" name="state" placeholder="State"
											(ngModelChange)="onStateChange($event.id)">
											<mat-option *ngFor="let state of states" [value]="state">{{ state.name
												}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>City<span class="text-danger">*</span></mat-label>
										<mat-select [(ngModel)]="newReceiver.city" name="city" placeholder="City"
											(ngModelChange)="onCityChange($event.id)">
											<mat-option *ngFor="let city of cities" [value]="city">{{ city.name
												}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Zipcode<span class="text-danger">*</span></mat-label>
										<mat-select [(ngModel)]="newReceiver.zipCode" name="zipCode"
											placeholder="Zipcode">
											<mat-option *ngFor="let pincode of pincodes" [value]="pincode.id">{{
												pincode.pinNumber }}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<h5><strong>BILLING ADDRESS - </strong></h5>
								<div class="col-md-4">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Billing Address<span class="text-danger">*</span></mat-label>
										<input matInput [(ngModel)]="newReceiver.billingAddress" name="billingAddress"
											placeholder="Billing Address">
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Billing Country<span class="text-danger">*</span></mat-label>
										<mat-select [(ngModel)]="newReceiver.billingCountry" name="billingCountry"
											placeholder="Billing Country"
											(ngModelChange)="onBillingCountrySelected($event)">
											<mat-option *ngFor="let country of billingCountries" [value]="country.id">{{
												country.name }}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Billing State<span class="text-danger">*</span></mat-label>
										<mat-select [(ngModel)]="newReceiver.billingState" name="billingState"
											placeholder="Billing State"
											(ngModelChange)="onBillingStateChange($event.id)">
											<mat-option *ngFor="let state of billingStates" [value]="state">{{
												state.name
												}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Billing City<span class="text-danger">*</span></mat-label>
										<mat-select [(ngModel)]="newReceiver.billingCity" name="billingCity"
											placeholder="Billing City" (ngModelChange)="onBillingCityChange($event.id)">
											<mat-option *ngFor="let city of billingCities" [value]="city">{{ city.name
												}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field class="py-0" appearance="standard" style="width: 100%;">
										<mat-label>Billing Zipcode<span class="text-danger">*</span></mat-label>
										<mat-select [(ngModel)]="newReceiver.billingZipCode" name="zipCode"
											placeholder="Zipcode">
											<mat-option *ngFor="let pincode of billingPincodes" [value]="pincode.id">{{
												pincode.pinNumber }}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
							<div style="position: absolute; bottom: 10px; right: 10px;">
								<button mat-button class="mb-2 me-2 btn btn-danger" (click)="cancelAddingReceiver()">Cancel</button>
								<button mat-button class="mb-2 me-2 btn btn-primary" (click)="addReceiver()">Save</button>
							</div>
						</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4 p-3">
							<div class="h4 fw-bold">
								Shipper Details
							</div>
							<div class="row">
								<div class="col-md-4 fw-bold">
									User Name:
								</div>
								<div class="col-md-6">
									{{shipperDetails.userName}}
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 fw-bold">
									Role Name:
								</div>
								<div class="col-md-6">
									{{shipperDetails.roleName}}
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 fw-bold">
									Base Station:
								</div>
								<div class="col md-6">
									{{shipperDetails.baseStation}}
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 fw-bold">
									Address:
								</div>
								<div class="col-md-6">
									{{shipperDetails.address}}
								</div>
							</div>



						</div>
						<div class="col-md-4 p-3">
							<div class="h4 fw-bold">
								Receiver Details
							</div>
							<div class="row">
								<div class="col-md-4 fw-bold">
									User Name:
								</div>
								<div class="col-md-6">
									{{receiverDetails.userName}}
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 fw-bold">
									Role Name:
								</div>
								<div class="col-md-6">
									{{receiverDetails.roleName}}
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 fw-bold">
									Base Station:
								</div>
								<div class="col md-6">
									{{receiverDetails.baseStation}}
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 fw-bold">
									Address:
								</div>
								<div class="col-md-6">
									{{receiverDetails.address}}
								</div>
							</div>


						</div>
						<div class="col-md-3">

							<mat-form-field style="width: 90%;" appearance="standard">
								<mat-label>
									Flight Date
								</mat-label>
								<input id="" matInput [(ngModel)]="flightdate.formattedDate" [matDatepicker]="pickup"
									disabled>
								<mat-datepicker-toggle matSuffix [for]="pickup"></mat-datepicker-toggle>
								<mat-datepicker #pickup disabled="false"></mat-datepicker>
							</mat-form-field>

							<div class="row p-2">
								<div class="col-md-4">
									<div class="text-bold"><b>Prefix Code</b></div>
									<input readonly style="width: 40px;" placeholder="MAWB Number"
										[(ngModel)]="flightPrifix" class="form-control mt-2 text-center">
								</div>
								<div class="col-md-5">
									<div class="text-bold"><b>Flight Number</b></div>
									<input style="width: 100px;" placeholder="Flight Number" [(ngModel)]="FLightNo"
										oninput="if(!this.value.match('^[0-9]*$'))this.value='';" maxlength="4"
										class="form-control mt-2">

								</div>
								<div class="col-md-3 d-flex align-items-end">
									<div class="text-bold">

									</div>
									<div class="btn-actions-pane-right">
										<button class="fa fa-search btn btn-primary" (click)="getScheduleId()"
											aria-hidden="true"></button>
									</div>
								</div>
								<div class="text-danger" *ngIf="schedules.ErrorDesc != null">
									{{schedules.ErrorDesc}}
								</div>
								<div class="text-success fw-bold" *ngIf="schedules.ScheduleID != null">
									Avaliable
								</div>

							</div>
						</div>

					</div>

				</div>
				<mat-divider></mat-divider>
				<div class="container">
					<div class="row">

						<div class="col-md-4">

							<mat-form-field class="py-0" appearance="standard" style="width: 250px;">
								<mat-label>Product Type<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl (selectionChange)="getProductDetails($event)">
									<mat-option *ngFor="let cous of Product.data" [value]="cous.id">
										{{cous.productType}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>

						<div class="col-md-4">

							<mat-form-field class="py-0" appearance="standard" style="width: 250px;">
								<mat-label>Product Commodity<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl (selectionChange)="getCommodityDetails($event)">
									<mat-option *ngFor="let cous of commodity.data" [value]="cous.id">
										{{cous.commodityName}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="row">
							<div class="col-md-4 p-3">
								<div class="h4 fw-bold">
									Product Details
								</div>
								<div class="row">
									<div class="col-md-6 fw-bold">
										Product Description:
									</div>
									<div class="col-md-3">
										{{productDetails.productDescription}}
									</div>
								</div>
							</div>
							<div class="col-md-4 p-3">
								<div class="h4 fw-bold">
									Commodity Details
								</div>
								<div class="row">
									<div class="col-md-7 fw-bold">
										Commodity Description:
									</div>
									<div class="col-md-3">
										{{commodityDetails.commodityDescription}}
									</div>
								</div>
								<div class="row">
									<div class="col-md-7 fw-bold">
										Commodity Code:
									</div>
									<div class="col-md-3">
										{{commodityDetails.commodityCode}}
									</div>
								</div>
								<div class="row">
									<div class="col-md-7 fw-bold">
										SHC Code:
									</div>
									<div class="col md-3">
										{{commodityDetails.shcCode}}
									</div>
								</div>
								<div class="row">
									<div class="col-md-7 fw-bold">
										Is Notoc:
									</div>
									<div class="col-md-3">
										{{commodityDetails.isNotoc}}
									</div>
								</div>


							</div>
							<div class="col-md-4 p-3">
								<div class="h4 fw-bold">
									Flight Details
								</div>
								<div class="row">
									<div class="col-md-4 fw-bold">
										Flight Date:
									</div>
									<div class="col-md-4">
										{{manifest_view.shippingTime *1000 | date:'dd/MM/yyyy'}}
									</div>
								</div>
								<div class="row">
									<div class="col-md-4 fw-bold">
										Flight Number:
									</div>
									<div class="col-md-3">
										{{manifest_view.flightScheduleLeg1.flightNo}}
									</div>
								</div>
								<div class="row">
									<div class="col-md-4 fw-bold">
										Origin:
									</div>
									<div class="col md-3">
										{{manifest_view.source.iataCode}}
									</div>
								</div>
								<div class="row">
									<div class="col-md-4 fw-bold">
										Destination:
									</div>
									<div class="col-md-3">
										{{manifest_view.destination.iataCode}}
									</div>
								</div>
								<div class="row">
									<div class="col-md-4 fw-bold">
										ScheduleID:
									</div>
									<div class="col-md-3 fw-bold">
										{{schedules.ScheduleID}}
									</div>
								</div>


							</div>


						</div>


					</div>
				</div>
				<mat-divider></mat-divider>
				<div class="container">

					<div class="row" style="width: 100%;">

						<div class="table-responsive">
							<table class="table p-2 ">
								<thead>
									<tr>
										<th style="width: 14%;">
											Package Dimensions <span class="text-danger">*</span>
										</th>
										<th style="width: 10%;">
											Volume
										</th>
										<th style="width: 10%;">
											No of Packages <span class="text-danger">*</span>
										</th>
										<th style="width: 10%;">
											Gross Weight <span class="text-danger">*</span>
										</th>
										<th style="width: 10%;">
											Chargeable Weight
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="mx-w-150">
											<div class="row">

												<!-- <mat-form-field class="m-0" style="width: 25%;" appearance="standard"> -->
												<div class="col-md-3">

													<input type="text" placeholder="L" aria-label="Number" matInput
														[(ngModel)]="mawbPackages.length" min="1" (keypress)="validateInput($event)" maxlength="5"
														(input)="eachPackageCharged()">
													<span matSuffix>{{selectedDimention}}</span>
												</div>
												<div class="col-md-3">

													<!-- </mat-form-field> -->
													<!-- <mat-form-field class="p-1" style="width: 25%;" appearance="standard"> -->
													<input type="text" placeholder="B" aria-label="Number" matInput
														[(ngModel)]="mawbPackages.breadth" min="1" (keypress)="validateInput($event)" maxlength="5"
														(input)="eachPackageCharged()">
													<span matSuffix>{{selectedDimention}}</span>
												</div>
												<div class="col-md-3">

													<!-- </mat-form-field> -->
													<!-- <mat-form-field class="p-1" style="width: 25%;" appearance="standard"> -->

													<input type="text" placeholder="H" aria-label="Number" matInput
														[(ngModel)]="mawbPackages.height" min="1" (keypress)="validateInput($event)" maxlength="5"
														(input)="eachPackageCharged()">
													<span matSuffix>{{selectedDimention}}</span>
													<!-- </mat-form-field> -->
												</div>
											</div>
										</td>
										<td class="mx-w-150">
											<mat-form-field class="py-0" style="width: 60%;" appearance="standard">
												<input type="text" placeholder="Volume" matInput disabled
													[(ngModel)]="mawbPackages.volume_weight">
											</mat-form-field>
										</td>
										<td class="mx-w-150">
											<mat-form-field class="py-0" style="width: 60%;" appearance="standard">
												<input type="text" placeholder="No of Packages" aria-label="Number" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
													matInput min="1" maxlength="8" [(ngModel)]="mawbPackages.packageNo">

											</mat-form-field>
										</td>
										<td class="mx-w-150">

											<mat-form-field class="py-0" style="width: 60%;" appearance="standard">
												<input type="text" placeholder="Gross weight" aria-label="Number" (keypress)="validateInput($event)"
													[(ngModel)]="mawbPackages.total_weight" matInput min="1" maxlength="8">
												<span matSuffix>{{ selectedWeight}}</span>
											</mat-form-field>
										</td>
										<td class="mx-w-200">
											<mat-form-field class="py-0" style="width: 70%;" appearance="standard">
												<input placeholder="Chargeable Weight" matInput (keypress)="validateInput($event)" maxlength="8"
													[(ngModel)]="mawbPackages.chargeable_weight">
												<span matSuffix>{{ selectedWeight}}</span>
											</mat-form-field>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="table-responsive">
							<table class="table p-2 ">
								<thead>
									<tr>
										<th style="width: 0%;">
											Customs <span class="text-danger">*</span>
										</th>
										<th style="width: 10%;">
											Declared Value
										</th>
										<th>

										</th>
									</tr>
								</thead>
								<tbody>
									<tr>

										<td class="mx-w-150">
											<mat-form-field class="py-0" style="width: 60%;" appearance="standard">
												<input type="text" placeholder="Customs" matInput (keypress)="validateInput($event)" maxlength="10"
													[(ngModel)]="mawbPackages.customs">
											</mat-form-field>
										</td>
										<td class="mx-w-150">
											<mat-form-field class="py-0" style="width: 60%;" appearance="standard">
												<input type="text" placeholder="Delared Value" aria-label="Number" (keypress)="validateInput($event)" maxlength="10"
													matInput [(ngModel)]="mawbPackages.DeclaredValue">
											</mat-form-field>
										</td>
									</tr>
								</tbody>
							</table>
						</div>


					</div>

				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-success" (click)="createMawbNumber()">Generate MAWB</div>
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');tablefilter()">Cancel</div>
					</div>
				</div>
				<ngx-loading [show]="loadingSecound"
					[config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
					[template]="loadingTemplate"></ngx-loading>
			</div>

		</some-element>

		<some-element *ngSwitchCase="'edit'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-Bold">
						<b style="color: blue;">UPDATE AWB NUMBER</b>
					</div>

				</div>

				<div class="container">
					<div class="row p-2">
						<div class="col-md-2 fw-bold">
							Manifestations Id
						</div>
						<div class="col-md-4">
							{{manifest_view.manifestIdLeg1}} <span *ngIf="manifest_view.isStopover==true"> --
								{{manifest_view.manifestIdLeg2}}</span>
						</div>
						<div class="col-md-2 fw-bold">
							Departure Time
						</div>
						<div class="col-md-4">
							{{manifest_view.shippingTime *1000 | date:'dd/MM/yyyy HH:mm'}}
						</div>
					</div>
					<div class="row p-2">
						<div class="col-md-2 fw-bold">
							Origin
						</div>
						<div class="col-md-4">
							{{manifest_view.source.iataCode}}
						</div>

						<div class="col-md-2 fw-bold">
							Destination
						</div>
						<div class="col-md-2">
							{{manifest_view.destination.iataCode}}
						</div>
					</div>
					<div class="row p-2">
						<div class="col-md-2 fw-bold">
							Lay Over Location
						</div>
						<div class="col-md-4">
							{{manifest_view.flightScheduleLeg2?.departureAirportID?.iataCode}}
						</div>

						<div class="col-md-2 fw-bold">
							Airline
						</div>
						<div class="col-md-2">
							{{manifest_view.flightScheduleLeg1?.airlineID?.name}}
						</div>
					</div>
					<div class="row p-2 ">
						<div class="row"
							*ngIf="manifest_view.flightScheduleLeg1.airlineID.id!=1 || manifest_view.awb_number == null">
							<div class="col-auto">
								<div class="text-bold"><b>MAWB</b></div>
								<div class="text-bold"><b> </b></div>
								<input readonly style="width: 70px;" placeholder="MAWB Number" [(ngModel)]="mawb_number"
									class="form-control mt-2 text-center">
							</div>
							<div class="col-auto">
								<div class="text-bold"><b>AWB Number</b></div>
								<input style="width: 180px;" oninput="if(!this.value.match('^[0-9]*$'))this.value='';" placeholder="AWB Number" [(ngModel)]="awb_number" #awb maxlength="8"
									class="form-control mt-2">
									<mat-hint align="end">{{awb.value.length}}/8
									</mat-hint>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');tablefilter()">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="updateAwb()">Save</div>
					</div>
				</div>
			</div>
			<div class="card col-md-6 my-3">

				<div class="card-header">
					<div class="card-header-title font-size-lg text-capitalize fw-Bold">
						<b style="color: blue;">UPDATE MANIFESTATION TRACKING</b>
					</div>

				</div>
				<div class="card-body">
					<!-- <div class="row">
					  <div class="col-md-6 row">
						<mat-form-field appearance="standard">
						  <mat-label>
							Manifest Id
						  </mat-label>
						  <input placeholder="Manifest Id" matInput [(ngModel)]="manifest_sts_update.manifestId">
						</mat-form-field>
					  </div>
					</div> -->
					<div class="row">
						<div class="col-md-7 row">
							<mat-form-field appearance="standard">
								<mat-label style="width: auto;">
									Status
								</mat-label>
								<select matNativeControl id="" [(ngModel)]="manifest_sts_update.status">
									<option *ngFor="let mst_st of manifest_status" [value]="mst_st?.id">
										{{mst_st?.status}}
									</option>
								</select>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-7 row">
							<mat-form-field appearance="standard">
								<mat-label>
									Text
								</mat-label>
								<input placeholder="Text" matInput [(ngModel)]="manifest_sts_update.comment">
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md- center-elem pl-0">
							<button class="btn btn-primary " (click)="update_mani_status()">Update Status</button>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table',null);tablefilter()">Back
						</div>
						<!-- <div class="mb-2 me-2 btn btn-primary" (click)="updateAwb()">Save</div> -->
					</div>
				</div>

			</div>
		</some-element>
		<some-element *ngSwitchCase="'view'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-Bold">
						<b style="color: blue;">MANIFEST DETAILS</b>
					</div>

				</div>
				<div class="container">
					<div class="row p-2">
						<div class="col-md-3 fw-bold">
							AWB Number
						</div>
						<div class="col-md-2">
							{{manifest_view.awbNumber}}
						</div>
						<div class="col-md-2 fw-bold">
							Manifestations Id
						</div>
						<div class="col-md-3">
							{{manifest_view.manifestIdLeg1}} <span
								*ngIf="manifest_view.isStopover==true">{{manifest_view.manifestIdLeg2}}</span>
						</div>
					</div>

					<div class="row p-2">
						<div class="col-md-3 fw-bold">
							Airline
						</div>
						<div class="col-md-2">
							{{manifest_view.flightScheduleLeg1?.airlineID?.name}}
						</div>
						<div class="col-md-2 fw-bold">
							Flight Number
						</div>
						<div class="col-md-3">
							{{manifest_view.flightScheduleLeg1?.flightNo }}
						</div>
					</div>
					<div class="row p-2">
						<div class="col-md-3 fw-bold">
							Origin
						</div>
						<div class="col-md-2">
							{{manifest_view.source.iataCode}}
						</div>

						<div class="col-md-2 fw-bold">
							Destination
						</div>
						<div class="col-md-3">
							{{manifest_view.destination.iataCode}}
						</div>
					</div>
					<div class="row p-2">
						<div class="col-md-3 fw-bold">
							Departure Time
						</div>
						<div class="col-md-2">
							{{manifest_view.shippingTime *1000 | date:'dd/MM/yyyy HH:mm'}}
						</div>

						<div class="col-md-2 fw-bold">
							Freezed Time
						</div>
						<div class="col-md-3">
							{{manifest_view.createdAt | date:'dd/MM/yyyy HH:mm:ss'}}
						</div>
					</div>
					<div class="row p-2">
						<div class="col-md-3 fw-bold">
							Lay Over Location
						</div>
						<div class="col-md-2">
							{{manifest_view.flightScheduleLeg2?.departureAirportID?.iataCode}}
						</div>
					</div>
				</div>
				<!-- <div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table',null)">Back</div>
					</div>
				</div> -->
				<div class="card m-3">
					<div class="card-header">

						<div class="card-header-title font-size-lg text-capitalize fw-Bold">
							<b style="color: blue;">CONSIGNMENTS</b>
						</div>

					</div>
					<div class="table-responsive">
						<table class="align-middle text-truncate mb-0 table table-borderless table-hover">
							<thead>
								<tr>
									<th class="text-center"></th>
									<th class="text-center">Consignment No.</th>
									<th class="text-center">Total No of Packages</th>
									<!-- <th class="text-center">Total Weight</th> -->
									<th class="text-center">Total Chargeable Weight</th>
									<!-- <th class="text-center"> Weight</th> -->
									<th class="text-center">Status</th>
								</tr>
							</thead>
							<tbody>
								<ng-container *ngFor="let manifest_view of manifest_view.consignments">

									<tr (click)="manifest_view.isExpand = !manifest_view.isExpand">
										<td class="text-center">
											<i class="fa fa-chevron-down" *ngIf="manifest_view.isExpand"></i>
											<i class="fa fa-chevron-right" *ngIf="!manifest_view.isExpand"
												matTooltip="Click To Expand" matTooltipPosition="above"></i>
										</td>
										<td class="text-center text-muted" style="width: 80px;">
											{{manifest_view.consignmentNo}}
										</td>
										<td class="text-center">{{manifest_view.totalNoOfPackages}}</td>
										<!-- <td class="text-center">{{manifest_view.totalWeight}}kg</td> -->
										<td class="text-center">
											{{calcTotalConsignmentChargeableWeight(manifest_view)}}Kg</td>
										<!-- <td class="text-center">{{getWeight(manifest_view.packages, manifest_view.totalNoOfPackages)}}</td> -->
										<td class="text-center">
											<div *ngIf="manifest_view.trackingId.status.id != 16 && manifest_view.trackingId.status.id!= 19 && manifest_view.trackingId.status.id!=18"
												class="badge bg-pill bg-primary">
												{{manifest_view.trackingId.status.name}}
											</div>
											<div *ngIf="manifest_view.trackingId.status.id == 16"
												class="badge bg-pill bg-success">
												{{manifest_view.trackingId.status.name}}
											</div>
											<div *ngIf="manifest_view.trackingId.status.id == 19"
												class="badge bg-pill bg-orange-400">
												{{manifest_view.trackingId.status.name}}
											</div>
											<div *ngIf="manifest_view.trackingId.status.id==18"
												class="badge bg-pill bg-danger">
												{{manifest_view.trackingId.status.name}}
											</div>
											<!-- <div class="badge bg-pill bg-primary">
												{{manifest_view.trackingId.status.name}}
											</div> -->
										</td>
									</tr>
									<tr *ngIf="manifest_view.isExpand" class="table-responsive">
										<td colspan="4">
											<table
												class="align-middle text-truncate mb-0 table table-borderless table-hover"
												style="background:rgb(205, 236, 247)">
												<thead>
													<tr>
														<th class="text-center"></th>
														<th class="text-center">Dimensions</th>
														<th class="text-center">Total Packages</th>
														<th class="text-center">Weight Per Package</th>
														<!-- <th class="text-center">Volume Weight</th> -->
														<th class="text-center">Total Chargeable Weight</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let pack of manifest_view.packages">
														<!-- <td colspan="2">	</td> -->
														<td class="text-center"></td>
														<td class="text-center">
															<!-- <div *ngFor="let pack of manifest_view.packages"> -->
															{{pack.length}}*{{pack.breadth}}*{{pack.height}}
															<br />
															<!-- </div> -->
														</td>
														<td class="text-center">
															{{pack.package_no}}
														</td>
														<td class="text-center">
															{{pack.weight_per_package}}Kg
														</td>
														<!-- <td class="text-center">
															{{calcVolumeaWeight(pack)}}
														</td> -->
														<td class="text-center">
															{{calcTotalChargableWeight(pack)}}Kg
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>

								</ng-container>
							</tbody>
						</table>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table',null);tablefilter()">Back
						</div>
					</div>
				</div>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'view1'">
			<div class="row">
				<div class="card col-md-12">
					<div class="card-header">

						<div class="card-header-title font-size-lg text-capitalize fw-Bold">
							Consignment No.
						</div>
						<div class="card-header-title font-size-lg text-capitalize fw-Bold text-primary mx-4">
							{{con.consignmentNo}} </div>
					</div>
					<div class="container m-2">

						<div class="col-md-12">
							<div class="row">
								<div class="col-md-3 fw-bold">Customer Name</div>
								<div class="col-md-3"> {{con.senderId?.firstName}} </div>
								<div class="col-md-3 fw-bold border-start">Consignment Type</div>
								<div class="col-md-3"> {{con.consignmentType.details}} </div>
							</div>
							<mat-divider class="m-1"></mat-divider>
							<div class="row ">
								<div class="col-md-3 fw-bold">Origin</div>
								<div class="col-md-3"> {{con.originLocation.iataCode}} </div>
								<div class="col-md-3 fw-bold border-start">Destination</div>
								<div class="col-md-3"> {{con.destinationLocation.iataCode}} </div>
							</div>
							<mat-divider class="m-1"></mat-divider>
							<div class="row ">
								<div class="col-md-3 fw-bold">Created At</div>
								<div class="col-md-3"> {{formatDate1(con.createdAt)}} </div>
								<div class="col-md-3 fw-bold border-start">Updated At</div>
								<div class="col-md-3" *ngIf="con.updatedAt!=null"> {{formatDate1(con.updatedAt)}} </div>
							</div>
							<mat-divider class="m-1"></mat-divider>
							<div class="row ">
								<div class="col-md-3 fw-bold ">Consignment Value</div>
								<div class="col-md-3"> {{con.consignmentValue}}</div>

								<div class="col-md-3 fw-bold border-start ">Status</div>
								<div *ngIf="con.trackingId" class="badge bg-pill bg-primary col-md-3">
									{{con.trackingId?.status?.name}}
								</div>
							</div>
							<mat-divider class="mb-3"></mat-divider>

							<div class="row">
								<div class="text-primary fw-bold"> PACKAGES</div>
							</div>
							<mat-divider class="m-1"></mat-divider>
							<table class="align-middle text-truncate mb-0 table table-borderless table-hover">
								<thead>
									<tr>
										<th class="text-center">Dimentions</th>
										<th class="test-center">Total Packages</th>
										<th class="text-center">Weight Per Package</th>
										<th class="text-center">Chargeable Weight</th>
										<th class="text-center">Total Chargeable Weight</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let pack of con.packages">
										<!-- <td colspan="2">	</td> -->
										<td class="text-center">
											<!-- <div *ngFor="let pack of manifest_view.packages"> -->
											{{pack.length}}*{{pack.breadth}}*{{pack.height}}
											<br />
											<!-- </div> -->
										</td>
										<td class="text-center">
											{{pack.package_no}}
										</td>
										<td class="text-center">
											{{pack.weight_per_package}}
										</td>
										<td class="text-center">
											{{pack.total_weight}}
										</td>
										<td class="text-center">
											{{pack.chargeable_weight}}
										</td>
									</tr>
								</tbody>
							</table>
							<mat-divider class="m-1"></mat-divider>
							<div class="row ">
								<div class="col-md-3 fw-bold  ">Total Num of Packages</div>
								<div class="col-md-3">
									{{con.totalNoOfPackages}}
								</div>
							</div>
							<mat-divider class="m-1"></mat-divider>
							<div class="row ">
								<div class="col-md-3 fw-bold ">Total Weight</div>
								<div class="col-md-3"> {{con.totalWeight}} </div>
								<div class="col-md-3 fw-bold border-start ">Total Chargeable Weight</div>
								<div class="col-md-3"> {{con.totalChargableWeight}} </div>
							</div>
							<mat-divider class="m-1"></mat-divider>
							<div class="row ">
								<div class="col-md-3 fw-bold ">Shipping Cost</div>
								<div class="col-md-3">
									{{con.shippingCost}}
								</div>
								<div class="col-md-3 fw-bold border-start">Total Shipping Cost</div>
								<div class="col-md-3">
									{{con.totalShippingCost}}
								</div>
							</div>
							<mat-divider class="mb-3"></mat-divider>
							<div class="row">
								<div class=" col-md-6 text-primary fw-bold"> PICKUP ADDRESS</div>
								<div class=" col-md-6 text-primary fw-bold"> DROP ADDRESS</div>
							</div>
							<mat-divider class="m-1"></mat-divider>
							<div class="row ">
								<div class="col-md-3 fw-bold">Pickup Address</div>
								<div class="col-md-3"> {{con.pickupAddress.address}}</div>
								<div class="col-md-3 fw-bold border-start ">Drop Address</div>
								<div class="col-md-3">
									{{con.receiverId.address}}
								</div>
							</div>
						</div>
						<!-- <div class="card-footer">

					  <div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table',0)">Back</div>
					  </div>
					</div> -->
					</div>
				</div>
			</div>
		</some-element>
	</container-element>
</div>
