import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {API_URL} from '../../../../../Global_API_URL';
import {DomSanitizer} from '@angular/platform-browser';
import {BillingOfflineDialog, CostCenterOfflineDialog} from '../billing-info-page/billing-info-page.component';
import {MatDialog} from '@angular/material/dialog';
import {BillingService} from 'src/app/modules/billingInfo/service/billing.service';
import Swal from 'sweetalert2';
import {HideurlService} from 'src/app/shared/hideurl.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {AuthService} from '../../../auth.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {DatePipe, Location} from '@angular/common';
import {ViewConsignmentService} from './view-consignment.service';

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';
const PrimaryRed = '#d92550';

@Component({
  selector: 'app-viewconsignment',
  templateUrl: './viewconsignment.component.html',
  styleUrls: ['./viewconsignment.component.sass'],
})
export class ViewconsignmentComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };

  private readonly canGoBack: boolean;

  mode: any;
  id: any;
  image_1 = null;
  image_2 = null;
  image_3 = null;
  image_4 = null;
  POD = null;
  largeImage: any
  image1: File;
  image2: File;
  image3: File;
  image4: File;
  temperature_record_file: File;
  pod: File;
  data_logger_file: File;
  other_documents_file: File;
  vehicle_calibration_file: File;
  selectedWeight: string = 'kg';

  consignmentlists: any = {
    id: '',
    consignment_no: '',
    consignmentType: '',
    weightUnit: 1,
    currency: 1,
    weightDivisor: null,
    consignmentNo: '',
    airlinesId: '',
    isStopover: '',
    totalTransitTime: '',
    senderIndividual: '',
    isIndividualCustomer: '',
    paymentCompletedAt: '',
    isXrayScreening: '',
    isXrayCertification: '',
    isDryIce: '',
    totalWeight: '',
    totalChargableWeight: '',
    planSelected: '',
    senderId: '',
    receiverId: '',
    isDocumentUploaded: '',
    isInsured: false,
    totalShippingCost: '',
    paymentMode: '',
    isPaymentCompleted: '',
    isActive: '',
    createdAt: '',
    updatedAt: '',
    createdBy: '',
    updatedBy: '',
    totalNoOfPackages: '',
    destinationLocation: '',
    consignmentSubType: '',
    originLocation: '',
    dimensionuUnit: '',
    insuranceCoverage: '',
    totalConsignmentValue: '',
    shippingCost: '',
    invoiceCreatedDate: '',
    invoiceReferenceNo: '',
    productType: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    data_logger_file: '',
    vehicle_calibration_file: '',
    other_documents_file: '',
    temperature_record_file: '',
    pod: '',
  }
  consignment_track;
  consignment_with_tracker;
  consignment_status;
  manifestation;
  trackorigin;
  trackdestination;
  consignment_sts_update = {
    consignmentId: null,
    status: null,
    comment: '',
  };
  pageview = 'view';
  userDetails;
  displaytoAdmin = false;
  displaytoSupervisor = false;
  displaytoProcessingAgent = false;
  displaytoAccountant=false;
  requiredDocument: any = [];
  consignment_id;
  packages = [
    {
      id: 1,
      length: null,
      breadth: null,
      height: null,
      weight_per_package: null,
      total_weight: null,
      packageNo: null,
      volume_weight: null,
      chargeable_weight: null,
      totalvolumeweight_per_pack: null,
    },
  ]
  add_packages = [
    {
      id: 1,
      length: null,
      breadth: null,
      height: null,
      weight_per_package: null,
      total_weight: null,
      package_no: null,
      volume_weight: null,
      chargeable_weight: null,
      totalvolumeweight_per_pack: null,
    },
  ]
  totalNoOfPackages;
  totalWeight;
  totalChargableWeight;
  weightDivisor;
  consignmentNo;
  consignment_details = {
    id: null,
    consignmentType: null,
    weightUnit: 1,
    currency: 1,
    weightDivisor: null,
    consignmentNo: null,
    airlinesId: null,
    isStopover: null,
    totalTransitTime: null,
    senderIndividual: null,
    isIndividualCustomer: null,
    paymentCompletedAt: null,
    isXrayScreening: null,
    isXrayCertification: null,
    isDryIce: null,
    totalWeight: null,
    totalChargableWeight: null,
    planSelected: null,
    senderId: null,
    packages: [],
    receiverId: null,
    isDocumentUploaded: null,
    isInsured: false,
    totalShippingCost: null,
    paymentMode: null,
    isPaymentCompleted: null,
    isActive: null,
    createdAt: '',
    updatedAt: '',
    createdBy: null,
    updatedBy: null,
    totalNoOfPackages: null,
    destinationLocation: null,
    consignmentSubType: null,
    originLocation: null,
    dimensionuUnit: null,
    insuranceCoverage: 0,
    totalConsignmentValue: 0,
    shippingCost: 0,
    invoiceCreatedDate: '',
    invoiceReferenceNo: null,
    productType: null,
    remarks: null,
    tentative_pickup_time: null,
    required_temperature: null,
    package_type: null,
  };
  changeable_packages = [
    {
      id: 1,
      length: null,
      breadth: null,
      height: null,
      weight_per_package: null,
      total_weight: null,
      package_no: null,
      volume_weight: null,
      chargeable_weight: null,
      totalvolumeweight_per_pack: null,
    },
  ];
  weightDivisorList = [
    6000, 5000, 4500, 3600, 2700,
  ];
  constructor(private hideurl: HideurlService, private route: ActivatedRoute, private sanitizer: DomSanitizer, private router: Router, public dialog: MatDialog, public commonApi: BillingService, private modalService: NgbModal, private authService: AuthService, private httpClient: HttpClient, private datePipe: DatePipe, private viewConsignmentService: ViewConsignmentService) {
    this.authService.getAuthToken();

    this.canGoBack = !!(this.router.getCurrentNavigation()?.previousNavigation);
  }

  unqid;
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      // console.log(params);
      this.unqid = params['cosigno'];
      // console.log("this is type"+ this.MODE);
    });

    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));

    if (this.userDetails.userTypes[0].name === 'Admin') {
      this.displaytoAdmin = true;
    }
    if (this.userDetails.userTypes[0].name === 'Supervisor') {
      this.displaytoSupervisor = true;
    }
    if (this.userDetails.userTypes[0].name === 'Processing Agent') {
      this.displaytoProcessingAgent = true;
    }
    if (this.userDetails.userTypes[0].name === 'Accountant') {
      this.displaytoAccountant = true;
    }
    const someFunction = async () => {
      await this.viewconsignmentById(this.unqid);
      await this.viewConsignmentDocumentsById(this.unqid);
      await this.documentsTimeStamps();
      // this.sumOfPackages()
      // this.getTrack();
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    };
    someFunction();
  }


  billCreated;
  billConsId;
  billId;
  billingPriceTaxableAmount;
  billingPriceGrandTotal;
  isInvoiced;
  invoiceId;
  image1Url;
  image2Url;
  image3Url;
  image4Url;
  podurl;
  uploadedTimeStamp
  dataLoggerTimeStamp;
  vehiclecalibrationTimeStamp;
  otherDocumentsTimeStamp;
  temperatureTimeStamp;
  dataLoggerView=false;
  vehiclecalibrationView=false;
  otherDocumentsTimeView=false;
  temperatureTimeView=false;
  async documentsTimeStamps() {
    const url=this.consignmentlists.data[0].dataLoggerFile;
    const url2=this.consignmentlists.data[0].vehicleCalibrationFile;
    const url3=this.consignmentlists.data[0].otherDocumentsFile;
    const url4=this.consignmentlists.data[0].temperatureRecordFile;
    if (url!=null) {
      this.dataLoggerTimeStamp=this.extractDateTime(url);
      this.dataLoggerView=true;
    } if (url2!=null) {
      this.vehiclecalibrationTimeStamp=this.extractDateTime(url2);
      this.vehiclecalibrationView=true;
    } if (url3!=null) {
      this.otherDocumentsTimeStamp=this.extractDateTime(url3);
      this.otherDocumentsTimeView=true;
    } if (url4!=null) {
      this.temperatureTimeStamp=url4.substring(url4.lastIndexOf('_') + 1, url4.lastIndexOf('-') + 10); ;
      this.temperatureTimeView=true;
    }
  }
  async viewconsignmentById(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/' + id, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })

    // Converting received data to JSON
        .then((response) => response.json())
        .then(async (json) => {
          if (json.data[0].image1 != null) {
            this.image1Url=json.data[0].image1;
            this.uploadDateTime(this.image1Url, 'image1');
            this.getimage1(id);
          }
          if (json.data[0].image2 != null) {
            this.image2Url=json.data[0].image2;
            this.uploadDateTime(this.image2Url, 'image2');
            this.getimage2(id);
          }
          if (json.data[0].image3 != null) {
            this.image3Url=json.data[0].image3;
            this.uploadDateTime(this.image3Url, 'image3');
            this.getimage3(id);
          }
          if (json.data[0].image4 != null) {
            this.image4Url=json.data[0].image4;
            this.uploadDateTime(this.image4Url, 'image4');
            this.getimage4(id);
          }
          if (json.data[0].pod != null) {
            this.podurl=json.data[0].pod;
            this.uploadDateTime(this.podurl, 'POD');
            this.getpod(id);
          }
          this.consignmentlists = json;
          this.billConsId = this.consignmentlists.data[0].id;
          this.commonApi.getBillingPriceByConsignmentId(this.billConsId)
              .subscribe((response) => {
                this.billCreated = this.commonApi.isBillingCreated;
                this.billingPriceTaxableAmount = this.commonApi.BillingPriceLists.taxableAmount;
                this.billingPriceGrandTotal = this.commonApi.BillingPriceLists.grandTotal;
                this.isInvoiced = this.commonApi.BillingPriceLists.isInvoiceGenerated;
                this.invoiceId = this.commonApi.BillingPriceLists.invoiceId;
                if (this.invoiceId != null) {
                  this.getInvoiceById(this.invoiceId);
                }
              });
          // if (this.billId){

          // }

          this.getTrack();
        });
  }
  uploadedTimeStamp1;
  uploadedTimeStamp2;
  uploadedTimeStamp3;
  uploadedTimeStamp4;
  uploadedTimeStamppod;
  // Retrieve date and TIme from the file name
  uploadDateTime(url: string, img: string) {
    if (img === 'image1') {
      const filename = url;
      this.uploadedTimeStamp1 = this.extractDateTime(filename);
    } else if (img === 'image2') {
      const filename = url;
      this.uploadedTimeStamp2 = this.extractDateTime(filename);
    } else if (img === 'image3') {
      const filename = url;
      this.uploadedTimeStamp3 = this.extractDateTime(filename);
    } else if (img === 'image4') {
      const filename = url;
      this.uploadedTimeStamp4 = this.extractDateTime(filename);
    } else if (img === 'POD') {
      const filename = url;
      this.uploadedTimeStamppod = this.extractDateTime(filename);
    }
  }

  imageDate;
  convertTime(fileName) {
    this.imageDate = this.extractDateTime(fileName);
    return this.datePipe.transform(this.imageDate, 'dd-MM-yyyy HH:mm:ss');
  }

  extractDateTime(filename: string): string {
    // Extract the substring containing the date and time
    const dateTimeSubstring = filename.substring(filename.lastIndexOf('_') + 1, filename.indexOf('T') + 7);
    // Output: 2024-05-06T22:46:45.184Z
    return dateTimeSubstring;
  }

  // sumOfPackages() {
  //   this.totalNoOfPackages  = 0;
  //   this.totalWeight = 0;
  //   this.totalChargableWeight = 0;
  //   console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB")
  //   console.log("this.consignmentlists------->",this.consignmentlists)
  //   console.log("this.consignmentlists.data------->",this.consignmentlists.data)
  //   console.log("this.consignmentlists.data[0].packages.length------->",this.consignmentlists.data[0].packages.length)
  //   console.log("this.consignmentlists.data[0].packages------->",this.consignmentlists.data[0].packages)
  //   if (this.consignmentlists && this.consignmentlists.data && this.consignmentlists.data.length > 0) {
  //     const packages = this.consignmentlists.data[0].packages;
  //     if (packages && packages.length > 0) {
  //       packages.forEach(packages => {
  //         this.totalNoOfPackages += packages.package_no;
  //         this.totalWeight += packages.total_weight;
  //         this.totalChargableWeight += packages.chargeable_weight;
  //       });
  //     }else{
  //       console.log("Nooooooooooo111111111111111111")
  //     }
  //   }else{
  //     console.log("Nooooooooooo22222222222222222222222")
  //   }
  //   // console.log("this.totalNoOfPackages----->",this.totalNoOfPackages)
  //   console.log("this.totalWeight----->",this.totalWeight)
  //   console.log("this.totalChargableWeight----->",this.totalChargableWeight)
  // }


  documentTypeWithDocuments : any;
  uploadedDocuments: any[] = [];
  async viewConsignmentDocumentsById(id) {
    const TOKEN = this.authService.getAuthToken();

    try {
      const response = await fetch(`${API_URL}/consignmentDocuments/` + id, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      });

      if (response.ok) {
        const json = await response.json();
        const consignmentData = json.data;


        // Group documents by consignmentDocumentTypeId
        const groupedDocuments = consignmentData.reduce((acc, document) => {
          const docType = document.consignmentDocumentTypeId;
          if (!acc[docType]) {
            acc[docType] = {
              documentType: {
                id: document.consignmentDocumentTypeId,
                name: document.name, // Add the actual property name for document type name
              },
              documents: [],
            };
          }
          acc[docType].documents.push({
            id: document.id,
            name: document.name,
            fileName: document.fileName,
            uploaded: true,
            downloadLink: document.fileLocation, // Assuming fileLocation is the download link
            // Add other properties as needed
          });
          return acc;
        }, {});

        // Iterate through all document types
        const allDocumentTypes = [1, 2, 3]; // Assuming you have 3 types
        this.uploadedDocuments = allDocumentTypes.map((type) => {
          const documentsForType = groupedDocuments[type];
          if (documentsForType) {
            return documentsForType;
          } else {
            return {
              documentType: {
                id: type,
                name: this.getDocumentTypeName(type),
                file: '',
                // fileOpen:false
              },
              documents: [], // No documents uploaded for this type
            };
          }
        });
      } else {
        const doc=[{
          documentType: {
            id: 1,
            name: 'MSDS or COA',
            file: '',
          },
          documents: [],
        },
        {
          documentType: {
            id: 2,
            name: 'E-waybill',
            file: '',
          },
          documents: [],
        },
        {
          documentType: {
            id: 3,
            name: 'Non-DG Declaration',
            file: '',
          },
          documents: [],
        }];
        this.uploadedDocuments = doc;
        console.error('No consignment documents found.');
      }

      // Continue processing other consignment details as needed
    } catch (error) {
      // Handle exceptions
      // console.error("Exception fetching consignment details:", error);
    }
  }


  showUploadSection: boolean = false;
  async uploadDocument(documentTypeWithDocuments: any) {
    try {
      if (!documentTypeWithDocuments || !documentTypeWithDocuments.file) {
        // If the document or its file is null, show an error message
        Swal.fire({
          icon: 'error',
          title: 'Please select a document to upload',
          showConfirmButton: false,
          timer: 3000,
        });
        return; // Exit the function
      }
      // Validate the content type of the document against the allowed types
      const allowedDocs = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'image/png',
        'image/jpeg',
        'image/jpg',
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ];
      if (!allowedDocs.includes(documentTypeWithDocuments.file.type)) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid File format',
          text: 'Allowed types: pdf, doc, docx, jpeg, jpg, png, csv, xls, xlsx',
          showConfirmButton: false,
          timer: 5000,
        });
        return; // Exit the function
      }
      // Check if file size exceeds the limit
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
      if (documentTypeWithDocuments.file.size > maxSizeInBytes) {
        Swal.fire({
          icon: 'error',
          title: 'File size exceeds limit',
          text: 'Maximum file size allowed is 10MB',
          showConfirmButton: false,
          timer: 5000,
        });
        return; // Exit the function
      }

      // Get authentication token
      const TOKEN = this.authService.getAuthToken();


      // Prepare request headers
      const headers: any = {
        'Authorization': 'Bearer ' + TOKEN,
        'fileMd5Hash': '',
        'fileType': '',
        'name': '',
        'consignmentDocumentTypeId': documentTypeWithDocuments.documentType.id,
        'consignmentDocumentTypeName': documentTypeWithDocuments.documentType.name,
      };

      // Prepare form data
      const formData = new FormData();
      formData.append('file', documentTypeWithDocuments.file);
      formData.append('consignmentDocumentTypeName', documentTypeWithDocuments.documentType.name);

      // Send POST request to upload the document
      const response = await fetch(API_URL + '/consignmentDocuments/'+this.unqid, {
        method: 'POST',
        body: formData,
        headers: headers,
      });

      // Handle response
      if (response.ok) {
        // Document uploaded successfully
        const result = await response.json();
        documentTypeWithDocuments.downloadLink = result.downloadLink;
        documentTypeWithDocuments.uploaded = true;

        // Add the uploaded document to the list
        // this.uploadedDocuments.push(documentTypeWithDocuments);

        Swal.fire({
          icon: 'success',
          title: 'Document Uploaded Successfully',
          showConfirmButton: false,
          timer: 2000,
        });

        // Reset the fileOpen flag and hide the upload section
        documentTypeWithDocuments.documents.fileOpen = false;
        this.showUploadSection = false;
        await this.viewConsignmentDocumentsById(this.unqid);
      } else {
        // Error uploading document
        const errorText = await response.text();
        Swal.fire({
          icon: 'error',
          title: 'Please select a document to upload',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      // Exception uploading document
      console.error('Exception uploading document:', error);
    }
  }

  showUploadSection1: boolean = false;
  async updateUploadDocument(documentTypeWithDocuments: any) {
    try {
      if (!documentTypeWithDocuments || !documentTypeWithDocuments.file) {
        // If the document or its file is null, show an error message
        Swal.fire({
          icon: 'error',
          title: 'Please select a document to upload',
          showConfirmButton: false,
          timer: 3000,
        });
        return; // Exit the function
      }
      // Validate the content type of the document against the allowed types
      const allowedDocs = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'image/png',
        'image/jpeg',
        'image/jpg',
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ];
      if (!allowedDocs.includes(documentTypeWithDocuments.file.type)) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid File format',
          text: 'Allowed types: pdf, doc, docx, jpeg, jpg, png, csv, xls, xlsx',
          showConfirmButton: false,
          timer: 5000,
        });
        return; // Exit the function
      }
      // Check if file size exceeds the limit
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
      if (documentTypeWithDocuments.file.size > maxSizeInBytes) {
        Swal.fire({
          icon: 'error',
          title: 'File size exceeds limit',
          text: 'Maximum file size allowed is 10MB',
          showConfirmButton: false,
          timer: 5000,
        });
        return; // Exit the function
      }

      // Get authentication token
      const TOKEN = this.authService.getAuthToken();


      // Prepare request headers
      const headers: any = {
        'Authorization': 'Bearer ' + TOKEN,
        'fileMd5Hash': '',
        'fileType': '',
        'name': '',
        'consignmentDocumentTypeId': documentTypeWithDocuments.documentType.id,
        'consignmentDocumentTypeName': documentTypeWithDocuments.documentType.name,
      };

      // Prepare form data
      const formData = new FormData();
      formData.append('file', documentTypeWithDocuments.file);
      formData.append('consignmentDocumentTypeName', documentTypeWithDocuments.documentType.name);

      // Send POST request to upload the document
      const response = await fetch(`${API_URL}/consignmentDocuments/${this.unqid}/${documentTypeWithDocuments.documentType.id}`, {
        method: 'PATCH',
        body: formData,
        headers: headers,
      });

      // Handle response
      if (response.ok) {
        // Document uploaded successfully
        const result = await response.json();
        documentTypeWithDocuments.downloadLink = result.downloadLink;
        documentTypeWithDocuments.uploaded = true;

        // Add the uploaded document to the list
        // this.uploadedDocuments.push(documentTypeWithDocuments);

        Swal.fire({
          icon: 'success',
          title: 'Document Updated Successfully',
          showConfirmButton: false,
          timer: 2000,
        });

        // Reset the fileOpen flag and hide the upload section
        documentTypeWithDocuments.documents.fileOpen = false;
        this.showUploadSection1 = false;
        await this.viewConsignmentDocumentsById(this.unqid);
      } else {
        // Error uploading document
        const errorText = await response.text();
        Swal.fire({
          icon: 'error',
          title: 'Please select a document to upload',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      // Exception uploading document
      console.error('Exception uploading document:', error);
    }
  }

  fileType: String;
  fileName: string;
  docName: string;
  consignmentDocumentTypeId;

  async downloadDocument(documentId: number) {
    await this.getDocumentMetadata(documentId);

    try {
      const TOKEN = this.authService.getAuthToken();
      const downloadHeaders = new Headers();
      downloadHeaders.append('Authorization', `Bearer ${TOKEN}`);


      const downloadOptions: RequestInit = {
        method: 'GET',
        headers: downloadHeaders,
        redirect: 'follow',
      };

      // Fetch binary data from getDocumentsById API
      const downloadResponse = await fetch(API_URL + `/getDocumentsById?id=${documentId}&fileType=${this.fileType}`, downloadOptions);


      const documentBlob = await downloadResponse.blob();
      const documentUrl = window.URL.createObjectURL(documentBlob);
      await this.getDocumentMetadata(documentId);
      this.fileName = `${this.docName}_${this.consignmentlists?.data[0]?.consignmentNo}.${this.fileType}`;

      const a = document.createElement('a');
      a.href = documentUrl;
      a.download = this.fileName;
      a.click();
      window.URL.revokeObjectURL(documentUrl);
    } catch (error) {
      console.error('Error downloading document:', error);
    }
  }

  async getDocumentMetadata(documentId: number) {
    try {
      const TOKEN = this.authService.getAuthToken();
      const response = await fetch(`${API_URL}/consignmentDocument/` + documentId, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      });

      if (response.ok) {
        const json = await response.json();
        const consignmentData = json.data;
        this.fileType = consignmentData[0]?.fileType;
        this.fileName = consignmentData[0]?.fileName;
        this.consignmentDocumentTypeId = consignmentData[0]?.consignmentDocumentTypeId;
        this.getDocumentTypeName(this.consignmentDocumentTypeId);
      }
    } catch (error) {
      console.error('Error downloading document metadata:', error);
    }
  }


  // Function to get the document type name based on documentTypeId
  getDocumentTypeName(documentTypeId) {
    switch (documentTypeId) {
      case 1:
        this.docName = 'Non-DG Declaration';
        return 'Non-DG Declaration';
      case 2:
        this.docName ='E-waybill';
        return 'E-waybill';
      case 3:
        this.docName ='Shipment Documents';
        return 'Shipment Documents';
    }
  }


  InvoiceData;
  getInvoiceById(id) {
    const TOKEN = this.authService.getAuthToken();
    try {
      const response = fetch(API_URL + '/getInvoice/' + id, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
          .then((response) => response.json())
          .then(async (json) => {
            this.InvoiceData = json.data[0];
          });
    } catch (error) {
      console.error('Error fetching Invoice details: ', error);
    }
  }

  async getTrack() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/tracking/consignment/' + this.consignmentlists.data[0].consignmentNo, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          this.consignmentlists = json;
          this.consignmentlists.data[0].packages = this.consignmentlists.data[0].packages.sort((a, b) => a.id - b.id);
          this.consignment_track = json.data[0].trackingId.trackingStatusComments;
          const updatedStatus = json.statuses.filter((eachStatus) => {
            const selectedStatus = this.consignment_track.map((each) => each.status.id);
            let highOrder = 0;
            this.consignment_track.forEach((each) => {
              highOrder = highOrder < each.status.order ? each.status.order : highOrder;
            });
            if (eachStatus.order < highOrder && eachStatus.id != 19 && eachStatus.id != 35 && eachStatus.id != 18 && eachStatus.id != 36) {
              return false;
            }
            // if (eachStatus.id === 36) {
            //   return false;
            // }
            if (selectedStatus.includes(eachStatus.id) && eachStatus.id != 19 && eachStatus.id != 35 && eachStatus.id != 18 && eachStatus.id != 36) {
              return false;
            } else {
              return true;
            }
          });
          this.consignment_status = updatedStatus;
          this.consignment_sts_update.status = null;
          this.consignment_sts_update.comment = '';
          this.consignment_track.sort((a, b) => a.id - b.id);
          if (json.data[0].series_ref.id != 13 && json.data[0].series_ref.id != 14 && json.data[0].series_ref.id != 15 && json.data[0].series_ref.id != 16) {
            this.trackorigin = json.data[0].originLocation.city.name;
            this.trackdestination = json.data[0].dropLocation.city.name;
          }
        });
  }
  //   viewchange1() {
  //     // if(this.hideurl.currentUrl === '/consignment'){
  //     //   this.router.navigate(['/consignment'])
  //     // }else{
  //     //   this.router.navigate(['/createinvoice'])
  //     // }
  // console.log(this.hideurl.currenturl)
  //   }
  viewchange1() {
    this.viewConsignmentService.back();
  }

  formatDate(date) {
    const date2 = new Date(date);
    return (
      [
        date2.getFullYear(),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date2.getHours()),
        this.padTo2Digits(date2.getMinutes()),
      ].join(':')
    );
  }
  formatDateToLocal(date: string): string {
    const date2 = new Date(date);
    return (
      [
        date2.getFullYear(),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-') +
      ' ' +
      date2.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
    );
  }
  formatDatePickup(date) {
    const date2 = new Date(date);
    return (
      [
        date2.getFullYear(),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-')
    );
  }
  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  getTime(date) {
    if (date) {
      const date2 = new Date(date);
      return (
        [
          date2.getFullYear(

          ),
          this.padTo2Digits(date2.getMonth() + 1),
          this.padTo2Digits(date2.getDate()),
        ].join('-') +
        ' ' +
        [
          this.padTo2Digits(date2.getHours()),
          this.padTo2Digits(date2.getMinutes()),
        ].join(':')
      );
    } else {
      return '-';
    }
  }


  gotoConsignmentEdit(id: any, consignment_no: any): void {
    this.router.navigate(['/consignmentEdit', id]);
  }

  openBillDialog(id): void {
    const dialogRef = this.dialog.open(BillingOfflineDialog, {
      data: {consignmentId: id},
    });
  }

  gotoBillingInfo(id) {
    this.commonApi.getBillingPriceByConsignmentId(id)
        .subscribe((response) => {
          this.router.navigate(['/billingInfoPage', id]);
        }, (error) => {
          this.openBillDialog(id);
        });
    // if (this.commonApi.isBillingCreated) {
    //   this.router.navigate(['/billingInfoPage', id])
    // }
    // else {
    //   this.openBillDialog(id)
    // }
  }

  openCostDialog(id): void {
    const dialogRef = this.dialog.open(CostCenterOfflineDialog, {
      data: {consignmentId: id},
    });
  }

  gotoCostCenter(id) {
    this.commonApi.getBillingPriceByConsignmentId(id)
        .subscribe((response) => {
          this.router.navigate(['/costCenter', id]);
        }, (error) => {
          this.openCostDialog(id);
        });
    // if (this.commonApi.isBillingCreated) {
    //   this.router.navigate(['/costCenter', id])
    // }
    // else {
    //   this.openCostDialog(id)
    // }
  }


  async getRecord(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/report/' + id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then(async (response) => {
          const filename = 'consignmentReport' + id;
          const blob = response.blob();
          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(await blob);
          a.click();
        },

        );
  }

  latestid: any
  ivalue;
  viewchange(type: string, id) {
    this.ivalue = id;
    if (type === 'image1') {
      this.pageview = 'image1';
    } else if (type === 'view') {
      this.pageview = 'view';
    } else if (type === 'image2') {
      this.pageview = 'image2';
    } else if (type === 'image3') {
      this.pageview = 'image3';
    } else if (type === 'image4') {
      this.pageview = 'image4';
    } else if (type === 'datalogger') {
      this.pageview = 'datalogger';
    } else if (type === 'vehiclecalibration') {
      this.pageview = 'vehiclecalibration';
    } else if (type === 'otherdocuments') {
      this.pageview = 'otherdocuments';
    } else if (type === 'temp') {
      this.pageview = 'temp';
    } else if (type === 'pod') {
      this.pageview = 'pod';
    } else if (type === 'statusupload') {
      this.pageview = 'statusupload';
    } else if (type === 'addpack') {
      this.pageview = 'addpack';
    } else if (type === 'Date') {
      this.pageview = 'Date';
      this.latestid = id;
      if (id && id.updatedAt) {
        const utcDateString = id.updatedAt;
        const dt=this.formatDate(utcDateString);
        this.status_update.updatedAt = dt;
      }
    }
  }
  convertToLocalDateTime(utcDateTime: string): string {
    // Create a Date object from the provided UTC date and time string
    const utcDateString = utcDateTime;
    const dt=this.formatDateToLocal(utcDateString);
    this.status_update.updatedAt = dt;
    return dt.toLocaleString();
  }
  async update_con_status() {
    const TOKEN = this.authService.getAuthToken();
    this.loading = true;
    const sts_update = {
      consignmentId: this.consignmentlists.data[0].consignmentNo,
      status: this.consignment_sts_update.status,
      comment: this.consignment_sts_update.comment,
    };
    if (sts_update.status == null) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please select status',
        showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    }
    // this.consignment_sts_update.consignmentId=this.consignment_id;
    // this.consignment_sts_update.comment=;
    await fetch(API_URL + '/trackByConsignment',
        {
          method: 'POST',
          body: JSON.stringify(sts_update),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          this.getTrack();
          // this.consignment_sts_update.status=null
          // this.consignment_sts_update.comment=null
          // alert("Status Changed")
          this.loading = false;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Status updated successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.viewchange('view', this.consignment_id);
        });
  }


  shouldShowOption(cons_st: any): boolean {
    if (this.userDetails.userTypes[0].name == 'Pickup Delivery Agent' || this.userDetails.userTypes[0].name == 'Processing Agent') {
      return !(cons_st?.name == 'Cancelled' || cons_st?.name == 'Shipment Abandoned');
    } else {
      return true;
    }
  }

  getFiles(event, documentTypeWithDocuments) {
    this.requiredDocument[0] = event.target.files[0];
    documentTypeWithDocuments.file = event.target.files[0];
    documentTypeWithDocuments.documents.fileOpen = true; // Assuming you want to open the upload section when a file is selected
  }


  addPackage() {
    const new_package = {
      id: this.add_packages.length + 1,
      length: null,
      breadth: null,
      height: null,
      weight_per_package: null,
      total_weight: null,
      package_no: null,
      volume_weight: null,
      chargeable_weight: null,
      totalvolumeweight_per_pack: null,
    };
    this.add_packages.push(new_package);
    let j = 1;
    this.add_packages.forEach((element) => {
      element.id = j;
      j++;
    });
  }
  removeItemOnce(arr, value) {
    const index = value;
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  deletePackage(id) {
    for (let index = 0; index < this.add_packages.length; index++) {
      if (this.add_packages[index].id == id) {
        this.removeItemOnce(this.add_packages, index);
      }
    }
    let j = 1;
    this.add_packages.forEach((element) => {
      element.id = j;
      j++;
    });
    const length = this.add_packages.length;
    this.addeachPackageCharged(length);
  }

  async addeachPackageCharged(id) {
    this.totalNoOfPackages = this.consignment_details.totalNoOfPackages;
    this.totalWeight = this.consignment_details.totalWeight;
    this.totalChargableWeight = this.consignment_details.totalChargableWeight;
    for (let index = 0; index < this.add_packages.length; index++) {
      this.add_packages[index].total_weight = Number(this.add_packages[index].package_no) * this.add_packages[index].weight_per_package;
      if (this.add_packages[index].id == id) {
        this.add_packages[index].volume_weight = Math.ceil(Number(this.add_packages[index].length) * Number(this.add_packages[index].breadth) * Number(this.add_packages[index].height) / Number(this.weightDivisor));


        if (this.add_packages[index].volume_weight > this.add_packages[index].weight_per_package) {
          this.add_packages[index].chargeable_weight = Number(this.add_packages[index].volume_weight) * Number(this.add_packages[index].package_no);
        } else {
          this.add_packages[index].chargeable_weight = this.add_packages[index].total_weight;
        }
      }
      this.totalNoOfPackages = Number(this.totalNoOfPackages) + Number(this.add_packages[index].package_no);
      this.totalWeight = this.totalWeight + Number(this.add_packages[index].total_weight);
      this.totalChargableWeight = Math.ceil(this.totalChargableWeight + this.add_packages[index].chargeable_weight);
    }
  }

  async addnewPackages() {
    const TOKEN = this.authService.getAuthToken();

    const cons =
      {
        'totalNoOfPackages': this.totalNoOfPackages,
        'totalWeight': this.totalWeight,
        'totalChargableWeight': this.totalChargableWeight,
        'packages': this.add_packages,
      };
    const checkLength = this.add_packages.filter((eachObj) => (
      eachObj.length == null
    ));
    const checkBreath = this.add_packages.filter((eachObj) => (
      eachObj.breadth == null
    ));
    const checkHeight = this.add_packages.filter((eachObj) => (
      eachObj.height == null
    ));
    const checkpack = this.add_packages.filter((eachObj) => (
      eachObj.package_no == null
    ));
    const checkweight = this.add_packages.filter((eachObj) => (
      eachObj.weight_per_package == null
    ));
    if (checkLength.length != 0) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        // text: 'Package dimetion should not exceed 90cm X 90cm X 90cm '
        text: 'Enter Length',
        timer: 5000,

      });
      this.loading = false;
    } else if (checkBreath.length != 0) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        // text: 'Package dimetion should not exceed 90cm X 90cm X 90cm '
        text: 'Enter Breath',
        timer: 5000,

      });
      this.loading = false;
    } else if (checkHeight.length != 0) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        // text: 'Package dimetion should not exceed 90cm X 90cm X 90cm '
        text: 'Enter Height',
        timer: 5000,

      });
      this.loading = false;
    } else if (checkpack.length != 0) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        // text: 'Package dimetion should not exceed 90cm X 90cm X 90cm '
        text: 'Enter No. Of Packages',
        timer: 5000,

      });
      this.loading = false;
    } else if (checkweight.length != 0) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        // text: 'Package dimetion should not exceed 90cm X 90cm X 90cm '
        text: 'Enter Weight per package',
        timer: 5000,

      });
      this.loading = false;
    } else if (cons.totalChargableWeight > 1000) {
      Swal.fire({
        icon: 'warning',
        title: 'Odd Size Shipment',
        html: 'Email: <a href="#" id="contactEmail">balajiks@iclscm.com</a> <br> contact: +91 9121006802',
        timer: 5000,

      });

      document.getElementById('contactEmail').addEventListener('click', function() {
        window.open('https://mail.google.com/mail/?view=cm&to=balajiks@iclscm.com');
      });
      this.loading = false;
    } else {
      await fetch(API_URL + '/addpackage/' + this.consignment_id,
          {
            method: 'POST',
            body: JSON.stringify(cons),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + TOKEN,
            },
          })
          .then((response) => response.json())
          .then((result) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Packages Added',
              showConfirmButton: false,
              timer: 5000,
            });
          // alert("Changes Saved");
          // this.getconsignmentsList();
          // this.viewchange('view', this.consignment_id);
          // console.log(result);
          })
          .catch((error) => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              showConfirmButton: false,
              timer: 5000,
            });
            console.error('Error', error);
            alert(error);
          });
    }
  }


  // async deletePackages(id) {
  //   let TOKEN = this.authService.getAuthToken();
  //
  //   await fetch(API_URL + "/deletePackage/" + id,
  //     {
  //       method: "DELETE",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": "Bearer " + TOKEN
  //       }
  //     })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Package Deleted',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //       // alert("Changes Saved");
  //       // this.getconsignmentsList();
  //       this.viewconsignmentById(this.consignment_id)
  //       // this.viewchange('view', this.consignment_id);
  //       // console.log(result);
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'error',
  //         title: 'Error',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //       console.error("Error", error);
  //       alert(error)
  //     });
  // }

  async allPackCal() {
    this.changeable_packages.forEach((element) => {
      this.eachPackageCharged(element.id);
    });
  }
  async eachPackageCharged(id) {
    this.consignment_details.totalNoOfPackages = 0;
    this.consignment_details.totalWeight = 0;
    this.consignment_details.totalChargableWeight = 0;
    for (let index = 0; index < this.packages.length; index++) {
      this.changeable_packages[index].total_weight = Number(this.changeable_packages[index].package_no) * this.changeable_packages[index].weight_per_package;
      if (this.changeable_packages[index].id == id) {
        this.changeable_packages[index].volume_weight = Math.ceil(Number(this.changeable_packages[index].length) * Number(this.changeable_packages[index].breadth) * Number(this.changeable_packages[index].height) / Number(this.consignment_details.weightDivisor));
        // console.log(this.packages[index].volume_weight + "  This is volume weight")

        if (this.changeable_packages[index].volume_weight > this.changeable_packages[index].weight_per_package) {
          this.changeable_packages[index].chargeable_weight = Number(this.changeable_packages[index].volume_weight) * Number(this.changeable_packages[index].package_no);
        } else {
          this.changeable_packages[index].chargeable_weight = this.changeable_packages[index].total_weight;
        }
      }
      this.consignment_details.totalNoOfPackages = Number(this.consignment_details.totalNoOfPackages) + Number(this.changeable_packages[index].package_no);
      this.consignment_details.totalWeight = this.consignment_details.totalWeight + Number(this.changeable_packages[index].total_weight);
      this.consignment_details.totalChargableWeight = Math.ceil(this.consignment_details.totalChargableWeight + this.changeable_packages[index].chargeable_weight);
    }
  }


  addpackageviewchange(val, consignment) {
    this.consignment_id = consignment.id;
    this.getconsignmentById();
    // this.totalNoOfPackages=null;
    // this.totalWeight=null;
    // this.totalChargableWeight=null;
    this.totalNoOfPackages = consignment.totalNoOfPackages;
    this.totalWeight = consignment.totalWeight;
    this.totalChargableWeight = consignment.totalChargableWeight;
    const length = this.add_packages.length;
    for (let i = 0; i < length; i++) {
      this.add_packages.pop();
    }
    this.weightDivisor = consignment.weightDivisor;
    this.consignmentNo = consignment.consignmentNo;
    this.pageview = val;
  }
  async getconsignmentById() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/' + this.consignment_id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          this.consignment_details = result.data[0];
          this.packages = this.consignment_details.packages;
          this.changeable_packages = this.consignment_details.packages;
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error',
            showConfirmButton: false,
            timer: 5000,
          });
        });
  }

  status_update = {
    id: null,
    updatedAt: '',
  }
  async updateDateTime() {
    const TOKEN = this.authService.getAuthToken();

    const Date_update = {
      id: this.latestid.id,
      updatedAt: this.status_update.updatedAt,
    };

    try {
      const response = await fetch(API_URL + '/tracking/updateDateTime', {
        method: 'PUT',
        body: JSON.stringify(Date_update),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonResponse = await response.json();

      this.getTrack();

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Status updated successfully',
        showConfirmButton: false,
        timer: 4000,
      }).then(() => {
        this.viewchange('view', 0);
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async uploadimage1() {
    try {
      const TOKEN = this.authService.getAuthToken();
      if (this.image1 == null && (!this.requiredDocument || this.requiredDocument.length === 0)) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Please select a file to upload.',
          showConfirmButton: true,
        });
      } else {
        const formData = new FormData();
        formData.append('consignmentId', this.consignmentlists.data[0].consignmentNo);
        formData.append('image1', this.requiredDocument[0]);
        this.loading = true;

        const response = await this.httpClient.post(API_URL + '/consignment/updateImage1', formData, {
          headers: {
            'Authorization': 'Bearer ' + TOKEN,
            'Accept': 'application/json',
          },
          responseType: 'text', // Ensure the response is treated as text
        }).toPromise();

        this.loading = false;

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Image 1 Uploaded successfully',
          showConfirmButton: false,
          timer: 5000,
        });

        this.refreshPage();

        this.viewchange('view', this.consignmentlists.data[0].id);
      }
    } catch (error) {
      console.error('Error', error);

      // Check if the error response contains an error message
      if (error instanceof HttpErrorResponse) {
        if (error.status === 400) {
          // Handle HTTP 400 (Bad Request) errors with custom messages
          if (error.error.includes('invalid content type')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Image1 has an invalid content type. Allowed types:  png, jpeg, jpg',
              showConfirmButton: true,
            });
          } else if (error.error.includes('file size limit')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Image1 exceeds the file size limit (10MB).',
              showConfirmButton: true,
            });
          }
        }
        this.loading = false;
      }
    }
  }

  async uploadimage2() {
    try {
      const TOKEN = this.authService.getAuthToken();
      if (this.image2 == null && (!this.requiredDocument || this.requiredDocument.length === 0)) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Please select a file to upload.',
          showConfirmButton: true,
        });
      } else {
        const formData = new FormData();
        formData.append('consignmentId', this.consignmentlists.data[0].consignmentNo);
        formData.append('image2', this.requiredDocument[0]);
        this.loading = true;

        const response = await this.httpClient.post(API_URL + '/consignment/updateImage2', formData, {
          headers: {
            'Authorization': 'Bearer ' + TOKEN,
            'Accept': 'application/json',
          },
          responseType: 'text', // Ensure the response is treated as text
        }).toPromise();

        this.loading = false;

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Image 2 Uploaded successfully',
          showConfirmButton: false,
          timer: 5000,
        });

        this.refreshPage();

        this.viewchange('view', this.consignmentlists.data[0].id);
      }
    } catch (error) {
      console.error('Error', error);

      // Check if the error response contains an error message
      if (error instanceof HttpErrorResponse) {
        if (error.status === 400) {
          // Handle HTTP 400 (Bad Request) errors with custom messages
          if (error.error.includes('invalid content type')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Image2 has an invalid content type. Allowed types:  png, jpeg, jpg',
              showConfirmButton: true,
            });
          } else if (error.error.includes('file size limit')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Image2 exceeds the file size limit (10MB).',
              showConfirmButton: true,
            });
          }
        }
        this.loading = false;
      }
    }
  }


  async uploadimage3() {
    try {
      const TOKEN = this.authService.getAuthToken();
      if (this.image3 == null && (!this.requiredDocument || this.requiredDocument.length === 0)) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Please select a file to upload.',
          showConfirmButton: true,
        });
      } else {
        const formData = new FormData();
        formData.append('consignmentId', this.consignmentlists.data[0].consignmentNo);
        formData.append('image3', this.requiredDocument[0]);
        this.loading = true;

        const response = await this.httpClient.post(API_URL + '/consignment/updateImage3', formData, {
          headers: {
            'Authorization': 'Bearer ' + TOKEN,
            'Accept': 'application/json',
          },
          responseType: 'text', // Ensure the response is treated as text
        }).toPromise();

        this.loading = false;
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Image 3 Uploaded successfully',
          showConfirmButton: false,
          timer: 5000,
        });

        this.refreshPage();

        this.viewchange('view', this.consignmentlists.data[0].id);
      }
    } catch (error) {
      console.error('Error', error);

      // Check if the error response contains an error message
      if (error instanceof HttpErrorResponse) {
        if (error.status === 400) {
          // Handle HTTP 400 (Bad Request) errors with custom messages
          if (error.error.includes('invalid content type')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Image3 has an invalid content type. Allowed types:  png, jpeg, jpg',
              showConfirmButton: true,
            });
          } else if (error.error.includes('file size limit')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Image3 exceeds the file size limit (10MB).',
              showConfirmButton: true,
            });
          }
        }
        this.loading = false;
      }
    }
  }

  async uploadimage4() {
    try {
      const TOKEN = this.authService.getAuthToken();
      if (this.image4 == null && (!this.requiredDocument || this.requiredDocument.length === 0)) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Please select a file to upload.',
          showConfirmButton: true,
        });
      } else {
        const formData = new FormData();
        formData.append('consignmentId', this.consignmentlists.data[0].consignmentNo);
        formData.append('image4', this.requiredDocument[0]);
        this.loading = true;

        const response = await this.httpClient.post(API_URL + '/consignment/updateImage4', formData, {
          headers: {
            'Authorization': 'Bearer ' + TOKEN,
            'Accept': 'application/json',
          },
          responseType: 'text', // Ensure the response is treated as text
        }).toPromise();

        this.loading = false;

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Image 4 Uploaded successfully',
          showConfirmButton: false,
          timer: 5000,
        });
        this.refreshPage();
        this.viewchange('view', this.consignmentlists.data[0].id);
      }
    } catch (error) {
      console.error('Error', error);

      // Check if the error response contains an error message
      if (error instanceof HttpErrorResponse) {
        if (error.status === 400) {
          // Handle HTTP 400 (Bad Request) errors with custom messages
          if (error.error.includes('invalid content type')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Image4 has an invalid content type. Allowed types:  png, jpeg, jpg',
              showConfirmButton: true,
            });
          } else if (error.error.includes('file size limit')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Image4 exceeds the file size limit (10MB).',
              showConfirmButton: true,
            });
          }
        }
        this.loading = false;
      }
    }
  }


  async uploadPOD() {
    try {
      const TOKEN = this.authService.getAuthToken();
      if (this.pod == null && (!this.requiredDocument || this.requiredDocument.length === 0)) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Please select a file to upload.',
          showConfirmButton: true,
        });
      } else {
        const formData = new FormData();
        formData.append('consignmentId', this.consignmentlists.data[0].consignmentNo);
        formData.append('Pod', this.requiredDocument[0]);
        this.loading = true;

        const response = await this.httpClient.post(API_URL + '/consignment/updatePod', formData, {
          headers: {
            'Authorization': 'Bearer ' + TOKEN,
            // "Accept":"multipart/form-data"
          },
          responseType: 'text', // Ensure the response is treated as text
        }).toPromise();

        this.loading = false;

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'POD Uploaded successfully',
          showConfirmButton: false,
          timer: 5000,
        });
        this.refreshPage();
        this.viewchange('view', this.consignmentlists.data[0].id);
      }
    } catch (error) {
      console.error('Error', error);

      // Check if the error response contains an error message
      if (error instanceof HttpErrorResponse) {
        if (error.status === 400) {
          // Handle HTTP 400 (Bad Request) errors with custom messages
          if (error.error.includes('invalid content type')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'POD File has an invalid content type. Allowed types:  png, jpeg, jpg',
              showConfirmButton: true,
            });
          } else if (error.error.includes('file size limit')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'POD File exceeds the file size limit (10MB).',
              showConfirmButton: true,
            });
          }
        }
        this.loading = false;
      }
    }
  }


  async uploadDatafiles() {
    try {
      const TOKEN = this.authService.getAuthToken();

      // Check if the upload button was clicked without selecting any file
      if (this.data_logger_file === null && (!this.requiredDocument || this.requiredDocument.length === 0)) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Please select a file to upload.',
          showConfirmButton: true,
        });
      } else {
        const formData = new FormData();
        formData.append('consignmentId', this.consignmentlists.data[0].consignmentNo);

        if (this.requiredDocument && this.requiredDocument.length > 0) {
          formData.append('DataLoggerFile', this.requiredDocument[0]);

          this.loading = true;

          const response = await this.httpClient.post(API_URL + '/consignment/updateDataLoggerFile', formData, {
            headers: {
              'Authorization': 'Bearer ' + TOKEN,
            },
            responseType: 'text', // Ensure the response is treated as text
          }).toPromise();

          this.loading = false;

          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Data Logger Uploaded successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.refreshPage();
          this.viewchange('view', this.consignmentlists.data[0].id);
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Please select a file to upload.',
            showConfirmButton: true,
          });
        }
      }
    } catch (error) {
      console.error('Error', error);

      // Check if the error response contains an error message
      if (error instanceof HttpErrorResponse) {
        if (error.status === 400) {
          // Handle HTTP 400 (Bad Request) errors with custom messages
          if (error.error.includes('invalid content type')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Data Logger File has an invalid content type. Allowed types: pdf, xls, xlsx, doc, docx, csv, jpeg, jpg, png',
              showConfirmButton: true,
            });
          } else if (error.error.includes('file size limit')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Data Logger File exceeds the file size limit (10MB).',
              showConfirmButton: true,
            });
          }
        }
        this.loading = false;
      }
    }
  }


  async uploadVechilefiles() {
    try {
      const TOKEN = this.authService.getAuthToken();

      // Check if the upload button was clicked without selecting any file
      if (this.vehicle_calibration_file === null && (!this.requiredDocument || this.requiredDocument.length === 0)) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Please select a file to upload.',
          showConfirmButton: true,
        });
      } else {
        const formData = new FormData();
        formData.append('consignmentId', this.consignmentlists.data[0].consignmentNo);

        if (this.requiredDocument && this.requiredDocument.length > 0) {
          formData.append('VehicleCalibrationFile', this.requiredDocument[0]);

          this.loading = true;

          const response = await this.httpClient.post(API_URL + '/consignment/updateVehicleCalibrationFile', formData, {
            headers: {
              'Authorization': 'Bearer ' + TOKEN,
              // "Accept": "multipart/form-data"
            },
            responseType: 'text', // Ensure the response is treated as text
          }).toPromise();

          this.loading = false;

          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Vehicle Calibration Uploaded successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.refreshPage();
          this.viewchange('view', this.consignmentlists.data[0].id);
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Please select a file to upload.',
            showConfirmButton: true,
          });
        }
      }
    } catch (error) {
      console.error('Error', error);

      // Check if the error response contains an error message
      if (error instanceof HttpErrorResponse) {
        if (error.status === 400) {
          // Handle HTTP 400 (Bad Request) errors with custom messages
          if (error.error.includes('invalid content type')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Vehicle Calibration File has an invalid content type. Allowed types: pdf, xls, xlsx, doc, docx, csv, jpeg, jpg, png',
              showConfirmButton: true,
            });
          } else if (error.error.includes('file size limit')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Vehicle Calibration File exceeds the file size limit (10MB).',
              showConfirmButton: true,
            });
          }
        }
        this.loading = false;
      }
    }
  }


  async uploadOthersfiles() {
    try {
      const TOKEN = this.authService.getAuthToken();

      // Check if the upload button was clicked without selecting any file
      if (this.other_documents_file === null && (!this.requiredDocument || this.requiredDocument.length === 0)) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Please select a file to upload.',
          showConfirmButton: true,
        });
      } else {
        const formData = new FormData();
        formData.append('consignmentId', this.consignmentlists.data[0].consignmentNo);

        if (this.requiredDocument && this.requiredDocument.length > 0) {
          formData.append('OtherDocumentsFile', this.requiredDocument[0]);

          this.loading = true;

          const response = await this.httpClient.post(API_URL + '/consignment/updateOtherDocumentsFile', formData, {
            headers: {
              'Authorization': 'Bearer ' + TOKEN,
              // "Accept": "multipart/form-data"
            },
            responseType: 'text', // Ensure the response is treated as text
          }).toPromise();

          this.loading = false;

          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Other Documents Uploaded successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.refreshPage();
          this.viewchange('view', this.consignmentlists.data[0].id);
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Please select a file to upload.',
            showConfirmButton: true,
          });
        }
      }
    } catch (error) {
      console.error('Error', error);

      // Check if the error response contains an error message
      if (error instanceof HttpErrorResponse) {
        if (error.status === 400) {
          // Handle HTTP 400 (Bad Request) errors with custom messages
          if (error.error.includes('invalid content type')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Other Documents File has an invalid content type. Allowed types: pdf, xls, xlsx, doc, docx, csv, jpeg, jpg, png',
              showConfirmButton: true,
            });
          } else if (error.error.includes('file size limit')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Other Documents file exceeds the file size limit (10MB).',
              showConfirmButton: true,
            });
          }
        }
        this.loading = false;
      }
    }
  }

  async uploadTemp() {
    try {
      const TOKEN = this.authService.getAuthToken();

      // Check if the upload button was clicked without selecting any file
      if (this.temperature_record_file === null && (!this.requiredDocument || this.requiredDocument.length === 0)) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Please select a file to upload.',
          showConfirmButton: true,
        });
      } else {
        const formData = new FormData();
        formData.append('consignmentId', this.consignmentlists.data[0].consignmentNo);

        if (this.requiredDocument && this.requiredDocument.length > 0) {
          formData.append('TemperatureRecordFile', this.requiredDocument[0]);

          this.loading = true;

          const response = await this.httpClient.post(API_URL + '/consignment/updateTemperatureRecordFile', formData, {
            headers: {
              'Authorization': 'Bearer ' + TOKEN,
              // "Accept": "multipart/form-data"
            },
            responseType: 'text', // Ensure the response is treated as text
          }).toPromise();

          this.loading = false;

          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Temperature Record Uploaded successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.refreshPage();
          this.viewchange('view', this.consignmentlists.data[0].id);
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Please select a file to upload.',
            showConfirmButton: true,
          });
        }
      }
    } catch (error) {
      console.error('Error', error);

      // Check if the error response contains an error message
      if (error instanceof HttpErrorResponse) {
        if (error.status === 400) {
          // Handle HTTP 400 (Bad Request) errors with custom messages
          if (error.error.includes('invalid content type')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Temperature Record File has an invalid content type. Allowed types: pdf, xls, xlsx, doc, docx, csv, jpeg, jpg, png',
              showConfirmButton: true,
            });
          } else if (error.error.includes('file size limit')) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: 'Temperature Record file exceeds the file size limit (10MB).',
              showConfirmButton: true,
            });
          }
        }
        this.loading = false;
      }
    }
  }


  async getimage1(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getImage1/' + id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then((response) => response.blob())
        .then((result) => {
          const objectURL = URL.createObjectURL(result);
          this.image_1 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        })
        .catch((error) => console.error('error', error));
  }


  async getimage2(id) {
    const TOKEN = this.authService.getAuthToken();

    await fetch(API_URL + '/consignment/getImage2/' + id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then((response) => response.blob())
        .then((result) => {
          const objectURL = URL.createObjectURL(result);
          this.image_2 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        })
        .catch((error) => console.error('error', error));
  }
  async getimage3(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getImage3/' + id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then((response) => response.blob())
        .then((result) => {
          const objectURL = URL.createObjectURL(result);
          this.image_3 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        })
        .catch((error) => console.error('error', error));
  }
  async getimage4(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getImage4/' + id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then((response) => response.blob())
        .then((result) => {
          const objectURL = URL.createObjectURL(result);
          this.image_4 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        })
        .catch((error) => console.error('error', error));
  }
  async getpod(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getPod/' + id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then((response) => response.blob())
        .then((result) => {
          const objectURL = URL.createObjectURL(result);
          this.POD = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        })
        .catch((error) => console.error('error', error));
  }

  async getvehiclecalibration() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getVehicleCalibrationFile/' + this.consignmentlists.data[0].id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then(async (response) => {
          const filename = 'VehicleCalibrationFile' + this.consignmentlists.data[0].id;
          const blob = response.blob();
          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(await blob);
          a.click();
        })
        .catch((error) => console.error('error', error));
  }

  async getDatalogger() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getDataLoggerFile/' + this.consignmentlists.data[0].id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then(async (response) => {
          const filename = 'DataloggerFile' + this.consignmentlists.data[0].id;
          const blob = response.blob();
          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(await blob);
          a.click();
        })
        .catch((error) => console.error('error', error));
  }

  async getotherdocuments() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getOtherDocumentsFile/' + this.consignmentlists.data[0].id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then(async (response) => {
          const filename = 'OtherDocumentsFile' + this.consignmentlists.data[0].id;
          const blob = response.blob();
          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(await blob);
          a.click();
        })
        .catch((error) => console.error('error', error));
  }

  async gettemp() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getTemperatureRecordFile/' + this.consignmentlists.data[0].id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then(async (response) => {
          const filename = 'TemperatureRecordFile' + this.consignmentlists.data[0].id;
          const blob = response.blob();
          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(await blob);
          a.click();
        })
        .catch((error) => console.error('error', error));
  }


  // backToConsginment(){
  //   this.route.navigate(['/consignment'])
  // }
  //
  refreshPage() {
    window.location.reload();
  }
  image1view=false;
  image2view=false;
  image3view=false;
  image4view=false;
  podview=false;
  // This will show the Date and Time
  open(content5, img, document) {
    if (document=='image1') {
      this.image1view=true;
      this.image2view=false;
      this.image3view=false;
      this.image4view=false;
      this.podview=false;
    }
    if (document=='image2') {
      this.image1view=false;
      this.image2view=true;
      this.image3view=false;
      this.image4view=false;
      this.podview=false;
    }
    if (document=='image3') {
      this.image1view=false;
      this.image2view=false;
      this.image3view=true;
      this.image4view=false;
      this.podview=false;
    }
    if (document=='image4') {
      this.image1view=false;
      this.image2view=false;
      this.image3view=false;
      this.image4view=true;
      this.podview=false;
    }
    if (document=='pod') {
      this.image1view=false;
      this.image2view=false;
      this.image3view=false;
      this.image4view=false;
      this.podview=true;
    }

    this.modalService.open(content5, {
      size: 'lg',
    });
    this.largeImage = img;
  }

  protected readonly DomSanitizer = DomSanitizer;
}
