import { Injectable } from '@angular/core';
import {API_URL} from '../../../../../Global_API_URL';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BillingCostBulkUploadService {

  constructor(private http: HttpClient) { }

  private uploadUrl = API_URL + '/consignmentBillingCostUpload';

  private uploadCostUrl = API_URL + '/consignmentBillingCostInfo/update';

  uploadCsv(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'
    });

    return this.http.post(this.uploadUrl, formData, { headers });
  }

  uploadCostCsv(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'
    });

    return this.http.patch(this.uploadCostUrl, formData, { headers });
  }

}
