import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConsignmentTypesModel} from '../../consignmentTypes/models/consignment-types-model';
import {Observable} from 'rxjs';
import {ConsignmentTypeResponse} from '../../consignmentTypes/models/consignment-type-response';
import {API_URL} from '../../../../../Global_API_URL';
import {ConsignmentSubTypesModel} from '../models/consignment-sub-types-model';
import {ConsignmentSubtypeResponse} from '../models/consignment-subtype-response';

@Injectable({
  providedIn: 'root',
})
export class ConsignmentSubtypeService {
  constructor(private http: HttpClient) { }

  postConsignmentType(newConsignmentSubtype: ConsignmentSubTypesModel): Observable<ConsignmentSubtypeResponse> {
    return this.http.post<ConsignmentSubtypeResponse>(`${API_URL}/consignment_sub_types`, newConsignmentSubtype);
  }

  patchConsignmentType(ConsignmentSubtype: ConsignmentSubTypesModel): Observable<ConsignmentSubtypeResponse> {
    return this.http.patch<ConsignmentSubtypeResponse>(`${API_URL}/consignment_sub_types/${ConsignmentSubtype.id}`, ConsignmentSubtype);
  }
}
