<!-- <p>airline-pricing-view works!</p> -->
<ngx-loading [show]="loading"
    [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
    [template]="loadingTemplate"></ngx-loading>

<div class="card col-md-12">

    <div class="card-header">
        <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            <i class="fa fa-arrow-left" (click)="backToAirlinePricingPage()"
                style="cursor: pointer; color: blue; margin-right: 10px;"></i>
            <b style="color: blue;">VIEW AIRLINE PRICING</b>
        </div>
        <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-primary" style="margin-top: 10px;" (click)="goToAirlinePricingEditPage()">Edit
            </div>
        </div>
    </div>

    <div class="container">
        <div class="col-md-12">
            <div *ngIf="airlinePricingData">
                <div class="row">
                    <div class="col-md-12 my-3">
                        <h4><strong>Airline Pricing</strong></h4>
                    </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">TariffMin</div>
                    <div class="col-md-1"> {{airlinePricingData?.airlineTariffMin}} </div>
                    <div class="col-md-3 fw-bold">TariffNRate</div>
                    <div class="col-md-1"> {{airlinePricingData?.airlineTariffNRate}} </div>
                    <div class="col-md-3 fw-bold">TariffAbove45</div>
                    <div class="col-md-1"> {{airlinePricingData?.airlineTariffAbove45}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">TariffAbove100</div>
                    <div class="col-md-1"> {{airlinePricingData?.airlineTariffAbove100}} </div>
                    <div class="col-md-3 fw-bold">TariffAbove300</div>
                    <div class="col-md-1"> {{airlinePricingData?.airlineTariffAbove300}} </div>
                    <div class="col-md-3 fw-bold">TariffAbove500</div>
                    <div class="col-md-1"> {{airlinePricingData?.airlineTariffAbove500}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-12 my-3">
                        <h4><strong>Outbound Charges</strong></h4>
                    </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">OutboundTspPerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.outboundTspPerkg}} </div>
                    <div class="col-md-3 fw-bold">OutboundTspMin</div>
                    <div class="col-md-1"> {{airlinePricingData?.outboundTspMin}} </div>
                    <div class="col-md-3 fw-bold">OutboundCashTspPerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.outboundCashTspPerkg}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">OutboundCashTspMin</div>
                    <div class="col-md-1"> {{airlinePricingData?.outboundCashTspMin}} </div>
                    <div class="col-md-3 fw-bold">OutboundUnitPerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.outboundUnitPerkg}} </div>
                    <div class="col-md-3 fw-bold">OutboundUnitMin</div>
                    <div class="col-md-1"> {{airlinePricingData?.outboundUnitMin}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">OutboundHandlingChargesPerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.outboundHandlingChargesPerkg}} </div>
                    <div class="col-md-3 fw-bold">OutboundHandlingChargesMin</div>
                    <div class="col-md-1"> {{airlinePricingData?.outboundHandlingChargesMin}} </div>
                    <div class="col-md-3 fw-bold">OutboundXrayScreeningPerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.outboundXrayScreeningPerkg}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">OutboundXrayScreeningMin</div>
                    <div class="col-md-1"> {{airlinePricingData?.outboundXrayScreeningMin}} </div>
                    <div class="col-md-3 fw-bold">OutboundXrayCertificationPerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.outboundXrayCertificationPerkg}} </div>
                    <div class="col-md-3 fw-bold">OutboundXrayCertificationMin</div>
                    <div class="col-md-1"> {{airlinePricingData?.outboundXrayCertificationMin}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">OutboundOriginSccPerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.outboundOriginSccPerkg}} </div>
                    <div class="col-md-3 fw-bold">OutboundOriginSccMin</div>
                    <div class="col-md-1"> {{airlinePricingData?.outboundOriginSccMin}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-12 my-3">
                        <h4><strong>Inbound Charges</strong></h4>
                    </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">InboundTspPerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.inboundTspPerkg}} </div>
                    <div class="col-md-3 fw-bold">InboundTspMin</div>
                    <div class="col-md-1"> {{airlinePricingData?.inboundTspMin}} </div>
                    <div class="col-md-3 fw-bold">InboundCashTspPerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.inboundCashTspPerkg}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">InboundCashTspMin</div>
                    <div class="col-md-1"> {{airlinePricingData?.inboundCashTspMin}} </div>
                    <div class="col-md-3 fw-bold">inboundUnitPerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.inboundUnitPerkg}} </div>
                    <div class="col-md-3 fw-bold">InboundUnitMin</div>
                    <div class="col-md-1"> {{airlinePricingData?.inboundUnitMin}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">InboundHandlingChargesPerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.inboundHandlingChargesPerkg}} </div>
                    <div class="col-md-3 fw-bold">InboundHandlingChargesMin</div>
                    <div class="col-md-1"> {{airlinePricingData?.inboundHandlingChargesMin}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-12 my-3">
                        <h4><strong>Other Charges</strong></h4>
                    </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">OtherChargesSecurityPerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.otherChargesSercurityPerkg}} </div>
                    <div class="col-md-3 fw-bold">OtherChargesSectorSurchargePerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.otherChargesSectorSurchargePerkg}} </div>
                    <div class="col-md-3 fw-bold">OtherChargesFlighSurchargePerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.otherChargesFlighSurchargePerkg}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-12 my-3">
                        <h4><strong>MAWB Charges</strong></h4>
                    </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">MAWBStatDa</div>
                    <div class="col-md-1"> {{airlinePricingData?.mawbStatDa}} </div>
                    <div class="col-md-3 fw-bold">MAWBDo</div>
                    <div class="col-md-1"> {{airlinePricingData?.mawbDo}} </div>
                    <div class="col-md-3 fw-bold">MAWBMiscPerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.mawbMiscPerkg}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">MAWBMiscFixed</div>
                    <div class="col-md-1"> {{airlinePricingData?.mawbMiscFixed}} </div>
                    <div class="col-md-3 fw-bold">MAWBOtherCharge</div>
                    <div class="col-md-1"> {{airlinePricingData?.mawbOtherCharge}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-12 my-3">
                        <h4><strong>ICLSCM Charges</strong></h4>
                    </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">IclscmHandlingPerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.iclscmHandlingPerkg}} </div>
                    <div class="col-md-3 fw-bold">IclscmHandlingMin</div>
                    <div class="col-md-1"> {{airlinePricingData?.iclscmHandlingMin}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-12 my-3">
                        <h4><strong>Airline Surcharges</strong></h4>
                    </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">SurchargeMin</div>
                    <div class="col-md-1"> {{airlinePricingData?.airlineSurchargeMin}} </div>
                    <div class="col-md-3 fw-bold">SurchargePerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.airlineSurchargePerkg}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-12 my-3">
                        <h4><strong>Additional Surcharges</strong></h4>
                    </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <div class="row">
                    <div class="col-md-3 fw-bold">Additional SurchargePerkg</div>
                    <div class="col-md-1"> {{airlinePricingData?.additionalSurchargePerkg}} </div>
                </div>
                <mat-divider class="m-1"></mat-divider>
                <br>
                <br>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="backToAirlinePricingPage()">Back</div>
        </div>
    </div>
</div>