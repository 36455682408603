<div class="col-md-12">
  <container-element [ngSwitch]="pageview">
    <some-element *ngSwitchCase="'table'">
      <div class="card">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            <b style="color: blue;">USERS</b>
          </div>
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-primary" (click)="gotoUserCreatePage()">Add</div>
          </div>
        </div>
        <div class="row p-4">
          <div class="col-md-2 row">
            <mat-form-field appearance="legacy">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="search2" (keyup)="applyFilter()" placeholder="Name" #input>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="legacy">
              <mat-label>Phone No.</mat-label>
              <input matInput #phone maxlength="10" placeholder="Phone No."
                     onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="10"
                     [(ngModel)]="search1" (keyup)="applyFilter()" (keydown)="onKeyDown($event)">
            </mat-form-field>
            <!-- <mat-hint align="end">{{phone.value.length}}/10
          </mat-hint> -->
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="legacy">
              <mat-label>Email</mat-label>
              <input matInput [(ngModel)]="search" (keyup)="applyFilter()" placeholder="Email Id" #input>
            </mat-form-field>
          </div>
          <!-- <div class="col-md-1 mt-3">
            <button class="btn btn-primary"(click)="find()">Find</button>
          </div> -->
          <div class="col-md-1 mt-3">
            <button class="btn btn-secondary" (click)="reset()">Reset</button>
          </div>
          <div class="col-md-1 mt-3">
            <button class="btn btn-success" (click)="exportToCSV()" style="white-space: nowrap;">
              Download Report
            </button>
          </div>
        </div>

        <div class="table-responsive">
          <table class="w-100" mat-table [dataSource]="dataSource" matSort
                 (matSortChange)="announceSortChange($event)"
                 *ngIf="dataSource?.data?.length > 0; else noRecordsMessage ">
            <!-- Position Column -->
            <ng-container matColumnDef="Name">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by Name" style="width: 16%;">Name
              </th>
              <td class="text-center" mat-cell *matCellDef="let element">{{ element.firstName }}</td>
            </ng-container>
            <ng-container matColumnDef="UserRole">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by UserRole">UserRole
              </th>
              <td class="text-center" mat-cell *matCellDef="let element">{{ element.userTypes[0]?.name }}</td>
            </ng-container>
            <ng-container matColumnDef="PhoneNo">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by Phone No">Phone No.
              </th>
              <td class="text-center" mat-cell *matCellDef="let element">
                {{ element.phone }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Email">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by Email">Email
              </th>
              <td class="text-center" mat-cell *matCellDef="let element">
                {{ element.email }}
              </td>
            </ng-container>

            <ng-container matColumnDef="CreatedAt">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by CreatedAt">
                Created At
              </th>
              <td class="text-center" mat-cell *matCellDef="let element">
                {{ element.createdAt | date:'dd-MM-yyyy HH:mm:ss' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="loggedInAt">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by loggedInAt">
                Last LoggedIn At
              </th>
              <td class="text-center" mat-cell *matCellDef="let element">
                {{ element.lastLoggedInAt | date:'dd-MM-yyyy HH:mm:ss' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Active">
              <th mat-header-cell *matHeaderCellDef> Action</th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex">
                  <div role="group" *ngIf="(element.isActive != false)" class="btn-group-sm btn-group m-1">
                    <button class="btn-shadow btn btn-primary" matTooltip="Edit"
                            (click)="gotoUserEditPage(element.id)"><i
                      class="lnr-pencil"></i></button>
                  </div>
                  <div role="group" *ngIf="(element.isActive != false)"
                       class="btn-group-sm btn-group m-1">
                    <span class="material-icons btn-shadow btn btn-danger" matTooltip="Deactivate User"
                          (click)="DeleteUser(element)">person_remove</span>
                  </div>

                  <div role="group" *ngIf="(element.isActive == false)"
                       class="btn-group-sm btn-group m-1">
                    <span class="material-icons btn-shadow btn btn-success" matTooltip="Activate User"
                          (click)="ActivateUser(element)"> person_add_alt_1 </span>
                  </div>

                  <div role="group" *ngIf="(element.isActive != false)"
                       class="btn-group-sm btn-group m-1">
                    <span class="material-icons btn-shadow btn btn-primary" matTooltip="Reset Password"
                          (click)="viewchange('reset',element.email)">vpn_key</span>
                  </div>

                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="row.is_active == false ? 'red':'white'"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="pageSizeOptionss" [length]="paginatorDetails.totalElements"
                         [pageIndex]="storedPage" showFirstLastButtons [pageSize]="pageSize"
                         (page)="onPaginateChange($event)">
          </mat-paginator>
          <ng-template #noRecordsMessage>
            <h1 class="text-center fw-bold">No Records Available..!</h1>
          </ng-template>
        </div>
        <ngx-loading [show]="loading"
                     [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                     [template]="loadingTemplate"></ngx-loading>
      </div>
    </some-element>

    <some-element *ngSwitchCase="'reset'">
      <div class="card">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            <b style="color: blue;">CHANGE PASSWORD</b>
          </div>
        </div><br>

        <div class="container" style="height: 180px">
            <div class="col-md-12">
              <mat-label>
                <b>New Password</b>
              </mat-label>
              <br>
              <p-password placeholder="Enter New Password" [(ngModel)]="newpassword"
                          [feedback]="false" [toggleMask]="true" (input)="checkNewPassword()"
                          [style]="{'width':'300px'}" [inputStyle]="{'width':'300px'}"></p-password><br>
              <mat-hint class="text-danger" *ngIf="oldPassLengthCheck">Password should have minimum of 6 characters </mat-hint>
            </div>
            <br>

            <div class="col-md-12">
              <mat-label>
                <b>Confirm New Password</b>
              </mat-label>
              <br>
              <p-password placeholder="Enter Confirm Password" [(ngModel)]="retypePassword"
                          [feedback]="false" [toggleMask]="true" (input)="checkConfirmPassword()"
                          [style]="{'width':'300px'}" [inputStyle]="{'width':'300px'}"></p-password>&nbsp;&nbsp;&nbsp;
              <span class="text-danger"
                    *ngIf="passwordFlag === true">New Password and Confirm Password should match</span>
            </div>
        </div>
      </div>

      <div class="mt-4 d-flex align-items-center">
        <div class="ms-auto">
          <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');resetForm();">Cancel</div>
          <button class="mb-2 me-2 btn btn-primary" (click)="resetPassword();">Reset Password</button>

        </div>
        <ngx-loading [show]="loading"
                     [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                     [template]="loadingTemplate"></ngx-loading>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'view'">
      <div class="card">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            View Country
          </div>

        </div>

        <div class="container">
          <div class="text-bold">Country Name</div>
          <span>India</span>
          <div class="text-bold">Country Code</div>
          <span>+91</span>
          <div class="text-bold">Currency</div>
          <span>INR</span>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');getFilterUserList()">Back</div>
          </div>
        </div>
        <ngx-loading [show]="loading"
                     [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                     [template]="loadingTemplate"></ngx-loading>
      </div>
    </some-element>
  </container-element>
</div>
