import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {API_URL, LOCAL_API_URL} from '../../../../../Global_API_URL';
import Swal from 'sweetalert2';
import {FormControl, Validators} from '@angular/forms';
const TOKEN = 'your_token';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: [],
})
export class ForgotPasswordComponent implements OnInit {
  emailregex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
  emailFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.emailregex),
  ]);


  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };

  email;
  emailExist = false;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  async emailAppuser() {
    // console.log(this.email, '-------->Email');

    try {
      const response = await fetch(API_URL + '/appuser/email/' + this.email,
          {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
          });

      if (response.ok) {
        const result = await response.json();
        // this.emailExist = result !== null;
        // this.emailExist = result && result.emailExists === true;
        // this.emailExist = result && result.data && result.data.length > 0 && result.data[0].first_name !== undefined;  
        this.emailExist = result.data && Array.isArray(result.data) && result.data.length > 0 && result.data[0] !== null ;
      } else {
        // Handle non-OK response (e.g., 404 Not Found)
        this.emailExist = false;
      }
    } catch (error) {
      this.emailExist = false;
    }
  }

  goBack() {
    this.router.navigateByUrl('');
  }

  async sendEmail() {
    if (this.email == null || this.email.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Email ',
        timer: 5000,
      });
    } else if (!(this.emailregex.test(this.email))) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        // showConfirmButton: false,
        text: 'Please Enter Valid Email',
        timer: 5000,

      });
    } else {
      // Check if the email exists
      await this.emailAppuser();

      // Proceed to send email if the email exists

      if (this.emailExist) {
        try {
          const result = await fetch(API_URL + '/forgot-password' + '?email=' + this.email + '&Path=' + LOCAL_API_URL,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                },
              });

          if (result.ok) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Reset link has been sent to your registered email address',
              showConfirmButton: false,
              timer: 5000,
            });

            this.router.navigateByUrl('');
          } else {
            console.error('Error sending email:', result.statusText);
          }
        } catch (error) {
          console.error('Error sending email:', error);
        }
      } else {
        // Show an error message as the email does not exist
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Email is not registered',
          timer: 5000,
        });
      }
    }
  }
}

