import {Component, OnInit} from '@angular/core';
import {ConsignmentPaperWorkDTO} from '../golbalInterfaces';
import {PaperWorkService} from './paper-work.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ConfirmDialog } from '../Components/confirmDialog/confirmDialog.component';

@Component({
  selector: 'app-paper-work',
  templateUrl: './paper-work.component.html',
  styleUrls: ['./paper-work.component.sass'],
})
export class PaperWorkComponent implements OnInit {
  constructor(private paperWorkService: PaperWorkService, private router: Router,private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllActivePaperWorks();
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name === 'Customer') {
      this.displaytoCustomer = false;
    }
  }

  userDetails;
  displaytoCustomer = true;
  paperWorks: any[] = [];
  activePaperWorks: any[] = [];
  newPaperWork: ConsignmentPaperWorkDTO = new ConsignmentPaperWorkDTO();
  selectedFile: File | undefined;
  errorMessage: string = '';

  getAllPaperWorks(): void {
    this.paperWorkService.getAllPaperWorks()
        .subscribe(
            (paperWorks) => {
              this.paperWorks = paperWorks;
            },
            (error) => {
              console.error('Error fetching paper works:', error);
              this.errorMessage = 'Failed to load paper works.';
            },
        );
  }

  getAllActivePaperWorks(): void {
    this.paperWorkService.getAllActivePaperWorks()
        .subscribe(
            (activePaperWorks) => {
              this.activePaperWorks = activePaperWorks;
            },
            (error) => {
              console.error('Error fetching active paper works:', error);
              this.errorMessage = 'Failed to load active paper works.';
            },
        );
  }

  createPaperWork(): void {
    this.paperWorkService.createPaperWork(this.newPaperWork, this.selectedFile!)
        .subscribe((response) => {
          // console.log('Response:', response);
          this.getAllPaperWorks(); // Refresh data after creation
        });
  }

  deleteFile(id) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to delete?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deactivatePaperWork(id);
      }
    });
  }

  // confirmDeactivate(id: number): void {
  //   Swal.fire({
  //     title: 'Confirm Deactivation',
  //     text: 'Are you sure you want to deactivate this paper work?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, deactivate it!',
  //     cancelButtonText: 'Cancel',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       this.deactivatePaperWork(id);
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       Swal.fire('Cancelled', 'Deactivation cancelled', 'info');
  //     }
  //   });
  // }

  deactivatePaperWork(id: number): void {
    this.paperWorkService.deactivatePaperWork(id)
        .subscribe(() => {
          Swal.fire('Success', 'File Deleted Successfully', 'success');
          this.getAllActivePaperWorks();
        }, (error) => {
          Swal.fire('Error', 'Failed to delete the file', 'error');
        });
  }

  downloadPaperWork(id: number): void {
    this.paperWorkService.downloadPaperWork(id).subscribe(
        (response) => {
          const contentDisposition = response.headers.get('Content-Disposition');
          const matches = contentDisposition && contentDisposition.match(/filename="(.+)"/);
          const filename = matches ? matches[1] : 'file';

          const blob = new Blob([response.body], {type: response.body.type});
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename; // Use the filename from the Content-Disposition header
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.error('Error downloading paper work:', error);
          this.errorMessage = 'Failed to download paper work.';
        },
    );
  }

  showDescription(description: string): void {
    Swal.fire({
      title: 'Description',
      html: description,
      showCloseButton: true,
    });
  }

  isPdfFile(fileType: string): boolean {
    return fileType === 'application/pdf';
  }

  isDocxOrDocFile(fileType: string): boolean {
    return fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || // DOCX
      fileType === 'application/msword'; // DOC
  }

  isImageFile(fileType: string): boolean {
    return fileType.startsWith('image/');
  }

  isOtherFile(fileType: string): boolean {
    return !this.isPdfFile(fileType) && !this.isDocxOrDocFile(fileType) && !this.isImageFile(fileType);
  }

  navigateToAddPaperWork(): void {
    this.router.navigate(['/addPaperWork']);
  }
}
