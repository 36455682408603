<!-- <p>airline-pricing-edit works!</p> -->
<ngx-loading [show]="loading"
    [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
    [template]="loadingTemplate"></ngx-loading>

<div class="card col-md-12">
    <div class="card">
        <div class="card-header">
            <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                <i class="fa fa-arrow-left" (click)="backToAirlinePricingViewPage()"
                    style="cursor: pointer; color: blue; margin-right: 10px;"></i>
                <b style="color: blue;">EDIT AIRLINE PRICING</b>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 my-3">
                    <h4><strong>Airline Pricing</strong></h4>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-4 ">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            TariffMin<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder=" TariffMin"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.airlineTariffMin">
                    </mat-form-field>
                </div>
                <div class="col-md-4 ">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            TariffNRate<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder=" TariffNRate"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.airlineTariffNRate">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            TariffAbove45<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder=" TariffAbove45"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.airlineTariffAbove45">
                    </mat-form-field>
                </div>
                <div class="col-md-4 ">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            TariffAbove100<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder=" TariffAbove100"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.airlineTariffAbove100">
                    </mat-form-field>
                </div>
                <div class="col-md-4 ">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            TariffAbove300<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder=" TariffAbove300"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.airlineTariffAbove300">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            TariffAbove500<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder=" TariffAbove500"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.airlineTariffAbove500">
                    </mat-form-field>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-12 my-3">
                    <h4><strong>Outbound Charges</strong></h4>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OutboundTspPerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OutboundTspPerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.outboundTspPerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OutboundTspMin<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OutboundTspMin"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.outboundTspMin">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OutboundCashTspPerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OutboundCashTspPerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.outboundCashTspPerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OutboundCashTspMin<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OutboundCashTspMin"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.outboundCashTspMin">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OutboundUnitPerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OutboundUnitPerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.outboundUnitPerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OutboundUnitMin<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OutboundUnitMin"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.outboundUnitMin">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OutboundHandlingChargesPerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OutboundHandlingChargesPerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.outboundHandlingChargesPerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OutboundHandlingChargesMin<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OutboundHandlingChargesMin"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.outboundHandlingChargesMin">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OutboundXrayScreeningPerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OutboundXrayScreeningPerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.outboundXrayScreeningPerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OutboundXrayScreeningMin<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OutboundXrayScreeningMin"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.outboundXrayScreeningMin">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OutboundXrayCertificationPerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OutboundXrayCertificationPerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.outboundXrayCertificationPerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OutboundXrayCertificationMin<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OutboundXrayCertificationMin"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.outboundXrayCertificationMin">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OutboundOriginSccPerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OutboundOriginSccPerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.outboundOriginSccPerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OutboundOriginSccMin<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OutboundOriginSccMin"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.outboundOriginSccMin">
                    </mat-form-field>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-12 my-3">
                    <h4><strong>Inbound Charges</strong></h4>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            InboundTspPerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="InboundTspPerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.inboundTspPerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            InboundTspMin<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="InboundTspMin"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.inboundTspMin">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            InboundCashTspPerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="InboundCashTspPerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.inboundCashTspPerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            InboundCashTspMin<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="InboundCashTspMin"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.inboundCashTspMin">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            InboundUnitPerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="inboundUnitPerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.inboundUnitPerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            InboundUnitMin<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="InboundUnitMin"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.inboundUnitMin">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            InboundHandlingChargesPerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="InboundHandlingChargesPerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.inboundHandlingChargesPerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            InboundHandlingChargesMin<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="InboundHandlingChargesMin"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.inboundHandlingChargesMin">
                    </mat-form-field>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-12 my-3">
                    <h4><strong>Other Charges</strong></h4>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OtherChargesSecurityPerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OtherChargesSecurityPerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.otherChargesSercurityPerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OtherChargesSectorSurchargePerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OtherChargesSectorSurchargePerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.otherChargesSectorSurchargePerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            OtherChargesFlightSurchargePerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="OtherChargesFlightSurchargePerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.otherChargesFlighSurchargePerkg">
                    </mat-form-field>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-12 my-3">
                    <h4><strong>MAWB Charges</strong></h4>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            mawbStatDa<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="mawbStatDa"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.mawbStatDa">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            mawbDo<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="MAWBDo"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.mawbDo">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            mawbMiscPerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="mawbMiscPerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.mawbMiscPerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            mawbMiscFixed<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="mawbMiscFixed"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.mawbMiscFixed">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            mawbOtherCharge<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="mawbOtherCharge"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.mawbOtherCharge">
                    </mat-form-field>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-12 my-3">
                    <h4><strong>ICLSCM Charges</strong></h4>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            IclscmHandlingPerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="IclscmHandlingPerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.iclscmHandlingPerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            IclscmHandlingMin<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="IclscmHandlingMin"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.iclscmHandlingMin">
                    </mat-form-field>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-12 my-3">
                    <h4><strong>Airline Surcharges</strong></h4>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            AirlineSurchargePerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="AirlineSurchargePerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.airlineSurchargePerkg">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            AirlineSurchargeMin<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder="AirlineSurchargeMin"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.airlineSurchargeMin">
                    </mat-form-field>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-12 my-3">
                    <h4><strong>Additional Surcharges</strong></h4>
                </div>
            </div>
            <mat-divider class="m-1"></mat-divider>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>
                            Additional SurchargePerkg<span class="text-danger">*</span>
                        </mat-label>
                        <input type="text" placeholder=" Additional SurchargePerkg"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                            [(ngModel)]="airlinePricingData.additionalSurchargePerkg">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="btn-actions-pane-right p-2">
                <div class="mb-2 me-2 btn btn-danger" (click)="backToAirlinePricingViewPage()">Cancel</div>
                <div class="mb-2 me-2 btn btn-primary" (click)="editairpricing()">Save</div>
            </div>
        </div>
    </div>
</div>