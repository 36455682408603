import {Component, Inject, OnInit} from '@angular/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProductTypeService} from '../../modules/productTypes/services/product-type.service';
import {ConsignmentTypeService} from '../../modules/consignmentTypes/services/consignment-type.service';
import {ConsignmentSubtypeService} from '../../modules/consignmentSubtypes/services/consignment-subtype.service';

/** File node data with possible child nodes. */
export interface FileNode {
  name: string;
  type: string;
  children?: FileNode[];
}

/**
 * Flattened tree node that has been created from a FileNode through the flattener. Flattened
 * nodes include level index and whether they can be expanded or not.
 */

interface TreeNode {
  name: string;
  children?: any;
  description: ''
}


const infoTree: TreeNode[] = [
  {
    name: 'Products',
    children: '',
    description: ''
  },
  {
    name: 'Consignment Type',
    children: '',
    description: ''
  },
  {
    name: 'Consignment Subtype',
    children: '',
    description: ''
  }
]


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit{


  constructor(
    public dialogRef: MatDialogRef<InfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private productTypeService: ProductTypeService,
    private consignmentTypeService: ConsignmentTypeService,
    private consignmentSubtypeService: ConsignmentSubtypeService)  {
    this.productTypeService.getProductTypes().subscribe(response => {
      infoTree[0].children = response.data;
      this.dataSource.data = infoTree
    });
    this.productTypeService.getConsignmentTypes().subscribe(response => {
      infoTree[1].children = response.data;
      this.dataSource.data = infoTree
    });
    this.productTypeService.getConsignmentSubTypes().subscribe(response => {
      infoTree[2].children = response.data;
      this.dataSource.data = infoTree
    });
  }


  treeControl = new NestedTreeControl<any>(node => {
    if(!!node.children && node.children.length > 0)
      return node.children;
    else if(!!node.consignment_types && node.consignment_types.length > 0)
      return node.consignment_types;
    else if(!!node.consignment_sub_types_list && node.consignment_sub_types_list.length > 0)
      return node.consignment_sub_types_list;
  });

  dataSource = new MatTreeNestedDataSource<any>();


  hasChild = (_: number, node) => {
    return (!!node.consignment_types ) || (!!node.consignment_sub_types_list )|| (!!node.children );
  }

  ngOnInit(): void {
    // infoTree.
    this.productTypeService.getProductTypes().subscribe(response => {
      infoTree[0].children = response.data;
      this.dataSource.data = infoTree
    });
    this.productTypeService.getConsignmentTypes().subscribe(response => {
      infoTree[1].children = response.data;
      this.dataSource.data = infoTree
    });
    this.productTypeService.getConsignmentSubTypes().subscribe(response => {
      infoTree[2].children = response.data;
      this.dataSource.data = infoTree
    });
  }

  showDescrption(node){
    if(!!node.description) return node.description;
    else return ''
  }
}
