<div>
  <h2 mat-dialog-title>Create new Consignment Subtype</h2>
  <div mat-dialog-content>
    <form [formGroup]="consignmentSubtypeForm">
      <ng-container>
      <mat-form-field style="width: 15rem" appearance="fill">
        <mat-label>Enter Name</mat-label>
        <input matInput type="text" formControlName="name">
        <mat-hint class="text-danger" *ngIf="nameWarning" >Please enter a name</mat-hint>
      </mat-form-field>

      </ng-container>
      <mat-form-field style="width: 20rem" appearance="fill">
        <mat-label>Enter Description</mat-label>
        <textarea matInput type="text-box" formControlName="description"></textarea>
      </mat-form-field>
<!--      <mat-hint class="text-danger" *ngIf="descriptionWarning" >Please enter a description</mat-hint>-->
<!--      <mat-form-field appearance="fill">-->
<!--        <mat-label>Select a Consignment Type</mat-label>-->
<!--        <mat-select [(value)]="selectedConsignmentType">-->
<!--          <mat-option *ngFor="let consignmentType of consignmentTypes" [value]="consignmentType">-->
<!--            {{consignmentType.name}}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->

<!--        <mat-label>Enter Consignment Type Name</mat-label>-->
<!--        <input matInput type="text" formControlName="name">-->

    </form>
  </div>
</div>
<div mat-dialog-actions style="float: right">
  <button mat-raised-button color="warn" style="color: white" *ngIf="action==='create'" (click)="onCancelClick()">Cancel</button>
  <button mat-raised-button color="warn" style="color: white" *ngIf="action==='patch'"  (click)="onCancelClick()">Discard</button>
  <button mat-raised-button color="primary" *ngIf="action==='create'" (click)="onSubmitClick()" cdkFocusInitial>Create</button>
  <button mat-raised-button color="primary" *ngIf="action==='patch'" (click)="onSubmitClick()" cdkFocusInitial>Save Changes</button>
</div>
