import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpService} from "../../../services/http.service";
import Swal from "sweetalert2";
import {API_URL} from "../../../../../Global_API_URL";
import {CustomerVerificationService} from "../../../shared/customer-verification/customer-verification.service";
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from "ngx-loading";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-retail-customer-create',
  templateUrl: './retail-customer-create.component.html',
  styleUrls: ['./retail-customer-create.component.sass']
})
export class RetailCustomerCreateComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  constructor(private httpService: HttpService, public customerVerification: CustomerVerificationService, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.customerVerification.emailExist = false;
    this.customerVerification.resetRetailCustomerData(this.customer_shipper);
    setTimeout(() => {
      this.loading = false; // Set loading to false after 2 seconds
    }, 2000);
  }

  selected = '';

  customer_shipper = {
    id: null,
    firstName: '',
    middleName: '',
    lastName: '',
    avatarUrl: '',
    email: '',
    emailForMasterGST: '',
    phone: '',
    whatsapp: '',
    designation: null,
    employmentNo: null,
    billingAddress: {
      pincode: {
        pin_number: ''
      },
      address: ''
    },
    isSuperUser: null,
    userName: '',
    passwordHash: null,
    lastLoggedInAt: null,
    lastLoggedInIp: null,
    aadhaarNumber: null,
    drivingLicense: null,
    gstNumber: null,
    panNumber: null,
    gstDetails: null,
    panDetails: null,
    aadhaarDetails: null,
    customerVerified: true,
    isActive: true,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
  }

  retailCustomerVerification() {
    if (this.selected == 'gst') {

      this.customerVerification.gstVerification(this.customer_shipper, "retail", "create");

    } else if (this.selected == 'pan') {

      this.customerVerification.panVerification(this.customer_shipper, "create");

    } else if (this.selected == 'aadhaar') {

      this.customerVerification.aadhaarVerification(this.customer_shipper, "create");

    }
  }


  sanitizeImage(image: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(image);
  }

  async createRetailCustomer() {
    if (this.customer_shipper.firstName == null || this.customer_shipper.firstName == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Display Name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.firstName.length != this.customer_shipper.firstName.trim().length || this.customer_shipper.firstName.trim().length == 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Remove the spaces at beginning or end of the first name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.lastName == null || this.customer_shipper.lastName == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Last Name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.lastName.length != this.customer_shipper.lastName.trim().length || this.customer_shipper.lastName.trim().length == 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Remove the spaces at beginning or end of the last name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.email == null || this.customer_shipper.email == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Email',
        timer: 5000,
      });
      return;
    } else if (!(this.customerVerification.emailregex.test(this.customer_shipper.email))) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter A Valid Email',
        timer: 5000,
      });
      return;
    } else if (await this.customerVerification.emailExistAppuser(this.customer_shipper.email)) {
      return;
    }
    /*else if (this.customerVerification.emailExist) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Email Already Exists',
        timer: 5000,
      });
      return;
    } */ else if (this.customer_shipper.phone == null || this.customer_shipper.phone == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Phone No.',
        timer: 5000,
      });
      return;
    } else if ((this.customer_shipper.phone).length < 10 || isNaN(parseInt(this.customer_shipper.phone)) == true) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Phone No.',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.whatsapp.length > 0 && this.customer_shipper.whatsapp.length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Whatsapp No.',
      });
    } else if (this.customer_shipper.billingAddress.address == '' || this.customer_shipper.billingAddress.address == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Address',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_country == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Country',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_state == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The State',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_city == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The City',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.billingAddress.pincode.pin_number == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Zip Code',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper?.aadhaarNumber?.length > 0 && this.customer_shipper?.aadhaarNumber?.length < 12) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Aadhaar No. must be 12 digits',
        timer: 5000,
      });
    } else if (this.customer_shipper?.panNumber?.length > 0 && !this.customerVerification.panregex.test(this.customer_shipper.panNumber)) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter Valid PAN No. ',
        timer: 5000,
      });
    } else {
      this.loading = true;
      this.httpService.post(API_URL + '/retailUser', this.customer_shipper, null, null)
        .subscribe(response => {
          this.loading = false;
          Swal.fire({
            icon: 'success',
            title: 'Customer Created',
            timer: 5000,
          }).then(() => {
            this.goBackToRetailCustomerTable();
          });
        }, error => {
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: 'Failed to create customer',
            timer: 5000,
          });
        })
    }
  }


  goBackToRetailCustomerTable() {
    history.back();
  }

  transformPANoUpperCaseForCreate() {
    this.customer_shipper.panNumber = this.customer_shipper.panNumber.toUpperCase();
  }


  // **************************************** Aadhaar ***********************************************************

  // isOTPSent1 = false;
  // isOTPResend = false;
  //
  // sendOTP() {
  //   this.isOTPSent1 = true;
  //   setTimeout(() => {
  //     this.isOTPResend = true;
  //     this.isOTPSent1 = false;
  //   }, 1000 * 5)
  // }
  //
  // resendOTP(){
  //   this.isOTPSent1 = true;
  // }
  //
  //
  // onOtpChange(event) {
  //   this.customerVerification.aadhaarVerifyOtpReqBody.otp = event;
  // }
  //
  // @ViewChild('timer') timer;
  // isOTPSent = false;
  //
  // startTimer() {
  //   this.isOTPSent = true;
  //   this.timer.start();
  // }
  //
  // onStart(data) {
  //   console.log("Timer started....");
  // }
  //
  // onTimerComplete() {
  //   this.isOTPResend = true;
  // }
  //
  // onTimerTick(event) {
  //   // Handle tick events if necessary
  // }
  //
  //
  // @ViewChild('basicTimer', {static: true}) basicTimer: CdTimerComponent;
  //
  // doActionBasicTimer(action: String) {
  //   switch (action) {
  //     case 'start':
  //       this.basicTimer.start();
  //       break;
  //     case 'resume':
  //       this.basicTimer.resume();
  //       break;
  //     case 'reset':
  //       this.basicTimer.reset();
  //       break;
  //     default:
  //       this.basicTimer.stop();
  //       break;
  //   }
  // }

}
