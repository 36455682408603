<div *ngIf="loading" class="loader-container">
  <ngx-loading [show]="loading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>
<div style="display: flex; float: right" >
  <div class="mb-2 me-2 btn btn-success" (click)="downloadPDF()"> Download Pdf </div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <div class="mb-2 me-2 btn btn-success" (click)="exportToCSV()"> Download XLSheet </div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <div aria-label="Close" class="btn-close" type="button" (click)="onNoClick()"></div>
</div>
<h1 align="center" style="margin-left: 145px"><b>Invoice Preview</b></h1>
<div style="height: 500px">
  <pdf-viewer [src]="pdfSrc"
              [render-text]="true"
              [original-size]="false"
              style="width: 1000px; height: 500px">
  </pdf-viewer>
</div>
