import {CustomerList, individuallist} from './../../golbalInterfaces';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {API_URL} from '../../../../../Global_API_URL.js';
import {getCities, getCitiesById, getCountries, getPincodesById, getStates, getStatesById} from '../../getCities.js';
import {getAirlines, getAirports} from '../../getAirportAirlines.js';
import {getSubTypeByConsignemntType} from '../../getConsignmentType.js';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {Airport, ConsignmentDocumentType, Countries, Package} from '../../golbalInterfaces.js';
import {DimentionUnits, WeightUnits} from '../../globalconstants.js';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';
import {ConsignmentTypeService} from '../../../modules/consignmentTypes/services/consignment-type.service';
import {ConsignmentTypesModel} from '../../../modules/consignmentTypes/models/consignment-types-model';
import {ConsignmentSubTypesModel} from '../../../modules/consignmentSubtypes/models/consignment-sub-types-model';
import {AuthService} from '../../../auth.service';
import {CreateNewCustomerComponent} from '../../../shared/create-new-customer/create-new-customer.component';
import {MatDialog} from '@angular/material/dialog';
import {CreateNewRepresentativeComponent} from '../../../shared/create-new-representative/create-new-representative.component';
import {HttpService} from 'src/app/services/http.service';
import {ViewConsignmentService} from '../viewconsignment/view-consignment.service';
import {ConsignmentViewService} from '../../AppUser/customer-consignment-view-page/consignment-view.service';
import {MatStepper} from "@angular/material/stepper";

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#d92550';
const SecondaryBlue = '#3f6ad8';


interface CustomerShipper {
  'id': number,
  'firstName': String,
  'address1': String,
  'city1': number,
  'zipCode': String,
  'creditPeriod': number,
  'credit_value': number,
  'email1': String,
  'isCreditExtended': boolean,
  'isKycApproved': boolean,
  'kycApprovedAt': boolean,
  'lastName': String,
  'phone1': String,
  'customerTypeId': number,
  'kycApprovedBy': boolean,
  'isBusinessAccount': boolean,
  'pan': String,
  'gst': String,
  'Id_type': number,
  'Id_value': String,
  'otp_varified': Boolean
}


@Component({
  selector: 'app-atd-consignment',
  templateUrl: './atd-consignment.component.html',
  styleUrls: ['./atd-consignment.component.sass'],
})


export class ATDConsignmentComponent implements OnInit {
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
    formControl: ['', Validators.required],
  });

  @ViewChild('stepper') private matStepper: MatStepper;

  PRODUCT_TYPE;
  MODE;
  errorAddress = false;
  errorReceiver = false;
  errorShipper = false;
  errorRepresentative = false;
  notdisplaytocustomer = true;
  displayAdminandPDA = true;
  clicked = false;
  clickedfind = false;
  show = false;
  customerList: CustomerList[];
  IndividualList: individuallist[];
  adressLists = false;
  CustomerExist = false;
  phoneNoExist = false;
  validatepincodes: boolean;
  validatepincodesServiceable: boolean = false
  emailExist = false;
  filteredOptions: Observable<CustomerList[] | Airport[]>;
  filteredOption: Observable<individuallist[]>;
  isLiner = true;
  isplans = false;
  consignment_with_id;
  customer_with_id;
  receiver_with_id;
  representativeList = [];
  plan_with_id;
  weight_divisor = 6000;
  cities: Array<any> = getCities();
  states: Array<any> = getStates();
  reprsentativeAddress;
  pincodes
  userDetails;
  user_cities
  user_states
  user_pincodes
  user_countries
  user_sel_city
  user_sel_state
  user_sel_country
  user_sel_pincodes
  selectedAddress = null;
  representative_created = false;

  business_cities
  business_states
  business_pincodes
  business_sel_country
  business_sel_state

  Airline

  countries: Countries[] = getCountries();
  selectedWeight: string = 'kg';
  selectedDimention: string = 'cm';
  faArrowRight = faArrowRight;
  myControl = new FormControl();
  myControl2 = new FormControl();
  MyControl_retail = new FormControl();
  MyControl_corporate = new FormControl();

  emailregex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
  emailFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.emailregex),
  ]);
  panregex = /^[a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][0-9][0-9][0-9][0-9][a-zA-Z]$/
  PanFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.panregex),
  ]);
  gstregex = /^[0-9][0-9][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][0-9][0-9][0-9][0-9][a-zA-Z][0-9a-zA-Z][a-zA-Z][0-9a-zA-Z]$/
  gstFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.gstregex),
  ]);
  dimensionuUnits_array: DimentionUnits[] = [{id: 1, name: 'cm'}];
  weightUnits_array: WeightUnits[] = [{id: 1, name: 'kg'}];
  paymentdone = false;

  customer_created = false;
  validatepin = {
    OOA: null,
    airport_id: null,
    airport_name: null,
    message: null,
    result: null,
    Destpincode: null,
    DestpincodeId: null,
    DestIatacode: null,

  }
  TrueOOA: string
  consignment_details = {
    id: null,
    consignmentType: null,
    weightUnit: 1,
    currency: '1',
    weightDivisor: 1,
    consignmentNo: null,
    airlinesId: null,
    isStopover: null,
    totalTransitTime: null,
    senderIndividual: null,
    isIndividualCustomer: null,
    paymentCompletedAt: null,
    isXrayScreening: null,
    isXrayCertification: null,
    isDryIce: null,
    totalWeight: null,
    totalChargableWeight: null,
    planSelected: null,
    senderId: null,
    receiverId: null,
    isDocumentUploaded: null,
    isInsured: false,
    totalShippingCost: null,
    paymentMode: null,
    isPaymentCompleted: null,
    isActive: null,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
    totalNoOfPackages: null,
    destinationLocation: null,
    consignmentSubType: null,
    originLocation: null,
    dimensionuUnit: 1,
    insuranceCoverage: 0,
    totalConsignmentValue: null,
    shippingCost: 0,
    invoiceCreatedDate: '',
    invoiceReferenceNo: null,
    productType: 1,
    remarks: null,
    commodityType: null,
    // tentative_pickup_time: new Date()
  }
  editable = true
  packages: Package[] = [
    {
      id: 1,
      length: null,
      breadth: null,
      height: null,
      weight: null,
      total_weight: null,
      packageNo: null,
      volume_weight: null,
      chargeable_weight: null,
      totalvolumeweight_per_pack: null,
    },
  ]
  receiver = {
    id: null,
    firstName: null,
    lastName: null,
    address: null,
    city: null,
    zipCode: null,
    phone1: null,
    email1: null,
  }
  consignmentTax = 0;
  validShipper = true;
  val = 1;
  total_cost = 0;
  indiviudal_user = {
    id: null,
    firstName: '',
    middleName: '',
    lastName: '',
    avatarUrl: '',
    email: '',
    emailForMasterGST: '',
    phone: '',
    whatsapp: '',
    designation: null,
    employmentNo: null,
    billingAddress: {
      pincode: {
        pin_number: ''
      },
      address: ''
    },
    isSuperUser: null,
    userName: '',
    passwordHash: null,
    lastLoggedInAt: null,
    lastLoggedInIp: null,
    userType: null,
    customerType: 2,
    orgCustomerId: {id: null},
    aadhaarNumber: null,
    drivingLicense: null,
    gstNumber: null,
    panNumber: null,
    gstDetails: null,
    panDetails: null,
    aadhaarDetails: null,
    isActive: true,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
  }
  customer_shipper = {
    id: null,
    firstName: '',
    address_1: '',
    city_1: null,
    creditPeriod: null,
    credit_value: null,
    email_1: '',
    zip_code: '',
    isCreditExtended: null,
    isKycApproved: null,
    kycApprovedAt: null,
    office_location: null,
    lastName: '',
    phone_1: '',
    customerTypeId: 2,
    kycApprovedBy: null,
    isBusinessAccount: true,
    pan: '',
    gst: '',
    Id_type: null,
    Id_value: null,
    otp_varified: null,
    isSezCustomer: false,
  }

  change_business() {
    if (this.val == 1) {
      this.indiviudal_user.customerType = 2;
    } else {
      this.indiviudal_user.customerType = 1;
    }
  }

  AutomobileDocument: Array<ConsignmentDocumentType> = [
    {
      id: 1,
      name: 'Non-DG Declaration',
      description: 'Non-DG Declaration',
      is_mandatory: true,
      upload_later: false,
    },
    {
      id: 2,
      name: 'E-waybill',
      description: 'E-waybill',
      is_mandatory: false,
      upload_later: false,
    },
    {
      id: 3,
      name: 'Packing List',
      description: 'Packing List',
      is_mandatory: false,
      upload_later: false,
    },
  ]

  planList: any = {
    origin: {},
    destination: {},
    bestRoutes: [],
    count: 0,
    query_timestamp: '2022-04-21T10:30:00+5:30',
  }

  PharmaDocument: Array<ConsignmentDocumentType> = [
    {
      id: 1,
      name: 'Non-DG Declaration',
      description: 'Non-DG Declaration',
      is_mandatory: true,
      upload_later: false,
    },
    {
      id: 2,
      name: 'E-waybill',
      description: 'E-waybill',
      is_mandatory: false,
      upload_later: false,
    },
    {
      id: 3,
      name: 'Shipment Documents',
      description: 'Shipment Documents',
      is_mandatory: true,
      upload_later: false,
    },
  ]

  requiredDocument: any = []


  consignmentTypes: ConsignmentTypesModel[];
  // consignmentTypes = getConsignmentType();
  consignmentSubTypes: ConsignmentSubTypesModel[];
  filteredAirports: Observable<Airport[]>
  filteredAirports2: Observable<Airport[]>
  airports: Airport[] = getAirports();
  airports2: Airport[] = getAirports();

  selectConsignmentType(consignmentType: ConsignmentTypesModel) {
    this.consignment_details.consignmentSubType = null;
    this.consignmentSubTypes = consignmentType.consignment_sub_types_list;
  }

  getDocumentTypes() {
    this.requiredDocument = [];
    const con_docs = [
      {
        id: 1,
        name: 'Non-DG Declaration',
        description: 'Non-DG Declaration',
        is_mandatory: true,
        upload_later: false,
      },
      {
        id: 2,
        name: 'E-waybill',
        description: 'E-waybill',
        is_mandatory: false,
        upload_later: false,
      },
      {
        id: 3,
        name: 'Shipment Documents',
        description: 'Shipment Documents',
        is_mandatory: true,
        upload_later: false,
      },
    ];

    // if (this.consignmentSubTypes && this.consignment_details && this.consignment_details.consignmentSubType) {
    //   this.consignmentSubTypes.forEach(element => {
    //     if (element.id == this.consignment_details.consignmentSubType) {
    //       con_docs = []; // Ensure fallback to an empty array if undefined
    //     }
    //   });
    // } else {
    //   // Handle scenario where necessary data is missing or undefined
    //   console.error("Missing required data for document types");
    //   return;
    // }

    for (let i = 0; i < con_docs.length; i++) {
      const document_req = {
        id: null,
        name: null,
        description: null,
        is_mandatory: false,
        upload_later: false,
        file: null,
        uploaded: false,
      };

      document_req.id = con_docs[i].id;
      document_req.name = con_docs[i].name;
      document_req.is_mandatory = con_docs[i].is_mandatory;
      document_req.upload_later = con_docs[i].upload_later;
      this.requiredDocument.push(document_req);
    }
  }

  async findindividual() {
    const TOKEN = this.authService.getAuthToken();
    this.errorShipper = true;
    this.errorRepresentative = true;
    await fetch(API_URL + '/org_users/' + this.indiviudal_user.id,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
            'X-Active': 'true',
          },
        })
        .then((response) => response.json())
        .then((result) => {
          this.representativeList = result.data;
          this.getAddressList(this.indiviudal_user.id);
          this.show = true;
        });
  }

  getFiles(event, id) {
    // alert(id);
    for (let i = 0; i < this.requiredDocument.length; i++) {
      if (this.requiredDocument[i].id == id) {
        // alert("here id ="+id)
        this.requiredDocument[i].file = event.target.files[0];
      }
    }
  }

  uploadedDocuments: any[] = [];
  async uploadDocument(doc: any) {
    if (!doc || !doc.file) {
      // If the document or its file is null, show an error message
      Swal.fire({
        icon: 'error',
        title: 'Please select a document to upload',

        timer: 3000,
      });
      return; // Exit the function
    }
    // Validate the content type of the document against the allowed types
    const allowedDocs = [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'image/png',
      'image/jpeg',
      'image/jpg',
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];
    if (!allowedDocs.includes(doc.file.type)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid content Type',
        text: 'Allowed types: pdf, doc, docx, jpeg, jpg, png, csv, xls, xlsx',

        timer: 5000,
      });
      return; // Exit the function
    }
    // Check if file size exceeds the limit
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (doc.file.size > maxSizeInBytes) {
      Swal.fire({
        icon: 'error',
        title: 'File size exceeds limit',
        text: 'Maximum file size allowed is 10MB',

        timer: 5000,
      });
      return; // Exit the function
    }

    const TOKEN = this.authService.getAuthToken();

    const head: any = {
      'Authorization': 'Bearer ' + TOKEN,
      'fileMd5Hash': '',
      'fileType': '',
      'name': '',
      'consignmentDocumentTypeId': doc.id,
      'consignmentId': this.consignment_with_id.data[0].id,
      'consignmentDocumentTypeName': doc.name, // Add this line

    };

    const formdata = new FormData();
    formdata.append('file', doc.file);
    formdata.append('consignmentDocumentTypeName', doc.name); // Assuming you include this field in your form

    try {
      const response = await fetch(API_URL + '/consignmentDocuments', {
        method: 'POST',
        body: formdata,
        headers: head,
      });

      if (response.ok) {
        const result = await response.json(); // Assuming the response is JSON, adjust if needed
        doc.downloadLink = result.downloadLink;
        doc.uploaded = true;


        // Add the uploaded document to the list
        this.uploadedDocuments.push(doc);

        Swal.fire({
          icon: 'success',
          title: 'Document Uploaded Successfully',

          timer: 2000,
        });
        doc.uploaded = true;
      } else {
        const errorText = await response.text();
        console.error('Error uploading document:', response.status, errorText);
      }
    } catch (error) {
      console.error('Exception uploading document:', error);
    }
  }
 documentPaths = {
   1: {path: 'assets/images/Non-DG_Declaration.docx', name: 'Non-DG_Declaration.docx'},
   2: {path: 'assets/images/E-waybill.docx', name: 'E-waybill.docx'},
   3: {path: 'assets/images/MSDS or COA.docx', name: 'MSDS or COA.docx'},
 };

 downloadDocuments(docId: number) {
   const doc = this.documentPaths[docId];
   if (doc) {
     const link = document.createElement('a');
     link.href = doc.path;
     link.download = doc.name;
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
   } else {
     console.error('Document not found for ID:', docId);
   }
 }
 private _filter(name: String): Airport[] {
   const filterValue = name.toLowerCase();

   return this.airports.filter((option) => option.city.name.toLowerCase().includes(filterValue));
 }

 private _filter2(name: String): Airport[] {
   const filterValue = name.toLowerCase();

   return this.airports2.filter((option) => option.city.name.toLowerCase().includes(filterValue));
 }

 displayFnss(user: individuallist): String {
   return user && user.firstName ? user.firstName : '';
 }

 private _filterss(name: String): individuallist[] {
   const filterValue = name.toLowerCase();
   return this.IndividualList.filter((option) => option.firstName.toLowerCase().includes(filterValue));
 }

 displayFn(airport: Airport): String {
   return airport && airport.city?.name ? airport.city?.name : '';
 }

 displayFn2(airport2: Airport): String {
   return airport2 && airport2.city?.name ? airport2.city?.name : '';
 }

 async ngOnInit() {
   this.getOfficeLocations();
   this.commodityTypes = await this.getCommodityTypes();
   this.route.queryParams.subscribe((params) => {
     // console.log(params);
     this.PRODUCT_TYPE = params.type;
     this.MODE = params.mode;
   },
   );

   this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
   // console.log(this.userDetails)
   // console.log(this.userDetails.userTypes[0].name)
   if (this.userDetails.userTypes[0].name == 'Customer') {
     this.notdisplaytocustomer = false;
     await this.selectRep(this.userDetails.id);
   }
   if (this.userDetails.userTypes[0].name == 'Admin' || this.userDetails.userTypes[0].name == 'Supervisor') {
     this.displayAdminandPDA = false;
   }
   this.airports = await getAirports();
   this.airports2 = await getAirports();
   this.filteredAirports = this.myControl.valueChanges.pipe(
       startWith(''),
       map((value) => (typeof value === 'string' ? value : value.name)),
       map((name) => (name ? this._filter(name) : this.airports.slice())),
   );

   this.filteredAirports2 = this.myControl2.valueChanges.pipe(
       startWith(''),
       map((value) => (typeof value === 'string' ? value : value.name)),
       map((name) => (name ? this._filter2(name) : this.airports2.slice())),
   );

   await this.updateoptions();
   this.Airline = await getAirlines();
   this.planList.bestRoutes = this.planList?.bestRoutes?.sort((low, high) => low.Price - high.Price);

   await this.consignmentTypeService.getConsignmentTypeByProductType(this.PRODUCT_TYPE).subscribe((result) => {
     this.consignmentTypes = result.data;
   });

   if (this.userDetails.customerType == 1) {
     this.val = 2;
   }
 }

 async updateoptions() {
   this.customerList = await this.getCustomerList();
   this.filteredOptions = this.MyControl_corporate.valueChanges.pipe(
       startWith(''),
       map((value) => {
         const name = typeof value === 'string' ? value : value?.name;
         return name ? this._filters(name as string) : this.customerList.slice();
       }),
   );
   this.IndividualList = await this.getIndividualList();
   this.filteredOption = this.MyControl_retail.valueChanges.pipe(
       startWith(''),
       map((value) => {
         const name = typeof value === 'string' ? value : value?.name;
         return name ? this._filterss(name as string) : this.IndividualList.slice();
       }),
   );
 }

 async getConsignmentSubtype() {
   if (this.consignment_details.consignmentType != null) {
     this.consignmentSubTypes = await getSubTypeByConsignemntType(this.consignment_details.consignmentType);
     // console.log(this.consignmentSubTypes + "in blur function")
   }
 }


 addPackage() {
   const new_package = {
     id: this.packages.length + 1,
     length: null,
     breadth: null,
     height: null,
     weight: null,
     total_weight: null,
     packageNo: null,
     volume_weight: null,
     chargeable_weight: null,
     totalvolumeweight_per_pack: null,
   };
   this.packages.push(new_package);
   let j = 1;
   this.packages.forEach((element) => {
     element.id = j;
     j++;
   });
 }

 deletePackage(id) {
   for (let index = 0; index < this.packages.length; index++) {
     if (this.packages[index].id == id) {
       this.removeItemOnce(this.packages, index);
     }
   }
   let j = 1;
   this.packages.forEach((element) => {
     element.id = j;
     j++;
   });
   const length = this.packages.length;
   this.eachPackageCharged(length);
 }

 selected_dimentions() {
   for (let index = 0; index < this.dimensionuUnits_array.length; index++) {
     if (this.dimensionuUnits_array[index].id == this.consignment_details.dimensionuUnit) {
       this.selectedDimention = this.dimensionuUnits_array[index].name;
     }
   }
 }

 selected_weight() {
   for (let index = 0; index < this.weightUnits_array.length; index++) {
     if (this.weightUnits_array[index].id == this.consignment_details.weightUnit) {
       this.selectedWeight = this.weightUnits_array[index].name;
     }
   }
 }

  totalvolume_weight = 0;

  eachPackageCharged(id) {
    this.consignment_details.totalNoOfPackages = 0;
    this.consignment_details.totalWeight = 0;
    this.consignment_details.totalChargableWeight = 0;
    for (let index = 0; index < this.packages.length; index++) {
      this.packages[index].total_weight = Number(this.packages[index].packageNo) * this.packages[index].weight;
      if (this.packages[index].id == id) {
        this.packages[index].volume_weight = (Number(this.packages[index].length) * Number(this.packages[index].breadth) * Number(this.packages[index].height) / Number(this.weight_divisor));
        // console.log(this.packages[index].volume_weight + "  This is volume weight")

        if (this.packages[index].volume_weight > this.packages[index].weight) {
          this.packages[index].chargeable_weight = Number(this.packages[index].volume_weight) * Number(this.packages[index].packageNo);
        } else {
          this.packages[index].chargeable_weight = this.packages[index].total_weight;
        }
      }
      this.consignment_details.totalNoOfPackages = Number(this.consignment_details.totalNoOfPackages) + Number(this.packages[index].packageNo);
      this.consignment_details.totalWeight = this.consignment_details.totalWeight + Number(this.packages[index].total_weight);
      this.consignment_details.totalChargableWeight = Math.ceil(this.consignment_details.totalChargableWeight + this.packages[index].chargeable_weight);
      this.packages[index].totalvolumeweight_per_pack = Math.ceil((this.packages[index].volume_weight) * (this.packages[index].packageNo));
      this.totalvolumeweightCal(this.packages);
    }
  }

  totalvolumeweightCal(pac) {
    this.totalvolume_weight = 0;
    pac.forEach((element) => {
      this.totalvolume_weight = this.totalvolume_weight + element.totalvolumeweight_per_pack;
    });
    this.totalvolume_weight = Math.ceil(this.totalvolume_weight);
  }

  generate_awb() {
    // this.consignment_details.awb_no = 101010;
  }

  generate_ewaybill() {
    // this.consignment_details.e_waybill_no = 1234569870
    // let roles = this.keycloakService.logout();
    // alert(roles);
  }

  removeItemOnce(arr, value) {
    const index = value;
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  RoundOffCharge(plan) {
    return Math.ceil(plan);
  }


  // add the get user detail api her if the user is existing
  async selectedPlan(plan) {
    const TOKEN = this.authService.getAuthToken();
    this.loadingSecound = true;
    // alert(JSON.stringify(plan));
    const Routes = [];

    plan.routes.forEach((route) => {
      const eachroute = {
        'flights_schedule': route.id,
        'arrival_time': route.arrivalTime,
        'departure_time': route.departureTime,
        'arrival_airport_Id': route.arrivalAirportID.id,
        'departure_airport_Id': route.departureAirportID.id,
        'airline_Id': route.airlineID.id,
        'flight_no': route.flightNo,
        'transit_time': route.transitTime,
        'transit_mode': route.transitMode,
      };
      Routes.push(eachroute);
    });

    const priceBreadown = {
      'airlineTarrifCharge': plan.priceBreakdown.airlineTarrifCharge,
      'ahcOutboundCharge': plan.priceBreakdown.ahcOutboundCharge,
      'ahcInboundCharge': plan.priceBreakdown.ahcInboundCharge,
      'mawbFeeCharge': plan.priceBreakdown.mawbFeeCharge,
      'otherCharges': plan.priceBreakdown.otherCharges,
      'iclscmHandlingCharge': plan.priceBreakdown.iclscmHandlingCharge,
      'shipmentValuveCharge': plan.priceBreakdown.shipmentValuveCharge,
      'demurgeCharge': plan.priceBreakdown.demurgeCharge,
      'coldChainWarehousingCharge': plan.priceBreakdown.coldChainWarehousingCharge,
      'doorPickupCharge': plan.priceBreakdown.doorPickupCharge,
      'doorDeliveryCharge': plan.priceBreakdown.doorDeliveryCharge,
      'airlineSurcharge': plan.priceBreakdown.airlineSurcharge,
      'additionalSurcharge': plan.priceBreakdown.additionalSurcharge,
    };
    const postSelected = {
      'consignmentId': this.planList.id,
      'pickupTime': plan.pickupTime,
      'dropTime': plan.dropTime,
      'transitTime': plan.totalTranistTime,
      'priceBreakdown': priceBreadown,
      'routes': Routes,
      'totalCost': plan.totalCostIn,
    };
    this.consignment_details.shippingCost = plan.totalCostIn;
    await fetch(API_URL + '/selected_plan',
        {
          method: 'POST',
          body: JSON.stringify(postSelected),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then(async (result) => {
        // this.clicked = true
        // console.log(result);
          this.plan_with_id = result.data[0];
          // console.log(this.plan_with_id);
        })
        .catch((error) => {
          console.error('Error', error);
          alert(error);
        });
    this.loading = true;
    this.loading = false;
    if (this.consignment_details.senderId != null) {
      this.validShipper = true;
      // get he user detia here

      // await featch
    }
    this.loadingSecound = false;
  }


  // add the get user detail api her if the user is existing
  async selectedPlanChange(plan) {
    const TOKEN = this.authService.getAuthToken();
    this.loadingSecound = true;
    // alert(JSON.stringify(plan));
    const Routes = [];

    plan.routes.forEach((route) => {
      const eachroute = {
        'flights_schedule': route.id,
        'arrival_time': route.arrivalTime,
        'departure_time': route.departureTime,
        'arrival_airport_Id': route.arrivalAirportID.id,
        'departure_airport_Id': route.departureAirportID.id,
        'airline_Id': route.airlineID.id,
        'flight_no': route.flightNo,
        'transit_time': route.transitTime,
        'transit_mode': route.transitMode,
      };
      Routes.push(eachroute);
    });

    const priceBreadown = {
      'airlineTarrifCharge': plan.priceBreakdown.airlineTarrifCharge,
      'ahcOutboundCharge': plan.priceBreakdown.ahcOutboundCharge,
      'ahcInboundCharge': plan.priceBreakdown.ahcInboundCharge,
      'mawbFeeCharge': plan.priceBreakdown.mawbFeeCharge,
      'otherCharges': plan.priceBreakdown.otherCharges,
      'iclscmHandlingCharge': plan.priceBreakdown.iclscmHandlingCharge,
      'shipmentValuveCharge': plan.priceBreakdown.shipmentValuveCharge,
      'demurgeCharge': plan.priceBreakdown.demurgeCharge,
      'coldChainWarehousingCharge': plan.priceBreakdown.coldChainWarehousingCharge,
      'doorPickupCharge': plan.priceBreakdown.doorPickupCharge,
      'doorDeliveryCharge': plan.priceBreakdown.doorDeliveryCharge,
      'airlineSurcharge': plan.priceBreakdown.airlineSurcharge,
      'additionalSurcharge': plan.priceBreakdown.additionalSurcharge,
    };
    const postSelected = {
      'consignmentId': this.planList.id,
      'pickupTime': plan.pickupTime,
      'dropTime': plan.dropTime,
      'transitTime': plan.totalTranistTime,
      'priceBreakdown': priceBreadown,
      'routes': Routes,
      'totalCost': plan.totalCostIn,
    };
    this.consignment_details.shippingCost = plan.totalCostIn;
    await fetch(API_URL + '/consignment/' + this.planList.id + '/selectedplan',
        {
          method: 'PATCH',
          body: JSON.stringify(postSelected),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then(async (result) => {
        // console.log(result);
          this.plan_with_id = result.data[0];
          // console.log(this.plan_with_id);
        })
        .catch((error) => {
          console.error('Error', error);
          alert(error);
        });
    this.loading = true;
    this.loading = false;
    if (this.consignment_details.senderId != null) {
      this.validShipper = true;
      // get he user detia here

      // await featch
    }
    this.loadingSecound = false;
  }

  async goToUpload() {
    this.loadingThird = true;

    this.getDocumentTypes();

    this.loadingThird = false;
  }

  async validatepincode() {
    const TOKEN = this.authService.getAuthToken();
    //  let validatepincode;
    await fetch(API_URL + '/validateDestinationPincode?destination=' + this.consignment_details.destinationLocation,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => response.json())
        .then(async (result) => {
          this.validatepin = result;
          if (this.validatepin.result == false) {
            this.validatepincodes = true;
            this.validatepincodesServiceable = false;
          } else if (this.validatepin.result == true) {
            this.validatepincodes = false;
            this.validatepincodesServiceable = true;
          }
          if (this.validatepin.OOA == true) {
            this.TrueOOA = 'OOA';
          } else if (this.validatepin.OOA == false) {
            this.TrueOOA = 'Regular';
          }
        })


        .catch((error) => {
          console.error('error', error);
        });
  }

  deleteDisable = false;

  async createConsignment() {
    const TOKEN = this.authService.getAuthToken();
    this.loading = true;
    const pac = {
      'packageNo': null,
      'shipment_id': null,
      'weight_per_package': null,
      'length': null,
      'breadth': null,
      'height': null,
      'total_weight': null,
      'volume_weight': null,
      'chargeable_weight': null,
    };
    const pacList = [];
    let valid_pack = true;
    for (let i = 0; i < this.packages.length; i++) {
      const pac = {
        'packageNo': null,
        'shipment_id': null,
        'weight_per_package': null,
        'length': null,
        'breadth': null,
        'height': null,
        'total_weight': null,
        'volume_weight': null,
        'chargeable_weight': null,
      };
      if (this.packages[i].breadth > 90 || this.packages[i].length > 90 || this.packages[i].height > 90) {
        valid_pack = false;
        Swal.fire({
          icon: 'error',
          title: 'CAUTION',
          // text: 'Package dimetion should not exceed 90cm X 90cm X 90cm '
          text: 'Odd Size Shipment. Please contact: 9121006802',
          timer: 5000,

        });
        this.loading = false;
        break;
      } else if (this.packages[i].breadth < 1 || this.packages[i].length < 1 || this.packages[i].height < 1 || this.packages[i].breadth == null || this.packages[i].length == null || this.packages[i].height == null) {
        valid_pack = false;
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Missing Package Dimension',
          timer: 5000,

        });
        this.loading = false;
        break;
      } else if (this.packages[i].packageNo <= 0 || this.packages[i].packageNo == null) {
        valid_pack = false;
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Atleast one package is required',
          timer: 5000,

        });
        this.loading = false;
        break;
      } else if (this.packages[i].weight <= 0 || this.packages[i].weight == null) {
        valid_pack = false;
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Weight per Package missing',
          timer: 5000,

        });
        this.loading = false;
        break;
      }
      pac.packageNo = this.packages[i].packageNo;
      pac.shipment_id = null;
      pac.weight_per_package = this.packages[i].weight;
      pac.length = this.packages[i].length;
      pac.breadth = this.packages[i].breadth;
      pac.height = this.packages[i].height;
      pac.total_weight = this.packages[i].total_weight;
      pac.volume_weight = this.packages[i].totalvolumeweight_per_pack;
      pac.chargeable_weight = this.packages[i].chargeable_weight;
      pacList.push(pac);
    }

    let cons;

    if (this.userDetails.customerType == 1) {
      cons =
        {
          'consignmentType': this.consignment_details.consignmentType,
          'consignmentSubTypeId': this.consignment_details.consignmentSubType,
          'dimensionuUnit': this.consignment_details.dimensionuUnit,
          'weightUnit': this.consignment_details.weightUnit,
          'currency': this.consignment_details.currency,
          'weightDivisor': this.consignment_details.weightDivisor,
          'details': 'string',
          'originLocation': this.consignment_details.originLocation,
          'destinationLocation': this.consignment_details.destinationLocation,
          'totalNoOfPackages': this.consignment_details.totalNoOfPackages,
          'totalWeight': this.consignment_details.totalWeight,
          'totalChargableWeight': this.consignment_details.totalChargableWeight,
          'packages': pacList,
          'isIndividualCustomer': true,
          // "senderId": this.userDetails.id,
          'senderIndividual': this.consignment_details.senderIndividual,
          'totalConsignmentValue': this.consignment_details.totalConsignmentValue,
          'invoiceCreatedDate': this.consignment_details.invoiceCreatedDate,
          'invoiceReferenceNo': this.consignment_details.invoiceReferenceNo,
          'iscorporateBooking': false,
          'productType': 4,
          'remarks': this.consignment_details.remarks,
          'createdBy': this.userDetails.id,
          'commodity_type': this.consignment_details.commodityType,
          // "tentative_pickup_time" : this.consignment_details.tentative_pickup_time
        };
    } else {
      cons =
        {
          'consignmentType': this.consignment_details.consignmentType,
          'consignmentSubTypeId': this.consignment_details.consignmentSubType,
          'dimensionuUnit': this.consignment_details.dimensionuUnit,
          'weightUnit': this.consignment_details.weightUnit,
          'currency': this.consignment_details.currency,
          'weightDivisor': this.consignment_details.weightDivisor,
          'details': 'string',
          'originLocation': this.consignment_details.originLocation,
          'destinationLocation': this.consignment_details.destinationLocation,
          'totalNoOfPackages': this.consignment_details.totalNoOfPackages,
          'totalWeight': this.consignment_details.totalWeight,
          'totalChargableWeight': this.consignment_details.totalChargableWeight,
          'packages': pacList,
          'isIndividualCustomer': true,
          'senderId': this.consignment_details.senderId,
          'senderIndividual': this.consignment_details.senderIndividual,
          'totalConsignmentValue': this.consignment_details.totalConsignmentValue,
          'invoiceCreatedDate': this.consignment_details.invoiceCreatedDate,
          'invoiceReferenceNo': this.consignment_details.invoiceReferenceNo,
          'iscorporateBooking': false,
          'productType': 4,
          'remarks': this.consignment_details.remarks,
          'createdBy': this.userDetails.id,
          'commodity_type': this.consignment_details.commodityType,
          // "tentative_pickup_time" : this.consignment_details.tentative_pickup_time
        };
    }

    // let cons =
    // {
    //   "consignmentType": this.consignment_details.consignmentType,
    //   "consignmentSubTypeId": this.consignment_details.consignmentSubType,
    //   "dimensionuUnit": this.consignment_details.dimensionuUnit,
    //   "weightUnit": this.consignment_details.weightUnit,
    //   "currency": this.consignment_details.currency,
    //   "weightDivisor": this.consignment_details.weightDivisor,
    //   "details": "string",
    //   "originLocation": this.consignment_details.originLocation,
    //   "destinationLocation": this.consignment_details.destinationLocation,
    //   "totalNoOfPackages": this.consignment_details.totalNoOfPackages,
    //   "totalWeight": this.consignment_details.totalWeight,
    //   "totalChargableWeight": this.consignment_details.totalChargableWeight,
    //   "packages": pacList,
    //   "isIndividualCustomer": true,
    //   "senderId": this.consignment_details.senderId,
    //   "senderIndividual": this.consignment_details.senderIndividual,
    //   "totalConsignmentValue": this.consignment_details.totalConsignmentValue,
    //   "invoiceCreatedDate": this.consignment_details.invoiceCreatedDate,
    //   "invoiceReferenceNo": this.consignment_details.invoiceReferenceNo,
    //   "iscorporateBooking": false,
    //   "productType": 4,
    //   "remarks": this.consignment_details.remarks,
    //   "createdBy": this.userDetails.id,
    //   // "tentative_pickup_time" : this.consignment_details.tentative_pickup_time
    // }
    if (this.userDetails.customerType != 1 && this.val != 2) {
      cons.isIndividualCustomer = false;
    } else {
      cons.isIndividualCustomer = true;
      cons.senderIndividual = this.indiviudal_user.id;
    }
    if ((this.val == 1 || this.val == 2) && !cons.senderIndividual) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please select the shipper.',
        timer: 5000,

      });
      this.loading = false;
      return; // exit the function if shipper is not selected
    }
    if (cons.consignmentType == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Consignment Type Missing',
        timer: 5000,

      });
      this.loading = false;
    }
    if (cons.consignmentSubTypeId == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Consignment Sub Type Missing',
        timer: 5000,

      });
      this.loading = false;
    } else if (cons.commodity_type == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select Commodity Type',
        timer: 5000,
      });
      this.loading = false;
      return;
    } else if (cons.originLocation == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please select Origin Location',
        timer: 5000,

      });
      this.loading = false;
    } else if (cons.destinationLocation == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please select Destination pincode',
        timer: 5000,

      });
      this.loading = false;
    } else if (this.validatepincodes == true) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Destination pincode Not Serviceable',
        timer: 5000,

      });
      this.loading = false;
    } else if (cons.totalChargableWeight > 1000) {
      Swal.fire({
        icon: 'warning',
        title: 'Odd Size Shipment',
        html: 'Email: <a href="#" id="contactEmail">balajiks@iclscm.com</a> <br> contact: +91 9121006802',
        timer: 5000,

      });

      document.getElementById('contactEmail').addEventListener('click', function() {
        window.open('https://mail.google.com/mail/?view=cm&to=balajiks@iclscm.com');
      });
      this.loading = false;
    } else if (valid_pack == true) {
      // console.log(cons.destinationLocation)
      cons.originLocation = cons.originLocation.id;
      cons.destinationLocation = this.validatepin.airport_id;
      await fetch(API_URL + '/consignment' + '?&type=' + this.PRODUCT_TYPE + '&mode=' + this.MODE,
          {
            method: 'POST',
            body: JSON.stringify(cons),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + TOKEN,
            },
          })
          .then((response) => response.json())
          .then(async (result) => {
            this.consignment_with_id = result;
            this.clicked = false;
            this.plan_with_id = null;
            this.receiver.city = this.consignment_with_id.data[0].destinationLocation.city.id;
            this.receiver.zipCode = this.validatepin.Destpincode;
            // console.log(this.receiver.zipCode);

            await fetch(API_URL + '/plan?destination=' + this.consignment_details.destinationLocation, {
              method: 'POST',
              body: JSON.stringify({
                'consignmentId': result.data[0].id,
              }),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + TOKEN,
              },
            })
                .then((response) => response.json())
                .then((res) => {
                  if(res?.errors?.length) {
                    // alert(res?.message);
                     Swal.fire({
                       icon: 'warning',
                       title: 'WARNING',
                       text: res?.message,
                       timer: 5000,
               
                     });
                   }
                  if(res.message == 'Contract Exists') {
                    this.matStepper.next();
                    return;
                  }
                  this.planList = res.data;
                  this.isplans = true;
                  // this.selectedAddress = null;
                  this.array = this.planList?.bestRoutes;
                  this.deleteDisable = true;

                  // alert(JSON.stringify(res))
                })
                .catch((error) => {
                  console.error('Error', error);
                  alert(error);
                });
          // console.log(result);
          })
          .catch((error) => {
            console.error('Error', error);
            alert(error);
          });
      this.getReceverPincodes();
      this.isplans = true;
      this.loading = false;
    }
    this.find();
  }

  async createCustomer() {
    const TOKEN = this.authService.getAuthToken();
    this.indiviudal_user.userName = this.indiviudal_user.email;
    this.indiviudal_user.userType = 5;

    // this.indiviudal_user.customerType == 2
    if (this.customer_shipper.firstName == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company Name ',
        timer: 5000,

      });
    } else if (this.customer_shipper.email_1 == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company Email ',
        timer: 5000,

      });
    } else if (!(this.emailregex.test(this.customer_shipper.email_1)) && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',

        text: 'Please Enter Valid Email',
        timer: 5000,

      });
    } else if (this.customer_shipper.phone_1 == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company Phone Number ',
        timer: 5000,

      });
    } else if ((this.customer_shipper.phone_1).length < 10 && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Valid Company Phone Number',
        timer: 5000,

      });
    } else if (isNaN(parseInt(this.customer_shipper.phone_1)) == true && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Valid Company Phone Number',
        timer: 5000,

      });
    } else if (this.customer_shipper.address_1 == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company Address',
        timer: 5000,

      });
    } else if (this.customer_shipper.city_1 == null && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company City',
        timer: 5000,

      });
    } else if (this.customer_shipper.zip_code == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company Zip Code',
        timer: 5000,

      });
    } else if (this.customer_shipper.office_location == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select the Billing Branch',
        timer: 5000,

      });
    } else if (this.customer_shipper.gst == null && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company GST No',
        timer: 5000,

      });
    } else if (!(this.gstregex.test(this.customer_shipper.gst)) && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Valid GST No',
        timer: 5000,

      });
    } else if (this.customer_shipper.pan == null && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company PAN No',
        timer: 5000,

      });
    } else if (!(this.panregex.test(this.customer_shipper.pan)) && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Valid PAN No',
        timer: 5000,

      });
    } else if (this.indiviudal_user.firstName == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s First Name ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.lastName == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Last Name ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.email == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s email ',
        timer: 5000,

      });
    } else if (!(this.emailregex.test(this.indiviudal_user.email))) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',

        text: 'Please Enter Valid Email',
        timer: 5000,

      });
    } else if (this.indiviudal_user.phone == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Phone Number ',
        timer: 5000,

      });
    } else if (isNaN(parseInt(this.indiviudal_user.phone)) == true || this.indiviudal_user.phone.length < 10) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Valid User\'s Phone Number',
        timer: 5000,

      });
    } else if (this.indiviudal_user.billingAddress?.address == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Address ',
        timer: 5000,

      });
    } else if (this.user_sel_city == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select The User\'s City ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.billingAddress.pincode.pin_number == '' || this.indiviudal_user.billingAddress.pincode.pin_number == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Zip Code ',
        timer: 5000,

      });
    } else {
      this.Representativeload = true;
      if (this.indiviudal_user.customerType == 2) {
        await fetch(API_URL + '/customer',
            {
              method: 'POST',
              body: JSON.stringify(this.customer_shipper),
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN,
              },
            })
            .then((response) => response.json())
            .then(async (result) => {
              this.customer_with_id = result.data[0];
              this.indiviudal_user.orgCustomerId = result?.data[0]?.id;
              this.consignment_details.senderId = result.data[0].id;
              await fetch(API_URL + '/register',
                  {
                    method: 'POST',
                    body: JSON.stringify(this.indiviudal_user),
                    headers: {
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                      'Authorization': 'Bearer ' + TOKEN,
                    },
                  })
                  .then((response) => response.json())
                  .then((res) => {
                    Swal.fire({
                      icon: 'success',
                      title: 'Sender created',

                      timer: 5000,
                    });
                    this.Representativeload = false;
                    this.indiviudal_user = res.data[0];
                    this.consignment_details.senderIndividual = this.indiviudal_user.id;
                    this.customer_created = true;
                  });

            // console.log(result);
            })
            .catch((error) => {
              console.error('Error', error);
              this.Representativeload = false;
              alert(error);
            });
      } else {
        await fetch(API_URL + '/register',
            {
              method: 'POST',
              body: JSON.stringify(this.indiviudal_user),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + TOKEN,
              },
            })
            .then((response) => response.json())
            .then((result) => {
              if (result.status == 500) {
                alert('Internal Server');
              } else {
                Swal.fire({
                  icon: 'success',
                  title: 'Sender created',

                  timer: 5000,
                });
                this.Representativeload = false;
                this.indiviudal_user = result.data[0];
                this.consignment_details.senderIndividual = this.indiviudal_user.id;
                this.customer_created = true;
              // this.createnewformreset()
              // console.log(result);
              }
            })
            .catch((error) => {
              console.error('Error', error);
              this.Representativeload = false;
              alert(error);
            });
      }
      // this.loadingSecound = false;
      this.updateoptions();
    }
  }

  async getIndividualList() {
    const TOKEN = this.authService.getAuthToken();
    let individualList: individuallist[];
    await fetch(API_URL + '/individual_user',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then(async (result) => {
          individualList = result.sort((a, b) => a.firstName.localeCompare(b.firstName));
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'User Not Found',

            timer: 5000,
          });
        });
    return individualList;
  }


  async createPayment() {
    const TOKEN = this.authService.getAuthToken();
    // console.log(this.selectedAddress);
    if (this.selectedAddress == null || this.selectedAddress == undefined) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select PickUp Address ',
        timer: 5000,

      });
    } else {
      const payment_json: any = {
        plan_id: this.plan_with_id?.id ?? null,
        consignmentId: this.consignment_with_id.data[0].id,
        pickupAddress: this.selectedAddress,
        flightNumber: this.plan_with_id?.flightNumber ?? null,
        source: this.consignment_with_id.data[0].originLocation.id,
        destination: this.consignment_with_id.data[0].destinationLocation.id,
        shippingTime: null,
        manifestId: '',
        premanifestId: '',
        totalShippingCost: this.consignment_details.totalShippingCost,
        shippingCost: this.consignment_details.shippingCost,
        paymentMode: this.consignment_details.paymentMode,
        totalConsignmentValue: this.consignment_details.totalConsignmentValue,
        isInsured: this.consignment_details.isInsured,
        insuranceCoverage: this.consignment_details.insuranceCoverage,
        recieverId: this.receiver_with_id.id,
      };
      if (this.consignment_details.totalConsignmentValue == null) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Please Enter Consignment Value',
          timer: 5000,

        });
      } else if (this.consignment_details.paymentMode == null) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',

          text: 'Please Select Payment Mode',
          timer: 5000,
        });
      } else {
        this.loading = true;
     //   const departure_time_parsed = (Date.parse(this.plan_with_id?.departureTime) / 1000) ?? null;
        const departure_time_parsed = isNaN(Date.parse(this.plan_with_id?.departureTime))? null: Date.parse(this.plan_with_id?.departureTime) / 1000;
        payment_json.shippingTime = departure_time_parsed;
        payment_json.manifestId = this.consignment_with_id.data[0].originLocation.iataCode + '_' + this.consignment_with_id.data[0].destinationLocation.iataCode + '_' + payment_json.flightNumber + '_' + parseInt(payment_json.shippingTime);
        payment_json.premanifestId = this.consignment_with_id.data[0].originLocation.iataCode + '_' + this.consignment_with_id.data[0].destinationLocation.iataCode + '_' + payment_json.flightNumber + '_' + parseInt(payment_json.shippingTime);
        await fetch(API_URL + '/createQuickPayment' + '?type=' + this.PRODUCT_TYPE + '&mode=' + this.MODE,
            {
              method: 'POST',
              body: JSON.stringify(payment_json),
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN,
              },
            })
            .then((response) => response.json())
            .then(async (result) => {
              if(result?.errors?.length) {
                this.loading = false;
                // alert(res?.message);
                 Swal.fire({
                   icon: 'warning',
                   title: 'WARNING',
                   text: result?.message,
                   timer: 5000,
           
                 });
               }else {
                this.consignment_with_id = result;
                // console.log(result);
                this.paymentdone = true;
                this.loading = false;
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Shipment Booked Successfully',
  
                  timer: 5000,
                });
                if(result?.data?.length){
                  this.gotoConsignmentView(result?.data[0]?.id, result?.data[0]?.consignmentNo);
                }
               }

            })
            .catch((error) => {
              this.loading = false;
              console.error('Error', error);
              // alert('fytytjgshxsagbjh');
              Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Warning',
                text:error.message,
                timer: 5000,
              });
            });
      }
    }
  }

  gotoConsignmentView(id: any, consignment_no: any): void {
    if (this.userDetails.customerType == 1 || this.userDetails.customerType == 2) {
      this.consignmentViewService.navigateToConsignment(id, '/consignmentTable');
    } else {
      this.viewConsignmentService.navigateToConsignment(id, '/consignment');
      // this.router.navigate(['/viewconsignment', consignment_no]);
    }
  }

  async getReceverPincodes() {
    this.pincodes = getPincodesById(this.receiver.city);
  }

  buttonactive = false;

  async saveReceiver() {
    if ((this.selectedAddress==null && this.receiver_with_id?.id == undefined)) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please select the shipper address',
        timer: 5000,
      });
    } else {
      const TOKEN = this.authService.getAuthToken();
      this.loadingSecound = true;
      const sender_rev = {
        receiverId: null,
      };

      // Validating receiver details
      if (!this.validateReceiverDetails()) {
        this.loadingSecound = false;
        return;
      }

      await fetch(API_URL + '/receiver', {
        method: 'POST',
        body: JSON.stringify(this.receiver),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
          .then((response) => response.json())
          .then((result) => {
            Swal.fire({
              icon: 'success',
              title: 'Receiver created',

              timer: 5000,
            });
            this.receiver_with_id = result.data[0];
            this.clicked = true;
            sender_rev.receiverId = this.receiver_with_id.id;
            if (sender_rev.receiverId != null || sender_rev.receiverId != undefined) {
              this.errorReceiver = true;
            }
          })
          .catch((error) => {
            console.error('Error', error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error,
              showConfirmButton: true,
            });
          });
      this.loadingSecound = false;
    }
  }

  async updateReceiver() {
    if ((this.selectedAddress==null && this.receiver_with_id?.id == undefined)) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please select the shipper address',
        timer: 5000,
      });
    } else {
      const TOKEN = this.authService.getAuthToken();
      this.loadingSecound = true;

      // Validating receiver details
      if (!this.validateReceiverDetails()) {
        this.loadingSecound = false;
        return;
      }

      await fetch(API_URL + '/receiver', {
        method: 'POST',
        body: JSON.stringify(this.receiver),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
          .then((response) => response.json())
          .then((result) => {
            Swal.fire({
              icon: 'success',
              title: 'Receiver updated',

              timer: 5000,
            });
            this.receiver_with_id = result.data[0];
            this.clicked = true;
          })
          .catch((error) => {
            console.error('Error', error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error,
              showConfirmButton: true,
            });
          });
      this.loadingSecound = false;
    }
  }

  validateReceiverDetails(): boolean {
    if (!this.receiver.firstName) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter First Name',
        timer: 5000,
      });
      return;
    } else if (this.receiver.firstName.length < 1 || this.receiver.firstName.length > 60) {
      Swal.fire({
        icon: 'error',
        title: 'First Name should be between 1 - 60 characters',
        timer: 5000,
      });
      return;
    } else if (!this.receiver.lastName) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter Last Name',
        timer: 5000,
      });
      return;
    } else if (this.receiver.lastName.length < 1 || this.receiver.lastName.length > 60) {
      Swal.fire({
        icon: 'error',
        title: 'Last Name should be between 1 - 60 characters',
        timer: 5000,
      });
      return;
    } else if (!this.receiver.email1) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter an Email',
        timer: 5000,
      });
      return;
    } else if (!this.emailregex.test(this.receiver.email1)) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter a Valid Email',
        timer: 5000,
      });
      return;
    } else if (!this.receiver.phone1) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter Phone No.',
        timer: 5000,
      });
      return;
    } else if (this.receiver.phone1.length < 10) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter a Valid Phone No.',
        timer: 5000,
      });
      return;
    } else if (!this.receiver.address) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter the Address',
        timer: 5000,
      });
      return;
    } else if (this.receiver.address.length < 2 || this.receiver.address.length > 255) {
      Swal.fire({
        icon: 'error',
        title: 'Address should be between 2 - 255 characters',
        timer: 5000,
      });
      return;
    }
    // else if (!this.receiver.city) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Please select City',
    //     timer: 5000
    //   });
    //   return;
    // }
    // else if (!this.receiver.zipCode) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Please Enter Zip code',
    //     timer: 5000
    //   });
    //   return;
    // }
    return true;
  }

  constructor(private http: HttpClient, private modalService: NgbModal, private _formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private consignmentTypeService: ConsignmentTypeService, private authService : AuthService, public dialog: MatDialog, private httpService: HttpService, private viewConsignmentService: ViewConsignmentService, private consignmentViewService: ConsignmentViewService) {
    this.authService.getAuthToken();
  }


  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };

  closeResult: string;

  open(con) {
    this.modalService.open(con, {
      size: 'lg',
    });
  }

  async finduser() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/representativeUser/' + this.consignment_details.senderIndividual,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'User Found',

            timer: 5000,
          });

          this.indiviudal_user = result.data[0];

          if (this.indiviudal_user.customerType == 1) {
            this.val = 2;
          } else {
            this.val = 1;
            this.consignment_details.senderId = this.indiviudal_user.orgCustomerId.id;
          }
          this.customer_shipper =  result.data[0].orgCustomerId;
          this.getAddressList(this.consignment_details.senderIndividual);
          this.consignment_details.senderIndividual = this.indiviudal_user.id;
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'User Not Found',

            timer: 5000,
          });
        });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  isInsuredConisgnment() {
    if (this.consignment_details.isInsured != true) {
      this.consignment_details.isInsured = true;
      this.consignment_details.insuranceCoverage = 0;
      this.consignment_details.paymentMode = 3;
      this.getTotalcost();
    } else {
      this.consignment_details.paymentMode = null;
      this.consignment_details.isInsured = false;
      this.getTotalcost();
    }
    // alert(this.consignment_details.isInsured)
  }

  getTotalcost() {
    const tax = 18;
    this.consignmentTax = Math.ceil((tax / 100) * this.consignment_details.shippingCost);
    this.consignment_details.totalShippingCost = Math.ceil(this.consignmentTax + this.consignment_details.shippingCost);


    if (this.consignment_details.isInsured == true) {
      const insuranceCharege = 0.25;
      this.consignment_details.insuranceCoverage = (insuranceCharege / 100) * this.consignment_details.totalConsignmentValue;
      if (this.consignment_details.insuranceCoverage < 200) {
        this.consignment_details.insuranceCoverage = 200;
      }
      this.consignment_details.totalShippingCost = Math.ceil(this.consignment_details.totalShippingCost + this.consignment_details.insuranceCoverage);
    }
  }


  getTime(time_string) {
    const ardate: any = new Date('01-Jan-1970 ' + time_string + ':00').getTime();
    const newdate = new Date();
    newdate.setTime(ardate);
    return (newdate);
  }

  formatDate(date) {
    const date2 = new Date(date);
    return (
      [
        date2.getFullYear(

        ),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date2.getHours()),
        this.padTo2Digits(date2.getMinutes()),
      ].join(':')
    );
  }

  formatTime(time) {
    const hrs = Math.floor(time / 60);
    let mins: any = time % 60;
    if (mins < 10) {
      mins = '0' + mins;
    }
    return (hrs + ':' + mins);
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  getparsetime(date, time) {
    const full_date: any = new Date(date);
    const time_parsed: any = new Date(time * 60 * 1000);
    //  alert(date+"d    "+time+ " in get paser dunc")
    //  full_date.setTime(time*60*1000);
    full_date.setHours(time_parsed.getHours());
    full_date.setMinutes(time_parsed.getMinutes());
    full_date.setMilliseconds(0);
    //  alert("return "+ full_date)

    return full_date;
  }

  async findbusiness() {
    const TOKEN = this.authService.getAuthToken();
    // await fetch(API_URL + "/customer/" + this.consignment_details.senderId,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Accept": "application/json",
    //       "Authorization": "Bearer " + TOKEN
    //     }
    //   })
    //   .then((response) => response.json())
    //   .then(async (result) => {
    //     Swal.fire({
    //       position: 'center',
    //       icon: 'success',
    //       title: 'User Found',
    //
    //       timer: 1500
    //     });

    //     this.customer_shipper = result.data[0];

    // this.customer_shipper= this.indiviudal_user.org_customer_id;
    this.consignment_details.senderId = this.customer_shipper.id;
    if (this.customer_shipper.id != null || this.customer_shipper.id != undefined) {
      this.errorShipper = true;
    }
    await fetch(API_URL + '/org_users/' + this.customer_shipper.id,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
            'X-Active': 'true',

          },
        })
        .then((response) => response.json())
        .then((result) => {
          this.representativeList = result.data;
          this.show = true;
        });
    // })
    // .catch((error) => {
    //   Swal.fire({
    //     position: 'center',
    //     icon: 'error',
    //     title: 'User Not Found',
    //
    //     timer: 1500
    //   });
    // });
  }

  async getIndividaulStates() {
    this.user_states = getStatesById(this.user_sel_country);
    this.user_sel_state = null;
    // this.indiviudal_user.city = null;
  }

  async getIndividaulCities() {
    this.user_cities = getCitiesById(this.user_sel_state);
    this.user_sel_pincodes = null;
    // this.indiviudal_user.city = null;
  }

  async getIndividualPincodes() {
    // this.user_pincodes = getPincodesById(this.indiviudal_user.city);
    // this.indiviudal_user.zip_code = null;
  }

  async getBusinessStates() {
    this.business_states = getStatesById(this.business_sel_country);
    this.business_sel_state = null;
    this.customer_shipper.city_1 = null;
  }

  async getBusinessCities() {
    this.business_cities = getCitiesById(this.business_sel_state);
    this.business_pincodes = null;
    this.customer_shipper.city_1 = null;
  }

  async getBusinessPincodes() {
    this.business_pincodes = getPincodesById(this.customer_shipper.city_1);
    this.customer_shipper.zip_code = null;
  }

  async getRecord() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/report/' + this.consignment_with_id.data[0].id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then(async (response) => {
          const filename = this.consignment_with_id.data[0].consignmentNo;
          const blob = response.blob();
          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(await blob);
          a.click();
        },
        );
  }

  getTranisitTime(time) {
    const hrs = Math.floor(time / 60);
    let mins: any = time % 60;
    mins = Math.round(mins);
    if (mins < 10) {
      mins = '0' + mins;
    }
    return (hrs + ' Hrs ' + mins + ' Mins');
  }

  async selectRep(id) {
    const TOKEN = this.authService.getAuthToken();
    this.consignment_details.senderIndividual = id;
    this.errorRepresentative = true;
    // console.log("this is selected" + id);
    await fetch(API_URL + '/representativeUser/' + this.consignment_details.senderIndividual,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          if (this.userDetails.userTypes[0].name != 'Customer') {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Representative selected',

              timer: 5000,
            });
          }

          this.indiviudal_user = result.data[0];
          this.getAddressList(id);

          this.customer_shipper = result.data[0].orgCustomerId;
          this.consignment_details.senderId = this.indiviudal_user.orgCustomerId.id;
        })
        .catch((error) => {
        // Swal.fire({
        //   position: 'center',
        //   icon: 'error',
        //   title: 'User Not Found',
        //
        //   timer: 5000
        // });
        });
  }


  async createRep() {
    const TOKEN = this.authService.getAuthToken();
    this.indiviudal_user.userName = this.indiviudal_user.email;
    this.indiviudal_user.orgCustomerId = this.consignment_details.senderId;
    if (this.indiviudal_user.firstName == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s First Name ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.lastName == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Last Name ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.email == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s email ',
        timer: 5000,

      });
    } else if (!(this.emailregex.test(this.indiviudal_user.email))) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',

        text: 'Please Enter Valid Email',
        timer: 5000,

      });
    } else if (this.indiviudal_user.phone == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Phone Number ',
        timer: 5000,

      });
    } else if (isNaN(parseInt(this.indiviudal_user.phone)) == true || this.indiviudal_user.phone.length < 10) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Valid User\'s Phone Number',
        timer: 5000,

      });
    } else if (this.indiviudal_user.billingAddress?.address == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Address ',
        timer: 5000,

      });
    } else if (this.user_sel_city == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select The User\'s City ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.billingAddress?.pincode?.pin_number == '' || this.indiviudal_user.billingAddress?.pincode?.pin_number == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Zip Code ',
        timer: 5000,

      });
    } else {
      this.Representativeload = true;
      await fetch(API_URL + '/register',
          {
            method: 'POST',
            body: JSON.stringify(this.indiviudal_user),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + TOKEN,
            },
          })
          .then((response) => response.json())
          .then((result) => {
            this.representative_created = true;
            Swal.fire({
              icon: 'success',
              title: 'Sender created',

              timer: 5000,
            });
            this.Representativeload = false;
            this.errorRepresentative = true;
            this.indiviudal_user = result.data[0];
            this.consignment_details.senderIndividual = this.indiviudal_user.id;
            this.customer_created = true;
            this.findbusiness();
          // this.resetform();
          // console.log(result);
          });
    }
  }

  async emailAppuser() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/appuser/email/' + this.indiviudal_user.email,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => response.json())
        .then((result) => {
        // console.log("User Exist");
          if (result.data[0] == null) {
            this.emailExist = false;
          } else {
            this.emailExist = true;
          }
        // alert("CustomerExist "+this.emailExist);
        })
        .catch((error) => {
          console.error('error', error);
        });
  }

  async phoneNoExistAppuser() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/appuser/phone/' + this.indiviudal_user.phone,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => response.json())
        .then((result) => {
        // console.log("User Exist");
          if (result.data[0] == null) {
            this.phoneNoExist = false;
          } else {
            this.phoneNoExist = true;
          }
        // alert("CustomerExist "+this.phoneNoExist);
        })
        .catch((error) => {
          console.error('error', error);
        });
  }

  async phoneNoExistAppusers() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/appuser/phone/' + this.indiviudal_user.phone,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => response.json())
        .then((result) => {
        // console.log("User Exist");
          if (result.data[0] == null) {
            this.phoneNoExist = false;
          } else {
            this.phoneNoExist = true;
          }
        // alert("CustomerExist "+this.phoneNoExist);
        })
        .catch((error) => {
          console.error('error', error);
        });
  }

  async nameExist() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/customer/name/' + this.customer_shipper.firstName,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
        // console.log("User Exist");
          if (result.data.length == 0) {
            this.CustomerExist = false;
          } else {
            this.CustomerExist = true;
          }
        // alert("CustomerExist"+this.CustomerExist);
        })
        .catch((error) => {
          console.error('error', error);
        });
  }

  displayFns(user: CustomerList): String {
    return user && user.firstName ? user.firstName : '';
  }

  private _filters(name: String): CustomerList[] {
    const filterValue = name.toLowerCase();
    return this.customerList.filter((option) => option.firstName.toLowerCase().includes(filterValue));
  }

  async getCustomerList() {
    const TOKEN = this.authService.getAuthToken();
    let businessList: CustomerList[];
    await fetch(API_URL + '/totalcustomers',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then(async (result) => {
          businessList = result;
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'User Not Found',

            timer: 5000,
          });
        });
    return businessList;
  }

  async getAddressList(id) {
    const TOKEN = this.authService.getAuthToken();
    let addressList;
    if (id != null) {
      await fetch(API_URL + '/address/' + id,
          {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + TOKEN,
            },
          })
          .then((response) => response.json())
          .then(async (result) => {
            addressList = result.data;
          })
          .catch((error) => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'User Not Found',

              timer: 5000,
            });
          });
    }
    this.reprsentativeAddress = addressList;
    // this.findexisitng();
  }

  findExisting() {
    let exist;
    for (let i = 0; i < this.reprsentativeAddress?.length; i++) {
      const cityVal = this.reprsentativeAddress[i].pincodeId?.cityId?.id;
      if (cityVal === this.consignment_details.originLocation?.city?.id) {
        return true;
        break;
      }
    }
    return false;
  }

  selectAddress(id) {
    this.selectedAddress = id;
    if (this.selectAddress != null || this.selectAddress != undefined) {
      this.errorAddress = true;
    }
  }

  BestRouteFilters = {
    sort: 'LTransit',
    gap: '60',
    air: '0',
    FlightType: '1',
  }

  time1() {
    this.timegap = this.BestRouteFilters.gap;
    this.timegap = this.timegap * 60;
    this.array = [];
    this.planList?.bestRoutes?.forEach((element) => {
      if (element.totalTranistTime < this.timegap) {
        this.array.push(element);
      }
    });
    if (this.c == 2) {
      this.array = this.array.sort((low, high) => low.totalCostIn - high.totalCostIn);
    } else if (this.c == 3) {
      this.array = this.array.sort((low, high) => high.totalCostIn - low.totalCostIn);
    } else if (this.c == 1) {
      this.array = this.array.sort((low, high) => low.totalTranistTime - high.totalTranistTime);
    } else if (this.c == 4) {
      this.array = this.array.sort((low, high) => high.totalTranistTime - low.totalTranistTime);
    }
    this.array;
  }

  time2array = []

  time2() {
    this.timegap = this.BestRouteFilters.gap;
    this.timegap = this.timegap * 60;
    this.array = [];
    this.time2array = [];
    if (this.BestRouteFilters.FlightType == '2') {
      this.planList?.bestRoutes?.forEach((element) => {
        if (element.routes.length == 1) {
          this.time2array.push(element);
        }
      });
    } else if (this.BestRouteFilters.FlightType == '3') {
      this.planList?.bestRoutes?.forEach((element) => {
        if (element.routes.length == 2) {
          this.time2array.push(element);
        }
      });
    }
    this.time2array.forEach((element) => {
      if (element.totalTranistTime < this.timegap) {
        this.array.push(element);
      }
    });
    if (this.c == 2) {
      this.array = this.array.sort((low, high) => low.totalCostIn - high.totalCostIn);
    } else if (this.c == 3) {
      this.array = this.array.sort((low, high) => high.totalCostIn - low.totalCostIn);
    } else if (this.c == 1) {
      this.array = this.array.sort((low, high) => low.totalTranistTime - high.totalTranistTime);
    } else if (this.c == 4) {
      this.array = this.array.sort((low, high) => high.totalTranistTime - low.totalTranistTime);
    }
    this.array;
  }

  sort1() {
    this.sorting = this.BestRouteFilters.sort;
    if (this.sorting == 'Low') {
      this.c = 2;
      this.array = this.array.sort((low, high) => low.totalCostIn - high.totalCostIn);
      this.sortRecommendedOnTop();
    } else if (this.sorting == 'High') {
      this.c = 3;
      this.array = this.array.sort((low, high) => high.totalCostIn - low.totalCostIn);
      this.sortRecommendedOnTop();
    } else if (this.sorting == 'LTransit') {
      this.c = 1;
      this.array = this.array.sort((low, high) => low.totalTranistTime - high.totalTranistTime);
      this.sortRecommendedOnTop();
    } else if (this.sorting == 'HTransit') {
      this.c = 4;
      this.array = this.array.sort((low, high) => high.totalTranistTime - low.totalTranistTime);
      this.sortRecommendedOnTop();
    }
    this.array;
  }

  allflightsactivated = 0;
  // directflightsactivated=0;
  // indirectflightsactivated=0;
  find() {
    if (this.BestRouteFilters.air == '0') {
      if (this.BestRouteFilters.FlightType == '1') {
        // this.BestRouteFilters.air = 0
        this.allflightsactivated = 1;
      } else if (this.BestRouteFilters.FlightType != '1' && this.BestRouteFilters.air == '0') {
        if (this.BestRouteFilters.FlightType == '2') {
          this.time2();
          this.sort1();
          this.allflightsactivated = 0;
        }
        if (this.BestRouteFilters.FlightType == '3') {
          this.time2();
          this.sort1();
          this.allflightsactivated = 0;
        }
      }
      if (this.allflightsactivated == 1) {
        this.time1();
        this.sort1();
      }
    } else {
      if (this.BestRouteFilters.sort != null && this.BestRouteFilters.gap == null && this.BestRouteFilters.air == null && this.BestRouteFilters.FlightType == null) {
        this.sort();
      } else if (this.BestRouteFilters.sort == null && this.BestRouteFilters.gap != null && this.BestRouteFilters.air == null && this.BestRouteFilters.FlightType == null) {
        this.time();
      } else if (this.BestRouteFilters.sort == null && this.BestRouteFilters.gap == null && this.BestRouteFilters.air != null && this.BestRouteFilters.FlightType == null) {
        this.sortByAirlines();
      } else if (this.BestRouteFilters.sort != null && this.BestRouteFilters.gap != null && this.BestRouteFilters.air == null && this.BestRouteFilters.FlightType == null) {
        this.time();
        this.sort();
      } else if (this.BestRouteFilters.sort != null && this.BestRouteFilters.gap == null && this.BestRouteFilters.air != null && this.BestRouteFilters.FlightType == null) {
        this.sortByAirlines();
        this.sort();
      } else if (this.BestRouteFilters.sort != null && this.BestRouteFilters.gap != null && this.BestRouteFilters.air != null && this.BestRouteFilters.FlightType == null) {
        this.sortByAirlines();
        this.time();
        this.sort();
      } else if (this.BestRouteFilters.sort == null && this.BestRouteFilters.gap == null && this.BestRouteFilters.air == null && this.BestRouteFilters.FlightType != null) {
        this.FlightsType();
      } else if (this.BestRouteFilters.sort != null && this.BestRouteFilters.gap == null && this.BestRouteFilters.air == null && this.BestRouteFilters.FlightType != null) {
        this.FlightsType();
        this.sort();
      } else if (this.BestRouteFilters.sort == null && this.BestRouteFilters.gap != null && this.BestRouteFilters.air == null && this.BestRouteFilters.FlightType != null) {
        this.FlightsType();
        this.time();
      } else if (this.BestRouteFilters.sort == null && this.BestRouteFilters.gap == null && this.BestRouteFilters.air != null && this.BestRouteFilters.FlightType != null) {
        this.FlightsType();
        this.sortByAirlines();
      } else if (this.BestRouteFilters.sort != null && this.BestRouteFilters.gap == null && this.BestRouteFilters.air != null && this.BestRouteFilters.FlightType != null) {
        this.FlightsType();
        this.sortByAirlines();
        this.sort();
      } else if (this.BestRouteFilters.sort == null && this.BestRouteFilters.gap != null && this.BestRouteFilters.air != null && this.BestRouteFilters.FlightType != null) {
        this.FlightsType();
        this.sortByAirlines();
        this.time();
      } else if (this.BestRouteFilters.sort != null && this.BestRouteFilters.gap != null && this.BestRouteFilters.air == null && this.BestRouteFilters.FlightType != null) {
        this.FlightsType();
        this.time();
        this.sort();
      } else if (this.BestRouteFilters.sort == null && this.BestRouteFilters.gap == null && this.BestRouteFilters.air == null && this.BestRouteFilters.FlightType == null) {
        this.array = this.planList?.bestRoutes;
      } else {
        this.FlightsType();
        this.sortByAirlines();
        this.time();
        this.sort();
      }
    }
  }

  RoutesArray = []
  routeselected

  FlightsType() {
    if (this.BestRouteFilters.FlightType == '1') {
      // all flights
      this.routeselected = 1;
      this.array = [];
      this.RoutesArray = [];
      this.RoutesArray = this.planList?.bestRoutes;
      this.array = this.RoutesArray;
    } else if (this.BestRouteFilters.FlightType == '2') {
      // Dirct Flights
      this.routeselected = 2;
      this.array = [];
      this.RoutesArray = [];
      this.planList?.bestRoutes?.forEach((element) => {
        if (element.routes.length == 1) {
          this.RoutesArray.push(element);
        }
      });
      // document.getElementById("AllFlights").style.backgroundColor =  "rgb(255, 255, 255)";
      // document.getElementById("DirectFlights").style.backgroundColor = "rgb(140, 223, 125)";
      // document.getElementById("InDirectFlights").style.backgroundColor =  "rgb(255, 255, 255)";
      // this.BestRouteFilters.air=null
      // this.BestRouteFilters.gap=null
      // this.BestRouteFilters.sort=null
      this.array = this.RoutesArray;
    } else if (this.BestRouteFilters.FlightType == '3') {
      // IndirectFLights
      this.routeselected = 3;
      this.array = [];
      this.RoutesArray = [];
      this.planList?.bestRoutes?.forEach((element) => {
        // element.routes.forEach(route => {
        //   this.routecount=this.routecount+1
        // });
        if (element.routes.length == 2) {
          this.RoutesArray.push(element);
          // this.routecount = 0
        }
      });
      // document.getElementById("AllFlights").style.backgroundColor =  "rgb(255, 255, 255)";
      // document.getElementById("DirectFlights").style.backgroundColor = "rgb(255, 255, 255)";
      // document.getElementById("InDirectFlights").style.backgroundColor = "rgb(140, 223, 125)";
      // this.BestRouteFilters.air=null
      // this.BestRouteFilters.gap=null
      // this.BestRouteFilters.sort=null
      this.array = this.RoutesArray;
    }
    this.array;
  }

  array = []
  sorting
  airlines
  timegap
  c = 0

  sort() {
    this.sorting = this.BestRouteFilters.sort;
    if (this.sorting == 'Low') {
      this.c = 2;
      this.array = this.array.sort((low, high) => low.totalCostIn - high.totalCostIn);
      this.sortRecommendedOnTop();
    } else if (this.sorting == 'High') {
      this.c = 3;
      this.array = this.array.sort((low, high) => high.totalCostIn - low.totalCostIn);
      this.sortRecommendedOnTop();
    } else if (this.sorting == 'LTransit') {
      this.c = 1;
      this.array = this.array.sort((low, high) => low.totalTranistTime - high.totalTranistTime);
      this.sortRecommendedOnTop();
    } else if (this.sorting == 'HTransit') {
      this.c = 4;
      this.array = this.array.sort((low, high) => high.totalTranistTime - low.totalTranistTime);
      this.sortRecommendedOnTop();
    }
    this.array;
  }

  sortRecommendedOnTop() {
    this.array.sort((high, low) => {
      if (low.recommended == true && high.recommended == true) return 0;
      else if (low.recommended == true) return 1;
      else if (high.recommended == true) return -1;
      else return 0;
    });
  }

  time() {
    this.FlightsType();
    this.timegap = this.BestRouteFilters.gap;
    this.timegap = this.timegap * 60;
    if (this.airlines == null) {
      this.array = [];
      if (this.RoutesArray.length == 0) {
        this.RoutesArray = this.planList?.bestRoutes;
      }
      this.RoutesArray.forEach((element) => {
        if (element.totalTranistTime < this.timegap) {
          this.array.push(element);
        }
      });
    } else if (this.airlines != null) {
      this.array = [];
      this.RoutesArray.forEach((element) => {
        if (element.totalTranistTime < this.timegap) {
          if (element.routes[0].airlineID.id == this.airlines) {
            this.array.push(element);
          }
        }
      });
    }
    if (this.c == 2) {
      this.array = this.array.sort((low, high) => low.totalCostIn - high.totalCostIn);
    } else if (this.c == 3) {
      this.array = this.array.sort((low, high) => high.totalCostIn - low.totalCostIn);
    } else if (this.c == 1) {
      this.array = this.array.sort((low, high) => low.totalTranistTime - high.totalTranistTime);
    } else if (this.c == 4) {
      this.array = this.array.sort((low, high) => high.totalTranistTime - low.totalTranistTime);
    }
    this.array;
  }

  sortByAirlines() {
    this.airlines = this.BestRouteFilters.air;
    if (this.timegap == null) {
      this.array = [];
      if (this.RoutesArray.length == 0) {
        this.RoutesArray = this.planList?.bestRoutes;
      }
      this.RoutesArray.forEach((element) => {
        if (element.routes[0].airlineID.id == this.airlines) {
          this.array.push(element);
        }
      });
    } else if (this.timegap != null) {
      this.array = [];
      this.RoutesArray.forEach((element) => {
        if (element.totalTranistTime < this.timegap) {
          if (element.routes[0].airlineID.id == this.airlines) {
            this.array.push(element);
          }
        }
      });
    }
    if (this.c == 2) {
      this.array = this.array.sort((low, high) => low.totalCostIn - high.totalCostIn);
    } else if (this.c == 3) {
      this.array = this.array.sort((low, high) => high.totalCostIn - low.totalCostIn);
    } else if (this.c == 1) {
      this.array = this.array.sort((low, high) => low.totalTranistTime - high.totalTranistTime);
    } else if (this.c == 4) {
      this.array = this.array.sort((low, high) => high.totalTranistTime - low.totalTranistTime);
    }
    this.array;
    this.array;
  }

  consignmentDetailsForm() {
    this.consignment_details = {
      id: null,
      consignmentType: null,
      weightUnit: 1,
      currency: '1',
      weightDivisor: 1,
      consignmentNo: null,
      airlinesId: null,
      isStopover: null,
      totalTransitTime: null,
      senderIndividual: null,
      isIndividualCustomer: null,
      paymentCompletedAt: null,
      isXrayScreening: null,
      isXrayCertification: null,
      isDryIce: null,
      totalWeight: null,
      totalChargableWeight: null,
      planSelected: null,
      senderId: null,
      receiverId: null,
      isDocumentUploaded: null,
      isInsured: false,
      totalShippingCost: null,
      paymentMode: null,
      isPaymentCompleted: null,
      isActive: null,
      createdAt: null,
      updatedAt: null,
      createdBy: null,
      updatedBy: null,
      totalNoOfPackages: null,
      destinationLocation: null,
      consignmentSubType: null,
      originLocation: null,
      dimensionuUnit: 1,
      insuranceCoverage: 0,
      totalConsignmentValue: null,
      shippingCost: 0,
      invoiceCreatedDate: '',
      invoiceReferenceNo: null,
      productType: 1,
      remarks: null,
      commodityType: null,
      // tentative_pickup_time: new Date()
    };
    this.receiver = {
      id: null,
      firstName: null,
      lastName: null,
      address: null,
      city: null,
      zipCode: null,
      phone1: null,
      email1: null,
    };

    this.packages = [
      {
        id: 1,
        length: null,
        breadth: null,
        height: null,
        weight: null,
        total_weight: null,
        packageNo: null,
        volume_weight: null,
        chargeable_weight: null,
        totalvolumeweight_per_pack: null,
      },
    ];
  }

  resetform() {
    this.indiviudal_user = {
      id: null,
      firstName: '',
      middleName: '',
      lastName: '',
      avatarUrl: '',
      email: '',
      emailForMasterGST: '',
      phone: '',
      whatsapp: '',
      designation: null,
      employmentNo: null,
      billingAddress: {
        pincode: {
          pin_number: ''
        },
        address: ''
      },
      isSuperUser: null,
      userName: '',
      passwordHash: null,
      lastLoggedInAt: null,
      lastLoggedInIp: null,
      userType: null,
      customerType: 2,
      orgCustomerId: {id: null},
      aadhaarNumber: null,
      drivingLicense: null,
      gstNumber: null,
      panNumber: null,
      gstDetails: null,
      panDetails: null,
      aadhaarDetails: null,
      isActive: true,
      createdAt: null,
      updatedAt: null,
      createdBy: null,
      updatedBy: null,
    };
    this.user_sel_country = null;
    this.user_sel_state = null;
    this.errorRepresentative = false;
    // this.change_business()
  }

  createnewformreset() {
    this.indiviudal_user = {
      id: null,
      firstName: '',
      middleName: '',
      lastName: '',
      avatarUrl: '',
      email: '',
      emailForMasterGST: '',
      phone: '',
      whatsapp: '',
      designation: null,
      employmentNo: null,
      billingAddress: {
        pincode: {
          pin_number: ''
        },
        address: ''
      },
      isSuperUser: null,
      userName: '',
      passwordHash: null,
      lastLoggedInAt: null,
      lastLoggedInIp: null,
      userType: null,
      customerType: 2,
      orgCustomerId: {id: null},
      aadhaarNumber: null,
      drivingLicense: null,
      gstNumber: null,
      panNumber: null,
      gstDetails: null,
      panDetails: null,
      aadhaarDetails: null,
      isActive: true,
      createdAt: null,
      updatedAt: null,
      createdBy: null,
      updatedBy: null,
    };
    this.customer_shipper = {
      id: null,
      firstName: '',
      address_1: '',
      city_1: null,
      creditPeriod: null,
      credit_value: null,
      email_1: '',
      zip_code: '',
      isCreditExtended: null,
      isKycApproved: null,
      kycApprovedAt: null,
      office_location: null,
      lastName: '',
      phone_1: '',
      customerTypeId: 2,
      kycApprovedBy: null,
      isBusinessAccount: true,
      pan: '',
      gst: '',
      Id_type: null,
      Id_value: null,
      otp_varified: null,
      isSezCustomer: false,
    };
    this.business_sel_country = null;
    this.business_sel_state = null;
    this.user_sel_country = null;
    this.user_sel_state = null;
    this.errorShipper = false;
    this.change_business();
  }

  resetroutes() {
    this.BestRouteFilters.sort = 'LTransit',
    this.BestRouteFilters.gap = '36',
    this.BestRouteFilters.air = '0',
    this.BestRouteFilters.FlightType = '1';
    this.find();
  }

  refresh = false;

  individualrefresh() {
    this.refresh = true;
  }

  corporefresh() {
    this.refresh = false;
  }


  myFunction() {
    // this.stepper.reset()
    this.createnewformreset();
    this.resetform();
    this.consignmentDetailsForm();
    this.reprsentativeAddress = null;
    this.requiredDocument = [];
    this.array = [];
    this.isplans = false;
    this.ngOnInit();
    this.consignmentTax = 0;
    this.totalvolume_weight = 0;
  }

  OfficesList

  async getOfficeLocations() {
    try {
      const TOKEN = this.authService.getAuthToken();
      const response = await fetch(API_URL + '/office_locations', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      });
      if (response.ok) {
        const json = await response.json();
        this.OfficesList = json.data;
      } else {
      }
    } catch (error) {
      console.error('Error fetching office locations:', error);
    }
  }

  clearData() {
    this.customer_shipper = {
      id: null,
      firstName: '',
      address_1: '',
      city_1: null,
      creditPeriod: null,
      credit_value: null,
      email_1: '',
      zip_code: '',
      isCreditExtended: null,
      isKycApproved: null,
      kycApprovedAt: null,
      office_location: null,
      lastName: '',
      phone_1: '',
      customerTypeId: 2,
      kycApprovedBy: null,
      isBusinessAccount: true,
      pan: '',
      gst: '',
      Id_type: null,
      Id_value: null,
      otp_varified: null,
      isSezCustomer: false,
    };
  }

  validateInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^(\d*\.?\d*)$/.test(inputChar)) {
      event.preventDefault();
    }
  }
  validateInput1(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^\d*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(CreateNewCustomerComponent, {
      data: {val: this.val},
    });
  }
  gotoCreateNewCustomerPage() {
    this.openDialog();
  }

  openDialogForRepresentative() {
    const dialogRef = this.dialog.open(CreateNewRepresentativeComponent, {
      data: {customer: this.consignment_details.senderId},
    });
  }

  gotoCreateNewRepresentativePage() {
    this.openDialogForRepresentative();
  }

  commodityTypes: any[] = [];
  async getCommodityTypes() {
    const TOKEN = this.authService.getAuthToken();
    try {
      const response: any = await this.httpService.get(API_URL + '/commodityType', null, null).toPromise();
      return response.data;
    } catch (error) {
      console.error('Error fetching commodity types:', error);
      return [];
    }
  }
}

