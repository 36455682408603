import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpService} from '../../../services/http.service';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {API_URL} from '../../../../../Global_API_URL';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Airport} from '../../golbalInterfaces';
import {map} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import moment from 'moment-timezone';
import {Location} from '@angular/common';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';
@Component({
  selector: 'app-create-airline-promotion',
  templateUrl: './create-airline-promotion.component.html',
  styleUrls: ['./create-airline-promotion.component.sass'],
})
export class CreateAirlinePromotionComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public isLoading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  constructor(private httpService: HttpService, private router: Router, private locationService: Location) { }

  myControl = new FormControl();
  myControl2 = new FormControl();

  formGroup: FormGroup | undefined;
  originAirports;
  destinationAirports;
  selectedOption = 'ONLY'
  startTimeEndTime: Boolean = false;
  startTime = '09:00';
  endTime = '23:59';
  failedRows: Set<number> = new Set<number>();

  // myControl = new FormControl();
  // myControl2 = new FormControl();
  // filteredAirports: Observable<Airport[]>;
  // filteredAirports2: Observable<Airport[]>;
  // filteredAirports;
  // filteredAirports2;
  // airports = this.getAirports().subscribe();
  // airports2 = this.getAirports().subscribe();
  // airports;
  // airports2;
  ngOnInit(): void {
    this.formGroup = new FormGroup({
      selectedCity: new FormControl(null),
    });

    this.httpService.get(API_URL + '/airline', null, null)
        .subscribe((response) => {
          this.airlines = response.data;
        });

    this.getAllProductTypes();

    this.getAirports().subscribe((response) => {
      this.originAirports = response;
      this.destinationAirports = response;
      // this.originAirports = this.myControl.valueChanges.pipe(
      //   startWith(''),
      //   map(value => (typeof value === 'string' ? value : value.name)),
      //   map(name => (name ? this._filter(name) : this.originAirports.slice())),
      // );
      // this.destinationAirports = this.myControl2.valueChanges.pipe(
      //   startWith(''),
      //   map(value => (typeof value === 'string' ? value : value.name)),
      //   map(name => (name ? this._filter2(name) : this.destinationAirports.slice())),
      // );
    });
  }

  airlines;
  selectedAirline;
  flightOptions = ['All', 'Custom']
  selectedFlightOption;
  flightsListInput = [];
  selectedStartDate: Date;
  selectedEndDate: Date;

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;


  goBackToAirlinePricingList() {
    this.locationService.back();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim().toUpperCase();

    // Add our fruit
    if (value && !this.isDuplicateFlight(value)) {
      this.flightsListInput.push({name: value});
    } else if (this.isDuplicateFlight(value)) {
      Swal.fire({
        icon: 'warning',
        title: 'Flight No. is Already Given',
        text: 'Duplicate flight numbers not allowed.',
        timer: 3000,
      });
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  isDuplicateFlight(flightNumber: string): boolean {
    return this.flightsListInput.some((flight) => flight.name === flightNumber);
  }

  remove(flight): void {
    const index = this.flightsListInput.indexOf(flight);

    if (index >= 0) {
      this.flightsListInput.splice(index, 1);
    }
  }


  debounceFlag: boolean = false;
  selectedRows = [{
    productType: null,
    consignmentType: null,
    consignmentSubtype: null,
    type: null,
    origin: null,
    destination: null,
    airlineTarrifNRate: 0,
    airlineTarrifAbove45: 0,
    airlineTarrifAbove100: 0,
    airlineTarrifAbove300: 0,
    airlineTarrifAbove500: 0,
    airlineTarrifAbove1000: 0,
    recommended: false,
  }]

  selectedCNTypesSubtype = [{
    cnTypes: null,
    cnSubtypes: null,
  }]

  count = 1;
  addDropDown() {
    this.selectedRows.push({
      productType: null,
      consignmentType: null,
      consignmentSubtype: null,
      type: null,
      origin: null,
      destination: null,
      airlineTarrifNRate: 0,
      airlineTarrifAbove45: 0,
      airlineTarrifAbove100: 0,
      airlineTarrifAbove300: 0,
      airlineTarrifAbove500: 0,
      airlineTarrifAbove1000: 0,
      recommended: null,
    });
    this.selectedCNTypesSubtype.push({
      cnTypes: null,
      cnSubtypes: null,
    });
    this.count += 1;
  }

  isDuplicateRow(newRow, existingRow = null) {
    if (existingRow) {
      return newRow.productType === existingRow.productType &&
        newRow.consignmentType === existingRow.consignmentType &&
        newRow.consignmentSubtype === existingRow.consignmentSubtype &&
        // newRow.type === existingRow.type &&
        newRow.origin === existingRow.origin &&
        newRow.destination === existingRow.destination;
    } else {
      return this.selectedRows.some((existingRow) =>
        newRow.productType === existingRow.productType &&
        newRow.consignmentType === existingRow.consignmentType &&
        newRow.consignmentSubtype === existingRow.consignmentSubtype &&
        // newRow.type === existingRow.type &&
        newRow.origin === existingRow.origin &&
        newRow.destination === existingRow.destination,
      );
    }
  }

  removeRow(index: number) {
    this.selectedRows.splice(index, 1);
    this.count -= 1;
  }

  productTypes;

  getAllProductTypes() {
    this.httpService.get(API_URL + '/getOrderTypes', null, null)
        .subscribe((response) => {
          this.productTypes = response.data;
        });
  }

  setCNTypes(i, data) {
    this.selectedCNTypesSubtype[i].cnTypes = data;
  }

  setAllCNSubTypes(i, data) {
    this.selectedCNTypesSubtype[i].cnSubtypes = data;
  }

  onProductTypeChange(index: number) {
    const selectedProductType = this.selectedRows[index].productType;
    if (selectedProductType === 'null') {
      // Set consignment type and subtype to "ALL"
      this.selectedRows[index].consignmentType = 'null';
      this.selectedRows[index].consignmentSubtype = 'null';
    }
    this.selectedCNTypesSubtype[index].cnTypes = null;
    this.selectedCNTypesSubtype[index].cnSubtypes = null;
  }

  onCNTypeChange(index: number) {
    const selectedCNType = this.selectedRows[index].consignmentType;
    if (selectedCNType === 'null') {
      // Set consignment subtype to "ALL"
      this.selectedRows[index].consignmentSubtype = 'null';
    }
  }

  getAirports() {
    let airports = [];
    let array = [];
    return this.httpService.get(API_URL + '/airport', null, null)
        .pipe(map((response) => {
          array = Array.from(response.data);
          array.sort((a, b) => {
            if (a.city.name < b.city.name) {
              return -1;
            }
            if (a.city.name > b.city.name) {
              return 1;
            }
            return 0;
          });
          response.data = [];
          response.data = array;
          airports = response.data;
          return airports;
        }));
  }

  airlinePromotionRequestBody = {
    airlineTarrifNRate: 0,
    airlineTarrifAbove45: 0,
    airlineTarrifAbove100: 0,
    airlineTarrifAbove300: 0,
    airlineTarrifAbove500: 0,
    airlineTarrifAbove1000: 0,
    startDate: ' ',
    endDate: ' ',
    recommended: '',
  };

  onRecommendedChange(index: number): void {
    const row = this.selectedRows[index];
    // console.log(`Checkbox change - Row ${index} recommended status: `, row.recommended);
  }

  createAirlinePromotion() {

  }


  // displayFn(airport: Airport): String {
  //   return airport && airport.city?.name ? airport.city?.name : '';
  // }
  //
  // private _filter(name: String): Airport[] {
  //   const filterValue = name.toLowerCase();
  //
  //   return this.airports.filter(option => option.city.name.toLowerCase().includes(filterValue));
  // }
  // private _filter2(name: String): Airport[] {
  //   const filterValue = name.toLowerCase();
  //
  //   return this.airports2.filter(option => option.city.name.toLowerCase().includes(filterValue));
  // }

  backToAirlinePromotion() {

  }

  saveAirlinePromotion() {
    // console.log(this.selectedStartDate.se)
    // console.log(moment(this.selectedEndDate).utc(true).toISOString());
    // console.log(this.selectedEndDate.toISOString());
    moment(this.selectedStartDate).utc().toISOString();

    let flag = 1;

    if (this.startTimeEndTime) {
      var [startHour, startMin] = this.startTime.split(':');
      var [endHour, endMin] = this.endTime.split(':');
    }

    let frequency = '';
    this.selectedDays.forEach((char) => {
      frequency = frequency + char;
    });

    if (frequency === '') {
      frequency = null;
    }

    let flights: string;
    if (this.selectedFlightOption === 'Custom') {
      flights = this.selectedOption + ' ';
      this.flightsListInput.forEach((flight, index) => {
        if (index < this.flightsListInput.length - 1) {
          flights = flights + flight.name + ', ';
        } else {
          flights = flights + flight.name;
        }
      });
    } else {
      flights = 'All';
    }

    const request = {
      airline: {
        id: this.selectedAirline,
      },
      endDate: moment(this.selectedEndDate).utc(false).toISOString(),
      flights,
      startDate: moment(this.selectedStartDate).utc(false).toISOString(),
      frequency,
      startTime: parseInt(startHour) * 60 + parseInt(startMin),
      endTime: parseInt(endHour) * 60 + parseInt(endMin),
      airlinePromotionList: [],
    };

    if (this.selectedAirline == null) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please Select An Airline.',
        timer: 5000,
      });
      flag = 0;
      return;
    }

    if (this.selectedFlightOption == null) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please Select Flight Option.',
        timer: 5000,
      });
      flag = 0;
      return;
    }
    if (this.selectedStartDate == null) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please Select Valid From Date.',
        timer: 5000,
      });
      flag = 0;
      return;
    }
    if (this.selectedEndDate == null) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please Select Valid Upto Date.',
        timer: 5000,
      });
      flag = 0;
      return;
    }
    if (moment(this.selectedEndDate).isBefore(moment(this.selectedStartDate))) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Valid upto date should be same or after Valid from date.',
        timer: 5000,
      });
      flag = 0;
      return;
    }

    const invalidRows = this.selectedRows.map((row, index) => ({row, index}))
        .filter(({row}) =>
          !row.productType || !row.consignmentType || !row.consignmentSubtype || !row.type ||
        !row.origin || !row.destination,
        );

    if (invalidRows.length > 0) {
      const missingFieldMessages = [];
      invalidRows.forEach(({row, index}) => {
        if (!row.productType) {
          missingFieldMessages.push({row: index + 1, field: 'Product Type'});
        } else if (!row.consignmentType) {
          missingFieldMessages.push({row: index + 1, field: 'Consignment Type'});
        } else if (!row.consignmentSubtype) {
          missingFieldMessages.push({row: index + 1, field: 'Consignment Subtype'});
        } else if (!row.type) {
          missingFieldMessages.push({row: index + 1, field: 'Type'});
        } else if (!row.origin) {
          missingFieldMessages.push({row: index + 1, field: 'Origin'});
        } else if (!row.destination) {
          missingFieldMessages.push({row: index + 1, field: 'Destination'});
        }
      });

      if (missingFieldMessages.length > 0) {
        const firstMissingField = missingFieldMessages[0];
        Swal.fire({
          icon: 'warning',
          title: 'Alert',
          text: `Row ${firstMissingField.row}: ${firstMissingField.field} is not selected.`,
        });
      }

      return;
    }

    for (let i = 0; i < this.selectedRows.length; i++) {
      for (let j = i + 1; j < this.selectedRows.length; j++) {
        if (this.isDuplicateRow(this.selectedRows[i], this.selectedRows[j])) {
          Swal.fire({
            icon: 'warning',
            title: 'Duplicate Row Detected',
            text: `Row ${i + 1} and Row ${j + 1} are duplicates.`,
          });
          return;
        }
      }
    }

    this.selectedRows.forEach((row, index) => {
      if (row.type == null) {
        Swal.fire({
          icon: 'error',
          title: 'Please select type for row: ' + (index + 1),
          timer: 5000,
        });
        flag = 0;
        return;
      }
      request.airlinePromotionList.push({
        row: index,
        airlineTarrifNRate: row.airlineTarrifNRate,
        airlineTarrifAbove45: row.airlineTarrifAbove45,
        airlineTarrifAbove100: row.airlineTarrifAbove100,
        airlineTarrifAbove300: row.airlineTarrifAbove300,
        airlineTarrifAbove500: row.airlineTarrifAbove500,
        airlineTarrifAbove1000: row.airlineTarrifAbove1000,
        recommended: row.recommended,
        type: row.type,
        consignmentSubtype: {
          id: row.consignmentSubtype.id,
        },
        consignmentType: {
          id: row.consignmentType.id,
        },
        productType: {
          id: row.productType.id,
        },
        origin: {
          id: row.origin.id,
        },
        destination: {
          id: row.destination.id,
        },
      });
      index++;
    });


    if (flag === 1) {
      this.isLoading = true;
      this.httpService.post(API_URL + '/airline-promotions', request, null, null)
          .subscribe((response) => {
            Swal.fire({
              title: 'Created',
              text: 'Airline Promotions Created Successfully',
              icon: 'success',
            }).then(() => this.goBackToAirlinePricingList());
            this.isLoading = false;
          },
          // (error) => {
          //   // console.log(error)
          //   this.failedRows = new Set<number>(error.error.rows);
          //   this.isLoading = false;
          //   // console.log(error.error.rows)
          // });
          (error) => {
            if (error.status === 400) {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Promotion already exists.`,
              });
            }
            this.failedRows = new Set<number>(error.error.rows);
            this.isLoading = false;
          },
          );
    }
  }

  private _filter(name: String): Airport[] {
    const filterValue = name.toLowerCase();

    return this.originAirports.filter((option) => option.city.name.toLowerCase().includes(filterValue));
  }

  private _filter2(name: string): Airport[] {
    const filterValue = name.toLowerCase();

    return this.destinationAirports.filter((option) => option.city.name.toLowerCase().includes(filterValue));
  }

  displayFn(airport: Airport): String {
    return airport && airport.city?.name ? airport.city?.name : '';
  }

  selectedDays: string[] = [];

  toggleEveryday(checked: boolean) {
    if (checked) {
      this.selectedDays = ['0', '1', '2', '3', '4', '5', '6']; // Setting other days
    } else {
      this.selectedDays = [];
    }
  }

  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const sanitizedValue = inputElement.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    inputElement.value = sanitizedValue;
  }

  // Method to update the end date with time set to 23:59:59
  updateEndDate(event: Date): void {
    if (event) {
      const endDate = new Date(event);
      endDate.setHours(23, 59, 59, 0); // Set the time to 23:59:59.999
      this.selectedEndDate = endDate;
    }
  }


  // Method to update the start date
  updateStartDate(event: Date): void {
    if (event) {
      const startDate = new Date(event);
      startDate.setHours(0, 0, 0, 0);
      this.selectedStartDate = startDate;
    }
  }

  isFailedRow(i: number) {
    if (this.failedRows.has(i)) {
      return '#ffb6a9';
    }

    // [ngStyle]="{'background-color': isFailedRow(i)}"
  }

  // airlineTarrifNRate
  clearairlineTarrifNRateValue(inputElement: HTMLInputElement, index: number): void {
    if (this.selectedRows[index].airlineTarrifNRate === 0) {
      this.selectedRows[index].airlineTarrifNRate = null;
      inputElement.value = '';
    }
  }

  restoreairlineTarrifNRateValue(inputElement: HTMLInputElement, index: number): void {
    if (!this.selectedRows[index].airlineTarrifNRate) {
      this.selectedRows[index].airlineTarrifNRate = 0;
      inputElement.value = '0';
    }
  }

  // airlineTarrifAbove45
  clearairlineTarrifAbove45Value(inputElement: HTMLInputElement, index: number): void {
    if (this.selectedRows[index].airlineTarrifAbove45 === 0) {
      this.selectedRows[index].airlineTarrifAbove45 = null;
      inputElement.value = '';
    }
  }

  restoreairlineTarrifAbove45Value(inputElement: HTMLInputElement, index: number): void {
    if (!this.selectedRows[index].airlineTarrifAbove45) {
      this.selectedRows[index].airlineTarrifAbove45 = 0;
      inputElement.value = '0';
    }
  }

  // airlineTarrifAbove100
  clearairlineTarrifAbove100Value(inputElement: HTMLInputElement, index: number): void {
    if (this.selectedRows[index].airlineTarrifAbove100 === 0) {
      this.selectedRows[index].airlineTarrifAbove100 = null;
      inputElement.value = '';
    }
  }

  restoreairlineTarrifAbove100Value(inputElement: HTMLInputElement, index: number): void {
    if (!this.selectedRows[index].airlineTarrifAbove100) {
      this.selectedRows[index].airlineTarrifAbove100 = 0;
      inputElement.value = '0';
    }
  }

  // airlineTarrifAbove300
  clearairlineTarrifAbove300Value(inputElement: HTMLInputElement, index: number): void {
    if (this.selectedRows[index].airlineTarrifAbove300 === 0) {
      this.selectedRows[index].airlineTarrifAbove300 = null;
      inputElement.value = '';
    }
  }

  restoreairlineTarrifAbove300Value(inputElement: HTMLInputElement, index: number): void {
    if (!this.selectedRows[index].airlineTarrifAbove300) {
      this.selectedRows[index].airlineTarrifAbove300 = 0;
      inputElement.value = '0';
    }
  }

  // airlineTarrifAbove500
  clearairlineTarrifAbove500Value(inputElement: HTMLInputElement, index: number): void {
    if (this.selectedRows[index].airlineTarrifAbove500 === 0) {
      this.selectedRows[index].airlineTarrifAbove500 = null;
      inputElement.value = '';
    }
  }

  restoreairlineTarrifAbove500Value(inputElement: HTMLInputElement, index: number): void {
    if (!this.selectedRows[index].airlineTarrifAbove500) {
      this.selectedRows[index].airlineTarrifAbove500 = 0;
      inputElement.value = '0';
    }
  }

  // airlineTarrifAbove1000
  clearairlineTarrifAbove1000Value(inputElement: HTMLInputElement, index: number): void {
    if (this.selectedRows[index].airlineTarrifAbove1000 === 0) {
      this.selectedRows[index].airlineTarrifAbove1000 = null;
      inputElement.value = '';
    }
  }

  restoreairlineTarrifAbove1000Value(inputElement: HTMLInputElement, index: number): void {
    if (!this.selectedRows[index].airlineTarrifAbove1000) {
      this.selectedRows[index].airlineTarrifAbove1000 = 0;
      inputElement.value = '0';
    }
  }
}
