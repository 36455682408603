<h3>{{Type}} Bookings</h3>

<!-- <app-mat-steps></app-mat-steps> -->
<div class="card">
  <!-- <mat-form-field appearance="legacy">
    <mat-label>Legacy form field</mat-label>
    <input matInput placeholder="Placeholder">
    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>Hint</mat-hint>
  </mat-form-field> -->
  <mat-horizontal-stepper [linear]="isLiner" #stepper="matHorizontalStepper">
    <mat-step label="Shipper">
      <div class="container ">
        <div class="row">
          <div *ngIf="notdisplaytocustomer" class="col-md-3">
            <mat-form-field appearance="standard">
              <mat-label>
                Select Shipper <span class="text-danger">*</span>
              </mat-label>
              <mat-select matNativeControl  (blur)="change_business()"
                          [(ngModel)]="val">
                <mat-option [value]="1">
                  Corporate User
                </mat-option>
                <mat-option [value]="2">
                  Retail User
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-5">
            <ng-template #content4 let-c="close" let-d="dismiss">
              <div class="modal-header">
                <h4 class="modal-title"><b>Find Shipper</b></h4>
                <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                </button>
              </div>
              <div class="modal-body">
                <div *ngIf="indiviudal_user.customerType == 2; else notbusinessuser">
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field class="example-full-width" appearance="standard"
                                      style="width: 400px;">
                        <mat-label>Corporate User Name<span
                          class="text-danger">*</span></mat-label>
                        <span matSuffix> <i class="fa fa-times" aria-hidden="true"
                                            (click)="clearData()"
                                            style="font-size: larger; cursor: pointer"></i> </span>
                        <input type="text" matInput [formControl]="myControl"
                               [matAutocomplete]="auto" [(ngModel)]="customer_shipper">
                        <mat-autocomplete class="over" #auto="matAutocomplete"
                                          [displayWith]="displayFn">
                          <mat-option *ngFor="let option of filteredOptions | async"
                                      (click)="findbusiness()" [value]="option"
                                      matTooltip="{{option.firstName}}">
                            {{option.firstName}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>

                  </div>
                  <div *ngIf="customer_shipper.id && show">
                    <div class="row">
                      <div class="col-md-3 fw-bold">
                        Business Name
                      </div>
                      <div class="col-md-4">
                        {{customer_shipper.firstName}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 fw-bold">
                        Address
                      </div>
                      <div class="col-md-4">
                        {{customer_shipper.address_1}}, {{customer_shipper.zip_code}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 fw-bold">
                        Email
                      </div>
                      <div class="col-md-4">
                        {{customer_shipper.email_1}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 fw-bold">
                        phone
                      </div>
                      <div class="col-md-4">
                        {{customer_shipper.phone_1}}
                      </div>
                    </div>
                    <div class="h4">
                      Representative List
                      <button class="btn btn-success m-1" (click)="gotoCreateNewRepresentativePage(); resetform()">
                        Create Representative
                      </button>
                      <button (click)="findbusiness()" class="btn btn-secondary m-1"
                              matTooltip="Refresh">
                        Refresh
                        <i class="pe-7s-refresh-2 btn-icon-wrapper"> </i>
                      </button>
                    </div>
                    <div class="row overflow-auto">
                      <div style="max-height: 200px; overflow: auto;">
                        <table
                          class="align-middle text-truncate figure-caption mb-0 table table-borderless table-hover ">
                          <thead>
                          <tr>
                            <th class="text-center">Representative Id</th>
                            <th class="text-center">Name</th>
                            <th class="text-center">Address</th>
                            <th class="text-center">City</th>
                            <th class="text-center">Email</th>
                            <th class="text-center">Phone</th>
                            <th class="text-center"></th>
                          </tr>
                          </thead>
                          <tbody *ngIf="representativeList!=null">
                          <tr *ngFor="let rep of representativeList"
                              [ngStyle]="{'background-color': (rep.id==consignment_details.senderIndividual) ? '#abd8ff' : '#ffffff'}">
                            <td class="text-center text-muted" style="width: 10%;">
                              #{{rep?.id}}</td>
                            <td class="text-center text-muted" style="width: 20%;">
                              {{rep?.firstName}}</td>
                            <td class="text-center text-muted" style="width: 30%;">
                              <div
                                style="white-space: pre-wrap;word-wrap: break-word;">
                                {{rep?.billingAddress?.address}}
                              </div>
                            </td>
                            <td class="text-center text-muted" style="width: 10%;">
                              <div *ngIf="rep?.billingAddress?.pincodeId?.cityId?.name"
                                   style="white-space: pre-wrap;word-wrap: break-word;">
                                {{rep?.billingAddress?.pincodeId?.cityId?.name}}
                              </div>
                            </td>
                            <td class="text-center text-muted" style="width: 15%;">

                              <div
                                style="white-space: pre-wrap;word-wrap: break-word;">
                                {{rep?.email}}
                              </div>
                            </td>
                            <td class="text-center text-muted" style="width: 10%;">
                              {{rep?.phone}}</td>
                            <td class="text-center text-muted" style="width: 5%;">
                              <button type="button" class="btn btn-success"
                                      (click)="selectRep(rep.id)">
                                Select
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-template #notbusinessuser>
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field class="example-full-width" appearance="standard"
                                      style="width: 400px;">
                        <mat-label>Individual Name</mat-label>
                        <input type="text" matInput [formControl]="myControl"
                               [matAutocomplete]="auto" [(ngModel)]="indiviudal_user">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnss">
                          <mat-option *ngFor="let option of filteredOption | async"
                                      [value]="option" matTooltip="{{option.firstName}}">
                            {{option.firstName}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <button class="btn btn-primary m-1 float-end" (click)="findindividual()">
                        <i class="pe-7s-search">
                        </i>
                      </button>
                    </div>
                  </div>
                  <div *ngIf="indiviudal_user.id && show">
                    <div class="row">
                      <div class="col-md-3 fw-bold">
                        Name
                      </div>
                      <div class="col-md-3">
                        {{indiviudal_user.firstName}} {{indiviudal_user.lastName}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 fw-bold">
                        Address
                      </div>
                      <div class="col-md-3">
                        {{indiviudal_user.billingAddress?.address}}, {{indiviudal_user.billingAddress?.pincode?.pin_number}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 fw-bold">
                        Email
                      </div>
                      <div class="col-md-3">
                        {{indiviudal_user.email}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 fw-bold">
                        phone
                      </div>
                      <div class="col-md-3">
                        {{indiviudal_user.phone}}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary"
                        (click)="c('Close click')">Close
                </button>
              </div>
            </ng-template>

            <button *ngIf="notdisplaytocustomer" class="btn btn-primary m-1"
                    (click)="open(content4); createnewformreset()">
              Find
              <i class="pe-7s-search">
              </i>
            </button>

<!--            <button *ngIf="notdisplaytocustomer && !displayAdminandPDA" class="btn btn-success m-1"-->
<!--                    (click)="gotoCreateNewCustomerPage(); createnewformreset()">-->
<!--              Create New<i class="fa fa-fw">-->
<!--            </i>-->
<!--            </button>-->
            <div *ngIf="notdisplaytocustomer" class="row d-flex float-end">
              <p class="float-end text-danger fw-bold mt-2"
                 *ngIf="errorRepresentative==false && errorShipper ==true">
                Select Corporate Representative Details</p>
              <p class="float-end text-danger fw-bold fa-1x mt-2" *ngIf="!errorShipper">Please Select
                Shipper </p>
            </div>
            <ng-template #createAddress let-c="close" let-d="dismiss">
              <div class="modal-header">
                <h4 class="modal-title">Create Address</h4>
                <button *ngIf="refresh == true" type="button" class="btn-close" aria-label="Close"
                        (click)="d('Cross click');getAddressList(indiviudal_user.id);">
                </button>
                <button *ngIf="refresh == false" type="button" class="btn-close" aria-label="Close"
                        (click)="d('Cross click');getAddressList(consignment_details.senderIndividual);">
                </button>
              </div>
              <div class="modal-body">
                <app-address [user_id]="indiviudal_user.id"
                             [user_name]="indiviudal_user.firstName+' '+indiviudal_user.lastName">
                </app-address>
              </div>
            </ng-template>
          </div>
          <div class="font-size-md fa-2x fw-bold" *ngIf="indiviudal_user.id && errorShipper && notdisplaytocustomer">
            <h6><b style="color: rgb(53, 68, 231);">{{indiviudal_user.firstName}}
              {{indiviudal_user.lastName}}</b></h6>
            <!-- <span class="material-icons font-size-md" style="color: rgb(0, 128, 55);"> check_circle_outline </span> -->
          </div>
        </div>
        <br>
        <div class="row">
          <!-- <div class="col-md-2">
            <mat-form-field appearance="standard">
              <mat-label>
                Customer Id
              </mat-label>
              <input type="text" placeholder="000000" aria-label="Number" matInput
                [(ngModel)]="consignment_details.senderIndividual">
            </mat-form-field>
          </div> -->


          <!-- <div *ngIf="indiviudal_user" class="col-md-4 fw-bold">
            {{indiviudal_user.firstName}} {{indiviudal_user.lastName}}
          </div> -->
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-4">
                <h3>Shipper</h3>
              </div>
              <!-- <div class="col-md-5 fw-bold text-danger">
                <p class="float-end" *ngIf="!erroraddress">Select Shipper Address</p>
              </div> -->
            </div>
            <div *ngIf="indiviudal_user.customerType == 2; else notbusinessuser">
              <div class="h4">
                Corporate Customer Details <span class="text-danger">*</span>
              </div>
              <!--							<div class="row">-->
              <!--								<div class="col-md-3 fw-bold">-->
              <!--									Business ID-->
              <!--								</div>-->
              <!--								<div class="col-md-3" *ngIf="errorShipper && errorRepresentative">-->
              <!--									{{customer_shipper.id}}-->
              <!--								</div>-->
              <!--							</div>-->
              <div class="row">
                <div class="col-md-3 fw-bold">
                  Company Name
                </div>
                <div class="col-md-3" *ngIf="errorShipper && errorRepresentative">
                  {{customer_shipper?.firstName}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  E-mail
                </div>
                <div class="col md-3" *ngIf="errorShipper && errorRepresentative">
                  {{customer_shipper?.email_1}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  Phone No.
                </div>
                <div class="col-md-3" *ngIf="errorShipper  && errorRepresentative">
                  {{customer_shipper?.phone_1}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  Address
                </div>
                <div class="col-md-9" *ngIf="errorShipper && errorRepresentative">
                  {{customer_shipper.address_1}} , {{customer_shipper.zip_code}}
                  <br/>
                  <div *ngIf="customer_shipper.city_1!= null">
                    <div *ngIf="customer_shipper.city_1.id!= null">
                      {{customer_shipper.city_1.name}}, {{customer_shipper.city_1.state_id.name}},
                      {{customer_shipper.city_1.state_id.country.name}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  GST No
                </div>
                <div class="col-md-3" *ngIf="errorShipper && errorRepresentative">
                  {{customer_shipper.gst}}
                </div>
              </div>
              <mat-divider>

              </mat-divider>
              <div class="h4">
                Corporate Representative Details <span class="text-danger">*</span>
              </div>
              <!-- <div class="row">
                <div class="col-md-6">
                  <h4>Corporate Representative Details  <span class="text-danger">*</span></h4>
                </div>
              </div> -->
              <!--							<div class="row">-->
              <!--								<div class="col-md-3 fw-bold">-->
              <!--									Representative ID-->
              <!--								</div>-->
              <!--								<div class="col-md-9">-->
              <!--									{{indiviudal_user.id}}-->
              <!--								</div>-->
              <!--							</div>-->
              <div class="row">
                <div class="col-md-3 fw-bold">
                  Name
                </div>
                <div class="col-md-9">
                  {{indiviudal_user.firstName}} {{indiviudal_user.middleName}}
                  {{indiviudal_user.lastName}}

                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  E-mail
                </div>
                <div class="col-md-6">
                  {{indiviudal_user.email}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3  fw-bold">
                  Phone No.
                </div>
                <div class="col-md-3">
                  {{ indiviudal_user.phone}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  Whatsapp No.
                </div>
                <div class="col-md-3">
                  {{indiviudal_user.whatsapp}}
                </div>
              </div>
              <div class="h4">
                Choose an Address <span class="text-danger me-4">*</span>
                <button class="btn btn-primary me-2" (click)="open(createAddress);corporefresh();">
                  Add Address<i class="fa fa-fw">
                </i>
                </button>
                <button class="btn btn-secondary m-1" matTooltip="Refresh"
                        (click)="getAddressList(consignment_details.senderIndividual)">
                  Refresh
                  <i class="pe-7s-refresh-2 btn-icon-wrapper"> </i>
                </button>
                <!-- <button class="m-auto btn-icon btn-icon-only btn mb-1"
                  (click)="getAddressList(consignment_details.senderIndividual)">
                  <i class="pe-7s-refresh-2 btn-icon-wrapper" matTooltip="Refresh"> </i></button> -->
              </div>
              <div style="max-height: 200px; overflow: auto;">
                <div *ngIf="reprsentativeAddress!= null">
                  <div *ngFor="let add of reprsentativeAddress " class="flex-row ">
                    <div class="widget-content p-0">
                      <div class="border-bottom border-bottom-3 border-top row"
                           [ngStyle]="{'background-color': (add.id==selectedAddress) ? '#abd8ff' : '#ffffff'}">
                        <div class="col-md-4 fw-bold opacity-7">
                          {{add.address}}
                          <!-- {{consignment_details.originLocation?.city?.id}} == {{add.cityId?.id}} -->
                        </div>
                        <div class="col-md-3 ">
                          <div class="widget-heading text-dark opacity-7">
                            {{add.pincodeId?.pinNumber}}
                          </div>
                          <div class="widget-subheading opacity-10">
                            {{add.pincodeId?.cityId?.name}}
                          </div>
                        </div>
                        <div class="col-md-3 ">
                          <div class="widget-heading text-dark opacity-7">
                            {{add.pincodeId?.cityId?.state_id.name}}
                          </div>
                          <div class="widget-subheading opacity-10 ps-2">
                            {{add.pincodeId?.cityId?.state_id.country.name}}
                          </div>
                        </div>
                        <div class="col-md-1">
                          <div class="md-2 me-2 btn btn-primary"
                               (click)="selectAddress(add.id)">
                            Select
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="flex-column">
                    {{add.address}}, {{add.pincodeId?.pin_number}}
                  </div> -->
                    <!-- <div class="flex-column">
                      {{add.cityId?.name}}, {{add.cityId?.state_id.name}},
                      {{add.cityId?.state_id.country.name}}
                  </div> -->
                  </div>

                </div>
              </div>
              <!-- <div class="row">
                <div class="col-md-3 fw-bold">
                  Address
                </div>
                <div class="col-md-9">
                  {{indiviudal_user.address}}, {{indiviudal_user.zip_code}}
                  <br />
                  <div *ngIf="indiviudal_user.city!= null">
                    <div *ngIf="indiviudal_user.city.name">

                      {{indiviudal_user.city.name}}, {{indiviudal_user.city.state_id.name}},
                      {{indiviudal_user.city.state_id.country.name}}
                    </div>
                  </div>
                </div>

              </div> -->
<!--              <div class="row">-->
<!--                <div class="col-md-3 fw-bold">-->
<!--                  <div *ngIf="indiviudal_user.varification_id_type == 1">-->
<!--                    AADHAR-->
<!--                  </div>-->
<!--                  <div *ngIf="indiviudal_user.varification_id_type == 2">-->
<!--                    PAN-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-3">-->
<!--                  {{indiviudal_user.verification_id}}-->
<!--                </div>-->
<!--              </div>-->
            </div>
            <ng-template #notbusinessuser>
              <div class="row">
                <div class="h4">
                  Retail Customer Details<span class="text-danger">*</span>
                </div>
                <!--								<div class="col-md-3 fw-bold">-->
                <!--									Customer ID-->
                <!--								</div>-->
                <!--								<div class="col-md-9" *ngIf="errorShipper">-->
                <!--									{{indiviudal_user.id}}-->
                <!--								</div>-->
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  Name
                </div>
                <div class="col-md-9">
                  {{indiviudal_user.firstName}} {{indiviudal_user.middleName}}
                  {{indiviudal_user.lastName}}

                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  E-mail
                </div>
                <div class="col-md-6">
                  {{indiviudal_user.email}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3  fw-bold">
                  Phone No.
                </div>
                <div class="col-md-3">
                  {{ indiviudal_user.phone}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  Whatsapp No.
                </div>
                <div class="col-md-3">
                  {{indiviudal_user.whatsapp}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  Address
                </div>
                <div class="col-md-9">
                  {{indiviudal_user.billingAddress?.address}}, {{indiviudal_user.billingAddress?.pincode?.pin_number}}
                  <br/>
<!--                  <div *ngIf="user_sel_city!= null">-->
<!--                    <div *ngIf="user_sel_city.id!= null">-->
<!--                      {{indiviudal_user.city.name}}, {{indiviudal_user.city.state_id.name}},-->
<!--                      {{indiviudal_user.city.state_id.country.name}}-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
                <div class="h4">
                  Choose An Address <span class="text-danger me-4">*</span>
                  <button class="btn btn-primary m-1"
                          (click)="open(createAddress);individualrefresh();">
                    Add Address<i class="fa fa-fw">
                  </i>
                  </button>
                  <button class="btn btn-secondary m-1" matTooltip="Refresh"
                          (click)="getAddressList(indiviudal_user.id)">
                    Refresh
                    <i class="pe-7s-refresh-2 btn-icon-wrapper"> </i>
                  </button>
                </div>
                <div style="max-height: 200px; overflow: auto;">
                  <div *ngIf="reprsentativeAddress!= null">
                    <div *ngFor="let add of reprsentativeAddress " class="flex-row ">
                      <div class="widget-content p-0">
                        <div class="border-bottom border-bottom-3 border-top row"
                             [ngStyle]="{'background-color': (add.id==selectedAddress) ? '#abd8ff' : '#ffffff'}">
                          <div class="col-md-4 fw-bold opacity-7">
                            {{add.address}}
                            <!-- {{consignment_details.originLocation?.city?.id}} == {{add.cityId?.id}} -->
                          </div>
                          <div class="col-md-3 ">
                            <div class="widget-heading text-dark opacity-7">
                              {{add.pincodeId?.pinNumber}}
                            </div>
                            <div class="widget-subheading opacity-10">
                              {{add.pincodeId?.cityId?.name}}
                            </div>
                          </div>
                          <div class="col-md-3 ">
                            <div class="widget-heading text-dark opacity-7">
                              {{add.pincodeId?.cityId?.state_id.name}}
                            </div>
                            <div class="widget-subheading opacity-10 ps-2">
                              {{add.pincodeId?.cityId?.state_id.country.name}}
                            </div>
                          </div>
                          <div class="col-md-1">
                            <div class="md-2 me-2 btn btn-primary"
                                 (click)="selectAddress(add.id)">
                              Select
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
<!--              <div class="row">-->
<!--                <div class="col-md-3 fw-bold">-->
<!--                  <div *ngIf="indiviudal_user.varification_id_type == 1">-->
<!--                    AADHAR-->
<!--                  </div>-->
<!--                  <div *ngIf="indiviudal_user.varification_id_type == 2">-->
<!--                    PAN-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-3">-->
<!--                  {{indiviudal_user.verification_id}}-->
<!--                </div>-->
<!--              </div>-->
            </ng-template>

            <!-- <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="standard">

                  <mat-label>
                    Credit Period
                  </mat-label>
                  <input disabled type="text" placeholder="Credit Period" aria-label="Number" matInput
                    [disabled]="validShipper">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="standard">

                  <mat-label>
                    Credit Value
                  </mat-label>
                  <input disabled type="text" placeholder="Credit Value" aria-label="Number" matInput
                    [disabled]="validShipper">
                </mat-form-field>
              </div>
            </div> -->
            <!-- <span class="border-end  border-dark"></span> -->
          </div>
          <div class="col-md-6">
          </div>

        </div>

      </div>
      <div *ngIf="selectedAddress!=null">
        <button class="btn btn-primary btn-lg float-end" mat-button matStepperNext>Next</button>
      </div>

      <ngx-loading [show]="loadingSecound"
                   [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                   [template]="loadingTemplate"></ngx-loading>
    </mat-step>
    <mat-step label="Receiver">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-4">
            <h3>Receiver</h3>
          </div>
          <div class="col-md-4 fw-bold text-danger">
            <p class="float-end mt-2" *ngIf="!errorReceiver">Enter Receiver Details.</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <mat-form-field appearance="standard">
              <mat-label>
                First Name<span class="text-danger">*</span>
              </mat-label>
              <input type="text" placeholder="First Name" aria-label="Number" matInput
                     [(ngModel)]="receiver.firstName">
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="standard">
              <mat-label>
                Last Name<span class="text-danger">*</span>
              </mat-label>
              <input type="text" placeholder="Last Name" aria-label="Number" matInput
                     [(ngModel)]="receiver.lastName">
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="standard">
              <mat-label>
                Company Name
              </mat-label>
              <input type="text" placeholder="Company Name" aria-label="Number" matInput
                     [(ngModel)]="receiver.companyName">
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Email<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Email" aria-label="Number" matInput
                       [(ngModel)]="receiver.email1" [formControl]="emailFormControl">
                <!-- <mat-hint class="text-danger fw-normal" *ngIf="emailFormControl.hasError('required')">Email is required</mat-hint> -->
                <mat-hint class="text-danger fw-normal"
                          *ngIf="emailFormControl.hasError('pattern')">Invalid email address
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Phone No.<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Phone No."
                       oninput="if(!this.value.match('^[0-9]*$'))this.value='';" aria-label="Number"
                       matInput #phone1 maxlength="10" [(ngModel)]="receiver.phone1">
                <mat-hint align="end">{{phone1.value.length}}/10
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-4">
            <mat-form-field class="example-full-width" appearance="standard">
              <mat-label>Address<span class="text-danger">*</span></mat-label>
              <textarea matInput placeholder="" [(ngModel)]="receiver.address"></textarea>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="standard">
              <mat-label>
                Country<span class="text-danger">*</span>
              </mat-label>
              <mat-select  (blur)="getRecverStates()" [(ngModel)]="recCon">
                <mat-option selected [value]="">SELECT</mat-option>
                <mat-option *ngFor="let con of countries | async" [value]="con.id">
                  {{con.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="standard">
              <mat-label>
                State<span class="text-danger">*</span>
              </mat-label>
              <mat-select  (blur)="getReceverCities()" [(ngModel)]="recState">
                <mat-option *ngFor="let sta of states |async" [value]="sta.id">
                  {{sta.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <mat-form-field appearance="standard">
              <mat-label>
                City<span class="text-danger">*</span>
              </mat-label>
              <mat-select  (blur)="getReceverPincodes()" [(ngModel)]="receiver.city">
                <mat-option *ngFor="let cit of cities |async" [value]="cit.id">
                  {{cit.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div class="col-md-4">
            <mat-form-field appearance="standard">
              <mat-label>
                Zip Code <span class="text-danger">*</span>
              </mat-label>
              <mat-select matNativeControl  [(ngModel)]="receiver.zipCode">
                <mat-option selected>SELECT</mat-option>
                <mat-option *ngFor="let cit of pincodes |async" [value]="cit.pinNumber">
                  {{cit.pinNumber}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row m-4 d-flex">
          <div class="col-md-2 float-right m-auto">

            <!-- <button *ngIf="clicked == false" class="btn btn-primary btn-lg" (click)="createReceiver()">Save
              Details</button> -->

            <button *ngIf="!clicked" class="btn btn-primary btn-lg" (click)="saveReceiver()">Save
              Details
            </button>
            <button *ngIf="clicked" class="btn btn-primary btn-lg" (click)="updateReceiver()">Update
              Details
            </button>
            <!-- <div *ngIf="this.receiver_with_id?.id!=undefined && clicked == true">
              <button class="btn btn-primary btn-lg float-end" mat-button matStepperNext>Next</button>
            </div> -->
          </div>

        </div>
        <div class="row">
          <div class="">
            <button class="btn btn-primary" mat-button matStepperPrevious>Back</button>
            <button class="col-md-1 btn btn-primary btn-lg float-end"
                    *ngIf="this.receiver_with_id?.id!=undefined && clicked == true" mat-button
                    matStepperNext>Next
            </button>
          </div>
        </div>
        <!-- <span class="border-end  border-dark"></span> -->
      </div>
    </mat-step>
    <mat-step label="Book Consignment">
      <div class="container ">
        <div class="row">
          <div class="col-md-2">
            <mat-form-field appearance="standard">
              <mat-label for="consignmentType">
                Consignment Type<span class="text-danger">*</span>
              </mat-label>
              <mat-select [(ngModel)]="consignment_details.consignmentType" id="consignmentType">
                <mat-option (click)="selectConsignmentType(option)"
                            *ngFor="let option of consignmentTypes" [value]="option.id">
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <!-- <input type="text" placeholder="Select" aria-label="Number" matInput [formControl]="myControl"
                [matAutocomplete]="auto" [(ngModel)]="consignment_details.consignmentType">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete> -->
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">
              <mat-label>
                Consignment Subtype <span class="text-danger">*</span>
              </mat-label>
              <mat-select  [(ngModel)]="consignment_details.consignmentSubType">
                <mat-option *ngFor="let sub_type of consignmentSubTypes " [value]="sub_type.id">
                  {{sub_type.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">
              <mat-label>
                Dimensions Units <span class="text-danger">*</span>
              </mat-label>
              <mat-select  [(ngModel)]="consignment_details.dimensionuUnit"
                          (blur)="selected_dimentions()">
                <mat-option *ngFor="let d_unit of dimensionuUnits_array " [value]="d_unit.id">
                  {{d_unit.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">

              <mat-label for="weightUnit">
                Weight Unit<span class="text-danger">*</span>
              </mat-label>
              <mat-select id="weightUnit" [(ngModel)]="consignment_details.weightUnit"
                          (blur)="selected_weight()">
                <mat-option *ngFor="let w_unit of weightUnits_array " [value]="w_unit.id">
                  {{w_unit.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">

              <mat-label for="dimensionuUnits">
                Currency<span class="text-danger">*</span>
              </mat-label>
              <mat-select  [(ngModel)]="consignment_details.currency">
                <mat-option [value]="1">
                  INR
                </mat-option>
                <!-- <mat-option value=1>
                  USD
                </mat-option> -->
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">

              <mat-label for="dimensionuUnits">
                Weight Divisor<span class="text-danger">*</span>
              </mat-label>
              <mat-select  [(ngModel)]="consignment_details.weightDivisor">

								<mat-option *ngFor="let w_div of weightDivisorList " [value]="w_div">
									{{w_div}}
								</mat-option>
								<!-- <mat-option value=1>
									USD
								</mat-option> -->
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col-md-2">
						<mat-form-field appearance="standard">
							<mat-label for="commodityTypes">
								Commodity Type<span class="text-danger">*</span>
							</mat-label>
							<mat-select id="commodityTypes" [(ngModel)]="consignment_details.commodityType">
								<mat-option *ngFor="let commodityType of commodityTypes" [value]="commodityType.id">
									{{ commodityType.commodity_type }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="col-md-2">
						<mat-form-field appearance="standard">
							<mat-label>
								Product Type<span class="text-danger">*</span>
							</mat-label>
							<mat-select  [(ngModel)]="consignment_details.productType">
								<mat-option [value]=1 *ngIf="Type =='PRUDENT' || Type =='GEM' ">
									ATA
								</mat-option>
								<mat-option [value]=2 *ngIf="Type =='PRUDENT' || Type =='GEM' ">
									ATD
								</mat-option>
								<mat-option [value]=3 *ngIf="Type =='PRUDENT' || Type =='GEM' ">
									DTA
								</mat-option>
								<mat-option [value]=4
									*ngIf="Type =='PRUDENT' || Type =='GEM'  || Type =='Full Truck Load' || Type =='Partial Truck Load' || Type =='Reefer Vehicle' || Type =='Coldchain Packaging'">
									DTD
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

          <div class="col-md-2">

            <mat-form-field style="width: 90%;" appearance="standard">
              <mat-label for="totalWeight">
                Invoice Reference No.<span class="text-danger">*</span>
              </mat-label>
              <input  matInput #invoiceReferenceNo maxlength="16"
                     [(ngModel)]="consignment_details.invoiceReferenceNo">
              <mat-hint align="end">{{invoiceReferenceNo.value.length}}/16
              </mat-hint>
            </mat-form-field>

          </div>
          <div class="col-md-2">

            <mat-form-field style="width: 90%;" appearance="standard">
              <mat-label>
                Invoice Date
              </mat-label>
              <input  matInput [(ngModel)]="consignment_details.invoiceCreatedDate"
                     [matDatepicker]="picker" disabled>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>

          </div>
          <div class="col-md-2">

						<mat-form-field style="width: 90%;" appearance="standard">
							<mat-label for="totalWeight">
								Consignment Value <span class="text-danger">*</span>
							</mat-label>
							<input id="Consignment Value" matInput maxlength="10"
								oninput="if(!this.value.match('^[0-9]*$'))this.value='';"
								[(ngModel)]="consignment_details.totalConsignmentValue">

            </mat-form-field>

          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">
              <mat-label for="totalWeight">
                Payment Mode <span class="text-danger">*</span>
              </mat-label>
              <mat-select  [(ngModel)]="consignment_details.paymentMode">
                <mat-option selected disabled [value]="0">
                  Select
                </mat-option>
                <mat-option [value]="1">
                  Cash
                </mat-option>
                <mat-option [value]="3">
                  Credit/Debit Card
                </mat-option>
                <mat-option [value]="2">
                  Credit
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">

            <mat-form-field style="width: 90%;" appearance="standard">
              <mat-label>
                Tentative Pickup Date
              </mat-label>
              <input  matInput
                     [(ngModel)]="consignment_details.tentative_pickup_time" [matDatepicker]="pickup"
                     disabled>
              <mat-datepicker-toggle matSuffix [for]="pickup"></mat-datepicker-toggle>
              <mat-datepicker #pickup disabled="false"></mat-datepicker>
            </mat-form-field>

          </div>

          <div class="col-md-3 row" *ngIf="manualConsignmentNumber">
            <mat-form-field appearance="standard">
              <mat-label>
                Consignment Number
              </mat-label>
              <input type="text" aria-label="Number" matInput #consignmentNo
                     oninput="if(!this.value.match('^[1-3][0-9]*$'))this.value='';" maxlength="11"
                     [(ngModel)]="consignment_details.consignmentNo" (blur)="consignmentNoExists()">
              <mat-hint class="text-danger fw-bold" *ngIf="consignmentNoExist==true">Consignment No. Exist
              </mat-hint>
              <mat-hint align="end">{{consignmentNo.value.length}}/11
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="col-md-2" *ngIf="Type == 'Reefer Vehicle' || Type == 'Coldchain Packaging'">
            <mat-form-field appearance="standard">
              <mat-label for="Temprature">
                Temperature Range <span class="text-danger">*</span>
              </mat-label>
              <mat-select [(ngModel)]="consignment_details.required_temperature.id">
                <mat-option selected disabled [value]="">
                  Select
                </mat-option>
                <mat-option *ngFor="let container of containerTempRangeOptions" [value]="container.id">
                  {{ container.name }}&deg;C
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2" *ngIf="Type == 'Full Truck Load' || Type == 'Reefer Vehicle'">
            <mat-form-field appearance="standard">
              <mat-label for="Temprature">
                Container Size <span class="text-danger">*</span>
              </mat-label>
              <mat-select [(ngModel)]="consignment_details.container_size.id">
                <mat-option selected disabled [value]="">
                  Select
                </mat-option>
                <mat-option *ngFor="let container of containerOptions" [value]="container.id">
                  {{ container.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 row">
            <mat-form-field style="width: 90%;" appearance="standard">
              <mat-label for="totalWeight">
                Remarks
              </mat-label>
              <textarea id="Remarks" matInput [(ngModel)]="consignment_details.remarks">
								</textarea>
            </mat-form-field>

          </div>

          <div class="col-md-4" *ngIf="Type == 'KP'">
            <mat-form-field appearance="standard">
              <mat-label for="package_type">
                Package Type
              </mat-label>
              <textarea id="package_type" matInput [(ngModel)]="consignment_details.package_type">
								</textarea>
            </mat-form-field>

          </div>
        </div>
        <div class="row py-2" *ngIf="Type == 'PRUDENT' || Type == 'GEM'">

          <div class="col-md-3 row">
            <mat-form-field appearance="standard">
              <mat-label>
                Origin Location <span class="text-danger">*</span>
              </mat-label>
              <input type="text" placeholder="Origin Location" aria-label="Number" disabled matInput
                     [formControl]="myControl1" [matAutocomplete]="auto"
                     [(ngModel)]="consignment_details.originLocation">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn1">
                <mat-option *ngFor="let cit of filteredAirports | async" [value]="cit">
                  {{cit.city.name}} - {{cit.iataCode}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

          </div>
          <div class="col-md-3 row">
            <mat-form-field appearance="standard">
              <mat-label>
                Destination Location <span class="text-danger">*</span>
              </mat-label>
              <input type="text" placeholder="Destination Location" aria-label="Number" disabled matInput
                     [formControl]="myControl2" [matAutocomplete]="auto2"
                     [(ngModel)]="consignment_details.destinationLocation">
              <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn2">
                <mat-option *ngFor="let cit of filteredAirports2 | async" [value]="cit">

                  {{cit.city.name}} - {{cit.iataCode}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

          </div>
        </div>
        <div class="row" style="width: 100%;">
          <mat-divider></mat-divider>
          <div class="table-responsive">
            <table class="table p-2 ">
              <thead>
              <tr>

                <th>
                  #
                </th>
                <th style="min-width: 150px;">
                  Package Dimensions <span class="text-danger">*</span>
                </th>
                <th class="mx-w-150">
                  No of Packages <span class="text-danger">*</span>
                </th>
                <th class="mx-w-150">
                  Weight per Package <span class="text-danger">*</span>
                </th>
                <th class="mx-w-150">
                  Volume Weight
                </th>
                <th class="mx-w-200">
                  Total Packages Weight
                </th>
                <th class="mx-w-200">
                  Chargeable Weight
                </th>
                <th>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let package of packages">
                <td>
                  {{package.id}}
                </td>
                <td class="mx-w-150">
                  <div class="row">

                    <!-- <mat-form-field class="m-0" style="width: 25%;" appearance="standard"> -->
                    <div class="col-md-4">

                      <input type="text" placeholder="L" aria-label="Number" matInput
                             [(ngModel)]="package.length" min="1" maxlength="5"
                             (keypress)="validateInput($event)"
                             (input)="eachPackageCharged(package.id)">
                      <span matSuffix>{{selectedDimention}}</span>
                    </div>
                    <div class="col-md-4">

                      <!-- </mat-form-field> -->
                      <!-- <mat-form-field class="p-1" style="width: 25%;" appearance="standard"> -->
                      <input type="text" placeholder="B" aria-label="Number" matInput
                             [(ngModel)]="package.breadth" min="1" maxlength="5"
                             (keypress)="validateInput($event)"
                             (input)="eachPackageCharged(package.id)">
                      <span matSuffix>{{selectedDimention}}</span>
                    </div>
                    <div class="col-md-4">

                      <!-- </mat-form-field> -->
                      <!-- <mat-form-field class="p-1" style="width: 25%;" appearance="standard"> -->

                      <input type="text" placeholder="H" aria-label="Number" matInput
                             [(ngModel)]="package.height" min="1" maxlength="5"
                             (keypress)="validateInput($event)"
                             (input)="eachPackageCharged(package.id)">
                      <span matSuffix>{{selectedDimention}}</span>
                      <!-- </mat-form-field> -->
                    </div>
                  </div>
                </td>
                <td class="mx-w-150">
                  <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                    <input type="number" placeholder="No of Packages" aria-label="Number" matInput
                           [(ngModel)]="package.packageNo" min="1"
                           (keypress)="validateInput1($event)"
                           (input)="eachPackageCharged(package.id)">

                  </mat-form-field>
                </td>
                <td class="mx-w-150">

                  <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                    <input type="number" placeholder="Package Weight" aria-label="Number" matInput
                           [(ngModel)]="package.weight" min="1" (keypress)="validateInput($event)"
                           (input)="eachPackageCharged(package.id)">
                    <span matSuffix>{{ selectedWeight}}</span>
                  </mat-form-field>
                </td>
                <td class="mx-w-150">
                  <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                    <!-- <input type="text" placeholder="Volume Weight" matInput disabled
                      [(ngModel)]="package.volume_weight"> -->
                    <input type="text" placeholder="Volume Weight" matInput disabled
                           [(ngModel)]="package.volume_weight">
                  </mat-form-field>
                </td>
                <td class="mx-w-200">
                  <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                    <input placeholder="Total Package Weight" matInput disabled
                           [(ngModel)]="package.total_weight">
                    <span matSuffix>{{ selectedWeight}}</span>

                  </mat-form-field>
                </td>
                <td class="mx-w-200">
                  <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                    <input placeholder="Chargeable Weight" matInput disabled
                           [(ngModel)]="package.chargeable_weight">
                    <span matSuffix>{{ selectedWeight}}</span>

                  </mat-form-field>
                </td>
                <td class="mx-w-150 align-middle">
                  <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon" [disabled]="packages.length == 1" (click)="deletePackage(package.id)">
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </td>
              </tr>
              <tr>
                <td class="mx-w-150">
                </td>
                <td class="mx-w-150">
                </td>
                <th class="mx-w-150">
                  <mat-label for="totalWeight">
                    Total No. Packages
                  </mat-label>
                  <mat-form-field style="width: 90%;" appearance="standard">
                    <input  matInput disabled value="Total No. Packages"
                           [(ngModel)]="consignment_details.totalNoOfPackages">
                  </mat-form-field>
                </th>


                <td>

                </td>
                <th class="mx-w-150">
                  <mat-label for="totalWeight">
                    Total Volume Weight
                  </mat-label>
                  <mat-form-field style="width: 90%;" appearance="standard">
                    <input matInput disabled value="Total Volume Weight"
                           [(ngModel)]="totalvolume_weight">
                  </mat-form-field>
                </th>
                <th class="mx-w-150">
                  <mat-label for="totalWeight">
                    Total Weight
                  </mat-label>
                  <mat-form-field style="width: 90%;" appearance="standard">
                    <input id="totalWeight" matInput disabled
                           [(ngModel)]="consignment_details.totalWeight">
                    <span matSuffix>{{ selectedWeight}}</span>

                  </mat-form-field>
                </th>
                <th class="mx-w-200">
                  <mat-label for="totalWeight">
                    Total Chargeable Weight
                  </mat-label>
                  <mat-form-field style="width: 90%;" appearance="standard">
                    <input id="total_charged" matInput disabled
                           [(ngModel)]="consignment_details.totalChargableWeight">
                    <span matSuffix>{{ selectedWeight}}</span>

                  </mat-form-field>
                </th>
                <td class="mx-w-200 align-middle">
                  <button class="btn btn-primary" (click)="addPackage()">
                    <i class="fa fa-plus">
                    </i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <mat-divider></mat-divider>
        </div>


        <div class="row m-2">
          <button class="btn btn-primary col-md-auto" mat-button matStepperPrevious>Back</button>
          <div *ngIf="paymentdone == false" class="col-md-2 float-right m-auto">

            <button class="btn btn-primary btn-lg" (click)="createOrderConsignment()" mat-button
                    matStepperNext>Create
            </button>
          </div>
          <!-- <button mat-button color="primary" (click)="stepper.selectedIndex = 0">Create New</button> -->
          <!-- <button mat-raised-button color="primary" (click)="createnewformreset(); stepper.selectedIndex = 0">Create New</button> -->
          <!-- <button mat-button color="primary" (click)="createNew()">Create New</button> -->
          <!-- <button mat-button (click)="createNew()">Create New</button> -->
          <!-- <button class="btn btn-primary col-md-auto" mat-button matStepperPrevious>Back</button> -->
          <!-- <div *ngIf="paymentdone == true" class="col-md-2 float-right m-auto">

            <button class="btn btn-primary btn-lg" (click)="getRecord()">Download Report</button>

          </div> -->
          <!-- <button class="btn btn-primary col-md-auto float-end" (click)="myFunction() ; stepper.reset()">Create New</button> -->
        </div>
        <!-- <div class="flex-row">
          <div class="col-md-3  float-end">
            <a [routerLink]="['/corporatebooking']" class="btn btn-primary btn-lg">Create New</a>
          </div>
        </div> -->
      </div>
      <ngx-loading [show]="loading"
                   [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                   [template]="loadingTemplate"></ngx-loading>

    </mat-step>

  </mat-horizontal-stepper>
</div>

<!-- <mat-slider min="1" max="100" step="1" value="50"></mat-slider> -->
