import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.sass'],
})
export class SendNotificationComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
