<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="row">
  <div class="col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Positions
      </div>
      <div class="card-body">
        <div class="example-tooltip-host" matTooltip="Tooltip!" [matTooltipPosition]="position">
          <span>Show tooltip</span>
          <mat-form-field>
            <mat-select class="example-select" [(ngModel)]="position">
              <mat-option value="before">Before</mat-option>
              <mat-option value="after">After</mat-option>
              <mat-option value="above">Above</mat-option>
              <mat-option value="below">Below</mat-option>
              <mat-option value="left">Left</mat-option>
              <mat-option value="right">Right</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Delay
      </div>
      <div class="card-body">
        <button class="me-2" mat-raised-button matTooltip="Tooltip!" matTooltipShowDelay="1000">
          My tooltip waits one second to show
        </button>
        <button class="me-2" mat-raised-button matTooltip="Tooltip!" matTooltipHideDelay="2000">
          My tooltip waits two seconds to hide
        </button>
      </div>
    </div>
    <div class="card main-card mb-3">
      <div class="card-header">
        Manual Trigger
      </div>
      <div class="card-body">
        <button mat-raised-button (click)="tooltip.show()"> Show tooltip </button>
        <span matTooltip="This is the tooltip message" #tooltip="matTooltip">
          I have a tooltip
        </span>
      </div>
    </div>
  </div>
</div>