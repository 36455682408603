import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-booking-types',
  templateUrl: './booking-types.component.html',
  styleUrls: ['./booking-types.component.sass'],
})
export class BookingTypesComponent implements OnInit {
  notdisplaytocustomer = true;

  currProductType: string;
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails.userTypes[0].name == 'Customer') {
      this.notdisplaytocustomer = false;
    }
    this.route.params.subscribe((params) => {
      // Access the parameter
      this.currProductType = params['productType'];
    });
  }

  goto(filter: string) {
    this.router.navigate(['/corporatebooking'],
        {
          queryParams: {
            type: filter,
          },
        },
    );
  }

  onlineRedirect() {
    if (this.currProductType==='Ace') {
      this.router.navigate(['/iclproducts'],
          {queryParams: {
            type: 'Ace',
          }});
    }
    if (this.currProductType==='Quick') {
      this.router.navigate(['/quickproducts'],
          {queryParams: {
            type: 'Quick',
          }});
    }
    if (this.currProductType==='Prudent') {
      this.router.navigate(['/iclproducts'],
          {queryParams: {
            type: 'Prudent',
          }});
    }
    if (this.currProductType==='Gem') {
      this.router.navigate(['/iclproducts'],
          {queryParams: {
            type: 'Gem',
          }});
    }
  }

  offlineRedirect() {
    if (this.currProductType==='Ace') {
      this.router.navigate(['/corporatebooking/ACE1']);
    }
    if (this.currProductType==='Quick') {
      this.router.navigate(['/corporatebooking/QUICK1']);
    }
    if (this.currProductType==='Prudent') {
      this.router.navigate(['/corporatebooking/PRUDENT1']);
    }
    if (this.currProductType==='Gem') {
      this.router.navigate(['/corporatebooking/GEM1']);
    }
  }
}
