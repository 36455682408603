import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL } from '../../../../../Global_API_URL';
import { CSVFileFormatDTO } from '../../golbalInterfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CsvFileFormatsService {

  constructor(private http: HttpClient) { }

  private apiUrl = API_URL + '/csvFileFormats';

  getAllCsvFileFormats(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}`);
  }

  getActiveCsvFileFormats(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/active`);
  }

  getCsvFileFormatById(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`);
  }

  createCsvFileFormat(csvFileFormat: CSVFileFormatDTO, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('contentDisposition', csvFileFormat.contentDisposition || '');
    formData.append('fileName', csvFileFormat.fileName || '');
    formData.append('fileType', csvFileFormat.fileType || '');
    formData.append('fileSize', csvFileFormat.fileSize?.toString() || '0');
    formData.append('description', csvFileFormat.description || '');
    formData.append('createdBy', csvFileFormat.createdBy?.toString() || '0');

    return this.http.post<any>(`${this.apiUrl}`, formData);
  }

  updateCsvFileFormat(id: number, csvFileFormat: CSVFileFormatDTO, file: File | null): Observable<any> {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    formData.append('contentDisposition', csvFileFormat.contentDisposition || '');
    formData.append('fileName', csvFileFormat.fileName || '');
    formData.append('fileType', csvFileFormat.fileType || '');
    formData.append('fileSize', csvFileFormat.fileSize?.toString() || '0');
    formData.append('description', csvFileFormat.description || '');
    formData.append('updatedBy', csvFileFormat.updatedBy?.toString() || '0');

    return this.http.patch<any>(`${this.apiUrl}/${id}`, formData);
  }

  deleteCsvFileFormat(id: number): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/${id}`);
  }

  deactivateCSVFileFormat(id: number): Observable<void> {
    const url = `${this.apiUrl}/deactivate/${id}`;
    return this.http.patch<void>(url, {});
  }

  downloadFileById(id: number): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders().set('Accept', 'text/csv');
    return this.http.get(`${this.apiUrl}/download/${id}`, {
      headers,
      responseType: 'blob',
      observe: 'response',
    });
  }

  existsByContentDisposition(contentDisposition: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}/exists?contentDisposition=${contentDisposition}`);
  }

}
