<!-- <p>tally-irn-generate-view works!</p> -->
<ngx-loading [show]="loading"
    [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
    [template]="loadingTemplate"></ngx-loading>

<div class="main-card mb-3 card">
    <div class="card-header">
        <div class="card-header-title font-size-lg text-capitalize fw-bold;">
            <i class="fa fa-arrow-left" (click)="backToInvoiceHistory()"
                style="cursor: pointer; color: blue; margin-right: 10px;"></i>
            <b style="color: blue;">VIEW INVOICE DETAILS</b>
        </div>
        <div class="btn-actions-pane-right p-2">
            <button mat-raised-button color="primary" (click)="goToIRNGenerateEdit()"><strong>EDIT</strong></button>
        </div>
    </div>
    <div class="container m-2 ">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-2 fw-bold">Invoice No</div>
                    <div class="col-md-1 fw-bold"> - </div>
                    <div class="col-md-3"> {{invoiceData?.invoiceNo}} </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-2 fw-bold">IRN Number</div>
                    <div class="col-md-1 fw-bold"> - </div>
                    <div class="col-md-3">{{ invoiceData?.irnNumber || 'N/A'}}</div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-2 fw-bold border-start">Acknowledgement No</div>
                    <div class="col-md-1 fw-bold"> - </div>
                    <div class="col-md-3">{{ invoiceData?.acknowledgementNumber || 'N/A' }}</div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-2 fw-bold border-start">IRN Generated At</div>
                    <div class="col-md-1 fw-bold"> - </div>
                    <div class="col-md-3">{{ invoiceData?.irnGeneratedAt ? (invoiceData.irnGeneratedAt | date: 'MMM d, y, h:mm a') : 'N/A' }}</div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-2 fw-bold border-start">QR Code</div>
                    <div class="col-md-1 fw-bold"> - </div>
                    <div class="col-md-8">{{ invoiceData?.qrCode || 'N/A' }}</div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-2 fw-bold border-start">Remarks</div>
                    <div class="col-md-1 fw-bold"> - </div>
                    <div class="col-md-8">{{ invoiceData?.remarks || 'N/A' }}</div>
                </div>
                <br>
            </div>
        </div>
    </div>
</div>