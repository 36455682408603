
const STG_SERVER = {
  api: 'https://apistg.iclscm.com/iclstg',
  header: 'Staging Server',
  apilocal: 'https://appstg.iclscm.com',
};
const PROD_SERVER = {
  api: 'https://api.iclscm.com/icl',
  header: 'Prod Server',
  apilocal: 'https://app.iclscm.com',
};
const MY_LOCAL = {
  api: 'http://localhost:3000',
  header: 'Local Server',
  apilocal: 'http://localhost:4200',
};

const DEV_SERVER = {
  api: 'http://192.168.0.151:8080/icldev',
  header: 'Dev Server',
  apilocal: 'http://192.168.0.151:4200',
};


const server = STG_SERVER;
// Just change the above api to change the variables
export const header = server.header;
export const API_URL = server.api;
export const LOCAL_API_URL = server.apilocal;
