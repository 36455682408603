<div class="col-md-12">
  <container-element [ngSwitch]="pageview">
    <some-element *ngSwitchCase="'table'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            <b style="color: blue;">Branches</b>
          </div>
          <div class="btn-actions-pane-right p-2" *ngIf="!displaytoAccountant">
            <div class="mb-2 me-2 btn btn-primary" (click)="viewchange('add')">Add</div>
          </div>
        </div>

        <div class="row p-4">
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Branch Name</mat-label>
              <input matInput [(ngModel)]="corporatefilters.name" placeholder="Branch Name" #input>
            </mat-form-field>
          </div>

          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Billing Code</mat-label>
              <input matInput [(ngModel)]="corporatefilters.billingCode" placeholder="Billing Code" #input>
            </mat-form-field>
          </div>

          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>City</mat-label>
              <input matInput placeholder="Search City" [(ngModel)]="corporatefilters.cityid" [matAutocomplete]="autoCity">
              <mat-autocomplete #autoCity="matAutocomplete" [displayWith]="displayCityFn">
                <mat-option *ngFor="let city of (cities | async) | filter: corporatefilters.cityid" [value]="city">{{ city.name }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>




          <div class="col-md-1">
            <button class="btn btn-primary mt-3" (click)="find()">Find</button>
          </div>
          <div class="col-md-1">
            <button class="btn btn-secondary mt-3" (click)="reset()">Reset</button>
          </div>
        </div>

        <!-- <div class="container">
        <div class="row">
        <div class="">
        <div class="table-responsive">
          <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
          <thead>
          <tr>
          <th class="text-center">Name</th>
          <th class="text-center">Address</th>
          <th class="text-center">City</th>
          <th class="text-center">State</th>
          <th class="text-center">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let office of office_locations | async">
          <td class="text-center">
            <div>
            {{office.name}}
            </div>
          </td>
          <td class="text-center">
            <div>
            {{office.address}}
            </div>
          </td>
          <td class="text-center">
            <div>
            {{office.city.name}}
            </div>
          </td>
          <td class="text-center">
            <div>
            {{office.city.state_id.name}}
            </div>
          </td>

          <td class="text-center">
            <div role="group" class="btn-group-sm btn-group">
            <button class="btn-shadow btn btn-primary" (click)="viewchange('edit')"><i
            class="lnr-pencil"></i></button>

            </div>
          </td>
          </tr>
          </tbody>
          </table>
          <mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
          [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
          [pageIndex]="pageIndex" aria-label="Select page">
          </mat-paginator>
        </div>
        </div>
        </div>
        </div> -->
        <div class="table-responsive">
          <table class="w-100" mat-table [dataSource]="dataSource" matSort
                 (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="name">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by name">Name</th>
              <td class="text-center" mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>
            <ng-container matColumnDef="address">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by address" style="width: 26%;">Address</th>
              <td class="text-center" mat-cell *matCellDef="let element">{{element.address | slice:0:30 }}</td>
            </ng-container>
            <ng-container matColumnDef="billingcode">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by city">Billing Code</th>
              <td class="text-center" mat-cell *matCellDef="let element">
                {{element.billingCode}} </td>
            </ng-container>
            <ng-container matColumnDef="location">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by city">Location</th>
              <td class="text-center" mat-cell *matCellDef="let element">
                {{element.city.name}} , {{element.city.state_id.name}} , {{element.city.state_id.country.name}}</td>
            </ng-container>
            <ng-container matColumnDef="gstnumber">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by sate">GST Number</th>
              <td class="text-center" mat-cell *matCellDef="let element">
                {{element.gstNumber}} </td>
            </ng-container>
            <!-- <ng-container matColumnDef="start_time">
            <th class="text-center" mat-header-cell *matHeaderCellDef
            sortActionDescription="Sort by sate">Start Time</th>
            <td class="text-center" mat-cell *matCellDef="let element">
            {{element.from_time}} </td>
            </ng-container>
            <ng-container matColumnDef="end_time">
            <th class="text-center" mat-header-cell *matHeaderCellDef
            sortActionDescription="Sort by sate">End Time</th>
            <td class="text-center" mat-cell *matCellDef="let element">
            {{element.to_time}} </td>
            </ng-container>						 -->
            <ng-container matColumnDef="Active">
              <th mat-header-cell *matHeaderCellDef > Action </th>
              <td mat-cell *matCellDef="let element">
                <div role="group" class="btn-group-sm btn-group">
                  <button class="btn-shadow btn btn-primary" (click)="viewchange1('edit',element);getOfficeLocations"><i
                    class="lnr-pencil"></i></button>

                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'add'">
      <div class="card">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            Add Branch
          </div>

        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Name<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Branch Name" aria-label="Number" [(ngModel)]="new_office.name"
                       matInput>
              </mat-form-field>
            </div>
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Branch Code<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Branch Code" aria-label="text" [(ngModel)]="new_office.branch_code"
                       matInput maxlength="5">
              </mat-form-field>
            </div>
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Billing Code
                </mat-label>
                <input type="text" placeholder="Billing Code" aria-label="text" (input) ="billingCodeExisForOfficeCreate()" [(ngModel)]="new_office.billing_code"
                       matInput maxlength="5">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">

                <mat-label>Address<span class="text-danger">*</span></mat-label>
                <textarea  matInput placeholder="Address" [(ngModel)]="new_office.address"></textarea>
              </mat-form-field>
            </div>

            <div class="col-md-7">
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field appearance="standard">
                    <mat-label>
                      Country <span class="text-danger">*</span>
                    </mat-label>
                    <mat-select  [(ngModel)]="business_sel_country">
                      <mat-option value="">SELECT</mat-option>
                      <mat-option *ngFor="let con of countries | async" [value]="con.id" (click)="getBusinessStates()">
                        {{con.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field appearance="standard">
                    <mat-label>
                      State <span class="text-danger">*</span>
                    </mat-label>
                    <mat-select  [(ngModel)]="business_sel_state">
                      <mat-option value="">SELECT</mat-option>
                      <mat-option *ngFor="let sta of business_states | async" [value]="sta.id" (click)=" getBusinessCities()">
                        {{sta.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field appearance="standard">
                    <mat-label>
                      City <span class="text-danger">*</span>
                    </mat-label>
                    <mat-select [(ngModel)]="new_office.city.id">
                      <mat-option value="">SELECT</mat-option>
                      <mat-option *ngFor="let cit of business_cities | async" [value]="cit.id" (click)="getBusinessPincodes()">
                        {{cit.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  GST No.
                </mat-label>
                <input type="text" placeholder="GST" aria-label="Number" matInput #gst maxlength="15"
                       [(ngModel)]="new_office.gst_number" [formControl]="gstFormControl">
                <mat-hint class="text-danger fw-normal" *ngIf="gstFormControl.hasError('pattern')">Invalid
                  GST No.</mat-hint>
                <mat-hint align="end">{{gst.value.length}}/15
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  PAN No.
                </mat-label>
                <input type="text" placeholder="PAN" aria-label="Number" matInput #pan maxlength="10"
                       [(ngModel)]="new_office.pan_number" [formControl]="PanFormControl">
                <!-- <mat-hint class="text-danger fw-normal" *ngIf="PanFormControl.hasError('required')">PAN No. required</mat-hint> -->
                <mat-hint class="text-danger fw-normal" *ngIf="PanFormControl.hasError('pattern')">Invalid
                  PAN No.</mat-hint>
                <mat-hint align="end">{{pan.value.length}}/10
                </mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="card-footer">
            <div class="btn-actions-pane-right p-2">
              <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table'); refreshPage()">Cancel</div>
              <div class="mb-2 me-2 btn btn-primary" (click)="createOffice()">Add</div>
            </div>
          </div>
        </div>
      </div>
    </some-element>

    <some-element *ngSwitchCase="'edit'">
      <div class="card">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            Edit Branch
          </div>

        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Name<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Branch Name" aria-label="Number" matInput
                       [(ngModel)]="updateoffice.name">
              </mat-form-field>
            </div>
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Branch Code<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Branch Code" aria-label="text" matInput
                       [(ngModel)]="updateoffice.branchCode" maxlength="5">
              </mat-form-field>
            </div>
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Billing Code
                </mat-label>
                <input type="text" placeholder="Billing Code" aria-label="text"  (input)="billingCodeExistForOfficeEdit()" matInput
                       [(ngModel)]="updateoffice.billingCode" maxlength="5">
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">

                <mat-label>Address<span class="text-danger">*</span></mat-label>
                <textarea matInput [(ngModel)]="updateoffice.address" placeholder="Address"></textarea>
              </mat-form-field>
            </div>

            <div class="col-md-7">
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field appearance="standard">
                    <mat-label>
                      Country <span class="text-danger">*</span>
                    </mat-label>
                    <mat-select  [(ngModel)]="business_sel_country">
                      <!--                      <mat-option value="">SELECT</mat-option>-->
                      <mat-option *ngFor="let con of countries | async" [value]="con.id" (click)="getBusinessStates()">
                        {{con.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field appearance="standard">
                    <mat-label>
                      State <span class="text-danger">*</span>
                    </mat-label>
                    <mat-select  [(ngModel)]="business_sel_state">
                      <!--                      <mat-option value="">SELECT</mat-option>-->
                      <mat-option *ngFor="let sta of business_states | async" [value]="sta.id" (click)=" getBusinessCities()">
                        {{sta.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field appearance="standard">
                    <mat-label>
                      City <span class="text-danger">*</span>
                    </mat-label>
                    <mat-select [(ngModel)]="new_office.city.id">
                      <!--                      <mat-option value="">SELECT</mat-option>-->
                      <mat-option *ngFor="let cit of business_cities | async" [value]="cit.id" (click)="getBusinessPincodes()">
                        {{cit.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  GST No.
                </mat-label>
                <input type="text" placeholder="GST" aria-label="Number" matInput #gst maxlength="15"
                       [(ngModel)]="updateoffice.gstNumber" [formControl]="gstFormControl">
                <mat-hint class="text-danger fw-normal" *ngIf="gstFormControl.hasError('pattern')">Invalid
                  GST No.</mat-hint>
                <mat-hint align="end">{{gst.value.length}}/15
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  PAN No.
                </mat-label>
                <input type="text" placeholder="PAN" aria-label="Number" matInput #pan maxlength="10"
                       [(ngModel)]="updateoffice.panNumber" [formControl]="PanFormControl">
                <!-- <mat-hint class="text-danger fw-normal" *ngIf="PanFormControl.hasError('required')">PAN No. required</mat-hint> -->
                <mat-hint class="text-danger fw-normal" *ngIf="PanFormControl.hasError('pattern')">Invalid
                  PAN No.</mat-hint>
                <mat-hint align="end">{{pan.value.length}}/10
                </mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="card-footer">
            <div class="btn-actions-pane-right p-2">
              <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');refreshPage();getOfficeLocations()">Cancel</div>
              <div class="mb-2 me-2 btn btn-primary" (click)="UpdateOffice()">Save</div>
            </div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'view'">
      <div class="card">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            View Office Locations
          </div>

        </div>

        <div class="container m-2">
          <div class="row ">
            <div class="col-md-3 fw-bold">Name</div>
            <div class="col-md-5">ICL HYD</div>
          </div>
          <div class="row ">
            <div class="col-md-3 fw-bold">Address</div>
            <div class="col-md-5"> Begampet </div>
          </div>
          <div class="row ">
            <div class="col-md-3 fw-bold">Country </div>
            <div class="col-md-5"> India </div>
          </div>
          <div class="row ">
            <div class="col-md-3 fw-bold">State </div>
            <div class="col-md-5"> Telengana </div>
          </div>
          <div class="row ">
            <div class="col-md-3 fw-bold">City</div>
            <div class="col-md-5"> HYD </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Back</div>
          </div>
        </div>
      </div>
    </some-element>
  </container-element>
</div>
