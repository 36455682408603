import {Component, OnInit, ViewChild} from '@angular/core';
import {API_URL} from 'Global_API_URL.js';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import Swal from 'sweetalert2';
import {AuthService} from '../../auth.service';


@Component({
  selector: 'app-operationaltimingspanel',
  templateUrl: './operationaltimingspanel.component.html',
  styleUrls: ['./operationaltimingspanel.component.sass'],
})
export class OperationaltimingspanelComponent implements OnInit {
  OperationalTimingsList = null;
  constructor(private _liveAnnouncer: LiveAnnouncer, private authService : AuthService) {
    this.authService.getAuthToken();
  }

  pageview = 'table';
  // operationaltimings2 = this.getOperationalTimings();

  newoperationaltimings = {
    id: null,
    type: null,
    fromTime: null,
    toTime: null,
    isActive: null,
  }

  viewchange(val) {
    this.pageview = val;
    this.newoperationaltimings = {
      id: null,
      type: null,
      fromTime: null,
      toTime: null,
      isActive: 1,
    };
  }

  viewchange1(val, time) {
    this.newoperationaltimings.id = time.id;
    this.newoperationaltimings.type = time.type;
    this.newoperationaltimings.fromTime = time.fromTime;
    this.newoperationaltimings.toTime = time.toTime;
    this.newoperationaltimings.isActive = time.isActive;
    this.pageview = val;
  }

  ngOnInit(): void {
    this.getOperationalTimings();
  }

  viewoperationaltimings = {
    type: null,
    fromTime: null,
    toTime: null,
    isActive: null,
  }

  viewsection(val, time) {
    this.viewoperationaltimings.type = time.type;
    this.viewoperationaltimings.fromTime = time.fromTime;
    this.viewoperationaltimings.toTime = time.toTime;
    this.viewoperationaltimings.isActive = time.isActive;
    this.pageview = val;
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  showFirstLastButtons;

  filterParams='';
  pagenation= {
    col_name: 'id',
    page_size: 10,
    page_no: 0,
    sort_dir: 'ASC',
  }

  pageSizeOptions=[10, 30];

  tablefilter() {
    this.pagenation.page_no=0;
    this.pagenation.sort_dir='ASC';
    this.getOperationalTimings();
  }
  filter() {
    this.filterParams='?';
    // this.filterParams= this.filterParams+"page_no="+this.pagenation.page_no+"&page_size="+this.pagenation.page_size+"&col_name="+this.pagenation.col_name+"&sort_dir="+this.pagenation.sort_dir
    return this.filterParams;
  }
  handlePageEvent(event: PageEvent) {
    this.pagenation.page_no = event.pageIndex;
    this.pagenation.page_size=event.pageSize;
    this.getOperationalTimings();
  }
  displayedColumns = ['type', 'from_time', 'to_time', 'Active']

  dataSource
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  array=[]
  async getOperationalTimings() {
    const TOKEN = this.authService.getAuthToken();
    // console.log(API_URL)
    const api = '/operational_timings2';
    const params = this.filter();
    await fetch(API_URL + api + params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          if (json.status == 400) {
            this.OperationalTimingsList = [];
          } else {
            this.OperationalTimingsList = json.data;
            this.array = Array.from(json.data);
            this.dataSource = new MatTableDataSource(this.array);
            // console.log(json.data)
            // this.paginator =
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        });
    return this.OperationalTimingsList;
    // return loads;
  }

  async editOperationalTimings() {
    const TOKEN = this.authService.getAuthToken();
    const users={
      'id': this.newoperationaltimings.id,
      'type': this.newoperationaltimings.type,
      'from_time': this.newoperationaltimings.fromTime,
      'to_time': this.newoperationaltimings.toTime,
      'is_active': 1,
    };
    await fetch(API_URL +'/editoperationaltimings/'+this.newoperationaltimings.id, {
      method: 'POST',
      body: JSON.stringify(users),
      headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
        .then((response) => response.json())
        .then((result) =>{
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Updated successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.viewchange('table');
          this.getOperationalTimings();
        })
        .catch((error) => console.error('error', error));
  }
}
