<div class="main-card mb-3 card">
    <div class="card-header">
      <div class="card-header-title font-size-lg text-capitalize fw-normal">
        Notifications
      </div>
      <div class="btn-actions-pane-right p-2">
        <a href="notifications/sendnotification" class="mb-2 me-2 btn btn-primary">Send Notification</a>
        <!-- <button type="button" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm m-1">
          Actions Menu
          <span class="ps-2 align-middle opactiy-7">
            <i class="fa fa-angle-down"></i>
          </span>
        </button> -->
      </div>
    </div>
    <!-- <div class="row">
      <div class="float-right">
        <button type="button" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm m-1">
          Filters
          <span class="ps-2 align-middle opactiy-7">
            <i class="fa fa-angle-down"></i>
          </span>
        </button>
      </div>
    </div> -->
    <div class="table-responsive">
      <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
        <thead>
          <tr>
            <th class="text-center">#Consignment Number</th>
            <!-- <th class="text-center">Avatar</th> -->
            <th class="text-center">Flight Number</th>
            <th class="text-center">AWB Number</th>
            <th class="text-center">Issue Raised At</th>
            <th class="text-center">Reason</th>
          </tr>
        </thead>
        <tbody>
         <tr>
            <td class="text-center">852314</td>
            <td class="text-center">FK123410</td>
            <td class="text-center"><a [routerLink]="">9845126</a></td>
            <td class="text-center">12 Dec 2022 11:45 AM</td>
            <td class="text-center">
                Consignment Delayed
            </td>
         </tr>
        </tbody>
      </table>
    </div>
    
    <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="length"
    [pageSize]="pageSize"
    [showFirstLastButtons]="showFirstLastButtons"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="pageIndex"
    aria-label="Select page">
</mat-paginator>
  </div>