import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Cities, States} from './../../golbalInterfaces';
import {API_URL} from '../../../../../Global_API_URL';
import Swal from 'sweetalert2';
import {getCities, getCitiesById, getCountries, getPincodesById, getStates, getStatesById,getDropDownCities} from '../../getCities.js';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {saveAs} from 'file-saver';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialog} from '../../Components/confirmDialog/confirmDialog.component';
import {AuthService} from '../../../auth.service';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {HttpService} from '../../../services/http.service';
import {Router} from "@angular/router";

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-representative-customer',
  templateUrl: './representative-customer.component.html',
  styleUrls: ['./representative-customer.component.sass'],
})
export class RepresentativeCustomerComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };


  constructor(private router: Router, private matDialog: MatDialog, private authService: AuthService, private httpService: HttpService) {
    this.TOKEN = this.authService.getAuthToken();
  }

  async ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name == 'Admin') {
      this.displaytoAdmin = true;
    } else if (this.userDetails.userTypes[0].name == 'Accountant') {
      this.displaytoAccountant = true;
    } else {
      this.displaytoAdmin = false;
    }
    this.filtersrep();
    this.states = await getStates();
    this.individuallist = this.getRepIndividualList();
    this.companyNames = this.getCompanyList();
  }


  pageview = 'table';
  customer_with_id;
  user_id = {
    id: null,
    firstName: '',
    middleName: '',
    lastName: '',
    avatarUrl: '',
    email: '',
    phone: '',
    whatsapp: '',
    designation: null,
    billingAddress: {
      pincode: {
        pin_number: ''
      },
      address: ''
    },
    employmentNo: null,
    isSuperUser: false,
    userName: '',
    passwordHash: null,
    lastLoggedInAt: null,
    lastLoggedInIp: null,
    isActive: true,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
    // userType: 5,
    // userType: [{id : 5}],
    // customerType: 2, // here 2 is for buisness user
    orgCustomerId: {id: null},
    aadhaarNumber: null,
    drivingLicense: null,
    panNumber: null,
  }
  indiviudal_user = {
    id: null,
    firstName: '',
    middleName: '',
    lastName: '',
    avatarUrl: '',
    email: '',
    phone: '',
    whatsapp: '',
    designation: null,
    billingAddress: {
      pincode: {
        pin_number: ''
      },
      address: ''
    },
    employmentNo: null,
    isSuperUser: false,
    userName: '',
    passwordHash: null,
    lastLoggedInAt: null,
    lastLoggedInIp: null,
    isActive: true,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
    // userType: 5,
    // userType: [{id : 5}],
    // customerType: 2, // here 2 is for buisness user
    orgCustomerId: {id: null},
    aadhaarNumber: null,
    drivingLicense: null,
    panNumber: null,
  }

  customer_shipper = {
    id: null,
    firstName: '',
    address1: '',
    city1: '',
    creditPeriod: '',
    credit_value: null,
    email1: '',
    zipCode: '',
    isCreditExtended: null,
    isKycApproved: null,
    kycApprovedAt: null,
    lastName: '',
    phone1: '',
    customerTypeId: 2,
    kycApprovedBy: null,
    isBusinessAccount: true,
    pan: '',
    gst: '',
    Id_type: null,
    Id_value: '',
    otp_varified: null,
  }
  user_cities
  user_states
  user_pincodes
  user_countries
  user_sel_state
  user_sel_country
  user_sel_pincodes

  business_cities
  business_states
  business_pincodes = []
  business_sel_country
  business_sel_state


  // company_name
  states = getStates();
  countries = getCountries();
  cities = getDropDownCities();
  companyNames
  filterUsersList = []
  displaytoAdmin = false;
  displaytoAccountant = false;
  userDetails;
  TOKEN;

  cityIdRep2;




  async getCompanyList() {
    const TOKEN = this.authService.getAuthToken();
    let users;
    await fetch(API_URL + '/totalcustomers',
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })

      // Converting received data to JSON
      .then((response) => response.json())
      .then((json) => {
        users = json;
      });
    return users;
  }


  filterParams = '';
  pageSizeOptions = [10, 30];
  pagenation = {
    col_name: 'id',
    page_size: 10,
    page_no: 0,
    sort_dir: 'DESC',
  }
  individuallist = null;


  filter() {
    this.filterParams = '?';
    if (this.repfilters.firstName != '') {
      this.filterParams = this.filterParams + 'firstName=' + this.repfilters.firstName + '&';
    }
    if (this.repfilters.cityid != '' && this.repfilters.cityid != null) {
      this.filterParams = this.filterParams + 'cityid=' + this.repfilters.cityid.id + '&';
    }
    if (this.repfilters.stateid != '' && this.repfilters.stateid != null) {
      this.filterParams = this.filterParams + 'stateid=' + this.repfilters.stateid.id + '&';
    }
    if (this.repfilters.email != null && this.repfilters.email != '') {
      this.filterParams = this.filterParams + 'email=' + this.repfilters.email + '&';
    }
    if (this.repfilters.phone != null && this.repfilters.phone != '') {
      this.filterParams = this.filterParams + 'phone=' + this.repfilters.phone + '&';
    }
    this.filterParams = this.filterParams + 'page_no=' + this.pagenation.page_no + '&page_size=' + this.pagenation.page_size + '&col_name=' + this.pagenation.col_name + '&sort_dir=' + this.pagenation.sort_dir;
    return this.filterParams;
  }


  async getRepIndividualList() {
    const TOKEN = this.authService.getAuthToken();
    const api = '/RCustomer_User';
    const params = this.filter();
    await fetch(API_URL + api + params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
      // Converting received data to JSON
      .then((response) => response.json())
      .then((json) => {
        this.individuallist = json;
        // this.companyNames = this.getCompanyList();
      });
    return this.individuallist;
  }

  existingEmail;

  viewchange(val, user) {
    if (val == 'reset') {
      this.pageview = val;
      this.user_id = user;
      this.userid = user;
      this.resetForm();
    } else {
      this.pageview = val;
      // this.user_id = user;
      this.userid = user;
      this.existingEmail = user.email;


      this.user_id.id = user.id;
      this.user_id.orgCustomerId.id = user.orgCustomerId.id;
      this.user_id.firstName = user.firstName;
      this.user_id.middleName = user.middleName;
      this.user_id.lastName = user.lastName;
      this.user_id.email = user.email;
      this.user_id.phone = user.phone;
      this.user_id.whatsapp = user.whatsapp;
      this.user_id.billingAddress.address = user.billingAddress.address;
      this.business_sel_country = user.billingAddress?.pincodeId?.cityId?.state_id.country.id;
      this.business_sel_state = user.billingAddress?.pincodeId?.cityId?.state_id.id;
      this.cityIdRep2 = user.billingAddress?.pincodeId?.cityId?.id;
      this.user_id.billingAddress.pincode.pin_number = user.billingAddress.pincodeId.pinNumber;
      this.user_id.aadhaarNumber = user?.aadhaarNumber;
      this.user_id.drivingLicense = user?.drivingLicense;
      this.user_id.panNumber = user?.panNumber;


      this.business_states = getStatesById(this.business_sel_country);
      this.business_cities = getCitiesById(this.business_sel_state);
      this.business_pincodes = getPincodesById(this.cityIdRep2);
      // this.indiviudal_user.orgCustomerId = "";
    }
  }

  city = null;

  repfilters: any = {
    firstName: '',
    email: null,
    phone: null,
    stateid: '',
    cityid: '',

  }

  displayedColumns = ['firstName', 'companyName', 'email', 'phone', 'stateid', 'cityid', 'Active']

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  retail = null;

  dataSource: any;

  find() {
    this.filtersrep();
  }

  reset() {
    this.repfilters.cityid = '';
    this.repfilters.email = '';
    this.repfilters.firstName = '';
    this.repfilters.phone = '';
    this.repfilters.stateid = '';
    this.search = '';
    this.search1 = '';
    this.search2 = '';
    this.filtersrep();
  }

  async filtersrep() {
    const TOKEN = this.authService.getAuthToken();
    const params = this.filter();
    this.loading = true;
    const api = '/appusersfilters';
    await fetch(API_URL + api + params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })

      // Converting received data to JSON
      .then((response) => response.json())
      .then((json) => {
        if (json.status == 'BAD_REQUEST') {
          this.retail = [];
        } else {
          this.retail = json.data;
          this.filterUsersList = json.data.reverse();
          // this.dataSource = new MatTableDataSource(json.data.reverse());
          this.dataSource = new MatTableDataSource(this.filterUsersList);
          // this.dataSource = new MatTableDataSource(this.retail.reverse());
          // this.paginator =

          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      });
    this.loading = false;
    return this.retail;
    // return loads;
  }

  formatDate(date) {
    const date2 = new Date(date);
    return (
      [
        date2.getFullYear(),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date2.getHours()),
        this.padTo2Digits(date2.getMinutes()),
      ].join(':')
    );
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }


  exportToCSV() {
    const csvData = [];
    const headers = ['Customer Name', 'Representative Name', 'Representative Address', 'City', 'State', 'Country', 'GST', 'PAN', 'Aadhar No.', 'Last Login At', 'Is Active', 'Phone No.', 'Email Id'];
    csvData.push(headers);
    this.dataSource.filteredData.forEach((row) => {
      const last_logged_in_at = this.formatDate(row.lastLoggedInAt);
      let is_active;
      let pan;
      let gst;
      if (row.orgCustomerId.gst != null && row.orgCustomerId.gst != '') {
        gst = row.orgCustomerId.gst;
      } else {
        gst = '-';
      }
      if (row.orgCustomerId.pan != null && row.orgCustomerId.pan != '') {
        pan = row.orgCustomerId.pan;
      } else {
        pan = '-';
      }
      if (row.isActive == false) {
        is_active = 'No';
      } else {
        is_active = 'Yes';
      }
      const data = [`"${row.orgCustomerId?.firstName}"`, `"${row.firstName}"`, `"${row?.billingAddress?.address}"`, `"${row?.billingAddress?.pincodeId?.cityId?.name}"`, `"${row?.billingAddress?.pincodeId?.cityId?.state_id?.name}"`, `"${row?.billingAddress?.pincodeId?.cityId?.state_id?.country?.name}"`, gst, pan,
        row.verificationId, last_logged_in_at, is_active, row.phone, row.email];
      csvData.push(data);
    });

    const csv = csvData.map((e) => e.join(',')).join('\n');
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
    saveAs(blob, 'RepresentativeDetails.csv');
  }

  async Deletedata(id) {
    const TOKEN = this.authService.getAuthToken();
    const data = JSON.stringify({
      'isActive': 0,
    });
    await fetch(API_URL + '/app_users_isactive/' + id,
      {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: data,
      })

      // Converting received data to JSON
      .then((response) => response.text());
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'User Deactivated Successfully',

      timer: 5000,
    });
    this.filtersrep()
      .catch((error) => console.error('error', error));
  }

  DeleteUser(user) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to deactivate ' + user.firstName + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.loading = true;
        this.Deletedata(user.id);
      }
    });
  }

  async Restoredata(id) {
    const TOKEN = this.authService.getAuthToken();
    const data = JSON.stringify({
      'isActive': 1,
    });
    await fetch(API_URL + '/app_users_isactive/' + id,
      {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: data,
      })

      // Converting received data to JSON
      .then((response) => response.text());
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'User activated Successfully',

      timer: 5000,
    });
    this.filtersrep()
      .catch((error) => console.error('error', error));
  }

  ActivateUser(user) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to activate ' + user.firstName + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.loading = true;
        this.Restoredata(user.id);
      }
    });
  }

  userid: string;
  passwordFlag = false;
  retypePassword: string | null = null;
  newpassword: string | null = null;

  checkPassword(): void {
    if (this.newpassword !== this.retypePassword) {
      this.passwordFlag = true;
    } else {
      this.passwordFlag = false;
    }
  }

  newPassLengthCheck = false;

  checkNewPassword(): void {
    this.newPassLengthCheck = this.newpassword.length < 6;
  }

  resetPassword(): Promise<void> {
    this.checkPassword();
    if (this.newpassword === null || this.newpassword === '' || this.newpassword.trim() === '') {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter New Password',
        timer: 5000,
      });
      return;
    }

    if (this.retypePassword === null || this.retypePassword === '' || this.retypePassword.trim() === '') {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please re-enter the new password for confirmation',
        timer: 5000,
      });
      return;
    } else if (this.newpassword?.length < 6) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Password should have minimum of 6 characters',
        timer: 5000,
      });
      return;
    } else if (this.passwordFlag) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'New Password and Confirm Password should match',
        timer: 5000,
      });
      return;
    } else {
      this.loading = true;
      const requestBody = {
        email: this.userid,
        newpassword: this.newpassword,
      };

      this.httpService.post(API_URL + '/resetpassword', requestBody, null, null)
        .subscribe((result) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Password Changed Successfully',
              timer: 5000,
            });
            this.viewchange('table', this.user_id.id);
            this.filtersrep();
            this.resetForm();
            this.loading = false;
          }, (error) => {
            this.loading = false;
            console.error('Error Resetting Password:', error);
          },
        );
    }
  }

  search

  searchByCons(con) {
    this.dataSource = new MatTableDataSource(this.filterUsersList.filter((cons) => cons.email.includes(con)));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search1

  searchByCons1(con) {
    this.dataSource = new MatTableDataSource(this.filterUsersList.filter((cons) => cons.phone.includes(con)));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search2

  searchByCons2(con) {
    this.dataSource = new MatTableDataSource(this.filterUsersList.filter((cons) => cons.firstName.toLowerCase().includes(con)));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  getCities(id) {
    this.cities = getCitiesById(id);
  }

  displayStateFn(state: States): String {
    return state?.name ? state?.name : '';
  }

  displayCityFn(city: Cities): String {
    return city?.name ? city?.name : '';
  }


  resetForm() {
    this.retypePassword = null;
    this.newpassword = null;
    this.passwordFlag = null;
  }


  gotoRepresentativeCreate() {
    this.router.navigate(["/representativeCreate"]);
  }

  gotoRepresentativeEdit(id) {
    this.router.navigate(["/representativeEdit", id]);
  }

}

