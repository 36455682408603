import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse,
} from '@angular/common/http';
import {BehaviorSubject, from, Observable, throwError} from 'rxjs';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {TokenService} from './services/token.service';


@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {
  isRefreshing = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
      null,
  );

  // constructor(private authService : AuthService) {}

  constructor(private authService : AuthService,
              // private localStorageService: LocalStorageService,
              // private toast: ToastrService,
              // @Inject(DOCUMENT) private document: Document,
              // public signupService: SignupService,
              private router: Router,
              private tokenService: TokenService,
  ) {}

  // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  //   let TOKEN = this.authService.getAuthToken();
  //   request = request.clone({
  //     setHeaders: {
  //       "Access-Control-Allow-Origin" : "*",
  //       'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  //       'Access-Control-Allow-Headers' : 'Origin, Content-Type, X-Auth-Token',
  //       Authorization: "Bearer " + TOKEN,
  //     }
  //   })
  //   return next.handle(request);
  // }

  intercept(
      request: HttpRequest<any>,
      next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.authService.getAuthToken()) {
      request = this.addTokenHeader(request, this.tokenService.getToken());
    }

    return next.handle(request).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && !request.url.includes('refreshAuth') && error.status === 401) {
            return this.handle401Error(request, next);
          } else {
            return throwError(error);
          }
        }),
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      // return this.authService.getAccessFromRefreshToken().pipe(
      //   switchMap((token: any) => {
      //     this.isRefreshing = false;
      //     this.refreshTokenSubject.next(token.accessToken);
      //     this.tokenService.saveToken(token.accessToken)
      //     return next.handle(this.addTokenHeader(request, token.accessToken));
      //   }),
      //   catchError((err) => {
      //     this.isRefreshing = false;
      //     this.authService.logout();
      //     return throwError(err);
      //   })
      // );

      return this.authService.getAccessFromRefreshToken().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.tokenService.saveToken(token.accessToken);
            this.refreshTokenSubject.next(token.accessToken);
            return next.handle(this.addTokenHeader(request, token.accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.authService.logout();
            return throwError(err);
          }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
          filter((token) => token != null),
          take(1),
          switchMap((jwt) => {
            return next.handle(this.addTokenHeader(request, jwt));
          }),
      );
    }
  }
}
