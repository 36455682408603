import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {getCountries} from '../../getCities.js';
import Swal from 'sweetalert2';
import {API_URL} from '../../../../../Global_API_URL';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {AuthService} from '../../../auth.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialog} from '../../Components/confirmDialog/confirmDialog.component';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';
@Component({
  selector: 'app-table-countries',
  templateUrl: './table-countries.component.html',
  styleUrls: ['./table-countries.component.sass'],
})

export class TableCountriesComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  pageview = 'table';
  country;
  countries = this.getCountries();
  showFirstLastButtons;
  pageNo: number = 0;
  pageSizeOptions = [5, 10];
  private _liveAnnouncer: any;
  userDetails;
  displaytoAccountant=false;
  constructor(private authService: AuthService, private matDialog: MatDialog) {
    this.authService.getAuthToken();
  }

  ngOnInit(): void {
    this.getCountries();
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name == 'Accountant') {
      this.displaytoAccountant=true;
    }
  }


  viewchange(val) {
    this.pageview = val;
    this.refreshData();
  }
  viewchange1(val, con) {
    this.UpdateCountry = {
      id: con.id,
      name: con.name,
      is_active: con.is_active,
      currency: con.currency,
      code: con.code,
    };
    this.pageview = val;
    this.refreshData();
  }

  viewcountry = {
    name: '',
    currency: '',
    code: '',
  }

  viewsection(val, con) {
    this.viewcountry.name = con.name,
    this.viewcountry.currency = con.currency,
    this.viewcountry.code = con.code;
    this.pageview = val;
    this.refreshData();
  }

  addCountry = {
    name: '',
    is_active: true,
    currency: '',
    code: '',
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  async getCountries() {
    const TOKEN = this.authService.getAuthToken();
    this.loading = true;
    const countries = [];
    await fetch(API_URL + '/countries', {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })

    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          if (json.status == 400) {
            this.country = [];
          } else {
            this.country = json.data;
            this.dataSource = new MatTableDataSource(this.country);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.loading = false;
          }
        });

    return this.country;
  }

  async Createcountry() {
    const TOKEN = this.authService.getAuthToken();
    const country = {
      'name': this.addCountry.name,
      'is_active': this.addCountry.is_active,
      'currency': this.addCountry.currency,
      'code': this.addCountry.code,
    };
    if (this.addCountry.name == null || this.addCountry.name == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Country Name',
        timer: 5000,

      });
    } else if (this.addCountry.currency == null || this.addCountry.currency == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Currency Type',
        timer: 5000,

      });
    } else if (this.addCountry.code == null || this.addCountry.code == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Country Code',
        timer: 5000,

      });
    } else {
      await fetch(API_URL + '/postCountry', {
        method: 'POST',
        body: JSON.stringify(country),
        headers: {
          'Authorization': 'Bearer ' + TOKEN,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      })
          .then((response) => response.json())
          .then((result) => {
          // console.log(result)

            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Country created successfully',
              showConfirmButton: false,
              timer: 5000,
            });
            this.countries = getCountries();
            this.viewchange('table');
            this.addCountry.name = '';
            this.addCountry.currency = '';
            this.addCountry.code = '';
          })
          .catch((error) => console.error('error', error));
    }
  }

  UpdateCountry = {
    id: null,
    name: '',
    is_active: null,
    currency: '',
    code: '',
  }

  async Updatecountry() {
    const TOKEN = this.authService.getAuthToken();
    const country = {
      'name': this.UpdateCountry.name,
      'is_active': this.UpdateCountry.is_active,
      'currency': this.UpdateCountry.currency,
      'code': this.UpdateCountry.code,
    };
    if (this.UpdateCountry.name == null || this.UpdateCountry.name == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Country Name',
        timer: 5000,

      });
    } else if (this.UpdateCountry.currency == null || this.UpdateCountry.currency == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Currency Type',
        timer: 5000,

      });
    } else if (this.UpdateCountry.code == null || this.UpdateCountry.code == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Country Code',
        timer: 5000,

      });
    } else {
      await fetch(API_URL + '/updateCountry' + '?' + 'country_id=' + this.UpdateCountry.id, {
        method: 'PATCH',
        body: JSON.stringify(country),
        headers: {
          'Authorization': 'Bearer ' + TOKEN,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      })
          .then((response) => response.json())
          .then((result) => {
          // console.log(result)

            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Country Updated successfully',
              showConfirmButton: false,
              timer: 5000,
            });
            this.countries = getCountries();
            this.viewchange('table');
            this.UpdateCountry.name = '';
            this.UpdateCountry.currency = '';
            this.UpdateCountry.code = '';
          })
          .catch((error) => console.error('error', error));
    }
  }

  DeactivateCountry(country) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to deactivate ' + country.name + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.Deletedata(country.id);
      }
    });
  }

  async Deletedata(id) {
    const TOKEN = this.authService.getAuthToken();
    // console.log(id)
    this.loading = true;
    const data = JSON.stringify({
      'is_active': 0,
    });
    await fetch(API_URL + '/country_isactive/' + id,
        {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
          body: data,
        })

    // Converting received data to JSON
        .then((response) => response.text());
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Country Deactivated Successfully',
      showConfirmButton: false,
      timer: 5000,
    }).then(() => {
      this.loading = false;
      this.getCountries().catch((error) => console.error('error', error));
      window.location.reload(); // Reload the window
    });
  }

  async Restoredata(id) {
    const TOKEN = this.authService.getAuthToken();
    // console.log(id)
    this.loading = true;
    const data = JSON.stringify({
      'is_active': 1,
    });
    await fetch(API_URL + '/country_isactive/' + id,
        {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
          body: data,
        })

    // Converting received data to JSON
        .then((response) => response.text());
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Country Activated Successfully',
      showConfirmButton: false,
      timer: 5000,
    }).then(() => {
      this.loading = false;
      this.getCountries().catch((error) => console.error('error', error));
      window.location.reload(); // Reload the window
    });
  }

  ActivateCountry(country) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to activate ' + country.name + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.Restoredata(country.id);
      }
    });
  }

  refreshData(): void {
    // Call your method to fetch updated data (modify based on your actual implementation)
    this.getCountries();

    // Optionally, reset pagination state if needed
    this.pageNo = 0;
  }
}
