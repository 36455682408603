<div *ngIf="loading" class="loader-container">
  <ngx-loading [show]="loading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>

<mat-card style="padding: 20px;">
  <div class="card-header-title font-size-lg text-capitalize fw-Bold">
    <a (click)="goBackToRepresentativeTable()"><i class="fa fa-arrow-left"
                                                  style="cursor: pointer"></i></a>&nbsp;<span
    style="color: blue; font-weight: bold; font-size: larger">ADD REPRESENTATIVE</span>
  </div>

  <mat-card-content>

    <div style="margin-left: 35px">
      <table class="compTable">
        <tr>
          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>Company Name<span class="text-danger">*</span></mat-label>
              <mat-select matNativeControl id="org_customer_id" [(ngModel)]="customer_shipper.orgCustomerId.id">
                <mat-option *ngFor="let cust of customerVerification.companyNames" [value]="cust.id">
                  {{cust.firstName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>

        <tr>
          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                First Name<span class="text-danger">*</span>
              </mat-label>
              <input type="text" placeholder="First Name" aria-label="Number" matInput
                     [(ngModel)]="customer_shipper.firstName">
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Middle Name
              </mat-label>
              <input type="text" placeholder="Middle Name" aria-label="Number" matInput
                     [(ngModel)]="customer_shipper.middleName">
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Last Name<span class="text-danger">*</span>
              </mat-label>
              <input type="text" placeholder="Last Name" aria-label="Number" matInput
                     maxlength="10" [(ngModel)]="customer_shipper.lastName">
            </mat-form-field>
          </td>

        </tr>

        <tr>
          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Email<span class="text-danger">*</span>
              </mat-label>
              <input type="text" placeholder="Email" aria-label="Number" matInput
                     [(ngModel)]="customer_shipper.email" (blur)="customerVerification.emailExistAppuser(customer_shipper.email)" [formControl]="customerVerification.emailFormControl">
              <mat-hint class="text-danger fw-normal" *ngIf="customerVerification.emailFormControl.hasError('pattern')">
                Invalid email address
              </mat-hint>
              <mat-hint class="text-danger fw-normal" *ngIf="customerVerification.emailExist">
                Email Already Exists
              </mat-hint>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Phone No.<span class="text-danger">*</span>
              </mat-label>
              <input type="text" placeholder="Phone No." #phone
                     aria-label="Number"
                     matInput
                     maxlength="10" [(ngModel)]="customer_shipper.phone" (keypress)="customerVerification.validateInputInt($event)">
              <mat-hint align="end">{{ phone.value.length }}/10
              </mat-hint>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Whatsapp No.
              </mat-label>
              <input type="text" placeholder="Whatsapp No." #whatsapp
                     aria-label="Number"
                     matInput
                     maxlength="10" [(ngModel)]="customer_shipper.whatsapp" (keypress)="customerVerification.validateInputInt($event)">
              <mat-hint align="end">{{ whatsapp.value.length }}/10
              </mat-hint>
            </mat-form-field>
          </td>


        </tr>

        <tr>
          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Address<span class="text-danger">*</span>
              </mat-label>
              <textarea placeholder="Address" matInput style="height: 15px"
                        [(ngModel)]="customer_shipper.billingAddress.address"></textarea>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Country<span class="text-danger">*</span>
              </mat-label>
              <mat-select matSelect matNativeControl [(ngModel)]="customerVerification.user_sel_country">
                <mat-option *ngFor="let con of customerVerification.countries |async" [value]="con.id"
                            (click)="customerVerification.getIndividaulStates(customer_shipper, 'representative')">
                  {{ con.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                State<span class="text-danger">*</span>
              </mat-label>
              <mat-select matNativeControl [(ngModel)]="customerVerification.user_sel_state">
                <mat-option *ngFor="let sta of customerVerification.user_states |async" [value]="sta.id"
                            (click)="customerVerification.getIndividaulCities(customer_shipper, 'representative')">
                  {{ sta.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>

        <tr>
          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                City<span class="text-danger">*</span>
              </mat-label>
              <mat-select matNativeControl [(ngModel)]="customerVerification.user_sel_city">
                <mat-option *ngFor="let cit of customerVerification.user_cities |async" [value]="cit.id"
                            (click)="customerVerification.getIndividaulPincodes(customer_shipper, 'representative')">
                  {{ cit.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Zip Code<span class="text-danger">*</span>
              </mat-label>
              <mat-select matNativeControl [(ngModel)]="customer_shipper.billingAddress.pincode.pin_number">
                <mat-option *ngFor="let cit of customerVerification.user_pincodes |async" [value]="cit.pinNumber">
                  {{ cit.pinNumber }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Aadhaar No.
              </mat-label>
              <input type="text" placeholder="Aadhaar No." #aadhaar aria-label="Number" matInput
                     maxlength="12" [(ngModel)]="customer_shipper.aadhaarNumber" (keypress)="customerVerification.validateInputInt($event)">
              <mat-hint align="end">{{ aadhaar.value.length }}/12
              </mat-hint>
            </mat-form-field>
          </td>

        </tr>

        <tr>
          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Driving License
              </mat-label>
              <input type="text" placeholder="Driving License" #license aria-label="Number" matInput
                     maxlength="16" [(ngModel)]="customer_shipper.drivingLicense">
              <mat-hint align="end">{{ license.value.length }}/16
              </mat-hint>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                PAN No.
              </mat-label>
              <input type="text" placeholder="PAN No." #pan aria-label="Number" matInput
                     maxlength="10" [(ngModel)]="customer_shipper.panNumber" (input)="transformPANoUpperCaseForCreate()">
              <mat-hint align="end">{{ pan.value.length }}/10
              </mat-hint>
            </mat-form-field>
          </td>
        </tr>

      </table>
    </div>

  </mat-card-content>

  <mat-card-actions align="end">
    <button mat-button class="btn btn-danger" (click)="goBackToRepresentativeTable()"> Cancel </button>
    <button mat-button class="btn btn-primary" (click)="createRepresentative()"> Create </button>
  </mat-card-actions>
</mat-card>
