import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ngxLoadingAnimationTypes} from "ngx-loading";
import {MatSort, Sort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {ContractService} from "./contract.service";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Router} from "@angular/router";

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.sass']
})
export class ContractsComponent implements OnInit {

  constructor(private service: ContractService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.paginatorDetails = this.service.getInitialPaginatorDetails()
    await this.getAllContracts()

  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageEvent: PageEvent;

  public loadingTemplate: TemplateRef<any>;
  public readonly ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  isLoading: boolean = false;

  paginatorDetails;

  async getAllContracts() {
    this.isLoading = true;
    const response = await this.service.getAllContracts(this.paginatorDetails.pageNo, this.paginatorDetails.pageSize, 'id', 'DESC')
    this.contractsTableDataSource = new MatTableDataSource(response);
    this.paginatorDetails = this.service.getPaginatorDetails()
    this.contractsTableDataSource.paginator = this.paginator;
    this.contractsTableDataSource.sort = this.sort;
    this.isLoading = false;
  }



  contractsTableDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();




  displayedColumns: string[] = ['S.No.', 'customerName', 'description', 'validFrom', 'validUpto', 'prices'];

  ngAfterViewInit() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.contractsTableDataSource.filter = filterValue.trim().toLowerCase();

    if (this.contractsTableDataSource.paginator) {
      this.contractsTableDataSource.paginator.firstPage();
    }
  }

  async onPaginateChange(event: PageEvent) {
    const page = event.pageIndex;
    const size = event.pageSize;
    this.isLoading = true;

    const response = await this.service.getAllContracts(page, size, 'id', 'DESC')
    this.contractsTableDataSource = new MatTableDataSource<any>(response);
    this.paginatorDetails = this.service.getPaginatorDetails()
    this.isLoading = false;
  }

  async sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.isLoading = true;
    this.paginatorDetails = this.service.getPaginatorDetails()
    const response = await this.service.getAllContracts(this.paginatorDetails.pageNo, this.paginatorDetails.pageSize, sort.active, sort.direction.toUpperCase())
    this.contractsTableDataSource = new MatTableDataSource<any>(response);
    this.paginatorDetails = this.service.getPaginatorDetails()
    this.isLoading = false;
  }

  goToCreateNew() {
    this.service.goToCreateNew();
  }

  goToRegionPrices(element: any) {
    this.router.navigate(['/regionPrice', { id: element.id, priceType: 'region' }]);
  }

  goToCityPrices(element: any) {
    this.router.navigate(['/cityPrice', { id: element.id, priceType: 'city' }]);
  }


}
