<!-- <p>billing-cost-bulk-upload works!</p> -->
<div *ngIf="loading" class="loader-container">
    <ngx-loading [show]="loading"
        [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
        [template]="loadingTemplate"></ngx-loading>
    <div class="please-wait-text">
        <p><strong>Please Wait...</strong></p>
    </div>
</div>
<div class="col-md-12">
    <div class="card">
        <div class="card-header">
            <div class="card-header-title font-size-lg text-capitalize fw-bold">
                <b style="color: blue;">UPLOAD BILLING & COST INFO</b>
            </div>
        </div>
        <div class="card mt-5">
            <div class="card-body">
                <form (ngSubmit)="onSubmit()">
                    <div class="mb-3">
                        <label for="file" class="form-label">Choose File<span class="text-danger">*</span></label>
                        <input type="file" id="file" #fileInput class="form-control" (change)="onFileSelected($event)"
                            required>
                    </div>
                    <div class="card-footer">
                        <div class="btn-actions-pane-right p-4">
                            <!-- <button type="button" class="btn btn-danger me-2" (click)="onCancel()">Cancel</button> -->
                            <button type="submit" class="btn btn-primary" [disabled]="isSubmitting">Upload</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- <div class="card-header">
            <div class="card-header-title font-size-lg text-capitalize fw-bold">
                <b style="color: blue;">UPDATE BILLING & COST INFO</b>
            </div>
        </div>
        <div class="card mt-5">
            <div class="card-body">
                <form (ngSubmit)="onCostSubmit()">
                    <div class="mb-3">
                        <label for="file" class="form-label">Choose File<span class="text-danger">*</span></label>
                        <input type="file" id="file" class="form-control" (change)="onCostFileSelected($event)" required>
                    </div>
                    <div class="card-footer">
                        <div class="btn-actions-pane-right p-4">
                            <button type="submit" class="btn btn-primary" [disabled]="isCostSubmitting">Upload</button>
                        </div>
                    </div>
                </form>
            </div>
        </div> -->
    </div>
</div>