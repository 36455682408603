<div *ngIf="loading" class="loader-container">
  <ngx-loading [show]="loading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>

<div class="card mt-2">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
      <a><i class="fa fa-arrow-left" (click)="backToConsignmentView()" style="cursor: pointer"></i></a> View Cost Center
    </div>
    <div class="btn-actions-pane-right p-2 ">
      <button mat-raised-button color="primary" (click)="costCenterEditPage()" [disabled]="commonApi.BillingPriceLists.isInvoiceGenerated">Edit</button>
    </div>
  </div>

  <div style="width: 90%; margin:4% 10%">

    <div class="table-responsive">
      <table class="table1" style="width:90%">
        <tr>
          <td class="left">Consignment No :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.consignment_no}}</td>
          <td class="left">Customer Name :</td>
          <td class="right">{{commonApi.BillingPriceLists?.consignment?.isRetailCustomer ? commonApi.BillingPriceLists?.consignment?.individual_name : commonApi.BillingPriceLists?.consignment?.customer_name}}</td>
        </tr>

        <tr>
          <td class="left">Origin :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.origin || commonApi.BillingPriceLists.consignment?.cityname }}</td>
          <td class="left">Destination :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.destination || commonApi.BillingPriceLists.consignment?.receivercity}}</td>
        </tr>

        <tr>
          <td class="left">No of packages :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.no_of_packages}}</td>
          <td class="left">Total chargeable weight :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.total_chargable_weight}} kg</td>
        </tr>
        <tr>
          <td class="left">Consignment Value :</td>
          <td class="right">INR {{commonApi.BillingPriceLists.consignment?.consignment_value?.toLocaleString('en-IN')}}</td>
        </tr>
      </table>
    </div>

    <hr style="margin-left: -11%; border: 1px solid black">

    <div>
      <div style="width: 100%">
        <div class="table-responsive">
          <table class="table" style="width:100%">
            <tr class="header">
              <th style="width: 25%">Line Items</th>
              <th style="width: 20%">Unit Price</th>
              <th style="width: 15%; border-right: 1px solid black">Total</th>
              <th style="width: 20%; border-right: 1px solid black; padding-left: 6%">Cost Incurred</th>
              <th style="width: 20%; padding-left: 5%">Total</th>
            </tr>
            <ng-container *ngFor="let item of commonApi.BillingLineItemsLists">
              <ng-container
                *ngIf="(commonApi.BillingPriceLists[item.value] != 0 && commonApi.BillingPriceLists[item.value] != null) || (commonApi.CostCenterPricesList[item.value] != 0 && commonApi.CostCenterPricesList[item.value] != null) "
              >
                <tr height="50px"
                    *ngIf="(item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage')">
                  <td>{{item.name}}</td>
                  <td>
                    INR {{(commonApi.BillingPriceLists[item.value]) ? (commonApi.BillingPriceLists[item.value]).toLocaleString('en-IN') : 0}}</td>
                  <td style="border-right: 1px solid black"></td>
                  <td style="border-right: 1px solid black; padding-left: 6%">
                    INR {{commonApi.CostCenterPricesList[item.value] == null ? 0 : (commonApi.CostCenterPricesList[item.value]).toLocaleString('en-IN')}}</td>
                </tr>
              </ng-container>
            </ng-container>
          </table>
        </div>

        <div style="display: flex; flex-direction: row">

          <div style="width:60%; border-right: 1px solid black">
            <div style="width: 85%; display: flex; flex-direction:row">
              <div style="width: 86%; display: flex; flex-direction:row">
                <div>
                  <b class="subTotal" style="float: left">Sub Total 1</b>
                </div>&nbsp;&nbsp;&nbsp;
                <div style="flex-grow: 1; border-top: 1px solid #ADADAD; margin-top:1.5%"></div>&nbsp;&nbsp;&nbsp;
              </div>
              <div style="margin-right: -2%">
                INR {{(commonApi.calculateTotalBillingInfoSum()).toLocaleString('en-IN')}}
              </div>
            </div>

            <div style="width: 94%; margin-top: 1%">
              <span style="float: left; width: 43.5%">Discount Amount</span> <span style="color: #0ba360">-INR {{commonApi.BillingPriceLists?.discountedAmount == null ? 0 : (commonApi.BillingPriceLists?.discountedAmount).toLocaleString('en-IN')}}</span>
            </div>


            <div style="width: 85%; margin-top: 4%; display: flex; flex-direction:row">
              <div style="width: 86%; display: flex; flex-direction:row">
                <div>
                  <b class="subTotal" style="float: left">Sub Total 2</b>
                </div>&nbsp;&nbsp;&nbsp;
                <div style="flex-grow: 1; border-top: 1px solid #ADADAD; margin-top:1.5%"></div>&nbsp;&nbsp;&nbsp;
              </div>
              <div style="margin-right: -11%; width: 25%">
                INR {{(commonApi.calculateTotalBillingInfoSum() - commonApi.BillingPriceLists?.discountedAmount).toLocaleString('en-IN')}}
              </div>
            </div>

            <div style="width: 93%; margin-top: 1%">
              <span style="float: left; width: 44.2%">Insurance (FOV)</span>
              <span style="float: left; width: 32.89%">+{{commonApi.BillingPriceLists.insurancePercentage == null ? 0 : commonApi.BillingPriceLists.insurancePercentage}} %</span>
              <span>+INR {{commonApi.BillInsuranceAmount?.toLocaleString('en-IN')}}</span>
            </div>


            <div style="width: 85%; margin-top: 4%; display: flex; flex-direction:row">
              <div style="width: 86%; display: flex; flex-direction:row">
                <div>
                  <b class="subTotal" style="float: left">Taxable Amount</b>
                </div>&nbsp;&nbsp;&nbsp;
                <div style="flex-grow: 1; border-top: 1px solid #ADADAD; margin-top:1.5%"></div>&nbsp;&nbsp;&nbsp;
              </div>
              <div style="margin-right: -11%; width: 25%">
                INR {{commonApi.BillTaxableAmount?.toLocaleString('en-IN')}}
              </div>
            </div>
            <div style="width: 92.5%; margin-top: 1%">
              <span style="float: left; width: 44.2%"> Tax% ({{commonApi.BillingPriceLists?.isGst}}) </span>
              <span style="float: left; width: 33.29%">+{{commonApi.BillTax}} %</span>
              <span>+INR {{commonApi.BillTaxAmount?.toLocaleString('en-IN')}}</span>
            </div>

            <div style="margin-top: 3%; display: flex; flex-direction:row">
              <div style="width: 67%">
                <h3>Grand Total</h3>
              </div>
              <div>
                <h4>
                  INR {{commonApi.GrandTotal?.toLocaleString('en-IN')}}</h4>
              </div>
            </div>
          </div>

          <div style="width: 20%; border-right: 1px solid black">
            <div style="color: #0ba360;padding-left: 28%" class="CostDiscountResp">-INR {{commonApi.CostCenterPricesList?.discountedAmount == null ? 0 : (commonApi.CostCenterPricesList?.discountedAmount).toLocaleString('en-IN')}}</div>
            <div style="padding-left: 29.5%" class="CostInsuranceResp">+ {{commonApi.CostCenterPricesList?.insurancePercentage == null ? 0 : commonApi.CostCenterPricesList?.insurancePercentage}} %</div>
            <div style="padding-left: 29.5%" class="CostTaxResp">+ {{commonApi.CostTax}} %</div>
          </div>

          <div style="width: 20%">
            <div style="margin-top: -1%" class="CostSubTotal1Resp">
              INR {{commonApi.calculateTotalCostCenterSum().toLocaleString('en-IN')}}
            </div>

            <div class="CostSubTotal2Resp">
              INR {{(commonApi.calculateTotalCostCenterSum() - commonApi.CostCenterPricesList?.discountedAmount).toLocaleString('en-IN')}}
            </div>
            <div class="CostInsurAmountResp">
              +INR {{commonApi.CostInsuranceAmount?.toLocaleString('en-IN')}}
            </div>

            <div class="CostTaxableResp">
              INR {{commonApi.CostTaxableAmount?.toLocaleString('en-IN')}}
            </div>
            <div class="CostTaxAmountResp">
              +INR {{commonApi.CostTaxAmount?.toLocaleString('en-IN')}}
            </div>

            <div class="CostGrandTotalResp"><h4>
              INR {{commonApi.CostGrandTotal?.toLocaleString('en-IN')}}</h4>
            </div>
          </div>

        </div>
      </div>

      <div style="display: flex; flex-direction: row">
        <div style="width: 450px">
          <b>Remarks: </b> {{commonApi.CostCenterPricesList?.remarks}}
        </div>

        <div style="margin-top: 2%" class="TotalDiffResp">
          On this shipment, a {{commonApi.ProfitOrLoss}} of <b [ngStyle]="{'color': commonApi.changeColor}">
          INR {{(commonApi.calculateDifference()).toLocaleString('en-IN')}} ({{commonApi.ProfitOrLossPercentage}}%)</b>
        </div>
      </div>
    </div>

  </div>
</div>
