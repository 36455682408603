import {Injectable} from '@angular/core';
import {API_URL} from '../../../../Global_API_URL';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {ConsignmentPaperWorkDTO} from '../golbalInterfaces';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaperWorkService {
  constructor(private http: HttpClient) { }

  private apiUrl = API_URL + '/consignment-paper-works';

  getAllPaperWorks(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}`);
  }

  getAllActivePaperWorks(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/active`);
  }

  getPaperWorkById(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`);
  }

  createPaperWork(paperWork: ConsignmentPaperWorkDTO, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('contentDisposition', paperWork.contentDisposition || '');
    formData.append('fileName', paperWork.fileName || '');
    formData.append('fileType', paperWork.fileType || '');
    formData.append('fileSize', paperWork.fileSize?.toString() || '0');
    formData.append('description', paperWork.description || '');
    formData.append('createdBy', paperWork.createdBy?.toString() || '0');

    return this.http.post<any>(`${this.apiUrl}`, formData);
  }

  updatePaperWork(id: number, paperWork: ConsignmentPaperWorkDTO, file: File | null): Observable<any> {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    formData.append('contentDisposition', paperWork.contentDisposition || '');
    formData.append('fileName', paperWork.fileName || '');
    formData.append('fileType', paperWork.fileType || '');
    formData.append('fileSize', paperWork.fileSize?.toString() || '0');
    formData.append('description', paperWork.description || '');
    formData.append('updatedBy', paperWork.updatedBy?.toString() || '0');

    return this.http.patch<any>(`${this.apiUrl}/${id}`, formData);
  }

  deletePaperWork(id: number): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/${id}`);
  }

  deactivatePaperWork(id: number): Observable<void> {
    const url = `${this.apiUrl}/deactivate/${id}`;
    return this.http.patch<void>(url, {});
  }

  downloadPaperWork(id: number): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders().set('Accept', 'application/octet-stream');
    return this.http.get(`${this.apiUrl}/download/${id}`, {
      headers,
      responseType: 'blob',
      observe: 'response',
    });
  }

  existsByContentDisposition(contentDisposition: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}/exists?contentDisposition=${contentDisposition}`);
  }
  
}
