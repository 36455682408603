import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HideurlService {
 currenturl: any = new BehaviorSubject('/cosingment');
 constructor() { }
  currentUrl:string;

  private id: any;

  setId(id: any): void {
    this.id = id;
  }

  getId(): any {
    return this.id;
  }
}
