import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import Swal from 'sweetalert2';
import {API_URL} from '../../../../../Global_API_URL';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {Airport, Cities} from '../../golbalInterfaces';
import {getAirports} from '../../getAirportAirlines.js';
import {MatSort, Sort} from '@angular/material/sort';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatTableDataSource} from '@angular/material/table';
import {getCities} from '../../getCities.js';
import {FormControl, Validators} from '@angular/forms';
import {async, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {AuthService} from '../../../auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MatSelectChange} from '@angular/material/select';

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#d92550';
const SecondaryBlue = '#3f6ad8';


@Component({
  selector: 'app-manifestation-list',
  templateUrl: './manifestation-list.component.html',
  styleUrls: ['./manifestation-list.component.sass'],
})
export class ManifestationListComponent implements OnInit {
  constructor(private _liveAnnouncer: LiveAnnouncer, private authService: AuthService, private http: HttpClient) {
    this.authService.getAuthToken();
  }
  mawb_number;
  mawb_indio = 312;
  awb_number;
  city1
  city2
  pageview = 'table';
  flightdate = {
    formattedDate: new Date(),

  }
  // epochTime = this.flightdate.formattedDate;
  // date = new Date(this.epochTime);
  // day = this.date.getDate();
  // month = this.date.getMonth() + 1;
  // year = this.date.getFullYear();
  // formattedDate = `${this.day.toString().padStart(2, '0')}/${this.month.toString().padStart(2, '0')}/${this.year}`;

  //  formattedDate = this.flightdate.formattedDate.toISOString().split('T')[0];


  flightPrifix = '6E'
  FLightNo;
  manifest_view;
  selectedDimention: string = 'cm';
  selectedWeight: string = 'kg';
  manifestations = null;
  chevron = false;
  MawbUsers = []
  MawbReceivers = []
  Product = []
  commodity = []
  productDetails = {
    id: null,
    isMail: null,
    isShipper: ' ',
    movpriority: ' ',
    priority: null,
    productDescription: ' ',
    productType: ' ',
    serialNum: null,
    showInMobileapp: ' ',
  }
  MawbNumber = {

    FileUrl: '',
    DepartureTimeMsg: '',
    ErrorDesc: '',
    FlightRoute: '',
    AWBNumber: '',
    ErrorCode: null,
    DeptTime: '',
    CommodityName: '',
    CurrentDate: '',

  }

  mawbPackages =
    {
      length: null,
      breadth: null,
      height: null,
      total_weight: null,
      packageNo: null,
      chargeable_weight: null,
      volume_weight: null,
      totalvolumeweight_per_pack: null,
      customs: 0,
      DeclaredValue: 0.0,
    }


  MAWBDetails = {
    loginName: null,
    sessionId: null,
    awbPrefix: null,
    origin: null,
    destination: null,
    shippingAgentCode: null,
    commodityCode: null,
    productType: null,
    commodityDesc: null,
    pcs: null,
    grossWt: null,
    chargeableWt: null,
    fltDate: null,
    fltNumber: null,
    UOM: null,
    Dimensions: null,
    ShprAccountCode: null,
    ShprCity: null,
    ShprState: null,
    ShprName: null,
    ShprAdd: null,
    ShprPincode: null,
    ShprCountryCode: null,
    ShprContactNo: null,
    ConsAccountCode: null,
    ConsCity: null,
    ConsState: null,
    ConsName: null,
    ConsAdd: null,
    ConsPincode: null,
    ConsCountryCode: null,
    ConsContactNo: null,
    ScheduleID: null,
    ShprLat: null,
    ShprLong: null,
    ConsLat: null,
    ConsLong: null,
    ShprEmailID: null,
    ConsEmailID: null,
    Unit: null,
    awbNumber: null,
    slac: null,
    dvCustom: null,
    declaredValue: null,
    payMode: null,
    packagingInfo: null,
    TokenNumber: null,
    volume: null,
    shcCode: null,
    multilegRoutes: null,
  }
  commodityDetails = {
    commodityCode: ' ',
    commodityDescription: ' ',
    commodityName: ' ',
    id: null,
    isNotoc: '',
    priority: null,
    shcCode: '',
  }

  receiverDetails = {
    address: '',
    agentCode: '',
    baseStation: '',
    billingCity: '',
    billingCountry: '',
    billingState: '',
    billingZipCode: '',
    // billingZipcode: "",
    city: '',
    contactPerson: '',
    country: '',
    createdAt: null,
    createBy: null,
    customerCode: '',
    emailId: '',
    iataAgentCode: '',
    id: null,
    isAllStn: null,
    is_active: null,
    loginId: '',
    mobileNumber: null,
    roleName: '',
    state: '',
    stationCode: '',
    updatedAt: null,
    updatedBy: null,
    userName: '',
    zipCode: null,
  };
  shipperDetails = {
    address: '',
    agentCode: '',
    baseStation: '',
    billingCity: '',
    billingCountry: '',
    billingState: '',
    billingZipCode: '',
    billingZipcode: '',
    city: '',
    contactPerson: '',
    country: '',
    createdAt: null,
    createBy: null,
    customerCode: '',
    emailId: '',
    iataAgentCode: '',
    id: null,
    isAllStn: null,
    is_active: null,
    loginId: '',
    mobileNumber: null,
    roleName: '',
    state: '',
    stationCode: '',
    updatedAt: null,
    updatedBy: null,
    userName: '',
    zipCode: null,
  };
  schedules = {
    FltNumber: '',
    STATUS: null,
    ArrTime: null,
    DepDateTime: null,
    ArrDateTime: null,
    FltOrigin: '',
    FltDestination: '',
    ScheduleID: null,
    DeptTime: '',
    LegSequence: null,
    FltDate: '',
  }

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };
  // manifest_id_edit;

  filterParams = '';
  pagenation = {
    sortBy: 'id',
    pageSize: 10,
    pageNo: 0,
    direction: 'DESC',
  }

  pageSizeOptions = [10, 30];
  tablefilter() {
    this.pagenation.pageNo = 0;
    this.pagenation.direction = 'DESC';
    this.manifestationfilters();
    // this.getmanifest();
  }
  myControl = new FormControl();
  myControl1 = new FormControl();
  filter() {
    this.filterParams = '?';
    if (this.source != '') {
      this.filterParams = this.filterParams + 'source=' + this.source.id + '&';
    }
    if (this.destination != '') {
      this.filterParams = this.filterParams + 'destination=' + this.destination.id + '&';
    }
    if (this.awb_numbers != '') {
      this.filterParams = this.filterParams + 'awb_number=' + this.awb_numbers + '&';
    }
    if (this.flight_number != '') {
      this.filterParams = this.filterParams + 'flight_number=' + this.flight_number + '&';
    }
    this.filterParams = this.filterParams + 'page_no=' + this.pagenation.pageNo + '&page_size=' + this.pagenation.pageSize + '&col_name=' + this.pagenation.sortBy + '&sort_dir=' + this.pagenation.direction;
    return this.filterParams;
  }


  handlePageEvent(event: PageEvent) {
    this.pagenation.pageNo = event.pageIndex;
    this.pagenation.pageSize = event.pageSize;
    this.manifestationfilters();
    // this.getmanifest();
  }


  async ngOnInit() {
    // this.getmanifest();
    this.manifestations = this.manifestationfilters();
    //  console.log("this is manifest view", this.manifestations)
    this.getMawbUserList();
    this.getMawbReceiversList();
    this.getProductList();
    this.getCommodityList();
    await this.loadCountries();
    await this.loadBillingCountries();
    this.airportssearch = await getAirports();
    this.airportssearch1 = await getAirports();
    this.filteredAirports = this.myControl.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter(name) : this.airportssearch.slice())),
    );
    this.filteredAirports1 = this.myControl1.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter1(name) : this.airportssearch1.slice())),
    );
    //  this.getScheduleId();
    this.city1 = getCities();
    this.city2 = getCities();
  }
  viewchange(val, manifest) {
    this.pageview = val;
    this.manifest_view = manifest;
    if (val == 'edit') {
      this.mawb_number = manifest.flightScheduleLeg1.airlineID.iataCodeMawb;
      if (manifest && manifest.awbNumber) {
        const response: string = manifest.awbNumber;

        // Split the string by "." and get the last part
        const parts: string[] = response.split('.');
        const lastPart: string = parts[parts.length - 1];

        // Extract the last 8 digits
        const extractedValue: string = lastPart.slice(-8);
        this.awb_number = extractedValue;
      }
      // else{
      //   console.error("Manifest AWB Number is null.");
      // }
    } else {
      this.awb_number = null;
    }
    this.productDetails = {...this.productDetails, productDescription: ''};
    this.shipperDetails = {...this.shipperDetails, userName: '', roleName: '', baseStation: '', address: ''};
    this.receiverDetails = {...this.receiverDetails, userName: '', roleName: '', baseStation: '', address: ''};
    this.commodityDetails = {...this.commodityDetails, commodityDescription: '', commodityCode: '', shcCode: '', isNotoc: ''};
    this.mawbPackages = {
      length: null,
      breadth: null,
      height: null,
      total_weight: null,
      packageNo: null,
      chargeable_weight: null,
      volume_weight: null,
      totalvolumeweight_per_pack: null,
      customs: 0,
      DeclaredValue: 0.0,
    };
    this.FLightNo = null;
    this.schedules = {
      FltNumber: '',
      STATUS: null,
      ArrTime: null,
      DepDateTime: null,
      ArrDateTime: null,
      FltOrigin: '',
      FltDestination: '',
      ScheduleID: null,
      DeptTime: '',
      LegSequence: null,
      FltDate: '',
    };
  }
  tableLength = 0
  displayedColumns = ['id', 'awbnumber', 'consignments', 'flight', 'source', 'destination', 'manifestationleg1', 'manifestationleg2', 'Active']
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource
  search
  search1
  searchText;
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  // get List of manifestations

  async manifestationfilters() {
    const TOKEN = this.authService.getAuthToken();
    this.loading = true;
    const api = '/manifestationfilters';
    const params = this.filter();
    await fetch(API_URL + api + params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          if (json.status == 400) {
            this.manifestations = [];
          } else {
            this.manifestations = json;
            this.dataSource = new MatTableDataSource(json.content);
          // this.dataSource.paginator = this.paginator;
          // this.dataSource.sort = this.sort;
          }
        });
    this.loading = false;
    return this.manifestations;
  }


  filterParams1 = '';
  source: any = '';
  destination: any = '';
  awb_numbers: any = '';
  flight_number: any = '';


  searchByCons(con) {
    this.dataSource = new MatTableDataSource(this.manifestations.content.filter((cons) => cons.awb_number?.includes(con)));
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  searchByCons1(con1) {
    this.dataSource = new MatTableDataSource(this.manifestations.content.filter((cons1) => cons1.flightScheduleLeg1.flightNo.includes(con1)));
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  displayFn(city1: Cities): String {
    return city1?.name ? city1.name : '';
  }
  displayFn1(city2: Cities): String {
    return city2?.name ? city2.name : '';
  }
  filteredAirports: Observable<Airport[]>
  filteredAirports1: Observable<Airport[]>
  airportssearch: Airport[] = getAirports();
  airportssearch1: Airport[] = getAirports();
  private _filter(name: String): Airport[] {
    const filterValue = name.toLowerCase();

    return this.airportssearch.filter((option) => option.city.name.toLowerCase().includes(filterValue));
  }
  private _filter1(name: String): Airport[] {
    const filterValue1 = name.toLowerCase();

    return this.airportssearch1.filter((option) => option.city.name.toLowerCase().includes(filterValue1));
  }
  displayFn2(airport1: Airport): String {
    return airport1 && airport1?.city?.name ? airport1.city.name : '';
  }
  displayFn3(airport1: Airport): String {
    return airport1 && airport1?.city?.name ? airport1.city.name : '';
  }
  resetForm() {
    this.source = '';
    this.destination = '';
    this.awb_numbers = '';
    this.flight_number = '';
    this.manifestationfilters();
    // this.getmanifest();
  }

  getWeight(weights, count) {
    let sum = 0;
    weights.forEach((w) => {
      sum = Math.ceil((w.length * w.breadth * w.height) / 6000);
      sum = sum * count;
      // console.log(sum)
    });
    return sum;
  }

  calcTotalChargableWeight(pack) {
    const weight1 = ((pack.length * pack.breadth * pack.height) / 6000) * pack.package_no;
    const weight2 = pack.package_no * pack.weight_per_package;
    let ceil = null;
    if (weight1 >= weight2) {
      ceil = Math.ceil(weight1);
    } else {
      ceil = Math.ceil(weight2);
    }

    return ceil;
  }
  // calcVolumeaWeight(pack){
  //   let weight= ((pack.length*pack.breadth*pack.height)/6000)
  //   let ceil = Math.ceil(weight);
  //   return ceil
  // }

  calcTotalConsignmentChargeableWeight(manifest_view) {
    let sum = 0;
    const weight = 0;
    // let ceil=0
    manifest_view.packages.forEach((element) => {
      const weight1 = ((element.length * element.breadth * element.height) / 6000) * element.package_no;
      const weight2 = element.package_no * element.weight_per_package;
      let ceil = 0;
      if (weight1 >= weight2) {
        ceil = Math.ceil(weight1);
      } else {
        ceil = Math.ceil(weight2);
      }
      sum = sum + ceil;
    });
    return sum;
  }

  async updateAwb() {

    if (this.awb_number == null || this.awb_number === undefined) {
      Swal.fire({
        title: 'Warning',
        icon: 'warning',
        text: 'Please Enter the AWB Number',
        timer: 5000,
      })
    } else{

      const TOKEN = this.authService.getAuthToken();

      const formdata = new FormData();
      formdata.append('id', this.manifest_view.id);
      formdata.append('awb_number', 'AWB' + this.mawb_number + '-' + this.awb_number);

      await fetch(API_URL + '/updateawb',
        {
          method: 'PATCH',
          body: formdata,
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then(async (result) => {
          // console.log(result);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Awb Number Updated',
            // showConfirmButton: false,
            timer: 5000,
          });
          // this.getmanifest();
          this.manifestationfilters();
          this.viewchange('table', null);
        })
        .catch((error) => {
          console.error('Error', error);
          alert(error);
        });
    }
  }


  manifest_status =
    [{
      id: 9,
      status: 'MANIFESTED',
    }, {
      id: 10,
      status: 'UPLIFTED',
    }, {
      id: 11,
      status: 'ARRIVED AT DESTINATION AIRPORT',
    }, {
      id: 12,
      status: 'READY FOR PICKUP AT DESTINATION AIRPORT',
    }, {
      id: 19,
      status: 'DELAY',
    },
    ];
  manifest_id;
  manifest_sts_update = {
    manifestId: null,
    status: null,
    comment: '',
  }

  async update_mani_status() {
    const TOKEN = this.authService.getAuthToken();
    const sts_update = {
      manifestId: this.manifest_view.id,
      status: this.manifest_sts_update.status,
      comment: this.manifest_sts_update.comment,
    };
    await fetch(API_URL + '/trackByManifest',
        {
          method: 'POST',
          body: JSON.stringify(sts_update),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Status updated successfully',
            // showConfirmButton: false,
            timer: 5000,
          });
          // this.getmanifest();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          this.manifestationfilters();
          this.viewchange('table', null);
        });
  }
  formatDate(date) {
    const date2 = new Date(date);
    return (
      [
        date2.getFullYear(),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date2.getHours()),
        this.padTo2Digits(date2.getMinutes()),
      ].join(':')
    );
  }
  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  async getMawbUserList() {
    const TOKEN = this.authService.getAuthToken();
    let users;
    await fetch(API_URL + '/getindigoUsersInfo',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((json) => {
          users = json;
        // console.log(users);
        });
    // return users;
    this.MawbUsers = users;
  }

  async getMawbReceiversList() {
    let receivers;
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/getIndigoReceiversInfo',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((json) => {
          receivers = json;
        });
    this.MawbReceivers = receivers;
  }

  userName: string;
  async getUserDetails(value: any) {
    const TOKEN = this.authService.getAuthToken();
    // console.log("this", value)
    let users;
    await fetch(API_URL + '/getindigoUsersInfo/' + value.value,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((json) => {
          users = json;
        });
    this.shipperDetails = users.data[0];
  }

  // async getReceiverDetails(value: any) {
  //   let receivers;
  //   TOKEN = localStorage.getItem('token');
  //   await fetch(API_URL + "/getIndigoReceiversInfo/" + value,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Accept": "application/json",
  //         "Content-Type": "application/json",
  //         "Authorization": "Bearer " + TOKEN
  //       }
  //     })
  //     .then(response => response.json())
  //     .then(json => {
  //       receivers = json;
  //     });
  //   this.receiverDetails = receivers.data[0];
  //   this.getScheduleId();
  // }

  emailregex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
  emailFormControl = new FormControl('', [
    Validators.pattern(this.emailregex),
  ]);
  phoneFormControl = new FormControl('', [
    Validators.pattern(/^[0-9]{10}$/), // Validation pattern for exactly 10 digits
  ]);

  async getReceiverDetails(value: MatSelectChange) {
    // Extracting the receiver ID from the MatSelectChange object
    const receiverId = value.value;
    if (typeof receiverId !== 'number') {
      // console.error('Invalid receiver ID:', receiverId);
      return;
    }
    let receivers;
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/getIndigoReceiversInfo/' + receiverId,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((json) => {
          receivers = json;
        });
    this.receiverDetails = receivers.data[0];
    this.getScheduleId();
  }

  // async getReceiverDetails(): Promise<void> {
  //   let receivers;
  //   TOKEN = localStorage.getItem('token');
  //   await fetch(API_URL + "/getIndigoReceiversInfo",
  //     {
  //       method: "GET",
  //       headers: {
  //         "Accept": "application/json",
  //         "Content-Type": "application/json",
  //         "Authorization": "Bearer " + TOKEN
  //       }
  //     })
  //     .then(response => response.json())
  //     .then(json => {
  //       receivers = json;
  //     });
  //   this.receiverDetails = receivers.data[0];
  //   this.getScheduleId();
  // }

  showReceiverForm: boolean = false;
  newReceiver: any = {
    address: '',
    // agentCode: "",
    // baseStation: "",
    billingCity: '',
    billingCountry: '',
    billingState: '',
    billingZipCode: '',
    billingAddress: '',
    city: '',
    // contactPerson: "",
    country: null,
    // createdAt: null,
    // createBy: null,
    // customerCode: "",
    emailId: '',
    // iataAgentCode: "",
    // id: null,
    // isAllStn: null,
    // is_active: null,
    // loginId: "",
    mobileNumber: null,
    // roleName: "",
    state: '',
    // stationCode: "",
    // updatedAt: null,
    // updatedBy: null,
    // userName: "",
    zipCode: '',
  };

  addReceiver(value: any): void {
    const {
      emailId, mobileNumber, address, country, state, city, zipCode, billingAddress, billingCountry, billingState, billingCity, billingZipCode,
    } = this.newReceiver;
    const requiredFields = {
      emailId, mobileNumber, address, country, state, city, zipCode, billingAddress, billingCountry, billingState, billingCity, billingZipCode,
    };
    const missingField = Object.entries(requiredFields).find(([key, value]) => value == null || value === '');
    if (missingField) {
      const fieldName = missingField[0]; // Get the name of the missing field
      // if (['loginId', 'userName', 'roleName', 'isAllStn', 'stationCode', 'agentCode', 'baseStation', 'customerCode', 'iataAgentCode', 'contactPerson'].includes(fieldName)) {
      //   this.saveReceiver(); // If it's one of the excluded fields, proceed without showing an error
      //   return;
      // }
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Please provide the ${fieldName}.`,
      });
      return; // Stop execution if a field is missing
    }
    // Validate email
    if (!this.emailFormControl.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please provide a valid email address.',
      });
      return;
    }

    // Validate phone number
    const phoneNumber = this.newReceiver.mobileNumber.toString(); // Convert to string to calculate length
    if (phoneNumber.length !== 10) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please provide a valid mobile number.',
      });
      return;
    }
    this.saveReceiver(); // All required fields are filled, proceed to save
  }
  saveReceiver(): void {
    const TOKEN = this.authService.getAuthToken();
    const {
      address, billingCity, billingCountry, billingState, billingZipCode, mobileNumber, billingAddress, city, country,
      emailId, state, zipCode,
    } = this.newReceiver;
    const newReceiverPayload = {
      // agentCode: agentCode,
      // baseStation: baseStation,
      // customerCode: customerCode,
      emailId: emailId,
      mobileNumber: mobileNumber,
      city: city.id,
      country: country,
      state: state.id,
      zipCode: zipCode,
      address: address,
      billingZipCode: billingZipCode,
      billingCity: billingCity.id,
      billingCountry: billingCountry,
      billingState: billingState.id,
      billingAddress: billingAddress,
    };
    const requestOptions = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    };
    this.http.post<any>(API_URL + '/indigoReceiver', newReceiverPayload, requestOptions)
        .subscribe((response) => {
        // console.log(response); // Handle response as needed
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'MAWB Receiver added successfully!',
            showConfirmButton: false,
            timer: 3000, // Display for 5 seconds
          });
          this.showReceiverForm = false;
          this.resetReceivers();
          this.getMawbReceiversList();
        }, (error) => {
        // console.error(error); // Handle error
        });
  }

  cancelAddingReceiver(): void {
    // Reset form and hide form
    this.newReceiver = {
      address: '',
      // agentCode: "",
      // baseStation: "",
      billingCity: '',
      billingCountry: '',
      billingState: '',
      billingZipCode: '',
      billingAddress: '',
      city: '',
      // contactPerson: "",
      country: null,
      // createdAt: null,
      // createBy: null,
      // customerCode: "",
      emailId: '',
      // iataAgentCode: "",
      // id: null,
      // isAllStn: null, // Reset isAllStn to null or its default value
      // is_active: null,
      // loginId: "",
      mobileNumber: null,
      // roleName: "",
      state: '',
      // stationCode: "",
      // updatedAt: null,
      // updatedBy: null,
      // userName: "",
      zipCode: '',
    };
    // Hide the receiver form
    this.showReceiverForm = false;
  }

  resetReceivers(): void {
    this.newReceiver = {
      address: '',
      // agentCode: "",
      // baseStation: "",
      billingCity: '',
      billingCountry: '',
      billingState: '',
      billingZipCode: '',
      billingAddress: '',
      city: '',
      // contactPerson: "",
      country: null,
      // createdAt: null,
      // createBy: null,
      // customerCode: "",
      emailId: '',
      // iataAgentCode: "",
      // id: null,
      // isAllStn: null, // Reset isAllStn to null or its default value
      // is_active: null,
      // loginId: "",
      mobileNumber: null,
      // roleName: "",
      state: '',
      // stationCode: "",
      // updatedAt: null,
      // updatedBy: null,
      // userName: "",
      zipCode: '',
    };
  }

  // loadReceivers(): void {
  //   this.getReceiverDetails();
  // }

  countries: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  pincodes: any[] = [];

  async loadCountries() {
    try {
      const response = await this.getCountry().toPromise();
      this.countries = response.data; // Accessing countries from the 'data' property
      // console.log('Countries API response:', response); // Log the entire response for debugging
      // console.log('Countries:', this.countries); // Log the countries array
    } catch (error) {
      // console.error('Error loading countries:', error);
    }
  }

  onCountrySelected(countryId: string): void {
    // console.log('Selected Country ID:', countryId);
    this.onCountryChange(countryId);
    this.newReceiver.state = '';
    this.newReceiver.city = '';
    this.newReceiver.zipCode = '';
  }

  async onCountryChange(countryId: string): Promise<void> {
    try {
      // console.log('Selected Country ID:', countryId);
      const response = await this.getStatesById(countryId).toPromise();
      // console.log('States API response:', response);
      this.states = response.data;
      // console.log('Updated States:', this.states);
    } catch (error) {
      // console.error('Error fetching states:', error);
    }
  }

  async onStateChange(stateId: number): Promise<void> {
    try {
      // console.log('Selected State ID:', stateId);
      const response = await this.getCitiesByStateId(stateId).toPromise();
      // console.log('Cities API response:', response);
      this.cities = response.data;
      this.newReceiver.city = '';
      this.newReceiver.zipCode = '';
      // console.log('Updated Cities:', this.cities);
    } catch (error) {
      // console.error('Error fetching cities:', error);
    }
  }

  async onCityChange(cityId: number): Promise<void> {
    try {
      // console.log('City selected:', cityId);
      const response = await this.getPincodesByCityId(cityId).toPromise();
      // console.log('Pincodes API response:', response);
      this.pincodes = response.data;
      this.newReceiver.zipCode = '';
      // console.log('Pincodes:', this.pincodes);
      // console.log('Selected Pincode ID:', this.newReceiver.zipCode); // Corrected access to pincode ID
    } catch (error) {
      // console.error('Error loading pincodes:', error);
    }
  }

  private getCountry(): Observable<any> {
    const TOKEN = this.authService.getAuthToken();

    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`,
    });
    return this.http.get<any>(`${API_URL}/countries`, {headers});
  }

  private getStatesById(id: string): Observable<any> {
    const TOKEN = this.authService.getAuthToken();

    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`,
    });
    return this.http.get<any>(`${API_URL}/country/${id}/states`, {headers});
  }

  private getCitiesByStateId(stateId: number): Observable<any> {
    const TOKEN = this.authService.getAuthToken();

    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`,
    });
    return this.http.get<any>(`${API_URL}/state/${stateId}/cities`, {headers});
  }

  private getPincodesByCityId(cityId: number): Observable<any> {
    const TOKEN = this.authService.getAuthToken();
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`,
    });
    return this.http.get<any>(`${API_URL}/pincode/cities/${cityId}`, {headers});
  }

  billingCountries: any[] = [];
  billingStates: any[] = [];
  billingCities: any[] = [];
  billingPincodes: any[] = [];

  async loadBillingCountries() {
    try {
      const response = await this.getCountry().toPromise();
      this.billingCountries = response.data; // Accessing countries from the 'data' property
      // console.log('Countries API response:', response); // Log the entire response for debugging
      // console.log('Countries:', this.billingCountries); // Log the countries array
    } catch (error) {
      // console.error('Error loading countries:', error);
    }
  }

  onBillingCountrySelected(countryId: string): void {
    // console.log('Selected Country ID:', countryId);
    this.onBillingCountryChange(countryId);
    this.newReceiver.billingState = '';
    this.newReceiver.billingCity = '';
    this.newReceiver.billingZipCode = '';
  }

  async onBillingCountryChange(countryId: string): Promise<void> {
    try {
      // console.log('Selected Country ID:', countryId);
      const response = await this.getStatesById(countryId).toPromise();
      // console.log('States API response:', response);
      this.billingStates = response.data;
      // console.log('Updated States:', this.billingStates);
    } catch (error) {
      // console.error('Error fetching states:', error);
    }
  }

  async onBillingStateChange(stateId: number): Promise<void> {
    try {
      // console.log('Selected State ID:', stateId);
      const response = await this.getCitiesByStateId(stateId).toPromise();
      // console.log('Cities API response:', response);
      this.billingCities = response.data;
      this.newReceiver.billingCity = '';
      this.newReceiver.billingZipCode = '';
      // console.log('Updated Cities:', this.billingCities);
    } catch (error) {
      // console.error('Error fetching cities:', error);
    }
  }

  async onBillingCityChange(cityId: number): Promise<void> {
    try {
      // console.log('City selected:', cityId);
      const response = await this.getPincodesByCityId(cityId).toPromise();
      // console.log('Pincodes API response:', response);
      this.billingPincodes = response.data;
      this.newReceiver.billingZipCode = '';
      // console.log('Pincodes:', this.billingPincodes);
      // console.log('Selected Pincode ID:', this.newReceiver.billingZipCode); // Corrected access to pincode ID
    } catch (error) {
      // console.error('Error loading pincodes:', error);
    }
  }

  flight;
  async getScheduleId() {
    const TOKEN = this.authService.getAuthToken();

    const epochTime = this.flightdate.formattedDate;
    const date = new Date(epochTime);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so we add 1
    const day = date.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    if (this.FLightNo == null || this.FLightNo == undefined) {
      this.flight = this.manifest_view.flightScheduleLeg1.flightNo;
    } else {
      this.flight = this.flightPrifix + this.FLightNo;
    }
    const users = {
      // "fltDate": new Date(this.manifest_view.shippingTime * 1000).toISOString().split('T')[0],
      'fltDate': formattedDate,

      //  "flightNo": this.manifest_view.flightScheduleLeg1.flightNo,
      'flightNo': this.flight,
      'origin': this.manifest_view.source.iataCode,
      'dest': this.manifest_view.destination.iataCode,
    };
    await fetch(API_URL + '/indigo/getScheduleId', {
      method: 'POST',
      body: JSON.stringify(users),
      headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
        .then((response) => response.json())
        .then((json) => {
          this.schedules = json;
        });

    // console.log("this is Schedulesss", this.schedules)
  }

  async getProductList() {
    const TOKEN = this.authService.getAuthToken();
    let users;
    await fetch(API_URL + '/Indigoproducttypes',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })

    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          users = json;
        // console.log(users);
        });
    // return users;
    this.Product = users;
  }

  async getCommodityList() {
    const TOKEN = this.authService.getAuthToken();
    let users;
    await fetch(API_URL + '/IndigoCommodityCodes',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })

    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          users = json;
        // console.log(users);
        });
    // return users;
    this.commodity = users;
  }

  async getProductDetails(value: any) {
    const TOKEN = this.authService.getAuthToken();
    let users;
    await fetch(API_URL + '/product_type/' + value.value,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })

    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          users = json;
        // console.log(users);
        });
    //  return users;
    this.productDetails = users.data[0];
  }

  eachPackageCharged() {
    this.mawbPackages.volume_weight = (Number(this.mawbPackages.length) * Number(this.mawbPackages.breadth) * Number(this.mawbPackages.height)).toString();
    // console.log("this is volume", this.mawbPackages.volume_weight)
  }

  async getCommodityDetails(value: any) {
    const TOKEN = this.authService.getAuthToken();
    let users;
    await fetch(API_URL + '/commodity_code/' + value.value,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })

    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          users = json;
        // console.log(users);
        });
    //  return users;
    this.commodityDetails = users.data[0];
    this.getScheduleId();
  }


  async createMawbNumber() {
    const TOKEN = this.authService.getAuthToken();
    this.loadingSecound = true;
    const epochTime = this.flightdate.formattedDate;
    const date = new Date(epochTime);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const year = date.getFullYear();
    const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;


    if (this.FLightNo == null || this.FLightNo == undefined) {
      this.flight = this.manifest_view.flightScheduleLeg1.flightNo;
    } else {
      this.flight = this.flightPrifix + this.FLightNo;
    }
    const Mawb =
    {
      'origin': this.manifest_view.source.iataCode,
      'destination': this.manifest_view.destination.iataCode,
      'shippingAgentCode': this.shipperDetails.agentCode,
      'commodityCode': this.commodityDetails.commodityCode,
      'productType': this.productDetails.productType,
      'commodityDesc': this.commodityDetails.commodityDescription,
      'pcs': this.mawbPackages.packageNo,
      'grossWt': this.mawbPackages.total_weight,
      'chargeableWt': this.mawbPackages.chargeable_weight,
      'fltDate': formattedDate,
      'fltNumber': this.flight,
      //  "fltDate":new Date(this.manifest_view.shippingTime * 1000).toISOString().split('T')[0],
      // "fltNumber":this.manifest_view.flightScheduleLeg1.flightNo,
      'Dimensions': this.mawbPackages.length + ',' + this.mawbPackages.breadth + ',' + this.mawbPackages.height + ',' + this.mawbPackages.packageNo + ',' + this.mawbPackages.chargeable_weight + ',' + '0/',
      'ShprAccountCode': this.shipperDetails.agentCode,
      'ShprCity': this.shipperDetails.city,
      'ShprState': this.shipperDetails.state,
      'ShprName': this.shipperDetails.userName,
      'ShprAdd': this.shipperDetails.address,
      'ShprPincode': this.shipperDetails.zipCode,
      'ShprCountryCode': this.shipperDetails.country,
      'ShprContactNo': this.shipperDetails.mobileNumber,
      'ConsAccountCode': this.receiverDetails.agentCode,
      'ConsCity': this.receiverDetails.city,
      'ConsState': this.receiverDetails.state,
      'ConsName': this.receiverDetails.userName,
      'ConsAdd': this.receiverDetails.address,
      'ConsPincode': this.receiverDetails.zipCode,
      'ConsCountryCode': this.receiverDetails.country,
      'ConsContactNo': this.receiverDetails.mobileNumber,
      'ScheduleID': this.schedules.ScheduleID,
      'ShprEmailID': this.shipperDetails.emailId,
      'ConsEmailID': this.receiverDetails.emailId,
      'dvCustom': this.mawbPackages.customs,
      'declaredValue': this.mawbPackages.DeclaredValue,
      'packagingInfo': '',
      'volume': this.mawbPackages.volume_weight,
      'shcCode': this.commodityDetails.shcCode,
      'multilegRoutes': this.flight + ',' + this.manifest_view.source.iataCode + ',' + this.manifest_view.destination.iataCode + ',' + this.schedules.ScheduleID + ',' + formattedDate,
      // "multilegRoutes":"6E2069"+","+this.manifest_view.source.iataCode+","+this.manifest_view.destination.iataCode+","+this.schedules.ScheduleID+","+"2023-06-01"
    };
    if (this.shipperDetails.agentCode == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Select Shipper Details',
        timer: 5000,

      });
      this.loading = false;
    } else if (this.receiverDetails.agentCode == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Select Receiver Details',
        timer: 5000,

      });
      this.loading = false;
    } else if (this.productDetails.productType == '' || this.productDetails.productType == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Select Product Details',
        timer: 5000,

      });
      this.loading = false;
    } else if (this.commodityDetails.commodityCode == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Select Commodity Details',
        timer: 5000,

      });
      this.loading = false;
    } else if (this.schedules.ScheduleID == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'ScheduleId is Present',
        timer: 5000,

      });
      this.loading = false;
    } else if (this.mawbPackages.length == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Enter Length details',
        timer: 5000,

      });
      this.loading = false;
    } else if (this.mawbPackages.breadth == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Enter Breath details',
        timer: 5000,

      });
      this.loading = false;
    } else if (this.mawbPackages.height == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Enter Height details',
        timer: 5000,

      });
      this.loading = false;
    } else if (this.mawbPackages.packageNo == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Enter  Number of Packages',
        timer: 5000,

      });
      this.loading = false;
    } else if (this.mawbPackages.total_weight == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Enter  Total Weight',
        timer: 5000,

      });
      this.loading = false;
    } else if (this.mawbPackages.chargeable_weight == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Enter  Chargeable Weight',
        timer: 5000,

      });
      this.loading = false;
    } else {
      await fetch(API_URL + '/indigo/getIndigoMawb',
          {
            method: 'POST',
            body: JSON.stringify(Mawb),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + TOKEN,
            },
          })
          .then((response) => response.json())
          .then((result) => {
            this.MawbNumber = result.Table6[0];

          // console.log("this is MAWB", this.MawbNumber);
          })
          .catch((error) => {
          // console.error("Error", error);
            alert(error);
          });
      this.updateAwbIndigo();
    }
    this.loadingSecound = false;
  }

  async updateAwbIndigo() {
    // alert(this.awb_number);
    const TOKEN = this.authService.getAuthToken();

    const formdata = new FormData();
    formdata.append('id', this.manifest_view.id);
    formdata.append('awb_number', 'AWB' + this.mawb_indio + '-' + this.MawbNumber.AWBNumber);

    await fetch(API_URL + '/updateawb',
        {
          method: 'PATCH',
          body: formdata,
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then(async (result) => {
        // console.log(result);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Awb Number Generated',
            showConfirmButton: false,
            timer: 5000,
          });
          // this.getmanifest();
          this.manifestationfilters();
          this.viewchange('table', null);
        })
        .catch((error) => {
        // console.error("Error", error);
          alert(error);
        });
  }

  validateInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^(\d*\.?\d*)$/.test(inputChar)) {
      event.preventDefault();
    }
  }
}
