import {Component, OnInit, TemplateRef} from '@angular/core';
import {getCities, getCountries, getStates} from '../../getCities.js';

import {ngxLoadingAnimationTypes} from 'ngx-loading';

import {getAirlines, getAirports} from '../../getAirportAirlines.js';
import {getConsignmentType} from '../../getConsignmentType.js';
import {API_URL} from '../../../../../Global_API_URL';
import Swal from 'sweetalert2';
import {AuthService} from '../../../auth.service';
import {HttpService} from '../../../services/http.service';

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#d92550';
const SecondaryBlue = '#3f6ad8';


@Component({
  selector: 'app-airline-pricing-schedule',
  templateUrl: './airline-pricing-schedule.component.html',
  styleUrls: ['./airline-pricing-schedule.component.sass'],
})
export class AirlinePricingScheduleComponent implements OnInit {
  constructor(private authService : AuthService, private httpService: HttpService) {
    this.authService.getAuthToken();
  }
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  loading = false;
  pageview='table';
  airlines = getAirlines();
  states = getStates();
  countries = getCountries();
  cities = getCities();
  airports = getAirports();
  airlinepricing =this.getairlinepricing();
  consignmentTYpes = getConsignmentType();
  ngOnInit(): void {
  }
  viewchange(val) {
    this.pageview = val;
  }

  newAirlinePricing={
    origin: null,
    destination: null,
    consignmentType: null,
    minimumCharge: null,
    netRate: null,
    above_45: null,
    above_100: null,
    above_300: null,
    above_500: null,
    above_1000: null,
    applicableFlight: null,
    applicableCommodity: null,
    sectorSurcharge: null,
    flightSurcharge: null,
    commoditySurcharge: null,
    airline: null,
  }


  async createAirlinePricing() {
    if (this.newAirlinePricing.consignmentType == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please select an Airline',
        showConfirmButton: false,
        timer: 5000,
      });
    } else if (this.newAirlinePricing.origin == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please select the origin',
        showConfirmButton: false,
        timer: 5000,
      });
    } else if (this.newAirlinePricing.destination == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please select the destination',
        showConfirmButton: false,
        timer: 5000,
      });
    } else if (this.newAirlinePricing.consignmentType == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please select the Consignment Type',
        showConfirmButton: false,
        timer: 5000,
      });
    } else {
      this.httpService.post(API_URL + '/airline_pricing_schedules', this.newAirlinePricing, null, null)
          .subscribe((result) => {
            // console.log(result);
            this.newAirlinePricing ={
              origin: null,
              destination: null,
              consignmentType: null,
              minimumCharge: null,
              netRate: null,
              above_45: null,
              above_100: null,
              above_300: null,
              above_500: null,
              above_1000: null,
              applicableFlight: null,
              applicableCommodity: null,
              sectorSurcharge: null,
              flightSurcharge: null,
              commoditySurcharge: null,
              airline: null,
            };
            this.airlinepricing= this.getairlinepricing();
            this.viewchange('table');
          }, (error) => {
            console.error('Error', error);
            alert(error);
          });
    }
  }

  async getairlinepricing() {
    let airportps: any = [];
    this.httpService.get(API_URL + '/airline_pricing_schedules', null, null)
    // Converting received data to JSON
        .subscribe((result) => {
          airportps = result;
        });
    return airportps.data;
  }
}
