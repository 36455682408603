<div class="col-md-8">
	<container-element [ngSwitch]="pageview">
		<some-element *ngSwitchCase="'table'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						Consignment Sub Types
					</div>
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-primary" (click)="viewchange('add')">Add</div>
					</div>
				</div>

				<div class="container">
					<div class="row">
						<div class="">
							<div class="table-responsive">
								<table class="align-middle text-truncate mb-0 table table-borderless table-hover">
									<thead>
										<tr>
											<th class="text-center">Name</th>
											<th class="text-center">Consignment Type</th>
											<th class="text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let subtype of consignment_sub_types | async">
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{subtype.name}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{subtype.consignmnet_type_id}}
												</a>
											</td>

											<td class="text-center">
												<div role="group" class="btn-group-sm btn-group">
													<button class="btn-shadow btn btn-primary" (click)="viewchange('edit')"><i
															class="lnr-pencil"></i></button>
													<!-- <button class="btn-shadow btn btn-primary">Delete</button> -->
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
									[showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
									[pageIndex]="pageIndex" aria-label="Select page">
								</mat-paginator>
							</div>
						</div>
					</div>
				</div>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'add'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						Add Consignment Sub Type
					</div>

				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-4 row">
							<mat-form-field appearance="standard">
								<mat-label>
									Name
								</mat-label>
								<input type="text" placeholder="Name" aria-label="Number" [(ngModel)]="new_subtype.name" matInput>

							</mat-form-field>
						</div>
          </div>
          <div class="row">
						<div class="col-md-4 row">
							<mat-form-field appearance="standard">
								
                <mat-label>Description</mat-label>
                <textarea matInput placeholder="Description" [(ngModel)]="new_subtype.description"></textarea>
							</mat-form-field>
						</div>
          </div>
					<div class="row">
						<div class="col-md-4 row">
							<mat-form-field appearance="standard">
								<mat-label>Consignment Type</mat-label>
								<select matNativeControl id="" [(ngModel)]="new_subtype.consignmnet_type_id">
									<option *ngFor="let con of consignment_types |async" [value]="con.id">
										{{con.name}}
									</option>
								</select>
							</mat-form-field>

						</div>
						
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="createOffice()">Add</div>
					</div>
				</div>
			</div>
		</some-element>

		<some-element *ngSwitchCase="'edit'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						Edit Consignment Sub Type
					</div>

				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-4 row">
							<mat-form-field appearance="standard">
								<mat-label>
									Name
								</mat-label>
								<input type="text" placeholder="Name" aria-label="Number" [(ngModel)]="new_subtype.name" matInput>

							</mat-form-field>
						</div>
          </div>
          <div class="row">
						<div class="col-md-4 row">
							<mat-form-field appearance="standard">
								
                <mat-label>Description</mat-label>
                <textarea matInput placeholder="Description" [(ngModel)]="new_subtype.description"></textarea>
							</mat-form-field>
						</div>
          </div>
					<div class="row">
						<div class="col-md-4 row">
							<mat-form-field appearance="standard">
								<mat-label>Consignment Type</mat-label>
								<select matNativeControl id="" [(ngModel)]="new_subtype.consignmnet_type_id">
									<option *ngFor="let con of consignment_types |async" [value]="con.id">
										{{con.name}}
									</option>
								</select>
							</mat-form-field>

						</div>
						
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="viewchange('table')">Save</div>
					</div>
				</div>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'view'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						View Consignment Sub Type
					</div>

				</div>

				<div class="container m-2">
					<div class="row ">
						<div class="col-md-3 fw-bold">Name</div>
						<div class="col-md-5">Cold Storage</div>
					</div>
					<div class="row ">
						<div class="col-md-3 fw-bold">Description</div>
						<div class="col-md-5"> Begampet </div>
					</div>
					<div class="row ">
						<div class="col-md-3 fw-bold">Consignment Types </div>
						<div class="col-md-5"> GCS </div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Back</div>
					</div>
				</div>
			</div>
		</some-element>
	</container-element>
</div>