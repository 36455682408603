<div [ngClass]="{
'closed-sidebar' : globals.toggleSidebar,
'closed-sidebar-md' : globals.toggleSidebarMobile,
'settings-open' : globals.toggleThemeOptions,
'closed-sidebar-open': globals.sidebarHover || globals.toggleSidebarMobile,
'header-menu-open' : globals.toggleHeaderMobile,
'drawer-open' : globals.toggleDrawer,
'fixed-footer' : globals.toggleFixedFooter
}" [class]="'app-container app-theme-white'">
  <app-header></app-header>
  <div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHoverMouseIn()"
  (mouseout)="sidebarHoverMouseOut()">
  <div class="app-header__logo">
    <div class="logo-src"></div>
    <div class="header__pane ms-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar();">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true" class="scroll-container" [config]="config" [scrollIndicators]="true"
        style="max-width: 600px; max-height: 690px;">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" #acc="ngbAccordion"
              activeIds="{{extraParameter}}">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Main Navigation</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="dashboards">
                <ng-template ngbPanelHeader>
                  <a href="/dashboard" ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-rocket"></i>
                    <span class="vsm-title">Dashboard</span>
                    <!-- <i class="vsm-arrow"></i> -->
                  </a>
                </ng-template>
                <!-- <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/dashboards/analytics" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Analytics</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a [routerLink]="['/dashboards/advertisement/']" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Advertisement</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/dashboards/management" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Management</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/dashboards/helpdesk" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Helpdesk</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/dashboards/monitoring" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Monitoring</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/dashboards/crypto" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Crypto</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/dashboards/project-management" routerLinkActive="active-item" (click)="activeId='dashboardsMenu'" class="vsm-link">
                          <span class="vsm-title">ProjectManagement</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/dashboards/product" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Product</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/dashboards/statistics" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Statistics</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template> -->
              </ngb-panel>
              <ngb-panel id="Consignments">
                <ng-template ngbPanelHeader>
                  <a href="consignment" ngbPanelToggle class="vsm-link ">
                    <i class="vsm-icon pe-7s-browser"></i>
                    <span class="vsm-title">Consignments</span>
                    <!-- <i class="vsm-arrow"></i> -->
                  </a>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="apps">
                <ng-template ngbPanelHeader>
                  <a href="notifications" ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-plugin"></i>
                    <span class="vsm-title">Notifications</span>
                    <!-- <i class="vsm-arrow"></i> -->
                  </a>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
  <div class="app-main__outer">
    <div class="app-main__inner">
      <div [@architectUIAnimation]="o.isActivated ? o.activatedRoute : ''">
        <router-outlet #o="outlet"></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <!-- <app-options-drawer></app-options-drawer>
  <app-drawer></app-drawer> -->
  <div class="sidebar-menu-overlay" (click)="toggleSidebarMobile()"></div>
</div>
<ngx-loading-bar [color]="'#3f6ad8'"></ngx-loading-bar>