<h1 mat-dialog-title>Assign Consignment Type</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Select Consignment Type</mat-label>
    <mat-select [(ngModel)]="selectedConsignmentType">
      <mat-option *ngFor="let consignmentType of data; let i = index;" [value]="consignmentType">
        {{consignmentType.name}}
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="showWarning===true" class="text-danger" >Please Select a Consignment Type</mat-hint>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="warn" style="color: white" (click)="onNoClick()"><b>Cancel</b></button>
  <button mat-raised-button color="primary" [disabled]="selectedConsignmentType==null" style="color: white" (click)="submitResult()" cdkFocusInitial><b>Confirm</b></button>
</div>

