import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpService} from "../../../services/http.service";
import Swal from "sweetalert2";
import {API_URL} from "../../../../../Global_API_URL";
import {getCitiesById, getPincodesById, getStatesById} from "../../getCities.js";
import {CustomerVerificationService} from "../../../shared/customer-verification/customer-verification.service";


@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.sass']
})
export class CustomerEditComponent implements OnInit {

  customerId;

  constructor(private route: Router, private ActivatedRoute: ActivatedRoute, private httpService: HttpService, public customerVerification: CustomerVerificationService) {
    this.ActivatedRoute.params.subscribe((params) => {
      this.customerId = params['id'];
    })
  }

  ngOnInit(): void {
    this.getCustomerById(this.customerId)
    this.customerVerification.getOfficeLocations();
    this.customerVerification.resetCorporateCustomerData(this.customer_shipper);
  }


  customer_shipper = {
    id: null,
    firstName: '',
    lastName: '',
    address_1: '',
    city_1: {id: null},
    creditPeriod: null,
    credit_value: null,
    email_1: '',
    emailForMasterGST: '',
    zip_code: null,
    isCreditExtended: null,
    isKycApproved: null,
    kycApprovedAt: null,
    office_location: {id: null},
    phone_1: '',
    customerTypeId: 2,
    kycApprovedBy: null,
    isBusinessAccount: true,
    pan: '',
    gst: '',
    otp_varified: null,
    isSezCustomer: false,
    isContractualCustomer: false,
    gstDetails: '',
    gstVerified: false
  }

  getCustomerById(id) {
    this.httpService.get(API_URL + "/customer/" + id, null, null)
      .subscribe(response => {
        this.customer_shipper = response.custData[0];
        this.customerVerification.gstData = JSON.parse(response.custData[0].gstDetails)
        this.customer_shipper.zip_code = parseInt(this.customer_shipper.zip_code);
        this.getCityByCityId(this.customer_shipper.city_1.id);
        if (this.customer_shipper.gstVerified) {
          this.customerVerification.gstin = this.customer_shipper?.gst;
        }
      })
  }

  cityData;

  getCityByCityId(id) {
    this.httpService.get(API_URL + "/cities/" + id, null, null)
      .subscribe(response => {
        this.cityData = response;
        this.customerVerification.user_sel_state = this.cityData?.state_id?.id
        this.customerVerification.user_sel_country = this.cityData?.state_id?.country?.id;
        this.customerVerification.user_states = getStatesById(this.customerVerification.user_sel_country);
        this.customerVerification.user_cities = getCitiesById(this.customerVerification.user_sel_state);
        this.customerVerification.user_pincodes = getPincodesById(this.customer_shipper.city_1.id);
      })
  }


  editCustomer() {
    if (this.customer_shipper.firstName == null || this.customer_shipper.firstName == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Display Name ',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.firstName.length != this.customer_shipper.firstName.trim().length || this.customer_shipper.firstName.trim().length == 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Remove the spaces at beginning or end of the display name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.email_1 == null || this.customer_shipper.email_1 == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Company Email ',
        timer: 5000,
      });
      return;
    } else if (!(this.customerVerification.emailregex.test(this.customer_shipper.email_1))) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter A Valid Email',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.phone_1 == '' || this.customer_shipper.phone_1 == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Company Phone No.',
        timer: 5000,
      });
      return;
    } else if ((this.customer_shipper.phone_1).length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Phone No.',
        timer: 5000,
      });
      return;
    } else if (isNaN(parseInt(this.customer_shipper.phone_1)) == true) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Phone No.',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.address_1 == null || this.customer_shipper.address_1 == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Address',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_country == null || this.customerVerification.user_sel_country == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Country',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_state == null || this.customerVerification.user_sel_state == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The State',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.city_1.id == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The City',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.zip_code == null || this.customer_shipper.zip_code == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select the Zip Code',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.pan == null || this.customer_shipper.pan == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter the PAN Number',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper?.pan?.length > 0 && !this.customerVerification.panregex.test(this.customer_shipper.pan)) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter Valid PAN No. ',
        timer: 5000,
      });
    } else if (this.customer_shipper.office_location == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Billing Branch',
        timer: 5000,
      });
      return;
    } else {
      this.customer_shipper.gstVerified = true;
      this.httpService.patch(API_URL + '/customer/' + this.customer_shipper.id, this.customer_shipper, null, null)
        .subscribe(response => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Updated successfully',
            timer: 5000,
          }).then(r => {
            this.route.navigate(["/customers"]);
          });
        }, error => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error while updating customer details',
            timer: 5000,
          });
        })
    }
  }

  validateInput1(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[a-zA-Z0-9]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }


  transformPANoUpperCaseForCreate() {
    this.customer_shipper.pan = this.customer_shipper.pan.toUpperCase();
  }

  goBackToCustomerTable() {
    this.route.navigate(["/customers"]);
  }

}
