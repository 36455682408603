import {Component, Inject, OnInit} from '@angular/core';
import {ProductTypeService} from '../../productTypes/services/product-type.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConsignmentTypesModel} from '../models/consignment-types-model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {da} from 'date-fns/locale';


@Component({
  selector: 'createConsignmentDialog',
  templateUrl: './createConsignmentType.html',
  styleUrls: ['./createConsignmentTypeDialog.Sass'],
})
export class CreateConsignmentTypeDialog implements OnInit {
  constructor(private productTypeService: ProductTypeService, public dialogRef: MatDialogRef<CreateConsignmentTypeDialog>,
              @Inject(MAT_DIALOG_DATA) public data, private formBuilder: FormBuilder) {
  }

  consignmentForm: FormGroup;

  action: string;

  nameRegex: RegExp = /\S+/;

  nameWarning: boolean = false;

  detailsWarning: boolean = false;

  consignmentTypeData: ConsignmentTypesModel;

  ngOnInit(): void {
    this.consignmentTypeData = this.data.consignmentType;
    this.action = this.data.action;
    if (this.consignmentTypeData!=null) {
      this.consignmentForm = this.formBuilder.group({
        name: [this.consignmentTypeData.name],
        details: [this.consignmentTypeData.description],
        isActive: [this.consignmentTypeData.is_active],
      });
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSubmitClick() {
    if (this.consignmentForm.controls['name'].value !== null && this.consignmentForm.controls['name'].value.length>0 && this.nameRegex.test(this.consignmentForm.controls['name'].value)) {
      const newConsingmentType = new ConsignmentTypesModel(null, this.consignmentForm.controls['name'].value, this.consignmentForm.controls['details'].value, true);
      newConsingmentType.id = this.consignmentTypeData.id;
      this.dialogRef.close(newConsingmentType);
    } else {
      if (!this.nameRegex.test(this.consignmentForm.controls['name'].value) || this.consignmentForm.controls['name'].value.length==0) this.nameWarning = true;
      if (this.nameRegex.test(this.consignmentForm.controls['name'].value)) this.nameWarning = false;
    }
  }
}
