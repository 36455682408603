import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {API_URL} from '../../../../../Global_API_URL';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password-boxed',
  templateUrl: './forgot-password-boxed.component.html',
  styles: [],
})
export class ForgotPasswordBoxedComponent implements OnInit {
  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };

  password;
  show = false;


  constructor(private route : ActivatedRoute, private router : Router) { }

  ngOnInit() {
    // this.password = 'password';
    this.route.queryParams.subscribe((params) =>{
      // console.log(params);
      this.Token=params;
    },
    );
  }

  Token;
  passwordAgain;
  public showPassword: boolean;
  public showPasswordOnPress: boolean;
  async resetPassword() {
    if (this.password == null || this.password == '' || this.password.trim() == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Password ',
        timer: 5000,
      });
    } else if (this.password?.length < 6) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Password should have minimum of 6 characters',
        timer: 5000,
      });
    } else if (this.passwordAgain!= this.password) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Passwords Should Match',
        timer: 5000,
      });
    } else {
      await fetch(API_URL+'/reset-password'+'?token='+this.Token.token+'&password='+this.password,
          {
            method: 'POST',
            // body: JSON.stringify(this.indiviudal_user),
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
          })
          .then((result) => {
            // console.log(result);
            Swal.fire({
              icon: 'success',
              title: 'Updated',
              text: 'Password Updated Sucessfully',
              timer: 5000,
            });
            this.router.navigateByUrl('');
          });
    }
  }

  passLenCheck = false;
  passLengthCheck() {
    this.passLenCheck = this.password?.length < 6;
  }
  //
  // onClick() {
  //   if (this.password === 'password') {
  //     this.password = 'text';
  //     this.show = true;
  //   } else {
  //     this.password = 'password';
  //     this.show = false;
  //   }
  // }
}
