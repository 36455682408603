import {Injectable, ViewChild} from '@angular/core';
import Swal from "sweetalert2";
import {FormControl, Validators} from "@angular/forms";
import {API_URL} from "../../../../Global_API_URL";
import {HttpService} from "../../services/http.service";
import {getCitiesById, getCountries, getPincodesById, getStates, getStatesById} from "../../Layout/getCities.js";

@Injectable({
  providedIn: 'root'
})
export class CustomerVerificationService {

  constructor(private httpService: HttpService) {
  }

  progressBar = false;
  GSTVerified = false;
  PANVerified = false;
  AADHAARVerified = false;
  CustomerVerified = false;
  gstData;
  panData;
  aadhaarData;
  otpSentSuccessfully = false;
  customerImage;


  user_pincodes: any
  user_cities: any
  user_states: any
  user_countries: any

  user_sel_pincodes: any
  user_sel_city: any
  user_sel_state: string
  user_sel_country: string
  states = getStates();


  // **************************************** GST ***********************************************************

  gstin: string;

  gstVerification(customer_shipper, customerType, creationType) {
    if (!this.gstin == null || this.gstin == '') {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please Enter The GST No.",
        timer: 5000
      })
      return;
    } else if (!(this.gstregex.test(this.gstin))) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter Valid GST No.',
        timer: 5000,
      });
      return;
    } else {
      let params = "?" + "gstin=" + this.gstin;
      this.progressBar = true;
      this.httpService.get(API_URL + "/gst/verify" + params, null, null)
        .subscribe(response => {
          // 1000 is the status code for success. It is set by GSTIN team
          // if (response.code == 1000) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Verified Successfully",
            timer: 5000
          })
          this.GSTVerified = true;
          this.CustomerVerified = true;
          this.progressBar = false;
          this.gstData = response;
          customer_shipper.gstDetails = JSON.stringify(response);

          if (creationType == "create"){
            customer_shipper.firstName = this.gstData?.data?.tradeNam;
          }

          if (customerType == "corporate"){
            customer_shipper.gst = this.gstData?.data.gstin;
            customer_shipper.address_1 = this.gstData?.data?.pradr?.addr?.bno + ', '
              + this.gstData?.data?.pradr?.addr?.bnm + ', '
              + this.gstData?.data?.pradr?.addr?.st + ', '
              + this.gstData?.data?.pradr?.addr?.loc + ', '
              + this.gstData?.data?.pradr?.addr?.stcd + ', '
              + this.gstData?.data?.pradr?.addr?.dst + ', '
              + this.gstData?.data?.pradr?.addr?.pncd;
            customer_shipper.zip_code = parseInt(this.gstData?.data?.pradr?.addr?.pncd);

            this.getPincode(customer_shipper.zip_code)
              .subscribe(response => {
                this.pincodeData = response.pincodeData
                this.user_sel_country = this.pincodeData.city.state_id.country.id;
                this.user_sel_state = this.pincodeData.city.state_id.id;
                customer_shipper.city_1.id = this.pincodeData.city.id;
                customer_shipper.zip_code = this.pincodeData.pin_number;
                this.user_states = getStatesById(this.user_sel_country);
                this.user_cities = getCitiesById(this.user_sel_state);
                this.user_pincodes = getPincodesById(customer_shipper.city_1.id);
              })
          }
          else if (customerType == "retail"){
            customer_shipper.gstNumber = this.gstData?.data.gstin;

            if (creationType == "create"){
              customer_shipper.billingAddress.address = this.gstData?.data?.pradr?.addr?.bno + ', '
                + this.gstData?.data?.pradr?.addr?.bnm + ', '
                + this.gstData?.data?.pradr?.addr?.st + ', '
                + this.gstData?.data?.pradr?.addr?.loc + ', '
                + this.gstData?.data?.pradr?.addr?.stcd + ', '
                + this.gstData?.data?.pradr?.addr?.dst + ', '
                + this.gstData?.data?.pradr?.addr?.pncd;
              customer_shipper.billingAddress.pincode.pin_number = parseInt(this.gstData?.data?.pradr?.addr?.pncd);

              this.getPincode(customer_shipper.billingAddress.pincode.pin_number)
                .subscribe(response => {
                  this.pincodeData = response.pincodeData
                  this.user_sel_country = this.pincodeData.city.state_id.country.id;
                  this.user_sel_state = this.pincodeData.city.state_id.id;
                  this.user_sel_city = this.pincodeData.city.id;
                  this.user_states = getStatesById(this.user_sel_country);
                  this.user_cities = getCitiesById(this.user_sel_state);
                  this.user_pincodes = getPincodesById(this.user_sel_city);
                })
            }


          }

        }, error => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.error.message,
            timer: 5000
          })
          this.progressBar = false;
        })
    }
  }


  // **************************************** PAN ***********************************************************


  panReqBody = {
    "number": "",
    "returnIndividualTaxComplianceInfo": ""
  }

  panVerification(customer_shipper, creationType) {
    if (!this.panReqBody.number == null || this.panReqBody.number == '') {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please Enter The PAN No.",
        timer: 5000
      })
      return;
    } else if (!(this.panregex.test(this.panReqBody.number))) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter Valid PAN No.',
        timer: 5000,
      });
      return;
    }else {
      this.progressBar = true;
      if (this.panReqBody.returnIndividualTaxComplianceInfo){
        this.panReqBody.returnIndividualTaxComplianceInfo = "true"
      } else {
        this.panReqBody.returnIndividualTaxComplianceInfo = ""
      }
      this.httpService.post(API_URL + "/pan/verify", this.panReqBody, null, null)
        .subscribe(response => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Verified Successfully",
              timer: 5000
            })
            this.PANVerified = true;
            this.CustomerVerified = true;
            this.progressBar = false;
            this.panData = response;
            customer_shipper.panDetails = JSON.stringify(response);
            customer_shipper.panNumber = this.panData?.number;
            if (creationType == "create"){
              customer_shipper.firstName = this.panData?.firstName;
              customer_shipper.middleName = this.panData?.middleName;
              customer_shipper.lastName = this.panData?.lastName;
            }
        }, error => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Not Verified",
            timer: 5000
          })
          this.progressBar = false;
        })
    }
  }


  // **************************************** AADHAAR ***********************************************************

  aadhaarGenerateOtpReqBody = {
    "aadhaarNumber": ""
  }

  requestId = "";

  aadhaarGenerateOtp(){
    if (this.aadhaarGenerateOtpReqBody.aadhaarNumber == null || this.aadhaarGenerateOtpReqBody.aadhaarNumber == '') {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please Enter The Aadhaar No.",
        timer: 5000
      })
      return;
    } else if (this.aadhaarGenerateOtpReqBody.aadhaarNumber.length < 12) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Aadhaar number must be 12 digits",
        timer: 5000
      })
      return;
    } else {
      this.progressBar = true;
      this.httpService.post(API_URL + "/aadhaar/generateOtp", this.aadhaarGenerateOtpReqBody, null, null)
        .subscribe(response => {
          this.otpSentSuccessfully = true;
          this.requestId = response.requestId
          this.progressBar = false;
          this.isOTPSent = true;
          setTimeout(() => {
            this.isOTPResend = true;
            this.isOTPSent = false;
          }, 1000 * 61);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "OTP Sent To Your Registered Mobile Number",
            timer: 5000
          })
        }, error => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.error.message,
            timer: 5000
          })
          this.progressBar = false;
        })
    }
  }


  aadhaarVerifyOtpReqBody = {
    "requestId": "",
    "otp": ""
  }


  aadhaarVerification(customer_shipper, creationType){
    if (this.aadhaarVerifyOtpReqBody.otp == null || this.aadhaarVerifyOtpReqBody.otp == "") {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please Enter OTP",
        timer: 5000
      })
      return;
    } else if (this.aadhaarVerifyOtpReqBody.otp.length != 6) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "OTP must be 6 digits",
        timer: 5000
      })
      return;
    } else{
      this.progressBar = true;
      this.aadhaarVerifyOtpReqBody.requestId = this.requestId;
      this.httpService.post(API_URL + "/aadhaar/verify" , this.aadhaarVerifyOtpReqBody, null, null)
        .subscribe(response => {
          this.progressBar = false;
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Verified Successfully",
            timer: 5000
          })
          this.AADHAARVerified = true;
          this.CustomerVerified = true;
          this.aadhaarData = response;
          customer_shipper.aadhaarDetails = JSON.stringify(response);
          customer_shipper.aadhaarNumber = this.aadhaarData?.aadhaar_number;
          this.customerImage =  'data:application/png;base64,' + this.aadhaarData?.profile_image;

          if (creationType == "create"){
            customer_shipper.firstName = this.aadhaarData?.full_name;
            customer_shipper.billingAddress.address = this.aadhaarData?.address?.house + ', '
              + this.aadhaarData?.address?.street + ', '
              + this.aadhaarData?.address?.landmark + ', '
              + this.aadhaarData?.address?.loc + ', '
              + this.aadhaarData?.address?.vtc + ', '
              + this.aadhaarData?.address?.dist + ', '
              + this.aadhaarData?.address?.state + ', '
              + this.aadhaarData?.address?.country + ', '
              + this.aadhaarData?.zip;
            customer_shipper.billingAddress.pincode.pin_number = parseInt(this.aadhaarData?.zip);

            this.getPincode(customer_shipper.billingAddress.pincode.pin_number)
              .subscribe(response => {
                this.pincodeData = response.pincodeData
                this.user_sel_country = this.pincodeData.city.state_id.country.id;
                this.user_sel_state = this.pincodeData.city.state_id.id;
                this.user_sel_city = this.pincodeData.city.id;
                this.user_states = getStatesById(this.user_sel_country);
                this.user_cities = getCitiesById(this.user_sel_state);
                this.user_pincodes = getPincodesById(this.user_sel_city);
              })
          }

        }, error => {
          if (error.error.message == "Invalid OTP"){
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Invalid OTP",
              timer: 5000
            })
            this.progressBar = false;
          } else{
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Not Verified",
              timer: 5000
            })
            this.progressBar = false;
          }
        })
    }
  }



  isOTPSent = false;
  isOTPResend = false;

  sendOTP() {
    this.aadhaarGenerateOtp();
  }

  onOtpChange(event) {
    this.aadhaarVerifyOtpReqBody.otp = event;
  }

  onTimerComplete() {
    this.isOTPResend = true;
  }

  onTimerTick(event) {
    // Handle tick events if necessary
  }


  // ***************************************************************************


  resetCorporateCustomerData(customer_shipper) {
    this.gstin = "";
    this.officesList = null;

    this.progressBar = false;
    this.GSTVerified = false;
    this.CustomerVerified = false;
    this.gstData = null;
    this.user_cities = null;
    this.user_states = null;
    this.user_pincodes = null;
    this.user_countries = null;
    this.user_sel_state = null;
    this.user_sel_country = null;
    this.user_sel_pincodes = null;

    customer_shipper = {
      id: null,
      firstName: '',
      lastName: '',
      address_1: '',
      city_1: {id: null},
      creditPeriod: null,
      credit_value: null,
      email_1: '',
      emailForMasterGST: '',
      zip_code: null,
      isCreditExtended: null,
      isKycApproved: null,
      kycApprovedAt: null,
      office_location: {id: null},
      phone_1: '',
      customerTypeId: 2,
      kycApprovedBy: null,
      isBusinessAccount: true,
      pan: '',
      gst: '',
      otp_varified: null,
      isSezCustomer: false,
      isContractualCustomer: false,
      gstDetails: '',
      gstVerified: false
    }
  }

  resetRetailCustomerData(customer_shipper) {
    this.gstin = "";

    this.panReqBody.number = "";
    this.panReqBody.returnIndividualTaxComplianceInfo = "";

    this.aadhaarGenerateOtpReqBody.aadhaarNumber = "";
    this.aadhaarVerifyOtpReqBody.requestId = "";
    this.aadhaarVerifyOtpReqBody.otp = "";
    this.requestId = "";
    this.isOTPSent = false;
    this.isOTPResend = false;


    this.progressBar = false;
    this.GSTVerified = false;
    this.PANVerified = false;
    this.AADHAARVerified = false;
    this.CustomerVerified = false;
    this.gstData = null;
    this.panData = null;
    this.aadhaarData = null;
    this.otpSentSuccessfully = false;
    this.customerImage = null;

    this.user_pincodes = null;
    this.user_cities = null;
    this.user_states = null;
    this.user_countries = null;

    this.user_sel_pincodes = null;
    this.user_sel_city = null;
    this.user_sel_state = null;
    this.user_sel_country = null;

    customer_shipper = {
      id: null,
      firstName: '',
      middleName: '',
      lastName: '',
      avatarUrl: '',
      email: '',
      emailForMasterGST: '',
      phone: '',
      whatsapp: '',
      designation: null,
      employmentNo: null,
      billingAddress: {
        pincode: {
          pin_number: ''
        },
        address: ''
      },
      isSuperUser: null,
      userName: '',
      passwordHash: null,
      lastLoggedInAt: null,
      lastLoggedInIp: null,
      aadhaarNumber: null,
      drivingLicense: null,
      gstNumber: null,
      panNumber: null,
      gstDetails: null,
      panDetails: null,
      aadhaarDetails: null,
      isActive: true,
      createdAt: null,
      updatedAt: null,
      createdBy: null,
      updatedBy: null,
    }
  }

  pincodeData;

  getPincode(pincode) {
    return this.httpService.get(API_URL + "/pincode/" + pincode, null, null);  // pincode means pin_number
  }

  countries = getCountries();

  getIndividaulStates(customer_shipper, customerType) {
    this.user_states = getStatesById(this.user_sel_country);
    this.user_sel_state = null;
    if (customerType == "corporate"){
      customer_shipper.city_1.id = null;
      customer_shipper.zip_code = '';
    }
    else if (customerType == "retail" || customerType == "representative" || customerType == "user"){
      this.user_sel_city = null;
      customer_shipper.billingAddress.pincode.pin_number = null;
    }
  }

  getIndividaulCities(customer_shipper, customerType) {
    this.user_cities = getCitiesById(this.user_sel_state);
    if (customerType == "corporate"){
      customer_shipper.city_1.id = null;
      customer_shipper.zip_code = '';
    }
    else if (customerType == "retail" || customerType == "representative" || customerType == "user"){
      this.user_sel_city = null;
      customer_shipper.billingAddress.pincode.pin_number = null;
    }
  }

  getIndividaulPincodes(customer_shipper, customerType) {
    if (customerType == "corporate"){
      customer_shipper.zip_code = null;
      this.user_pincodes = getPincodesById(customer_shipper?.city_1.id);
    }
    else if (customerType == "retail" || customerType == "representative" || customerType == "user"){
      customer_shipper.billingAddress.pincode.pin_number = null;
      this.user_pincodes = getPincodesById(this.user_sel_city);
    }
  }

  officesList;

  getOfficeLocations() {
    this.httpService.get(API_URL + '/office_locations', null, null)
      .subscribe(response => {
        this.officesList = response.data.sort((a, b) => a.name.localeCompare(b.name));
      })
  }

  companyNames;

  getCompaniesList(){
    this.httpService.get(API_URL + '/totalcustomers', null, null)
      .subscribe(response => {
        this.companyNames = response;
      })
  }

  emailExist = false;
  async emailExistAppuser(email): Promise<boolean> {
    const response = await this.httpService.get(API_URL + "/appuser/email/" + email, null, null).toPromise().catch(error => {
      this.emailExist = false;
      return false;
    });

    if (response.data[0] != null) {
      this.emailExist = true;
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Email Already Exists',
        timer: 5000,
      });
      return true;
    } else {
      this.emailExist = false;
      return false;
    }
  }

  phoneNoExist = false;
  phoneNoExistAppuser(phone) {
    this.httpService.get(API_URL + '/appuser/phone/' + phone, null, null)
      .subscribe((response) => {
        if (response.data[0] != null){
          this.phoneNoExist = true;
          Swal.fire({
            icon: 'warning',
            title: 'Warning',
            text: 'Phone No. Already Exists',
            timer: 5000,
          });
        } else{
          this.phoneNoExist = false;
        }
      }, (error) => {
        console.error('Error' + error);
      });
  }

  transformGSTToUpperCaseForCreate() {
    this.gstin = this.gstin.toUpperCase();
  }

  transformPANToUpperCaseForCreate() {
    this.panReqBody.number = this.panReqBody.number.toUpperCase();
  }

  validateInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[a-zA-Z0-9]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateInputInt(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[0-9]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  emailregex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
  emailFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.emailregex),
  ]);
  //
  gstregex = /^[0-9][0-9][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][0-9][0-9][0-9][0-9][a-zA-Z][0-9a-zA-Z][a-zA-Z][0-9a-zA-Z]$/
  gstFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.gstregex),
  ]);

  panregex = /^[a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][0-9][0-9][0-9][0-9][a-zA-Z]$/
  panFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.panregex),
  ]);


}
