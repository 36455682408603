<ngx-loading [show]="loading"
  [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
  [template]="loadingTemplate"></ngx-loading>

<div class="main-card mb-3 card">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-bold">
      <i class="fa fa-arrow-left" (click)="backToIRNGenerateView()"
        style="cursor: pointer; color: blue; margin-right: 10px;"></i>
      <b style="color: blue;">EDIT INVOICE DETAILS</b>
    </div>
  </div>
  <div class="container m-2 ">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-2 fw-bold">Invoice No</div>
          <div class="col-md-1 fw-bold"> - </div>
          <div class="col-md-3"> {{invoiceData?.invoiceNo}} </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-2 fw-bold">IRN Number</div>
          <div class="col-md-1 fw-bold"> - </div>
          <div class="col-md-3">{{ invoiceData?.irnNumber || 'N/A'}}</div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-2 fw-bold border-start">Acknowledgement No</div>
          <div class="col-md-1 fw-bold"> - </div>
          <div class="col-md-3">{{ invoiceData?.acknowledgementNumber || 'N/A' }}</div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-2 fw-bold border-start">IRN Generated At</div>
          <div class="col-md-1 fw-bold"> - </div>
          <div class="col-md-3">{{ invoiceData?.irnGeneratedAt | date: 'MMM d, y, h:mm a' || 'N/A' }}</div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-2 fw-bold border-start">QR Code</div>
          <div class="col-md-1 fw-bold"> - </div>
          <div class="col-md-8">{{ invoiceData?.qrCode || 'N/A' }}</div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-2 fw-bold border-start">Remarks</div>
          <div class="col-md-1 fw-bold"> - </div>
          <div class="col-md-8">
            <textarea id="remarks" class="form-control" #remarks maxlength="200" [(ngModel)]="invoiceData.remarks"
              name="remarks" rows="4" required></textarea>
            <div class="text-muted position-absolute end-0 me-3">{{remarks.value.length}}/200</div>
          </div>
        </div>
        <br>
        <div class="btn-actions-pane-right p-2" style="margin-top: -2%; margin-bottom: 2%; margin-right: 100%">
          <div class="mb-2 me-2 btn" style="background-color: red; color: white" (click)="backToIRNGenerateView()">
            Cancel
          </div>
          <div class="mb-2 me-2 btn btn-primary" (click)="patchInvoieByID()">Save</div>
        </div>
      </div>
    </div>
  </div>
</div>