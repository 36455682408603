import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { HttpHeaders } from '@angular/common/http';
import { API_URL } from '../../../../../Global_API_URL';
import { HttpService } from 'src/app/services/http.service';
import { AuthService } from 'src/app/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-airline-pricing-edit',
  templateUrl: './airline-pricing-edit.component.html',
  styleUrls: ['./airline-pricing-edit.component.sass']
})
export class AirlinePricingEditComponent implements OnInit {

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  airlinePricingId;
  airlinePricingData: any = {};
  TOKEN;
  userDetails;
  displayToAdmin = false;
  displayToSupervisor = false;

  constructor(private ActivatedRoute: ActivatedRoute, private authService: AuthService, private httpService: HttpService, private router: Router) {
    this.TOKEN = this.authService.getAuthToken();
    this.ActivatedRoute.params.subscribe((params) => {
      this.airlinePricingId = params['id'];
    });
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0] == 'Admin') {
      this.displayToAdmin = true;
    }
    if (this.userDetails.userTypes[0] == 'Supervisor') {
      this.displayToSupervisor = true;
    }
    this.getAirlinePricingById(this.airlinePricingId);
  }

  getAirlinePricingById(id) {
    this.loading = true;
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    });
    this.httpService.get(API_URL + '/pricing/' + id, null, headers)
      .subscribe((response) => {
        this.airlinePricingData = response.data[0];
        this.loading = false;
      }, (error) => {
        console.error('Error:', error);
        this.loading = false;
      });
  }

  backToAirlinePricingViewPage() {
    this.router.navigate(['airlinePricingView', this.airlinePricingId])
  }

  goToAirlinePricingPage() {
    this.router.navigate(['airlinePricing'])
  }

  async editairpricing() {
    const TOKEN = this.authService.getAuthToken();
    const users = {
      'id': this.airlinePricingData.id,
      'airline': this.airlinePricingData.airline.id,
      'origin': this.airlinePricingData.origin.id,
      'destination': this.airlinePricingData.destination.id,
      'consignment_type': this.airlinePricingData.consignmentType.id,
      'applicable_flights': this.airlinePricingData.applicable_flights,
      'applicable_commodity': this.airlinePricingData.applicable_commodity,
      'airline_tariff_min': this.airlinePricingData.airlineTariffMin,
      'airline_tariff_n_rate': this.airlinePricingData.airlineTariffNRate,
      'airline_tariff_above_45': this.airlinePricingData.airlineTariffAbove45,
      'airline_tariff_above_100': this.airlinePricingData.airlineTariffAbove100,
      'airline_tariff_above_300': this.airlinePricingData.airlineTariffAbove300,
      'airline_tariff_above_500': this.airlinePricingData.airlineTariffAbove500,
      'outbound_tsp_perkg': this.airlinePricingData.outboundTspPerkg,
      'outbound_tsp_min': this.airlinePricingData.outboundTspMin,
      'outbound_cash_tsp_perkg': this.airlinePricingData.outboundCashTspPerkg,
      'outbound_cash_tsp_min': this.airlinePricingData.outboundCashTspMin,
      'outbound_unit_perkg': this.airlinePricingData.outboundUnitPerkg,
      'outbound_unit_min': this.airlinePricingData.outboundUnitMin,
      'outbound_handling_charges_perkg': this.airlinePricingData.outboundHandlingChargesPerkg,
      'outbound_handling_charges_min': this.airlinePricingData.outboundHandlingChargesMin,
      'outbound_xray_screening_perkg': this.airlinePricingData.outboundXrayScreeningPerkg,
      'outbound_xray_screening_min': this.airlinePricingData.outboundXrayScreeningMin,
      'outbound_xray_certification_perkg': this.airlinePricingData.outboundXrayCertificationPerkg,
      'outbound_xray_certification_min': this.airlinePricingData.outboundXrayCertificationMin,
      'outbound_origin_scc_perkg': this.airlinePricingData.outboundOriginSccPerkg,
      'outbound_origin_scc_min': this.airlinePricingData.outboundOriginSccMin,
      'outbound_total_ahc': this.airlinePricingData.outboundTotalAhc,
      'inbound_tsp_perkg': this.airlinePricingData.inboundTspPerkg,
      'inbound_tsp_min': this.airlinePricingData.inboundTspMin,
      'inbound_cash_tsp_perkg': this.airlinePricingData.inboundCashTspPerkg,
      'inbound_cash_tsp_min': this.airlinePricingData.inboundCashTspMin,
      'inbound_handling_charges_perkg': this.airlinePricingData.inboundHandlingChargesPerkg,
      'inbound_handling_charges_min': this.airlinePricingData.inboundHandlingChargesMin,
      'inbound_unit_perkg': this.airlinePricingData.inboundUnitPerkg,
      'inbound_unit_min': this.airlinePricingData.inboundUnitMin,
      'inbound_total_ahc': this.airlinePricingData.inboundTotalAhc,
      'other_charges_sercurity_perkg': this.airlinePricingData.otherChargesSercurityPerkg,
      'other_charges_sector_surcharge_perkg': this.airlinePricingData.otherChargesSectorSurchargePerkg,
      'other_charges_fligh_surcharge_perkg': this.airlinePricingData.otherChargesFlighSurchargePerkg,
      'mawb_stat_da': this.airlinePricingData.mawbStatDa,
      'mawb_do': this.airlinePricingData.mawbDo,
      'mawb_misc_perkg': this.airlinePricingData.mawbMiscPerkg,
      'mawb_misc_fixed': this.airlinePricingData.mawbMiscFixed,
      'mawb_other_charge': this.airlinePricingData.mawbOtherCharge,
      'iclscm_handling_perkg': this.airlinePricingData.iclscmHandlingPerkg,
      'iclscm_handling_min': this.airlinePricingData.iclscmHandlingMin,
      'freight_attached_consignment_perkg': this.airlinePricingData.freightAttachedConsignmentPerkg,
      'freight_attached_consignment_min': this.airlinePricingData.freightAttachedConsignmentMin,
      'human_remains_perkg': this.airlinePricingData.humanRemainsPerkg,
      'human_remains_min': this.airlinePricingData.humanRemainsMin,
      'livestock_perkg': this.airlinePricingData.livestockPerkg,
      'livestock_min': this.airlinePricingData.livestockMin,
      'livestock_pkg_charge': this.airlinePricingData.livestockPkgCharge,
      'shirmps_sea_food_perkg': this.airlinePricingData.shirmpsSeaFoodPerkg,
      'shirmps_sea_food_min': this.airlinePricingData.shirmpsSeaFoodMin,
      'perishable_charge_perkg': this.airlinePricingData.perishableChargePerkg,
      'perishable_charge_min': this.airlinePricingData.perishableChargeMin,
      'dryice_fixed': this.airlinePricingData.dryiceFixed,
      'heavy_cargo_perkg': this.airlinePricingData.heavyCargoPerkg,
      'heavy_cargo_min': this.airlinePricingData.heavyCargoMin,
      'valuve_cargo_perkg': this.airlinePricingData.valuveCargoPerkg,
      'valuve_carge_min': this.airlinePricingData.valuveCargeMin,
      'shipment_value_perkg': this.airlinePricingData.shipmentValuePerkg,
      'shipment_value_min': this.airlinePricingData.shipmentValueMin,
      'demurage_perkg': this.airlinePricingData.demuragePerkg,
      'demurage_min': this.airlinePricingData.demurageMin,
      'cold_chain_perkg': this.airlinePricingData.coldChainPerkg,
      'cold_chain_min': this.airlinePricingData.coldChainMin,
      'door_pickup_perkg': this.airlinePricingData.doorPickupPerkg,
      'door_pickup_min': this.airlinePricingData.doorPickupMin,
      'door_pickup_opa': this.airlinePricingData.doorPickupOpa,
      'door_delivery_perkg': this.airlinePricingData.doorDeliveryPerkg,
      'door_delivery_min': this.airlinePricingData.doorDeliveryMin,
      'door_delivery_oda': this.airlinePricingData.doorDeliveryOda,
      'dangerous_goods_perkg': this.airlinePricingData.dangerousGoodsPerkg,
      'dangerous_goods_min': this.airlinePricingData.dangerousGoodsMin,
      'special_cargo_perkg': this.airlinePricingData.specialCargoPerkg,
      'special_cargo_min': this.airlinePricingData.specialCargoMin,
      'cansoul_charges_perkg': this.airlinePricingData.cansoulChargesPerkg,
      'cansoul_charges_min': this.airlinePricingData.cansoulChargesMin,
      'airline_surcharge_perkg': this.airlinePricingData.airlineSurchargePerkg,
      'airline_surcharge_min': this.airlinePricingData.airlineSurchargeMin,
      'additional_surcharge_perkg': this.airlinePricingData.additionalSurchargePerkg,
      'net_rate_100kg_slab': this.airlinePricingData.netRate100kgSlab,
      'discount_min': this.airlinePricingData.discountMin,
      'discount_perkg': this.airlinePricingData.discountPerkg,
      'is_active': true,
    };
    await fetch(API_URL + '/airline_pricing1/' + users.id, {
      method: 'PATCH',
      body: JSON.stringify(users),
      headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((result) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Updated successfully',
          showConfirmButton: false,
          timer: 5000,
        });
        this.goToAirlinePricingPage();
      })
      .catch((error) => console.error('error', error));
    return this.goToAirlinePricingPage();
  }

}
