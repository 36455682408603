import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { API_URL } from '../../../../Global_API_URL';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AirlinePromotionsService {
  constructor(private httpService: HttpService) { }

  getAllAirlinePromotions(pageNo, pageSize, sortBy, direction, cnType?, cnSubType?, productMode?, airline?, origin?, destination?, validFrom?, validTo?) {
    let params: HttpParams = new HttpParams();
    if (pageNo != null) {
      params = params.set('pageNo', pageNo);
    }

    if (pageSize != null) {
      params = params.set('pageSize', pageSize);
    }

    if (sortBy != null) {
      params = params.set('sortBy', sortBy);
    }

    if (direction != null) {
      params = params.set('direction', direction);
    }
    if (cnType != null) {
      params = params.set('cnType', cnType.toString());
    }

    if (cnSubType != null) {
      params = params.set('cnSubType', cnSubType.toString());
    }

    if (productMode != null) {
      params = params.set('productMode', productMode.toString());
    }

    if (airline != null) {
      params = params.set('airline', airline.toString());
    }

    if (origin != null) {
      params = params.set('origin', origin.toString());
    }

    if (destination != null) {
      params = params.set('destination', destination.toString());
    }

    if (validFrom != null) {
      params = params.set('validFrom', validFrom.toString());
    }

    if (validTo != null) {
      params = params.set('validTo', validTo.toString());
    }

    return this.httpService.get(API_URL + '/airline-promotions/plansList', params, null);
  }

  postAirlinePromotion(body) {
    return this.httpService.post(API_URL + '/airline-promotions', body, null, null);
  }

  patchAirlinePromotion(id, body) {
    return this.httpService.patch(API_URL + '/airline-promotions/plan/' + id, body, null, null);
  }
}
