<div *ngIf="isLoading" class="loader-container">
  <ngx-loading [show]="isLoading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: '#0000ff', secondaryColour: '#00f', backdropBorderRadius: '3px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>
<mat-card>
  <mat-card-title class="title-container">
    <div class="left-section">
      <button mat-icon-button>
        <mat-icon (click)="goBack()">arrow_back</mat-icon>
      </button>
      <h4 class="title-text">CREATE CUSTOMER CONTRACT</h4>
    </div>
    <div>
      <button mat-button color="primary" class="download-btn" (click)="downloadCSVRegionFormat()">
        <mat-icon>download</mat-icon>
        Region Format
      </button>
      <button mat-button color="primary" class="download-btn" (click)="downloadCSVCityFormat()">
        <mat-icon>download</mat-icon>
        City Format
      </button>
    </div>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>
    <form [formGroup]="contractFormGroup" class="contract-form" (ngSubmit)="createCustomerContract()">
      <mat-form-field appearance="outline">
        <mat-label>Customer</mat-label>
        <input type="text"
               placeholder="Select a Customer"
               matInput
               required
               formControlName="customer"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div style="display: flex; gap: 1rem">
        <mat-form-field appearance="outline">
          <mat-label>Valid From</mat-label>
          <input matInput required placeholder="Select a date" formControlName="validFrom" (ngModelChange)="updateStartDate($event)" [matDatepicker]="startDate" [min]="minStartDate">
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Valid Upto</mat-label>
          <input matInput required formControlName="validUpto" placeholder="Select a Date" (ngModelChange)="updateEndDate($event)" [matDatepicker]="endDate" [min]="minEndDate">
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput placeholder="Add a description" formControlName="description" #description maxlength="150"></textarea>
        <mat-icon matSuffix>edit</mat-icon>
        <div class="text-muted position-absolute end-0 me-3">{{description.value.length}}/150</div>
      </mat-form-field>

      <div class="file-upload-section">
        <div class="file-upload">
          <label>Contractual Price for Regions:</label>
          <div class="file-input-wrapper">
            <div>
              <input type="file" #regionFileInput class="form-control" (change)="onRegionFileSelected($event)" accept=".csv">
            </div>
          </div>
        </div>

        <div class="file-upload">
          <label>Contractual Price for Cities:</label>
          <div class="file-input-wrapper">
            <div>
              <input type="file" #cityFileInput class="form-control" (change)="onCityFileSelected($event)" accept=".csv">
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="action-buttons">
          <button mat-flat-button color="warn" type="reset" style="color: white" (click)="goBack()">Discard</button>
          <button type="submit" mat-flat-button color="primary">Create</button>
        </div>
      </div>
    </form>




  </mat-card-content>
</mat-card>
