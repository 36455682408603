<!-- Loading Spinner Component -->
<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px', animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: '#3f6ad8', secondaryColour: '#ccc' }"
></ngx-loading>

<div>
  <mat-card style="min-height: 30rem">
    <div style="display: flex; flex-direction: row;">
      <div style=" width: 30%">
        <table mat-table [dataSource]="productTypeList" style="margin-top: 0.5rem;" class="mat-elevation-z5 relational_product-type_table">
          <!-- Name Column -->
          <ng-container matColumnDef="Name">
            <th mat-header-cell style="color: #065ddc" *matHeaderCellDef>Product Types</th>
            <td mat-cell *matCellDef="let element">{{element?.name}} <i *ngIf="isProductTypeRowSelected(element?.name)" style="float: right" class="fa fa-arrow-right"></i></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="relationTableColumns"></tr>
          <tr mat-row class="hoverForClick"
              (click)="selectProductType(i)"
              [class.relational_product-type_table_selected]="isProductTypeRowSelected(row.name)"
              *matRowDef="let row; columns: relationTableColumns; let i = index;"></tr>
        </table>
      </div>
      <div style="width: 30%; margin-left: 1rem;">
        <div class="card-header-title font-size-lg text-capitalize fw-normal" style="margin-left: 1rem">
        </div>
        <table #consignmentTypesModelMatTable mat-table [dataSource]="selectedProductTypeConsignmentTypes" style=" width: 100%; margin-top: 0.5rem;" class="mat-elevation-z5 relational_consignment-type_table">
          <!-- Name Column -->
          <ng-container matColumnDef="Name">
            <th mat-header-cell style="color: #065ddc" *matHeaderCellDef>Consignment Types</th>
            <td mat-cell *matCellDef="let element">{{element?.name}}  <i *ngIf="isConsignmentTypeRowSelected(element?.name)" style="float: right" class="fa fa-arrow-right"></i></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="relationTableColumns"></tr>
          <tr mat-row [ngClass]="{'hoverForClick': isRemoveConsignmentTypeProductType!=true, 'hoverForRemove': isRemoveConsignmentTypeProductType==true}"
              (click)="selectOrRemoveConsignmentType(i)"
              [class.relational_product-type_table_selected]="isConsignmentTypeRowSelected(row.name)"
              *matRowDef="let row; columns: relationTableColumns; let i = index"></tr>
        </table>

        <div *ngIf="selectedProductTypeIndex!=null">
          <div *ngIf="productTypeList!=null && productTypeList[selectedProductTypeIndex].consignment_types!=null && productTypeList[selectedProductTypeIndex].consignment_types.length>0 && isRemoveConsignmentTypeProductType==false && selectedProductTypeConsignmentTypes?.data.length<consignmentTypes?.length" style="display: flex; flex-direction: row;">
            <button class="hoverForClick" *ngIf="!displaytoAccountant && selectedProductTypeIndex!=null && selectedProductTypeConsignmentTypes?.data[0].name!='No Product Selected'" mat-raised-button style="margin-top: 0.2rem; width: 50%; color: #3F69D6FF" (click)="openConsignmentTypeDialog()">+ Add</button>
            <button class="hoverForClick" *ngIf="!displaytoAccountant && productTypeList!=null && productTypeList[selectedProductTypeIndex].consignment_types!=null && productTypeList[selectedProductTypeIndex].consignment_types.length>0 && selectedProductTypeIndex!=null && selectedProductTypeConsignmentTypes?.data[0].name!='No Product Selected'" mat-raised-button style="margin-top: 0.2rem; margin-left: 0.2rem; width: 50%; color: #f44336" (click)="enableRemoveConsignmentType()">X Remove</button>
          </div>
          <div *ngIf="productTypeList!=null && (productTypeList[selectedProductTypeIndex].consignment_types==null || productTypeList[selectedProductTypeIndex].consignment_types.length==0) && isRemoveConsignmentTypeProductType==false && selectedProductTypeConsignmentTypes?.data.length<consignmentTypes?.length" style="display: flex; flex-direction: row;">
            <button class="hoverForClick" *ngIf="!displaytoAccountant && selectedProductTypeIndex!=null && selectedProductTypeConsignmentTypes?.data[0].name!='No Product Selected'" mat-raised-button style="margin-top: 0.2rem; width: 100%; color: #3F69D6FF" (click)="openConsignmentTypeDialog()">+ Add</button>
          </div>
          <div *ngIf="isRemoveConsignmentTypeProductType==false && selectedProductTypeConsignmentTypes?.data.length==consignmentTypes?.length" style="display: flex; flex-direction: row;">
            <button class="hoverForClick" *ngIf="!displaytoAccountant && selectedProductTypeIndex!=null && selectedProductTypeConsignmentTypes?.data[0].name!='No Product Selected'" mat-stroked-button color="warn" style="margin-top: 0.2rem; width: 100%" (click)="enableRemoveConsignmentType()">X Remove</button>
          </div>
          <div *ngIf="isRemoveConsignmentTypeProductType==true">
            <button class="hoverForClick" *ngIf="!displaytoAccountant && selectedProductTypeIndex!=null && selectedProductTypeConsignmentTypes?.data[0].name!='No Product Selected'" mat-raised-button style="margin-top: 0.2rem; width: 100%" (click)="cancelRemoval()">Cancel</button>
          </div>
        </div>

      </div>
      <div style="width: 30%; margin-left: 1rem;">
        <div class="card-header-title font-size-lg text-capitalize fw-normal" style="margin-left: 1rem">
        </div>
        <table mat-table [dataSource]="selectedProductTypeConsignmentTypeConsignmentSubTypes" style=" width: 100%; margin-top: 0.5rem;" class="mat-elevation-z5 relational_consignment-sub-type_table">
          <!-- Name Column -->
          <ng-container matColumnDef="Name">
            <th mat-header-cell style="color: #065ddc" *matHeaderCellDef>Consignment SubTypes</th>
            <td mat-cell *matCellDef="let element">{{element?.name}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="relationTableColumns"></tr>
          <tr mat-row
              (click)="RemoveConsignmentSubtype(i)"
              [ngClass]="{'hoverForRemove' : isRemoveConsignmentSubtypeConsignmentTypeProductType==true}"
              *matRowDef="let row; columns: relationTableColumns; let i = index;"></tr>
        </table>
        <div *ngIf="selectedProductTypeIndex!=null">
          <div *ngIf="productTypeList!=null && (productTypeList[selectedProductTypeIndex].consignment_types!=null || productTypeList[selectedProductTypeIndex].consignment_types.length>0) && productTypeList[selectedProductTypeIndex].consignment_types[selectedProductTypeConsignmentTypeIndex]?.consignment_sub_types_list!=null && productTypeList[selectedProductTypeIndex].consignment_types[selectedProductTypeConsignmentTypeIndex]?.consignment_sub_types_list.length>0 && isRemoveConsignmentSubtypeConsignmentTypeProductType==false" style="display: flex; flex-direction: row;">
            <button class="hoverForClick" *ngIf="!displaytoAccountant && selectedProductTypeConsignmentTypes!=null && selectedProductTypeConsignmentTypes.data.length>0 && selectedProductTypeConsignmentTypes.data[0].name!=='No Consignment types assigned' && selectedProductTypeConsignmentTypeIndex!=null && selectedProductTypeConsignmentTypeConsignmentSubTypes?.data[0]?.name!='Select Consignment Type'" mat-raised-button style="margin-top: 0.2rem; width: 100%; color:#3F69D6FF;" (click)="openConsignmentSubTypeDialog()">+ Add</button>
            <button class="hoverForClick" *ngIf="!displaytoAccountant && productTypeList!=null && productTypeList[selectedProductTypeIndex].consignment_types!=null && productTypeList[selectedProductTypeIndex].consignment_types.length>0 &&  selectedProductTypeConsignmentTypeIndex!=null && selectedProductTypeConsignmentTypeConsignmentSubTypes?.data[0]?.name!='Select Consignment Type'" mat-raised-button  style="margin-top: 0.2rem; margin-left: 0.2rem; width: 100%; color:#f44336;" (click)="enableRemoveConsignmentSubtype()">X Remove</button>
          </div>
          <div *ngIf="productTypeList!=null && (productTypeList[selectedProductTypeIndex].consignment_types!=null && productTypeList[selectedProductTypeIndex].consignment_types.length>0) && (productTypeList[selectedProductTypeIndex].consignment_types[selectedProductTypeConsignmentTypeIndex]?.consignment_sub_types_list==null || productTypeList[selectedProductTypeIndex].consignment_types[selectedProductTypeConsignmentTypeIndex]?.consignment_sub_types_list.length==0) && isRemoveConsignmentSubtypeConsignmentTypeProductType==false" style="display: flex; flex-direction: row;">
            <button class="hoverForClick" *ngIf="!displaytoAccountant && selectedProductTypeConsignmentTypes!=null && selectedProductTypeConsignmentTypes.data.length>0 && selectedProductTypeConsignmentTypes.data[0].name!=='No Consignment types assigned' && selectedProductTypeConsignmentTypeIndex!=null && selectedProductTypeConsignmentTypeConsignmentSubTypes?.data[0]?.name!='Select Consignment Type'" mat-raised-button style="margin-top: 0.2rem; width: 100%; color:#3F69D6FF;" (click)="openConsignmentSubTypeDialog()">+ Add</button>
          </div>
          <div *ngIf="isRemoveConsignmentSubtypeConsignmentTypeProductType==true">
            <button class="hoverForClick" *ngIf="!displaytoAccountant && selectedProductTypeConsignmentTypeIndex!=null && selectedProductTypeConsignmentTypeConsignmentSubTypes?.data[0]?.name!='Select Consignment Type'" mat-raised-button style="margin-top: 0.2rem; width: 100%" (click)="cancelRemoval()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="productTypeList!=null  && !displaytoAccountant" style="margin-top: 0.2rem; direction: rtl">
      <button mat-raised-button  color="primary" (click)="saveProductTypes()"><b>Save Changes</b></button>
      <button mat-raised-button style="margin-right: 1rem; color: whitesmoke"  color="warn" (click)="discardChages()"><b>Discard Changes</b></button>
    </div>

  </mat-card>

</div>
