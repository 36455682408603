import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {MatChipList} from "@angular/material/chips";
import {RegionService} from "../region.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-regions-edit',
  templateUrl: './regions-edit.component.html',
  styleUrls: ['./regions-edit.component.sass']
})
export class RegionsEditComponent implements OnInit {
  regionsForm: FormGroup;
  stateCtrl = new FormControl('');
  filteredStates: Observable<any[]>;
  allStates = [];
  userDetails
  displaytoAccountant

  @ViewChildren('stateInput') stateInput: QueryList<ElementRef<HTMLInputElement>>;

  constructor(private fb: FormBuilder, private service: RegionService) {
    this.regionsForm = this.fb.group({
      regions: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.getRegions();
  }

  get regions(): FormArray {
    return this.regionsForm.get('regions') as FormArray;
  }

  getStates() {
    const regions = this.regionsForm.get('regions') as FormArray;
    const states1 = regions.controls.map(region => {
      const states = region.get('states') as FormArray;
      return states.controls.map(s => s.value.name as string);
    }).flat()
    this.service.getStates().subscribe((data: any) => {
      if (data && data.data) {
        this.allStates = data.data.filter((state : any | null) => {
          if(state !== null) {
            return !states1.includes(state.name);
          }
        }).sort((a, b) => {
          if(a.name < b.name) return -1;
          else if(a.name > b.name) return 1;
          return 0;
        });
        this.filteredStates = this.stateCtrl.valueChanges.pipe(
          startWith(null),
          map((state: string | null) => {
            console.log(state);
            return state ? this._filter(state) : this.allStates
          })
        );
      }
    });
  }

  getRegions() {
    return this.service.getRegions().subscribe(response => {
      response.data.forEach(element => {
        this.regions.push(this.fb.group({
          id: [element.id || ''],
          name: [element.name || ''],
          description: [element.description || ''],
          states: this.fb.array(element.states || [])
        }));
      });
      this.getStates();
    });
  }

  addRegion() {
    const region = this.fb.group({
      name: [''],
      states: this.fb.array([])
    });
    this.regions.push(region);
  }

  // addStateToRegion(region: FormGroup, event: MatChipInputEvent) {
  //   const value = event.value;
  //   if (value) {
  //     const statesArray = region.get('states') as FormArray;
  //     statesArray.push(this.fb.control(value));
  //   }
  //   event.chipInput!.clear();
  //   this.stateCtrl.setValue(null);
  // }

  removeState(region: FormGroup, state: AbstractControl): void {
    const statesArray = region.get('states') as FormArray;
    const index = statesArray.value.indexOf(state);
    if (index >= 0) {
      statesArray.removeAt(index);
    }
    this.allStates.push(state);
    this.allStates = this.allStates.sort((a, b) => {
      if(a.name < b.name) return -1;
      else if(a.name > b.name) return 1;
      return 0;
    })
    this.stateInput.forEach(input => {
      input.nativeElement.blur();
    });
  }

  selected(event: MatAutocompleteSelectedEvent, region: FormGroup): void {
    const statesArray = region.get('states') as FormArray;
    this.stateInput.forEach(input => {
      input.nativeElement.value = '';
      input.nativeElement.blur();
    });
    this.stateCtrl.setValue(null);

    statesArray.push(this.fb.control(event.option.value));
     this.allStates.splice(this.allStates.indexOf(event.option.value), 1);

  }

  private _filter(value: string): string[] {
    try {
      const filterValue = value.toLowerCase();
      return this.allStates.filter(state => {
        return state.name.toLowerCase().includes(filterValue)
      });
    }
    catch (e) {
      return this.allStates
    }
  }

  saveData() {
    const formData = this.regionsForm.value.regions as any[];
    const requestBody = formData.map(region => {
      return {
        name: region.name,
        stateIds: region.states.map(state => state.id)
      }
    })

    this.service.saveRegionsWithPatch(requestBody).subscribe(response => {
      history.back();
      Swal.fire({
        title: 'Success',
        text: "Regions Updated successfully",
        allowEscapeKey: true,
        timer:4000
      })
    })
    // Implement your save logic here
  }

  discardChanges() {
    history.back();
  }
}
