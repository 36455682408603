<!-- shipment-ingestion.component.html -->
<div class="col-md-12">
    <container-element [ngSwitch]="pageview">
        <some-element *ngSwitchCase="'table'">
            <div class="main-card mb-3 card">
                <div class="card-header">
                    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                        <b style="color: blue;">BOOKINGS UPLOAD STATUS</b>
                    </div>
                    <div class="btn-actions-pane-right p-2">
                        <a [routerLink]="['/bookingsupload']" class="mb-2 me-2 btn btn-primary">Bookings Upload</a>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div>
                                <ng-container *ngIf="errorLogs.length > 0">
                                    <table *ngIf="errorLogs?.length > 0" class="w-100" mat-table
                                        [dataSource]="dataSource" matSort>
                                        <ng-container matColumnDef="filename">
                                            <th mat-header-cell *matHeaderCellDef >Filename</th>
                                            <td mat-cell *matCellDef="let errorLogs">{{ errorLogs.filename }}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef >Status</th>
                                            <td mat-cell *matCellDef="let errorLogs">
                                                <ng-container
                                                    *ngIf="errorLogs.status === 'Success'; else showDownloadButton">
                                                    <span class="text-success"
                                                        matTooltip="Download is not available for Successful Uploads">Success</span>
                                                </ng-container>
                                                <ng-template #showDownloadButton>
                                                    <span class="text-danger"
                                                        (click)="errorLogs.status === 'Failed' ? downloadLogFile(errorLogs.logFilename) : null"
                                                        matTooltip="Download the log">Failed</span>
                                                </ng-template>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="createdAt">
                                            <th mat-header-cell *matHeaderCellDef >Uploaded At</th>
                                            <td mat-cell *matCellDef="let errorLogs">{{ errorLogs.uploadedAt | date:
                                                'dd-MM-yyyy HH:mm:ss' }}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="uploadedBy">
                                            <th mat-header-cell *matHeaderCellDef >Uploaded By</th>
                                            <td mat-cell *matCellDef="let errorLogs">{{ errorLogs.uploadedBy }}</td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let errorLogs; columns: displayedColumns;"></tr>
                                    </table>
                                    <mat-paginator [length]="totalElements" [pageIndex]="pageNo"
                                        [pageSizeOptions]="[5, 10, 30]" [pageSize]="pageSize"
                                        (page)="onPageChange($event)" showFirstLastButtons>
                                    </mat-paginator>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </some-element>
    </container-element>
</div>
