<div class="col-md-8">
  <div class="card p-4">
    <div class="card-header">

      <div class="card-header-title font-size-lg text-capitalize fw-normal">
        Update Tracking Status
      </div>
    </div>
    <mat-tab-group>
      <mat-tab label="Consignment">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Consignment Id
                </mat-label>
                <input placeholder="Consignment Id" [(ngModel)]="consignment_sts_update.consignmentId" matInput>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Status
                </mat-label>
                <select matNativeControl id="" [(ngModel)]="consignment_sts_update.status">
                  <option *ngFor="let cons_st of consignment_status" [value]="cons_st.id">
                    {{cons_st.status}}
                  </option>
                </select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Text
                </mat-label>
                <input placeholder="Text" matInput [(ngModel)]="consignment_sts_update.comment">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md- center-elem pl-0">
              <button class="btn btn-primary " (click)="update_con_status()">Update Status</button>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Manifest">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Manifest Id
                </mat-label>
                <input placeholder="Manifest Id" matInput [(ngModel)]="manifest_sts_update.manifestId">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Status
                </mat-label>
                <select matNativeControl id="" [(ngModel)]="manifest_sts_update.status">
                  <option *ngFor="let mst_st of manifest_status" [value]="mst_st.id">
                    {{mst_st.status}}
                  </option>
                </select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Text
                </mat-label>
                <input placeholder="Text" matInput [(ngModel)]="manifest_sts_update.comment">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md- center-elem pl-0">
              <button class="btn btn-primary " (click)="update_mani_status()">Update Status</button>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

  </div>
</div>