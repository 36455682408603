import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import Swal from 'sweetalert2';
import {getCountries} from '../../getCities.js';
import {API_URL} from '../../../../../Global_API_URL';
import {saveAs} from 'file-saver';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatTableDataSource} from '@angular/material/table';
import {AuthService} from '../../../auth.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialog} from '../../Components/confirmDialog/confirmDialog.component';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-table-states',
  templateUrl: './table-states.component.html',
  styleUrls: ['./table-states.component.sass'],
})
export class TableStatesComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  constructor(private _liveAnnouncer: LiveAnnouncer, private matDialog: MatDialog, private authService : AuthService) {
    this.authService.getAuthToken();
  }
  pageview = 'table';
  state;
  states = this.getState();
  countries = getCountries();
  userDetails;
  displaytoAccountant=false;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name == 'Accountant') {
      this.displaytoAccountant=true;
    }
    this.getState();
  }

  viewchange(val) {
    this.pageview = val;
    this.refreshData();
  }
  viewchange1(val, sta) {
    this.updatestate={
      id: sta.id,
      name: sta.name,
      country: sta.country.id,
      gstPrefixCode: sta.gstPrefixCode,
      is_active: sta.is_active,

    };

    this.pageview = val;
  }
  viewstates = {
    name: null,
    country: null,
  }
  viewsection(val, con) {
    this.viewstates.name = con.name;
    this.viewstates.country = con.country.name;
    this.pageview= val;
  }
  displayedColumns = ['Name', 'Country', 'gstPrefixCode', 'Active']
  @ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;
dataSource
announceSortChange(sortState: Sort) {
  if (sortState.direction) {
    this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
  } else {
    this._liveAnnouncer.announce('Sorting cleared');
  }
}
showFirstLastButtons;
  pageSizeOptions=[10, 30];

  async getState() {
    const TOKEN = this.authService.getAuthToken();
    this.loading = true;
    const states=[];
    await fetch(API_URL+'/states', {method: 'GET', headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+TOKEN,
    }})

        // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          if (json.status == 400) {
            this.state = [];
          } else {
            this.state = json.data;
            this.dataSource = new MatTableDataSource(this.state);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        });
    this.loading = false;
    return this.state;
  }

  addstate={
    name: '',
    country: null,
    gstPrefixCode: '',
    is_active: true,
  }
  async Createstate() {
    const TOKEN = this.authService.getAuthToken();
    if (this.addstate.gstPrefixCode.length ==1) {
      this.addstate.gstPrefixCode = '0'+this.addstate.gstPrefixCode;
    }
    const state = {
      'name': this.addstate.name,
      'is_active': this.addstate.is_active,
      'country': {id: this.addstate.country},
      'gstPrefixCode': this.addstate.gstPrefixCode,
    };
    if (this.addstate.name == null || this.addstate.name == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter State Name',
        timer: 5000,

      });
    } else if (this.addstate.country == null || this.addstate.country == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select Country',
        timer: 5000,

      });
    } else if (this.addstate.gstPrefixCode == null || this.addstate.gstPrefixCode == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Gst Prefix Code',
        timer: 5000,

      });
    } else {
      await fetch(API_URL + '/postState', {
        method: 'POST',
        body: JSON.stringify(state),
        headers: {
          'Authorization': 'Bearer ' + TOKEN,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      })
          .then((response) => response.json())
          .then((result) => {
          // console.log(result)

            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'State created successfully',
              showConfirmButton: false,
              timer: 5000,
            });
            this.states = this.getState();
            this.viewchange('table');
            this.addstate.name = '';
            this.addstate.country = null;
            this.addstate.gstPrefixCode = null;
          })
          .catch((error) => console.error('error', error));
    }
  }

  updatestate={
    id: null,
    name: '',
    country: null,
    gstPrefixCode: null,
    is_active: null,
  }
  async UpdateDState() {
    if (this.updatestate.gstPrefixCode.toString().length ==1) {
      this.updatestate.gstPrefixCode = '0'+this.updatestate.gstPrefixCode;
    }
    const TOKEN = this.authService.getAuthToken();
    const state = {
      'name': this.updatestate.name,
      'is_active': this.updatestate.is_active,
      'country': {id: this.updatestate.country},
      'gstPrefixCode': this.updatestate.gstPrefixCode,
    };
    if (this.updatestate.name == null || this.updatestate.name == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter State Name',
        timer: 5000,

      });
    } else if (this.updatestate.country == null || this.updatestate.country == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Country',
        timer: 5000,

      });
    } else if (this.updatestate.gstPrefixCode == null || this.updatestate.gstPrefixCode == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Gst Prefix Code',
        timer: 5000,

      });
    } else {
      await fetch(API_URL + '/updateState' +'?'+'state_id='+this.updatestate.id, {
        method: 'PATCH',
        body: JSON.stringify(state),
        headers: {
          'Authorization': 'Bearer ' + TOKEN,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      })
          .then((response) => response.json())
          .then((result) => {
          // console.log(result)

            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Country Updated successfully',
              showConfirmButton: false,
              timer: 5000,
            });
            this.states = this.getState();
            this.viewchange('table');
            this.updatestate.name='';
            this.updatestate.country=null;
            this.updatestate.id=null;
          })
          .catch((error) => console.error('error', error));
    }
  }


  exportToCSV() {
    const csvData = [];
    const headers = ['State Name', 'Country', 'Is Active'];
    csvData.push(headers);
    let is_active;
    this.dataSource.filteredData.forEach((row) => {
      if (row.is_active == false) {
        is_active = 'No';
      } else {
        is_active ='Yes';
      }
      const data = [row.name, row.country.name, is_active];
      csvData.push(data);
    });

    const csv = csvData.map((e) => e.join(',')).join('\n');
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
    saveAs(blob, 'StateDetails.csv');
  }


  DeactivateState(state) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to deactivate ' + state.name +' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.Deletedata(state.id);
      }
    });
  }
  async Deletedata(id) {
    const TOKEN = this.authService.getAuthToken();
    // console.log(id)
    const data = JSON.stringify({
      'is_active': 0,
    });
    await fetch(API_URL + '/state_isactive/' + id,
        {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
          body: data,
        })

    // Converting received data to JSON
        .then((response) => response.text());
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'State Deactivated Successfully',
      showConfirmButton: false,
      timer: 5000,
    });
    // .then(result => console.log(result))
    this.getState()
        .catch((error) => console.error('error', error));
  }

  async Restoredata(id) {
    const TOKEN = this.authService.getAuthToken();
    // console.log(id)
    const data = JSON.stringify({
      'is_active': 1,
    });
    await fetch(API_URL + '/state_isactive/' + id,
        {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
          body: data,
        })

    // Converting received data to JSON
        .then((response) => response.text());
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'State Activated Successfully',
      showConfirmButton: false,
      timer: 5000,
    });
    // .then(result => console.log(result))
    this.getState()
        .catch((error) => console.error('error', error));
  }


  ActivateState(state) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to activate ' + state.name + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.Restoredata(state.id);
      }
    });
  }


  refreshData(): void {
  // Call your method to fetch updated data (modify based on your actual implementation)
    this.getState();
    this.addstate = {
      name: '',
      country: null,
      gstPrefixCode: '',
      is_active: true,
    };
  }

  validateInput(event) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[0-9]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateInput1(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[a-zA-Z\s]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }
}
