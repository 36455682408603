import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContractService} from '../contract.service';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from "ngx-loading";
import {MatTableDataSource} from "@angular/material/table";
import {getCities,getDropDownCities} from "../../getCities.js";
import {Cities} from "../../golbalInterfaces";

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';
@Component({
  selector: 'app-city-prices',
  templateUrl: './city-prices.component.html',
  styleUrls: ['./city-prices.component.sass']
})
export class CityPricesComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  constructor(private service: ContractService, private route: ActivatedRoute) {
  }

  cityPricesTableDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  id;
  priceType;

  async ngOnInit(): Promise<void> {
    this.id = this.route.snapshot.params['id'];
    this.priceType = this.route.snapshot.params['priceType'];
    this.paginatorDetails = this.service.getInitialPaginatorDetails()
    await this.getAllCityPrices();
  }

  displayedColumns: string[] = ['Origin', 'Destination', 'Product', 'Mode', 'CN Type', 'CN Subtype', 'Per KG', 'AWB Fee', 'Min Chargeable Weight', 'Min Freight', 'FOV', 'FOV Min', 'Fuel Surcharge', 'OOA Discount', 'Handling Charges', 'Dim Weight Calculator', 'Temp Range', 'Container Size'];
  dataSource;
  paginatorDetails;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageEvent: PageEvent;
  origin: any = '';
  destination: any ='';
  cities = getDropDownCities();
  customerName;

  async getAllCityPrices() {
    const response = await this.service.getContractualPricesByContract(this.id, this.priceType, 0, this.paginatorDetails.pageSize, 'id', 'DESC', this.origin.id, this.destination.id)
    this.cityPricesTableDataSource = new MatTableDataSource(response.cityPrices.content);
    this.customerName = response.contract?.customer?.firstName
    this.paginatorDetails = this.service.getPaginatorDetails();
    this.cityPricesTableDataSource.paginator = this.paginator;
    this.cityPricesTableDataSource.sort = this.sort;
    this.loading = false;
  }


  displayCityFn(city: Cities): String {
    return city?.name ? city?.name : '';
  }

  async onPaginateChange(event: PageEvent) {
    const page = event.pageIndex;
    const size = event.pageSize;
    this.loading = true;

    const response = await this.service.getContractualPricesByContract(this.id, this.priceType, page, size, 'id', 'DESC', this.origin.id, this.destination.id)
    this.cityPricesTableDataSource = new MatTableDataSource<any>(response.cityPrices.content);
    this.paginatorDetails = this.service.getPaginatorDetails()
    this.loading = false;

    this.paginatorDetails.pageSize = size;
  }

  reset(){
    if (this.origin != '' || this.destination != '') {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2000)

      this.origin = '';
      this.destination = '';
      // this.paginatorDetails.pageSize = 5;
      this.getAllCityPrices();
    }
  }


  goToContractPage() {
    window.history.back();
  }

}
