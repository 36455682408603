import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {Location} from "@angular/common";

@Component({
  selector: 'app-create-region',
  templateUrl: './create-region.component.html',
  styleUrls: ['./create-region.component.sass']
})
export class CreateRegionComponent {
  regionForm = this.fb.group({
    name: [null, Validators.required],
    description: [null, Validators.required],
  });

  constructor(private fb: FormBuilder, private locationService: Location) {}

  onSubmit(): void {

    console.log("Heelo")
    alert("Thanks")
  }

  goBack() {
    this.locationService.back();
  }
}
