<form [formGroup]="regionsForm">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h3 class="title"> Edit Regions</h3>
      </mat-card-title>
    </mat-card-header>
        <div formArrayName="regions">
          <div *ngFor="let region of regions.controls; let i = index" [formGroupName]="i" class="region-row">
            <div class="region-name">
              <input formControlName="name" matInput placeholder="Region Name">
            </div>
            <div class="region-states">
              <mat-form-field>
                <mat-chip-list #chipList>
                  <mat-chip *ngFor="let state of region.get('states').value"
                            [removable]="true"
                            [selectable]="true"
                            (removed)="removeState(region, state)">
                    {{state.name}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input placeholder="New state..."
                         #stateInput
                         [formControl]="stateCtrl"
                         [matChipInputFor]="chipList"
                         [matAutocomplete]="auto">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event, region)">
                  <mat-option *ngFor="let state of filteredStates | async" [value]="state">
                    {{state.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
    <mat-card-actions style="display: flex; flex-direction: row-reverse" *ngIf="!displaytoAccountant">
      <button mat-raised-button color="primary" type="submit" (click)="saveData()">
        Save
      </button>
      <button mat-raised-button color="warn" style="color: white" (click)="discardChanges()">
        Discard
      </button>

    </mat-card-actions>
  </mat-card>
</form>
