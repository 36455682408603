import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { AirlinePromotionsService } from '../../../modules/airline-promotions/airline-promotions.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ConfirmDialog } from '../../Components/confirmDialog/confirmDialog.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { HttpService } from 'src/app/services/http.service';
import { API_URL } from '../../../../../Global_API_URL';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-airline-promotions',
  templateUrl: './airline-promotions.component.html',
  styleUrls: ['./airline-promotions.component.sass'],
})
export class AirlinePromotionsComponent implements OnInit {
  public loadingTemplate: TemplateRef<any>;
  public readonly ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageEvent: PageEvent;

  isLoading: boolean = true;

selectedCnType: number;
selectedCnSubType: number;
selectedProductMode: number;
selectedAirline: number;
selectedOrigin: number;
selectedDestination: number;
validFrom: Date;
validTo: Date;

  constructor(private service: AirlinePromotionsService, private router: Router, private matDialog: MatDialog,private httpService: HttpService) { }

  ngOnInit(): void {
    this.getAllAilinePromotions();
    this.getAllProductTypes();
    // console.log(this.getAllProductTypes());
    this.getAirports().subscribe((response) => {
      this.originAirports = response;
      this.destinationAirports = response;
    });
    this.getAirlines().subscribe((response) => {
      this.airlines = response;
    })
  }


  getAllAilinePromotions(
    productMode?: number,
    cnType?: number,
    cnSubType?: number,
    airline?: number,
    origin?: number,
    destination?: number,
    validFrom?: Date,
    validTo?: Date
  ) {
    this.isLoading = true;
    const formattedFromDate = validFrom ? validFrom.toISOString() : null;
    const formattedToDate = validTo ? validTo.toISOString() : null;
    // Call the service method with the new parameters
    this.service.getAllAirlinePromotions(
      this.paginatorDetails.pageNo,
      this.paginatorDetails.pageSize,
      'id', // or any default sorting you prefer
      'DESC', // or any default direction you prefer
      cnType,
      cnSubType,
      productMode,
      airline,
      origin,
      destination,
      formattedFromDate,
      formattedToDate
    ).subscribe((response) => {
      this.airlinePromotionsList = new MatTableDataSource<any>(response.data.content);
  
      this.paginatorDetails.pageNo = response.data.page.number;
      this.paginatorDetails.totalPages = response.data.page.totalPages;
      this.paginatorDetails.totalElements = response.data.page.totalElements;
  
      this.airlinePromotionsList.paginator = this.paginator;
      this.airlinePromotionsList.sort = this.sort;
      this.isLoading = false;
    },
    (error) => {
      this.isLoading = false;
    });
  }

  paginatorSizeOptions = [5, 10, 20, 30];

  airlinePromotionsList: MatTableDataSource<any> = new MatTableDataSource<any>();

  paginatorDetails = {
    pageNo: 0,
    pageSize: this.paginatorSizeOptions[0],
    totalPages: 0,
    totalElements: 0,

  }

  onReset() {
    this.productType = 'null'; // Reset product type
    this.consignmentType = 'null'; // Reset consignment type
    this.consignmentSubtype = 'null'; // Reset consignment subtype
    this.airline = 'null'; //Reset airline
    this.origin = 'null'; // Reset origin
    this.destination = 'null'; // Reset destination
    this.cnTypes = []; // Reset consignment types
    this.cnSubtypes = []; // Reset consignment subtypes
    this.validFrom = null; // or new Date() for current date
    this.validTo = null;
    // Optionally, you can also call getAllAilinePromotions to refresh the data
    this.getAllAilinePromotions(null, null, null, null, null);
  }
  

  displayedColumns: string[] = ['S.No.', 'airline', 'flights', 'product', 'cnType', 'cnSubtype','origin','destination', 'validFrom', 'validUpto', 'startTime', 'endTime', 'actions'];

  ngAfterViewInit() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.airlinePromotionsList.filter = filterValue.trim().toLowerCase();

    if (this.airlinePromotionsList.paginator) {
      this.airlinePromotionsList.paginator.firstPage();
    }
  }

  onPaginateChange(event: PageEvent) {
    const page = event.pageIndex;
    const size = event.pageSize;
  
    this.isLoading = true;
    this.service.getAllAirlinePromotions(
      page,
      size,
      null,
      null,  // or keep existing sorting
      this.selectedCnType,       // replace with actual filter variable
      this.selectedCnSubType,    // replace with actual filter variable
      this.selectedProductMode,   // replace with actual filter variable
      this.selectedAirline,       // replace with actual filter variable
      this.selectedOrigin,        // replace with actual filter variable
      this.selectedDestination,    // replace with actual filter variable
      this.validFrom,            // replace with actual filter variable
      this.validTo               // replace with actual filter variable
    ).subscribe((response) => {
      this.airlinePromotionsList = new MatTableDataSource<any>(response.data.content);
      this.paginatorDetails.pageNo = response.data.page.number;
      this.paginatorDetails.totalPages = response.data.page.totalPages;
      this.paginatorDetails.totalElements = response.data.page.totalElements;
      this.isLoading = false;
    },
    (error) => {
      this.isLoading = false;
    });
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.isLoading = true;
  
    this.service.getAllAirlinePromotions(
      this.paginatorDetails.pageNo,
      this.paginatorDetails.pageSize,
      sort.active,
      sort.direction.toUpperCase(),
      this.selectedCnType,       // replace with actual filter variable
      this.selectedCnSubType,    // replace with actual filter variable
      this.selectedProductMode,   // replace with actual filter variable
      this.selectedAirline,       // replace with actual filter variable
      this.selectedOrigin,        // replace with actual filter variable
      this.selectedDestination,    // replace with actual filter variable
      this.validFrom,            // replace with actual filter variable
      this.validTo               // replace with actual filter variable
    ).subscribe((response) => {
      this.airlinePromotionsList = new MatTableDataSource<any>(response.data.content);
      this.paginatorDetails.pageNo = response.data.page.number;
      this.paginatorDetails.totalPages = response.data.page.totalPages;
      this.paginatorDetails.totalElements = response.data.page.totalElements;
      this.isLoading = false;
    },
    (error) => {
      this.isLoading = false;
    });
  }

  formatTime(timeInMinutes: number): string {
    if (!timeInMinutes && timeInMinutes !== 0) {
      return '';
    }
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    const formattedHours = ('0' + hours).slice(-2);
    const formattedMinutes = ('0' + minutes).slice(-2);
    return `${formattedHours}:${formattedMinutes}`;
  }

  goToCreateNewAirlinePromotion() {
    this.router.navigate(['/createAirlinePromotions']);
  }

  deleteAirlinePromotion(id, i) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to Delete ' + i + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isLoading = true;
        this.service.patchAirlinePromotion(id, { isActive: false }).subscribe((response) => {
          this.getAllAilinePromotions();
          Swal.fire({
            icon: 'success',
            title: 'Successfully Deleted',
            timer: 5000,
          });
          this.isLoading = false;
        },
          (error) => {
            this.isLoading = false;
          });
      }
    });
  }

  viewAirlinePromotion(id: number): void {
    if (id !== null && id !== undefined) {
      this.router.navigate(['/viewAirlinePromotions', id]);
    } else {
      console.error('Invalid ID:', id);
    }
  }

  productType: any = 'null';
  productTypes: any[] = [];
  consignmentType: any = 'null';
  consignmentSubtype: any = 'null';
  cnTypes: any[] = [];
  cnSubtypes: any[] = [];
  origin
  destination
  originAirports;
  destinationAirports;
  airline
  airlines: any[] = [];

  getAllProductTypes() {
    this.httpService.get(API_URL + '/getOrderTypes', null, null)
      .subscribe((response) => {
        this.productTypes = response.data;
        // console.log("Product Types fetched from API:", this.productTypes);
      }, (error) => {
        console.error("Error fetching product types:", error);
      });
  }


  onProductTypeChange() {
    // console.log('Selected Product Type:', this.productType);
    if (this.productType === 'null') {
      this.cnTypes = [];
      this.cnSubtypes = [];
      this.consignmentType = 'null';
      this.consignmentSubtype = 'null';
      // console.log('Cleared consignment types and subtypes');
    } else {
      // Find the selected product type
      const selectedProduct = this.productTypes.find(type => type.id === this.productType);
      if (selectedProduct) {
        this.cnTypes = selectedProduct.consignment_types || []; // Ensure this exists
        // console.log('Consignment Types:', this.cnTypes);
        this.consignmentType = 'null';
        this.cnSubtypes = [];
      } 
      // else {
      //   console.log('Selected Product Type not found in productTypes');
      // }
    }
  }

  onCNTypeChange() {
    // console.log('Selected Consignment Type:', this.consignmentType);
    if (this.consignmentType === 'null') {
      this.cnSubtypes = [];
      // console.log('Cleared consignment subtypes');
    } else {
      // Find the selected consignment type
      const selectedConsignmentType = this.cnTypes.find(type => type.id === this.consignmentType);
      if (selectedConsignmentType) {
        this.cnSubtypes = selectedConsignmentType.consignment_sub_types_list || []; // Ensure this exists
        // console.log('Consignment Subtypes:', this.cnSubtypes);
      } 
      // else {
      //   console.log('Selected Consignment Type not found in cnTypes');
      // }
    }
  }


  getAirports() {
    let airports = [];
    let array = [];
    return this.httpService.get(API_URL + '/airport', null, null)
      .pipe(map((response) => {
        array = Array.from(response.data);
        array.sort((a, b) => {
          if (a.city.name < b.city.name) {
            return -1;
          }
          if (a.city.name > b.city.name) {
            return 1;
          }
          return 0;
        });
        response.data = [];
        response.data = array;
        airports = response.data;
        return airports;
      }));
  }

  getAirlines() {
    let airlines = [];
    let array = [];
    return this.httpService.get(API_URL + '/airline', null, null)
      .pipe(map((response) => {
        array = Array.from(response.data);
        array.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        response.data = [];
        response.data = array;
        airlines = response.data;
        return airlines;
      }));
  }

}
