<div class="col-md-7">
    <div class="card p-4">
      <div class="card-header">
  
        <div class="card-header-title font-size-lg text-capitalize fw-normal">
          <b style="color: blue;">ICL QUICK</b>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 p-4">
          <a (click)="goto('ATA')" class="card p-2 card-hover-product">
            <img matTooltip="Airport To Airport" src="../../../../assets/images/icl-products/ATA.png" class="">
          </a>
        </div>
        <div class="col-md-6 p-4">
          <a (click)="gotoATD('ATD')" class="card p-2 card-hover-product">
            <img matTooltip="Airport To Door" src="../../../../assets/images/icl-products/ATD.png">
            <!-- A2D -->
          </a>
        </div>
       
      </div>
  
      <div class="row">
        <div class="col-md-6 p-4">
          <a (click)="gotoDTA('DTA')" class="card p-2 card-hover-product">
            <img matTooltip="Door To Airport" src="../../../../assets/images/icl-products/DTA.png" class="">
            <!-- D2A -->
          </a>
        </div>
        <div class="col-md-6 p-4">
          <a (click)="gotoDTD('DTD')" class="card p-2 card-hover-product">
            <img matTooltip="Door To Door" src="../../../../assets/images/icl-products/DTD.png">
            <!-- D2D -->
          </a>
        </div>
      </div>
  
    </div>
  </div>