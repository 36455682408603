import {Component, OnInit, ViewChild} from '@angular/core';
import {getCitiesById, getCountries, getStatesById} from '../../getCities.js';
import {API_URL} from '../../../../../Global_API_URL';

import Swal from 'sweetalert2';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {FormControl} from '@angular/forms';
import {MatSort, Sort} from '@angular/material/sort';
import {saveAs} from 'file-saver';
import {ConfirmDialog} from '../../Components/confirmDialog/confirmDialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '../../../auth.service';
import {HttpService} from '../../../services/http.service';
import {map} from 'rxjs/operators';


@Component({
  selector: 'app-table-airport',
  templateUrl: './table-airport.component.html',
  styleUrls: ['./table-airport.component.sass'],
})
export class TableAirportComponent implements OnInit {
  constructor(private _liveAnnouncer: LiveAnnouncer, private matDialog: MatDialog, private authService : AuthService, private httpService: HttpService) {
    this.authService.getAuthToken();
  }
  searchText;
  business_sel_state;
  business_states;
  myControl1 = new FormControl();
  newairport = {
    id: null,
    name: null,
    address: null,
    alias: null,
    latitude: null,
    longitude: null,
    is_active: null,
    iataCode: null,
    icaoCode: null,
    cityid: {
      id: null,
    },
    country: null,
    stateid: null,
    ib_active: null,
    ob_active: null,
  }
  edit = {
    ib_active: null,
    ob_active: null,
  }
  async ibobcity() {
    if (this.edit.ib_active=='1') {
      this.newairport.ib_active = true;
    } else {
      this.newairport.ib_active = false;
    }
    if (this.edit.ob_active=='1') {
      this.newairport.ob_active = true;
    } else {
      this.newairport.ob_active = false;
    }
  }
  cityid = '';
  stateid = '';
  pagenation = {
    sortBy: 'id',
    pageSize: 10,
    pageNo: 0,
    direction: 'DESC',
  }
  filterParams = '';
  user_cities
  user_sel_pincodes
  user_sel_state

  // dataSource: any;
  // paginator: any;
  // sort: any;

  // displayedColumns = ["state", "city", "airportname", "iata", "icao", "Active"]
  displayedColumns = ['state', 'city', 'airportname', 'iata', 'ib_active', 'ob_active', 'Active']
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  showFirstLastButtons;
  pageSizeOptions = [10, 20, 30];
  viewchange1(val, air) {
    this.newairport.id = air.id,
    this.newairport.name = air.name;
    this.newairport.latitude = air.latitude;
    this.newairport.longitude = air.longitude;
    this.newairport.iataCode = air.iataCode;
    this.newairport.icaoCode = air.icaoCode;
    this.newairport.country = air.city.state_id.country.id;
    this.getRecverStates();
    this.newairport.stateid = air.city.state_id.id;
    this.getReceverCities();
    this.newairport.cityid.id = air.city.id;
    this.newairport.ib_active = air.ib_active;
    this.newairport.ob_active = air.ob_active;
    if (air.ib_active==true) {
      this.newairport.ib_active=true;
      this.edit.ib_active='1';
    } else {
      this.newairport.ib_active=false;
      this.edit.ib_active='0';
    }

    if (air.ob_active==true) {
      this.newairport.ob_active=true;
      this.edit.ob_active='1';
    } else {
      this.newairport.ob_active=false;
      this.edit.ob_active='0';
    }
    this.pageview = val;
  }

  loading = false;

  pageview = 'table';
  airportlist;
  // airportlist = getAirports();
  states = this.getStates();
  countries = getCountries();
  cities = this.getCities();

  states1
  cities1

  countryid = this.newairport.country
  statebyid = this.newairport.stateid

  async getRecverStates() {
    this.states1 = getStatesById(this.newairport.country);
  }

  async getReceverCities() {
    this.cities1 = getCitiesById(this.newairport.stateid);
  }


  AirportsList = null;
  state = null
  city = null
  userDetails;
  displaytoAccountant=false;
  ngOnInit(): void {
    this.userDetails=JSON.parse(localStorage.getItem('userDetails'));
    this.getAirports();
    this.getAirport();
    this.reset();
    if (this.userDetails.userTypes[0].name == 'Accountant') {
      this.displaytoAccountant=true;
    }
  }


  viewchange(val) {
    this.pageview = val;
    this.newairport = {
      id: null,
      name: null,
      address: null,
      alias: null,
      latitude: null,
      longitude: null,
      is_active: null,
      iataCode: null,
      icaoCode: null,
      cityid: {id: null},
      country: null,
      stateid: null,
      ib_active: null,
      ob_active: null,
    };
  }


  // state = getStates();
  // countries = getCountries();
  // pincodes = getPincodes();


  addairport = {
    id: null,
    name: null,
    latitude: null,
    longitude: null,
    iataCode: null,
    icaoCode: null,
    cityid: {
      id: '',
    },
    country: null,
    stateid: null,
    ib_active: null,
    ob_active: null,
  }
  addcities
  addstates
  async getaddRecverStates() {
    this.addstates = getStatesById(this.addairport.country);
  }

  async getaddReceverCities() {
    this.addcities = getCitiesById(this.addairport.stateid);
  }

  async createAirport() {
    const TOKEN = this.authService.getAuthToken();
    this.ibobcity();
    if (this.addairport.name == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter the Airport name',
        showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else if (this.addairport.iataCode == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter the IATA code',
        showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else if (this.addairport.icaoCode == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter the ICAO code',
        showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else if (this.newairport.ib_active == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter the IB Active',
        showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else if (this.newairport.ob_active == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter the OB Active',
        showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else {
      const postparams = {
        'name': this.addairport.name,
        'latitude': this.addairport.latitude,
        'longitude': this.addairport.longitude,
        'city': this.addairport.cityid,
        'iata_code': this.addairport.iataCode,
        'icao_code': this.addairport.icaoCode,
        'ib_active': this.newairport.ib_active,
        'ob_active': this.newairport.ob_active,
      };


      this.httpService.post(API_URL + '/airport', postparams, null, null)
          .subscribe((result) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Airport added successfully',
              showConfirmButton: false,
              timer: 5000,
            });
            this.addairport = {
              id: null,
              name: null,
              latitude: null,
              longitude: null,
              iataCode: null,
              icaoCode: null,
              cityid: null,
              country: null,
              stateid: null,
              ib_active: null,
              ob_active: null,
            };
            this.getAirports();
            this.viewchange('table');
          }, (error) => {
            console.error('Error', error);
            alert(error);
          });
    }
  }

  async ActiveAirport(id, status) {
    const TOKEN = this.authService.getAuthToken();
    const activeparams = {
      'is_active': status,
    };

    this.httpService.patch(API_URL + '/updateAirport/isActive/' + id, activeparams, null, null)
        .subscribe((result) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Airport status changed successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.getAirports();
          this.viewchange('table');
        }, (error) => {
          console.error('Error', error);
          alert(error);
        });
  }

  async getStates() {
    let consignment;
    this.httpService.get(API_URL + '/states', null, null)
        .subscribe((response) => {
          this.state = response.data;
        });
  }

  async getCities() {
    const TOKEN = this.authService.getAuthToken();
    let consignment;
    this.httpService.get(API_URL + '/cities', null, null)
        .subscribe((response) => {
          this.city = response.data;
        });
  }

  async editAirport() {
    const TOKEN = this.authService.getAuthToken();
    this.ibobcity();
    const editparams = {
      'name': this.newairport.name,
      'latitude': this.newairport.latitude,
      'longitude': this.newairport.longitude,
      'city': {
        id: this.newairport.cityid.id,
      },
      'iata_code': this.newairport.iataCode,
      'icao_code': this.newairport.icaoCode,
      'ib_active': this.newairport.ib_active,
      'ob_active': this.newairport.ob_active,
    };

    this.httpService.patch(API_URL + '/updateAirport/' + this.newairport.id, editparams, null, null)
        .subscribe((result) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Airport Details changed successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.newairport = {
            country: null,
            id: null,
            name: null,
            address: null,
            alias: null,
            latitude: null,
            longitude: null,
            is_active: null,
            iataCode: null,
            icaoCode: null,
            cityid: null,
            stateid: null,
            ib_active: null,
            ob_active: null,
          };
          this.getAirports();
          this.viewchange('table');
        }, (error) => {
          console.error('Error', error);
          alert(error);
        });
  }

  tablefilter() {
    this.getAirports();
  }
  airid = ''
  filter() {
    this.filterParams = '?';
    if (this.cityid != '') {
      if (this.stateid == '' && this.cityid != '') {
        this.cityid = '';
      }
      this.filterParams = this.filterParams + 'cityid=' + this.cityid + '&';
    }
    if (this.stateid != '') {
      this.filterParams = this.filterParams + 'stateid=' + this.stateid + '&';
    }
    // this.filterParams = this.filterParams + "pageNo=" + this.pagenation.pageNo + "&pageSize=" + this.pagenation.pageSize + "&sortBy=" + this.pagenation.sortBy + "&direction=" + this.pagenation.direction
    return this.filterParams;
  }

  handlePageEvent(event: PageEvent) {
    // this.length = event.length;
    // this.pageSize = event.pageSize;
    // alert(event.pageIndex)
    //  this.pagenation.page_size  =event.pageSizeOptions;
    this.pagenation.pageSize = event.pageSize;
    this.pagenation.pageNo = event.pageIndex;
    this.getAirports();
  }

  async getAirports() {
    const TOKEN = this.authService.getAuthToken();
    let array=[];
    const api = '/airportFilters';
    const params = this.filter();
    this.httpService.get(API_URL + api + params, null, null)
        .subscribe((json) => {
          if (json.status == 400) {
            this.AirportsList = [];
          } else {
            this.AirportsList = json.data;
            array = Array.from(json.data);
            array.sort((low, high) => {
            // if (low.city.state_id.name < high.city.state_id.name) return -1;
            // if (low.city.state_id.name > high.city.state_id.name) return 1;
              if (low.city.name < high.city.name) return -1;
              if (low.city.name > high.city.name) return 1;
              return 0;
            });
            if (this.airid != '' && this.airid != null) {
              const barray=[];
              array.forEach((element) => {
                if (element.name==this.airid) {
                  barray.push(element);
                }
              });
              array=[];
              array=barray;
            }
            json.data=[];
            json.data = array;
            this.dataSource = new MatTableDataSource(json.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        });
    return this.AirportsList;
    // return loads;
  }
  city1
  async getIndividualCities() {
    this.city1 = await getCitiesById(this.stateid);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async reset() {
    this.cityid = '';
    this.stateid = '';
    this.airid = '';
    this.tablefilter();
  }

  viewairport = {
    name: null,
    iata: null,
    icaoCode: null,
    country: null,
    state: null,
    city: null,
    latitude: null,
    longitude: null,
    ib_active: null,
    ob_active: null,
  }

  viewsection(val, air) {
    this.viewairport.name = air.name;
    this.viewairport.iata = air.iataCode;
    this.viewairport.icaoCode = air.icaoCode;
    this.viewairport.country = air.city.state_id.country.name;
    this.viewairport.state = air.city.state_id.name;
    this.viewairport.city = air.city.name;
    this.viewairport.latitude = air.latitude;
    this.viewairport.longitude = air.longitude;
    this.viewairport.ib_active = air.ib_active;
    this.viewairport.ob_active = air.ob_active;
    this.pageview = val;
  }

  async getAirport() {
    const TOKEN = this.authService.getAuthToken();
    this.httpService.get(API_URL + '/airport', null, null)
        .subscribe((json) => {
          this.airportlist = json?.data?.sort((a, b) => a.city.name.localeCompare(b.city.name));
        });
  }

  exportToCSV() {
    const csvData = [];
    const headers = ['Airport Name', 'IATA Code', 'City', 'State', 'Is Active', 'InBound', 'OutBound'];
    csvData.push(headers);
    let isActive;
    let ib_active;
    let ob_active;

    this.dataSource.filteredData.forEach((row) => {
      if (row.isActive == false) {
        isActive = 'No';
      } else {
        isActive ='Yes';
      }
      if (row.ib_active == false) {
        ib_active = 'No';
      } else {
        ib_active ='Yes';
      }
      if (row.ob_active == false) {
        ob_active = 'No';
      } else {
        ob_active ='Yes';
      }
      const data = [row.name, row.iataCode, `"${row.city.name}"`, row.city.state_id.name, isActive, ib_active, ob_active];
      csvData.push(data);
    });

    const csv = csvData.map((e) => e.join(',')).join('\n');
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
    saveAs(blob, 'AirportDetails.csv');
  }

  DeActivateAirport(airport, status) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to deactivate ' + airport.name + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.loading = true;
        this.ActiveAirport(airport.id, status);
      }
    });
  }

  ActivateAirport(airport, status) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to activate ' + airport.name + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.loading = true;
        this.ActiveAirport(airport.id, status);
      }
    });
  }
}
