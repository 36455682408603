import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { API_URL } from '../../../../../Global_API_URL';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { HttpService } from 'src/app/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-airline-pricing-view',
  templateUrl: './airline-pricing-view.component.html',
  styleUrls: ['./airline-pricing-view.component.sass']
})
export class AirlinePricingViewComponent implements OnInit {

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  airlinePricingId;
  airlinePricingData;
  TOKEN;
  userDetails;
  displayToAdmin = false;
  displayToSupervisor = false;

  constructor(private ActivatedRoute: ActivatedRoute, private authService: AuthService, private httpService: HttpService, private router: Router) {
    this.TOKEN = this.authService.getAuthToken();
    this.ActivatedRoute.params.subscribe((params) => {
      this.airlinePricingId = params['id'];
    });
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0] == 'Admin') {
      this.displayToAdmin = true;
    }
    if (this.userDetails.userTypes[0] == 'Supervisor') {
      this.displayToSupervisor = true;
    }
    this.getAirlinePricingById(this.airlinePricingId);
  }

  getAirlinePricingById(id) {
    this.loading = true;
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    });
    this.httpService.get(API_URL + '/pricing/' + id, null, headers)
      .subscribe((response) => {
        this.airlinePricingData = response.data[0];
        this.loading = false;
      }, (error) => {
        console.error('Error:', error);
        this.loading = false;
      });
  }

  backToAirlinePricingPage() {
    this.router.navigate(['airlinePricing'])
  }

  goToAirlinePricingEditPage() {
    this.router.navigate(['airlinePricingEdit', this.airlinePricingId])
  }

}
