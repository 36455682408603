import {ConsignmentTypesModel} from '../../consignmentTypes/models/consignment-types-model';

export class ConsignmentSubTypesModel {
  id: string;
  name: string;
  description: string;
  consignmentType: ConsignmentTypesModel;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;


  constructor(id: string, name: string, description: string, consignmentType: ConsignmentTypesModel, isActive: boolean) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.consignmentType = consignmentType;
    this.isActive = isActive;
  }
}
