import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../auth.service';
import Swal from 'sweetalert2';
import {API_URL} from '../../../../../Global_API_URL';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ConfirmDialog} from '../../Components/confirmDialog/confirmDialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {HttpParams} from "@angular/common/http";
import {HttpService} from "../../../services/http.service";

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-consignment-edit',
  templateUrl: './consignment-edit.component.html',
  styleUrls: ['./consignment-edit.component.sass'],
})
export class ConsignmentEditComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  constructor(private router: Router, private route: ActivatedRoute, private matDialog: MatDialog, private authService: AuthService, private locationService: Location, private httpService: HttpService) {
    this.authService.getAuthToken();
  }

  consignment_id;
  filterParams = '';
  sender_id = '';
  pickup_location: any = '';
  drop_location: any = '';
  is_coperate_booking = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource

  ngOnInit(): void {
    const someFunction = async () => {
      await this.getconsignmentById();
      this.sumOfPackages();
      this.sortPackagesById(); // Sort packages by ID after fetching data
      this.volumeWeightFunction();
    };
    this.route.params.subscribe((params) => {
      this.consignment_id = params['cosigno'];
    });
    someFunction();
  }
  sortPackagesById() {
    this.initialPackagesState.sort((a, b) => a.id - b.id);
    this.add_packages.sort((a, b) => a.id - b.id);
  }
  totalNoOfPackages;
  totalChargableWeight;
  sumOfPackages() {
    this.consignment_details.totalNoOfPackages = 0;
    this.consignment_details.totalWeight = 0;
    this.consignment_details.totalChargableWeight = 0;
    this.add_packages.forEach((packages) => {
      this.consignment_details.totalNoOfPackages += packages.package_no;
      this.totalNoOfPackages += packages.package_no;
      this.consignment_details.totalWeight += packages.total_weight;
      this.consignment_details.totalChargableWeight += packages.chargeable_weight;
    });
  }

  consignment_details = {
    id: null,
    consignmentType: null,
    weightUnit: 1,
    currency: 1,
    weightDivisor: null,
    consignmentNo: null,
    airlinesId: null,
    isStopover: null,
    totalTransitTime: null,
    senderIndividual: null,
    isIndividualCustomer: null,
    paymentCompletedAt: null,
    isXrayScreening: null,
    isXrayCertification: null,
    isDryIce: null,
    totalWeight: null,
    totalChargableWeight: null,
    planSelected: null,
    senderId: null,
    packages: [],
    receiverId: null,
    isDocumentUploaded: null,
    isInsured: false,
    totalShippingCost: null,
    paymentMode: null,
    isPaymentCompleted: null,
    isActive: null,
    createdAt: '',
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
    totalNoOfPackages: null,
    destinationLocation: null,
    consignmentSubType: null,
    originLocation: null,
    dimensionuUnit: null,
    insuranceCoverage: 0,
    totalConsignmentValue: null,
    shippingCost: 0,
    invoiceCreatedDate: '',
    invoiceReferenceNo: null,
    productType: null,
    remarks: null,
    tentative_pickup_time: null,
    requiredTemperature: {id : null},
    package_type: null,
  }

  packages = [
    {
      id: 1,
      length: null,
      breadth: null,
      height: null,
      weight_per_package: null,
      total_weight: null,
      packageNo: null,
      volume_weight: null,
      chargeable_weight: null,
      totalvolumeweight_per_pack: null,
    },
  ]

  weightDivisorList = [
    6000, 5000, 4500, 3600, 2700,
  ]

  async allPackCal() {
    this.add_packages.forEach((element) => {
      this.eachPackageCharged(element.id);
    });
  }

  async eachPackageCharged(id) {
    this.consignment_details.totalNoOfPackages = 0;
    this.consignment_details.totalWeight = 0;
    this.consignment_details.totalChargableWeight = 0;
    for (let index = 0; index < this.packages.length; index++) {
      this.add_packages[index].total_weight = Number(this.add_packages[index].package_no) * this.add_packages[index].weight_per_package;
      if (this.add_packages[index].id == id) {
        this.add_packages[index].volume_weight = Number(this.add_packages[index].length) * Number(this.add_packages[index].breadth) * Number(this.add_packages[index].height) * Number(this.add_packages[index].package_no) / Number(this.consignment_details.weightDivisor);

        if (this.add_packages[index].volume_weight > this.add_packages[index].weight_per_package) {
          this.add_packages[index].chargeable_weight = Number(this.add_packages[index].volume_weight) * Number(this.add_packages[index].package_no);
        } else {
          this.add_packages[index].chargeable_weight = this.add_packages[index].total_weight;
        }
      }
      this.consignment_details.totalNoOfPackages = Number(this.consignment_details.totalNoOfPackages) + Number(this.add_packages[index].package_no);
      this.consignment_details.totalWeight = this.consignment_details.totalWeight + Number(this.add_packages[index].total_weight);
      this.consignment_details.totalChargableWeight = Math.ceil(this.consignment_details.totalChargableWeight + this.add_packages[index].chargeable_weight);
    }
  }

  consignmentlists: any = {
    id: '',
    consignment_no: '',
    consignmentType: '',
    weightUnit: 1,
    currency: 1,
    weightDivisor: null,
    consignmentNo: '',
    airlinesId: '',
    isStopover: '',
    totalTransitTime: '',
    senderIndividual: '',
    isIndividualCustomer: '',
    paymentCompletedAt: '',
    isXrayScreening: '',
    isXrayCertification: '',
    isDryIce: '',
    totalWeight: '',
    totalChargableWeight: '',
    planSelected: '',
    senderId: '',
    receiverId: '',
    isDocumentUploaded: '',
    isInsured: false,
    totalShippingCost: '',
    paymentMode: '',
    isPaymentCompleted: '',
    isActive: '',
    createdAt: '',
    updatedAt: '',
    createdBy: '',
    updatedBy: '',
    totalNoOfPackages: '',
    destinationLocation: '',
    consignmentSubType: '',
    originLocation: '',
    dimensionuUnit: '',
    insuranceCoverage: '',
    totalConsignmentValue: '',
    shippingCost: '',
    invoiceCreatedDate: '',
    invoiceReferenceNo: '',
    productType: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    data_logger_file: '',
    vehicle_calibration_file: '',
    other_documents_file: '',
    temperature_record_file: '',
    pod: '',

  }

  filter() {
    this.filterParams = '?';
    if (this.sender_id != '') {
      this.filterParams = this.filterParams + 'sender_id=' + this.sender_id + '&';
    }
    if (this.pickup_location != '') {
      this.filterParams = this.filterParams + 'pickup_location=' + this.pickup_location.id + '&';
    }
    if (this.drop_location != '') {
      this.filterParams = this.filterParams + 'drop_location=' + this.drop_location.id + '&';
    }
    if (this.is_coperate_booking != '') {
      this.filterParams = this.filterParams + 'is_coperate_booking=' + this.is_coperate_booking + '&';
    }
    return this.filterParams;
  }

  initialPackagesState: any[];
  initialPackagesLength;
  initialInvoiceReferenceNo;
  initialInvoiceCreatedDate;
  initialConsignmentValue;
  initialTentativePickupDate;
  initialCreatedDate;
  initialTemp;
  initialRemarks;

  async getconsignmentById() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/' + this.consignment_id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          this.consignment_details = result.data[0];
          this.initialInvoiceReferenceNo = this.consignment_details.invoiceReferenceNo;
          this.initialInvoiceCreatedDate = this.consignment_details.invoiceCreatedDate;
          this.initialConsignmentValue = this.consignment_details.totalConsignmentValue;
          this.initialTentativePickupDate = this.consignment_details.tentative_pickup_time;
          if (this.consignment_details.requiredTemperature != null){
            this.initialTemp = this.consignment_details.requiredTemperature?.id;
          }
          this.initialCreatedDate = this.consignment_details.createdAt;
          this.initialRemarks = this.consignment_details.remarks;
          this.packages = this.consignment_details.packages;
          this.initialPackagesState = JSON.parse(JSON.stringify(this.packages));
          this.initialPackagesLength = this.initialPackagesState.length;
          this.add_packages = this.consignment_details.packages;
          this.getContainerTempRangeOptions(result.data[0]?.series_ref?.productType?.name)
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error',
            timer: 5000,
          });
        });
  }

  newPackages: any[] = [];

  addPackage() {
    const newPackageCount = this.add_packages.length + 1;
    let maxId = 0;
    this.add_packages.forEach((packages) => {
      if (packages.id > maxId) {
        maxId = packages.id;
      }
    });
    const new_package = {
      id: newPackageCount,
      length: null,
      breadth: null,
      height: null,
      weight_per_package: null,
      total_weight: null,
      package_no: null,
      volume_weight: null,
      chargeable_weight: null,
      totalvolumeweight_per_pack: null,
    };
    this.add_packages.push(new_package);
    this.newPackages.push(new_package);
  }

  async saveChanges() {
    const TOKEN = this.authService.getAuthToken();

    this.dataChanged();
    this.volumeWeightFunction();
    if (this.VWCount > 0) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Volume weight not taken',
        timer: 5000,
      });
    } else
    if (this.saveDisable) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No changes Occured',
        timer: 3000,
      });
      setTimeout(() => {
        this.gotoConsignment();
      }, 3000);
    } else {
      if (this.consignment_details.invoiceReferenceNo == '') {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Please Enter Invoice Reference Number',
          timer: 5000,
        });
      }
      // else if (this.consignment_details.invoiceCreatedDate == "" || this.consignment_details.invoiceCreatedDate == null) {
      //   Swal.fire({
      //     position: 'center',
      //     icon: 'warning',
      //     title: 'Please Enter PO Invoice Date',
      //
      //     timer: 5000
      //   });
      // }
      else if (this.consignment_details.totalConsignmentValue == '') {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Please Enter Consignment Value',
          timer: 5000,
        });
      }
      // else if (this.consignment_details.tentative_pickup_time == "" || this.consignment_details.tentative_pickup_time == null) {
      //   Swal.fire({
      //     position: 'center',
      //     icon: 'warning',
      //     title: 'Please Enter Tentative Pickup Date',
      //
      //     timer: 5000
      //   });
      // }
      // else if (this.consignment_details.createdAt == "" ||  this.consignment_details.createdAt == null) {
      //   Swal.fire({
      //     position: 'center',
      //     icon: 'warning',
      //     title: 'Please Enter Created Date',
      //
      //     timer: 5000
      //   });
      // }
      else if (!this.add_packages.every((packages) => packages.length && packages.breadth && packages.height && packages.package_no && packages.weight_per_package)) {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Please Enter Package Values',

          timer: 5000,
        });
      } else {
        // Filter out the packages that were modified
        const modifiedPackages = this.add_packages.slice(0, this.initialPackagesLength).filter((newPackage) => {
          return this.isPackageUnchanged(newPackage);
        });

        // Call PATCH API endpoint for modified packages
        if (this.count > 0 || this.initialInvoiceReferenceNo != this.consignment_details.invoiceReferenceNo || this.initialInvoiceCreatedDate != this.consignment_details.invoiceCreatedDate || this.initialConsignmentValue != this.consignment_details.totalConsignmentValue || this.initialTentativePickupDate != this.consignment_details.tentative_pickup_time || this.initialCreatedDate != this.consignment_details.createdAt || this.initialRemarks != this.consignment_details.remarks || this.initialTemp != this.consignment_details.requiredTemperature?.id) {
          const patchPayload = {
            totalNoOfPackages: this.consignment_details.totalNoOfPackages,
            totalWeight: this.consignment_details.totalWeight,
            totalChargableWeight: this.consignment_details.totalChargableWeight,
            packages: modifiedPackages,
            totalConsignmentValue: this.consignment_details.totalConsignmentValue,
            createdAt: this.consignment_details.createdAt,
            invoiceCreatedDate: this.consignment_details.invoiceCreatedDate,
            invoiceReferenceNo: this.consignment_details.invoiceReferenceNo,
            remarks: this.consignment_details.remarks,
            weightDivisor: this.consignment_details.weightDivisor,
            tentative_pickup_time: this.consignment_details.tentative_pickup_time,
            required_temperature: this.consignment_details.requiredTemperature,
            package_type: this.consignment_details.package_type,
          };

          await fetch(API_URL + '/consignment/' + this.consignment_id, {
            method: 'PATCH',
            body: JSON.stringify(patchPayload),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + TOKEN,
            },
          })
              .then((response) => response.json())
              .then((result) => {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Changes Saved',

                  timer: 5000,
                });
                this.gotoConsignment();
              })
              .catch((error) => {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Error',

                  timer: 5000,
                });
                console.error('Error', error);
                alert(error);
              });
        }

        if (this.consignmentFieldsChanged) {
          this.removeObjectsNotPresentInOb1();
          const postPayload = {
            totalNoOfPackages: this.consignment_details.totalNoOfPackages,
            totalWeight: this.consignment_details.totalWeight,
            totalChargableWeight: this.consignment_details.totalChargableWeight,
            packages: this.newPackages,
          };

          // Call POST API endpoint for new packages
          if (this.newPackages.length > 0) {
            await fetch(API_URL + '/addpackage/' + this.consignment_id, {
              method: 'POST',
              body: JSON.stringify(postPayload),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + TOKEN,
              },
            })
                .then((response) => response.json())
                .then((result) => {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'New Packages Added',

                    timer: 5000,
                  });
                  this.gotoConsignment();
                })
                .catch((error) => {
                  Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Error',

                    timer: 5000,
                  });
                  console.error('Error', error);
                  alert(error);
                });
          }
        }

        // Reset new packages array
        this.newPackages = [];
      }
    }
  }

  removeObjectsNotPresentInOb1() {
    const objectsToRemove: any[] = [];

    this.newPackages.forEach((obj2) => {
      const found = this.add_packages.some((obj1) => this.isEqual(obj1, obj2));
      if (!found) {
        objectsToRemove.push(obj2);
      }
    });

    // Remove objects that are not present in ob1 from ob2
    objectsToRemove.forEach((obj) => {
      const index = this.newPackages.indexOf(obj);
      if (index !== -1) {
        this.newPackages.splice(index, 1);
      }
    });
  }

  isEqual(obj1: any, obj2: any): boolean {
    // Customize this method based on how you define equality between objects
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  consignmentFieldsChanged = true;
  saveDisable = true;
  dataChanged() {
    if (this.initialInvoiceReferenceNo != this.consignment_details.invoiceReferenceNo || this.initialInvoiceCreatedDate != this.consignment_details.invoiceCreatedDate || this.initialConsignmentValue != this.consignment_details.totalConsignmentValue || this.initialTentativePickupDate != this.consignment_details.tentative_pickup_time || this.initialCreatedDate != this.consignment_details.createdAt || this.initialRemarks != this.consignment_details.remarks || this.initialTemp != this.consignment_details.requiredTemperature?.id) {
      if (JSON.stringify(this.initialPackagesState) != JSON.stringify(this.add_packages)) {
        this.saveDisable = false;
      } else {
        this.consignmentFieldsChanged = false;
        this.add_packages = this.initialPackagesState;
        this.saveDisable = false;
      }
    } else if (JSON.stringify(this.initialPackagesState) != JSON.stringify(this.add_packages)) {
      this.saveDisable = false;
    } else {
      this.saveDisable = true;
      this.consignmentFieldsChanged = false;
    }
  }


  count = 0

  isPackageUnchanged(newPackage) {
    const editedPackages = this.initialPackagesState.find((packages) => JSON.stringify(packages) === JSON.stringify(newPackage));
    if (editedPackages == undefined) {
      this.count += 1;
      return this.count;
    }
  }


  add_packages = [
    {
      id: 1,
      length: null,
      breadth: null,
      height: null,
      weight_per_package: null,
      total_weight: null,
      package_no: null,
      volume_weight: null,
      chargeable_weight: null,
      totalvolumeweight_per_pack: null,
    },
  ]

  // totalNoOfPackages;
  totalWeight;
  // totalChargableWeight;
  weightDivisor;
  consignmentNo;

  volumeWeightPresent = false;
  volumeWeightTest = 0;
  async addeachPackageCharged(id) {
    this.consignment_details.totalNoOfPackages = 0;
    this.consignment_details.totalWeight = 0;
    this.consignment_details.totalChargableWeight = 0;
    for (let index = 0; index < this.packages.length; index++) {
      this.add_packages[index].total_weight = Math.ceil(Number(this.add_packages[index].package_no) * this.add_packages[index].weight_per_package);
      if (this.add_packages[index].id == id) {
        this.add_packages[index].volume_weight = Math.ceil(Number(this.add_packages[index].length) * Number(this.add_packages[index].breadth) * Number(this.add_packages[index].height) * Number(this.add_packages[index].package_no) / Number(this.consignment_details.weightDivisor));

        if (this.add_packages[index].volume_weight > this.add_packages[index].total_weight) {
          this.add_packages[index].chargeable_weight = Math.ceil(Number(this.add_packages[index].volume_weight));
        } else {
          this.add_packages[index].chargeable_weight = Math.ceil(this.add_packages[index].total_weight);
        }
      }
      this.consignment_details.totalNoOfPackages = Number(this.consignment_details.totalNoOfPackages) + Number(this.add_packages[index].package_no);
      this.consignment_details.totalWeight = this.consignment_details.totalWeight + Number(this.add_packages[index].total_weight);
      this.consignment_details.totalChargableWeight = Math.ceil(this.consignment_details.totalChargableWeight + this.add_packages[index].chargeable_weight);
    }

    this.volumeWeightFunction();
  }

  VWCount = 0;
  volumeWeightFunction() {
    this.add_packages.forEach((packages) => {
      if (packages.volume_weight == null) {
        this.VWCount += 1;
      } else {
        this.VWCount = 0;
      }
    });
  }

  async deletePackages(id) {
    const TOKEN = this.authService.getAuthToken();

    await fetch(API_URL + '/deletePackage/' + id,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Package Deleted',

            timer: 5000,
          });
          this.refreshPage();
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error',

            timer: 5000,
          });
          console.error('Error', error);
          alert(error);
        });
  }

  DeletePack(id, i) {
    if (i > this.initialPackagesLength) {
      this.add_packages = this.add_packages.filter((packages) => packages.id !== id);
    } else {
      const dialogRef = this.matDialog.open(ConfirmDialog, {
        width: '25rem',
        data: 'Are you sure you want to delete ' + i + ' ?',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.deletePackages(id);
        }
      });
    }
  }

  refreshPage() {
    window.location.reload();
  }

  gotoConsignment() {
    this.locationService.back();
  }

  validateInput1(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^(\d*\.?\d*)$/.test(inputChar)) {
      event.preventDefault();
    }
  }
  validateInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^\d*$/.test(inputChar)) {
      event.preventDefault();
    }
  }


  containerTempRangeOptions;
  getContainerTempRangeOptions(productName){
    const params: HttpParams = new HttpParams().set('productName', productName);
    this.httpService.get(API_URL + '/dropdown/containerTempRange/', params, null)
      .subscribe((response) => {
        this.containerTempRangeOptions = response?.data?.content;
      })
  }
}
