<div class="col-md-12">
  <container-element [ngSwitch]="pageview">
    <some-element *ngSwitchCase="'table'">
      <div class="main-card mb-3 card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            <b style="color: blue;">BOOKINGS</b>
          </div>
          <div class=" m-1"><span (click)="refresh()" [@rotate]="refreshing ? 'active' : 'inactive'"
                                  class="btn  material-icons" style="color: rgb(0, 0, 255); font-size: 180%;"
                                  matTooltip="refresh">refresh</span></div>
          <div class="btn-actions-pane-right p-2">
          </div>
        </div>
        <div>
          <div style="display: flex">
            <div>
              <mat-form-field appearance="standard" style="width: 200px; margin-right: 20px; margin-left: 10px">
                <mat-label>Origin</mat-label>
                <input matInput placeholder="Search Origin" [(ngModel)]="pickup_location" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option *ngFor="let city of city | async | filter: pickup_location" [value]="city">
                    {{city.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard" style="width: 200px; margin-right: 20px;">
                <mat-label>Destination</mat-label>
                <input matInput placeholder="Search Destination" [(ngModel)]="drop_location" [matAutocomplete]="auto1">
                <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn1">
                  <mat-option *ngFor="let city of city | async | filter: drop_location" [value]="city">
                    {{city.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard" style="width: 200px; margin-right: 20px;">
                <mat-label>Bookings</mat-label>
                <mat-select matNativeControl [(ngModel)]="is_coperate_booking">
                  <mat-option value="">
                    All
                  </mat-option>
                  <mat-option value="0">
                    Online Booking
                  </mat-option>
                  <mat-option value="1">
                    Offline Booking
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard" style="width: 150px; margin-right: 20px;">
                <mat-label>Consignment No</mat-label>
                <input matInput #input [(ngModel)]="search" oninput="if(!this.value.match('^[0-9]*$'))this.value='';"
                       maxlength="11" (keyup)="searchByCons(search)" placeholder="Consignment Number">
                <mat-hint align="end">{{input.value.length}}/11</mat-hint>
              </mat-form-field>
            </div>
              <div [formGroup]="filterForm">
                <mat-form-field style="width: 150px; margin-right: 20px; margin-top: 12px">
                  <input #fromInput matInput [matDatepicker]="fromDate" placeholder="From Date"
                         formControlName="fromDate">
                  <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                  <mat-datepicker #fromDate></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="date-block__list col-md-3" [formGroup]="filterForm">
                <mat-form-field style="width: 150px; margin-top: 12px;">
                  <input #toInput matInput [matDatepicker]="toDate" placeholder="To Date" formControlName="toDate">
                  <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                  <mat-datepicker #toDate></mat-datepicker>
                </mat-form-field>
              </div>
          </div>
          <div  [formGroup]="filterForm">
            <div class="date-block row justify-content-end">
              <div class="col-lg-2 col-md-2 row">
                <button class="btn btn-primary m-auto" (click)="tablefilter()">
                  Find
                  <i class="pe-7s-search">
                  </i>
                </button>
              </div>
              <div class="col-lg-2 col-md-1  row m-1">
                <button class="btn btn-secondary m-auto" (click)="resetForm()">
                  Reset
                </button>
              </div>
              <div class="col-lg-2 col-md-2  row m-1">
                <button class="btn btn-success m-auto" (click)="exportToCSV()">
                  Download Report (CSV)
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div *ngIf="!loading" style="overflow-x: auto">
            <ng-container *ngIf="dataSource?.data?.length > 0; else noRecordsMessage">
              <table class="w-100" mat-table [dataSource]="dataSource" matSort style="min-width: 1200px;"
                     (matSortChange)="announceSortChange($event)">
                <ng-container matColumnDef="consignmentNo">
                  <th class="text-center" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by number"> Consignment No
                  </th>
                  <td class="text-center" mat-cell *matCellDef="let element"><a [routerLink]=""
                                                                                (click)="gotoConsignmentView(element.id, element.consignment_no)">
                                                                                <div *ngIf="element.pod==null" class="text-primary">
                                                                                  {{element.consignment_no}}
                                                                                </div>
                                                                                <div *ngIf="element.pod!=null" class="text-success">
                                                                                  {{element.consignment_no}}
                                                                                </div></a>
                  </td>
                </ng-container>
                <!-- <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef  sortActionDescription="Sort by Name"> Name </th>
                  <td mat-cell *matCellDef="let element"> {{element.customer_name}} </td>
                </ng-container> -->

                <ng-container matColumnDef="totalNoOfPackages">
                  <th class="text-center" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by No of Packages"> No
                    of Packages
                  </th>
                  <td class="text-center" mat-cell *matCellDef="let element"> {{element?.no_of_packages}} </td>
                </ng-container>

                <ng-container matColumnDef="totalChargableWeight">
                  <th class="text-center" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by Chargeable Weight">
                    Chargeable Weight
                  </th>
                  <td class="text-center" mat-cell *matCellDef="let element"> {{element?.total_chargable_weight}}kg
                  </td>
                </ng-container>


                <ng-container matColumnDef="orign">
                  <th class="text-center" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by Origin"> Origin
                  </th>
                  <td class="text-center" mat-cell *matCellDef="let element"> {{element.cityname}} </td>
                </ng-container>
                <ng-container matColumnDef="destination">
                  <th class="text-center" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by Destination">
                    Destination
                  </th>
                  <td class="text-center" mat-cell *matCellDef="let element"> {{element.receivercity}} </td>
                </ng-container>


                <ng-container matColumnDef="status">
                  <th class="text-center" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by Status"> Status
                  </th>
                  <td class="text-center" mat-cell *matCellDef="let element">
                    <div *ngIf="element.name!= null">
                      <div *ngIf="element.status != 16 && element.status!= 19 && element.status!=18 && element.status!=36"
                           class="badge bg-pill bg-primary">
                        {{element.name}}
                      </div>
                      <div *ngIf="element.status == 16" class="badge bg-pill bg-success">
                        {{element.name}}
                      </div>
                      <div *ngIf="element.status == 19" class="badge bg-pill bg-orange-400">
                        {{element.name}}
                      </div>
                      <div *ngIf="element.status==18" class="badge bg-pill bg-danger">
                        {{element.name}}
                      </div>
                      <div *ngIf="element.status==36" class="badge bg-pill bg-danger">
                        {{element.name}}
                      </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="isInvoiced">
                  <th class="text-center p-1" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by Consignment invoiced status"> Invoiced
                  </th>
                  <td mat-cell *matCellDef="let element"
                      style="text-align: center"> {{element.isInvoiced ? "Yes" : "No"}} </td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                  <th class="text-center" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by Consignment Created At"> Created At
                  </th>
                  <td class="text-center" mat-cell *matCellDef="let element"> {{ element.created_at | date:'dd-MM-yyyy HH:mm:ss' }} </td>
                </ng-container>
                <ng-container matColumnDef="Active">
                  <th mat-header-cell *matHeaderCellDef > Action</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex">
                      <!-- <div role="group" *ngIf="element.is_active!=false" class="btn-group-sm btn-group m-1">
                        <button class="btn-shadow btn btn-danger" (click)="Deletedata(element.id)"><i
                            class="pe-7s-trash"></i>
                        </button>
                      </div> -->
                      <div role="group" class="btn-group-sm btn-group m-1">
                        <button class="btn-shadow btn btn-primary" (click)="getRecord(element.id)"><i
                          class="pe-7s-download"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </ng-container>
          </div>
          <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" (page)="onPaginateChange($event)"
          [pageIndex]="storedPage" showFirstLastButtons>
          </mat-paginator>
          <ng-template #noRecordsMessage>
            <h1 class="text-center fw-bold">No Records Available..!</h1>
          </ng-template>
        </div>
        <ngx-loading [show]="loading"
                     [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                     [template]="loadingTemplate"></ngx-loading>
      </div>
    </some-element>

    <!--    <some-element *ngSwitchCase="'image4'">-->
    <!--      <div class="card">-->
    <!--        <div class="card-header">-->
    <!--          <div class="card-header-title font-size-lg text-capitalize fw-Bold">-->
    <!--            Image Upload-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="container p-4">-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-10 center p-1">-->
    <!--              <label style="margin-bottom: 10px;">-->
    <!--                Image 4-->
    <!--              </label>-->
    <!--              <input class="form-control" type="file" (change)="getFiles($event)">-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="card-footer">-->
    <!--          <div class="btn-actions-pane-right p-2">-->
    <!--            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>-->
    <!--            <div class="mb-2 me-2 btn btn-primary" (click)="uploadimage4()">Save</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </some-element>-->
    <!--    <some-element *ngSwitchCase="'pod'">-->
    <!--      <div class="card">-->
    <!--        <div class="card-header">-->
    <!--          <div class="card-header-title font-size-lg text-capitalize fw-Bold">-->
    <!--            File Upload-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="container p-4">-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-10 center p-1">-->
    <!--              <label style="margin-bottom: 10px;">-->
    <!--                POD-->
    <!--              </label>-->
    <!--              <input class="form-control" type="file" (change)="getFiles($event)">-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="card-footer">-->
    <!--          <div class="btn-actions-pane-right p-2">-->
    <!--            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>-->
    <!--            <div class="mb-2 me-2 btn btn-primary" (click)="uploadPOD()">Save</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </some-element>-->
    <!--    <some-element *ngSwitchCase="'datalogger'">-->
    <!--      <div class="card">-->
    <!--        <div class="card-header">-->
    <!--          <div class="card-header-title font-size-lg text-capitalize fw-Bold">-->
    <!--            File Upload-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="container p-4">-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-10 center p-1">-->
    <!--              <label style="margin-bottom: 10px;">-->
    <!--                Data Logger-->
    <!--              </label>-->
    <!--              <input class="form-control" type="file" (change)="getFiles($event)">-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="card-footer">-->
    <!--          <div class="btn-actions-pane-right p-2">-->
    <!--            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>-->
    <!--            <div class="mb-2 me-2 btn btn-primary" (click)="uploadDatafiles()">Save</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </some-element>-->
    <!--    <some-element *ngSwitchCase="'vehiclecalibration'">-->
    <!--      <div class="card">-->
    <!--        <div class="card-header">-->
    <!--          <div class="card-header-title font-size-lg text-capitalize fw-Bold">-->
    <!--            File Upload-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="container p-4">-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-10 center p-1">-->
    <!--              <label style="margin-bottom: 10px;">-->
    <!--                Vehicle Calibration-->
    <!--              </label>-->
    <!--              <input class="form-control" type="file" (change)="getFiles($event)">-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="card-footer">-->
    <!--          <div class="btn-actions-pane-right p-2">-->
    <!--            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>-->
    <!--            <div class="mb-2 me-2 btn btn-primary" (click)="uploadVechilefiles()">Save</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </some-element>-->
    <!--    <some-element *ngSwitchCase="'otherdocuments'">-->
    <!--      <div class="card">-->
    <!--        <div class="card-header">-->
    <!--          <div class="card-header-title font-size-lg text-capitalize fw-Bold">-->
    <!--            File Upload-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="container p-4">-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-10 center p-1">-->
    <!--              <label style="margin-bottom: 10px;">-->
    <!--                Other Documents-->
    <!--              </label>-->
    <!--              <input class="form-control" type="file" (change)="getFiles($event)">-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="card-footer">-->
    <!--          <div class="btn-actions-pane-right p-2">-->
    <!--            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>-->
    <!--            <div class="mb-2 me-2 btn btn-primary" (click)="uploadOthersfiles()">Save</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </some-element>-->
    <!--    <some-element *ngSwitchCase="'temp'">-->
    <!--      <div class="card">-->
    <!--        <div class="card-header">-->
    <!--          <div class="card-header-title font-size-lg text-capitalize fw-Bold">-->
    <!--            File Upload-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="container p-4">-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-10 center p-1">-->
    <!--              <label style="margin-bottom: 10px;">-->
    <!--                Temperature Record-->
    <!--              </label>-->
    <!--              <input class="form-control" type="file" (change)="getTempfiles($event)">-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="card-footer">-->
    <!--          <div class="btn-actions-pane-right p-2">-->
    <!--            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>-->
    <!--            <div class="mb-2 me-2 btn btn-primary" (click)="uploadTemp()">Save</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </some-element>-->
    <!--    <some-element *ngSwitchCase="'image3'">-->
    <!--      <div class="card">-->
    <!--        <div class="card-header">-->
    <!--          <div class="card-header-title font-size-lg text-capitalize fw-Bold">-->
    <!--            Image Upload-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="container p-4">-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-10 center p-1">-->
    <!--              <label style="margin-bottom: 10px;">-->
    <!--                Image 3-->
    <!--              </label>-->
    <!--              <input class="form-control" type="file" (change)="getFiles($event)">-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="card-footer">-->
    <!--          <div class="btn-actions-pane-right p-2">-->
    <!--            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>-->
    <!--            <div class="mb-2 me-2 btn btn-primary" (click)="uploadimage3()">Save</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </some-element>-->
    <!--    <some-element *ngSwitchCase="'image2'">-->
    <!--      <div class="card">-->
    <!--        <div class="card-header">-->
    <!--          <div class="card-header-title font-size-lg text-capitalize fw-Bold">-->
    <!--            Image Upload-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="container p-4">-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-10 center p-1">-->
    <!--              <label style="margin-bottom: 10px;">-->
    <!--                Image 2-->
    <!--              </label>-->
    <!--              <input class="form-control" type="file" (change)="getFiles($event)">-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="card-footer">-->
    <!--          <div class="btn-actions-pane-right p-2">-->
    <!--            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>-->
    <!--            <div class="mb-2 me-2 btn btn-primary" (click)="uploadimage2()">Save</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </some-element>-->
    <!--    <some-element *ngSwitchCase="'image1'">-->
    <!--      <div class="card">-->
    <!--        <div class="card-header">-->
    <!--          <div class="card-header-title font-size-lg text-capitalize fw-Bold">-->
    <!--            Image Upload-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="container p-4">-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-10 center p-1">-->
    <!--              <label style="margin-bottom: 10px;">-->
    <!--                Image 1-->
    <!--              </label>-->
    <!--              <input class="form-control" type="file" (change)="getFiles($event)">-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="card-footer">-->
    <!--          <div class="btn-actions-pane-right p-2">-->
    <!--            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>-->
    <!--            <div class="mb-2 me-2 btn btn-primary" (click)="uploadimage1()">Save</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </some-element>-->
    <!--    <some-element *ngSwitchCase="'view'">-->
    <!--      <div class="row">-->
    <!--        <div class="card col-md-12">-->
    <!--          <div class="card-header">-->

    <!--            <div class="card-header-title font-size-lg text-capitalize fw-Bold"><i class="fa fa-arrow-left" style="cursor: pointer" (click)="viewchange('table',0)"></i>-->
    <!--              Consignment No.-->
    <!--            </div>-->
    <!--            <div *ngFor="let con of consignmentlists.data"-->
    <!--              class="card-header-title font-size-lg text-capitalize fw-Bold text-primary mx-4">-->
    <!--              {{con.consignmentNo}} ({{con?.consignmentType?.details}})-->
    <!--            </div>-->
    <!--            <div class="btn-actions-pane-right p-2" *ngFor="let con of consignmentlists.data">-->
    <!--              <div class="mb-2 me-2 btn btn-primary" (click)="getRecord(con.id)">-->
    <!--                Download CN</div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="container m-2">-->

    <!--            <div class="row">-->
    <!--              <div class="col-md-12">-->
    <!--                <div *ngFor="let con of consignmentlists.data">-->
    <!--                  <div class="row">-->
    <!--                    <div class="col-md-3 fw-bold">Customer Name</div>-->
    <!--                    <div class="col-md-3"> {{con?.senderId?.firstName!=null-->
    <!--                      ?con?.senderId?.firstName: con?.senderIndividual?.first_name }} </div>-->
    <!--                    <div class="col-md-3 fw-bold border-start">Consignee Name</div>-->
    <!--                    <div class="col-md-3"> {{con?.receiverId?.firstName}}</div>-->
    <!--                  </div>-->
    <!--                  <mat-divider class="m-1"></mat-divider>-->
    <!--                  <div class="row ">-->
    <!--                    <div class="col-md-3 fw-bold">Origin</div>-->
    <!--                    <div class="col-md-3">{{ con?.originLocation?.city.name ||-->
    <!--                    (con?.pickupAddress?.cityId?.name ||-->
    <!--                      'N/A')}}</div>-->
    <!--                    <div class="col-md-3 fw-bold border-start">Destination</div>-->
    <!--                    <div class="col-md-3">-->
    <!--                      {{ con?.destinationLocation?.city.name || (con?.receiverId?.city?.name ||-->
    <!--                      'N/A') }}-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <mat-divider class="m-1"></mat-divider>-->
    <!--                  <div class="row ">-->
    <!--                    <div class="col-md-3 fw-bold">Consignment Type</div>-->
    <!--                    <div class="col-md-3"> {{con.consignmentType.details}} </div>-->
    <!--                    <div class="col-md-3 fw-bold border-start">Consignment SubType</div>-->
    <!--                    <div class="col-md-3"> {{con.consignmentSubType.description}} </div>-->
    <!--                  </div>-->
    <!--                  <mat-divider class="m-1"></mat-divider>-->
    <!--                  <div class="row ">-->
    <!--                    <div class="col-md-3 fw-bold">Product Mode</div>-->
    <!--                    <div class="col-md-3"> {{con?.series_ref?.product_mode}} </div>-->
    <!--                    <div class="col-md-3 fw-bold border-start">Product Type</div>-->
    <!--                    <div class="col-md-3"> {{con?.series_ref?.code}} </div>-->
    <!--                  </div>-->
    <!--                  <mat-divider class="m-1"></mat-divider>-->
    <!--                  <div class="row ">-->
    <!--                    <div class="col-md-3 fw-bold">Created At</div>-->
    <!--                    <div class="col-md-3"> {{formatDate(con.createdAt)}} </div>-->
    <!--                    <div class="col-md-3 fw-bold border-start">Updated At</div>-->
    <!--                    <div class="col-md-3" *ngIf="con.updatedAt!=null"> {{formatDate(con.updatedAt)}} </div>-->
    <!--                  </div>-->
    <!--                  <mat-divider class="m-1"></mat-divider>-->
    <!--                  <div class="row ">-->
    <!--                    <div class="col-md-3 fw-bold ">Consignment Value</div>-->
    <!--                    <div class="col-md-3"> {{con.consignmentValue}}</div>-->

    <!--                    <div class="col-md-3 fw-bold border-start ">Status</div>-->
    <!--                    <div class=" col-md-3">-->
    <!--                      <div-->
    <!--                        *ngIf="con?.trackingId?.status.id != 16 && con?.trackingId?.status.id!= 19 && con?.trackingId?.status.id!=18"-->
    <!--                        class="badge bg-pill bg-primary">-->
    <!--                        {{con?.trackingId?.status?.name}}-->
    <!--                      </div>-->
    <!--                      <div *ngIf="con?.trackingId?.status.id == 16" class="badge bg-pill bg-success">-->
    <!--                        {{con?.trackingId?.status?.name}}-->
    <!--                      </div>-->
    <!--                      <div *ngIf="con?.trackingId?.status.id == 19" class="badge bg-pill bg-orange-400">-->
    <!--                        {{con?.trackingId?.status?.name}}-->
    <!--                      </div>-->
    <!--                      <div *ngIf="con?.trackingId?.status.id==18" class="badge bg-pill bg-danger">-->
    <!--                        {{con?.trackingId?.status?.name}}-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                    &lt;!&ndash; <div *ngIf="con.trackingId" class="badge bg-pill bg-primary col-md-3">-->
    <!--                      {{con.trackingId?.status?.name}}-->
    <!--                    </div> &ndash;&gt;-->
    <!--                  </div>-->
    <!--                  <mat-divider class="m-1"></mat-divider>-->
    <!--                  <div class="row ">-->
    <!--                    <div class="col-md-3 fw-bold">Temperature Range</div>-->
    <!--                    <div *ngIf="con?.required_temperature == null" class="col-md-3">-->
    <!--                      - -->
    <!--                    </div>-->
    <!--                    <div *ngIf="con?.required_temperature != null" class="col-md-3"> {{con?.required_temperature}} </div>-->
    <!--                    <div class="col-md-3 fw-bold border-start">Invoice No</div>-->
    <!--                    <div *ngIf="invoiceId == null" class="col-md-3"> - </div>-->
    <!--                    <div *ngIf="billCreated && invoiceId != null" class="col-md-3"> {{InvoiceData.invoiceNo}} </div>-->
    <!--                  </div>-->
    <!--                  <mat-divider class="m-1"></mat-divider>-->
    <!--&lt;!&ndash;                  <div class="row ">&ndash;&gt;-->
    <!--&lt;!&ndash;                    <div class="col-md-3 fw-bold">AWB Number</div>&ndash;&gt;-->
    <!--&lt;!&ndash;                    <div class="col-md-3"> {{con?.preManifestationId?.awb_number}} </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                    <div class="col-md-3 fw-bold border-start">Manifest Id</div>&ndash;&gt;-->
    <!--&lt;!&ndash;                    <div class="col-md-3"> {{con?.preManifestationId?.manifestIdLeg1}} - {{con?.preManifestationId?.manifestIdLeg2}} </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                  </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                  <mat-divider class="mb-3"></mat-divider>&ndash;&gt;-->
    <!--                  <div class="row ">-->
    <!--                    <div class="col-md-3 fw-bold">PickUp Date</div>-->
    <!--                    <div *ngIf="con?.tentative_pickup_time == null" class="col-md-3">-->
    <!--                      - -->
    <!--                    </div>-->
    <!--                    <div *ngIf="con?.tentative_pickup_time != null" class="col-md-3">-->
    <!--                      {{formatDate(con?.tentative_pickup_time)}} </div>-->
    <!--                  </div>-->
    <!--                  <mat-divider class="mb-3"></mat-divider>-->
    <!--                  <div class="row">-->
    <!--                    <div class="text-primary fw-bold"> PACKAGES</div>-->
    <!--                  </div>-->
    <!--                  <mat-divider class="m-1"></mat-divider>-->
    <!--                  <table class="align-middle text-truncate  mb-0 table table-borderless table-hover">-->
    <!--                    <thead>-->
    <!--                      <tr>-->
    <!--                        <th class="text-center">Dimentions</th>-->
    <!--                        <th class="test-center">Total Packages</th>-->
    <!--                        <th class="text-center">Weight Per Package</th>-->
    <!--                        <th class="text-center">Chargeable Weight</th>-->
    <!--                        <th class="text-center">Total Chargeable Weight</th>-->
    <!--                      </tr>-->
    <!--                    </thead>-->
    <!--                    <tbody>-->
    <!--                      <tr *ngFor="let pack of con.packages">-->
    <!--                        <td class="text-center">-->
    <!--                          {{pack.length}}*{{pack.breadth}}*{{pack.height}}-->
    <!--                          <br />-->
    <!--                        </td>-->
    <!--                        <td class="text-center">-->
    <!--                          {{pack.package_no}}-->
    <!--                        </td>-->
    <!--                        <td class="text-center">-->
    <!--                          {{pack.weight_per_package}}Kg-->
    <!--                        </td>-->
    <!--                        <td class="text-center">-->
    <!--                          {{pack.total_weight}}-->
    <!--                        </td>-->
    <!--                        <td class="text-center">-->
    <!--                          {{pack.chargeable_weight}}Kg-->
    <!--                        </td>-->
    <!--                      </tr>-->
    <!--                    </tbody>-->
    <!--                  </table>-->
    <!--                  <mat-divider class="m-1"></mat-divider>-->
    <!--                  <div class="row ">-->
    <!--                    <div class="col-md-3 fw-bold  ">Total Num of Packages</div>-->
    <!--                    <div class="col-md-3">-->
    <!--                      {{con.totalNoOfPackages}}-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <mat-divider class="m-1"></mat-divider>-->
    <!--                  <div class="row ">-->
    <!--                    <div class="col-md-3 fw-bold ">Total Weight</div>-->
    <!--                    <div class="col-md-3"> {{con.totalWeight}} </div>-->
    <!--                    <div class="col-md-3 fw-bold border-start ">Total Chargeable Weight</div>-->
    <!--                    <div class="col-md-3"> {{con.totalChargableWeight}} </div>-->
    <!--                  </div>-->
    <!--                  <mat-divider class="m-1"></mat-divider>-->
    <!--                  <div class="row ">-->
    <!--                    <div class="col-md-3 fw-bold ">Taxable Amount</div>-->
    <!--                    <div class="col-md-3">-->
    <!--                      <span *ngIf="billCreated"> INR {{billingPriceTaxableAmount}}</span>-->
    <!--                    </div>-->
    <!--                    <div class="col-md-3 fw-bold border-start">Grand Total</div>-->
    <!--                    <div class="col-md-3">-->
    <!--                      <span *ngIf="billCreated"> INR {{billingPriceGrandTotal}}</span>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <mat-divider class="mb-3"></mat-divider>-->
    <!--                  <div class="row">-->
    <!--                    <div class=" col-md-6 text-primary fw-bold"> SHIPPER ADDRESS</div>-->
    <!--                    <div class=" col-md-6 text-primary fw-bold"> CONSIGNEE ADDRESS</div>-->
    <!--                  </div>-->
    <!--                  <mat-divider class="m-1"></mat-divider>-->
    <!--                  <div class="row ">-->
    <!--                    <div class="col-md-3 fw-bold">Pickup Address</div>-->
    <!--                    <div class="col-md-3">{{con?.senderIndividual?.address}}-->
    <!--                      {{con?.senderIndividual?.city?.name}},{{con?.senderIndividual?.city?.state_id?.name}}-->
    <!--                      <br>-->
    <!--                      {{con?.senderIndividual?.city?.state_id?.country?.name}}-->
    <!--                    </div>-->
    <!--                    <div class="col-md-3 fw-bold border-start ">Drop Address</div>-->
    <!--                    <div class="col-md-3">-->
    <!--                      {{con?.receiverId?.address + "," + con?.receiverId?.city?.name + "," +-->
    <!--                    con?.receiverId?.city?.state_id?.name}}-->
    <!--                      <br>-->
    <!--                      {{con?.receiverId?.city?.state_id?.country?.name}},{{con?.receiverId?.zipCode}}-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="card-footer">-->

    <!--              <div class="btn-actions-pane-right p-2">-->
    <!--                <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table',0)">Back</div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="row">-->
    <!--        <div class="col-md-7">-->
    <!--          <div class="row">-->

    <!--            <div class="card mt-2">-->
    <!--              <div class="card-header">-->
    <!--                <div class="card-header-title font-size-lg text-capitalize fw-Bold">-->
    <!--                  Images-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="row ">-->
    <!--                <div class="col-md-6">-->
    <!--                  <div class="fw-bold h4 margin-h-center pt-4 text-center ">-->
    <!--                    Image 1-->
    <!--                    <div class=" fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].image1==null"-->
    <!--                      style="font-size: 15px;">-->
    <!--                      Image 1 is not uploaded-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="row">-->
    <!--                    <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].image1!=null">-->
    <!--                      <img [src]='image_1' (click)="open(content5, image_1)" style="height: 200px; width: 200px;"-->
    <!--                        alt="Image" class="rounded mx-auto d-block p-1" alt="Responsive image">-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="col-md-6">-->
    <!--                  <div class="fw-bold h4 margin-h-center pt-4 text-center">-->
    <!--                    Image 2-->
    <!--                    <div class=" fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].image2==null"-->
    <!--                      style="font-size: 15px;">-->
    <!--                      Image 2 is not uploaded-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="row">-->
    <!--                    <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].image2!=null">-->
    <!--                      <img [src]='image_2' (click)="open(content5, image_2)" style="height: 200px; width: 200px;"-->
    <!--                        alt="Image" class="rounded mx-auto d-block p-1" alt="Responsive image">-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="row mb-5">-->
    <!--                <div class="col-md-6">-->
    <!--                  <div class="fw-bold h4 margin-h-center pt-4 text-center">-->
    <!--                    Image 3-->
    <!--                    <div class=" fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].image3==null"-->
    <!--                      style="font-size: 15px;">-->
    <!--                      Image 3 is not uploaded-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="row">-->
    <!--                    <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].image3!=null">-->
    <!--                      <img [src]='image_3' (click)="open(content5, image_3)" style="height: 200px; width: 200px;"-->
    <!--                        alt="Image" class="rounded mx-auto d-block  p-1" alt="Responsive image">-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="col-md-6">-->
    <!--                  <div class="fw-bold h4 margin-h-center pt-4 text-center">-->
    <!--                    Image 4-->

    <!--                    <div class="fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].image4==null"-->
    <!--                      style="font-size: 15px;">-->
    <!--                      Image 4 is not uploaded-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="row">-->
    <!--                    <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].image4!=null">-->
    <!--                      <img [src]='image_4' (click)="open(content5, image_4)" style="height: 200px; width: 200px;"-->
    <!--                        alt="Image" class="rounded mx-auto d-block p-1" alt="Responsive image">-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <ng-template #content5 let-c="close" let-d="dismiss">-->
    <!--                    <div class="modal-header">-->
    <!--                      <h4 class="modal-title fw-bold">View Image</h4>-->
    <!--                      <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">-->
    <!--                      </button>-->
    <!--                    </div>-->
    <!--                    <div class="modal-body">-->
    <!--                      <img [src]='largeImage' alt="Image" class="rounded mx-auto d-block w-100 p-5"-->
    <!--                        alt="Responsive image">-->

    <!--                    </div>-->
    <!--                    <div class="modal-footer">-->
    <!--                      <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>-->
    <!--                    </div>-->
    <!--                  </ng-template>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="row">-->

    <!--            <div class="card mt-2">-->
    <!--              <div class="card-header">-->
    <!--                <div class="card-header-title font-size-lg text-capitalize fw-Bold">-->
    <!--                  Documents-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="row ">-->
    <!--                <div class="col-md-6">-->
    <!--                  <div class="fw-bold h4 margin-h-center pt-4 text-center ">-->
    <!--                    Data Logger File-->

    <!--                    <div class="fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].dataLoggerFile==null"-->
    <!--                      style="font-size: 15px;">-->
    <!--                      DataLoggerFile is not uploaded-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="row">-->
    <!--                    <div class="col-md-4 m-auto">-->
    <!--                      <button class="font-icon-wrapper" *ngIf="consignmentlists.data[0].dataLoggerFile!=null"-->
    <!--                        (click)="getDatalogger()"><i class="fa fa-fw text-success "-->
    <!--                          matTooltip="Download" aria-hidden="true" style="font-size: 50px;"></i>-->
    <!--                      </button>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="col-md-6">-->
    <!--                  <div class="fw-bold h4 margin-h-center pt-4 text-center">-->
    <!--                    Vehicle Calibration File-->

    <!--                    <div class="fw-normal text-danger mt-4"-->
    <!--                      *ngIf="consignmentlists.data[0].vehicleCalibrationFile==null" style="font-size: 15px;">-->
    <!--                      VehicleCalibrationFile is not uploaded-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="row">-->
    <!--                    <div class="col-md-4 m-auto">-->
    <!--                      <button class="font-icon-wrapper" *ngIf="consignmentlists.data[0].vehicleCalibrationFile!=null"-->
    <!--                        (click)="getvehiclecalibration()"><i class="fa fa-fw text-success"-->
    <!--                          aria-hidden="true" matTooltip="Download" style="font-size: 50px;"></i>-->
    <!--                      </button>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="row">-->
    <!--                <div class="col-md-6">-->
    <!--                  <div class="fw-bold h4 margin-h-center pt-4 text-center">-->
    <!--                    Other Documents File-->

    <!--                    <div class="fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].otherDocumentsFile==null"-->
    <!--                      style="font-size: 15px;">-->
    <!--                      OtherDocumentsFile is not uploaded-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="row">-->
    <!--                    <div class="col-md-4 m-auto">-->
    <!--                      <button class="font-icon-wrapper" *ngIf="consignmentlists.data[0].otherDocumentsFile!=null"-->
    <!--                        (click)="getotherdocuments()"><i class="fa fa-fw text-success"-->
    <!--                          aria-hidden="true" matTooltip="Download" style="font-size: 50px;"></i>-->
    <!--                      </button>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="col-md-6">-->
    <!--                  <div class="fw-bold h4 margin-h-center pt-4 text-center">-->
    <!--                    Temperature Record File-->

    <!--                    <div class="fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].temperatureRecordFile==null"-->
    <!--                      style="font-size: 15px;">-->
    <!--                      TemperatureRecordFile is not uploaded-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="row">-->
    <!--                    <div class="col-md-4 m-auto">-->
    <!--                      <button class="font-icon-wrapper" *ngIf="consignmentlists.data[0].temperatureRecordFile!=null"-->
    <!--                        (click)="gettemp()"><i class="fa fa-fw text-success"-->
    <!--                          aria-hidden="true" matTooltip="Download" style="font-size: 50px;"></i>-->
    <!--                      </button>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="row">-->
    <!--                <div class="col-md-6">-->
    <!--                  <div class="fw-bold h4 margin-h-center pt-4 text-center">-->
    <!--                    POD-->
    <!--                    <div class="fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].pod==null"-->
    <!--                      style="font-size: 15px;">-->
    <!--                      POD is not uploaded-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="row">-->
    <!--                    <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].pod!=null">-->
    <!--                      <img [src]='POD' (click)="open(content5, POD)" style="height: 200px; width: 200px;" alt="Image"-->
    <!--                        class="rounded mx-auto d-block p-1" alt="Responsive image">-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="col-md-5">-->
    <!--          <div class="card mt-2">-->
    <!--            <div class="card-header">-->
    <!--              <div class="card-header-title font-size-lg text-capitalize fw-bold">-->
    <!--                Tracking-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="card-body">-->
    <!--              <div class="row">-->
    <!--                <div class="row">-->
    <!--                  <div class="col-md-6">-->
    <!--                    <div *ngFor="let tra of consignmentlists.data[0].trackingId.trackingStatusComments;let i=index">-->
    <!--                    <div class="vertical-timeline vertical-timeline&#45;&#45;animate vertical-timeline&#45;&#45;one-column" *ngIf="tra.status.activeForCustomer == true " >-->
    <!--                      <div class="vertical-timeline-item vertical-timeline-element"-->
    <!--                      >-->
    <!--                        <div>-->
    <!--                          <span class="vertical-timeline-element-icon bounce-in" *ngIf="tra.status.activeForCustomer == true ">-->
    <!--                            <i class="badge badge-dot badge-dot-xl bg-success"-->
    <!--                              [ngClass]="tra.createdAt == null?'bg-light':'bg-primary'"></i>-->
    <!--                          </span>-->
    <!--                          <div class="vertical-timeline-element-content bounce-in">-->
    <!--                            <h4 class="timeline-title" *ngIf="tra.status.activeForCustomer == true ">{{tra.status.name}}</h4>-->
    <!--                            <p *ngIf="tra.status.activeForCustomer == true" >{{tra.comment}}</p>-->
    <!--                            <input matInput readonly [(ngModel)]="origin" *ngIf="tra.status.activeForCustomer == true && tra.status.id>0 && tra.status.id<11">-->
    <!--                            <input matInput readonly [(ngModel)]="destination" *ngIf="tra.status.activeForCustomer == true && tra.status.id>10 && tra.status.id<17">-->
    <!--                            <p class="" *ngIf="tra.status.activeForCustomer == true ">{{getTime(tra.createdAt)}}</p>-->
    <!--                            &lt;!&ndash; <p class="">{{getTime(tra.createdAt)}}</p> &ndash;&gt;-->
    <!--                          </div>-->
    <!--                        </div>-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        &lt;!&ndash; <div class="col-lg-6 col-md-6">-->
    <!--          <div class="col-md-6">-->
    <!--            <div class="card-body" *ngIf="current_status_id>=16">-->
    <!--              <div class="fw-bold h4 margin-h-center pt-4 text-center">-->
    <!--                <b style="color: rgb(11, 11, 242);">POD</b>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <img [src]='POD' style="height: 200px; width: 200px;" alt="Image" class="rounded mx-auto d-block p-1" alt="Responsive image">-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div> &ndash;&gt;-->
    <!--      </div>-->
    <!--    </some-element>-->
    <!--    <some-element *ngSwitchCase="'edit'">-->
    <!--      <div class="main-card mb-3 card">-->
    <!--        <div class="card-header">-->
    <!--          <div class="card-header-title font-size-lg text-capitalize fw-normal">-->
    <!--            Edit Consignments-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="container ">-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-2 fw-bold h4">Consignment Number</div>-->
    <!--            <div class="col-md-2 h4 text-primary">{{consignment_details.consignmentNo}}</div>-->
    <!--          </div>-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-2">-->

    <!--              <mat-form-field style="width: 90%;" appearance="standard">-->
    <!--                <mat-label for="totalWeight">-->
    <!--                  Invoice Reference No.<span class="text-danger">*</span>-->
    <!--                </mat-label>-->
    <!--                <input id="total_no_packages" matInput [(ngModel)]="consignment_details.invoiceReferenceNo">-->
    <!--              </mat-form-field>-->

    <!--            </div>-->
    <!--            <div class="col-md-2">-->

    <!--              <mat-form-field style="width: 90%;" appearance="standard">-->
    <!--                <mat-label>-->
    <!--                  Invoice Date-->
    <!--                </mat-label>-->
    <!--                <input id="total_no_packages" matInput [(ngModel)]="consignment_details.invoiceCreatedDate"-->
    <!--                  [max]="maxDate" [matDatepicker]="picker" disabled>-->
    <!--                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
    <!--                <mat-datepicker #picker disabled="false"></mat-datepicker>-->
    <!--              </mat-form-field>-->

    <!--            </div>-->
    <!--            <div class="col-md-2">-->

    <!--              <mat-form-field style="width: 90%;" appearance="standard">-->
    <!--                <mat-label for="totalWeight">-->
    <!--                  Consignment Value-->
    <!--                </mat-label>-->
    <!--                <input id="Consignment Value" matInput [(ngModel)]="consignment_details.totalConsignmentValue">-->
    <!--              </mat-form-field>-->

    <!--            </div>-->
    <!--            <div class="col-md-2">-->
    <!--              <mat-form-field appearance="standard">-->

    <!--                <mat-label for="dimensionuUnits">-->
    <!--                  Weight Divisor<span class="text-danger">*</span>-->
    <!--                </mat-label>-->
    <!--                <mat-select id="dimensionuUnits" [(ngModel)]="consignment_details.weightDivisor"-->
    <!--                  (ngModelChange)="weightDivisorChange()">-->

    <!--                  <mat-option *ngFor="let w_div of weightDivisorList " [value]="w_div">-->
    <!--                    {{w_div}}-->
    <!--                  </mat-option>-->
    <!--                </mat-select>-->
    <!--              </mat-form-field>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-8 row">-->
    <!--              <mat-form-field style="width: 90%;" appearance="standard">-->
    <!--                <mat-label for="totalWeight">-->
    <!--                  Remarks-->
    <!--                </mat-label>-->
    <!--                <textarea id="Remarks" matInput [(ngModel)]="consignment_details.remarks">-->
    <!--                  </textarea>-->
    <!--              </mat-form-field>-->

    <!--            </div>-->
    <!--            <div class="col-md-2">-->

    <!--              <mat-form-field style="width: 90%;" appearance="standard">-->
    <!--                <mat-label>-->
    <!--                  Created Date-->
    <!--                </mat-label>-->
    <!--                <input id="createdAt" matInput [(ngModel)]="consignment_details.createdAt" [max]="maxDate"-->
    <!--                  [matDatepicker]="pickers" disabled>-->
    <!--                <mat-datepicker-toggle matSuffix [for]="pickers"></mat-datepicker-toggle>-->
    <!--                <mat-datepicker #pickers disabled="false"></mat-datepicker>-->
    <!--              </mat-form-field>-->

    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="row" style="width: 100%;">-->
    <!--            <mat-divider></mat-divider>-->
    <!--            <div class="table-responsive">-->
    <!--              <div class="table-responsive">-->
    <!--                <table class="table p-2 ">-->
    <!--                  <thead>-->
    <!--                    <tr>-->

    <!--                      <th>-->
    <!--                        #-->
    <!--                      </th>-->
    <!--                      <th style="min-width: 150px;">-->
    <!--                        Package Dimentions-->
    <!--                      </th>-->
    <!--                      <th class="mx-w-150">-->
    <!--                        No of Packages-->
    <!--                      </th>-->
    <!--                      <th class="mx-w-150">-->
    <!--                        Weight per Package-->
    <!--                      </th>-->
    <!--                      <th class="mx-w-150">-->
    <!--                        Volume Weight-->
    <!--                      </th>-->
    <!--                      <th class="mx-w-200">-->
    <!--                        Total Packages Weight-->
    <!--                      </th>-->
    <!--                      <th class="mx-w-200">-->
    <!--                        Chargeable Weight-->
    <!--                      </th>-->
    <!--                      <th>-->
    <!--                      </th>-->
    <!--                    </tr>-->
    <!--                  </thead>-->
    <!--                  <tbody>-->
    <!--                    <tr *ngFor="let package of changeable_packages">-->
    <!--                      <td>-->
    <!--                        {{package.id}}-->
    <!--                      </td>-->
    <!--                      <td class="mx-w-150">-->
    <!--                        <div class="row">-->
    <!--                          <div class="col-md-4">-->
    <!--                            <input type="text" placeholder="L" aria-label="Number" matInput [(ngModel)]="package.length"-->
    <!--                              min="1" (blur)="eachPackageCharged(package.id)">-->
    <!--                            <span matSuffix>{{selectedDimention}}</span>-->
    <!--                          </div>-->
    <!--                          <div class="col-md-4">-->

    <!--                            <input type="text" placeholder="B" aria-label="Number" matInput-->
    <!--                              [(ngModel)]="package.breadth" min="1" (blur)="eachPackageCharged(package.id)">-->
    <!--                            <span matSuffix>{{selectedDimention}}</span>-->
    <!--                          </div>-->
    <!--                          <div class="col-md-4">-->
    <!--                            <input type="text" placeholder="H" aria-label="Number" matInput [(ngModel)]="package.height"-->
    <!--                              min="1" (blur)="eachPackageCharged(package.id)">-->
    <!--                            <span matSuffix>{{selectedDimention}}</span>-->
    <!--                          </div>-->
    <!--                        </div>-->
    <!--                      </td>-->
    <!--                      <td class="mx-w-150">-->
    <!--                        <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
    <!--                          <input type="text" placeholder="No of Packages" aria-label="Number" matInput-->
    <!--                            [(ngModel)]="package.package_no" min="1" (blur)="eachPackageCharged(package.id)">-->

    <!--                        </mat-form-field>-->
    <!--                      </td>-->
    <!--                      <td class="mx-w-150">-->

    <!--                        <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
    <!--                          <input type="text" placeholder="package weight" aria-label="Number" matInput-->
    <!--                            [(ngModel)]="package.weight_per_package" min="1" (blur)="eachPackageCharged(package.id)">-->
    <!--                          <span matSuffix>{{ selectedWeight}}</span>-->
    <!--                        </mat-form-field>-->
    <!--                      </td>-->
    <!--                      <td class="mx-w-150">-->
    <!--                        <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
    <!--                          <input type="text" placeholder="Volume Weight" matInput disabled-->
    <!--                            [(ngModel)]="package.volume_weight">-->
    <!--                        </mat-form-field>-->
    <!--                      </td>-->
    <!--                      <td class="mx-w-200">-->
    <!--                        <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
    <!--                          <input placeholder="Total Package Weight" matInput disabled-->
    <!--                            [(ngModel)]="package.total_weight">-->
    <!--                          <span matSuffix>{{ selectedWeight}}</span>-->

    <!--                        </mat-form-field>-->
    <!--                      </td>-->
    <!--                      <td class="mx-w-200">-->
    <!--                        <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
    <!--                          <input placeholder="Chargeable Weight" matInput disabled-->
    <!--                            [(ngModel)]="package.chargeable_weight">-->
    <!--                          <span matSuffix>{{ selectedWeight}}</span>-->

    <!--                        </mat-form-field>-->
    <!--                      </td>-->
    <!--                    </tr>-->
    <!--                    <tr>-->
    <!--                      <td class="mx-w-150">-->
    <!--                      </td>-->
    <!--                      <td class="mx-w-150">-->
    <!--                      </td>-->
    <!--                      <th class="mx-w-150">-->
    <!--                        <mat-label for="totalWeight">-->
    <!--                          Total No. Packages-->
    <!--                        </mat-label>-->
    <!--                        <mat-form-field style="width: 90%;" appearance="standard">-->
    <!--                          <input id="total_no_packages" matInput disabled value="Total No. Packages"-->
    <!--                            [(ngModel)]="consignment_details.totalNoOfPackages">-->
    <!--                        </mat-form-field>-->
    <!--                      </th>-->


    <!--                      <td>-->
    <!--                      </td>-->
    <!--                      <td>-->

    <!--                      </td>-->
    <!--                      <th class="mx-w-150">-->
    <!--                        <mat-label for="totalWeight">-->
    <!--                          Total Weight-->
    <!--                        </mat-label>-->
    <!--                        <mat-form-field style="width: 90%;" appearance="standard">-->
    <!--                          <input id="totalWeight" matInput disabled value="Google"-->
    <!--                            [(ngModel)]="consignment_details.totalWeight">-->
    <!--                          <span matSuffix>{{ selectedWeight}}</span>-->

    <!--                        </mat-form-field>-->
    <!--                      </th>-->
    <!--                      <th class="mx-w-200">-->
    <!--                        <mat-label for="totalWeight">-->
    <!--                          Total Chargeable Weight-->
    <!--                        </mat-label>-->
    <!--                        <mat-form-field style="width: 90%;" appearance="standard">-->
    <!--                          <input id="total_charged" matInput disabled value="Google"-->
    <!--                            [(ngModel)]="consignment_details.totalChargableWeight">-->
    <!--                          <span matSuffix>{{ selectedWeight}}</span>-->

    <!--                        </mat-form-field>-->
    <!--                      </th>-->
    <!--                    </tr>-->
    <!--                  </tbody>-->
    <!--                </table>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="row p-2">-->
    <!--              <div class="col-md-2 m-auto">-->
    <!--                <div class="btn btn-primary btn-lg m-2" (click)="saveChanges()">-->
    <!--                  Save-->
    <!--                </div>-->
    <!--                <div class="btn btn-danger btn-lg m-2" (click)="viewchange('table',0)">-->
    <!--                  Cancel-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </some-element>-->
  </container-element>
</div>
