<div class="col-md-8">
  <container-element [ngSwitch]="pageview">
    <some-element *ngSwitchCase="'table'">
      <div class="card p-4">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            Edit User Details
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  First Name <span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="First Name" aria-label="text" matInput
                  [(ngModel)]="userDetails.firstName" maxlength="60">
                  <mat-hint *ngIf="!userDetails.firstName.length" ><span style="color: red;">Please Enter First Name</span></mat-hint>
                  <mat-hint *ngIf="userDetails.firstName.length == 60" ><span style="color: red;">Please Enter First Name between 60 characters</span></mat-hint>

              </mat-form-field>
            </div>
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Middle Name
                </mat-label>
                <input type="text" placeholder="Middle Name" aria-label="Number" matInput
                  [(ngModel)]="userDetails.middleName" maxlength="45">
                  <mat-hint *ngIf="userDetails.middleName?.length == 45" ><span style="color: red;">Please enter Middle Name between 45 characters</span></mat-hint>

              </mat-form-field>
            </div>
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Last Name <span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Last Name" aria-label="Number" matInput
                  [(ngModel)]="userDetails.lastName" maxlength="45">
                  <mat-hint *ngIf="!userDetails.lastName.length" ><span style="color: red;">Please enter Last Name</span></mat-hint>
                  <mat-hint *ngIf="userDetails.lastName.length == 45" ><span style="color: red;">Please enter Last Name between 45 characters</span></mat-hint>

              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 row">

              <mat-form-field appearance="standard">
                <mat-label>
                  Email <span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Email" aria-label="Number" matInput [(ngModel)]="userDetails.email" [disabled]="true">
              </mat-form-field>
            </div>
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Phone No. <span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Phone No." aria-label="Number" matInput [(ngModel)]="userDetails.phone" (keypress)="validateInput($event)" maxlength="10">
                <mat-hint *ngIf="!userDetails.phone.length" ><span style="color: red;">Please Enter Phone Number</span></mat-hint>
                 <mat-hint align="end">{{ userDetails.phone.length }}/10</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-4 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Whatsapp No.
                </mat-label>
                <input type="text" placeholder="Whatsapp No." aria-label="Number" matInput
                  [(ngModel)]="userDetails.whatsapp" maxlength="10" (keypress)="validateInput($event)" #whatsappNum>
<!--                  <mat-hint *ngIf="!userDetails.whatsapp?.length" ><span style="color: red;">Please enter Whatsapp Number</span></mat-hint>-->
                  <mat-hint align="end">{{whatsappNum.value.length}}/10</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="row">
          <div class="col-md-4 row">
            <mat-form-field appearance="standard">
              <mat-label>
                user_name
              </mat-label>
              <input type="text" placeholder="Address" aria-label="Number" matInput [(ngModel)]="userDetails.user_name">

            </mat-form-field>
          </div>
          <div class="col-md-4 row">
            <mat-form-field appearan ce="standard">
              <mat-label>
                Password
              </mat-label>
              <input type="password" placeholder="password" aria-label="Number" matInput [(ngModel)]="userDetails.password_hash">
            </mat-form-field>
          </div>
          <div class="col-md-4 row">
            <mat-form-field appearance="standard">
              <mat-label>
                Confirm Password
              </mat-label>
              <input type="password" placeholder="confirm password" aria-label="Number"  matInput [(ngModel)]="confirm_password" [formControl]="emailFormControl"  [errorStateMatcher]="matcher">
              <mat-error *ngIf="userDetails.password_hash != confirm_password">
                Password doesn't match
              </mat-error>

            </mat-form-field>
          </div>
        </div> -->
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-2 me-2 btn btn-danger" (click)="viewchange()">Back</div>
              </div>
              <div class="col-md-6">
                <div><button type="button" class="btn btn-primary" (click)="edituser();" >Save</button></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </some-element>
  </container-element>
</div>
