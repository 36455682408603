import {Component, OnInit} from '@angular/core';
import {getCities, getCountries, getStates} from '../../getCities.js';
import {API_URL} from '../../../../../Global_API_URL';
import Swal from 'sweetalert2';
import {getAirlines} from '../../getAirportAirlines.js';
import {ConfirmDialog} from '../../Components/confirmDialog/confirmDialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '../../../auth.service';
import {HttpService} from '../../../services/http.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

@Component({
  selector: 'app-table-airlines',
  templateUrl: './table-airlines.component.html',
  styleUrls: ['./table-airlines.component.sass'],
})
export class TableAirlinesComponent implements OnInit {
  constructor(private matDialog: MatDialog, private authService : AuthService, private httpService: HttpService) {
    this.authService.getAuthToken();
  }


  pageview = 'table';
  airlines = getAirlines();
  states = getStates();
  countries = getCountries();
  cities = getCities();

  // activestatus = 1

  newAirline = {
    id: null,
    name: null,
    alias: null,
    iata_code_mawb: null,
    iata_code: null,
    isActive: 1,
  }

  viewchange(val) {
    this.pageview = val;
    this.newAirline = {
      id: null,
      name: null,
      alias: null,
      iata_code_mawb: null,
      iata_code: null,
      isActive: 1,
    };
  }
  viewchange1(val, air) {
    this.newAirline.id=air.id;
    this.newAirline.name=air.name;
    this.newAirline.alias=air.alias;
    this.newAirline.iata_code=air.iata_code;
    this.newAirline.iata_code_mawb=air.iata_code_mawb;
    this.pageview = val;
  }
  userDetails;
  displaytoAccountant=false;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name == 'Accountant') {
      this.displaytoAccountant=true;
    }
    this.getAirlinePage();
  }


  async createAirline() {
    if (this.newAirline.name == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter the Airline name',
        showConfirmButton: false,
        timer: 5000,
      });
    } else if (this.newAirline.iata_code == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter the IATA Code',
        showConfirmButton: false,
        timer: 5000,
      });
    } else
    if (this.newAirline.iata_code_mawb == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter the IATA MAWB Code',
        showConfirmButton: false,
        timer: 5000,
      });
    } else {
      this.httpService.post(API_URL + '/airline', this.newAirline, null, null)
          .subscribe((result) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Airline added successfully',
              showConfirmButton: false,
              timer: 1500,
            });
            this.newAirline = {
              id: null,
              name: null,
              alias: null,
              iata_code_mawb: null,
              iata_code: null,
              isActive: 1,
            };
            this.airlines = getAirlines();
            this.getAirlinePage();
            this.viewchange('table');
          }, (error) => {
            console.error('Error', error);
            alert(error);
          });
    }
  }


  async editAirline() {
    const airline=
    {
      'name': this.newAirline.name,
      'iata_code_mawb': this.newAirline.iata_code_mawb,
      'iata_code': this.newAirline.iata_code,
    };

    this.httpService.patch(API_URL + '/updateAirline/'+this.newAirline.id, airline, null, null)
        .subscribe((result) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Airline details changed successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.newAirline = {
            id: null,
            name: null,
            alias: null,
            iata_code_mawb: null,
            iata_code: null,
            isActive: 1,
          };
          this.airlines = getAirlines();
          this.getAirlinePage();
          this.viewchange('table');
        }, (error) => {
          console.error('Error', error);
          alert(error);
        });
  }


  async activeAirline(id, status) {
    const activeparam ={
      'isActive': status,
    };

    this.httpService.patch(API_URL + '/updateAirline/isActive/'+id, activeparam, null, null)
        .subscribe((result) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Airline status changed successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.airlines = getAirlines();
          this.getAirlinePage();
          this.viewchange('table');
        }, (error) => {
          console.error('Error', error);
          alert(error);
        });
  }

  viewairline={
    name: null,
    iata: null,
    mawb: null,
  }
  viewsection(val, air) {
    this.viewairline.name=air.name;
    this.viewairline.iata=air.iata_code;
    this.viewairline.mawb=air.iata_code_mawb;
    this.pageview = val;
  }

  DeActivateAirline(airline, status) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to deactivate ' + airline.name + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.loading = true;
        this.activeAirline(airline.id, status);
      }
    });
  }

  ActivateAirline(airline, status) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to activate ' + airline.name + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.loading = true;
        this.activeAirline(airline.id, status);
      }
    });
  }


  updateRecommended(id, event: MatSlideToggleChange) {
    console.log('hello');
    this.httpService.patch(API_URL + '/updateAirline/'+id, {'recommended': event.checked}, null, null).toPromise();
  }

  public Loading = false;
  airlineList = null;
  filterParams: string = '';
  pagination: any = {
    pageNo: 0,
    pageSize: 10,
  };
  filter() {
    this.filterParams = '?';

    this.filterParams = this.filterParams + 'pageNo=' + this.pagination.pageNo + '&pageSize=' + this.pagination.pageSize;
    return this.filterParams;
  }
  async getAirlinePage() {
    // console.log('Fetching air pricing list...');
    this.Loading = true;
    const TOKEN = this.authService.getAuthToken();
    const api = '/airlinePage';
    const params = this.filter(); // Get filter parameters
    try {
      const response = await fetch(API_URL + api + params, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      });
      if (response.ok) {
        const json = await response.json();
        this.airlineList = json;
      } else {
        console.error('Failed to fetch data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      this.Loading = false;
    }
  }
}

