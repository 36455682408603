<div class="h-100">
  <div class="row h-100 g-0">
    <div class="d-none d-lg-block col-lg-4">
      <div class="slider-light">
        <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig2">
          <div ngxSlickItem>

            <div class="position-relative h-100  justify-content-center align-items-center bg-plum-plate">
              <div class="heading">
                <p>ICL AIR CARGO</p>
              </div>
              <div class="icons ">
                <img src="./assets/images/Cube.png" style="width: 90px;"/>
              </div>
              <div class="icons1 ">
                <img src="./assets/images/Flight.png" style="width: 90px;"/>
              </div>
              <div class="icons2 ">
                <img src="./assets/images/Time.png" style="width: 90px;"/>
              </div>
              <div class="icons3 ">
                <img src="./assets/images/Flight1.png" style="width: 90px;"/>
              </div>
              <div class="text">
                <p>ICL-QUICK</p>
              </div>
              <div class="text1">
                <p>ICL-ACE</p>
              </div>
              <div class="text2">
                <p>ICL-PRUDENT</p>
              </div>
              <div class="text3">
                <p>ICL-GEM</p>
              </div>
              <div class="slide-img-bg"></div>
              <div class="slide-img-bgs"></div>
              <div class="slide-img-bgss"></div>
              <div class="slide-img-bgsss"></div>
              <!-- <div class="slide-img-bgssss"></div> -->
              <!-- <img src="assets/images/Time.png" width="100" height="100"> -->

              <div class="slider-content text-bold" style="color: black;">
                <p>
                  A NFO product offering flavors of Door
                  pickup and delivery services.
                </p>
              </div>
              <div class="slider-contents text-bold" style="color: black;">
                <p>
                  A 24-to-48-hour air express service
                  offering reliable transportation of
                  shipments by commercial airlines.
                </p>
              </div>
              <div class="slider-contentss text-center" style="color: black;">
                <p>
                  A multimodal service offering superior
                  transit times at economical prices using
                  a combination of Ground and Air Networks.
                </p>
              </div>
              <div class="slider-contentsss text-center" style="color: black;">
                <p>
                  An air express offering for high value
                  shipments such as gem & jewelry with
                  real time tracking and tech-secured
                  delivery.
                </p>
              </div>
            </div>
          </div>
          <div ngxSlickItem>
            <div class="position-relative h-100  justify-content-center align-items-center bg-premium-dark">
              <div class="heading1">
                <p>ICL GROUND</p>
              </div>
              <div class="slide-img-bg-2"></div>
              <div class="slide-img-bg-3"></div>
              <div class="icons4 ">
                <img src="./assets/images/FTL.png" style="width: 100px;"/>
              </div>
              <div class="text4">
                <p>FTL</p>
              </div>
              <div class="icons5 ">
                <img src="./assets/images/PTL.png" style="width: 100px;"/>
              </div>
              <div class="text5">
                <p>PTL</p>
              </div>
              <div class="slider-content-2 text-center" style="color: black;">
                <p>
                  Dedicated transportation faster than PTL
                  coupled with an assured transit time.
                  Serving offering also has a choice of highly secured operation at a premium.
                </p>
              </div>
              <div class="slider-content-3 text-center" style="color: black;">
                <p>
                  A service offering of reliable transportation for your small parcels by ground.
                  The service includes a door pickup and door delivery.
                </p>
              </div>
            </div>
          </div>
          <div ngxSlickItem>
            <div class="position-relative h-100  justify-content-center align-items-center bg-sunny-morning">
              <div class="heading2">
                <p>ICL KELVIN</p>
              </div>
              <div class="icons6 ">
                <img src="./assets/images/CCP.png" style="width: 100px;"/>
              </div>
              <div class="text6">
                <p>CCP</p>
              </div>
              <div class="icons7 ">
                <img src="./assets/images/Reefe.png" style="width: 100px;"/>
              </div>
              <div class="text7">
                <p>REEFER</p>
              </div>
              <div class="slide-img-bg-4"></div>
              <div class="slide-img-bg-5"></div>
              <div class="slider-content-4 text-center" style="color: black;">
                <p>
                  All our packing solutions are validated and ensure the cold chain integrity is maintained without any
                  compromise.
                  These packing solutions are backed with air transportation to ensure your
                  cold chain cargo reaches the customers in the shortest possible time.
                </p>
              </div>
              <div class="slider-content-5 text-center" style="color: black;">
                <p>
                  Our reefer vehicle operations are designed to meet the pharmaceutical standards. Data loggers used are
                  backed by calibration certificate and most importantly shipment is tracked with images at every point
                  of human interface to ensure highly reliable and transparent operation.

                </p>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
    <div class="h-50 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
      <div class="mx-auto app-login-box col-sm-12 col-md-8 col-lg-6 mt-auto">
        <div class="app-logo9">
          <img src="./assets/images/icl-logo.png" style="width: 200px;"/>
        </div>
        <h4>
          <div>Reset your Password?</div>
          <span>Use the form below to reset it.</span>
        </h4>
        <div>
          <form>
            <div class="row">
              <div class="col-md-8">
                <label class="form-label"><b>New Password</b></label><br>
                <p-password name="newPassword" placeholder="New Password" [(ngModel)]="password"
                            [feedback]="false" [toggleMask]="true" (keyup)="passLengthCheck()"
                            [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"></p-password>
                <mat-hint class="text-danger" *ngIf="passLenCheck">Password should have minimum of 6 characters
                </mat-hint>
              </div>
              <div class="col-md-8"><br>
                <label class="form-label"><b>Confirm Password</b></label>
                <p-password name="confirmPassword" placeholder="Confirm Password"
                            [(ngModel)]="passwordAgain" [feedback]="false" [toggleMask]="true"
                            [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"></p-password>
              </div>
            </div>
            <div class="mt-4 d-flex align-items-center">
              <div class="ms-auto">
                <button type="button" class="btn btn-primary btn-lg" (click)="resetPassword()">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
