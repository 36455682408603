import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ViewConsignmentService {
  private previousPage;

  constructor(private location: Location, private router: Router) { }

  public navigateToConsignment(id, previousPage) {
    this.previousPage = previousPage;
    this.router.navigate(['/viewconsignment', id]);
  }

  public back(): void {
    if (this.previousPage != null) {
      this.router.navigate([this.previousPage]);
      this.previousPage = null;
    } else this.location.back();
  }
}
