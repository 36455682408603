import {Component, OnInit} from '@angular/core';
import {ProductTypeModel} from '../../modules/productTypes/models/product-type-model';
import {ConsignmentTypesModel} from '../../modules/consignmentTypes/models/consignment-types-model';
import {ConsignmentSubTypesModel} from '../../modules/consignmentSubtypes/models/consignment-sub-types-model';
import {ProductTypeService} from '../../modules/productTypes/services/product-type.service';
import {MatDialog} from '@angular/material/dialog';
import {CreateConsignmentTypeDialog} from '../../modules/consignmentTypes/views/createConsignmentType';
import {ConsignmentTypeService} from '../../modules/consignmentTypes/services/consignment-type.service';
import {ngxLoadingAnimationTypes} from 'ngx-loading';
import {MatTableDataSource} from '@angular/material/table';
import {CreateConsignmentSubtypeDialog} from '../../modules/consignmentSubtypes/views/createConsignmentSubtype';
import {ConsignmentSubtypeService} from '../../modules/consignmentSubtypes/services/consignment-subtype.service';
import {ConfirmDialog} from '../Components/confirmDialog/confirmDialog.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'productTypes',
  templateUrl: './productTypes.html',
  styleUrls: ['./productTypes.component.sass'],
})
export class ProductTypesComponent implements OnInit {
  constructor(private productTypeService: ProductTypeService) {
  }

  productTypeList: ProductTypeModel[];
  productTypeTableColumns = ['Name', 'Created', 'Updated'];
  ngOnInit(): void {
    this.productTypeService.getProductTypes().subscribe((result) => {
      this.productTypeList = result.data;
    });
  }
}


@Component({
  selector: 'consignment-types',
  templateUrl: './consignmentTypes.html',
  styleUrls: ['./consignment-types.component.sass'],
})
export class ConsignmentTypesComponent implements OnInit {
  constructor(private productTypeService: ProductTypeService, private consignmentTypeService: ConsignmentTypeService, private matDialog: MatDialog) {
  }

  consignmentTypes: MatTableDataSource<ConsignmentTypesModel>;
  productTypeTableColumns = ['Name', 'Description', 'Created', 'Updated', 'Actions'];
  public loading = false;

  createNewConsignmentType() {
    const dialogRef = this.matDialog.open(CreateConsignmentTypeDialog, {width: '30rem', data: {consignmentType: new ConsignmentTypesModel(null, '', '', null), action: 'create'}});

    dialogRef.afterClosed().subscribe((result) => {
      this.loading = true;
      if (result!=null) {
        this.consignmentTypeService.postConsignmentType(new ConsignmentTypesModel(null, result.name, result.description, true)).subscribe((result) => {
          this.consignmentTypes.data.push(result.data[0]);
          this.consignmentTypes._updateChangeSubscription();
          this.loading = false;
          Swal.fire({
            title: 'Created',
            text: result.data[0].name+' is successfully created.',
            icon: 'success',
            timer: 4000,
          });
        });
      } else this.loading = false;
    });
  }

  patchConsignmentType(consignmentType: ConsignmentTypesModel) {
    const dialogRef = this.matDialog.open(CreateConsignmentTypeDialog, {width: '30rem', data: {consignmentType: consignmentType, action: 'patch'}});

    dialogRef.afterClosed().subscribe((result) => {
      this.loading = true;
      if (result!=null) {
        this.consignmentTypeService.patchConsignmentType(result).subscribe((result) => {
          consignmentType.description = result.data[0].description;
          consignmentType.name = result.data[0].name;
          consignmentType.is_active = result.data[0].is_active;
          consignmentType.updated_at = result.data[0].updated_at;
          this.loading = false;
          Swal.fire({
            title: 'Updated',
            text: consignmentType.name+' is successfully updated.',
            icon: 'success',
            timer: 4000,
          });
        }, (error) => {
          Swal.fire({
            title: 'Failed',
            text: 'Failed to Create',
            icon: 'error',
            timer: 4000,
          });
          this.loading = false;
        });
      } else this.loading = false;
    });
  }

  deactivateConsignmentType(consignmentType: ConsignmentTypesModel) {
    this.loading = true;

    consignmentType.is_active? consignmentType.is_active = false : consignmentType.is_active = true;

    this.consignmentTypeService.patchConsignmentType(consignmentType).subscribe((result) => {
      consignmentType.description = result.data[0].description;
      consignmentType.name = result.data[0].name;
      consignmentType.is_active = result.data[0].is_active;
      consignmentType.updated_at = result.data[0].updated_at;
      this.loading = false;
      if (result.data[0].is_active===true) {
        Swal.fire({
          title: 'Activated',
          text: consignmentType.name+' is successfully activated.',
          icon: 'success',
          timer: 4000,
        });
      }
      if (result.data[0].is_active===false) {
        Swal.fire({
          title: 'Deactivated',
          text: consignmentType.name+' is successfully deactivated.',
          icon: 'success',
          timer: 4000,
        });
      }
    }, (error) => {
      Swal.fire({
        title: 'Failed',
        text: 'Failed to Create.',
        icon: 'error',
        timer: 4000,
      });
      this.loading = false;
    });
  }

  userDetails;
  displaytoAccountant=false;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name=='Accountant') {
      this.displaytoAccountant=true;
    }
    this.productTypeService.getConsignmentTypes().subscribe((result) => {
      this.consignmentTypes = new MatTableDataSource<ConsignmentTypesModel>(result.data);
    });
  }

  readonly ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
}


@Component({
  selector: 'consignment-subtypes',
  templateUrl: './consignmentSubtypes.html',
  styleUrls: ['./consignment-subtypes.component.sass'],
})
export class ConsignmentSubtypesComponent implements OnInit {
  constructor(private productTypeService: ProductTypeService, private matDialog: MatDialog, private consignmentSubtypeService: ConsignmentSubtypeService) {
  }

  consignmentSubtypes: MatTableDataSource<ConsignmentSubTypesModel>;
  ConsignmentSubtypeTableColumns = ['Name', 'Description', 'Created', 'Updated', 'Actions'];
  public loading = false;
  userDetails;
  displaytoAccountant=false;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name=='Accountant') {
      this.displaytoAccountant=true;
    }
    this.productTypeService.getConsignmentSubTypes().subscribe((result) => {
      this.consignmentSubtypes = new MatTableDataSource<ConsignmentSubTypesModel>(result.data);
    });
  }

  readonly ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  createNewConsignmentSubType() {
    const dialogRef = this.matDialog.open(CreateConsignmentSubtypeDialog, {width: '30rem', data: {consignmentSubtype: new ConsignmentSubTypesModel('', '', '', null, null), action: 'create'}});

    dialogRef.afterClosed().subscribe((result) => {
      this.loading = true;
      if (result!=null) {
        this.consignmentSubtypeService.postConsignmentType(new ConsignmentSubTypesModel(null, result.name, result.description, result.consignmentType, true)).subscribe((result) => {
          this.consignmentSubtypes.data.push(result.data[0]);
          this.consignmentSubtypes._updateChangeSubscription();
          this.loading = false;
          Swal.fire({
            title: 'Created',
            text: result.data[0].name+' is successfully created.',
            icon: 'success',
            timer: 4000,
          });
        }, (error) => {
          Swal.fire({
            title: 'Failed',
            text: 'Failed to Create',
            icon: 'error',
            timer: 4000,
          });
          this.loading = false;
        });
      } else this.loading = false;
    });
  }

  patchConsignmentSubType(consignmentSubTypes: ConsignmentSubTypesModel) {
    const dialogRef = this.matDialog.open(CreateConsignmentSubtypeDialog, {width: '30rem', data: {consignmentSubtype: consignmentSubTypes, action: 'patch'}});

    dialogRef.afterClosed().subscribe((result) => {
      this.loading = true;
      if (result!=null) {
        this.consignmentSubtypeService.patchConsignmentType(new ConsignmentSubTypesModel(result.id, result.name, result.description, result.consignmentType, result.isActive)).subscribe((result) => {
          consignmentSubTypes.name = result.data[0].name;
          consignmentSubTypes.consignmentType = result.data[0].consignmentType;
          consignmentSubTypes.description = result.data[0].description;
          consignmentSubTypes.isActive = result.data[0].isActive;
          consignmentSubTypes.updatedAt = result.data[0].updatedAt;
          this.consignmentSubtypes._updateChangeSubscription();
          this.loading = false;
          Swal.fire({
            title: 'Updated',
            text: result.data[0].name+' is successfully updated.',
            icon: 'success',
            timer: 4000,
          });
        }, (error) => {
          Swal.fire({
            title: 'Failed',
            text: 'Failed to Create',
            icon: 'error',
            timer: 4000,
          });
          this.loading = false;
        });
      } else this.loading = false;
    });
  }

  deactivateConsignmentSubType(consignmentSubType: ConsignmentSubTypesModel) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: this.activateDeactivateMessage(consignmentSubType.name, consignmentSubType.isActive),
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loading = true;
        this.consignmentSubtypeService.patchConsignmentType(new ConsignmentSubTypesModel(consignmentSubType.id, null, null, null, !consignmentSubType.isActive)).subscribe((result) => {
          consignmentSubType.isActive = result.data[0].isActive;
          consignmentSubType.updatedAt = result.data[0].updatedAt;
          this.consignmentSubtypes._updateChangeSubscription();
          this.loading = false;
          if (result.data[0].isActive===true) {
            Swal.fire({
              title: 'Activated',
              text: consignmentSubType.name+' is successfully activated.',
              icon: 'success',
              timer: 4000,
            });
          }
          if (result.data[0].isActive===false) {
            Swal.fire({
              title: 'Deactivated',
              text: consignmentSubType.name+' is successfully deactivated.',
              icon: 'success',
              timer: 4000,
            });
          }
        }, (error) => {
          Swal.fire({
            title: 'Failed',
            text: 'Failed to Create',
            icon: 'error',
            timer: 4000,
          });
          this.loading = false;
        });
      }
    });
  }

  activateDeactivateMessage(name: string, isActive: boolean): string {
    if (isActive) return 'Please confirm, Deactivate '+name+'?';
    else return 'Please confirm, Activate '+name+'?';
  }
}
