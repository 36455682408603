import {Component, OnInit} from '@angular/core';
import {UserRoles} from './Layout/store.js';
import {Title} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
export let Roles;
export let Profile;
export let UserName;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {
  title = 'ICLerate';

  public isLoggedIn = false;
  // public userProfile: KeycloakProfile | null = null;


  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title) {
  }

  async ngOnInit() {
    this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
    ).subscribe((data) => {
      if (!data.title) {
        this.titleService.setTitle('ICLTSCM');
      }
    });
    // console.log("in app component")
    // console.log(this.userProfile)
    // Profile = await  this.keycloakService.loadUserProfile()
    // UserName = await this.keycloakService.getUsername();
    // Roles = await this.keycloakService.getUserRoles();
  }
}
function typeOf(profile: any): any {
  throw new Error('Function not implemented.');
}

