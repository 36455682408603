import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-icl-products',
  templateUrl: './icl-products.component.html',
  styleUrls: ['./icl-products.component.sass'],
})
export class IclProductsComponent implements OnInit {
  PRODUCT_TYPE;

  constructor(private router: Router, private activatedRoute : ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      // console.log(params);
      this.PRODUCT_TYPE = params.type;
      // console.log("this is type"+ this.MODE);
    },
    );
  }

  goto(filter: string) {
    this.router.navigate(['/consignment/addconsignment'],
        {
          queryParams: {
            type: this.PRODUCT_TYPE,
            mode: filter,
          },
        },
    );
  }

  gotoATD(filter: string) {
    this.router.navigate(['/ATDbooking'],
        {
          queryParams: {
            type: this.PRODUCT_TYPE,
            mode: filter,
          },
        },
    );
  }

  gotoDTA(filter: string) {
    this.router.navigate(['/DTAbooking'],
        {
          queryParams: {
            type: this.PRODUCT_TYPE,
            mode: filter,
          },
        },
    );
  }

  gotoDTD(filter: string) {
    this.router.navigate(['/DTDbooking'],
        {
          queryParams: {
            type: this.PRODUCT_TYPE,
            mode: filter,
          },
        },
    );
  }
}
