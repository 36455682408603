import {Component, OnInit} from '@angular/core';
import {header} from '../../../../../Global_API_URL';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  constructor() { }
  server = header;
  ngOnInit() {
  }
}
