<div *ngIf="loading" class="loader-container">
  <ngx-loading [show]="loading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>

<mat-card style="padding: 20px;">
  <div class="card-header-title font-size-lg text-capitalize fw-Bold">
    <a (click)="goBackToRetailCustomerTable()"><i class="fa fa-arrow-left"
                                                  style="cursor: pointer"></i></a>&nbsp;<span
    style="color: blue; font-weight: bold; font-size: larger">EDIT RETAIL CUSTOMER</span>
  </div>

  <mat-card-content>
    <div style="margin-left: 35px; margin-top: 30px">
      <mat-form-field>
        <mat-label>Select Verification Type</mat-label>
        <mat-select [(value)]="selected"
                    [disabled]="customerVerificationService.progressBar || customerVerificationService.CustomerVerified">
          <mat-option value="gst">GST</mat-option>
          <mat-option value="pan">PAN</mat-option>
          <mat-option value="aadhaar">Aadhaar</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div style="margin-left: 35px" *ngIf="selected != ''">
      <div style="display: flex">

        <div *ngIf="selected == 'gst'">
          <mat-form-field appearance="standard">
            <mat-label>
              GST No.<span class="text-danger">*</span>
            </mat-label>
            <input [disabled]="customerVerificationService.progressBar || customerVerificationService.GSTVerified" type="text"
                   placeholder="GST" aria-label="Number"
                   (input)="customerVerificationService.transformGSTToUpperCaseForCreate()"
                   matInput #gst maxlength="15"
                   [(ngModel)]="customerVerificationService.gstin"
                   (keypress)="customerVerificationService.validateInput($event)">
            <mat-hint class="text-danger fw-normal" *ngIf="customerVerificationService.gstFormControl.hasError('pattern')">
              Invalid GST No.
            </mat-hint>
            <mat-hint align="end">{{ gst.value.length }}/15</mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="selected == 'pan'">
          <mat-form-field appearance="standard">
            <mat-label>
              PAN No.<span class="text-danger">*</span>
            </mat-label>
            <input [disabled]="customerVerificationService.progressBar || customerVerificationService.CustomerVerified" type="text"
                   placeholder="PAN" aria-label="Number"
                   (input)="customerVerificationService.transformPANToUpperCaseForCreate()"
                   matInput #pan maxlength="10"
                   [(ngModel)]="customerVerificationService.panReqBody.number"
                   (keypress)="customerVerificationService.validateInput($event)">
            <mat-hint class="text-danger fw-normal" *ngIf="customerVerificationService.panFormControl.hasError('pattern')">
              Invalid PAN No.
            </mat-hint>
            <mat-hint align="end">{{ pan.value.length }}/10</mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="selected == 'aadhaar'">
          <mat-form-field appearance="standard">
            <mat-label>
              Aadhaar No.<span class="text-danger">*</span>
            </mat-label>
            <input [disabled]="customerVerificationService.progressBar || customerVerificationService.AADHAARVerified" type="text"
                   placeholder="Aadhaar No." aria-label="Number"
                   matInput #aadhaar maxlength="12"
                   [(ngModel)]="customerVerificationService.aadhaarGenerateOtpReqBody.aadhaarNumber"
                   (keypress)="customerVerificationService.validateInputInt($event)">
            <mat-hint class="text-danger fw-normal" *ngIf="customerVerificationService.gstFormControl.hasError('pattern')">
              Invalid Aadhaar No.
            </mat-hint>
            <mat-hint align="end">{{ aadhaar.value.length }}/12</mat-hint>
          </mat-form-field>
        </div>
        <div class="m-3" *ngIf="selected == 'gst' || selected == 'pan'">
          <button class="btn btn-success" style="margin-left: 10px" (click)="retailCustomerVerification()"
                  [disabled]="customerVerificationService.progressBar || customerVerificationService.GSTVerified">Verify
          </button>
        </div>
        <div class="m-3" *ngIf="selected == 'aadhaar' && !customerVerificationService.isOTPResend">
          <button class="btn btn-success" style="margin-left: 10px" (click)="customerVerificationService.sendOTP()"
                  [disabled]="customerVerificationService.progressBar || customerVerificationService.AADHAARVerified">Send otp
          </button>
        </div>

        <div class="m-3" *ngIf="selected == 'aadhaar' && customerVerificationService.isOTPResend"
             (click)="customerVerificationService.sendOTP()">
          <button class="btn btn-success" style="margin-left: 10px"
                  [disabled]="customerVerificationService.progressBar || customerVerificationService.AADHAARVerified">Resend otp
          </button>
        </div>
        <div style="margin-top: 22px; width: 50px">
          <div
            *ngIf="customerVerificationService.isOTPSent && customerVerificationService.otpSentSuccessfully && !customerVerificationService.AADHAARVerified">
            <cd-timer #timer
                      [startTime]="60"
                      [endTime]="0"
                      [countdown]="true"
                      [autoStart]="true"
                      [format]="'ms'"
                      (onComplete)="customerVerificationService.onTimerComplete()"
                      (onTick)="customerVerificationService.onTimerTick($event)">
            </cd-timer>
          </div>
        </div>

        <div *ngIf="selected == 'aadhaar' && customerVerificationService.otpSentSuccessfully"
             style="margin-left: 20px; margin-top: 14px">
          <ng-otp-input (keypress)="customerVerificationService.validateInputInt($event)" (onInputChange)="customerVerificationService.onOtpChange($event)"
                        [(ngModel)]="customerVerificationService.aadhaarVerifyOtpReqBody.otp"
                        [config]="{length:6, inputStyles: {'width': '37px', 'height': '37px', 'font-size': '20px'}}"></ng-otp-input>
        </div>

        <div class="m-3" *ngIf="selected == 'aadhaar' && customerVerificationService.otpSentSuccessfully">
          <button class="btn btn-success" style="margin-left: 10px" (click)="retailCustomerVerification()"
                  [disabled]="customerVerificationService.progressBar || customerVerificationService.AADHAARVerified">Verify otp
          </button>
        </div>

      </div>
      <mat-progress-bar *ngIf="customerVerificationService.progressBar" mode="indeterminate"
                        style="width: 100%"></mat-progress-bar>

      <div *ngIf="selected == 'pan'">
        <section>
          <mat-checkbox [(ngModel)]="customerVerificationService.panReqBody.returnIndividualTaxComplianceInfo"
                        [disabled]="customerVerificationService.PANVerified">Return Individual Tax Compliance Info
          </mat-checkbox>
        </section>
      </div>

    </div>

    <br><br>

    <container-element [ngSwitch]="selected">
      <some-element *ngSwitchCase="'gst'">
        <div *ngIf="customerVerificationService.GSTVerified" style="margin-left: 35px">
          <h5><b style="color: blue;">Master Details : </b></h5>

          <table class="masterTable">
            <tr>
              <td class="td1">GST No. <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.gstData?.data?.gstin }}</td>
              <td class="td1">Street <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.gstData?.data?.pradr?.addr?.st }}</td>
            </tr>

            <tr>
              <td class="td1">Legal Name <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.gstData?.data?.lgnm }}</td>
              <td class="td1">Location <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.gstData?.data?.pradr?.addr?.loc }}</td>
            </tr>

            <tr>
              <td class="td1">Trade Name <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.gstData?.data?.tradeNam }}</td>
              <td class="td1">Landmark <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.gstData?.data?.pradr?.addr?.landmark }}</td>
            </tr>

            <tr>
              <td class="td1">Status <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.gstData?.data?.sts }}</td>
              <td class="td1">Building Name <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.gstData?.data?.pradr?.addr?.bnm }}</td>
            </tr>

            <tr>
              <td class="td1">Taxpayer Type <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.gstData?.data?.dty }}</td>
              <td class="td1">State <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.gstData?.data?.pradr?.addr?.stcd }}</td>
            </tr>

            <tr>
              <td class="td1">Constitute of business <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.gstData?.data?.ctb }}</td>
              <td class="td1">District <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.gstData?.data?.pradr?.addr?.dst }}</td>
            </tr>

            <tr>
              <td class="td1" style="padding: 20px 0 20px 20px">Registered Date <span style="float: right">:</span></td>
              <td class="td2" style="padding: 20px 0 20px 20px">{{ customerVerificationService.gstData?.data?.rgdt }}</td>
              <td class="td1" style="padding: 20px 0 20px 20px"> Pincode <span style="float: right">:</span></td>
              <td class="td2"
                  style="padding: 20px 0 20px 20px">{{ customerVerificationService.gstData?.data?.pradr?.addr?.pncd }}
              </td>
            </tr>
          </table>
        </div>

        <br><br>

      </some-element>

      <some-element *ngSwitchCase="'pan'">
        <div *ngIf="customerVerificationService.PANVerified" style="margin-left: 35px">
          <h5><b style="color: blue;">Master Details : </b></h5>

          <table class="masterTable">
            <tr>
              <td class="td1">PAN No. <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.panData?.number }}</td>
            </tr>

            <tr>
              <td class="td1"> Name <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.panData?.name }}</td>
            </tr>

            <tr>
              <td class="td1"> Is Individual <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.panData?.isIndividual }}</td>
            </tr>

            <tr>
              <td class="td1" style="padding: 20px 0 20px 20px"> Individual Tax Compliance Status <span
                style="float: right">:</span></td>
              <td class="td2"
                  style="padding: 20px 0 20px 20px">{{ customerVerificationService.panData?.individualTaxComplianceStatus }}
              </td>
            </tr>
          </table>
        </div>

        <br><br>

      </some-element>

      <some-element *ngSwitchCase="'aadhaar'">
        <div *ngIf="customerVerificationService.AADHAARVerified" style="margin-left: 35px">
          <h5><b style="color: blue;">Master Details : </b></h5>

          <table class="masterTable">
            <tr>
              <td class="td1">AADHAAR No. <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.aadhaarData?.aadhaar_number }}</td>
              <td class="td1">Image <span style="float: right">:</span></td>
              <td class="td1" rowspan="5" colspan="5"><img [src]="sanitizeImage(customerVerificationService.customerImage)"
                                                           alt="customer image"/></td>
            </tr>

            <tr>
              <td class="td1"> Name <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.aadhaarData?.full_name }}</td>
            </tr>

            <tr>
              <td class="td1">Date Of Birth <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.aadhaarData?.dob }}</td>
            </tr>

            <tr>
              <td class="td1">Gender <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.aadhaarData?.gender }}</td>
            </tr>

            <tr>
              <td class="td1"> Care/of <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.aadhaarData?.care_of }}</td>
            </tr>

            <tr>
              <td class="td1"> Post office name <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.aadhaarData?.address?.po }}</td>
              <td class="td1"> Pincode <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.aadhaarData?.zip }}</td>
            </tr>

            <tr>
              <td class="td1"> House <span style="float: right">:</span></td>
              <td class="td2"> {{ customerVerificationService.aadhaarData?.address?.house }}</td>
              <td class="td1"> City <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.aadhaarData?.address?.vtc }}</td>
            </tr>

            <tr>
              <td class="td1"> Street <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.aadhaarData?.address?.street }}</td>
              <td class="td1"> District <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.aadhaarData?.address?.dist }}</td>
            </tr>

            <tr>
              <td class="td1"> Landmark <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.aadhaarData?.address?.landmark }}</td>
              <td class="td1"> State <span style="float: right">:</span></td>
              <td class="td2">{{ customerVerificationService.aadhaarData?.address?.state }}</td>
            </tr>

            <tr>
              <td class="td1" style="padding: 20px 0 20px 20px"> Locality <span style="float: right">:</span></td>
              <td class="td2" style="padding: 20px 0 20px 20px">{{ customerVerificationService.aadhaarData?.address?.loc }}
              </td>
              <td class="td1" style="padding: 20px 0 20px 20px"> Country <span style="float: right">:</span></td>
              <td class="td2"
                  style="padding: 20px 0 20px 20px">{{ customerVerificationService.aadhaarData?.address?.country }}
              </td>
            </tr>
          </table>
        </div>

        <br><br>

      </some-element>

      <div style="margin-left: 35px">
        <h5><b style="color: blue;">User Details : </b></h5>

        <table class="compTable">
          <tr>
            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  First Name<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="First Name" aria-label="Number" matInput
                       [(ngModel)]="customer_shipper.firstName">
              </mat-form-field>
            </td>

            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  Middle Name
                </mat-label>
                <input type="text" placeholder="Middle Name" aria-label="Number" matInput
                       [(ngModel)]="customer_shipper.middleName">
              </mat-form-field>
            </td>

            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  Last Name<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Last Name" aria-label="Number" matInput  [(ngModel)]="customer_shipper.lastName">
              </mat-form-field>
            </td>

          </tr>

          <tr>
            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  Email<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Email" aria-label="Number" matInput disabled
                       [(ngModel)]="customer_shipper.email" (input)="emailExistAppuser()"
                       [formControl]="customerVerificationService.emailFormControl">
                <mat-hint class="text-danger fw-normal"
                          *ngIf="customerVerificationService.emailFormControl.hasError('pattern')">
                  Invalid
                  email address
                </mat-hint>
                <mat-hint class="text-danger fw-normal" *ngIf="customerVerificationService.emailExist">
                  Email Already Exists
                </mat-hint>
              </mat-form-field>
            </td>

            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  Phone No.<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Phone No." #phone1
                       onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="Number"
                       matInput
                       maxlength="10" [(ngModel)]="customer_shipper.phone">
                <mat-hint align="end">{{ phone1.value.length }}/10
                </mat-hint>
              </mat-form-field>
            </td>

            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  Whatsapp No.
                </mat-label>
                <input type="text" placeholder="Whatsapp No." #whatsapp
                       onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="Number"
                       matInput
                       maxlength="10" [(ngModel)]="customer_shipper.whatsapp">
                <mat-hint align="end">{{ whatsapp.value.length }}/10
                </mat-hint>
              </mat-form-field>
            </td>
          </tr>

          <tr>
            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  Address<span class="text-danger">*</span>
                </mat-label>
                <textarea placeholder="Address" matInput style="height: 15px"
                          [(ngModel)]="customer_shipper.billingAddress.address"></textarea>
              </mat-form-field>
            </td>

            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  Country<span class="text-danger">*</span>
                </mat-label>
                <mat-select matSelect matNativeControl [(ngModel)]="customerVerificationService.user_sel_country">
                  <mat-option *ngFor="let con of customerVerificationService.countries |async" [value]="con.id"
                              (click)="customerVerificationService.getIndividaulStates(customer_shipper, 'retail')">
                    {{ con.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>

            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  State<span class="text-danger">*</span>
                </mat-label>
                <mat-select matNativeControl [(ngModel)]="customerVerificationService.user_sel_state">
                  <mat-option *ngFor="let sta of customerVerificationService.user_states |async" [value]="sta.id"
                              (click)="customerVerificationService.getIndividaulCities(customer_shipper, 'retail')">
                    {{ sta.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>

          <tr>
            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  City<span class="text-danger">*</span>
                </mat-label>
                <mat-select matNativeControl [(ngModel)]="customerVerificationService.user_sel_city">
                  <mat-option *ngFor="let cit of customerVerificationService.user_cities |async" [value]="cit.id"
                              (click)="customerVerificationService.getIndividaulPincodes(customer_shipper, 'retail')">
                    {{ cit.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>

            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  Zip Code<span class="text-danger">*</span>
                </mat-label>
                <mat-select matNativeControl [(ngModel)]="customer_shipper.billingAddress.pincode.pin_number">
                  <mat-option *ngFor="let cit of customerVerificationService.user_pincodes |async" [value]="cit.pinNumber">
                    {{ cit.pinNumber }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>

            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  Aadhaar No.
                </mat-label>
                <input type="text" placeholder="Aadhaar No." #aadhaar aria-label="Number" matInput
                       maxlength="12" [(ngModel)]="customer_shipper.aadhaarNumber" [disabled]="selected == 'aadhaar'">
                <mat-hint align="end">{{ aadhaar.value.length }}/12
                </mat-hint>
              </mat-form-field>
            </td>
          </tr>

          <tr>
            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  Driving License
                </mat-label>
                <input type="text" placeholder="Driving License" #license aria-label="Number" matInput
                       maxlength="16" [(ngModel)]="customer_shipper.drivingLicense">
                <mat-hint align="end">{{ license.value.length }}/16
                </mat-hint>
              </mat-form-field>
            </td>

            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  PAN No.
                </mat-label>
                <input type="text" placeholder="PAN No." #pan aria-label="Number" matInput
                       maxlength="10" [(ngModel)]="customer_shipper.panNumber"
                       (input)="transformPANoUpperCaseForCreate()" [disabled]="selected == 'pan'">
                <mat-hint align="end">{{ pan.value.length }}/10
                </mat-hint>
              </mat-form-field>
            </td>

            <td>
              <mat-form-field class="py-0" appearance="standard">
                <mat-label>
                  GST No.
                </mat-label>
                <input type="text" placeholder="GST No." #gst aria-label="Number" matInput
                       maxlength="15" [(ngModel)]="customer_shipper.gstNumber" [disabled]="selected == 'gst'">
                <mat-hint align="end">{{ gst.value.length }}/15
                </mat-hint>
              </mat-form-field>
            </td>
          </tr>

        </table>
      </div>

    </container-element>

  </mat-card-content>

  <mat-card-actions align="end">
    <button mat-button class="btn btn-danger" (click)="goBackToRetailCustomerTable()"
            [disabled]="customerVerificationService.progressBar">Cancel
    </button>
    <button mat-flat-button color="primary"
            [disabled]="customerVerificationService.progressBar || !customerVerificationService.CustomerVerified"
            (click)="editRetailCustomer()">Save
    </button>
  </mat-card-actions>
</mat-card>
