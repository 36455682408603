<div class="dialogBody">
  <div class="modal-header" style="padding-top: 10px">
    <h4 class="modal-title">Create Customer</h4>
    <button aria-label="Close" class="btn-close" type="button" (click)="onNoClick()">
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="standard">
          <mat-label>
            Customer
          </mat-label>
          <mat-select (blur)="change_business()" [(ngModel)]="val" matNativeControl>
            <mat-option value="1">
              Corporate User
            </mat-option>
            <mat-option value="2">
              Retail User
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <button class="btn btn-primary m-3">Select</button>
      </div>
    </div>
    <div *ngIf="indiviudal_user.customer_type == 2; else notbusinessuser">
      <div class="h4">
        Corporate Customer Details
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="standard" class="py-0">
            <mat-label>
              Name<span class="text-danger">*</span>
            </mat-label>
            <input [(ngModel)]="customer_shipper.firstName" aria-label="Number" matInput
                   placeholder="Name" type="text" >
<!--            <mat-hint *ngIf="CustomerExist==true" class="text-danger fw-bold">Name-->
<!--              Exist-->
<!--            </mat-hint>-->
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              E-mail <span class="text-danger">*</span>
            </mat-label>
            <input [(ngModel)]="customer_shipper.email1" [formControl]="emailFormControl1" aria-label="Number" matInput
                   placeholder="Email" type="text">
            <!-- <mat-hint class="text-danger fw-normal" *ngIf="emailFormControl1.hasError('required')">Email is required</mat-hint> -->
            <mat-hint *ngIf="emailFormControl1.hasError('pattern')" class="text-danger fw-normal">Invalid
              email address
            </mat-hint>

          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              Phone No.<span class="text-danger">*</span>
            </mat-label>
            <input #phone1 [(ngModel)]="customer_shipper.phone1"
                   aria-label="Number" matInput
                   maxlength="10" onkeypress="return event.charCode >= 48 && event.charCode <= 57" placeholder="Phone No."
                   type="text">
            <mat-hint align="end">{{ phone1.value.length }}/10
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="standard" class="example-full-width">
            <mat-label>Address<span class="text-danger">*</span></mat-label>
            <textarea [(ngModel)]="customer_shipper.address1" matInput
                      placeholder="Address"></textarea>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>Country<span class="text-danger">*</span></mat-label>
            <mat-select [(ngModel)]="business_sel_country"  matNativeControl>
              <mat-option selected>SELECT</mat-option>
              <mat-option (click)="getBusinessStates()" *ngFor="let con of countries |async"
                          [value]="con.id">
                {{ con.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              State<span class="text-danger">*</span>
            </mat-label>
            <mat-select [(ngModel)]="business_sel_state"
                        matNativeControl>
              <mat-option selected>SELECT</mat-option>
              <mat-option (click)="getBusinessCities(sta.id)"
                          *ngFor="let sta of business_states |async" [value]="sta.id">
                {{ sta.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              City<span class="text-danger">*</span>
            </mat-label>
            <mat-select [(ngModel)]="customer_shipper.city1"
                        matNativeControl>
              <mat-option selected>SELECT</mat-option>
              <mat-option (click)="getBusinessPincodes()"
                          *ngFor="let cit of business_cities |async" [value]="cit.id">
                {{ cit.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              Zip Code<span class="text-danger me-auto">*</span>
            </mat-label>
            <mat-select [(ngModel)]="customer_shipper.zipCode"
                        matNativeControl>
              <mat-option selected>SELECT</mat-option>
              <mat-option *ngFor="let cit of business_pincodes |async"
                          [value]="cit.id">
                {{ cit.pin_number }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              Billing Branch<span class="text-danger">*</span>
            </mat-label>
            <mat-select [(ngModel)]="customer_shipper.office_location"  matNativeControl>
              <mat-option *ngFor="let role of OfficesList" [value]="role.id">
                {{ role.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              GST No.<span class="text-danger">*</span>
            </mat-label>
            <input type="text" placeholder="GST" aria-label="Number" (input)="gstNoExist(); transformGSTToUpperCaseForCorp()" matInput #gst maxlength="15"
                   [(ngModel)]="customer_shipper.gst" [formControl]="gstFormControl" (keypress)="validateInput1($event)">
            <!-- <mat-hint class="text-danger fw-normal" *ngIf="gstFormControl.hasError('required')">GST No. required</mat-hint> -->
            <mat-hint *ngIf="gstFormControl.hasError('pattern')" class="text-danger fw-normal">Invalid GST
              No.
            </mat-hint>
            <mat-hint align="end">{{ gst.value.length }}/15
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              PAN No.<span class="text-danger">*</span>
            </mat-label>
            <input #pan [(ngModel)]="customer_shipper.pan" [formControl]="PanFormControl" (input)="panNoExist(); transformPANToUpperCaseForCorp()" aria-label="Number" matInput maxlength="10"
                   placeholder="PAN" type="text" (keypress)="validateInput1($event)">
            <!-- <mat-hint class="text-danger fw-normal" *ngIf="PanFormControl.hasError('required')">PAN No. required</mat-hint> -->
            <mat-hint *ngIf="PanFormControl.hasError('pattern')" class="text-danger fw-normal">Invalid PAN No.
            </mat-hint>
            <mat-hint align="end">{{ pan.value.length }}/10
            </mat-hint>
          </mat-form-field>
        </div>

        <br>
        <section>
          <mat-checkbox [(ngModel)]="customer_shipper.isSezCustomer" >Is SEZ Customer</mat-checkbox>
        </section>

      </div>
      <br>
      <mat-divider>

      </mat-divider>
      <div class="h4">
        Corporate Representative Details
      </div>

      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="standard" class="py-0">
            <mat-label>
              First Name<span class="text-danger">*</span>
            </mat-label>
            <input [(ngModel)]="indiviudal_user.first_name" aria-label="Number" matInput placeholder="First Name"
                   type="text">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard" class="py-0">
            <mat-label>
              Middle Name
            </mat-label>
            <input [(ngModel)]="indiviudal_user.middle_name" aria-label="Number" matInput
                   placeholder="Middle Name" type="text" (keypress)="validateInput($event)">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard" class="py-0">
            <mat-label>
              Last Name<span class="text-danger">*</span>
            </mat-label>
            <input [(ngModel)]="indiviudal_user.last_name" aria-label="Number" matInput placeholder="Last Name"
                   type="text">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              E-mail<span class="text-danger">*</span>
            </mat-label>
            <input (blur)="emailAppuser()" [(ngModel)]="indiviudal_user.email" [formControl]="emailFormControl" aria-label="Number"
                   matInput placeholder="Email"
                   type="text">
            <!-- <mat-hint class="text-danger fw-normal" *ngIf="emailFormControl.hasError('required')">Email is required</mat-hint> -->
            <mat-hint *ngIf="emailFormControl.hasError('pattern')" class="text-danger fw-normal">Invalid
              email address
            </mat-hint>
            <mat-hint *ngIf="emailExist==true" class="text-danger fw-bold">Email Exists</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              Phone No.<span class="text-danger">*</span>
            </mat-label>
            <input #phone
                   [(ngModel)]="indiviudal_user.phone" aria-label="Number"
                   matInput maxlength="10" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                   placeholder="Phone No." type="text">
            <mat-hint *ngIf="phoneNoExist==true" class="text-danger fw-bold">Phone No. Exists
            </mat-hint>
            <mat-hint align="end">{{ phone.value.length }}/10
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              Whatsapp No.
            </mat-label>
            <input #whatsappnum [(ngModel)]="indiviudal_user.whatsapp"
                   aria-label="Number" matInput
                   maxlength="10" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                   placeholder="Whatsapp No." type="text">
            <mat-hint align="end">{{ whatsappnum.value.length }}/10</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="standard" class="example-full-width">
            <mat-label>Address<span class="text-danger">*</span></mat-label>
            <textarea [(ngModel)]="indiviudal_user.address" matInput
                      placeholder="Address"></textarea>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>Country<span class="text-danger">*</span></mat-label>
            <mat-select [(ngModel)]="user_sel_country"
                        matNativeControl>
              <mat-option selected>SELECT</mat-option>
              <mat-option (click)="getIndividaulStates()" *ngFor="let con of countries |async"
                          [value]="con.id">
                {{ con.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              State<span class="text-danger">*</span>
            </mat-label>
            <mat-select [(ngModel)]="user_sel_state"
                        matNativeControl>
              <mat-option selected>SELECT</mat-option>
              <mat-option (click)="getIndividaulCities()" *ngFor="let sta of user_states |async"
                          [value]="sta.id">
                {{ sta.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              City<span class="text-danger">*</span>
            </mat-label>
            <mat-select [(ngModel)]="indiviudal_user.city.id"
                        matNativeControl>
              <mat-option selected>SELECT</mat-option>
              <mat-option (click)="getIndividualPincodes()" *ngFor="let cit of user_cities |async"
                          [value]="cit.id">
                {{ cit.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              Zip Code<span class="text-danger me-auto">*</span>
            </mat-label>
            <mat-select [(ngModel)]="indiviudal_user.zip_code"
                        matNativeControl>
              <mat-option selected>SELECT</mat-option>
              <mat-option *ngFor="let cit of user_pincodes |async"
                          [value]="cit.id">
                {{ cit.pin_number }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard" class="py-0">
            <mat-label>
              Aadhaar No.
            </mat-label>
            <input #aadhaarnum1 [(ngModel)]="indiviudal_user.aadhaar_number"
                   aria-label="Number" matInput
                   maxlength="12" onkeypress="return event.charCode >= 48 && event.charCode <= 57" placeholder="Aadhaar No."
                   type="text">
            <mat-hint align="end">{{ aadhaarnum1.value.length }}/12</mat-hint>
          </mat-form-field>
        </div>
        <div class="row">
          <div class="col-md-4">
            <mat-form-field appearance="standard">
              <mat-label>
                Driving License
              </mat-label>
              <input [(ngModel)]="indiviudal_user.driving_license" aria-label="text" matInput placeholder="Driving License" #drivingLicense
                     type="text" maxlength="16">
                     <mat-hint>{{drivingLicense.value.length}}/16</mat-hint>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="standard" class="py-0">
              <mat-label>
                PAN No.
              </mat-label>
              <input #pan2 [(ngModel)]="indiviudal_user.pan_number" aria-label="Number" matInput placeholder="PAN No."
                     type="text" (keypress)="validateInput1($event)" [formControl]="PanFormControl2" maxlength="10" (input)="transformPANToUpperCaseForRepres()">
              <mat-hint *ngIf="PanFormControl2.hasError('pattern')" class="text-danger fw-normal">Invalid PAN No.
              </mat-hint>
              <mat-hint align="end">{{ pan2.value.length }}/10
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <ng-template #notbusinessuser>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="standard" class="py-0">
            <mat-label>
              First Name<span class="text-danger">*</span>
            </mat-label>
            <input [(ngModel)]="indiviudal_user.first_name" aria-label="Number" matInput placeholder="First Name" type="text">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard" class="py-0">
            <mat-label>
              Middle Name
            </mat-label>
            <input [(ngModel)]="indiviudal_user.middle_name" aria-label="Number" matInput
                   placeholder="Middle Name" type="text">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard" class="py-0">
            <mat-label>
              Last Name<span class="text-danger">*</span>
            </mat-label>
            <input [(ngModel)]="indiviudal_user.last_name" aria-label="Number" matInput placeholder="Last Name"
                   type="text">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              E-mail<span class="text-danger">*</span>
            </mat-label>
            <input (blur)="emailAppuser()" [(ngModel)]="indiviudal_user.email" [formControl]="emailFormControl" aria-label="Number"
                   matInput placeholder="Email"
                   type="text">
            <!-- <mat-hint class="text-danger fw-normal" *ngIf="emailFormControl.hasError('required')">Email is required</mat-hint> -->
            <mat-hint *ngIf="emailFormControl.hasError('pattern')" class="text-danger fw-normal">Invalid
              email address
            </mat-hint>
            <mat-hint *ngIf="emailExist==true" class="text-danger fw-bold">Email Exists</mat-hint>


          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              Phone No.<span class="text-danger">*</span>
            </mat-label>
            <input #phone
                   [(ngModel)]="indiviudal_user.phone" aria-label="Number"
                   matInput maxlength="10" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                   placeholder="Phone No." type="text">
            <mat-hint *ngIf="phoneNoExist==true" class="text-danger fw-bold">Phone No. Exists
            </mat-hint>
            <mat-hint align="end">{{ phone.value.length }}/10
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-md-4 ">
          <mat-form-field appearance="standard">
            <mat-label>
              Whatsapp No.
            </mat-label>
            <input type="text" placeholder=" Whatsapp No." onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput maxlength="10" #whatsappnum
                   [(ngModel)]="indiviudal_user.whatsapp">
            <mat-hint align="end">{{whatsappnum.value.length}}/10 </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="standard" class="example-full-width" style="margin-top: -16px">
            <mat-label> Address <span class="text-danger">*</span></mat-label>
            <textarea [(ngModel)]="indiviudal_user.address" matInput
                      placeholder="Address"></textarea>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>Country<span class="text-danger">*</span></mat-label>
            <mat-select (blur)="getIndividaulStates()" [(ngModel)]="user_sel_country"
                        matNativeControl>
              <mat-option selected>SELECT</mat-option>
              <mat-option *ngFor="let con of countries |async" [value]="con.id">
                {{ con.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              State<span class="text-danger">*</span>
            </mat-label>
            <mat-select [(ngModel)]="user_sel_state"
                        matNativeControl>
              <mat-option selected>SELECT</mat-option>
              <mat-option (click)="getIndividaulCities1(sta.id)"  *ngFor="let sta of user_states |async" [value]="sta.id">
                {{ sta.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              City<span class="text-danger">*</span>
            </mat-label>
            <mat-select (blur)="getIndividualPincodes()" [(ngModel)]="indiviudal_user.city.id"
                        matNativeControl>
              <mat-option selected>SELECT</mat-option>
              <mat-option *ngFor="let cit of user_cities |async" [value]="cit.id">
                {{ cit.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>
              Zip Code<span class="text-danger me-auto">*</span>
            </mat-label>
            <mat-select [(ngModel)]="indiviudal_user.zip_code"
                        matNativeControl>
              <mat-option selected>SELECT</mat-option>
              <mat-option *ngFor="let cit of user_pincodes |async"
                          [value]="cit.id">
                {{ cit.pin_number }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard" class="py-0">
            <mat-label>
              Aadhaar No.
            </mat-label>
            <input #aadhaarnum1 [(ngModel)]="indiviudal_user.aadhaar_number"
                   aria-label="Number" matInput
                   maxlength="12" onkeypress="return event.charCode >= 48 && event.charCode <= 57" placeholder="Aadhaar No."
                   type="text" (keypress)="validateInput1($event)">
            <mat-hint align="end">{{ aadhaarnum1.value.length }}/12</mat-hint>
          </mat-form-field>
        </div>
      </div>

          <div class="row">

            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Driving License
                </mat-label>
                <input [(ngModel)]="indiviudal_user.driving_license" aria-label="text" matInput placeholder="Driving License" #drivingLicense
                       type="text" maxlength="16">
                <mat-hint>{{drivingLicense.value.length}}/16</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field appearance="standard" class="py-0">
                <mat-label>
                  PAN No.
                </mat-label>
                <input #pan [(ngModel)]="indiviudal_user.pan_number" aria-label="Number" matInput placeholder="PAN No."
                       type="text" (keypress)="validateInput1($event)" [formControl]="PanFormControl" maxlength="10" (input)="panNoExist(); transformPANToUpperCaseForRetail()">
                <mat-hint *ngIf="PanFormControl.hasError('pattern')" class="text-danger fw-normal">Invalid PAN
                  No.
                </mat-hint>
                <mat-hint align="end">{{ pan.value.length }}/10
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard" class="py-0">
                <mat-label>
                  GST No.
                </mat-label>
                <input #gst2 [(ngModel)]="indiviudal_user.gst_number" aria-label="Number" matInput placeholder="GST No."
                       type="text" (keypress)="validateInput1($event)" maxlength="15" (input)="gstNoExist(); transformGSTToUpperCaseForRetail()">
                <mat-hint align="end">{{gst2.value.length}}/15</mat-hint>
              </mat-form-field>
            </div>
          </div>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary"
            type="button" (click)="onNoClick()">Close
    </button>
    <button (click)="createCustomer()" *ngIf="customer_created==false" class="btn btn-primary"
            type="button">Create
    </button>
  </div>
</div>
