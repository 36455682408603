import { Component, OnInit } from '@angular/core';
import Swal from "sweetalert2";
import {HttpService} from "../../../services/http.service";
import {API_URL} from "../../../../../Global_API_URL";
import {Router} from "@angular/router";
import {CustomerVerificationService} from "../../../shared/customer-verification/customer-verification.service";

@Component({
  selector: 'app-customer-create',
  templateUrl: './customer-create.component.html',
  styleUrls: ['./customer-create.component.sass']
})
export class CustomerCreateComponent implements OnInit {

  constructor(private route: Router, private httpService: HttpService, public customerVerification: CustomerVerificationService) {
  }

  ngOnInit(): void {
    this.customerVerification.getOfficeLocations();
    this.customerVerification.resetCorporateCustomerData(this.customer_shipper);
  }

  customer_shipper = {
    id: null,
    firstName: '',
    lastName: '',
    address_1: '',
    city_1: {id: null},
    creditPeriod: null,
    credit_value: null,
    email_1: '',
    emailForMasterGST: '',
    zip_code: null,
    isCreditExtended: null,
    isKycApproved: null,
    kycApprovedAt: null,
    office_location: {id: null},
    phone_1: '',
    customerTypeId: 2,
    kycApprovedBy: null,
    isBusinessAccount: true,
    pan: '',
    gst: '',
    otp_varified: null,
    isSezCustomer: false,
    isContractualCustomer: false,
    gstDetails: '',
    gstVerified: false
  }

  gstVerification() {
    this.customerVerification.gstVerification(this.customer_shipper, "corporate", "create");
  }


  createCustomer() {
    if (this.customer_shipper.firstName == null || this.customer_shipper.firstName == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Display Name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.firstName.length != this.customer_shipper.firstName.trim().length || this.customer_shipper.firstName.trim().length == 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Remove the spaces at beginning or end of the display name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.email_1 == null || this.customer_shipper.email_1 == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Email',
        timer: 5000,
      });
      return;
    } else if (!(this.customerVerification.emailregex.test(this.customer_shipper.email_1))) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter A Valid Email',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.phone_1 == null || this.customer_shipper.phone_1 == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Phone No.',
        timer: 5000,
      });
      return;
    } else if ((this.customer_shipper.phone_1).length < 10 || isNaN(parseInt(this.customer_shipper.phone_1)) == true) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Phone No.',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.address_1 == '' || this.customer_shipper.address_1 == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Address',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_country == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Country',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_state == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The State',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.city_1.id == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The City',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.zip_code == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Zip Code',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper?.pan == "" || this.customer_shipper?.pan == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The PAN No. ',
        timer: 5000,
      });
    } else if (this.customer_shipper?.pan?.length > 0 && !this.customerVerification.panregex.test(this.customer_shipper.pan)) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter Valid PAN No. ',
        timer: 5000,
      });
    } else if (this.customer_shipper.office_location.id === null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select the Billing Branch',
        timer: 5000,
      });
      return;
    } else {
      this.httpService.post(API_URL + '/customer', this.customer_shipper, null, null)
        .subscribe(response => {
          Swal.fire({
            icon: 'success',
            title: 'Customer Created',
            timer: 5000,
          }).then(r => {
            this.route.navigate(["/customers"]);
          });
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Failed to create customer',
            timer: 5000,
          });
        })
    }
  }

  validateInput1(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[a-zA-Z0-9]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  transformPANoUpperCaseForCreate() {
    this.customer_shipper.pan = this.customer_shipper.pan.toUpperCase();
  }


  goBackToCustomerTable() {
    this.route.navigate(["/customers"]);
  }

}
