import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {getCountries} from '../../getCities.js';
import {getAirports} from '../../getAirportAirlines.js';
import {API_URL} from '../../../../../Global_API_URL';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatTableDataSource} from '@angular/material/table';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {Router} from '@angular/router';
import {AuthService} from '../../../auth.service';

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#d92550';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-groundservices',
  templateUrl: './groundservices.component.html',
  styleUrls: ['./groundservices.component.sass'],
})
export class GroundservicesComponent implements OnInit {
  constructor(private _liveAnnouncer: LiveAnnouncer, private router: Router, private authService : AuthService) {
    this.authService.getAuthToken();
  }
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };
  pageview = 'table';
  nameExist = false;
  countries = getCountries();
  airports = getAirports();
  states1;
  city
  ngOnInit(): void {
    this.getCities();
  }

  pagenation = {
    sortBy: 'id',
    pageSize: 10,
    pageNo: 0,
    direction: 'DESC',
  }
  filterParams = '';
  state_id = '';
  supported_airport_id = '';
  is_door_supported = '';
  is_air_supported = '';
  CitiesList = null;
  state = null;
  displayedColumns = ['City', 'FTL', 'PTL', 'KV', 'KP']
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource;
  showFirstLastButtons;
  pageSizeOptions = [10, 20, 30];
  supporte_airport_id = null;
  ib_airport_id = null;
  ob_airport_id = null;

  tablefilter() {
    this.pagenation.pageNo = 0;
    this.pagenation.direction = 'DESC';
    this.getCities();
  }
  filter() {
    this.filterParams = '?';
    if (this.is_air_supported != '') {
      this.filterParams = this.filterParams + 'is_air_supported=' + this.is_air_supported + '&';
    }
    if (this.is_door_supported != '') {
      this.filterParams = this.filterParams + 'is_door_supported=' + this.is_door_supported + '&';
    }
    if (this.supported_airport_id != '') {
      this.filterParams = this.filterParams + 'supported_airport_id=' + this.supported_airport_id + '&';
    }
    if (this.state_id != '') {
      this.filterParams = this.filterParams + 'state_id=' + this.state_id + '&';
    }
    this.filterParams = this.filterParams + 'pageNo=' + this.pagenation?.pageNo + '&pageSize=' + this.pagenation?.pageSize + '&sortBy=' + this.pagenation?.sortBy + '&direction=' + this.pagenation?.direction;
    return this.filterParams;
  }
  handlePageEvent(event: PageEvent) {
    // this.length = event.length;
    // this.pageSize = event.pageSize;
    // alert(event.pageIndex)
    // this.pagenation.page_size  =event.pageSizeOptions;
    this.pagenation.pageNo = event.pageIndex;
    this.getCities();
  }
  cityid
  async getCities() {
    const TOKEN = this.authService.getAuthToken();
    this.loading = true;
    let array = [];
    // console.log(API_URL)
    const api = '/citiesfilters';
    const params = this.filter();
    await fetch(API_URL + api + params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })

    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          if (json.status == 400) {
            this.CitiesList = [];
          } else {
            this.CitiesList = json.data;
            array = Array.from(json.data);
            if (this.cityid != '' && this.cityid != null) {
              json.data = [];
              array.forEach((element) => {
                if (element.name == this.cityid) {
                  json.data.push(element);
                }
              });
            }
            this.dataSource = new MatTableDataSource(json.data);
            // this.paginator =
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        });
    this.loading = false;
    return this.CitiesList;
    // return loads;
  }
}
