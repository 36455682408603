import {Component, HostListener, OnInit} from '@angular/core';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ThemeOptions} from '../../theme-options';
import {animate, query, style, transition, trigger} from '@angular/animations';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';


@Component({
  selector: 'app-apps-layout',
  templateUrl: './apps-layout.component.html',
  animations: [

    trigger('architectUIAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: 0,
            display: 'flex',
            flex: '1',
            transform: 'translateY(-20px)',
            flexDirection: 'column',

          }),
        ]),
        query(':enter', [
          animate('600ms ease', style({opacity: 1, transform: 'translateY(0)'})),
        ], {optional: true}),

        query(':leave', [
          animate('600ms ease', style({opacity: 0, transform: 'translateY(-20px)'})),
        ], {optional: true}),
      ]),
    ]),
  ],
})

export class AppsLayoutComponent implements OnInit {
   conClass={
     'closed-sidebar': this.globals.toggleSidebar,
     'closed-sidebar-md': this.globals.toggleSidebarMobile,
     'settings-open': this.globals.toggleThemeOptions,
     'closed-sidebar-open': this.globals.sidebarHover ||this.globals.toggleSidebarMobile,
     'header-menu-open': this.globals.toggleHeaderMobile,
     'drawer-open': this.globals.toggleDrawer,
     'fixed-footer': this.globals.toggleFixedFooter,
   }

  @select('config') public config$: Observable<any>;

  public config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 2,
    swipeEasing: true,
    suppressScrollX: true,
    suppressScrollY: true,
    wheelPropagation: true,
    useBothWheelAxes: true,
  };
  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboards';

  public extraParameter: any;

  constructor(public globals: ThemeOptions, private activatedRoute: ActivatedRoute) {

  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }
  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
    this.globals.sidebarHover = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  sidebarHoverMouseOut() {
    this.globals.sidebarHover = false;
  }

  sidebarHoverMouseIn() {
    this.globals.sidebarHover = true;
  }

  ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.extraParameter = this.activatedRoute.snapshot.firstChild.routeConfig.path;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }
  }
}


