<!-- <p>control-panel works!</p> -->
<div class="col-md-8">
    <container-element [ngSwitch]="pageview">
        <some-element *ngSwitchCase="'table'">
            <div class="card">
                <div class="card-header">

                    <div class="card-header-title font-size-lg text-capitalize fw-normal">
                        <b style="color: blue;">LEAD LAG TIMES</b>
                    </div>
                </div>
                <div class="row p-4">
                    <div class="col-md-2 row">
                      <mat-form-field appearance="standard">
                        <mat-label>Product Type</mat-label>
                        <mat-select matNativeControl  [(ngModel)]="productType">
                            <mat-option value="">
                            All
                            </mat-option>
                            <mat-option value="ACE">
                                ACE
                            </mat-option>
                            <mat-option value="QUICK">
                                QUICK
                            </mat-option>
                            <mat-option value="MANUAL_MANIFEST">
                                MANUAL MANIFEST
                            </mat-option>
                            <!-- <mat-option value="PRUDENT">
                                PRUDENT
                            </mat-option>
                            <mat-option value="GEM">
                                GEM
                            </mat-option> -->
                          <!-- <mat-option *ngFor="let llt of leadlagtimes2 |async" [value]="llt">
                            {{llt.productType}}
                          </mat-option> -->
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2 row">
                      <mat-form-field appearance="standard">
                        <mat-label>Mode</mat-label>
                        <mat-select matNativeControl [(ngModel)]="mode">
                          <mat-option value="">
                            All
                          </mat-option>
                          <mat-option value="ATA">
                            ATA
                          </mat-option>
                          <mat-option value="ATD">
                            ATD
                          </mat-option>
                          <mat-option value="DTA">
                            DTA
                          </mat-option>
                          <mat-option value="DTD">
                            DTD
                          </mat-option>
                          <!-- <mat-option *ngFor="let llt of leadlagtimes2 |async" [value]="llt">
                            {{llt.mode}}
                          </mat-option> -->
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2 row">
                      <mat-form-field appearance="standard">
                        <mat-label>Type</mat-label>
                        <mat-select matNativeControl [(ngModel)]="type" (blur)="getLeadLagTimes2()">
                          <mat-option value="">
                            All
                          </mat-option>
                          <mat-option value="LEAD_TIME">
                            LEAD TIME
                          </mat-option>
                          <mat-option value="LAG_TIME">
                            LAG TIME
                          </mat-option>
                          <!-- <mat-option *ngFor="let llt of leadlagtimes2" [value]="llt">
                            {{llt.type}}
                          </mat-option> -->
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2 row">
                        <mat-form-field appearance="standard">
                          <mat-label>Originated City</mat-label>
                          <mat-select matNativeControl [(ngModel)]="origin_city">
                            <mat-option value="">
                              All
                            </mat-option>
                            <mat-option value="1">
                                Yes
                            </mat-option>
                            <mat-option value="0">
                                No
                            </mat-option>
                            <!-- <mat-option *ngFor="let llt of leadlagtimes2" [value]="llt">
                              {{llt.origin_city}}
                            </mat-option> -->
                          </mat-select>
                        </mat-form-field>
                      </div>
                    <div class="col-md-1 ">
                      <button class="btn btn-primary m-3" (click)="tablefilter()">Find</button>
                    </div>
                    <div class="col-md-1">
                      <button class="btn btn-secondary m-3" (click)="reset()">Reset</button>
                    </div>
                  </div>
                <!-- <div class="container">
                    <div class="row">
                        <div class="">
                            <div class="table-responsive">
                                <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Product Type</th>
                                            <th class="text-center">Mode</th>
                                            <th class="text-center">Type</th>
                                            <th class="text-center">Originated City</th>
                                            <th class="text-center">Time in Minutes</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let llt of LeadLagList.content">
                                            <td class="text-center">
                                                <a [routerLink]="" (click)="viewsection('view',air)">
													{{air.product_type}}
												</a>
                                                {{llt.productType}}
                                            </td>
                                            <td class="text-center">
                                                {{llt.mode}}
                                            </td>
                                            <td class="text-center">
                                                {{llt.type}}
                                            </td>
                                            <td class="text-center">
                                                <div *ngIf="llt.origin_city == 1">Yes</div>
                                                <div *ngIf="llt.origin_city == 0">No</div>
                                            </td>
                                            <td class="text-center">
                                                {{llt.time}}
                                            </td>
                                            <td class="text-center">
                                                <div role="group" class="btn-group-sm btn-group m-1">
                                                    <button class="btn-shadow btn btn-primary" matTooltip="Edit"
                                                        *ngIf="llt.is_active == 1" (click)="viewchange1('edit',llt)"><i
                                                            class="lnr-pencil"></i></button>
                                                </div>
                                                <div role="group" class="btn-group-sm btn-group ">
                                                    <button *ngIf="llt.is_active == 0" id="active"
                                                        class="btn-shadow btn btn-primary"
                                                        (click)="activeAirline(llt.id,1)"><i
                                                            class="fa fa-undo"></i></button>
                                                </div>
                                            </td>
                                    </tbody>
                                </table>
                                <mat-paginator (page)="handlePageEvent($event)" [length]="LeadLagList?.totalElements"
                                [pageSize]="LeadLagList?.numberOfElements" [showFirstLastButtons]="showFirstLastButtons"
                                [pageSizeOptions]="pageSizeOptions" [pageIndex]="LeadLagList?.number" aria-label="Select page">
                              </mat-paginator>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="table-responsive">
					<table class="w-100" mat-table [dataSource]="dataSource" matSort
						(matSortChange)="announceSortChange($event)">
						<!-- Position Column -->
						<ng-container matColumnDef="product_type">
							<th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header
								sortActionDescription="Sort by product_type" style="width: 16%;">Product Type</th>
							<td class="text-center" mat-cell *matCellDef="let element">{{element?.productType}}</td>
						</ng-container>
						<ng-container matColumnDef="mode">
							<th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header
								sortActionDescription="Sort by mode">Mode</th>
							<td class="text-center" mat-cell *matCellDef="let element"> {{element?.mode}} </td>
						</ng-container>
						<ng-container matColumnDef="type">
							<th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header
								sortActionDescription="Sort by type">Type</th>
							<td class="text-center" mat-cell *matCellDef="let element">
								{{element?.type}} </td>
						</ng-container>
						<ng-container matColumnDef="origin_city">
							<th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header
								sortActionDescription="Sort by origin_city">
								Originated City</th>
							<td class="text-center" mat-cell *matCellDef="let element">
								<div *ngIf="element.origin_city==true">Yes</div>
                                <div *ngIf="element.origin_city==false">No</div>
						</ng-container>
						<ng-container matColumnDef="time">
							<th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header
								sortActionDescription="Sort by time">Time</th>
							<td class="text-center" mat-cell *matCellDef="let element">
                                {{element?.time}}
							</td>
						</ng-container>
            <ng-container matColumnDef="updated_at">
							<th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header
								sortActionDescription="Sort by Updated At">Updated At</th>
							<!-- <td class="text-center" mat-cell *matCellDef="let element">{{element?.updated_at}}</td> -->
              <td class="text-center" mat-cell *matCellDef="let element">{{ element?.updated_at | date: 'MMM d, yyyy HH:mm:ss' }}</td>
						</ng-container>
						<ng-container matColumnDef="Active">
							<th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
							<td mat-cell *matCellDef="let element">
								<div class="d-flex">
									<div role="group" class="btn-group-sm btn-group m-1">
										<button class="btn-shadow btn btn-primary" matTooltip="Edit"
											*ngIf="element.is_active==true" (click)="viewchange1('edit',element)"><i
												class="lnr-pencil"></i></button>
									</div>
								</div>
							</td>
						</ng-container>
						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns;"
							[ngClass]="row.origin_city == false? 'red':'white'"></tr>
					</table>
					<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
					</mat-paginator>
				</div>
            </div>
        </some-element>
        <some-element *ngSwitchCase="'edit'">
            <div class="card">
                <div class="card-header">

                    <div class="card-header-title font-size-lg text-capitalize fw-normal">
                        <b style="color: blue;">EDIT LEAD LAG TIMES</b>
                    </div>

                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field appearance="standard">
                                <mat-label>
                                    Product Type
                                </mat-label>
                                <input type="text" [(ngModel)]="newleadlagtimes.productType" placeholder="Product Type"
                                    aria-label="Number" matInput disabled>

                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="standard">
                                <mat-label>
                                    Mode
                                </mat-label>
                                <input type="text" [(ngModel)]="newleadlagtimes.mode" placeholder="Mode"
                                    aria-label="Number" matInput disabled>

                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="standard">
                                <mat-label>
                                    Type
                                </mat-label>
                                <input type="text" [(ngModel)]="newleadlagtimes.type" placeholder="Type"
                                    aria-label="Number" matInput disabled>

                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="standard">
                                <mat-label>
                                    Time
                                </mat-label>
                                <input type="text" [(ngModel)]="newleadlagtimes.time" placeholder="Time"
                                    aria-label="Number" matInput>

                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="btn-actions-pane-right p-2">
                        <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');tablefilter()">Cancel</div>
                        <div class="mb-2 me-2 btn btn-primary" (click)="editLeadLagTimes()">Save</div>
                    </div>
                </div>
            </div>
        </some-element>
    </container-element>
</div>
