<h1 mat-dialog-title style="color: #333; font-size: 1.5em; font-weight: bold;">Create Cost-Center Info</h1>
<div mat-dialog-content style="color: #555; margin-top: 15px;">
    There is no Cost-Center Info for this consignment. Would you like to create a new Cost Center?
</div>
<div mat-dialog-actions style="display: flex; justify-content: flex-end; margin-top: 20px;">
    <div class="btn-actions-pane-right p-2">
        <div class="mb-2 me-2 btn" style="background-color: red; color: white" (click)="onNoClick()">Cancel</div>
        <div class="mb-2 me-2 btn btn-primary" (click)="getConsignmentByIdToCostCenterEdit()">Create</div>
    </div>
</div>
