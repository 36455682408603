import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ProductTypeService} from '../../modules/productTypes/services/product-type.service';
import {ProductTypeModel} from '../../modules/productTypes/models/product-type-model';
import {ConsignmentTypesModel} from '../../modules/consignmentTypes/models/consignment-types-model';
import {ConsignmentSubTypesModel} from '../../modules/consignmentSubtypes/models/consignment-sub-types-model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import Swal from 'sweetalert2';
import {ConfirmDialog} from '../Components/confirmDialog/confirmDialog.component';
import {ngxLoadingAnimationTypes} from 'ngx-loading';
import * as _ from 'lodash';

@Component({
  selector: 'linking',
  templateUrl: './product-type.component.html',
  styleUrls: ['./product-type.component.sass'],
})
export class ProductTypeComponent implements OnInit {
  constructor(private productTypeService: ProductTypeService, public matDialog : MatDialog, private cdr: ChangeDetectorRef) { }

  public loading = false;
  isRemoveConsignmentTypeProductType: boolean = false;
  isRemoveConsignmentSubtypeConsignmentTypeProductType: boolean = false;
  productTypeList: ProductTypeModel[];
  productTypeListWithNoChange: ProductTypeModel[];
  relationTableColumns = ['Name']
  productTypeTableColumns = ['Name', 'Created', 'Updated']
  selectedProductTypeIndex : number;
  selectedProductTypeConsignmentTypes: MatTableDataSource<ConsignmentTypesModel>;
  selectedProductTypeConsignmentTypeIndex: number;
  selectedProductTypeConsignmentTypeConsignmentSubTypes: MatTableDataSource<ConsignmentSubTypesModel>;
  consignmentTypes: ConsignmentTypesModel[];
  consignmentSubtypes: ConsignmentSubTypesModel[];
  productTypeRowId;

  @ViewChild('consignmentTypesModelMatTable') consignmentTypesModelMatTable: ElementRef<MatTable<ConsignmentTypesModel>>;
  userDetails;
  displaytoAccountant=false;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name=='Accountant') {
      this.displaytoAccountant=true;
    }
    this.productTypeService.getProductTypes().subscribe((result) => {
      this.productTypeList = result.data;

      this.selectedProductTypeConsignmentTypes = new MatTableDataSource(Array.of(new ConsignmentTypesModel('', 'No Product Selected', '', false)));
      this.selectedProductTypeConsignmentTypeConsignmentSubTypes = new MatTableDataSource(Array.of(new ConsignmentSubTypesModel('', 'No Product Selected', '', null, null)));
    });

    this.productTypeService.getProductTypes().subscribe((result) => {
      this.productTypeListWithNoChange = result.data;
    });

    this.productTypeService.getConsignmentTypes().subscribe((result) => {
      this.consignmentTypes = result.data;
    });
    this.productTypeService.getConsignmentSubTypes().subscribe((result) => {
      this.consignmentSubtypes = result.data;
    });
  }

  selectProductType(productTypeIndex: number) {
    this.selectedProductTypeIndex = productTypeIndex;
    this.selectedProductTypeConsignmentTypes = new MatTableDataSource(this.productTypeList[this.selectedProductTypeIndex].consignment_types);
    this.selectedProductTypeConsignmentTypeIndex = null;
    this.selectedProductTypeConsignmentTypeConsignmentSubTypes = new MatTableDataSource( [new ConsignmentSubTypesModel('', 'Select Consignment Type', '', null, null)]);
    // this.selectedProductTypeConsignmentTypes.push(new ConsignmentTypesModel("No"));
    if (this.selectedProductTypeConsignmentTypes?.data.length==0) {
      this.selectedProductTypeConsignmentTypes = new MatTableDataSource(Array.of(new ConsignmentTypesModel('', 'No Consignment Types Assigned', '', false)));
      this.selectedProductTypeConsignmentTypeConsignmentSubTypes = new MatTableDataSource( Array.of(new ConsignmentSubTypesModel('', 'No Consignment Sub-types Assigned', '', null, null)));
    } else this.selectedProductTypeConsignmentTypeConsignmentSubTypes = new MatTableDataSource( [new ConsignmentSubTypesModel('', 'Select Consignment Type', '', null, null)]);
  }

  selectOrRemoveConsignmentType(consignmentTypeIndex: number) {
    if (this.isRemoveConsignmentTypeProductType == false) {
      this.cancelRemoval();
      this.selectedProductTypeConsignmentTypeIndex = consignmentTypeIndex;
      this.selectedProductTypeConsignmentTypeConsignmentSubTypes = new MatTableDataSource(this.selectedProductTypeConsignmentTypes.data[consignmentTypeIndex].consignment_sub_types_list);
      if (this.selectedProductTypeConsignmentTypes.data[consignmentTypeIndex].consignment_sub_types_list==null || this.selectedProductTypeConsignmentTypes.data[consignmentTypeIndex].consignment_sub_types_list.length==0) this.selectedProductTypeConsignmentTypeConsignmentSubTypes = new MatTableDataSource(Array.of(new ConsignmentSubTypesModel('', 'No Consignment Sub-types Assigned', '', null, null)));
      // this.selectedProductTypeConsignmentTypeConsignmentSubTypes.push(new ConsignmentSubTypesModel("No"))}
    } else if (this.isRemoveConsignmentTypeProductType == true) {
      this.cancelRemoval();
      const dialogRef = this.matDialog.open(ConfirmDialog, {
        width: '25rem',
        data: 'Please confirm, Remove '+ this.selectedProductTypeConsignmentTypes.data[consignmentTypeIndex].name+'?',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result==true) {
          this.productTypeList[this.selectedProductTypeIndex].consignment_types.splice(consignmentTypeIndex, 1);
          this.selectProductType(this.selectedProductTypeIndex);
        }
      });
    }
  }


  RemoveConsignmentSubtype(consignmentSubtypeIndex: number) {
    if (this.isRemoveConsignmentSubtypeConsignmentTypeProductType == true) {
      this.cancelRemoval();
      const dialogRef = this.matDialog.open(ConfirmDialog, {
        width: '25rem',
        data: 'Please confirm, Remove '+this.selectedProductTypeConsignmentTypeConsignmentSubTypes.data[consignmentSubtypeIndex].name+'?',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result==true) {
          this.productTypeList[this.selectedProductTypeIndex]?.consignment_types[this.selectedProductTypeConsignmentTypeIndex]?.consignment_sub_types_list?.splice(consignmentSubtypeIndex, 1);
          this.selectOrRemoveConsignmentType(this.selectedProductTypeConsignmentTypeIndex);
        }
      });
    }
  }

  isProductTypeRowSelected(name) {
    if (name===this.productTypeList[this.selectedProductTypeIndex]?.name) return true;
  }

  isConsignmentTypeRowSelected(name) {
    if (name===this.productTypeList[this.selectedProductTypeIndex]?.consignment_types[this.selectedProductTypeConsignmentTypeIndex]?.name) return true;
  }


  openConsignmentTypeDialog(): void {
    this.cancelRemoval();
    const dialogRef = this.matDialog.open(AddConsignmentTypeProductTypeDialog, {
      width: '25rem',
      data: this.consignmentTypes.filter((consignmentType) => {
        for (const item of this.selectedProductTypeConsignmentTypes.data) {
          if (item.id===consignmentType.id || consignmentType.is_active===false) return false;
        }
        return true;
      }),
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log("returned->", result)

      if (result!=null) {
        this.productTypeList[this.selectedProductTypeIndex].consignment_types.push(result);
        this.selectProductType(this.selectedProductTypeIndex);
      }
      this.selectedProductTypeConsignmentTypeIndex = null;
      this.selectedProductTypeConsignmentTypeConsignmentSubTypes = new MatTableDataSource(Array.of(new ConsignmentSubTypesModel('', 'No Consignment Sub-types Assigned', '', null, null)));
    });
  }

  openConsignmentSubTypeDialog(): void {
    this.cancelRemoval();
    const dialogRef = this.matDialog.open(AddConsignmentSubtypeConsignmentTypeProductTypeDialog, {
      width: '25rem',
      data: this.consignmentSubtypes.filter((consignmentSubtype) => {
        for ( const consignmentSubtype2 of this.selectedProductTypeConsignmentTypeConsignmentSubTypes.data) {
          if (consignmentSubtype.id === consignmentSubtype2.id || consignmentSubtype.isActive===false) return false;
        }
        return true;
      }),
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result!=null) {
        if (this.productTypeList[this.selectedProductTypeIndex].consignment_types[this.selectedProductTypeConsignmentTypeIndex].consignment_sub_types_list!=null) this.productTypeList[this.selectedProductTypeIndex].consignment_types[this.selectedProductTypeConsignmentTypeIndex].consignment_sub_types_list.push(result);
        else this.productTypeList[this.selectedProductTypeIndex].consignment_types[this.selectedProductTypeConsignmentTypeIndex].consignment_sub_types_list = Array.of(result);
        // this.selectProductType(this.selectedProductTypeIndex);
        this.selectOrRemoveConsignmentType(this.selectedProductTypeConsignmentTypeIndex);
      }
    });
  }

  enableRemoveConsignmentType() {
    this.isRemoveConsignmentTypeProductType = true;
    this.isRemoveConsignmentSubtypeConsignmentTypeProductType = false;
  }

  enableRemoveConsignmentSubtype() {
    this.isRemoveConsignmentTypeProductType = false;
    this.isRemoveConsignmentSubtypeConsignmentTypeProductType = true;
  }

  cancelRemoval() {
    this.isRemoveConsignmentTypeProductType = false;
    this.isRemoveConsignmentSubtypeConsignmentTypeProductType = false;
  }

  saveProductTypes() {
    this.loading = true;
    // const productTypeSaveList: Array<ProductTypeModel> = new Array<ProductTypeModel>();
    // for ( let i = 0; i<this.productTypeList.length; i++ ) {
    //   let isContinue : boolean = false;
    //   if (this.productTypeListWithNoChange[i].name!==this.productTypeList[i].name) {
    //     productTypeSaveList.push(this.productTypeList[i]);
    //     isContinue=true;
    //     continue;
    //   }
    //   if (this.productTypeListWithNoChange[i].consignment_types.length!==this.productTypeList[i].consignment_types.length) {
    //     productTypeSaveList.push(this.productTypeList[i]);
    //     isContinue=true;
    //     continue;
    //   }
    //   for ( let j: number = 0; j<this.productTypeListWithNoChange[i].consignment_types.length; j++ ) {
    //     if (isContinue) break;
    //     if (this.productTypeList[i].consignment_types[j].name!==this.productTypeListWithNoChange[i].consignment_types[j].name) {
    //       productTypeSaveList.push(this.productTypeList[i]);
    //       isContinue=true;
    //       break;
    //     }
    //     if (this.productTypeList[i].consignment_types[j].consignment_sub_types_list.length!==this.productTypeListWithNoChange[i].consignment_types[j].consignment_sub_types_list.length) {
    //       productTypeSaveList.push(this.productTypeList[i]);
    //       isContinue=true;
    //       break;
    //     }
    //     for ( let k: number = 0; k<this.productTypeListWithNoChange[i].consignment_types[j].consignment_sub_types_list.length; k++ ) {
    //       if (isContinue) break;
    //       if (this.productTypeList[i].consignment_types[j].consignment_sub_types_list[k].name!==this.productTypeListWithNoChange[i].consignment_types[j].consignment_sub_types_list[k].name) {
    //         productTypeSaveList.push(this.productTypeList[i]);
    //         isContinue=true;
    //         break;
    //       }
    //     }
    //   }
    // }

    if(!_.isEqual(this.productTypeList, this.productTypeListWithNoChange)) {
      let flag = true;

      // Checking if any consignment type having empty list of consignment subtype
      this.productTypeList.forEach(productType => {
        productType.consignment_types.forEach(consignmentType => {
          if(consignmentType.consignment_sub_types_list==null || consignmentType.consignment_sub_types_list.length==0) {
            flag = false;  // flipping flag to false if a consignment type does not have any valid consignment subtype list
            this.loading = false;
            Swal.fire({
              title: 'Consignment Sub Types Missing',
              text: `Please add consignment subtypes to ${productType.name} -> ${consignmentType.name}`,
              icon: 'warning',
              timer: 6000
            })

          }
        })
      })

      if(flag)
        this.productTypeService.postOrderTypesConsignmentSubTypes(this.productTypeList).subscribe((result) => {
          this.loading = false;
          Swal.fire({
            title: 'Success',
            text: 'Changes Saved Successfully.',
            icon: 'success',
            timer: 2000,
          });
        });
    }
    else {
      this.loading = false;
      Swal.fire({
        title: 'No changes found',
        text: 'Please make some changes',
        icon: 'warning',
        timer: 3000
      })
    }
  }

  readonly ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  discardChages() {
    this.productTypeList = _.cloneDeep(this.productTypeListWithNoChange);
    this.selectedProductTypeConsignmentTypes = new MatTableDataSource(Array.of(new ConsignmentTypesModel('', 'No Product Selected', '', false)));
    this.selectedProductTypeConsignmentTypeConsignmentSubTypes = new MatTableDataSource(Array.of(new ConsignmentSubTypesModel('', 'No Product Selected', '', null, null)));
    this.cancelRemoval();
  }
}


@Component({
  selector: 'add_consignment_type_product_type_dialog',
  templateUrl: 'add_consignment_type_product_type_dialog.html',
})
export class AddConsignmentTypeProductTypeDialog {
  constructor(
    public dialogRef: MatDialogRef<AddConsignmentTypeProductTypeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConsignmentTypesModel[]) {}

  selectedConsignmentType: ConsignmentTypesModel = null;

  showWarning:boolean = false;

  onNoClick(): void {
    this.dialogRef.close();
  }

  submitResult() {
    // console.log("selected->", this.selectedConsignmentType)
    if (this.selectedConsignmentType==null) this.showWarning=true;
    else this.dialogRef.close(new ConsignmentTypesModel(this.selectedConsignmentType.id, this.selectedConsignmentType.name, this.selectedConsignmentType.description, this.selectedConsignmentType.is_active));
  }

  protected readonly ConsignmentTypesModel = ConsignmentTypesModel;
}


@Component({
  selector: 'add_consignment_subtype_consignment_type_product_type_dialog',
  templateUrl: 'add_consignment_subtype_consignment_type_product_type_dialog.html',
})
export class AddConsignmentSubtypeConsignmentTypeProductTypeDialog {
  constructor(
    public dialogRef: MatDialogRef<AddConsignmentSubtypeConsignmentTypeProductTypeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConsignmentSubTypesModel[]) {}

  selectedConsignmentSubType: ConsignmentSubTypesModel = null;

  onNoClick(): void {
    this.dialogRef.close();
  }
}
