import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {API_URL} from '../../../../Global_API_URL.js';
import {ngxLoadingAnimationTypes} from 'ngx-loading';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import * as FileSaver from 'file-saver';
import {AuthService} from '../../auth.service';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-shipment-ingestion',
  templateUrl: './shipment-ingestion.component.html',
  styleUrls: ['./shipment-ingestion.component.sass'],
})
export class ShipmentIngestionComponent implements OnInit {
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public loadingTemplate: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageview = 'table';
  errorLogs: any[] = [];
  dataSource: MatTableDataSource<any>;
  totalElements: number = 0;
  pageSize: number = 10;
  pageNo: number = 0;
  displayedColumns: string[] = ['filename', 'status', 'createdAt', 'uploadedBy'];

  constructor(private http: HttpClient, private authService : AuthService) {
    this.authService.getAuthToken();
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getErrorLogs(0, 10);
  }

  async getErrorLogs(page = 0, pageSize = 10) {
    // console.log(`Fetching page ${page} with pageSize ${pageSize}`);
    const TOKEN = this.authService.getAuthToken();
    try {
      const response = await fetch(API_URL + `/bulkUpload/getbulkUploads?pageNo=${page}&pageSize=${pageSize}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${TOKEN}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch error logs. Status: ${response.status}`);
      }
      const data = await response.json();

      if (!data || !data.content || !Array.isArray(data.content)) {
        console.error('Invalid response format:', data);
        return;
      }

      this.errorLogs = data.content;
      this.dataSource.data = this.errorLogs;
      this.totalElements = data.totalElements;
      this.loading = false;
      // console.log(`Received ${this.errorLogs.length} records from the backend`);
      // console.log(this.errorLogs);
    } catch (error) {
      console.error('Error fetching error logs:', error);
    }
  }


  onPageChange(event: PageEvent): void {
    this.pageNo = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getErrorLogs(event.pageIndex, event.pageSize);
  }

  convertUTCtoIST(utcTimestamp: string): string {
    const date = new Date(utcTimestamp);
    const istOffset = 330 * 60 * 1000;
    const istTime = new Date(date.getTime() + istOffset);
    return istTime.toISOString();
  }

  downloadLogFile(logFileName: string): void {
    const DOWNLOAD_URL = API_URL + `/bulkUpload/downloadLog/${logFileName}`;
    const TOKEN = this.authService.getAuthToken();
    this.http
        .get(DOWNLOAD_URL, {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${TOKEN}`,
            'Content-Type': 'application/octet-stream',
          }),
          responseType: 'blob',
        })
        .subscribe(
            (data: Blob) => {
              FileSaver.saveAs(data, logFileName);
            },
            (error) => {
              console.error('Error downloading file:', error);
            },
        );
  }
}
