import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

const TOKEN_KEY = 'accessToken';
const REFRESHTOKEN_KEY = 'refreshToken';
const USER_KEY = 'userDetails';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private router: Router) { }
  signOut(): void {
    localStorage.removeItem('isUserLoggedIn');
    sessionStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userDetails');
    this.router.navigate(['/']);
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);

    // const user = this.getUser();
    // if (user.id) {
    //   this.saveUser({ user, accessToken: token });
    // }
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveRefreshToken(token: string): void {
    window.localStorage.removeItem(REFRESHTOKEN_KEY);
    window.localStorage.setItem(REFRESHTOKEN_KEY, token);
  }

  public getRefreshToken(): string | null {
    return window.localStorage.getItem(REFRESHTOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) {
      // console.log(user)
      return JSON.parse(user);
    }

    return {};
  }
}
