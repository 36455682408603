<div *ngIf="loading" class="loader-container">
  <ngx-loading [show]="loading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>

<div class="card mt-2">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
      <a><i class="fa fa-arrow-left" (click)="backToBillingInfo()" style="cursor: pointer"></i></a>&nbsp;Edit Billing
      Info
    </div>
    <div class="btn-actions-pane-right p-2 ">
      <div class="mb-2 me-2 btn btn-primary" (click)="saveBillingPrices()"> Save</div>
    </div>
  </div>

  <div style="width: 80%; margin:4% 10%">

    <div class="table-responsive">
      <table class="table1" style="width:100%">
        <tr>
          <td class="left">Consignment No :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment.consignment_no}}</td>
          <td class="left">Customer Name :</td>
          <td
            class="right">{{commonApi.BillingPriceLists?.consignment.isRetailCustomer ? commonApi.BillingPriceLists?.consignment.individual_name : commonApi.BillingPriceLists?.consignment.customer_name}}</td>
        </tr>

        <tr>
          <td class="left">Origin :</td>
          <td
            class="right">{{commonApi.BillingPriceLists.consignment?.origin || commonApi.BillingPriceLists.consignment?.cityname }}</td>
          <td class="left">Destination :</td>
          <td
            class="right">{{commonApi.BillingPriceLists.consignment?.destination || commonApi.BillingPriceLists.consignment?.receivercity}}</td>
        </tr>

        <tr>
          <td class="left">No of packages :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.no_of_packages}}</td>
          <td class="left">Total chargeable weight :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.total_chargable_weight}} kg</td>
        </tr>
        <tr>
          <td class="left">Consignment Value :</td>
          <td class="right">
            INR {{commonApi.BillingPriceLists.consignment?.consignment_value?.toLocaleString('en-IN')}}</td>
        </tr>
      </table>
    </div>

    <hr style="margin-right:-12.5%; margin-left: -12.4%; border: 1px solid black">

    <div class="table-responsive">
      <table class="table" style="width:100%">
        <tr class="header">
          <th style="width: 43%; padding-left: 1%">Line Items</th>
          <th style="width: 43%; padding-left: 1%">Unit Price</th>
          <th style="width: 14%">Total</th>
          <th>
            <button style="color: white; border: white; border-radius: 7px" (click)="addDropdown()"
                    [ngStyle]="{'background-color': addColour()}" [disabled]="count > limit"><i class="fa fa-plus"
                                                                                                aria-hidden="true"></i>
            </button>
          </th>
        </tr>
        <tr *ngFor="let selectedItem of selectedBillingLineItems; let i = index">
          <td>
            <mat-form-field appearance="legacy" style="margin-top: -10%">
              <!--              <mat-label>Select Billing Line Item</mat-label>-->
              <mat-select [(ngModel)]="selectedBillingLineItems[i].name">
                <mat-option *ngFor="let item of getAvailableOptions(i)" [value]="item.name">
                  {{ item.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field appearance="legacy" style="width: 125px; margin-top: -2%">
              <span matPrefix style="font-size: 14px">INR&nbsp;</span>
              <input matInput [(ngModel)]="selectedBillingLineItems[i].billPrice" (ngModelChange)="onPriceChange()"
                     type="number" min="0" onkeypress="if (this.value.length > 7) return false;"
                     (keypress)="validateInput($event)" (focus)="clearUnitPriceZero(i)"
                     title="Please enter a maximum of 8 digits">
            </mat-form-field>
            <i class="fa fa-trash fa-lg" style="color: red; height: 50px; cursor:pointer;" aria-hidden="true"
               (click)="removeLineItem(i)"></i>
          </td>
        </tr>
      </table>
    </div>


    <div style="display: flex; flex-direction:row; margin-top: 1%">
      <div style="width: 86%; display: flex; flex-direction:row">
        <div>
          <b class="subTotal" style="float: left">Sub Total 1</b>
        </div>&nbsp;&nbsp;&nbsp;
        <div style="flex-grow: 1; border-top: 1px solid #ADADAD; margin-top:1.5%"></div>&nbsp;&nbsp;&nbsp;
      </div>
      <div>
        INR {{subTotal1?.toLocaleString('en-IN')}}
      </div>
    </div>

    <div style="margin-top: 1%">
      <span style="float: left; width: 42.5%;">Discount Amount</span>
      <span style="color: #0ba360">
        <mat-form-field appearance="legacy" style="width: 112px; margin-top: -2%">
          <span matPrefix>-INR&nbsp;</span>
          <input matInput [(ngModel)]="commonApi.BillingPriceLists.discountedAmount" (ngModelChange)="onPriceChange()"
                 type="number" (keypress)="validateInput($event)" min="0" onkeypress="if (this.value.length > 7) return false;" (focus)="clearDiscountZero()">
        </mat-form-field>
      </span>
    </div>


    <div style="margin-top: 1%; display: flex; flex-direction:row">
      <div style="width: 86%; display: flex; flex-direction:row">
        <div>
          <b class="subTotal" style="float: left">Sub Total 2</b>
        </div>&nbsp;&nbsp;&nbsp;
        <div style="flex-grow: 1; border-top: 1px solid #ADADAD; margin-top:1.5%"></div>&nbsp;&nbsp;&nbsp;
      </div>
      <div>
        INR {{subTotal2?.toLocaleString('en-IN')}}
      </div>
    </div>
    <div style="margin-top: 1%">
      <span class="insurance1" style="float: left; margin-top:  -0.2%"> Insurance (FOV)</span>
      <span style="display: flex; flex-direction: row">
        <span>
          <mat-form-field style="width: 50px; margin-right: 5px; margin-top: -29%">
<!--            <mat-label>fov</mat-label>-->
            <mat-select [(value)] = "selected" (selectionChange)="commonApi.onFovOptionChange($event)">
              <mat-option value="0"> % </mat-option>
              <mat-option value="1"> INR </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <span class="BillInsResp">
          <mat-form-field class="insurance2" appearance="legacy" style="width: 113px">
            <span matPrefix>+&nbsp;</span>
            <span matSuffix *ngIf="commonApi.isSuffix">%</span>
            <span matPrefix *ngIf="!commonApi.isSuffix">INR</span>
                <input *ngIf="commonApi.isSuffix" matInput
                       [(ngModel)]="commonApi.BillingPriceLists.insurancePercentage" (ngModelChange)="onPriceChange()"
                       type="text" pattern="[0-9]+([\.,][0-9]+)?" (keypress)="validateInput($event)"
                       (focus)="clearInsuranceZero()">

                <input *ngIf="!commonApi.isSuffix" matInput
                       [(ngModel)]="commonApi.BillingPriceLists.insurance" (ngModelChange)="onPriceChange()"
                       type="text" pattern="[0-9]+([\.,][0-9]+)?" maxlength="8" (keypress)="validateInput($event)"
                       (focus)="clearInsuranceValueZero()">
          </mat-form-field>
<!--          <span *ngIf="!commonApi.isSuffix"> ({{commonApi.BillInsurancePercentage}} %) </span>-->
        </span>
      <span>+INR {{commonApi.BillInsuranceAmount?.toLocaleString('en-IN')}}</span>
      </span>
    </div>


    <div style="margin-top: 1%; display: flex; flex-direction:row">
      <div style="width: 86%; display: flex; flex-direction:row">
        <div>
          <b class="subTotal" style="float: left">Taxable Amount</b>
        </div>&nbsp;&nbsp;&nbsp;
        <div style="flex-grow: 1; border-top: 1px solid #ADADAD; margin-top:1.5%"></div>&nbsp;&nbsp;&nbsp;
      </div>
      <div>
        INR {{BillTaxableAmount?.toLocaleString('en-IN')}}
      </div>
    </div>
    <div style="margin-top: 1%">
      <span style="float: left; width: 44%; margin-top: -0.2%"> Tax% ({{commonApi.BillingPriceLists?.isGst}}) </span>
      <span style="display: flex; flex-direction: row">
        <span class="BillResp">
<!--          <mat-form-field appearance="legacy" style="width: 90px; margin-top: -1%">-->
          <!--            <span matPrefix>+&nbsp;</span>-->
          <!--            <span matSuffix>%</span>-->
          <!--              <input matInput-->
          <!--                     [(ngModel)]="commonApi.BillingPriceLists.taxablePercentage"-->
          <!--                     (ngModelChange)="onPriceChange()"-->
          <!--                     type="text" pattern="[0-9]+([\.,][0-9]+)?" maxlength="5" (keypress)="validateInput($event)" min="0" max="100" (focus)="clearTaxZero()">-->
          <!--            </mat-form-field>-->
          + {{commonApi.BillTax}} %
        </span>
        <span>+INR {{BillTaxAmount?.toLocaleString('en-IN')}}</span>
      </span>
    </div>

    <div style="margin-top: 1%; display: flex; flex-direction:row">
      <div class="BillGrandTotalResp">
        <h3>Grand Total</h3>
      </div>
      <div>
        <h4>
          INR {{BillGrandTotal?.toLocaleString('en-IN')}}
        </h4>
      </div>
    </div>

    <div>
      <mat-form-field appearance="outline" style="width: 400px">
        <mat-label>Remarks</mat-label>
        <textarea matInput [(ngModel)]="commonApi.BillingPriceLists.remarks" #input style="height: 80px"
                  maxlength="250"></textarea>
        <mat-hint align="end">{{input.value.length}}/250</mat-hint>
      </mat-form-field>
    </div>

  </div>

  <div class="btn-actions-pane-right p-2" style="margin-top: -2%; margin-bottom: 2%; margin-right: 2%">
    <div class="mb-2 me-2 btn" style="background-color: red; color: white" (click)="backToBillingInfo()"> Discard</div>
    <div class="mb-2 me-2 btn btn-primary" (click)="saveBillingPrices()"> Save</div>
  </div>
</div>
