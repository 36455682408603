<div class="row">
  <div class="col-md-10">
    <div class="card mb-3">
      <div class="card-header">
        <!-- Google tag (gtag.js) -->
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-QSHLPMS4HK"></script>
        <script>
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-QSHLPMS4HK');
        </script>

        <div class="card-header-title font-size-lg text-capitalize fw-normal">
          <b style="color: blue;">ICL AIR CARGO</b>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 p-4"*ngIf="displaytocustomer">
          <a [routerLink]="['/bookingtypes', 'Ace']" class="card p-2 card-hover-product">
            <img matTooltip="General Air Cargo" src="../../../../../assets/images/icl-products/ACE.jpg" class="">
          </a>
        </div>
        <div class="col-md-3 p-4" *ngIf="!displaytocustomer">
          <a (click)="gotoAce()" class="card p-2 card-hover-product">
            <img matTooltip="General Air Cargo" src="../../../../../assets/images/icl-products/ACE.jpg" class="">
          </a>
        </div>
        <div class="col-md-3 p-4">
          <a (click)="gotoQuick('Online')" class="card p-2 card-hover-product" *ngIf="!displaytocustomer" >
            <img matTooltip="Express Air Cargo" src="../../../../../assets/images/icl-products/QUICK.png" class="">
          </a>
          <a [routerLink]="['/bookingtypes', 'Quick']" class="card p-2 card-hover-product" *ngIf="displaytocustomer" >
            <img matTooltip="Express Air Cargo" src="../../../../../assets/images/icl-products/QUICK.png" class="">
          </a>
<!--          <a [routerLink]="['/quickproducts']" class="card p-2 card-hover-product"  >-->
<!--            <img matTooltip="Ready To Work" src="../../../../../assets/images/icl-products/QUICK.png" class="">-->
<!--          </a>-->
          <!-- D2A -->
        </div>
        <div class="col-md-3 p-4">
          <a [routerLink]="['/bookingtypes', 'Prudent']" class="card p-2 card-hover-product" *ngIf="displaytocustomer" >
            <img matTooltip="Prudent" src="../../../../../assets/images/icl-products/PRUDENT.jpg" >
          </a>
          <a (click)="gotoPrudent()" class="card p-2 card-hover-product" *ngIf="!displaytocustomer" >
            <img matTooltip="Prudent" src="../../../../../assets/images/icl-products/PRUDENT.jpg" >
          </a>
          <!-- A2D -->
        </div>
        <div class="col-md-3 p-4">
          <a [routerLink]="['/bookingtypes', 'Gem']" class="card p-2 card-hover-product"  *ngIf="displaytocustomer">
            <img matTooltip="Precious" src="../../../../../assets/images/icl-products/GEM.jpg">
          </a>
          <a (click)="gotoGem()" class="card p-2 card-hover-product"  *ngIf="!displaytocustomer">
            <img matTooltip="Precious" src="../../../../../assets/images/icl-products/GEM.jpg">
          </a>
          <!-- D2D -->
        </div>
      </div>

    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-5">
    <div class="card">
      <div class="card-header">

        <div class="card-header-title font-size-lg text-capitalize fw-normal">
          <b style="color: blue;">ICL GROUND</b>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 p-4">
          <a (click)="goto('Full Truck Load')" class="card p-2 card-hover-product">
            <img matTooltip="Full truck Load" src="../../../../../assets/images/icl-products/full_truck_load.jpg"
              class="">
          </a>
        </div>
        <div class="col-md-6 p-4">
          <a (click)="goto('Partial Truck Load')"class="card p-2 card-hover-product">
            <img matTooltip="Partial Truck Load" src="../../../../../assets/images/icl-products/partial_truck_load.jpg"
              class="">
          </a>
          <!-- D2A -->
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-5">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title font-size-lg text-capitalize fw-normal">
          <b style="color: blue;">ICL KELVIN</b>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 p-4">
          <a (click)="goto('Reefer Vehicle')" class="card p-2 card-hover-product">
            <img matTooltip="Reefer - Temperature control" src="../../../../../assets/images/icl-products/reefer_vehicle.jpg" class="">
          </a>
        </div>
        <div class="col-md-6 p-4">
          <a (click)="goto('Coldchain Packaging')" class="card p-2 card-hover-product">
            <img matTooltip="Coldchain - Packaging solutions" src="../../../../../assets/images/icl-products/CCP.jpg" class="">
          </a>
          <!-- D2A -->
        </div>
      </div>
    </div>
  </div>
</div>
