import { Component, OnInit } from '@angular/core';
import { CSVFileFormatDTO } from '../../golbalInterfaces';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CsvFileFormatsService } from '../csv-file-formats/csv-file-formats.service';

@Component({
  selector: 'app-csv-file-formats-add',
  templateUrl: './csv-file-formats-add.component.html',
  styleUrls: ['./csv-file-formats-add.component.sass']
})
export class CsvFileFormatsAddComponent implements OnInit {

  constructor(private router: Router, private CSVFileFormatService: CsvFileFormatsService) { }

  ngOnInit(): void {
  }

  newCSVFileFormat: CSVFileFormatDTO = new CSVFileFormatDTO();
  selectedFile: File | undefined;
  isSubmitting: boolean = false;

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      const currentTime = new Date().getTime();
      const fileNameParts = this.selectedFile.name.split('.');
      const newFileName = `${fileNameParts[0]}_${currentTime}.${fileNameParts[1]}`;
      this.selectedFile = new File([this.selectedFile], newFileName, { type: this.selectedFile.type });
    }
  }

  onSubmit(): void {
    if (this.isSubmitting) return;
    if (!this.newCSVFileFormat.contentDisposition) {
      Swal.fire('Warning', 'Please Enter the File Name', 'warning');
      return;
    }
    if (!this.selectedFile) {
      Swal.fire('Warning', 'No file selected', 'warning');
      return;
    }
    this.isSubmitting = true;

    // Check if content disposition already exists
    this.CSVFileFormatService.existsByContentDisposition(this.newCSVFileFormat.contentDisposition)
      .subscribe((exists) => {
        if (exists) {
          Swal.fire('Warning', 'File Name you given is already existing', 'warning');
          this.isSubmitting = false;
        } else {
          // If content disposition doesn't exist, proceed with creating csv file format
          this.CSVFileFormatService.createCsvFileFormat(this.newCSVFileFormat, this.selectedFile)
            .subscribe((response) => {
              Swal.fire('Success', 'File uploaded successfully', 'success');
              this.router.navigate(['/fileFormats']);
            }, (error) => {
              const errorMessage = error.error.errorMessage || 'Error uploading csv file';
              Swal.fire('Error', errorMessage, 'error');
            })
            .add(() => {
              this.isSubmitting = false; // Reset submitting state
            });
        }
      }, (error) => {
        Swal.fire('Error', 'Failed to check content disposition existence', 'error');
        this.isSubmitting = false;
      });
  }

  onCancel(): void {
    this.router.navigate(['/fileFormats']);
  }

}
