import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {getCitiesById, getCountries, getStatesById} from '../../getCities.js';
import {API_URL} from '../../../../../Global_API_URL';
import {ConfirmDialog} from '../../Components/confirmDialog/confirmDialog.component';
import {MatDialog} from '@angular/material/dialog';
import Swal from 'sweetalert2';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {FormControl} from '@angular/forms';
import {MatSort, Sort} from '@angular/material/sort';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatTableDataSource} from '@angular/material/table';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {saveAs} from 'file-saver';
import {AuthService} from '../../../auth.service';
import {HttpService} from '../../../services/http.service';
import { th } from 'date-fns/locale';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#d92550';
const SecondaryBlue = '#3f6ad8';


@Component({
  selector: 'app-table-pincodes',
  templateUrl: './table-pincodes.component.html',
  styleUrls: ['./table-pincodes.component.sass'],
})
export class TablePincodesComponent implements OnInit {
  pagenation: any;
  totalItems: any;
  constructor(private _liveAnnouncer: LiveAnnouncer, private authService : AuthService, private httpService: HttpService,private httpClient: HttpClient,private matDialog: MatDialog) {
    this.authService.getAuthToken();
  }
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };
  searchText;
  business_sel_state;
  business_states;
  myControl1 = new FormControl();
  editparams = {
    id: null,
    pincode: null,
    cityid: null,
    countryid: null,
    stateid: null,
    AreaName: null,
    IB: 0,
    OB: 0,
    OOA: 0,

  }
  cityid = '';
  stateid = '';
  ooa = '';
  ob = '';
  ib = '';
  pinNumber:string='';
  pageSize: number = 10;  // Default page size
  pageNo: number = 0;     // Default page number (starting from 0)
  sortOrder: string = 'ASC'; // Default sort order
  sortOn: string = 'id'; // Default field to sort on
  filterParams = '';
  user_cities
  user_sel_pincodes
  user_sel_state

  edit = {
    IB: null,
    OB: null,
    OOA: null,

  }
  displayedColumns = ['pincode', 'city', 'state', 'country', 'InBound', 'OutBound', 'ooa', 'Active']
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  showFirstLastButtons;
  pageSizeOptions = [10, 30];
  viewchange1(val, con) {
    this.editparams.id = con.id;
    this.editparams.pincode = con.pin_number;
    this.editparams.countryid = con.city.state_id.country.id;
    this.getRecverStates();
    this.editparams.stateid = con.city.state_id.id;
    this.getReceverCities();
    // this.getCities1(id)
    this.editparams.cityid = con.city.id;
    this.editparams.AreaName = con.area_name;
    if (con.ib == true) {
      this.editparams.IB = 1;
      this.edit.IB = '1';
    } else {
      this.editparams.IB = 0;
      this.edit.IB = '0';
    }
    if (con.ob == true) {
      this.editparams.OB = 1;
      this.edit.OB = '1';
    } else {
      this.editparams.OB = 0;
      this.edit.OB = '0';
    }
    if (con.ooa == true) {
      this.editparams.OOA = 1;
      this.edit.OOA = '1';
    } else {
      this.editparams.OOA = 0;
      this.edit.OOA = '0';
    }
    this.pageview = val;
  }

  async activeorinactive() {
    if (this.edit.IB == '1') {
      this.editparams.IB = 1;
    } else {
      this.editparams.IB = 0;
    }
    if (this.edit.OB == '1') {
      this.editparams.OB = 1;
    } else {
      this.editparams.OB = 0;
    }
    if (this.edit.OOA == '1') {
      this.editparams.OOA = 1;
    } else {
      this.editparams.OOA = 0;
    }
  }


  pageview = 'table';


  states = this.getStates();
  countries = getCountries();
  cities = this.getCities();
  //  pincodes = this.getPincodes();

  states1
  cities1

  countryid = this.editparams.countryid
  statebyid = this.editparams.stateid


  async getRecverStates() {
    this.states1 = getStatesById(this.editparams.countryid);
    this.editparams.stateid = '';
    this.editparams.cityid = '';
  }

  async getReceverCities() {
    this.cities1 = getCitiesById(this.editparams.stateid);
    this.editparams.cityid = '';
  }


  PincodesList = null;
  state = null;
  city = null;
  userDetails;
  displaytoAccountant=false;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name=='Accountant') {
      this.displaytoAccountant=true;
    }
    this.getPincodes(this.pageNo, this.pageSize, this.sortOrder, this.sortOn);
  }
  viewchange(val, target) {
    this.pageview = val;
    this.editparams = {
      id: null,
      pincode: null,
      cityid: null,
      countryid: null,
      stateid: null,
      AreaName: null,
      IB: 0,
      OB: 0,
      OOA: 0,

    };
    this.edit = {
      IB: null,
      OB: null,
      OOA: null,

    };
    if (target == 'cancel') {
      this.pinexist = false;
      this.pinexist2 = false;
      this.loading = true;
      this.getPincodes(this.pageNo, this.pageSize, this.sortOrder, this.sortOn);
    }
  }

  async Createpincode() {
    this.activeorinactive();
    this.pincodeexist();
    const pin = {
      'pin_number': this.editparams.pincode,
      'is_active': true,
      'city': {id : this.editparams.cityid},
      'area_name': this.editparams.AreaName,
      'ib': this.editparams.IB,
      'ob': this.editparams.OB,
      'OOA': this.editparams.OOA,
    };
    if (this.editparams.pincode == '' || this.editparams.pincode ==null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Pincode',
        timer: 5000,
      });
    } else if (this.editparams.pincode.length != 6) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Pincode Should be in 6 digits',
        timer: 5000,


      });
    } else if (this.editparams.pincode.toString().charAt(0) === '0') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Pincode cannot start with zero',
        timer: 5000,
      });
    } else if (this.editparams.countryid == '' || this.editparams.countryid == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select Country',
        timer: 5000,

      });
    } else if (this.editparams.stateid == ''|| this.editparams.stateid == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select State',
        timer: 5000,

      });
    } else if (this.editparams.cityid == '' || this.editparams.cityid == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select City',
        timer: 5000,

      });
    } else if (this.editparams.AreaName == '' || this.editparams.AreaName == null || this.editparams.AreaName.trim().length==0) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select AreaName',
        timer: 5000,

      });
    } else if (this.pinexist == true) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Pincode Already Exists',
        timer: 5000,

      });
    } else if (this.edit.IB == '' || this.edit.IB == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please select either \'Active\' or \'Inactive\' for the IB.',
        timer: 5000,

      });
    } else if (this.edit.OB == '' || this.edit.OB == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please select either \'Active\' or \'Inactive\' for the OB.',
        timer: 5000,

      });
    } else if (this.edit.OOA == '' || this.edit.OOA == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please select either \'Active\' or \'Inactive\' for the OOA.',
        timer: 5000,

      });
    } else {
      this.httpService.post(API_URL + '/createPincodes', pin, null, null)
          .subscribe((result) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Pincode created successfully',
              timer: 5000,
            });
            this.editparams = {
              id: null,
              pincode: null,
              cityid: null,
              countryid: null,
              stateid: null,
              AreaName: null,
              IB: null,
              OB: null,
              OOA: null,
            };
            this.getPincodes(this.pageNo, this.pageSize, this.sortOrder, this.sortOn);
            this.viewchange('table', 'cancel');
          }, (error) => console.error('error', error));
    }
  }


  async activePincode(id, status) {
    const pin = {
      'is_active': status,
    };

    this.httpService.patch(API_URL + '/updatePincode/isActive/' + id, pin, null, null)
        .subscribe((result) => {
        // console.log(result)
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Pincode status changed successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.getPincodes(this.pageNo, this.pageSize, this.sortOrder, this.sortOn);
          this.viewchange('table', 'cancel');
        }, (error) => console.error('error', error));
  }


  async getStates() {
    let consignment;
    this.httpService.get(API_URL + '/states', null, null)
        .subscribe((json) => {
          this.state = json.data;
        });
  }

  async getCities() {
    const array=[];

    let consignment;
    this.httpService.get(API_URL + '/cities', null, null)
        .subscribe((json) => {
          this.city = json.data.sort((a, b) => a.name.localeCompare(b.name));
        });
  }


  async editpincode() {
    this.activeorinactive();
    const editparams = {

      'pin_number': this.editparams.pincode,
      'city': {id : this.editparams.cityid},
      'area_name': this.editparams.AreaName,
      'ib': this.editparams.IB,
      'ob': this.editparams.OB,
      'OOA': this.editparams.OOA,

    };
    if (this.editparams.pincode == '' || this.editparams.pincode == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Pincode',
        timer: 5000,

      });
    } else if (this.editparams.pincode.toString().charAt(0) === '0') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Pincode cannot start with zero',
        timer: 5000,
      });
    } else if (this.pinexist2 == true) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Pincode Already Exists',
        timer: 5000,

      });
    } else if (this.editparams.countryid == '' || this.editparams.countryid == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select Country',
        timer: 5000,

      });
    } else if (this.editparams.stateid == '' || this.editparams.stateid == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select State',
        timer: 5000,

      });
    } else if (this.editparams.cityid == '' || this.editparams.cityid == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select City',
        timer: 5000,

      });
    } else if (this.editparams.AreaName == '' || this.editparams.AreaName == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select AreaName',
        timer: 5000,

      });
    } else {
      this.httpService.patch(API_URL + '/updatePincode/' + this.editparams.id, editparams, null, null)
          .subscribe((result) => {
            // console.log(result)
            this.editparams = {
              id: null,
              pincode: null,
              cityid: null,
              countryid: null,
              stateid: null,
              AreaName: null,
              IB: null,
              OB: null,
              OOA: null,
            };
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Pincode status updated successfully',
              timer: 5000,
            });
            this.getPincodes(this.pageNo, this.pageSize, this.sortOrder, this.sortOn);
            this.viewchange('table', 'cancel');
          }, (error) => console.error('error', error));
    }
  }


  onPaginateChange(event) {
    this.pageNo = event.pageIndex; // Update the current page index
    this.pageSize = event.pageSize; // Update the current page size
    // console.log(this.pageNo);
    // Store the current pagination state in local storage
    // localStorage.setItem('pageState', JSON.stringify({ pageIndex: this.pageNo, pageSize: this.pageSize }));
     // Fetch the updated list of consignments based on the new pagination
  this.getPincodes(this.pageNo, this.pageSize, this.sortOrder, this.sortOn);
  }
  tablefilter() {
    this.getPincodes(0, this.pageSize, this.sortOrder, this.sortOn);
  }
  filter() {
    this.filterParams = '?';
    if (this.cityid != '') {
      this.filterParams = this.filterParams + 'cityid=' + this.cityid + '&';
    }
    if (this.stateid != '') {
      this.filterParams = this.filterParams + 'stateid=' + this.stateid + '&';
    }
    if (this.ooa != '') {
      this.filterParams = this.filterParams + 'ooa=' + this.ooa + '&';
    }
    if (this.ob != '') {
      this.filterParams = this.filterParams + 'ob=' + this.ob + '&';
    }
    if (this.ib != '') {
      this.filterParams = this.filterParams + 'ib=' + this.ib + '&';
    }if(this.pinNumber !=''){
      this.filterParams = this.filterParams + 'pinNumber='+this.pinNumber+'&';
    }

    // if(this.filterParams[this.filterParams.length]=="&"){
    //   this.filterParams.padEnd
    // }
    // this.filterParams= this.filterParams+"pageNo="+this.pagenation.pageNo+"&pageSize="+this.pagenation.pageSize+"&sortBy="+this.pagenation.sortBy+"&direction="+this.pagenation.direction
    return this.filterParams;
  }
  handlePageEvent(event: PageEvent) {
    // this.length = event.length;
    // this.pageSize = event.pageSize;
    // alert(event.pageIndex)
    // this.pagenation.page_size  =event.pageSizeOptions;
    this.pagenation.page_no = event.pageIndex;
    this.getPincodes(this.pageNo, this.pageSize, this.sortOrder, this.sortOn);
  }
  array=[]
  async getPincodes(pageNo, pageSize, sortOrder, sortOn) {
    this.loading = true;
    
    // console.log(API_URL)
    const api = '/pincodefilters';
    const params = this.filter()+ `&pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortOn=${sortOn}`;
    this.httpService.get(API_URL + api + params, null, null)
        .subscribe((json) => {
          if (json.status == 400) {
            this.PincodesList = [];
          } else {
            // json.data.sort((a, b) => b.id - a.id);
            this.PincodesList = json.data.content;
            this.array = Array.from(json.data.content);
            this.dataSource = new MatTableDataSource(json.data.content);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.totalItems = json.data.page.totalElements;
          }
          this.loading= false;
        });

    return this.PincodesList;
    // return loads;
  }

  async getIndividaulCities() {
    this.city = await getCitiesById(this.stateid);
    // console.log(this.stateid);
    // console.log(this.city);
  }

  business_cities
  async getBusinessCities() {
    this.business_cities = getCitiesById(this.editparams.stateid);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  // displayFn(pincode: getPincodes): String {
  //   return pincode && pincode.pinNumber;
  // }

  async reset() {
    this.stateid = '';
    this.cityid = '';
    this.ib = '';
    this.ob = '';
    this.ooa = '';
    this.pinNumber='';
    this.resetPaginationState();
    // this.getPincodes(0, this.pageSize, this.sortOrder, this.sortOn);
   
  }

  resetPaginationState() {
    localStorage.removeItem('pageState');
    this.pageNo = 0;
    this.pageSize = 10;
    this.getPincodes(this.pageNo, this.pageSize, this.sortOrder, this.sortOn);
}

  pinexist = false
  pincodeexist() {
    this.pinexist = false;
    this.array.forEach((element) => {
      if (this.editparams.pincode == element.pin_number) {
        this.pinexist=true;
      }
    });
  }

  pinexist2 = false
  pincodeexist2() {
    this.pinexist2 = false;
    this.array.forEach((element) => {
      if (this.editparams.pincode == element.pin_number) {
        this.pinexist2=true;
      }
    });
  }


  // exportToCSV() {
  //   const csvData = [];
  //   const headers = ['Pin Number', 'City', 'State', 'Country', 'Is Active', ' IB Active', 'OB Active', 'OOA Active'];
  //   csvData.push(headers);
  //   let is_Active;
  //   let ib;
  //   let ob;
  //   let ooa;
  //   this.dataSource.filteredData.forEach((row) => {
  //     if (row.is_Active == false) {
  //       is_Active = 'No';
  //     } else {
  //       is_Active ='Yes';
  //     }
  //     if (row.ib == false) {
  //       ib = 'No';
  //     } else {
  //       ib ='Yes';
  //     }
  //     if (row.ob == false) {
  //       ob = 'No';
  //     } else {
  //       ob ='Yes';
  //     }
  //     if (row.ooa == false) {
  //       ooa = 'No';
  //     } else {
  //       ooa ='Yes';
  //     }


  //     const data = [row.pinNumber, row.cityId.name, row.cityId.state_id.name, row.cityId.state_id.country.name, is_Active, ib, ob, ooa];
  //     csvData.push(data);
  //   });

  //   const csv = csvData.map((e) => e.join(',')).join('\n');
  //   const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
  //   saveAs(blob, 'PincodeDetails.csv');
  // }



  exportToCSV() {
    let headers = new HttpHeaders({
      'Accept': 'text/csv'
    });
    // Use HttpParams to pass the parameters
  let params = new HttpParams();
   // Parse the filterParams from the filter method
   const filterParamsString = this.filter(); // Call the filter method to get filter params
 // Split the filter params into key-value pairs and append to HttpParams
 if (filterParamsString) {
  const urlParams = new URLSearchParams(filterParamsString.slice(1)); // Remove the leading '?' character
  urlParams.forEach((value, key) => {
      params = params.append(key, value); // Append each parameter to HttpParams
  });
}
  
    this.httpClient.get(API_URL + "/pinCodeCSV", {

      headers: headers,
    params: params, // Pass the parameters
    responseType: 'text' // Set the response type to text
    }).subscribe(response => {
      // this.downloadCsv(response, "consignmentMSI.csv")
      this.downloadCsv(response, `pinCodeDetails_${this.getCurrentDate()}.csv`);
    }, error => {
      console.error('Error downloading CSV: ', error);
    });
  }

// Method to get the current date in YYYY-MM-DD format
getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}

  downloadCsv(csvData: string, fileName: string): void {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }



  DeletePincodeNumber(pin) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to delete ' + pin.pin_number + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.Deletedata(pin.id);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Deleted successfully',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    });
  }

  async Deletedata(id) {
    const TOKEN = this.authService.getAuthToken();
    const data = JSON.stringify({
      'is_active': 0,
    });
    try {
      await fetch(API_URL + '/pinCodeIsActive/' + id, {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: data,
      });
      this.getPincodes(this.pageNo, this.pageSize, this.sortOrder, this.sortOn); // Refresh data after deletion
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  }

}


