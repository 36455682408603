<div *ngIf="loading" class="loader-container">
  <ngx-loading [show]="loading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>

<div class="card mt-2">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
      <a><i class="fa fa-arrow-left" style="cursor: pointer" (click)="backToCostCenter()"></i></a> Edit Cost Center
    </div>
    <div class="btn-actions-pane-right p-2 ">
      <div class="mb-2 me-2 btn btn-primary" (click)="saveBillingPrices()"> Save</div>
    </div>
  </div>

  <div style="width: 90%; margin:4% 10%">

    <div class="table-responsive">
      <table class="table1" style="width:90%">
        <tr>
          <td class="left">Consignment No :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.consignment_no}}</td>
          <td class="left">Customer Name :</td>
          <td
            class="right">{{commonApi.BillingPriceLists?.consignment?.isRetailCustomer ? commonApi.BillingPriceLists?.consignment?.individual_name : commonApi.BillingPriceLists?.consignment?.customer_name}}</td>
        </tr>

        <tr>
          <td class="left">Origin :</td>
          <td
            class="right">{{commonApi.BillingPriceLists.consignment?.origin || commonApi.BillingPriceLists.consignment?.cityname }}</td>
          <td class="left">Destination :</td>
          <td
            class="right">{{commonApi.BillingPriceLists.consignment?.destination || commonApi.BillingPriceLists.consignment?.receivercity}}</td>
        </tr>

        <tr>
          <td class="left">No of packages :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.no_of_packages}}</td>
          <td class="left">Total chargeable weight :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.total_chargable_weight}} kg</td>
        </tr>
        <tr>
          <td class="left">Consignment Value :</td>
          <td
            class="right">
            INR {{commonApi.BillingPriceLists.consignment?.consignment_value?.toLocaleString('en-IN')}}</td>
        </tr>
      </table>
    </div>

    <hr style="margin-left: -11%; border: 1px solid black">

    <div>
      <div style="width: 100%">
        <div class="table-responsive">
          <table class="table" style="width:100%">
            <tr class="header">
              <th style="width: 25%; padding-left: 0.8%">Line Items</th>
              <th style="padding-left: 0.6%" class="BillUnitPriceResp">Unit Price</th>
              <th style="border-right: 1px solid black" class="BillTotalResp">Total</th>
              <th style="width: 17.3%; border-right: 1px solid black; padding-left: 5.6%">Cost Incurred</th>
              <th style="width: 23%; padding-left: 5%">Total
                <button style="color: white; border: white; border-radius: 7px; margin-left: 15%"
                        (click)="addDropdown()" [ngStyle]="{'background-color': addColour()}" [disabled]="count2>limit">
                  <i class="fa fa-plus"
                     aria-hidden="true"
                  ></i></button>
              </th>
            </tr>

            <tr style="vertical-align: top"
                *ngFor="let selectedItem of duplicateSelectedBillingLineItems; let i = index">
              <ng-container
                *ngIf="duplicateSelectedBillingLineItems[i].billPrice !=0 || duplicateSelectedBillingLineItems[i].costPrice != 0">
                <td>
                  <mat-form-field appearance="legacy" style="margin-top: -2.2%">
                    <mat-select [(ngModel)]="duplicateSelectedBillingLineItems[i].name">
                      <mat-option *ngFor="let item1 of getAvailableOptions(i)" [value]="item1.name">
                        {{ item1.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>

                <td>
                  <mat-form-field appearance="legacy" style="width: 53%; margin-top: -2%">
                    <span matPrefix style="font-size: 14px">INR&nbsp;</span>
                    <input matInput type="number" min="0" onkeypress="if (this.value.length > 7) return false;"
                           [(ngModel)]="duplicateSelectedBillingLineItems[i].billPrice"
                           (ngModelChange)="onPriceChange()" (keypress)="validateInput($event)"
                           (focus)="clearBillUnitPriceZero(i)">
                  </mat-form-field>
                </td>
                <td style="border-right: 1px solid black"></td>
                <td style="border-right: 1px solid black; padding-left: 5%">
                  <mat-form-field appearance="legacy" style="width: 70%; margin-top: -2%">
                    <span matPrefix style="font-size: 14px">INR&nbsp;</span>
                    <input matInput type="number" min="0" onkeypress="if (this.value.length > 7) return false;"
                           [(ngModel)]="duplicateSelectedBillingLineItems[i].costPrice"
                           (ngModelChange)="onPriceChange()"
                           (keypress)="validateInput($event)" (focus)="clearCostUnitPriceZero(i)">
                  </mat-form-field>
                  <i class="fa fa-trash fa-lg" style="color: red; height: 50px; cursor:pointer;" aria-hidden="true"
                     (click)="removeLineItem(i)"></i>
                </td>
              </ng-container>
            </tr>

            <!--            ************************************-->

            <tr height="25px">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td style="display: flex; flex-direction: column">
                <b class="BillSubTotal1Resp">Sub Total 1</b><br>
                &nbsp;&nbsp;<span style="margin-top: -3.6%">Discount Amount</span>
              </td>
              <td class="disc">
                <div style="width: 50%" class="BillDiscRep">
                  <hr style="margin-top: 0; margin-bottom: 0">
                  <mat-form-field appearance="legacy" style="width: 100%; color: #0ba360">
                    <span matPrefix>-INR&nbsp;</span>
                    <input matInput [(ngModel)]="commonApi.BillingPriceLists.discountedAmount"
                           (ngModelChange)="onPriceChange()" type="number" (keypress)="validateInput($event)" min="0" onkeypress="if (this.value.length > 7) return false;"
                           (focus)="clearBillDiscountZero()">
                  </mat-form-field>
                </div>
              </td>
              <td style="border-right: 1px solid black" class="BillSub1Resp">
                INR {{subTotal1?.toLocaleString('en-IN')}}</td>
              <td style="border-right: 1px solid black; padding-left: 5%; color: #0ba360">
                <mat-form-field appearance="legacy" style="width: 70%; margin-top: -2%">
                  <span matPrefix>-INR&nbsp;</span>
                  <input matInput [(ngModel)]="commonApi.CostCenterPricesList.discountedAmount"
                         (ngModelChange)="onPriceChange()" type="number" (keypress)="validateInput($event)" min="0" onkeypress="if (this.value.length > 7) return false;"
                         (focus)="clearCostDiscountZero()">
                </mat-form-field>
              </td>
              <td style="padding-left: 5%">
                <div class="CostSub1Resp">
                  INR {{costSubTotal1?.toLocaleString('en-IN')}}
                </div>
              </td>
            </tr>

            <tr>
              <td style="display: flex; flex-direction: column">
                <b class="BillSubTotal2Resp">Sub Total 2</b>
                &nbsp;&nbsp;<span>Insurance (FOV)</span>
              </td>
              <td>
                <div style="width: 50%" class="BillInsResp">
                  <hr style="margin-top: 0; margin-bottom: 0">
                  <span style="display: flex; flex-direction: row">
                    <span>
                      <mat-form-field style="width: 50px; margin-right: 5px">
<!--                        <mat-label>fov</mat-label>-->
                        <mat-select [(value)] = "selected" (selectionChange)="commonApi.onFovOptionChange($event)">
                          <mat-option value="0"> % </mat-option>
                          <mat-option value="1"> INR </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </span>
                    <span>
                      <mat-form-field appearance="legacy" style="width: 111px; margin-left: 4%">
                        <span matPrefix>+&nbsp;</span>
                        <span matSuffix *ngIf="commonApi.isSuffix">%</span>
                        <span matPrefix *ngIf="!commonApi.isSuffix">INR</span>
                        <input *ngIf="commonApi.isSuffix" matInput
                               [(ngModel)]="commonApi.BillingPriceLists.insurancePercentage"
                               (ngModelChange)="onPriceChange()" type="text" pattern="[0-9]+([\.,][0-9]+)?"
                               (keypress)="validateInput($event)" min="0"
                               (focus)="clearBillInsuranceZero()">

                        <input *ngIf="!commonApi.isSuffix" matInput [(ngModel)]="commonApi.BillingPriceLists.insurance"
                               (ngModelChange)="onPriceChange()" type="text" pattern="[0-9]+([\.,][0-9]+)?" maxlength="8"
                               (keypress)="validateInput($event)" min="0"
                               (focus)="clearBillInsuranceValueZero()">
                      </mat-form-field>
                    </span>
<!--                    <span style="margin-top: auto; margin-left: -25px" *ngIf="!commonApi.isSuffix"> ({{commonApi.BillInsurancePercentage?.toLocaleString('en-IN')}}%) </span>-->
                  </span>
                </div>
              </td>
              <td style="border-right: 1px solid black; padding-bottom: 2.5%">
                <div style="padding-left: 0" class="BillSubTotal2ValueResp">
                  INR {{subTotal2?.toLocaleString('en-IN')}} <br><br>
                </div>
                <div style="margin-left: -9%">
                  +INR {{BillInsuranceAmount?.toLocaleString('en-IN')}}
                </div>
              </td>
              <td style="border-right: 1px solid black; padding-left: 5.2%">
                <mat-form-field appearance="legacy" style="width: 123px; margin-top: -2%">
                  <span matPrefix>+&nbsp;</span>
                  <span matSuffix *ngIf="commonApi.isSuffix">%</span>
                  <span matPrefix *ngIf="!commonApi.isSuffix">INR</span>
                  <input *ngIf="commonApi.isSuffix" matInput [(ngModel)]="commonApi.CostCenterPricesList.insurancePercentage"
                         (ngModelChange)="onPriceChange()" type="text" pattern="[0-9]+([\.,][0-9]+)?"
                         (keypress)="validateInput($event)" min="0"
                         (focus)="clearCostInsuranceZero()">

                  <input *ngIf="!commonApi.isSuffix" matInput [(ngModel)]="commonApi.CostCenterPricesList.insurance"
                         (ngModelChange)="onPriceChange()" type="text" pattern="[0-9]+([\.,][0-9]+)?" maxlength="8"
                         (keypress)="validateInput($event)" min="0"
                         (focus)="clearCostInsuranceValueZero()">
                </mat-form-field>
<!--                <span style="margin-top: auto; margin-left: -25px" *ngIf="!commonApi.isSuffix"> ({{commonApi.CostInsurancePercentage?.toLocaleString('en-IN')}}%) </span>-->
              </td>
              <td style="padding-left: 5%">
                <div class="CostSub2Resp">
                  INR {{costSubTotal2?.toLocaleString('en-IN')}}
                </div>
                +INR {{CostInsuranceAmount?.toLocaleString('en-IN')}}
              </td>
            </tr>

            <tr>
              <td style="display: flex; flex-direction: column">
                <b class="BillTaxAmountResp">Taxable Amount</b>
                &nbsp;&nbsp;<span> Tax% ({{commonApi.BillingPriceLists?.isGst}}) </span>
              </td>
              <td>
                <div style="width: 50%">
                  <hr style="margin-bottom: 0" class="BillTaxResp">
                  <!--                  <mat-form-field appearance="legacy" style="width: 78px; margin-left: 4%">-->
                  <!--                    <span matSuffix>%</span>-->
                  <!--                    <span matPrefix>+</span>-->
                  <!--                    <input matInput [(ngModel)]="commonApi.BillingPriceLists.taxablePercentage"-->
                  <!--                           (ngModelChange)="onPriceChange()" type="text" pattern="[0-9]+([\.,][0-9]+)?" maxlength="5" (keypress)="validateInput($event)" min="0"-->
                  <!--                           (focus)="clearBillTaxZero()">-->
                  <!--                  </mat-form-field>-->
                  <div style="margin-top: 15%; margin-left: 33%">
                    {{BillTax}} %
                  </div>
                </div>
              </td>
              <td style="border-right: 1px solid black; padding-left: 0">
                <div style="padding-left: 0" class="BillTaxAmountValueResp">
                  INR {{BillTaxableAmount?.toLocaleString('en-IN')}}
                </div>
                <div style="margin-left: -9%">
                  +INR {{BillTaxAmount?.toLocaleString('en-IN')}}
                </div>
              </td>
              <td style="border-right: 1px solid black; padding-left: 5.2%">
                <!--                <mat-form-field appearance="legacy" style="width: 95px" class="CostTaxPercResp">-->
                <!--                  <span matSuffix>%</span>-->
                <!--                  <span matPrefix>+</span>-->
                <!--                  <input matInput [(ngModel)]="commonApi.CostCenterPricesList.taxablePercentage"-->
                <!--                         (ngModelChange)="onPriceChange()" type="text" pattern="[0-9]+([\.,][0-9]+)?" maxlength="5"-->
                <!--                         (keypress)="validateInput($event)" (focus)="clearCostTaxZero()">-->
                <!--                </mat-form-field>-->
                <div style="margin-top: 12%; margin-left: 16%">
                  {{CostTax}} %
                </div>
              </td>
              <td style="padding-left: 5%">
                <div class="CostTaxableResp">
                  INR {{CostTaxableAmount?.toLocaleString('en-IN')}}
                </div>
                +INR {{CostTaxAmount?.toLocaleString('en-IN')}}
              </td>

            </tr>

            <tr>
              <td><h3>Grand Total</h3></td>
              <td></td>
              <td style="border-right: 1px solid black; padding-left: 0">
                <h4 style="margin-left: -18%;">
                  INR {{BillGrandTotal?.toLocaleString('en-IN')}}</h4>
              </td>
              <td style="border-right: 1px solid black"></td>
              <td>
                <h4 class="CostGrandTotalResp">
                  INR {{CostGrandTotal?.toLocaleString('en-IN')}}</h4>
              </td>
            </tr>

          </table>
        </div>

      </div>

      <div style="display: flex; flex-direction: row">
        <div>
          <mat-form-field appearance="outline" style="width: 400px">
            <mat-label>Remarks</mat-label>
            <textarea matInput [(ngModel)]="commonApi.CostCenterPricesList.remarks" #input style="height: 80px"
                      maxlength="250"></textarea>
            <mat-hint align="end">{{input.value.length}}/250</mat-hint>
          </mat-form-field>
        </div>

        <div style="margin-top: 2%" class="TotalDiffResp">
          On this shipment, a {{ProfitOrLoss}} of <b [ngStyle]="{'color': changeColor}">
          INR {{calculateDifference().toLocaleString('en-IN')}} ({{ProfitOrLossPercentage}}%)</b>
        </div>
      </div>

    </div>

  </div>

  <div class="btn-actions-pane-right p-2" style="margin-top: -2%; margin-bottom: 2%; margin-right: 2%">
    <div class="mb-2 me-2 btn" style="background-color: red; color: white" (click)="backToCostCenter()"> Discard</div>
    <div class="mb-2 me-2 btn btn-primary" (click)="saveBillingPrices()"> Save</div>
  </div>
</div>
