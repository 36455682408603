import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import {API_URL} from '../../../../../Global_API_URL';
import {AuthService} from '../../../auth.service';
import {HttpService} from '../../../services/http.service';


@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.sass'],
})
export class UpdatePasswordComponent implements OnInit {
  passwordFlag = true;
  newpasswordFlag=false;
  retypePassword='';
  password=''
  newpassword=''


  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };

  constructor(private authService : AuthService, private httpService : HttpService) {
    this.authService.getAuthToken();
  }

  ngOnInit(): void {
  }
  checkOldNewPassword() {
    this.newpasswordFlag = this.password == this.newpassword;
  }

  passLenCheck = false;
  checkPassword() {
    this.passwordFlag = this.newpassword == this.retypePassword;
    this.passLenCheck = this.newpassword?.length < 6;
  }

  async updatepassword() {
    // console.log(this.password);
    if (this.password == null || this.password == '' || this.password == undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Please Enter Old password',
        timer: 5000,
      });
    } else if (this.newpassword==null || this.newpassword == '' || this.newpassword == undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Please Enter New password',
        timer: 5000,
      });
    } else if (this.newpassword?.length < 6) {
      Swal.fire({
        icon: 'warning',
        title: 'Password should have a minimum of 6 characters',
        timer: 5000,
      });
    } else if (this.retypePassword == null || this.retypePassword == '' || this.retypePassword == undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Please Enter Confirm password',
        timer: 5000,
      });
    } else if (this.newpasswordFlag) {
      Swal.fire({
        icon: 'warning',
        title: 'New Password and Old Password should not match',
        timer: 5000,
      });
    } else if (!this.passwordFlag) {
      Swal.fire({
        icon: 'warning',
        title: 'New Password and Confirm Password should match',
        timer: 5000,
      });
    } else {
      const TOKEN = this.authService.getAuthToken();
      const formdata = new FormData();
      formdata.append('password', this.password);
      formdata.append('newpassword', this.retypePassword);

      await fetch(API_URL + '/change-password', {
        method: 'POST',
        body: formdata,
        headers: {
          'Authorization': 'Bearer ' + TOKEN,
          'Accept': 'application/json',
        },
      })
          .then((response) => {
            if (response.ok) {
            // Password changed successfully
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Password Changed successfully',
                timer: 3000,
              }).then(()=>{
                this.Cancel();
              });
              // window.location.replace("/");
            } else {
            // Incorrect old password
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Incorrect old password',
                timer: 3000,
              });
              this.password=null;
              this.newpassword=null;
              this.retypePassword=null;
            }
          })
          .catch((error) => console.error('Error:', error));
    }
  }

  Cancel() {
    window.location.replace('/quickstart');
  }
}

