<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHoverMouseIn()"
     (mouseout)="sidebarHoverMouseOut()">
  <div class="app-header__logo">
    <img src="./assets/images/icl-logo.png" style="width: 180px; height: 55px;" />
    <div class="header__pane ms-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
              [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar();">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true" class="scroll-container" [config]="config" [scrollIndicators]="true"
                         style="max-width: 450px; max-height: 90vh;">
        <container-element [ngSwitch]="role">
          <some-element *ngSwitchCase="'Pickup Delivery Agent'">
            <div class="v-sidebar-menu vsm-default">
              <div class="vsm-list">
                <div class="vsm-header">MENU</div>
                <div class="vsm-item">
                  <a routerLink="quickstart" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-clock-o"></i>
                    <span class="vsm-title">Quickstart</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="trackingview" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-map-marker"></i>
                    <span class="vsm-title">Track Now</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="consignment" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-file-text"></i>
                    <span class="vsm-title">Bookings</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="customers" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-address-card"></i>
                    <span class="vsm-title">Corporate Customer</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="representative" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-address-book"></i>
                    <span class="vsm-title">Corporate Representative</span>
                  </a>
                </div>
                <!-- <div class="vsm-item">
                  <a routerLink="retailCustomers" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-address-book"></i>
                    <span class="vsm-title">Retail Customer</span>
                  </a>
                </div> -->
              </div>
            </div>
          </some-element>
          <some-element *ngSwitchCase="'Processing Agent'">
            <div class="v-sidebar-menu vsm-default">
              <div class="vsm-list">
                <div class="vsm-header">MENU</div>
                <div class="vsm-item">
                  <a routerLink="quickstart" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-clock-o"></i>
                    <span class="vsm-title">Quickstart</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="trackingview" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-map-marker"></i>
                    <span class="vsm-title">Track Now</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="consignment" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-file-text"></i>
                    <span class="vsm-title">Bookings</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="manifestation" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-delicious"></i>
                    <span class="vsm-title">Manifestation</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="customers" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-address-card"></i>
                    <span class="vsm-title">Corporate Customer</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="representative" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-address-book"></i>
                    <span class="vsm-title">Corporate Representative</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="retailCustomers" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-address-book"></i>
                    <span class="vsm-title">Retail Customer</span>
                  </a>
                </div>
              </div>
            </div>
          </some-element>
          <some-element *ngSwitchCase="'Destination Agent'">
            <div class="v-sidebar-menu vsm-default">
              <div class="vsm-list">
                <div class="vsm-header">MENU</div>
                <div class="vsm-item">
                  <a routerLink="quickstart" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-clock-o"></i>
                    <span class="vsm-title">Quickstart</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="trackingview" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-map-marker"></i>
                    <span class="vsm-title">Track Now</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="consignment" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-file-text"></i>
                    <span class="vsm-title">Bookings</span>
                  </a>
                </div>
                <div class="vsm-header">Admin Components</div>
                <div class="vsm-item">
                  <a routerLink="customers" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Business Customer</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="retailCustomers" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Representative & Individual Customer</span>
                  </a>
                </div>
              </div>
            </div>
          </some-element>
          <some-element *ngSwitchCase="'Admin'">
            <div class="v-sidebar-menu vsm-default">
              <div class="vsm-list">
                <div class="vsm-header">MENU</div>
                <div class="vsm-item">
                  <a routerLink="quickstart" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-clock-o"></i>
                    <span class="vsm-title">Quickstart</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="trackingview" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-map-marker"></i>
                    <span class="vsm-title">Track Now</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="consignment"  routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-file-text"></i>
                    <span class="vsm-title">Bookings</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="manifestation" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-delicious"></i>
                    <span class="vsm-title">Manifestation</span>
                  </a>
                </div>
                <!-- <div class="vsm-item">
                  <a routerLink="paperWork" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-file-word-o"></i>
                    <span class="vsm-title">Paper Works</span>
                  </a>
                </div> -->
                <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center" (click)="toggleDropdownFileSection()">
                    <i class="vsm-icon fa fa-file-word-o" ></i>
                    <span class="vsm-title" >File Section</span>
                    <i class="vsm-icon fa fa-fw"
                       [ngClass]="{'fa-caret-down': showDropdownFileSection, 'fa-caret-right': !showDropdownFileSection}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showDropdownFileSection">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="paperWork" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Paper Works</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="fileFormats" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">CSV Files</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vsm-item">
                  <a routerLink="billingAndCostUpload" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-hourglass-half"></i>
                    <span class="vsm-title">Bulk Upload - Billing & Cost</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center" (click)="toggleDropdownInvoicing()">
                    <i class="vsm-icon fa fa-fw fa-file-code-o" ></i>
                    <span class="vsm-title" >Invoicing</span>
                    <i class="vsm-icon fa fa-fw"
                       [ngClass]="{'fa-caret-down': showDropdownInvoicing, 'fa-caret-right': !showDropdownInvoicing}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showDropdownInvoicing">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="createinvoice" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Create Invoice</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="invoicehistory" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Invoice History</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vsm-item">
                  <a routerLink="consignmentUpload" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-map-marker"></i>
                    <span class="vsm-title">Booking - Bulk Upload</span>
                  </a>
                </div>
                <div class="vsm-header">Admin Components</div>

                <div class="vsm-item">
                  <a routerLink="usermanagement" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-user"></i>
                    <span class="vsm-title">User Management</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center" (click)="toggleDropdown()">
                    <i class="vsm-icon fa fa-fw fa-users" ></i>
                    <span class="vsm-title" >Customers</span>
                    <i class="vsm-icon fa fa-fw"
                       [ngClass]="{'fa-caret-down': showDropdown, 'fa-caret-right': !showDropdown}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showDropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="customers" routerLinkActive="active-item" class="vsm-link">

                          <span class="vsm-title">Corporate Customer</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="representative" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Corporate Representative</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="retailCustomers" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Retail Customer</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="contracts" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Customer Contracts</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center" (click)="toggleDropdowncontrolpannel()">
                    <i class="vsm-icon fa fa-fw fa-hourglass-half" ></i>
                    <span class="vsm-title" >Control Panel</span>
                    <i class="vsm-icon fa fa-fw"
                       [ngClass]="{'fa-caret-down': showDropdowncontrolpannel, 'fa-caret-right': !showDropdowncontrolpannel}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showDropdowncontrolpannel">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="controlpanel" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Lead Lag Times</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="operationaltimings" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Operational Timings</span>
                        </a>
                      </div>
<!--                      <div class="vsm-item">-->
<!--                        <a routerLink="app-product-type" routerLinkActive="active-item" class="vsm-link">-->
<!--                          <span class="vsm-title">Product Types</span>-->
<!--                        </a>-->
<!--                      </div>-->
                    </div>
                  </div>
                </div>

                <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center" (click)="toggleDropdownConsignmentTypes()">
                    <i class="vsm-icon fa fa-fw fa-hourglass-half" ></i>
                    <span class="vsm-title" >CN Types/Subtypes</span>
                    <i class="vsm-icon fa fa-fw"
                       [ngClass]="{'fa-caret-down': showConsignmentTypecontrolpannel, 'fa-caret-right': !showConsignmentTypecontrolpannel}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showConsignmentTypecontrolpannel">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="productTypes" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Products</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="consignment-types" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Consignment Types</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="consignment-subtypes" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Consignment Subtypes</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="linking" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Linking</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center"  (click)="toggleDropdownAirway()">
                    <i class="vsm-icon fa fa-fw fa-plane" ></i>
                    <span class="vsm-title">Airway Details</span>
                    <i class="vsm-icon fa fa-fw"
                       [ngClass]="{'fa-caret-down': showDropdownAirway, 'fa-caret-right': !showDropdownAirway}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showDropdownAirway">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="airports" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Airports</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="airlines" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Airlines</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="airlinePricing" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Airline Pricing</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="airlinePromotions" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Airline Promotions</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="vsm-item">
                  <a routerLink="doorpricing" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-inr"></i>
                    <span class="vsm-title">Door Pricing</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="officelocations" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-building"></i>
                    <span class="vsm-title">Branches</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center" (click)="toggleDropdownLocations()">
                    <i class="vsm-icon fa fa-fw fa-globe" ></i>
                    <span class="vsm-title" >Locations</span>
                    <i class="vsm-icon fa fa-fw"
                       [ngClass]="{'fa-caret-down': showDropdownLocations, 'fa-caret-right': !showDropdownLocations}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showDropdownLocations">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="countries" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Country</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="states" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">State</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="cities" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">City</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="pincodes" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Pincode</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="regions" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Region</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </some-element>
          <some-element *ngSwitchCase="'Supervisor'">
            <div class="v-sidebar-menu vsm-default">
              <div class="vsm-list">
                <div class="vsm-header">MENU</div>
                <div class="vsm-item">
                  <a routerLink="quickstart" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-clock-o"></i>
                    <span class="vsm-title">Quickstart</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="trackingview" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-map-marker"></i>
                    <span class="vsm-title">Track Now</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="consignment" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-file-text"></i>
                    <span class="vsm-title">Bookings</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="manifestation" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-delicious"></i>
                    <span class="vsm-title">Manifestation</span>
                  </a>
                </div>
<!--                <div class="vsm-item">-->
<!--                  <a class="vsm-link d-flex justify-content-between align-items-center" (click)="toggleDropdownInvoicing()">-->
<!--                    <i class="vsm-icon fa fa-fw fa-users" ></i>-->
<!--                    <span class="vsm-title" >Invoicing</span>-->
<!--                    <i class="vsm-icon fa fa-fw"-->
<!--                       [ngClass]="{'fa-caret-down': showDropdownInvoicing, 'fa-caret-right': !showDropdownInvoicing}" style="position: initial; margin-top: 2px;"></i>-->
<!--                  </a>-->

<!--                  <div class="vsm-dropdown" *ngIf="showDropdownInvoicing">-->
<!--                    <div class="vsm-list">-->
<!--                      <div class="vsm-item">-->
<!--                        <a routerLink="createinvoice" routerLinkActive="active-item" class="vsm-link">-->
<!--                          <span class="vsm-title">Create Invoice</span>-->
<!--                        </a>-->
<!--                      </div>-->
<!--                      <div class="vsm-item">-->
<!--                        <a routerLink="invoicehistory" routerLinkActive="active-item" class="vsm-link">-->
<!--                          <span class="vsm-title">Invoice History</span>-->
<!--                        </a>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
                <div class="vsm-header">Admin Components</div>

                <!-- <div class="vsm-item">
                  <a routerLink="usermanagement" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-user"></i>
                    <span class="vsm-title">User Management</span>
                  </a>
                </div> -->
                <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center" (click)="toggleDropdown()">
                    <i class="vsm-icon fa fa-fw fa-users" ></i>
                    <span class="vsm-title" >Customers</span>
                    <i class="vsm-icon fa fa-fw"
                       [ngClass]="{'fa-caret-down': showDropdown, 'fa-caret-right': !showDropdown}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showDropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="customers" routerLinkActive="active-item" class="vsm-link">

                          <span class="vsm-title">Corporate Customer</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="representative" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Corporate Representative</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="retailCustomers" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Retail Customer</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center" (click)="toggleDropdowncontrolpannel()">
                    <i class="vsm-icon fa fa-fw fa-hourglass-half" ></i>
                    <span class="vsm-title" >Control Panel</span>
                    <i class="vsm-icon fa fa-fw"
                      [ngClass]="{'fa-caret-down': showDropdowncontrolpannel, 'fa-caret-right': !showDropdowncontrolpannel}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showDropdowncontrolpannel">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="controlpanel" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Lead Lag Times</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="operationaltimings" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Operational Timings</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center"  (click)="toggleDropdownAirway()">
                    <i class="vsm-icon fa fa-fw fa-plane" ></i>
                    <span class="vsm-title">Airway Details</span>
                    <i class="vsm-icon fa fa-fw"
                       [ngClass]="{'fa-caret-down': showDropdownAirway, 'fa-caret-right': !showDropdownAirway}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showDropdownAirway">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="airports" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Airports</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="airlines" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Airlines</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="airlinePricing" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Airline Pricing</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>


                <!-- <div class="vsm-item">
                  <a routerLink="doorpricing" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-inr"></i>
                    <span class="vsm-title">Door Pricing</span>
                  </a>
                </div> -->
                <!-- <div class="vsm-item">
                  <a routerLink="officelocations" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-building"></i>
                    <span class="vsm-title">Branches</span>
                  </a>
                </div> -->
                <!-- <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center" (click)="toggleDropdownLocations()">
                    <i class="vsm-icon fa fa-fw fa-globe" ></i>
                    <span class="vsm-title" >Locations</span>
                    <i class="vsm-icon fa fa-fw"
                      [ngClass]="{'fa-caret-down': showDropdownLocations, 'fa-caret-right': !showDropdownLocations}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showDropdownLocations">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="countries" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Country</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="states" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">State</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="cities" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">City</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="pincodes" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Pincode</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </some-element>
          <some-element *ngSwitchCase="'Customer'">
            <div class="v-sidebar-menu vsm-default">
              <div class="vsm-list">
                <div class="vsm-header">MENU</div>
                <div class="vsm-item">
                  <a routerLink="quickstart" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-clock-o"></i>
                    <span class="vsm-title">Quickstart</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="trackingview" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-map-marker"></i>
                    <span class="vsm-title">Track Now</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="consignmentTable" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-file-text"></i>
                    <span class="vsm-title">Bookings</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="paperWork" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-file-word-o"></i>
                    <span class="vsm-title">Paper Works</span>
                  </a>
                </div>
              </div>
            </div>
          </some-element>
          <some-element *ngSwitchCase="'Accountant'">
            <div class="v-sidebar-menu vsm-default">
              <div class="vsm-list">
                <div class="vsm-header">MENU</div>
                <div class="vsm-item">
                  <a routerLink="quickstart" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-clock-o"></i>
                    <span class="vsm-title">Quickstart</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="trackingview" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-map-marker"></i>
                    <span class="vsm-title">Track Now</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="consignment"  routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-file-text"></i>
                    <span class="vsm-title">Bookings</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="manifestation" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-delicious"></i>
                    <span class="vsm-title">Manifestation</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center" (click)="toggleDropdownInvoicing()">
                    <i class="vsm-icon fa fa-fw fa-file-code-o" ></i>
                    <span class="vsm-title" >Invoicing</span>
                    <i class="vsm-icon fa fa-fw"
                       [ngClass]="{'fa-caret-down': showDropdownInvoicing, 'fa-caret-right': !showDropdownInvoicing}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showDropdownInvoicing">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="createinvoice" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Create Invoice</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="invoicehistory" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Invoice History</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vsm-header">Accountant Components</div>


                <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center" (click)="toggleDropdown()">
                    <i class="vsm-icon fa fa-fw fa-users" ></i>
                    <span class="vsm-title" >Customers</span>
                    <i class="vsm-icon fa fa-fw"
                       [ngClass]="{'fa-caret-down': showDropdown, 'fa-caret-right': !showDropdown}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showDropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="customers" routerLinkActive="active-item" class="vsm-link">

                          <span class="vsm-title">Corporate Customer</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="representative" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Corporate Representative</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="retailCustomers" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Retail Customer</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center" (click)="toggleDropdownConsignmentTypes()">
                    <i class="vsm-icon fa fa-fw fa-hourglass-half" ></i>
                    <span class="vsm-title" >CN Types/Subtypes</span>
                    <i class="vsm-icon fa fa-fw"
                       [ngClass]="{'fa-caret-down': showConsignmentTypecontrolpannel, 'fa-caret-right': !showConsignmentTypecontrolpannel}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showConsignmentTypecontrolpannel">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="productTypes" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Products</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="consignment-types" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Consignment Types</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="consignment-subtypes" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Consignment Subtypes</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="linking" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Linking</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center"  (click)="toggleDropdownAirway()">
                    <i class="vsm-icon fa fa-fw fa-plane" ></i>
                    <span class="vsm-title">Airway Details</span>
                    <i class="vsm-icon fa fa-fw"
                       [ngClass]="{'fa-caret-down': showDropdownAirway, 'fa-caret-right': !showDropdownAirway}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showDropdownAirway">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="airports" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Airports</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="airlines" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Airlines</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="airlinePricing" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Airline Pricing</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="vsm-item">
                  <a routerLink="doorpricing" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-inr"></i>
                    <span class="vsm-title">Door Pricing</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="officelocations" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon fa fa-fw fa-building"></i>
                    <span class="vsm-title">Branches</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a class="vsm-link d-flex justify-content-between align-items-center" (click)="toggleDropdownLocations()">
                    <i class="vsm-icon fa fa-fw fa-globe" ></i>
                    <span class="vsm-title" >Locations</span>
                    <i class="vsm-icon fa fa-fw"
                       [ngClass]="{'fa-caret-down': showDropdownLocations, 'fa-caret-right': !showDropdownLocations}" style="position: initial; margin-top: 2px;"></i>
                  </a>

                  <div class="vsm-dropdown" *ngIf="showDropdownLocations">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="countries" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Country</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="states" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">State</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="cities" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">City</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="pincodes" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Pincode</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="regions" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Region</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                </div></div>
              </some-element>
        </container-element>
      </perfect-scrollbar>
    </div>
  </div>
</div>
