import {Component, OnInit} from '@angular/core';
import {getCities, getCountries, getStates} from '../../getCities.js';
import {API_URL} from '../../../../../Global_API_URL';
import {getAirlines, getAirports} from '../../getAirportAirlines.js';
import {AuthService} from '../../../auth.service';
import {HttpService} from '../../../services/http.service';

@Component({
  selector: 'app-flight-schedule',
  templateUrl: './flight-schedule.component.html',
  styleUrls: ['./flight-schedule.component.sass'],
})
export class FlightScheduleComponent implements OnInit {
  constructor(private authService : AuthService, private httpService: HttpService) {
    this.authService.getAuthToken();
  }

  pageview = 'table';
  airlines = getAirlines();
  states = getStates();
  countries = getCountries();
  cities = getCities();
  airports = getAirports();
  flightSchedules = this.getFlightSch();

  newFlightSch = {
    arrivalTime: null,
    departureTime: null,
    arrivalAirportID: null,
    departureAirportID: null,
    airlineID: null,
    flightNo: null,
    isActive: null,
    transitTime: null,
  }
  ngOnInit(): void {
  }
  viewchange(val) {
    this.pageview = val;
  }

  async createFlightSch() {
    this.newFlightSch.arrivalTime= this.getTime(this.newFlightSch.arrivalTime);
    this.newFlightSch.departureTime= this.getTime(this.newFlightSch.departureTime);
    this.httpService.post(API_URL + '/flight_schedules', this.newFlightSch, null, null)
        .subscribe((result) => {
          // console.log(result);
          this.flightSchedules=this.getFlightSch();
          this.viewchange('table');
        }, (error) => {
          console.error('Error', error);
        });
  }

  getTime(time_string) {
    const ardate:any = new Date('01-Jan-1970 '+time_string+':00').getTime();
    const newdate = new Date();
    newdate.setTime(ardate);
    return (newdate);
  }

  async getFlightSch() {
    let flightsh: any = [];
    this.httpService.get(API_URL + '/flight_schedules', null, null)
        .subscribe((json) => {
          flightsh = json;
        });
    return flightsh.data;
  }
}
