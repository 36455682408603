<!-- Loading Spinner Component -->
<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px', animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: '#3f6ad8', secondaryColour: '#ccc' }"
></ngx-loading>

<!-- Material Card for Consignment Subtypes -->
<mat-card style="width: 100%;">

  <!-- Title and Create Button -->
  <b class="card-header-title font-size-lg " style="color: blue;">CONSIGNMENT SUBTYPES</b>
  <div style="float: right" *ngIf="!displaytoAccountant"><button mat-raised-button color="primary" style="float: right" (click)="createNewConsignmentSubType()">Create</button></div>

  <!-- Consignment Subtypes Table -->
  <table mat-table [dataSource]="consignmentSubtypes" style="margin-top: 1rem; width: 100%;" class="mat-elevation-z8">

    <!-- Name Column -->
    <ng-container matColumnDef="Name">
      <th style="padding-right: 1rem" mat-header-cell *matHeaderCellDef>Name</th>
      <td style="padding-right: 1rem" mat-cell *matCellDef="let element">{{element?.name}}</td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="Description">
      <th style="padding-right: 1rem" mat-header-cell *matHeaderCellDef>Description</th>
      <td style="padding-right: 1rem" mat-cell *matCellDef="let element">{{element?.description}}</td>
    </ng-container>

<!--    &lt;!&ndash; Consignment Type Column &ndash;&gt;-->
<!--    <ng-container matColumnDef="ConsignmentType">-->
<!--      <th style="padding-right: 1rem" mat-header-cell *matHeaderCellDef>Consignment Type</th>-->
<!--      <td style="padding-right: 1rem" mat-cell *matCellDef="let element">{{element?.consignmentType?.name}}</td>-->
<!--    </ng-container>-->

    <!-- Created Column -->
    <ng-container matColumnDef="Created">
      <th style="padding-right: 1rem" mat-header-cell *matHeaderCellDef>Created At</th>
      <td style="padding-right: 1rem" mat-cell *matCellDef="let element">{{element?.createdAt | date:'dd-MM-yyyy HH:mm:ss'}}</td>
    </ng-container>

    <!-- Updated Column -->
    <ng-container matColumnDef="Updated">
      <th style="padding-right: 1rem" mat-header-cell *matHeaderCellDef>Updated At</th>
      <td style="padding-right: 1rem" mat-cell *matCellDef="let element">{{element?.updatedAt | date:'dd-MM-yyyy HH:mm:ss'}}</td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef [style.display]="displaytoAccountant ? 'none' : 'table-cell'">Actions</th>
      <td mat-cell *matCellDef="let element">
        <!-- Edit Button -->
        <button class="btn-shadow btn btn-primary" matTooltip="Edit" *ngIf="element.isActive && !displaytoAccountant" (click)="patchConsignmentSubType(element)"><i class="lnr-pencil"></i></button>
        <!-- Deactivate Toggle Button -->
        <button class="btn btn-shadow btn-danger" style="margin-left: 1rem;" *ngIf="element.isActive && !displaytoAccountant" matTooltip="Deactivate" (click)="deactivateConsignmentSubType(element)"><i class="pe-7s-trash"></i></button>
        <button class="btn btn-shadow" style="margin-left: 1rem; background: #0ba360; color: whitesmoke; " *ngIf="!element.isActive && !displaytoAccountant" matTooltip="Restore" (click)="deactivateConsignmentSubType(element)"><i class="fa fa-undo"></i></button>
      </td>
    </ng-container>

    <!-- Header and Row Definitions -->
    <tr mat-header-row *matHeaderRowDef="ConsignmentSubtypeTableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: ConsignmentSubtypeTableColumns; let i = index"></tr>

  </table>
</mat-card>
