<div class="col-md-12">
  <container-element [ngSwitch]="pageview">
    <some-element *ngSwitchCase="'table'">
      <div class="main-card mb-3 card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            <b style="color: blue;">Invoice History</b>
          </div>
        </div>
        <div class="row p-4">
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Select Customer Type</mat-label>
              <mat-select matNativeControl [(ngModel)]="customerType" (ngModelChange)="onCustomerTypeChange()">
                <mat-option value="customer">Corporate Customers</mat-option>
                <mat-option value="retail">Retail Customers</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Customer Name</mat-label>
              <mat-select matNativeControl id="" [(ngModel)]="sender_id" (selectionChange)="onCustomerSelected()">
                <mat-option value="">All</mat-option>
                <ng-container *ngIf="customerType === 'customer'; else retailCustomersSection">
                  <mat-option *ngFor="let customer of corporateCustomers" [value]="customer.id">
                    {{ customer.firstName }}
                  </mat-option>
                </ng-container>
                <ng-template #retailCustomersSection>
                  <mat-option *ngFor="let customer of individualCustomers" [value]="customer.id">
                    {{ customer.firstName }}
                  </mat-option>
                </ng-template>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <input matInput [matDatepicker]="fromDate" placeholder="From Date" (dateChange)="onDateChange('fromDate')"
                     [formControl]="filterForm.get('fromDate')">
              <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
              <mat-datepicker #fromDate></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <input matInput [matDatepicker]="toDate" placeholder="To Date" (dateChange)="onDateChange('toDate')"
                     [formControl]="filterForm.get('toDate')">
              <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
              <mat-datepicker #toDate></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-lg-2 col-md-1  row m-1">
            <button class="btn btn-secondary m-auto" (click)="resetForm()">
              Reset
            </button>
          </div>
          <!-- <div class="col-lg-2 col-md-2 row">
            <button class="btn btn-primary m-auto" (click)="tablefilter()"> Find
              <i class="pe-7s-search"></i></button>
          </div> -->
          <div class="col-lg-2 col-md-2  row m-1">
            <button class="btn btn-success m-auto" (click)="exportToCSV()">
              Download Report (CSV)
            </button>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div>
                <ngx-loading [show]="loader"
                             [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                             [template]="loadingTemplate"></ngx-loading>
                <ng-container *ngIf="invoices.length > 0; else noRecordsMessage">
                  <table *ngIf="invoices?.length > 0" class="w-100" mat-table [dataSource]="invoices" matSort>
                    <ng-container matColumnDef="invoiceNo">
                      <th mat-header-cell *matHeaderCellDef >Invoice No</th>
                      <td mat-cell *matCellDef="let invoice">{{ invoice.invoiceNo }}</td>
                    </ng-container>
                    <ng-container matColumnDef="customerName">
                      <th mat-header-cell *matHeaderCellDef >Customer Name</th>
                      <td mat-cell *matCellDef="let invoice">{{invoice.customerName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="consignmentNo">
                      <th mat-header-cell *matHeaderCellDef >No. Of CN</th>
                      <td mat-cell *matCellDef="let invoice">
                        <span matTooltip="{{ invoice.consignmentNos}}">
                          {{ invoice.totalConsignments }}
                          <i class="pe-7s-info-circle" style="cursor: pointer;"></i>
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="type">
                      <th mat-header-cell *matHeaderCellDef >Product Type</th>
                      <td mat-cell *matCellDef="let invoice">{{ invoice.productType}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="mode">
                      <th mat-header-cell *matHeaderCellDef >Product Mode</th>
                      <td mat-cell *matCellDef="let invoice">{{ invoice.mode}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="createdAt">
                      <th mat-header-cell *matHeaderCellDef >Created At</th>
                      <td mat-cell *matCellDef="let invoice">{{ invoice.createdAt | date: 'MMM d, y, h:mm a' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="updatedAt">
                      <th mat-header-cell *matHeaderCellDef >Updated At</th>
                      <td mat-cell *matCellDef="let invoice">{{ invoice.updatedAt | date: 'MMM d, y, h:mm a' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Active">
                      <th class="text-center p-1" mat-header-cell *matHeaderCellDef >
                        Action </th>
                      <td mat-cell *matCellDef="let invoice">
                        <div class="d-flex" style="padding-left: 40%">
                          <div role="group" class="btn-group-sm btn-group m-1">
                            <button [disabled]="invoice.key==null" class="btn-shadow btn btn-white" matTooltip="Download as PDF"
                                    (click)="downloadPDFByKey(invoice.key, invoice.invoiceNo)">
                              <i class="fa fa-file-pdf-o"
                                 style="font-size: 20px; color: red; text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;"></i>
                            </button>
                          </div>
                          <div role="group" class="btn-group-sm btn-group m-1" [matTooltip]="invoice.isIrnGenerated ? 'Already Generated IRN' : 'IRN Generation'" *ngIf="displaytoAccountant || displaytoAdmin">
                            <button class="btn-shadow btn btn-primary" (click)="generateIRN(invoice)" [disabled]="invoice.isIrnGenerated">IRN</button>
                          </div>
                          <div role="group" class="btn-group-sm btn-group m-1" *ngIf="displaytoAccountant || displaytoAdmin">
                            <button class="btn-shadow btn btn-primary" (click)="goToInvoiceViewPage(invoice.id)" matTooltip="View & Edit"><i class="lnr-eye"></i></button>
                          </div>

                        </div>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let invoice; columns: displayedColumns;"></tr>
                  </table>
                </ng-container>
                <mat-paginator  [length]="totalInvoices" [pageIndex]="pageNo" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                                (page)="onPaginateChange($event)" showFirstLastButtons>
                </mat-paginator>


                <ng-template #noRecordsMessage>
                  <h1 class="text-center fw-bold">No Records Available..!</h1>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </some-element>
  </container-element>
</div>
