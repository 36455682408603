import {Component, Inject, OnInit} from '@angular/core';
import {ProductTypeService} from '../../productTypes/services/product-type.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConsignmentTypesModel} from '../../consignmentTypes/models/consignment-types-model';
import {ConsignmentSubTypesModel} from '../models/consignment-sub-types-model';


@Component({
  selector: 'createConsignmentSubtypeDialog',
  templateUrl: './createConsignmentSubtype.html',
  styleUrls: ['./createConsignmentSubtypeDialog.Sass'],
})
export class CreateConsignmentSubtypeDialog implements OnInit {
  constructor(private productTypeService: ProductTypeService, public dialogRef: MatDialogRef<CreateConsignmentSubtypeDialog>,
              @Inject(MAT_DIALOG_DATA) public data, private formBuilder: FormBuilder) {
  }


  consignmentSubtypeForm: FormGroup;

  selectedConsignmentType: ConsignmentTypesModel;

  nameRegex: RegExp = /\S+/;

  nameWarning: boolean = false;

  consignmentSubtypeData: ConsignmentSubTypesModel;

  action: string;


  ngOnInit(): void {
    this.consignmentSubtypeData = this.data.consignmentSubtype;
    this.action = this.data.action;
    if (this.consignmentSubtypeData!=null) {
      this.consignmentSubtypeForm = this.formBuilder.group({
        name: [this.consignmentSubtypeData.name],
        description: [this.consignmentSubtypeData.description],
      });
    }
  }


  onCancelClick(): void {
    // console.log(this.selectedConsignmentType);
    this.dialogRef.close();
  }

  onSubmitClick() {
    // console.log(this.selectedConsignmentType);
    this.nameWarning = false;
    if (this.consignmentSubtypeForm.controls['name'].value !== null && this.consignmentSubtypeForm.controls['name'].value.length>0 && this.nameRegex.test(this.consignmentSubtypeForm.controls['name'].value)) {
      const newConsingmentType = new ConsignmentSubTypesModel(this.consignmentSubtypeData.id, this.consignmentSubtypeForm.controls['name'].value, this.consignmentSubtypeForm.controls['description'].value, this.selectedConsignmentType, this.consignmentSubtypeData.isActive);
      this.dialogRef.close(newConsingmentType);
    } else {
      if (!this.nameRegex.test(this.consignmentSubtypeForm.controls['name'].value) || this.consignmentSubtypeForm.controls['name'].value.length==0) this.nameWarning = true;
      if (this.nameRegex.test(this.consignmentSubtypeForm.controls['name'].value)) this.nameWarning = false;
    }
  }
}
