import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../../../../../Global_API_URL';
import {ProductTypeResponse} from '../models/product-type-response';
import {ConsignmentTypeResponse} from '../../consignmentTypes/models/consignment-type-response';
import {ConsignmentSubtypeResponse} from '../../consignmentSubtypes/models/consignment-subtype-response';
import {ProductTypeModel} from '../models/product-type-model';

@Injectable({
  providedIn: 'root',
})
export class ProductTypeService {
  constructor(private http: HttpClient) { }

  getProductTypes(): Observable<ProductTypeResponse> {
    return this.http.get<ProductTypeResponse>(`${API_URL}/getOrderTypes`);
  }

  getConsignmentTypes(): Observable<ConsignmentTypeResponse> {
    return this.http.get<ConsignmentTypeResponse>(`${API_URL}/consignment_type`);
  }
  getConsignmentSubTypes(): Observable<ConsignmentSubtypeResponse> {
    return this.http.get<ConsignmentSubtypeResponse>(`${API_URL}/consignment_sub_types`);
  }

  postOrderTypesConsignmentSubTypes(productTypes: ProductTypeModel[]): Observable<ProductTypeResponse> {
    return this.http.post<ProductTypeResponse>(`${API_URL}/postOrderWithConsignmentSubTypes`, {data: productTypes});
  }
}
