import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BillingService} from '../../../modules/billingInfo/service/billing.service';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {API_URL} from '../../../../../Global_API_URL';
import Swal from 'sweetalert2';
import {AuthService} from '../../../auth.service';
import {HttpService} from '../../../services/http.service';
import {Location} from '@angular/common';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-billing-info1-edit',
  templateUrl: './billing-info-edit.component.html',
  styleUrls: ['./billing-info-edit.component.sass'],
})
export class BillingInfoEditComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  consignment_id;

  constructor(
    private route: ActivatedRoute,
    public commonApi: BillingService,
    private router: Router,
    private authService: AuthService,
    private httpService: HttpService,
    private locationService: Location,
  ) {
    this.authService.getAuthToken();
    this.route.params.subscribe((params) => {
      this.consignment_id = params['id'];
    });
    this.selected = '0';
  }

  userDetails;
  displayToAdmin = false;
  displayToSupervisor = false;
  billPriceId;
  count;
  limit;
  selected;

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0] == 'Admin') {
      this.displayToAdmin = true;
    }
    if (this.userDetails.userTypes[0] == 'Supervisor') {
      this.displayToSupervisor = true;
    }

    this.selectedBillingLineItems = [];
    this.commonApi.getBillingLineItems();
    this.commonApi.getBillingPriceByConsignmentId(this.consignment_id)
        .subscribe((response) => {
          this.billPriceId = this.commonApi.BillingPriceLists?.id;
          this.previousBillingList();
          this.count = this.selectedBillingLineItems.length;

          if (this.commonApi.BillingPriceLists.discountedAmount === null) {
            this.commonApi.BillingPriceLists.discountedAmount = 0;
          }
          if (this.commonApi.BillingPriceLists.insurancePercentage === null) {
            this.commonApi.BillingPriceLists.insurancePercentage = 0;
          }
          this.calculateSubTotal1();
          this.commonApi.calculateGrandTotal();
          // this.commonApi.ModeBasedBillingLineItems();
          this.calculateBillGrandTotal();
          this.BillDiscount();
        });

    setTimeout(() => {
      this.loading = false; // Set loading to false after 2 seconds
    }, 2000);
  }


  selectedBillingLineItems: { name: string, billPrice: number }[] = [];

  // previousBillingList() {
  //   this.commonApi.BillingLineItemsLists.forEach((item) => {
  //     if (this.commonApi.BillingPriceLists.consignment?.product_mode === 'ATA') {
  //       if (this.commonApi.BillingPriceLists[item.value] != null && this.commonApi.BillingPriceLists[item.value] > 0 && item.value != 'pickupCharges' && item.value != 'deliveryCharges' && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
  //         this.selectedBillingLineItems.push({name: item.name, billPrice: this.commonApi.BillingPriceLists[item.value]});
  //       }
  //       this.count = this.selectedBillingLineItems.length;
  //       this.limit = 15;
  //     } else if (this.commonApi.BillingPriceLists.consignment?.product_mode === 'ATD') {
  //       if (this.commonApi.BillingPriceLists[item.value] != null && this.commonApi.BillingPriceLists[item.value] > 0 && item.value != 'pickupCharges' && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
  //         this.selectedBillingLineItems.push({name: item.name, billPrice: this.commonApi.BillingPriceLists[item.value]});
  //       }
  //       this.count = this.selectedBillingLineItems.length;
  //       this.limit = 16;
  //     } else if (this.commonApi.BillingPriceLists.consignment?.product_mode === 'DTA') {
  //       if (this.commonApi.BillingPriceLists[item.value] != null && this.commonApi.BillingPriceLists[item.value] > 0 && item.value != 'deliveryCharges' && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
  //         this.selectedBillingLineItems.push({name: item.name, billPrice: this.commonApi.BillingPriceLists[item.value]});
  //       }
  //       this.count = this.selectedBillingLineItems.length;
  //       this.limit = 16;
  //     } else {
  //       if (this.commonApi.BillingPriceLists[item.value] != null && this.commonApi.BillingPriceLists[item.value] > 0 && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
  //         this.selectedBillingLineItems.push({name: item.name, billPrice: this.commonApi.BillingPriceLists[item.value]});
  //       }
  //       this.count = this.selectedBillingLineItems.length;
  //       this.limit = 17;
  //     }
  //   });
  // }

  previousBillingList() {
    this.commonApi.BillingLineItemsLists.forEach((item) => {
      if (this.commonApi.BillingPriceLists[item.value] != null && this.commonApi.BillingPriceLists[item.value] > 0 && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage') {
        this.selectedBillingLineItems.push({name: item.name, billPrice: this.commonApi.BillingPriceLists[item.value]});
      }
      this.count = this.selectedBillingLineItems.length;
      this.limit = 17;
    });
  }

  addDropdown() {
    this.selectedBillingLineItems.push({name: '', billPrice: 0});
    this.count += 1;
  }


  addColour() {
    if (this.count > this.limit) {
      return 'rgba(0, 0, 0, 0.12)';
    } else {
      return '#3f6ad8';
    }
  }

  getAvailableOptions(index: number): any[] {
    // Get the names of already selected items in previous dropdowns
    const selectedNames = this.selectedBillingLineItems.slice(0, index).map((item) => item.name);

    // Filter out the selected items for the specific dropdown
    return this.commonApi.BillingLineItemsLists.filter((item) =>
      (!selectedNames.includes(item.name) &&
        !['Insurance Percentage', 'Insurance', 'Discounted Amount', 'Tax Amount', 'Taxable Amount', 'Taxable Percentage'].includes(item.name)) ||
      this.selectedBillingLineItems[index].name === item.name,
    );
  }


  BillDiscount1;

  BillDiscount() {
    if (this.commonApi.BillingPriceLists.discountedAmount == null) {
      this.BillDiscount1 = 0;
    } else {
      this.BillDiscount1 = this.commonApi.BillingPriceLists.discountedAmount;
    }
    return this.BillDiscount1;
  }

  subTotal1;

  calculateSubTotal1() {
    this.subTotal1 = 0;
    this.selectedBillingLineItems.forEach((item) => {
      this.subTotal1 += item.billPrice;
      this.subTotal1 = parseFloat(this.subTotal1.toFixed(2));
    });
  }

  subTotal2;
  BillInsuranceAmount;
  BillInsurancePercentage;
  BillGrandTotal;
  BillTaxableAmount;
  BillTax;
  BillTaxAmount;

  async calculateBillGrandTotal() {
    this.subTotal2 = this.subTotal1 - this.commonApi.BillingPriceLists.discountedAmount;
    if (this.commonApi.BillingPriceLists?.consignment?.senderId != null) {
      await this.getCustomerById();
      // if (!this.commonApi.BillingPriceLists?.consignment?.isRetailCustomer){
      //   this.BillTax = 0;
      // }
      // else{
      if (this.commonApi.BillingPriceLists?.igst == 0) {
        this.BillTax = this.commonApi.BillingPriceLists?.cgst + this.commonApi.BillingPriceLists?.sgst;
      } else {
        this.BillTax = this.commonApi.BillingPriceLists?.igst;
      }
      // }
    } else {
      if (this.commonApi.BillingPriceLists?.igst == 0) {
        this.BillTax = this.commonApi.BillingPriceLists?.cgst + this.commonApi.BillingPriceLists?.sgst;
      } else {
        this.BillTax = this.commonApi.BillingPriceLists?.igst;
      }
    }

    this.BillInsurancePercentage = this.commonApi.BillingPriceLists?.insurancePercentage;
    this.BillInsuranceAmount = parseInt( this.commonApi.BillingPriceLists?.insurance || 0);

    if (this.commonApi.isSuffix === true) {
      this.BillInsuranceAmount = (this.commonApi.BillingPriceLists?.insurancePercentage * this.commonApi.BillingPriceLists.consignment?.consignment_value)/100;
    } else {
      this.BillInsurancePercentage = (this.commonApi.BillingPriceLists?.insurance / this.commonApi.BillingPriceLists.consignment?.consignment_value)*100;
    }

    // this.BillInsuranceAmount = (this.commonApi.BillingPriceLists?.insurancePercentage * this.commonApi.BillingPriceLists.consignment?.consignment_value) / 100
    this.BillTaxableAmount = (this.subTotal1 - this.commonApi.BillingPriceLists.discountedAmount) + this.BillInsuranceAmount;
    // this.BillTax = this.commonApi.BillingPriceLists?.taxablePercentage
    this.BillTaxAmount = (this.BillTaxableAmount * this.BillTax) / 100;
    this.BillGrandTotal = this.BillTaxableAmount + this.BillTaxAmount;

    this.subTotal2 = parseFloat(this.subTotal2.toFixed(2));
    this.BillTaxableAmount = parseFloat(this.BillTaxableAmount.toFixed(2));
    this.BillTaxAmount = parseFloat(this.BillTaxAmount.toFixed(2));
    this.BillGrandTotal = parseFloat(this.BillGrandTotal.toFixed(2));
  }


  onPriceChange() {
    this.calculateSubTotal1();
    this.commonApi.calculateGrandTotal();
    this.calculateBillGrandTotal();
  }

  backToBillingInfo() {
    this.commonApi.isSuffix = true;
    this.locationService.back();
  }

  // billPriceId = this.commonApi.BillingPriceLists?.id
  saveBillingPrices() {
    // Check for empty name in selectedBillingLineItems
    const hasEmptyName = this.selectedBillingLineItems.some((item) => item.name.trim() === '');
    // Filter out items with billingPrice zero
    const hasNullBillingPrice = this.selectedBillingLineItems.some((item) => item.billPrice === null);


    if (hasEmptyName || hasNullBillingPrice) {
      Swal.fire({
        title: 'Warning',
        text: hasEmptyName ? 'Please select line items' : 'Please enter the price for line item',
        icon: 'warning',
        timer: 5000,
      });
    } else if (this.BillInsurancePercentage > 100) {
      Swal.fire({
        title: 'Warning',
        text: 'Fov percentage must be less than 100.',
        icon: 'warning',
        timer: 5000,
      });
    } else if (this.BillGrandTotal < 0) {
      Swal.fire({
        title: 'Warning',
        text: 'Grand Total must be greater than zero.',
        icon: 'warning',
        timer: 5000,
      });
    } else {
      this.commonApi.patchBillingPrices(this.billPriceId, this.selectedBillingLineItems, this.BillInsuranceAmount, this.BillInsurancePercentage, this.BillTaxableAmount, this.BillTaxAmount, this.BillGrandTotal)
          .subscribe((response) => {
            this.commonApi.isSuffix = true;
            this.router.navigate(['billingInfoPage', this.consignment_id], {replaceUrl: true});
          });
    }
  }

  validateInput(event: KeyboardEvent): void {
    const inputChar = String.fromCharCode(event.charCode);

    // Allow only digits and a dot (for decimal numbers)
    if (!/^\d*\.?\d*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  clearUnitPriceZero(i): void {
    if (this.selectedBillingLineItems[i].billPrice === 0) {
      this.selectedBillingLineItems[i].billPrice = null;
    }
  }

  clearDiscountZero(): void {
    // Check if the value is 0 and clear it
    if (this.commonApi.BillingPriceLists.discountedAmount === 0) {
      this.commonApi.BillingPriceLists.discountedAmount = null;
    }
  }

  clearInsuranceZero(): void {
    if (this.commonApi.BillingPriceLists.insurancePercentage === '0' || this.commonApi.BillingPriceLists.insurancePercentage === 0) {
      this.commonApi.BillingPriceLists.insurancePercentage = null;
    }
  }

  clearInsuranceValueZero(): void {
    if (this.commonApi.BillingPriceLists.insurance === '0' || this.commonApi.BillingPriceLists.insurance === 0) {
      this.commonApi.BillingPriceLists.insurance = null;
    }
  }

  clearTaxZero(): void {
    if (this.commonApi.BillingPriceLists.taxablePercentage === '0' || this.commonApi.BillingPriceLists.taxablePercentage === 0) {
      this.commonApi.BillingPriceLists.taxablePercentage = null;
    }
  }

  removeLineItem(index: number): void {
    this.selectedBillingLineItems.splice(index, 1);
    this.count -= 1;
    // Recalculate totals or perform any other necessary actions
    this.calculateSubTotal1();
    this.calculateBillGrandTotal();
    // You can also perform additional calculations or updates here
  }


  customerData
  getCustomerById() {
    const id = this.commonApi.BillingPriceLists?.consignment?.senderId;
    this.httpService.get(API_URL + '/customer/' + id, null, null)
        .subscribe((response) => {
          this.customerData = response.data[0];
        }, (error) => {
          Swal.fire({
            icon: 'error',
            title: error.message,
            showConfirmButton: false,
            timer: 5000,
          });
        });
  }
}
