<div *ngIf="loading" class="loader-container">
  <ngx-loading [show]="loading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '5px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>

<div class="col-md-12">
  <container-element [ngSwitch]="pageview">
    <some-element *ngSwitchCase="'table'">
      <div class="card">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            <b style="color: blue;">RETAIL CUSTOMER</b>
          </div>
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-primary" (click)="gotoRetailCustomerCreatePage()">Add</div>
          </div>
        </div>

        <div class="row p-4">
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="search2" (keyup)="searchByCons2(search2)" placeholder="Name" #input>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Email</mat-label>
              <input matInput [(ngModel)]="search" (keyup)="searchByCons(search)" placeholder="Email Id" #input>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Phone No.</mat-label>
              <input matInput #phone maxlength="10" placeholder="Phone No." oninput="if(!this.value.match('^[0-9]*$'))this.value='';" [(ngModel)]="search1" (keyup)="searchByCons1(search1)" (keydown)="onKeyDown($event)" #input>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>State</mat-label>
              <input matInput placeholder="Search State" [(ngModel)]="retailfilters.stateid" [matAutocomplete]="autoState">
              <mat-autocomplete #autoState="matAutocomplete" [displayWith]="displayStateFn">
                <mat-option *ngFor="let state of states | filter: retailfilters.stateid" [value]="state" (click)="getCities(state.id)">{{ state.name }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>City</mat-label>
              <input matInput placeholder="Search City" [(ngModel)]="retailfilters.cityid" [matAutocomplete]="autoCity">
              <mat-autocomplete #autoCity="matAutocomplete" [displayWith]="displayCityFn">
                <mat-option *ngFor="let city of (cities | async) | filter: retailfilters.cityid" [value]="city">{{ city.name }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="col-md-2 row" style="width: 153px; margin-top: 12.5px">
            <mat-form-field>
              <mat-label>Select</mat-label>
              <mat-select [(ngModel)]="retailfilters.status" (selectionChange)="applyFilter()">
                <mat-option value="all">All</mat-option>
                <mat-option value="verified">Verified</mat-option>
                <mat-option value="notVerified">Not Verified</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-1" style="width: 6%">
            <button class = "btn btn-primary mt-3" (click)="find()">Find</button>
          </div>
          <div class="col-md-1">
            <button class = "btn btn-secondary mt-3" (click)="reset()">Reset</button>
          </div>
        </div>

        <div class="table-responsive">
          <table class="w-100" mat-table [dataSource]="dataSource" matSort >
            <!-- Position Column -->
            <ng-container matColumnDef="customer_id">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by firstname" style="width: 16%;">Name</th>
              <td class="text-center" mat-cell *matCellDef="let element">{{element.firstName}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by email" style="width: 16%;">Email</th>
              <td class="text-center" mat-cell *matCellDef="let element">{{element.email}}</td>
            </ng-container>
            <ng-container matColumnDef="phone">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by phone" style="width: 16%;">Phone No.</th>
              <td class="text-center" mat-cell *matCellDef="let element">{{element.phone}}</td>
            </ng-container>
            <ng-container matColumnDef="stateid">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by state">State</th>
              <td class="text-center" mat-cell *matCellDef="let element">
                {{element?.billingAddress?.pincodeId?.cityId?.state_id?.name}} </td>
            </ng-container>
            <ng-container matColumnDef="cityid">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by city">City </th>
              <td class="text-center" mat-cell *matCellDef="let element"> {{element.billingAddress?.pincodeId?.cityId?.name}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th class="text-center" mat-header-cell *matHeaderCellDef
                  sortActionDescription="Sort by status">Status
              </th>
              <td class="text-center" mat-cell *matCellDef="let element" [ngStyle]="(element?.gstDetails != null || element?.panDetails != null || element?.aadhaarDetails != null) ? {'color': 'green'} : {'color': 'red'}"> {{ (element?.gstDetails != null || element?.panDetails != null || element?.aadhaarDetails != null) ? "Verified" : "Not Verified" }}</td>
            </ng-container>

            <ng-container matColumnDef="Active">
              <th class="text-center" mat-header-cell *matHeaderCellDef > Action </th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex justify-content-center">
                  <div role="group" *ngIf="(element.isActive != false)" class="btn-group-sm btn-group m-1">
                    <button class="btn-shadow btn btn-primary" matTooltip="Edit"
                            (click)="gotoRetailCustomerEditPage(element.id)"><i
                      class="lnr-pencil"></i></button>
                  </div>
                  <div role="group" *ngIf="(element.isActive != false) && !displaytoAccountant"
                  class="btn-group-sm btn-group m-1">
               <span class="material-icons btn-shadow btn btn-danger" matTooltip="Deactivate User" (click)="DeleteUser(element)">person_remove</span>
             </div>

             <div role="group" *ngIf="(element.isActive == false) && !displaytoAccountant"
             class="btn-group-sm btn-group m-1">
          <span class="material-icons btn-shadow btn btn-success" matTooltip="Activate User" (click)="ActivateUser(element)"> person_add_alt_1 </span>
        </div>
                  <div role="group" *ngIf="element.isActive != false && displaytoAdmin"
                  class="btn-group-sm btn-group m-1">
               <span class="material-icons btn-shadow btn btn-primary" matTooltip="Reset Password" (click)="viewchange('reset',element.email)">vpn_key</span>
             </div>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="row.isActive == false ? 'red':'white'"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
          </mat-paginator>
        </div>

      </div>
    </some-element>


   <some-element *ngSwitchCase="'reset'">
     <div class="card">
        <div class="card-header">

           <div class="card-header-title font-size-lg text-capitalize fw-normal">
             <b style="color: blue;">CHANGE PASSWORD</b>
           </div>

        </div>

           <div class="container" style="height: 180px">
             <div class="col-md-12">
               <mat-label>
                 <b>New Password</b>
               </mat-label>
               <br>
               <p-password placeholder="Enter New Password" [(ngModel)]="newpassword"
                           [feedback]="false" [toggleMask]="true" (input)="checkNewPassword()"
                           [style]="{'width':'300px'}" [inputStyle]="{'width':'300px'}"></p-password><br>
               <mat-hint class="text-danger" *ngIf="newPassLengthCheck">Password should have minimum of 6 characters </mat-hint>
             </div>
             <br>

             <div class="col-md-12">
               <mat-label>
                 <b>Confirm New Password</b>
               </mat-label>
               <br>
               <p-password placeholder="Enter Confirm Password" [(ngModel)]="retypePassword"
                           [feedback]="false" [toggleMask]="true" (input)="checkPassword()"
                           [style]="{'width':'300px'}" [inputStyle]="{'width':'300px'}"></p-password>&nbsp;&nbsp;&nbsp;
               <span class="text-danger"
                     *ngIf="passwordFlag === true">New Password and Confirm Password should match</span>
             </div>
              </div>
              </div>

          <div class="mt-4 d-flex align-items-center">
          <div class="ms-auto">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');resetForm();filtersretail()">Cancel</div>
            <button class="mb-2 me-2 btn btn-primary" (click)="resetPassword()">Reset Password</button>

           </div>
        </div>
       </some-element>
<!--    <some-element *ngSwitchCase="'view'">-->
<!--      <div class="card">-->
<!--        <div class="card-header">-->

<!--          <div class="card-header-title font-size-lg text-capitalize fw-normal">-->
<!--            View Country-->
<!--          </div>-->

<!--        </div>-->

<!--        <div class="container">-->
<!--          <div class="text-bold">Country Name</div> <span>India</span>-->
<!--          <div class="text-bold">Country Code</div> <span>+91</span>-->
<!--          <div class="text-bold">Currency</div> <span>INR</span>-->
<!--        </div>-->
<!--        <div class="card-footer">-->
<!--          <div class="btn-actions-pane-right p-2">-->
<!--            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');filtersretail()">Back</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </some-element>-->
  </container-element>
</div>
