import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BillingCostBulkUploadService } from './billing-cost-bulk-upload.service';
import Swal from 'sweetalert2';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-billing-cost-bulk-upload',
  templateUrl: './billing-cost-bulk-upload.component.html',
  styleUrls: ['./billing-cost-bulk-upload.component.sass']
})
export class BillingCostBulkUploadComponent implements OnInit {

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  @ViewChild('fileInput') fileInput: ElementRef;

  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  constructor(private billingCostBulkUploadService: BillingCostBulkUploadService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false; // Set loading to false after 2 seconds
    }, 2000);
  }

  selectedFile: File | null = null;
  isSubmitting = false;

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  onCancel(): void {
    this.selectedFile = null;
    this.fileInput.nativeElement.value = '';
  }

  onSubmit(): void {
    if (this.selectedFile) {
      this.isSubmitting = true;
      this.loading = true;
      this.billingCostBulkUploadService.uploadCsv(this.selectedFile).subscribe(
        response => {
          this.isSubmitting = false;
          Swal.fire({
            icon: 'success',
            title: 'Upload Successful',
            timer: 5000,
            text: 'The file has been uploaded successfully!'
          }).then(() => {
            this.loading = false;
            this.selectedFile = null;
            this.fileInput.nativeElement.value = '';
            location.reload();  // Reload the page after closing the SweetAlert
          });
        },
        warn => {
          this.isSubmitting = false;
          const errorMessage = warn.error.message || 'Failed to upload file';
          Swal.fire({
            icon: 'warning',
            title: 'Warning',
            text: errorMessage
          }).then(() => {
            this.loading = false;
            this.selectedFile = null;
            this.fileInput.nativeElement.value = '';
            location.reload();  // Reload the page after closing the SweetAlert
          });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 5000);
        }
      );
    }
  }

  selectedCostFile: File | null = null;
  isCostSubmitting = false;

  onCostFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedCostFile = file;
    }
  }

  onCostCancel(): void {
    this.selectedCostFile = null;
  }

  onCostSubmit(): void {
    if (this.selectedCostFile) {
      this.isCostSubmitting = true;
      this.billingCostBulkUploadService.uploadCostCsv(this.selectedCostFile).subscribe(
        response => {
          this.isCostSubmitting = false;
          Swal.fire({
            icon: 'success',
            title: 'Upload Successful',
            timer: 5000,
            text: 'The file has been uploaded successfully!'
          });
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        },
        error => {
          this.isCostSubmitting = false;
          const errorMessage = error.error.message || 'Failed to upload file';
          Swal.fire({
            icon: 'error',
            title: 'Warning',
            text: errorMessage
          });
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      );
    }
  }

}
