<div class="col-md-12">
	<container-element [ngSwitch]="pageview">
		<some-element *ngSwitchCase="'table'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						Airline Pricing Schedules
					</div>
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-primary" (click)="viewchange('add')">Add</div>
					</div>
				</div>

				<div class="container">
					<div class="row">
						<div class="">
							<div class="table-responsive">
								<table class="align-middle text-truncate mb-0 table table-borderless table-hover">
									<thead>
										<tr>
											<th class="text-center">Origin</th>
											<th class="text-center">Destination</th>
											<th class="text-center">Airline</th>
											<th class="text-center">Consignment Type</th>
											<th class="text-center">Minmum Charge</th>
											<th class="text-center">N Rate</th>
											<th class="text-center">+45</th>
											<th class="text-center">+100</th>
											<th class="text-center">Applicable Commodity</th>
											<th class="text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										<!-- *ngFor="let air of airports | async" -->
										<tr *ngFor="let air of airlinepricing | async">
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{air.origin.iataCode}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{air.destination.iataCode}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{air.airline.name}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{air.consignmentType.name}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{air.minimumCharge}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{air.netRate}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{air.above45}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{air.above100}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{air.applicableCommodity}}
												</a>
											</td>
											<td class="text-center">
												<div role="group" class="btn-group-sm btn-group">
													<button class="btn-shadow btn btn-primary" (click)="viewchange('edit')"><i
															class="lnr-pencil"></i></button>
													<!-- <button class="btn-shadow btn btn-primary">Delete</button> -->
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
									[showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
									[pageIndex]="pageIndex" aria-label="Select page">
								</mat-paginator>
							</div>
						</div>
					</div>
				</div>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'add'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						Add Airline Pricing Schedules
					</div>

				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Airline</mat-label>
								<select matNativeControl id="" [(ngModel)]="newAirlinePricing.airline" >
									<option *ngFor="let con of airlines |async" [value]="con.id">
										{{con.name}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Origin</mat-label>
								<select matNativeControl id="" [(ngModel)]="newAirlinePricing.origin">
									<option *ngFor="let airport of airports |async" [value]="airport.id">
										{{airport.iataCode}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Destination</mat-label>
								<select matNativeControl id="" [(ngModel)]="newAirlinePricing.destination">
									<option *ngFor="let airport of airports |async" [value]="airport.id">
										{{airport.iataCode}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Consignment Type</mat-label>
								<select matNativeControl id="" [(ngModel)]="newAirlinePricing.consignmentType">
									<option *ngFor="let con of consignmentTYpes |async" [value]="con.id">
										{{con.name}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Minmum Charge
								</mat-label>
								<input type="text" placeholder="Minmum Charge" aria-label="Number" [(ngModel)]="newAirlinePricing.minimumCharge" matInput >

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									N Rate
								</mat-label>
								<input type="text" placeholder="N Rate" aria-label="Number" [(ngModel)]="newAirlinePricing.netRate" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									+45
								</mat-label>
								<input type="text" placeholder="+45" aria-label="Number" [(ngModel)]="newAirlinePricing.above_45" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									+100
								</mat-label>
								<input type="text" placeholder="+100" aria-label="Number" [(ngModel)]="newAirlinePricing.above_100" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									+300
								</mat-label>
								<input type="text" placeholder="+300" aria-label="Number" [(ngModel)]="newAirlinePricing.above_300" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									+500
								</mat-label>
								<input type="text" placeholder="+500" aria-label="Number" [(ngModel)]="newAirlinePricing.above_500" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									+1000
								</mat-label>
								<input type="text" placeholder="+1000" aria-label="Number" [(ngModel)]="newAirlinePricing.above_1000" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Applicable Flight
								</mat-label>
								<input type="text" placeholder="Applicable Flight" aria-label="Number" [(ngModel)]="newAirlinePricing.applicableFlight" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Applicable Commodity
								</mat-label>
								<input type="text" placeholder="Applicable Commodity" aria-label="Number" [(ngModel)]="newAirlinePricing.applicableCommodity" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Flight Surcharge
								</mat-label>
								<input type="text" placeholder="Flight Surcharge" aria-label="Number" [(ngModel)]="newAirlinePricing.sectorSurcharge" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Sector Surcharge
								</mat-label>
								<input type="text" placeholder="Sector Surcharge" aria-label="Number" [(ngModel)]="newAirlinePricing.flightSurcharge" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Commodity Surcharge
								</mat-label>
								<input type="text" placeholder="Commodity Surcharge" aria-label="Number" [(ngModel)]="newAirlinePricing.commoditySurcharge" matInput>

							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="createAirlinePricing()">Add</div>
					</div>
				</div>
			</div>
		</some-element>

		<some-element *ngSwitchCase="'edit'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						Edit Airline Pricing Schedules
					</div>

				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Airline</mat-label>
								<select matNativeControl id="">
									<option *ngFor="let con of getAirlines |async" [value]="con.id">
										{{con.name}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Origin</mat-label>
								<select matNativeControl id="">
									<option *ngFor="let airport of airports |async" [value]="airport.id">
										{{airport.name}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Destination</mat-label>
								<select matNativeControl id="">
									<option *ngFor="let airport of airports |async" [value]="airport.id">
										{{airport.name}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Consignment Type</mat-label>
								<select matNativeControl id="">
									<!-- <option *ngFor="let airport of airports |async" [value]="airport.id">
										{{airport.name}}
									</option> -->
									<option>GCR</option>
									<option>SCR</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Minmum Charge
								</mat-label>
								<input type="text" placeholder="Minmum Charge" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									N Rate
								</mat-label>
								<input type="text" placeholder="N Rate" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									+45
								</mat-label>
								<input type="text" placeholder="+45" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									+100
								</mat-label>
								<input type="text" placeholder="+100" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									+300
								</mat-label>
								<input type="text" placeholder="+300" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									+500
								</mat-label>
								<input type="text" placeholder="+500" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									+1000
								</mat-label>
								<input type="text" placeholder="+1000" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Applicable Flight
								</mat-label>
								<input type="text" placeholder="Applicable Flight" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Applicable Commodity
								</mat-label>
								<input type="text" placeholder="Applicable Commodity" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Flight Surcharge
								</mat-label>
								<input type="text" placeholder="Flight Surcharge" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Sector Surcharge
								</mat-label>
								<input type="text" placeholder="Sector Surcharge" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Commodity Surcharge
								</mat-label>
								<input type="text" placeholder="Commodity Surcharge" aria-label="Number" matInput>

							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="viewchange('table')">Save</div>
					</div>
				</div>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'view'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						View Airline Pricing Schedules
					</div>

				</div>

				<div class="container m-2">
					<div class="row ">
						<div class="col-md-3 fw-bold">Airline</div>
						<div class="col-md-3"> Indigo </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">Origin</div>
						<div class="col-md-3"> Hyd </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">Destination</div>
						<div class="col-md-3"> DEL </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">Consignment Type</div>
						<div class="col-md-3"> SCR </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">Minimum Charge</div>
						<div class="col-md-3"> 1500 </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">N Rate</div>
						<div class="col-md-3">106 </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">+45</div>
						<div class="col-md-3"> 50 </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">+100</div>
						<div class="col-md-3"> 45 </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">+300</div>
						<div class="col-md-3"> - </div>
					</div>
					<mat-divider class="m-1"></mat-divider>

					<div class="row ">
						<div class="col-md-3 fw-bold">+500</div>
						<div class="col-md-3"> - </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">+1000</div>
						<div class="col-md-3">- </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">Applicable Flight</div>
						<div class="col-md-3"> - </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">Applicable Commodity</div>
						<div class="col-md-3"> Shrimps/ Fish Seeds (FSD) </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">Sector Surcharge</div>
						<div class="col-md-3"> - </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">Flight Surcharge</div>
						<div class="col-md-3"> - </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">Commodity Surcharge</div>
						<div class="col-md-3"> - </div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Back</div>
					</div>
				</div>
			</div>
		</some-element>
	</container-element>
</div>