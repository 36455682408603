<div class="row">
  <!-- <div class="col-md-3 fw-bold">
    Name   ={{userName}}
  </div> -->
</div>
<div *ngIf="userId!= null">
  <div class="row">
    <div class="col-md-4">
      <mat-form-field class="example-full-width" appearance="standard">
        <mat-label>Address<span class="text-danger">*</span></mat-label>
        <textarea matInput placeholder="Address" [(ngModel)]="new_address.address"></textarea>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>Country<span class="text-danger">*</span></mat-label>
        <mat-select matNativeControl id="" (blur)="getStates()" [(ngModel)]="sel_country">
          <mat-option selected>SELECT</mat-option>
          <mat-option *ngFor="let con of countriesList |async" [value]="con.id">
            {{con.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>
          State<span class="text-danger">*</span>
        </mat-label>
        <mat-select matNativeControl  (blur)="getCities()"
          [(ngModel)]="sel_state">
          <mat-option selected>SELECT</mat-option>
          <mat-option *ngFor="let sta of statesList |async" [value]="sta.id">
            {{sta.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>
          City<span class="text-danger">*</span>
        </mat-label>
        <mat-select matNativeControl  (blur)="getPincodes()" [(ngModel)]="sel_city">
          <mat-option selected>SELECT</mat-option>
          <mat-option *ngFor="let cit of citiesList |async" [value]="cit.id">
            {{cit.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>
          Zip Code<span class="text-danger">*</span>
        </mat-label>
        <mat-select matNativeControl
          [(ngModel)]="new_address.pincode.pin_number">
          <mat-option selected>SELECT</mat-option>
          <mat-option *ngFor="let cit of pincodeList |async"
            [value]="cit.pinNumber">
            {{cit.pinNumber}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="address_created==false" class="row text-center">
    <div class="te">
        <button type="button" class="btn btn-primary" (click)="createAddress()">Create</button>
    </div>
    <ngx-loading [show]="loading"
				[config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
				[template]="loadingTemplate"></ngx-loading>
  </div>
</div>
