import {Component, OnInit} from '@angular/core';
import {API_URL} from '../../../../../Global_API_URL';
import Swal from 'sweetalert2';
import {PageEvent} from '@angular/material/paginator';
import {AuthService} from '../../../auth.service';

@Component({
  selector: 'app-corporate-list',
  templateUrl: './corporate-list.component.html',
  styleUrls: ['./corporate-list.component.sass'],
})
export class CorporateListComponent implements OnInit {
  constructor(private authService : AuthService) {
    this.authService.getAuthToken();
  }
  consignments: any;
  pageview='table';
  activestatus=false;
  weight_divisor= 5000
  consignment_id=0
  consignment_details = {
    id: null,
    consignmentType: null,
    weightUnit: 1,
    currency: 1,
    weightDivisor: 1,
    consignmentNo: null,
    airlinesId: null,
    isStopover: null,
    totalTransitTime: null,
    senderIndividual: null,
    isIndividualCustomer: null,
    paymentCompletedAt: null,
    isXrayScreening: null,
    isXrayCertification: null,
    isDryIce: null,
    totalWeight: null,
    totalChargableWeight: null,
    planSelected: null,
    senderId: null,
    receiverId: null,
    isDocumentUploaded: null,
    isInsured: false,
    totalShippingCost: null,
    paymentMode: null,
    isPaymentCompleted: null,
    isActive: null,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
    totalNoOfPackages: null,
    destinationLocation: null,
    consignmentSubType: null,
    originLocation: null,
    dimensionuUnit: null,
    insuranceCoverage: 0,
    totalConsignmentValue: 0,
    shippingCost: 0,
    invoiceCreatedDate: '',
    invoiceReferenceNo: null,
    productType: null,
    remarks: null,
  }
  weightDivisorList= [
    6000, 5000, 4500, 3600, 2700,
  ]
  packages = [
    {
      id: 1,
      length: null,
      breadth: null,
      height: null,
      weight_per_package: null,
      total_weight: null,
      packageNo: null,
      volume_weight: null,
      chargeable_weight: null,
    },
  ]
  changeable_packages = [
    {
      id: 1,
      length: null,
      breadth: null,
      height: null,
      weight_per_package: null,
      total_weight: null,
      package_no: null,
      volume_weight: null,
      chargeable_weight: null,
    },
  ]
  filterParams='';
  pagenation= {
    col_name: 'id',
    page_size: 10,
    page_no: 0,
    sort_dir: 'DESC',
  }
  consignmentlist=null;
  ngOnInit(): void {
    this.consignments = this.getconsignmentsList();
  }
  pageSizeOptions=[5, 10, 30];
  tablefilter() {
    this.pagenation.page_no=0;
    this.pagenation.sort_dir='DESC';
    this.getconsignmentsList();
  }
  filter() {
    this.filterParams='?';
    this.filterParams= this.filterParams+'page_no='+this.pagenation.page_no+'&page_size='+this.pagenation.page_size+'&col_name='+this.pagenation.col_name+'&sort_dir='+this.pagenation.sort_dir;
    return this.filterParams;
  }
  handlePageEvent(event: PageEvent) {
    this.pagenation.page_no = event.pageIndex;
    this.pagenation.page_size=event.pageSize;
    this.getconsignmentsList();
  }
  async getconsignmentsList() {
    const TOKEN = this.authService.getAuthToken();
    // console.log(API_URL)
    const api= '/corporate';
    const params = this.filter();
    await fetch(API_URL + api+params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          this.consignmentlist = json;
        });
    return this.consignmentlist;
  }
  async getconsignmentById() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/' + this.consignment_id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          this.consignment_details = result.data[0];
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error',
            showConfirmButton: false,
            timer: 5000,
          });
        });
    await fetch(API_URL + '/consignments/packages/' + this.consignment_id,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          this.packages = result.data;
          this.changeable_packages=result.data;
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'User Not Found',
            showConfirmButton: false,
            timer: 5000,
          });
        });
  }
  async getRecord(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/report/' + id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then(async (response) => {
          const filename = 'consignmentReport' + id;
          const blob = response.blob();
          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(await blob);
          a.click();
        },
        );
  }
  weightDivisorChange() {
    for (let i=0; i < this.changeable_packages.length; i++) {
      this.eachPackageCharged(this.changeable_packages[i].id);
    // alert( "here")
    }
  }
  eachPackageCharged(id) {
    this.consignment_details.totalNoOfPackages = 0;
    this.consignment_details.totalWeight = 0;
    this.consignment_details.totalChargableWeight = 0;
    for (let index = 0; index < this.changeable_packages.length; index++) {
      this.changeable_packages[index].total_weight = Number(this.changeable_packages[index].package_no) * this.changeable_packages[index].weight_per_package;
      if (this.changeable_packages[index].id == id) {
        this.changeable_packages[index].volume_weight = Number(this.changeable_packages[index].length) * Number(this.changeable_packages[index].breadth) * Number(this.changeable_packages[index].height) / Number(this.consignment_details.weightDivisor);
        // console.log(this.changeable_packages[index].volume_weight + "  This is volume weight")
        if (this.changeable_packages[index].volume_weight > this.changeable_packages[index].weight_per_package) {
          this.changeable_packages[index].chargeable_weight = Number(this.changeable_packages[index].volume_weight) * Number(this.changeable_packages[index].package_no);
        } else {
          this.changeable_packages[index].chargeable_weight = this.changeable_packages[index].total_weight;
        }
      }
      this.consignment_details.totalNoOfPackages = Number(this.consignment_details.totalNoOfPackages) + Number(this.changeable_packages[index].package_no);
      this.consignment_details.totalWeight = this.consignment_details.totalWeight + Number(this.changeable_packages[index].total_weight);
      this.consignment_details.totalChargableWeight = this.consignment_details.totalChargableWeight + this.changeable_packages[index].chargeable_weight;
    }
  }
  async saveChanges() {
    const TOKEN = this.authService.getAuthToken();
    const cons =
    {
      'totalNoOfPackages': this.consignment_details.totalNoOfPackages,
      'totalWeight': this.consignment_details.totalWeight,
      'totalChargableWeight': this.consignment_details.totalChargableWeight,
      'packages': this.changeable_packages,
      'totalConsignmentValue': this.consignment_details.totalConsignmentValue,
      'invoiceCreatedDate': this.consignment_details.invoiceCreatedDate,
      'invoiceReferenceNo': this.consignment_details.invoiceReferenceNo,
      'remarks': this.consignment_details.remarks,
      'weightDivisor': this.consignment_details.weightDivisor,
    };

    await fetch(API_URL + '/consignment/'+this.consignment_details.id,
        {
          method: 'PATCH',
          body: JSON.stringify(cons),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Changes Saved',
            showConfirmButton: false,
            timer: 5000,
          });
          // alert("Changes Saved");
          this.viewchange('table', 0);
          // console.log(result);
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error',
            showConfirmButton: false,
            timer: 5000,
          });
          console.error('Error', error);
          alert(error);
        });
  }
  viewchange(val, id) {
    this.pageview=val;
    this.consignment_id=id;
    if (this.consignment_id!=0) {
      this.getconsignmentById();
    }
  }
  alertfunc() {
    alert('here');
  }
  formatDate(date) {
    const date2 = new Date(date);
    return (
      [
        date2.getFullYear(),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date2.getHours()),
        this.padTo2Digits(date2.getMinutes()),
      ].join(':')
    );
  }
  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  async Deletedata(id) {
    const TOKEN = this.authService.getAuthToken();
    // console.log(id)
    const data = JSON.stringify({
      'id': id,
    });
    await fetch(API_URL + '/consignment/active/'+this.activestatus,
        {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+TOKEN,
          },
          body: data,
        })

    // Converting received data to JSON
        .then((response) => response.text());
    // .then(result => console.log(result))
    this.consignments = this.getconsignmentsList()
        .catch((error) => console.error('error', error));
  }
}
