import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import {API_URL} from '../../../../../Global_API_URL';
import {getCities, getCountries, getStates} from '../../getCities.js';
import {Router} from '@angular/router';
import {AuthService} from '../../../auth.service';
import {HttpService} from '../../../services/http.service';


let USERID;

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.sass'],
})
export class EditUserComponent implements OnInit {
  pageview = 'table';
  states = getStates();
  countries = getCountries();
  cities = getCities();
  userRoles = this.getuserRoles();
  user_id={
    id: null,
    firstName: null,
    middleName: null,
    lastName: null,
    email: null,
    phone: null,
    whatsapp: null,
  }
  userDetails = {
    id: null,
    firstName: '',
    middleName: '',
    lastName: '',
    // avatarUrl: '',
    email: '',
    // emailForMasterGST: '',
    phone: '',
    whatsapp: '',
    // designation: null,
    // userTypes: [{id: null}],
    // employementNo: null,
    // officeLocation: {id: null},
    billingAddress: {
      pincode: {
        city: {id: null},
        pin_number: ''
      },
      address: ''
    },
    // isSuperUser: null,
    // username: '',
    // passwordHash: null,
    // lastLoggedInAt: null,
    // lastLoggedInIp: null,
    customerType: null,
    // orgCustomerId: {id: null},
    // customerId: {id: null},
    // aadhaarNumber: null,
    // drivingLicense: null,
    // gstNumber: null,
    // panNumber: null,
    // gstDetails: null,
    // panDetails: null,
    // aadhaarDetails: null,
    // customerVerified: true,
    // tokenCreationDate: null,
    // token: null,
    // isActive: true,
    // createdAt: null,
    // updatedAt: null,
    // createdBy: null,
    // updatedBy: null
  }


  async getuserRoles() {
    let userRoles;
    this.httpService.get(API_URL + '/users_types', null, null)
        .subscribe((json) => {
          userRoles = json;
        });
    if (userRoles != undefined) {
      return userRoles.data;
    } else {
      return [];
    }
  }

  constructor( private router : Router, private authService : AuthService, private httpService: HttpService) { }

  async ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.userDetails.billingAddress.pincode = JSON.parse(localStorage.getItem('userDetails')).billingAddress?.pincodeId;
    this.userDetails.billingAddress.pincode.city = JSON.parse(localStorage.getItem('userDetails')).billingAddress?.pincodeId?.cityId;
    this.userDetails.billingAddress.pincode.pin_number = JSON.parse(localStorage.getItem('userDetails')).billingAddress?.pincodeId?.pinNumber;
    USERID =this.userDetails.id;
  }

  viewchange() {
    // this.pageview = val;
    // this.user_id=user;
    this.router.navigate(['/quickstart']);
  }

  edituser() {
    if (this.userDetails.firstName == '' || this.userDetails.firstName == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The First Name',
        timer: 5000,
      });
    } else if (this.userDetails.firstName.length != this.userDetails.firstName.trim().length || this.userDetails.firstName.trim().length == 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Remove the spaces at beginning or end of the first name',
        timer: 5000,
      });
      return;
    } else if (this.userDetails.lastName == '' || this.userDetails.lastName == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Last Name',
        timer: 5000,
      });
    } else if (this.userDetails.lastName.length != this.userDetails.lastName.trim().length || this.userDetails.lastName.trim().length == 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Remove the spaces at beginning or end of the last name',
        timer: 5000,
      });
      return;
    } else if (this.userDetails.phone == '' || this.userDetails.phone == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Phone No.',
        timer: 5000,
      });
    } else if (this.userDetails.phone.length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter Valid Phone No.',
        timer: 5000,
      });
    } else if (this.userDetails.whatsapp?.length > 0 && this.userDetails.whatsapp?.length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter Valid Whatsapp No.',
        timer: 5000,
      });
    } else {

      if (this.userDetails.customerType == 1){
        this.httpService.patch(API_URL +'/retailUser/'+this.userDetails.id, this.userDetails, null, null)
          .subscribe((result) =>{
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Updated successfully',
              timer: 5000,
            }).then(()=>{
              this.viewchange();
            }).then(()=>{
              window.location.reload();
            });
            this.fetchUserData(this.userDetails.id);
          }, (error) => console.error('error', error));
      }
       else if (this.userDetails.customerType == 2){
        this.httpService.patch(API_URL +'/representativeUser/'+this.userDetails.id, this.userDetails, null, null)
          .subscribe((result) =>{
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Updated successfully',
              timer: 5000,
            }).then(()=>{
              this.viewchange();
            }).then(()=>{
              window.location.reload();
            });
            this.fetchUserData(this.userDetails.id);
          }, (error) => console.error('error', error));
      }
       else{
        this.httpService.patch(API_URL +'/user/'+this.userDetails.id, this.userDetails, null, null)
          .subscribe((result) =>{
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Updated successfully',
              timer: 5000,
            }).then(()=>{
              this.viewchange();
            }).then(()=>{
              window.location.reload();
            });
            this.fetchUserData(this.userDetails.id);
          }, (error) => console.error('error', error));
      }

    }
  }

  validateInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[0-9\s]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  fetchUserData(userId: number) {
    this.httpService.get(API_URL + '/app_users/' + userId, null, null)
        .subscribe((userData: any) => {
          this.userDetails.firstName=userData.data[0].firstName;
          this.userDetails.middleName=userData.data[0].middleName;
          this.userDetails.lastName=userData.data[0].lastName;
          this.userDetails.phone=userData.data[0].phone;
          this.userDetails.whatsapp=userData.data[0].whatsapp;
          localStorage.removeItem('userDetails');
          localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
        }, (error) => console.error('error', error));
  }


  // ************************************************************************

}
