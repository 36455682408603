import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {AuthService} from '../../../auth.service';
import {Router} from '@angular/router';
import {API_URL} from '../../../../../Global_API_URL';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [],
})
export class LoginComponent implements OnInit {
  invalid_cred = true;
  missing_cred = true;
  userName: string = '';
  password: string = '';
  formData: FormGroup;

  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };
  origin
  destination
  emailregex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
  emailFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.emailregex),
  ]);

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.formData = new FormGroup({
      userName: new FormControl('admin'),
      password: new FormControl('admin'),
    });
    if (this.authService.getAuthToken() != null) {
      this.router.navigate(['/quickstart']);
    }
    this.route.data.subscribe((data) => {
      document.title = data.title || 'Login - ICLTSCM';
    });
  }

  goToRegister() {
    this.router.navigateByUrl('register');
  }

  goToForgotPassword() {
    this.router.navigateByUrl('forgotpassword');
  }

  async onClickSubmit() {
    if (this.userName.trim() === '') {
      // Email is empty
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please enter email',
        timer: 5000,
      });
    } else if (!this.emailregex.test(this.userName)) {
      // Invalid email format
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please enter a valid email',
        timer: 5000,
      });
    } else if (this.password === '') {
      // Password is empty
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please enter password',
        timer: 5000,
      });
    } else {
      this.missing_cred = true;
      this.authService.login(this.userName, this.password)
    }
  }

  sortList: any;
  consignment_sts_update = {
    consignmentId: null,
    status: null,
    comment: '',
  }
  consignment_id;
  manifestation;
  consignment_track;
  consignment_with_tracker;
  consignment_status


  async getTrack() {
    if (this.consignment_id == null || this.consignment_id == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Consignment Number',
        timer: 5000,

      });
    } else if (this.consignment_id.length < 11) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'The Consignment Number should be 11 digits',
        timer: 5000,

      });
    }
    const response = await fetch(API_URL + '/tracking/consignment/' + this.consignment_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // "Authorization": "Bearer " + TOKEN
      },
    })
    if (response.status === 400) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Invalid Consignment Number',
        timer: 5000,
      });
      this.consignment_track = "";
      return;
    }

    const json = await response.json();
    this.consignment_track = json.data[0].trackingId.trackingStatusComments.sort((a, b) => a.id - b.id);
    this.origin = json.data[0].originLocation.city.name;
    this.destination = json.data[0].dropLocation.city.name;
    this.consignment_status = json.statuses;
    this.consignment_sts_update.status = null;
    this.consignment_sts_update.comment = '';
    this.consignment_track.sort((a, b) => a.id - b.id);
  }

  catch(error) {
    console.error('Error fetching consignment tracking data:', error);
  }

  getTime(date) {
    if (date) {
      const date2 = new Date(date);
      return (
        [
          date2.getFullYear(

          ),
          this.padTo2Digits(date2.getMonth() + 1),
          this.padTo2Digits(date2.getDate()),
        ].join('-') +
        ' ' +
        [
          this.padTo2Digits(date2.getHours()),
          this.padTo2Digits(date2.getMinutes()),
        ].join(':')
      );
    } else {
      return '-';
    }
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
}
