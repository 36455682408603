<mat-card style="padding: 20px;">
  <div class="card-header-title font-size-lg text-capitalize fw-Bold">
    <a (click)="goBackToCustomerTable()"><i class="fa fa-arrow-left"
                                            style="cursor: pointer"></i></a>&nbsp;<span
    style="color: blue; font-weight: bold; font-size: larger">EDIT CORPORATE CUSTOMER</span>
  </div>

  <mat-card-content>
    <div style="margin-left: 35px">
      <div style="display: flex">
        <div>
          <mat-form-field appearance="standard">
            <mat-label>
              GST No.<span class="text-danger">*</span>
            </mat-label>
            <input [disabled]="customerVerification.GSTVerified || customerVerification.gstData != null" type="text"
                   placeholder="GST" aria-label="Number"
                   (input)="customerVerification.transformGSTToUpperCaseForCreate()"
                   matInput #gst maxlength="15"
                   [(ngModel)]="customerVerification.gstin"
                   (keypress)="validateInput1($event)">
            <mat-hint class="text-danger fw-normal" *ngIf="customerVerification.gstFormControl.hasError('pattern')">
              Invalid GST No.
            </mat-hint>
            <mat-hint align="end">{{ gst.value.length }}/15</mat-hint>
          </mat-form-field>
        </div>
        <div class="m-3">
          <button class="btn btn-success" style="margin-left: 10px" (click)="customerVerification.gstVerification(customer_shipper, 'corporate', 'edit')"
                  [disabled]="customerVerification.progressBar || customerVerification.GSTVerified || customerVerification.gstData != null">
            Verify
          </button>
        </div>
      </div>
      <mat-progress-bar *ngIf="customerVerification.progressBar" mode="indeterminate"
                        style="width: 100%"></mat-progress-bar>
    </div>

    <br><br>

    <div style="margin-left: 35px"
         *ngIf="customerVerification.GSTVerified || this.customerVerification.gstData != null">
      <h5><b style="color: blue;">Master Details : </b></h5>

      <table class="masterTable">
        <tr>
          <td class="td1">GST No. <span style="float: right">:</span></td>
          <td class="td2">{{ customerVerification.gstData?.data?.gstin }}</td>
          <td class="td1">Street <span style="float: right">:</span></td>
          <td class="td2">{{ customerVerification.gstData?.data?.pradr?.addr?.st }}</td>
        </tr>

        <tr>
          <td class="td1">Legal Name <span style="float: right">:</span></td>
          <td class="td2">{{ customerVerification.gstData?.data?.lgnm }}</td>
          <td class="td1">Location <span style="float: right">:</span></td>
          <td class="td2">{{ customerVerification.gstData?.data?.pradr?.addr?.loc }}</td>
        </tr>

        <tr>
          <td class="td1">Trade Name <span style="float: right">:</span></td>
          <td class="td2">{{ customerVerification.gstData?.data?.tradeNam }}</td>
          <td class="td1">Landmark <span style="float: right">:</span></td>
          <td class="td2">{{ customerVerification.gstData?.data?.pradr?.addr?.landmark }}</td>
        </tr>

        <tr>
          <td class="td1">Status <span style="float: right">:</span></td>
          <td class="td2">{{ customerVerification.gstData?.data?.sts }}</td>
          <td class="td1">Building Name <span style="float: right">:</span></td>
          <td class="td2">{{ customerVerification.gstData?.data?.pradr?.addr?.bnm }}</td>
        </tr>

        <tr>
          <td class="td1">Taxpayer Type <span style="float: right">:</span></td>
          <td class="td2">{{ customerVerification.gstData?.data?.dty }}</td>
          <td class="td1">State <span style="float: right">:</span></td>
          <td class="td2">{{ customerVerification.gstData?.data?.pradr?.addr?.stcd }}</td>
        </tr>

        <tr>
          <td class="td1">Constitute of business <span style="float: right">:</span></td>
          <td class="td2">{{ customerVerification.gstData?.data?.ctb }}</td>
          <td class="td1">District <span style="float: right">:</span></td>
          <td class="td2">{{ customerVerification.gstData?.data?.pradr?.addr?.dst }}</td>
        </tr>

        <tr>
          <td class="td1" style="padding: 20px 0 20px 20px">Registered Date <span style="float: right">:</span></td>
          <td class="td2" style="padding: 20px 0 20px 20px">{{ customerVerification.gstData?.data?.rgdt }}</td>
          <td class="td1" style="padding: 20px 0 20px 20px"> Pincode <span style="float: right">:</span></td>
          <td class="td2" style="padding: 20px 0 20px 20px">{{ customerVerification.gstData?.data?.pradr?.addr?.pncd }}</td>
        </tr>
      </table>
    </div>

    <br><br>

    <div style="margin-left: 35px">
      <h5><b style="color: blue;">Company Details : </b></h5>

      <table class="compTable">
        <tr>
          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Display Name<span class="text-danger">*</span>
              </mat-label>
              <input type="text" placeholder="Display Customer Name" aria-label="Number" matInput
                     [(ngModel)]="customer_shipper.firstName">
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Email<span class="text-danger">*</span>
              </mat-label>
              <input type="text" placeholder="Email" aria-label="Number" matInput
                     [(ngModel)]="customer_shipper.email_1">
              <mat-hint class="text-danger fw-normal" *ngIf="customerVerification.emailFormControl.hasError('pattern')">
                Invalid
                email address
              </mat-hint>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Phone No.<span class="text-danger">*</span>
              </mat-label>
              <input type="text" placeholder="Phone No." #phone1
                     onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="Number"
                     matInput
                     maxlength="10" [(ngModel)]="customer_shipper.phone_1">
              <mat-hint align="end">{{ phone1.value.length }}/10
              </mat-hint>
            </mat-form-field>
          </td>

        </tr>

        <tr>
          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Address<span class="text-danger">*</span>
              </mat-label>
              <textarea placeholder="Address" matInput style="height: 15px"
                        [(ngModel)]="customer_shipper.address_1"></textarea>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Country<span class="text-danger">*</span>
              </mat-label>
              <mat-select matSelect matNativeControl [(ngModel)]="customerVerification.user_sel_country">
                <mat-option *ngFor="let con of customerVerification.countries |async" [value]="con.id"
                            (click)="customerVerification.getIndividaulStates(customer_shipper, 'corporate')">
                  {{ con.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                State<span class="text-danger">*</span>
              </mat-label>
              <mat-select matNativeControl [(ngModel)]="customerVerification.user_sel_state">
                <mat-option *ngFor="let sta of customerVerification.user_states |async" [value]="sta.id"
                            (click)="customerVerification.getIndividaulCities(customer_shipper, 'corporate')">
                  {{ sta.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>

        </tr>

        <tr>
          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                City<span class="text-danger">*</span>
              </mat-label>
              <mat-select matNativeControl [(ngModel)]="customer_shipper.city_1.id">
                <mat-option *ngFor="let cit of customerVerification.user_cities |async" [value]="cit.id"
                            (click)="customerVerification.getIndividaulPincodes(customer_shipper, 'corporate')">
                  {{ cit.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                Zip Code<span class="text-danger">*</span>
              </mat-label>
              <mat-select matNativeControl [(ngModel)]="customer_shipper.zip_code">
                <mat-option *ngFor="let cit of customerVerification.user_pincodes |async" [value]="cit.pinNumber">
                  {{ cit.pinNumber }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="py-0" appearance="standard">
              <mat-label>
                GST No.<span class="text-danger">*</span>
              </mat-label>
              <input type="text" placeholder="GST No." #gst2 aria-label="Number" matInput
                     maxlength="15" [(ngModel)]="customer_shipper.gst" disabled>
              <mat-hint align="end">{{ gst2.value.length }}/15
              </mat-hint>
            </mat-form-field>
          </td>

        </tr>

        <tr>
          <td>
          <mat-form-field class="py-0" appearance="standard">
            <mat-label>
              PAN No.<span class="text-danger">*</span>
            </mat-label>
            <input type="text" placeholder="PAN No." #pan aria-label="Number" matInput
                   maxlength="10" [(ngModel)]="customer_shipper.pan"
                   (input)="transformPANoUpperCaseForCreate()">
            <mat-hint align="end">{{ pan.value.length }}/10
            </mat-hint>
          </mat-form-field>
        </td>
          <td>
            <mat-form-field appearance="standard">
              <mat-label>
                Billing Branch<span class="text-danger">*</span>
              </mat-label>
              <mat-select matNativeControl [(ngModel)]="customer_shipper.office_location.id">
                <mat-option *ngFor="let office of customerVerification.officesList" [value]="office.id">
                  {{ office.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>

        <tr>
          <td>
            <section style="margin-top: 20px">
              <mat-checkbox [(ngModel)]="customer_shipper.isSezCustomer">Is SEZ Customer
              </mat-checkbox>
            </section>
          </td>
<!--          <td>-->
<!--            <section style="margin-top: 20px">-->
<!--              <mat-checkbox [(ngModel)]="customer_shipper.isContractualCustomer">Is Contractual-->
<!--                Customer-->
<!--              </mat-checkbox>-->
<!--            </section>-->
<!--          </td>-->
          <td></td>
          <td></td>
        </tr>

      </table>
    </div>
  </mat-card-content>

  <mat-card-actions align="end">
    <button mat-button class="btn btn-danger" (click)="goBackToCustomerTable()">Cancel</button>
    <button mat-button class="btn btn-primary" (click)="editCustomer()">Save</button>
  </mat-card-actions>
</mat-card>
