import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-login-boxed',
  templateUrl: './login-boxed.component.html',
  styles: [],
})
export class LoginBoxedComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
