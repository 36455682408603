<div class="col-md-8">
  <container-element [ngSwitch]="pageview">
    <some-element *ngSwitchCase="'table'">
      <div class="card">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            <b style="color: blue;">AIRLINES</b>
          </div>
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-primary" (click)="viewchange('add')">Add</div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="">
              <div class="table-responsive">
                <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
                  <thead>
                  <tr>
                    <th class="text-center">Airline Name</th>
                    <th class="text-center">IATA Code</th>
                    <th class="text-center">Prefix Code</th>
                    <th class="text-center">Action</th>
<!--                    <th class="text-center">Recommended</th>-->
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let air of airlines | async" [ngClass]="air.isActive == false ? 'red':'white'">
                    <td class="text-center">
                      <a [routerLink]="" (click)="viewsection('view',air)">
                        {{air.name}}
                      </a>
                    </td>
                    <td class="text-center">
                      {{air.iata_code}}
                    </td>
                    <td class="text-center">
                      {{air.iata_code_mawb}}
                    </td>
                    <td class="text-center">

                      <div role="button" class="btn-group-sm btn-group m-1">
                        <button class="btn-shadow btn btn-primary" matTooltip="Edit"
                                *ngIf="air.isActive == 1"
                                (click)="viewchange1('edit',air)">
                          <i class="lnr-pencil"></i></button>
                      </div>
                      <div role="button" class="btn-group-sm btn-group ">
                        <button *ngIf="air.isActive == 1"
                                class="btn-shadow btn btn-danger"
                                (click)="DeActivateAirline(air,0)">
                          <i class="pe-7s-trash"></i></button>
                      </div>
                      <div role="button" class="btn-group-sm btn-group ">
                        <button *ngIf="air.isActive == 0"
                                class="btn-shadow btn btn-primary"
                                (click)="ActivateAirline(air,1)"><i
                          class="fa fa-undo"></i></button>
                      </div>
                    </td>
<!--                  <td class="text-center">-->
<!--                    <div  role="button">-->
<!--                      <mat-slide-toggle style="align-self: center" (change)="updateRecommended(air.id,$event)" [(ngModel)]="air.recommended"></mat-slide-toggle>-->
<!--                    </div>-->
<!--                  </td>-->

                  </tbody>
                </table>
                <!-- <mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
                               [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
                               [pageIndex]="pageIndex" aria-label="Select page">
                </mat-paginator> -->
                <mat-paginator [length]="airlineList?.totalElements" [pageIndex]="airlineList?.number"
                  [pageSizeOptions]="[10, 20, 50]" [pageSize]="pageSize" (page)="handlePageEvent($event)"
                  showFirstLastButtons>
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'add'">
      <div class="card">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            <b style="color: blue;">ADD AIRLINE</b>
          </div>

        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Airline Name
                </mat-label>
                <input type="text" placeholder="Airline Name" aria-label="Number"
                       [(ngModel)]="newAirline.name" matInput>

              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  IATA Code
                </mat-label>
                <input type="text" placeholder="IATA Code" aria-label="Number"
                       [(ngModel)]="newAirline.iata_code" matInput>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Prefix Code
                </mat-label>
                <input type="text" placeholder="Prefix Code" aria-label="Number"
                       [(ngModel)]="newAirline.iata_code_mawb" matInput>

              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="createAirline()">Add</div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'edit'">
      <div class="card">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            <b style="color: blue;">EDIT AIRLINE DETAILS</b>
          </div>

        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Airline Name
                </mat-label>
                <input type="text" [(ngModel)]="newAirline.name" placeholder="Airline Name"
                       aria-label="Number" matInput>

              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  IATA Code
                </mat-label>
                <input type="text" [(ngModel)]="newAirline.iata_code" placeholder="IATA Code"
                       aria-label="Number" matInput>

              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Prefix Code
                </mat-label>
                <input type="text" [(ngModel)]="newAirline.iata_code_mawb" placeholder="Prefix Code"
                       aria-label="Number" matInput>

              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="editAirline()">Save</div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'view'">
      <div class="card">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            View Airline
          </div>

        </div>

        <div class="container m-2">
          <div class="row ">
            <div class="col-md-3 fw-bold">Airline Name</div>
            <div class="col-md-3"> {{viewairline.name}} </div>
          </div>
          <div class="row ">
            <div class="col-md-3 fw-bold">IATA Code</div>
            <div class="col-md-3"> {{viewairline.iata}} </div>
          </div>
          <div class="row ">
            <div class="col-md-3 fw-bold">Prefix Code</div>
            <div class="col-md-3"> {{viewairline.mawb}} </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Back</div>
          </div>
        </div>
      </div>
    </some-element>
  </container-element>
</div>
