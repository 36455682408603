<div class="container">
  <div class="card p-1 ">
    <div class="card-header">

      <div class="card-header-title font-size-lg text-capitalize fw-Bold">
        <b style="color: blue;">MANIFESTATION</b>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card m-2 border border-secondary" style="height: 70vh;background-color: #8080805c;">

          <div class="card-header-title font-size-lg text-capitalize fw-normal px-2">
            List of bookings
            <div class="btn btn-primary float-end mt-2" (click)="getPendingCons()" (click)="open(pendingcons)">Pending
              Consignments</div>
          </div>
          <!-- <div class="fw-bold text-center font-size-lg">
            HYD -- DLH
          </div> -->
          <ng-template #pendingcons let-c="close" let-d="dismiss">
            <div class="modal-header">
              Pending Consignments
            </div>
            <div class="modal-body" style="height: 70vh;background-color: #8080805c;">
              <perfect-scrollbar class="h-75">

                <div class="card m-2 border border-secondary" style="font-size:small;" *ngFor="let man of PendingList">

                  <div class="widget-content p-1">
                    <div class="widget-content-wrapper d-flex justify-content-around fy-2">
                      <div class="">
                        <div class="widget-heading text-dark opacity-7">
                          S̥ource
                        </div>
                        <div class="fs-3 fw-bold m-auto text-primary">
                          {{man.source.iataCode}}
                        </div>
                      </div>
                      <div class="">
                        <div class="widget-heading text-dark opacity-7">
                          Destination
                        </div>
                        <div class="fs-3 fw-bold m-auto text-primary">
                          {{man.dest.iataCode}}
                        </div>
                      </div>
                      <div class="">
                        <div class="widget-heading text-dark opacity-7">
                          Consignments
                        </div>
                        <div class="fs-4 fw-bold m-auto text-primary float-end">
                          {{man.numOfConsignment}}
                        </div>
                      </div>
                      <div class="">
                        <button class="m-auto btn btn-success" (click)="selectPendingManifest(man)">
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
              <!-- <button type="button" class="btn btn-primary" (click)="createRep()">Create</button> -->
            </div>
          </ng-template>


          <div class="px-2">
            <div class="row">
              <div class="col-md-6 row">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Origin Location<span class="text-danger">*</span>
                  </mat-label>
                  <input type="text" placeholder="Origin Location" aria-label="Number" matInput
                    [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="origin">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let cit of filteredAirports | async" [value]="cit">
                      {{cit.city.name}}- {{cit.iataCode}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

              </div>

              <div class="col-md-6 row">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Destination Location <span class="text-danger">*</span>
                  </mat-label>
                  <input type="text" placeholder="Origin Location" aria-label="Number" matInput
                    [formControl]="myControl2" [matAutocomplete]="auto2" [(ngModel)]="destination">
                  <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn2">
                    <mat-option *ngFor="let cit of filteredAirports2 | async" [value]="cit">
                      {{cit.city.name}} - {{cit.iataCode}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <ngx-loading [show]="loading_left"
                  [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                  [template]="loadingTemplate"></ngx-loading>
              </div>
            </div>
            <div class="col-md-8 text-dark text-center fw-bold">Search:
              <button class="btn-shadow btn btn-primary m-2" [disabled]="this.origin==null || this.destination==null" (click)="get_flight('12')">12 Hr</button>
              <button class="btn-shadow btn btn-primary m-2" [disabled]="this.origin==null || this.destination==null" (click)="get_flight('24')">24 Hr</button>
              <button class="btn-shadow btn btn-primary m-2" [disabled]="this.origin==null || this.destination==null" (click)="get_flight('36')">36 Hr</button>
            </div>
          </div>

          <perfect-scrollbar style="display: 100%;">
            <div *ngFor="let con of listConsignments">
              <div class="card m-2 border border-secondary" style="font-size:small;"
                *ngIf="con.trackingId.status.order < 11000">

                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left me-3" (click)="open(viewcons)">

                      <div class=" m-0 text-center" style="cursor: pointer">
                        <b class="text-primary d-block w-100 fsize-1 ps-3">
                          <span>
                            {{con.consignmentNo}}
                          </span>
                        </b>
                      </div>
                      <ng-template #viewcons let-c="close" let-d="dismiss">
                        <div class="modal-body">
                          <div class="row">
                            <div class="card col-md-12">
                              <div class="card-header">

                                <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                                  Consignment No.
                                </div>
                                <div class="card-header-title font-size-lg text-capitalize fw-Bold text-primary mx-4">
                                  {{con.consignmentNo}} </div>
                              </div>
                              <div class="container m-2">

                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="col-md-3 fw-bold">Customer Name</div>
                                    <div class="col-md-3"> {{con.senderId?.firstName}} </div>
                                    <div class="col-md-3 fw-bold border-start">Consignment Type</div>
                                    <div class="col-md-3"> {{con.consignmentType.name}} </div>
                                  </div>
                                  <mat-divider class="m-1"></mat-divider>
                                  <div class="row ">
                                    <div class="col-md-3 fw-bold">Origin</div>
                                    <div class="col-md-3"> {{con.originLocation.iataCode}} </div>
                                    <div class="col-md-3 fw-bold border-start">Destination</div>
                                    <div class="col-md-3"> {{con.destinationLocation.iataCode}} </div>
                                  </div>
                                  <mat-divider class="m-1"></mat-divider>
                                  <div class="row ">
                                    <div class="col-md-3 fw-bold">Created At</div>
                                    <div class="col-md-3"> {{formatDate1(con.createdAt)}} </div>
                                    <div class="col-md-3 fw-bold border-start">Updated At</div>
                                    <div class="col-md-3" *ngIf="con.updatedAt!=null"> {{formatDate1(con.updatedAt)}}
                                    </div>
                                  </div>
                                  <mat-divider class="m-1"></mat-divider>
                                  <div class="row ">
                                    <div class="col-md-3 fw-bold ">Consignment Value</div>
                                    <div class="col-md-3"> {{con.consignmentValue}}</div>

                                    <div class="col-md-3 fw-bold border-start ">Status</div>
                                    <div *ngIf="con.trackingId" class="badge bg-pill bg-primary col-md-3" style="width: 193px; margin-left: -8px">
                                      {{con.trackingId?.status?.name}}
                                    </div>
                                  </div>
                                  <mat-divider class="mb-3"></mat-divider>

                                  <div class="row">
                                    <div class="text-primary fw-bold"> PACKAGES</div>
                                  </div>
                                  <mat-divider class="m-1"></mat-divider>
                                  <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
                                    <thead>
                                      <tr>
                                        <th class="text-center">Dimensions (L x B x H)</th>
                                        <th class="test-center">Total Packages</th>
                                        <th class="text-center">Weight Per Package</th>
                                        <th class="text-center">Chargeable Weight</th>
                                        <th class="text-center">Total Chargeable Weight</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let pack of con.packages">
                                        <!-- <td colspan="2">	</td> -->
                                        <td class="text-center">
                                          <!-- <div *ngFor="let pack of manifest_view.packages"> -->
                                          {{pack.length}}cm x {{pack.breadth}}cm x {{pack.height}}cm
                                          <br />
                                          <!-- </div> -->
                                        </td>
                                        <td class="text-center">
                                          {{pack.package_no}}
                                        </td>
                                        <td class="text-center">
                                          {{pack.weight_per_package}}Kg
                                        </td>
                                        <td class="text-center">
                                          {{pack.total_weight}}Kg
                                        </td>
                                        <td class="text-center">
                                          {{pack.chargeable_weight}}Kg
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <mat-divider class="m-1"></mat-divider>
                                  <div class="row ">
                                    <div class="col-md-3 fw-bold  ">Total Num of Packages</div>
                                    <div class="col-md-3">
                                      {{con.totalNoOfPackages}}
                                    </div>
                                  </div>
                                  <mat-divider class="m-1"></mat-divider>
                                  <div class="row ">
                                    <div class="col-md-3 fw-bold ">Total Weight</div>
                                    <div class="col-md-3"> {{con.totalWeight}}Kg </div>
                                    <div class="col-md-3 fw-bold border-start ">Total Chargeable Weight</div>
                                    <div class="col-md-3"> {{con.totalChargableWeight}}Kg </div>
                                  </div>
                                  <mat-divider class="m-1"></mat-divider>
<!--                                  <div class="row ">-->
<!--                                    <div class="col-md-3 fw-bold ">Shipping Cost</div>-->
<!--                                    <div class="col-md-3">-->
<!--                                      {{con.shippingCost}}-->
<!--                                    </div>-->
<!--                                    <div class="col-md-3 fw-bold border-start">Total Shipping Cost</div>-->
<!--                                    <div class="col-md-3">-->
<!--                                      {{con.totalShippingCost}}-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                  <mat-divider class="mb-3"></mat-divider>-->
                                  <div class="row">
                                    <div class=" col-md-6 text-primary fw-bold"> SHIPPER ADDRESS</div>
                                    <div class=" col-md-6 text-primary fw-bold"> CONSIGNEE ADDRESS</div>
                                  </div>
                                  <mat-divider class="m-1"></mat-divider>
                                  <div class="row ">
                                    <div class="col-md-3 fw-bold">Pickup Address</div>
                                    <div class="col-md-3"> {{con.pickupAddress?.address}}
                                      {{con?.pickupAddress?.pincodeId?.cityId?.name}},{{con?.pickupAddress?.pincodeId?.cityId?.state_id?.name}},
                                      <br>
                                      {{con?.pickupAddress?.pincodeId?.cityId?.state_id?.country?.name}},{{con?.pickupAddress?.pincodeId?.pin_number}}</div>
                                    <div class="col-md-3 fw-bold border-start ">Drop Address</div>
                                    <div class="col-md-3">
                                      {{con.receiverId.address + "," + con?.receiverId?.city?.name + "," +
                                    con?.receiverId?.city?.state_id?.name}},
                                      <br>
                                      {{con?.receiverId?.city?.state_id?.country?.name}},{{con?.receiverId?.zipCode}}
                                    </div>
                                  </div>
                                </div>
                                <!-- <div class="card-footer">

                                  <div class="btn-actions-pane-right p-2">
                                    <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table',0)">Back</div>
                                  </div>
                                </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
                          <!-- <button type="button" class="btn btn-primary" (click)="createRep()">Create</button> -->
                        </div>
                      </ng-template>
                    </div>
                    <div class="widget-content-left fsize-1">
                      <div class="widget-subheading opacity-10">
                        <i class="fa fa-fw">
                          
                        </i>
                        {{con?.series_ref.productType.name}} - {{con?.consignmentType?.name}} - {{con?.consignmentSubType?.name}} - {{con.preManifestationId?.flightScheduleLeg1?.flightNo}}

                        <!-- {{con.consignmentId.consignmentType.name}} - -->
                      </div>
                      <div class="widget-subheading opacity-10">
                        <i class="fa fa-fw">
                          
                        </i>
                        {{formatDate1(con.createdAt)}}
                      </div>
                      <!-- <div class="widget-subheading opacity-10">
                      <i class="fa fa-fw">
                        
                      </i>
                      {{con.flightNo}} -  {{con.airline}}
                    </div> -->
                    </div>
                    <div class="widget-content-right fsize-1 ">
                      <div class="widget-heading text-dark opacity-7">
                        <!-- {{con.consignmentId.consignmentType}} -  -->
                      </div>
                      <div class="widget-subheading opacity-10 ps-2">
                        <i class="fa fa-fw">
                          
                        </i>
                        <!-- {{con.consignmentId.totalChargableWeight}} KG -->

                        {{con.totalChargableWeight}} KG
                      </div>
                    </div>
                    <div class="widget-content-right">
                      <div class="icon-wrapper m-0">
                        <button *ngIf="con.series_ref.productType.id===this.manifest?.productType.id && con.consignmentType.id===this.manifest?.consignmentType.id && con.consignmentSubType.id===this.manifest?.consignmentSubtype.id" class="m-auto btn-icon btn-icon-only btn btn-primary" (click)="addToManifest(con)"><i
                            class="pe-7s-angle-right-circle btn-icon-wrapper"> </i></button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </perfect-scrollbar>

        </div>
      </div>
      <div class="col-md-6">
        <mat-card *ngIf="menifestationDetails" class="card m-2 border border-secondary"
          style="height: 70vh; background-color: #8080805c;">
          <div class="card-header-title font-size-lg text-capitalize fw-normal px-2">
            Manifest <span><button class="btn-shadow btn btn-primary m-2 float-end" [disabled]="this.origin==null || this.destination==null" (click)="createManifestService()"  (click)="open(createManifest)" >Add Manifest</button></span>
<!--            Manifest <span><button class="btn-shadow btn btn-primary m-2 float-end"  (click)="open(selectProductTypes)" (click)="createManifestService()">Add Manifest</button></span>-->
<!--            Manifest <span><button class="btn-shadow btn btn-primary m-2 float-end" [disabled]="this.origin==null || this.destination==null" (click)="open(createManifest)"-->

          </div>
          <ng-template #createManifest let-c="close" let-d="dismiss">
            <div class="modal-header">
              <h4 class="modal-title">Create Manual Manifest</h4>
              <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
              </button>
            </div>
            <ngx-loading [show]="loading"
              [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
              [template]="loadingTemplate"></ngx-loading>
            <div class="modal-body">
              <div class="row m-2" *ngFor="let plan of flightSchedule.bestRoutes ">
                <div class="col-md-12 card" style="width:100%">
                  <div class="row p-2 w-100">
                    <div class="col-md-4">
                      <div class="widget-chart-content">
                        <div class="widget-subheading fw-bold">Origin Location</div>
                        <div class="widget-numbers text-primary fs-4"><span>{{flightSchedule.origin}} </span></div>
                        <div class="widget-description text-focus fw-bold">
                          <div matTooltip="Consignment Drop Time">
                            CDT:
                          </div>
                          <span class="text-info ps-1 ">
                            <!-- <fa-icon [icon]="faAngleUp"></fa-icon> -->
                            <span class="ps-1"> {{formatDate1(plan.dropTime)}}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="widget-subheading fw-bold">Transit Time</div>

                      <div class="widget-numbers text-warning fs-4">
                        <span>{{getTranisitTime(plan.totalTranistTime)}} </span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="widget-subheading fw-bold">Destination Location</div>
                      <div class="widget-numbers text-primary fs-4"><span>{{flightSchedule.destination}}</span></div>
                      <div class="widget-description text-focus fw-bold">
                        <div matTooltip="Consignment Pickup Time">
                          CPT:
                        </div>
                        <span class="text-info ps-1 ">
                          <!-- <fa-icon [icon]="faAngleUp"></fa-icon> -->
                          <span class="ps-1">{{formatDate1(plan.pickupTime)}}</span>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-1 center ">
                      <div class="">
                        <button class="btn btn-success my-auto" mat-button (click)="open(selectProductTypes)" (click)="d('Cross click')" (click)="selectManualManifestPLan(plan)">
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="table-responsive">
                      <table class="table w-100">
                        <thead>
                          <tr>
                            <th>
                              Origin
                            </th>
                            <th>
                              Route
                            </th>
                            <th>
                              Airlines
                            </th>
                            <th>
                              Flight No.
                            </th>
                            <th>
                              Departure Time
                            </th>
                            <th>
                              Transit Time
                            </th>
                            <th>
                              Arrival TIme
                            </th>
                            <th>
                              Destination
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let route of plan.routes">
                            <td>
                              {{route.departureAirportID.iataCode}}
                            <td>
                              {{route.transitMode}}
                            </td>
                            <td>
                              <div *ngIf="route.airlineID">
                                {{route.airlineID.name}}
                              </div>
                              <ng-template>

                                <div class="text-center">
                                  -
                                </div>
                              </ng-template>
                            </td>
                            <td>
                              <div *ngIf="route.flightNo != null">
                                {{route.flightNo}}
                              </div>
                              <ng-template>
                                <div class="text-center">
                                  -
                                </div>
                              </ng-template>
                            </td>
                            <td>
                              {{formatDate1(route.departureTime)}}
                            </td>
                            <td>
                              {{getTranisitTime(route.transitTime)}}
                            </td>
                            <td>
                              {{formatDate1(route.arrivalTime)}}
                            </td>
                            <td>
                              {{route.arrivalAirportID.iataCode}}
                            </td>
                          </tr>
                        </tbody>
                      </table>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template #selectProductTypes let-c="close" let-d="dismiss">
            <div class="modal-header">
              <h4 class="modal-title">Select Product and CN Type & Subtype</h4>
              <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
              </button>
            </div>
            <ngx-loading [show]="loading"
                         [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                         [template]="loadingTemplate"></ngx-loading>
            <div class="modal-body">
              <div>
                <div class="col-md-12 card" style="width:100%">
                  <div class="row p-2 w-100">
                    <div class="col-md-4">
                      <div class="widget-chart-content">
                        <div class="widget-subheading fw-bold">Origin Location</div>
                        <div class="widget-numbers text-primary fs-4"><span>{{flightSchedule.origin}} </span></div>
                        <div class="widget-description text-focus fw-bold">
                          <div matTooltip="Consignment Drop Time">
                            CDT:
                          </div>
                          <span class="text-info ps-1 ">
                            <!-- <fa-icon [icon]="faAngleUp"></fa-icon> -->
                            <span class="ps-1"> {{formatDate1(selectedManualManifest.dropTime)}}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="widget-subheading fw-bold">Transit Time</div>

                      <div class="widget-numbers text-warning fs-4">
                        <span>{{getTranisitTime(selectedManualManifest.totalTranistTime)}} </span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="widget-subheading fw-bold">Destination Location</div>
                      <div class="widget-numbers text-primary fs-4"><span>{{flightSchedule.destination}}</span></div>
                      <div class="widget-description text-focus fw-bold">
                        <div matTooltip="Consignment Pickup Time">
                          CPT:
                        </div>
                        <span class="text-info ps-1 ">
                          <!-- <fa-icon [icon]="faAngleUp"></fa-icon> -->
                          <span class="ps-1">{{formatDate1(selectedManualManifest.pickupTime)}}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="table-responsive">
                      <table class="table w-100">
                        <thead>
                        <tr>
                          <th>
                            Origin
                          </th>
                          <th>
                            Route
                          </th>
                          <th>
                            Airlines
                          </th>
                          <th>
                            Flight No.
                          </th>
                          <th>
                            Departure Time
                          </th>
                          <th>
                            Transit Time
                          </th>
                          <th>
                            Arrival TIme
                          </th>
                          <th>
                            Destination
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let route of selectedManualManifest.routes">
                          <td>
                            {{route.departureAirportID.iataCode}}
                          <td>
                            {{route.transitMode}}
                          </td>
                          <td>
                            <div *ngIf="route.airlineID">
                              {{route.airlineID.name}}
                            </div>
                            <ng-template>

                              <div class="text-center">
                                -
                              </div>
                            </ng-template>
                          </td>
                          <td>
                            <div *ngIf="route.flightNo != null">
                              {{route.flightNo}}
                            </div>
                            <ng-template>
                              <div class="text-center">
                                -
                              </div>
                            </ng-template>
                          </td>
                          <td>
                            {{formatDate1(route.departureTime)}}
                          </td>
                          <td>
                            {{getTranisitTime(route.transitTime)}}
                          </td>
                          <td>
                            {{formatDate1(route.arrivalTime)}}
                          </td>
                          <td>
                            {{route.arrivalAirportID.iataCode}}
                          </td>
                        </tr>
                        </tbody>
                      </table>


                    </div>
                  </div>
                </div>
              </div>

              <mat-card>
                <mat-form-field appearance="standard" style="margin-left: 1rem">
                  <mat-label for="productType">
                    Product Type
                  </mat-label>
                  <mat-select [(ngModel)]="selectedProductType">
                    <mat-option (click)="selectProductType(option)" *ngFor="let option of productsList" [value]="option">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              &nbsp;
                <mat-form-field appearance="standard" >
                  <mat-label for="productType">
                    Consignment Type
                  </mat-label>
                  <mat-select [(ngModel)]="selectedConsignmentType" >
                    <mat-option (click)="selectConsignmentType(option)" *ngFor="let option of selectedConsignmentTypesList" [value]="option">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              &nbsp;
                <mat-form-field appearance="standard">
                  <mat-label for="productType">
                    Consignment Subtype
                  </mat-label>
                  <mat-select [(ngModel)]="selectedConsignmentSubtype">
                    <mat-option *ngFor="let option of selectedConsignmentSubtypeList" [value]="option">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                &nbsp;
<!--                <div class="col-md-1 center ">-->
                    <button   class="btn btn-actions-pane-right btn-success my-auto" mat-button (click)="createmanifest(selectedManualManifest)">
                      Create
                    </button>
<!--                </div>-->
              </mat-card>
            </div>
          </ng-template>
          <div *ngIf="manifest" class="fw-bold text-center font-size-lg">
            <div *ngIf="manifest.isStopover==true">
              {{manifest?.flightScheduleLeg1.departureAirportID.iataCode}} --
              {{manifest?.flightScheduleLeg2.arrivalAirportID.iataCode}}
            </div>
            <div *ngIf="manifest.isStopover!=true">
              {{manifest?.flightScheduleLeg1.departureAirportID.iataCode}} --
              {{manifest?.flightScheduleLeg1.arrivalAirportID.iataCode}}

            </div>
          </div>
          <mat-tab-group *ngIf="manifest" (selectedTabChange)="getManifestConsignments($event)">
            <div *ngFor="let flight of menifestationDetails">
              <mat-tab>
                <ng-template mat-tab-label>
                  <span [matTooltip]=" flight.isStopover==false ?  flight.flightScheduleLeg1.flightNo +'-'+ flight.productType?.name+'-'+ flight.consignmentType?.name+'-'+ flight.consignmentSubtype?.name : flight.flightScheduleLeg1.flightNo +' / '+ flight.flightScheduleLeg2.flightNo+'-'+ flight.productType?.name+'-'+ flight.consignmentType?.name+'-'+ flight.consignmentSubtype?.name">
                    {{  (flight.isStopover==false ?  flight.flightScheduleLeg1.flightNo +'-'+ flight.productType?.name+'-'+ flight.consignmentType?.name+'-'+ flight.consignmentSubtype?.name : flight.flightScheduleLeg1.flightNo +' / '+ flight.flightScheduleLeg2.flightNo+'-'+ flight.productType?.name+'-'+ flight.consignmentType?.name+'-'+ flight.consignmentSubtype?.name) | slice:0:15 }} {{  (flight.isStopover==false ?  flight.flightScheduleLeg1.flightNo +'-'+ flight.productType.name+'-'+ flight.consignmentType.name+'-'+ flight.consignmentSubtype.name : flight.flightScheduleLeg1.flightNo +' / '+ flight.flightScheduleLeg2.flightNo+'-'+ flight.productType.name+'-'+ flight.consignmentType.name+'-'+ flight.consignmentSubtype.name).length >15 ? '...':'' }}
                  </span>
                </ng-template>
                <div class="row m-auto" style="width:90% ;" style="background-color: whitesmoke;">
                  <div class="col-md-4 border-dark border-end border-bottom">
                    <div class="fw-bold">
                      Manifestation Id
                    </div>
                    <span>
                      {{manifest?.id}}
                    </span>
                  </div>
                  <div class="col-md-4 border-dark border-end border-bottom">
                    {{manifest?.manifestIdLeg1}}
                  </div>
                  <div class="col-md-4 border-dark border-bottom">
                    {{manifest?.manifestIdLeg2}}
                  </div>
                </div>
                <div class="row m-auto" style="width:90% ;" style="background-color: whitesmoke;">
                  <div class="col-md-4 border-dark border-end border-bottom">
                    <div class="fw-bold">
                      Depature time :
                    </div>
                    <span>

                      {{formatDate(manifest?.shippingTime)}}
                    </span>
                  </div>
                  <div class="col-md-4  border-dark border-end border-bottom">
                    <div class="fw-bold">
                      Freeze Time :
                    </div>
                    <span>

                      {{timeleft(manifest?.shippingTime)}}
                    </span>
                  </div>
                  <div class="col-md-4 border-dark border-bottom">
                    <div class="fw-bold">
                      Total Weight :
                    </div>
                    {{total_manifest_weight}}
                  </div>
                  <div class="col-md-12">
                    <div class="widget-subheading opacity-10">
                      <i class="fa fa-fw">
                        
                      </i>
                      {{manifest?.flightScheduleLeg1.flightNo}} - {{manifest?.flightScheduleLeg1.airlineID.name}}
                      <span *ngIf="manifest?.isStopover==true">
                        /{{manifest?.flightScheduleLeg2.flightNo}} - {{manifest?.flightScheduleLeg2.airlineID.name}}

                      </span>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </div>
          </mat-tab-group>
          <perfect-scrollbar *ngIf="manifest" [autoPropagation]="true" style="display: 100%;">

            <div class="card m-2 border border-secondary" style="font-size:small;"
              *ngFor="let con of manifestConsignemnts">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left me-3" (click)="open(viewcons)">

                    <div class=" m-0 text-center">
                      <b class="text-primary d-block w-100 fsize-1 ps-3">
                        <span>
                          {{con.consignmentNo}}
                        </span>
                      </b>
                    </div>
                    <ng-template #viewcons let-c="close" let-d="dismiss">
                      <div class="modal-body">
                        <div class="row">
                          <div class="card col-md-12">
                            <div class="card-header">

                              <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                                Consignment No.
                              </div>
                              <div class="card-header-title font-size-lg text-capitalize fw-Bold text-primary mx-4">
                                {{con.consignmentNo}} </div>
                            </div>
                            <div class="container m-2">

                              <div class="col-md-12">
                                <div class="row">
                                  <div class="col-md-3 fw-bold">Customer Name</div>
                                  <div class="col-md-3"> {{con.senderId?.firstName}} </div>
                                  <div class="col-md-3 fw-bold border-start">Consignment Type</div>
                                  <div class="col-md-3"> {{con.consignmentType.details}} </div>
                                </div>
                                <mat-divider class="m-1"></mat-divider>
                                <div class="row ">
                                  <div class="col-md-3 fw-bold">Origin</div>
                                  <div class="col-md-3"> {{con.originLocation.iataCode}} </div>
                                  <div class="col-md-3 fw-bold border-start">Destination</div>
                                  <div class="col-md-3"> {{con.destinationLocation.iataCode}} </div>
                                </div>
                                <mat-divider class="m-1"></mat-divider>
                                <div class="row ">
                                  <div class="col-md-3 fw-bold">Created At</div>
                                  <div class="col-md-3"> {{formatDate1(con.createdAt)}} </div>
                                  <div class="col-md-3 fw-bold border-start">Updated At</div>
                                  <div class="col-md-3" *ngIf="con.updatedAt!=null"> {{formatDate1(con.updatedAt)}}
                                  </div>
                                </div>
                                <mat-divider class="m-1"></mat-divider>
                                <div class="row ">
                                  <div class="col-md-3 fw-bold ">Consignment Value</div>
                                  <div class="col-md-3"> {{con.consignmentValue}}</div>

                                  <div class="col-md-3 fw-bold border-start ">Status</div>
                                  <div *ngIf="con.trackingId" class="badge bg-pill bg-primary col-md-3">
                                    {{con.trackingId?.status?.name}}
                                  </div>
                                </div>
                                <mat-divider class="mb-3"></mat-divider>

                                <div class="row">
                                  <div class="text-primary fw-bold"> PACKAGES</div>
                                </div>
                                <mat-divider class="m-1"></mat-divider>
                                <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
                                  <thead>
                                    <tr>
                                      <th class="text-center">Dimensions</th>
                                      <th class="test-center">Total Packages</th>
                                      <th class="text-center">Weight Per Package</th>
                                      <th class="text-center">Chargeable Weight</th>
                                      <th class="text-center">Total Chargeable Weight</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let pack of con.packages">
                                      <!-- <td colspan="2">	</td> -->
                                      <td class="text-center">
                                        <!-- <div *ngFor="let pack of manifest_view.packages"> -->
                                        {{pack.length}}*{{pack.breadth}}*{{pack.height}}
                                        <br />
                                        <!-- </div> -->
                                      </td>
                                      <td class="text-center">
                                        {{pack.package_no}}
                                      </td>
                                      <td class="text-center">
                                        {{pack.weight_per_package}}
                                      </td>
                                      <td class="text-center">
                                        {{pack.total_weight}}
                                      </td>
                                      <td class="text-center">
                                        {{pack.chargeable_weight}}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <mat-divider class="m-1"></mat-divider>
                                <div class="row ">
                                  <div class="col-md-3 fw-bold  ">Total Num of Packages</div>
                                  <div class="col-md-3">
                                    {{con.totalNoOfPackages}}
                                  </div>
                                </div>
                                <mat-divider class="m-1"></mat-divider>
                                <div class="row ">
                                  <div class="col-md-3 fw-bold ">Total Weight</div>
                                  <div class="col-md-3"> {{con.totalWeight}} </div>
                                  <div class="col-md-3 fw-bold border-start ">Total Chargeable Weight</div>
                                  <div class="col-md-3"> {{con.totalChargableWeight}} </div>
                                </div>
                                <mat-divider class="m-1"></mat-divider>
                                <div class="row ">
                                  <div class="col-md-3 fw-bold ">Shipping Cost</div>
                                  <div class="col-md-3">
                                    {{con.shippingCost}}
                                  </div>
                                  <div class="col-md-3 fw-bold border-start">Total Shipping Cost</div>
                                  <div class="col-md-3">
                                    {{con.totalShippingCost}}
                                  </div>
                                </div>
                                <mat-divider class="mb-3"></mat-divider>
                                <div class="row">
                                  <div class=" col-md-6 text-primary fw-bold"> PICKUP ADDRESS</div>
                                  <div class=" col-md-6 text-primary fw-bold"> DROP ADDRESS</div>
                                </div>
                                <mat-divider class="m-1"></mat-divider>
                                <div class="row ">
                                  <div class="col-md-3 fw-bold">Pickup Address</div>
                                  <div class="col-md-3"> {{con.pickupAddress.address}}</div>
                                  <div class="col-md-3 fw-bold border-start ">Drop Address</div>
                                  <div class="col-md-3">
                                    {{con.receiverId.address}}
                                  </div>
                                </div>
                              </div>
                              <!-- <div class="card-footer">

                                <div class="btn-actions-pane-right p-2">
                                  <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table',0)">Back</div>
                                </div>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
                        <!-- <button type="button" class="btn btn-primary" (click)="createRep()">Create</button> -->
                      </div>
                    </ng-template>
                  </div>
                  <div class="widget-content-left fsize-1">
                    <div class="widget-subheading opacity-10">
                      <i class="fa fa-fw">
                        
                      </i>
                      {{con.consignmentType.name}} - {{con.preManifestationId?.flightScheduleLeg1?.flightNo}}
                    </div>

                    <div class="widget-subheading opacity-10">
                      <i class="fa fa-fw">
                        
                      </i>
                      {{formatDate1(con.createdAt)}}
                    </div>
                  </div>
                  <div class="widget-content-right fsize-1 ">
                    <div class="widget-heading text-dark opacity-7">
                      <!-- {{con.consignmentId.consignmentType}} -  -->
                    </div>
                    <div class="widget-subheading opacity-10 ps-2">
                      <i class="fa fa-fw">
                        
                      </i>
                      {{con.totalChargableWeight}} KG
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="icon-wrapper m-0">
                      <button [disabled]="selectedManifest && selectedManifest.freezed!= false"
                        class="m-auto btn-icon btn-icon-only btn btn-danger" (click)="removeFromManifest(con)"><i
                          class="pe-7s-angle-left-circle btn-icon-wrapper"> </i></button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- -->
              <button [disabled]="manifest && manifest?.freezed!= false || manifestConsignemnts.length==0"
                      class="m-auto col-md-4 m-auto btn btn-primary" style="right: 0; bottom: 0" (click)="freezmanifest(manifest?.id)">
                Freeze Manifest
              </button>

            </div>
          </perfect-scrollbar>
          <ngx-loading [show]="loading_right==true|| loading_left==true"
            [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
            [template]="loadingTemplate"></ngx-loading>
          <mat-card-footer *ngIf="manifest" class="px-2 py-1">

          </mat-card-footer>
        </mat-card>
      </div>
    </div>


  </div>
