<div class="col-md-12">
    <div class="card">
        <div class="card-header">
            <div class="card-header-title font-size-lg text-capitalize fw-bold">
                <b style="color: blue;">ADD PAPER WORK</b>
            </div>
        </div>
        <!-- <div class="container"> -->
        <div class="card mt-5">
            <div class="card-body">
                <form>
                    <!-- <div class="mb-3">
                        <label for="contentDisposition" class="form-label">File Name<span
                                class="text-danger">*</span></label>
                        <input type="text" id="contentDisposition" class="form-control" #contentDisposition maxlength="30"
                            [(ngModel)]="newPaperWork.contentDisposition" name="contentDisposition" required>
                        <div class="text-muted position-absolute end-0 me-3">{{contentDisposition.value.length}}/30</div>
                    </div>
                    <div class="mb-3">
                        <label for="description" class="form-label">Description</label>
                        <input type="text" id="description" class="form-control" #description maxlength="256"
                            [(ngModel)]="newPaperWork.description" name="description" required>
                        <div class="text-muted position-absolute end-0 me-3">{{description.value.length}}/256</div>
                    </div>
                    <div class="mb-3">
                        <label for="file" class="form-label">Choose File<span class="text-danger">*</span></label>
                        <input type="file" id="file" class="form-control" (change)="onFileSelected($event)" required>
                    </div>
                    <div class="card-footer">
                        <div class="btn-actions-pane-right p-4">
                            <button type="button" class="btn btn-danger me-2" (click)="onCancel()">Cancel</button>
                            <button type="submit" class="btn btn-primary" (click)="onSubmit()">Upload</button>
                        </div>
                    </div> -->
                    <!-- <div class="mb-3">
                        <label for="contentDisposition" class="form-label">File Name<span
                                class="text-danger">*</span></label>
                        <input type="text" id="contentDisposition" class="form-control" #contentDisposition pattern="^[a-zA-Z][\w\s]*$"
                            maxlength="30" [(ngModel)]="newPaperWork.contentDisposition" name="contentDisposition"
                            required>
                        <div class="text-muted position-absolute end-0 me-3">{{contentDisposition.value.length}}/30
                        </div>
                    </div> -->
                    <div class="mb-3">
                        <label for="contentDisposition" class="form-label">File Name<span
                                class="text-danger">*</span></label>
                        <input type="text" id="contentDisposition" class="form-control" #contentDisposition
                            pattern="^[a-zA-Z0-9]+[\w\s]*$" maxlength="30" [(ngModel)]="newPaperWork.contentDisposition"
                            name="contentDisposition" required>
                        <div class="text-muted position-absolute end-0 me-3">{{contentDisposition.value.length}}/30
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="description" class="form-label">Description</label>
                        <textarea id="description" class="form-control" #description maxlength="256"
                            [(ngModel)]="newPaperWork.description" name="description" rows="4" required></textarea>
                        <div class="text-muted position-absolute end-0 me-3">{{description.value.length}}/256</div>
                    </div>
                    <div class="mb-3">
                        <label for="file" class="form-label">Choose File<span class="text-danger">*</span></label>
                        <input type="file" id="file" class="form-control" (change)="onFileSelected($event)" required>
                    </div>
                    <div class="card-footer">
                        <div class="btn-actions-pane-right p-4">
                            <button type="button" class="btn btn-danger me-2" (click)="onCancel()">Cancel</button>
                            <button type="submit" class="btn btn-primary" [disabled]="isSubmitting" (click)="onSubmit()">Upload</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
        <!-- </div> -->
    </div>
</div>