import {ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ngxLoadingAnimationTypes} from "ngx-loading";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {ContractService} from "../contract.service";
import {Router} from "@angular/router";
import {CsvService} from "../../../services/csv.service";
import {HttpHeaders} from "@angular/common/http";
import Swal from "sweetalert2";
import {Location} from "@angular/common";
import moment from "moment-timezone";

@Component({
  selector: 'app-create-contract',
  templateUrl: './create-contract.component.html',
  styleUrls: ['./create-contract.component.sass']
})
export class CreateContractComponent implements OnInit {

  constructor(private service: ContractService, private router: Router, private locationService: Location, private formBuilder: FormBuilder, private csvService: CsvService, private cdr: ChangeDetectorRef) { }

  public loadingTemplate: TemplateRef<any>;
  @ViewChild('regionFileInput', { static: false }) regionFileInput!: ElementRef;
  @ViewChild('cityFileInput', { static: false }) cityFileInput!: ElementRef;
  public readonly ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  isLoading: boolean = false;

  regionFile: File | null = null;
  cityFile: File | null = null;




  options;
  filteredOptions: Observable<string[]>;

  contractFormGroup: FormGroup = this.formBuilder.group({
    customer: new FormControl(),
    description: new FormControl(),
    validFrom: new FormControl(''),
    validUpto: new FormControl('')
  })

  minStartDate: Date = new Date();
  minEndDate: Date = new Date(this.contractFormGroup.get('validFrom').value);

  ngOnInit() {
    this.service.getCustomers().subscribe(response => {
      this.options = response.data.content;
      this.initFilteredOptions();
    })
  }

  private initFilteredOptions() {
    this.filteredOptions = this.contractFormGroup.get('customer').valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name) : this.options.slice();
      })
    );
  }

  private _filter(name: string): string[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  // Method to update the start date
  updateStartDate(event: Date): void {
    if (event) {
      const startDate = new Date(event);
      startDate.setHours(0, 0, 0, 0);
      this.contractFormGroup.get('validFrom').setValue(startDate);
      this.minEndDate = new Date(event);
    }
  }

  // Method to update the end date with time set to 23:59:59
  updateEndDate(event: Date): void {
    if (event) {
      const endDate = new Date(event);
      endDate.setHours(23, 59, 59, 0); // Set the time to 23:59:59.999
      this.contractFormGroup.get('validUpto').setValue(endDate);
    }
  }

  displayFn(customer): string {
    return customer && customer.name ? customer.name : '';
  }

  goBack() {
    this.locationService.back();
  }

  downloadCSVRegionFormat() {
    const data = [
      { 'Product': '', 'Consignment Type': '',	'Consignment Subtype': '', 'Mode':'',	'Origin': '',	'Destination': '', 'Per KG': '',	'Min Chargeable WT': '',	'Min Freight': '',	'AWB Fee': '',	'FOV': '',	'FOV Min': '',	'Fuel Surcharge': '',	'OOA Discount': '',	'Handling Charges': '',	'Dim WT Calculator': ''}
    ];

    const csvContent = this.csvService.generateCsv(data);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'RegionFormat.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  downloadCSVCityFormat() {
    const data = [
      { 'Product': '', 'Consignment Type': '',	'Consignment Subtype': '', 'Mode':'',	'Origin': '',	'Destination': '', 'Per KG': '',	'Min Chargeable WT': '',	'Min Freight': '',	'AWB Fee': '',	'FOV': '',	'FOV Min': '',	'Fuel Surcharge': '',	'OOA Discount': '',	'Handling Charges': '',	'Dim WT Calculator': '', 'Temp Range':'', 'Container Size': ''}
    ];

    const csvContent = this.csvService.generateCsv(data);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'CityFormat.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }


  onRegionFileSelected(event: any): void {
    this.regionFile = event.target.files[0] ?? null;
    if(this.regionFile.type!='text/csv') {
      Swal.fire({
        title: 'Incorrect File Format',
        text: 'Please upload CSV File',
        icon: 'warning',
        timer: 5000
      })
      this.regionFile = null;
    }
  }

  onCityFileSelected(event: any): void {
    this.cityFile = event.target.files[0] ?? null;
    if(this.cityFile.type!='text/csv') {
      Swal.fire({
        title: 'Incorrect File Format',
        text: 'Please upload CSV File',
        icon: 'warning',
        timer: 5000
      })
      this.cityFile = null;
    }
  }

  uploadRegionFile(): void {
    if (this.regionFile) {
      // Implement your file upload logic here
      console.log('Uploading region file:', this.regionFile.name);
      // You would typically send this file to your server here
    }
  }

  uploadCityFile(): void {
    if (this.cityFile) {
      // Implement your file upload logic here
      console.log('Uploading city file:', this.cityFile.name);
      // You would typically send this file to your server here
    }
  }

  createCustomerContract() {
   const formData = new FormData();

    const jsonData = {
      customer: {
        id: this.contractFormGroup.get('customer')?.value.id ?? null
      },
      description: this.contractFormGroup.get('description')?.value,
      validFrom: this.contractFormGroup.get('validFrom')?.value,
      validUpto: this.contractFormGroup.get('validUpto')?.value
    };

   if(this.regionFile!=null) formData.append('regionPricesFile', this.regionFile, this.regionFile.name);
   else {
     Swal.fire({
       title: 'Missing fields',
       text: 'Please upload Region Price File',
       icon: 'question',
       timer: 5000
     })
     return;
   }
   if(this.cityFile!=null) formData.append('cityPricesFile', this.cityFile, this.cityFile.name);
   formData.append('contractDetails', JSON.stringify(jsonData));

   const headers = new HttpHeaders({
     'Accept': 'application/json',
   });

   this.isLoading = true;

   this.service.postContracts(formData).subscribe(response => {
     Swal.fire({
       title: 'Success',
       text: response.message,
       timer: 40000,
     })
     this.isLoading = false;
     this.goBack();
   }, error => {
     console.log(error.error.message);
     Swal.fire({
       title: 'Upload Failed',
       text: error.error.message,
       icon: 'error'
     })
     this.regionFileInput.nativeElement.value = ''
     this.cityFileInput.nativeElement.value = ''
     this.isLoading = false;

   })


  }


  readonly Date = Date;
  protected readonly moment = moment;
}
