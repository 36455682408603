import {Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BillingService} from '../../../modules/billingInfo/service/billing.service';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Location} from '@angular/common';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-billing-info-page',
  templateUrl: './billing-info-page.component.html',
  styleUrls: ['./billing-info-page.component.sass'],
})
export class BillingInfoPageComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  consignment_id;

  constructor(
    private ActivatedRoute: ActivatedRoute,
    private route: Router,
    public commonApi: BillingService,
    public locationService: Location,
  ) {
    this.ActivatedRoute.params.subscribe((params) => {
      this.consignment_id = params['id'];
    });
  }

  userDetails;
  displayToCustomer = false;

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name == 'Customer') {
      this.displayToCustomer = true;
    }

    this.commonApi.getBillingLineItems();
    this.commonApi.getBillingPriceByConsignmentId(this.consignment_id)
        .subscribe((response) => {
          // this.commonApi.ModeBasedBillingLineItems();
          this.commonApi.calculateGrandTotal();
        });

    setTimeout(() => {
      this.loading = false; // Set loading to false after 2 seconds
    }, 2000);
  }


  // BillGrandTotal;
  // calculateBillGrandTotal(){
  //   this.BillGrandTotal = ((((this.commonApi.calculateTotalBillingInfoSum() - this.commonApi.BillingPriceLists?.discountedAmount) + ((this.commonApi.BillingPriceLists?.insurancePercentage * this.commonApi.BillingPriceLists.consignment.consignment_value)/100)) * this.commonApi.BillingPriceLists?.taxablePercentage)/100)
  //     + ((this.commonApi.calculateTotalBillingInfoSum() - this.commonApi.BillingPriceLists?.discountedAmount) + ((this.commonApi.BillingPriceLists?.insurancePercentage * this.commonApi.BillingPriceLists.consignment.consignment_value)/100))
  //
  //   this.BillGrandTotal = parseFloat(this.BillGrandTotal.toFixed(2))
  // }

  getConsignmentByIdToBillingEdit() {
    this.route.navigate(['/billingInfoEdit', this.consignment_id]);
  }

  backToConsignmentView() {
    if (this.displayToCustomer) {
      this.route.navigate(['/consignmentView', this.commonApi.BillingPriceLists.consignment?.id]);
    } else {
      this.locationService.back();
      // this.route.navigate(['/viewconsignment', this.commonApi.BillingPriceLists.consignment?.consignment_no])
    }
  }
}


@Component({
  selector: 'app-billing-offline-dialog',
  templateUrl: './billing-offline-dialog.html',
})
export class BillingOfflineDialog implements OnInit {
  consignment_id;
  constructor(
    private ActivatedRoute: ActivatedRoute,
    private route: Router,
    public commonApi: BillingService,
    public dialogRef: MatDialogRef<BillingOfflineDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.ActivatedRoute.params.subscribe((params) => {
      this.consignment_id = params['id'];
    });
  }

  userDetails;

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getConsignmentByIdToBillingEdit() {
    this.commonApi.createBillingPrice(this.data.consignmentId)
        .subscribe((response) => {
          if (this.userDetails.userTypes[0].details == 'Customer') {
            this.route.navigate(['/billingInfoPage', this.data.consignmentId]);
          } else {
            this.route.navigate(['/billingInfoEdit', this.data.consignmentId]);
          }
        });
    this.onNoClick();
  }
}


@Component({
  selector: 'app-cost-center-offline-dialog',
  templateUrl: './cost-center-offline-dialog.html',
})
export class CostCenterOfflineDialog implements OnInit {
    consignment_id;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public commonApi: BillingService,
        public dialogRef: MatDialogRef<CostCenterOfflineDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
      this.route.params.subscribe((params) => {
        this.consignment_id = params['id'];
      });
    }

    ngOnInit(): void {
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    getConsignmentByIdToCostCenterEdit() {
      this.commonApi.createBillingPrice(this.data.consignmentId)
          .subscribe((response) => {
            this.router.navigate(['/costCenterEdit', this.data.consignmentId]);
          });
      this.onNoClick();
    }
}
