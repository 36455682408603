import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../auth.service';
import {BillingService} from '../../../modules/billingInfo/service/billing.service';
import {API_URL} from '../../../../../Global_API_URL';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {ActivatedRoute, Router} from '@angular/router';
import Swal from 'sweetalert2';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';
@Component({
  selector: 'app-customer-consignment-view-page',
  templateUrl: './customer-consignment-view-page.component.html',
  styleUrls: ['./customer-consignment-view-page.component.sass'],
})
export class CustomerConsignmentViewPageComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  constructor(private _liveAnnouncer: LiveAnnouncer, private http: HttpClient, private sanitizer: DomSanitizer, private modalService: NgbModal, private authService: AuthService, public commonApi: BillingService, private router: Router, private route: ActivatedRoute) {
    this.authService.getAuthToken();
  }
  userDetails;
  displayToAdmin = false;
  displayToSupervisor = false;
  displayToCustomer = false;
  unqid;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.sender_individual = this.userDetails.id;
    if (this.userDetails.userTypes[0] == 'Admin') {
      this.displayToAdmin = true;
    }
    if (this.userDetails.userTypes[0] == 'Supervisor') {
      this.displayToSupervisor = true;
    }
    if (this.userDetails.userTypes[0] == 'Customer') {
      this.displayToCustomer = true;
    }
    this.route.params.subscribe((params) => {
      this.unqid = params['id'];
    });
    const someFunction = async () => {
      await this.viewconsignmentById(this.unqid);
      await this.viewConsignmentDocumentsById(this.unqid); // this.sumOfPackages()
      this.getTrack();
      setTimeout(()=>{
        this.loading = false;
      }, 2000);
    };
    someFunction();
  }


  pageview = 'view';
  consignment_id;
  filterParams='';
  sender_individual='';
  pickup_location='';
  drop_location ='';
  is_coperate_booking='';
  image_1= null;
  image_2 = null;
  image_3 = null;
  image_4 = null;
  POD=null;
  largeImage:any
  image1:File;
  image2:File;
  image3:File;
  image4:File;
  pod:File;


  latestid:any
  ivalue;
  viewchange(type: string, id) {
    this.ivalue = id;
    if (type === 'image1') {
      this.pageview = 'image1';
    } else if (type === 'view') {
      this.pageview = 'view';
    } else if (type === 'image2') {
      this.pageview = 'image2';
    } else if (type === 'image3') {
      this.pageview = 'image3';
    } else if (type === 'image4') {
      this.pageview = 'image4';
    } else if (type === 'datalogger') {
      this.pageview = 'datalogger';
    } else if (type === 'vehiclecalibration') {
      this.pageview = 'vehiclecalibration';
    } else if (type === 'otherdocuments') {
      this.pageview = 'otherdocuments';
    } else if (type === 'temp') {
      this.pageview = 'temp';
    } else if (type === 'pod') {
      this.pageview = 'pod';
    } else if (type === 'statusupload') {
      this.pageview = 'statusupload';
    } else if (type === 'addpack') {
      this.pageview = 'addpack';
    } else if (type === 'Date') {
      this.pageview = 'Date';
      this.latestid = id;
    }
  }
  filter() {
    this.filterParams='?';
    if (this.sender_individual!='') {
      this.filterParams= this.filterParams+'sender_individual='+this.sender_individual+'&';
    }
    if (this.pickup_location!='') {
      this.filterParams= this.filterParams+'pickup_location='+this.pickup_location+'&';
    }
    if (this.drop_location!='') {
      this.filterParams= this.filterParams+'drop_location='+this.drop_location+'&';
    }
    if (this.is_coperate_booking!='') {
      this.filterParams= this.filterParams+'is_coperate_booking='+this.is_coperate_booking+'&';
    }
    return this.filterParams;
  }

  consignmentlists: any = {
    id: '',
    consignment_no: '',
    consignmentType: '',
    weightUnit: 1,
    currency: 1,
    weightDivisor: 1,
    consignmentNo: '',
    airlinesId: '',
    isStopover: '',
    totalTransitTime: '',
    senderIndividual: '',
    isIndividualCustomer: '',
    paymentCompletedAt: '',
    isXrayScreening: '',
    isXrayCertification: '',
    isDryIce: '',
    totalWeight: '',
    totalChargableWeight: '',
    planSelected: '',
    senderId: '',
    receiverId: '',
    isDocumentUploaded: '',
    isInsured: false,
    totalShippingCost: '',
    paymentMode: '',
    isPaymentCompleted: '',
    isActive: '',
    createdAt: '',
    updatedAt: '',
    createdBy: '',
    updatedBy: '',
    totalNoOfPackages: '',
    destinationLocation: '',
    consignmentSubType: '',
    originLocation: '',
    dimensionuUnit: '',
    insuranceCoverage: '',
    totalConsignmentValue: '',
    shippingCost: '',
    invoiceCreatedDate: '',
    invoiceReferenceNo: '',
    productType: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    data_logger_file: '',
    vehicle_calibration_file: '',
    other_documents_file: '',
    temperature_record_file: '',
    pod: '',

  }

  billCreated;
  billConsId;
  billingPriceTaxableAmount;
  billingPriceGrandTotal;
  isInvoiced;
  invoiceId;
  async viewconsignmentById(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/' + id, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })

    // Converting received data to JSON
        .then((response) => response.json())
        .then(async (json) => {
          if (json.data[0].image1 != null) {
            this.getimage1(id);
          }
          if (json.data[0].image2 != null) {
            this.getimage2(id);
          }
          if (json.data[0].image3 != null) {
            this.getimage3(id);
          }
          if (json.data[0].image4 != null) {
            this.getimage4(id);
          }
          if (json.data[0].pod != null) {
            this.getpod(id);
          }
          this.consignmentlists = json;
          this.getTrack();


          this.billConsId = this.consignmentlists.data[0].id;
          this.commonApi.getBillingPriceByConsignmentId(this.billConsId)
              .subscribe((response) => {
                this.billCreated = this.commonApi.isBillingCreated;
                this.billingPriceTaxableAmount = this.commonApi.BillingPriceLists.taxableAmount;
                this.billingPriceGrandTotal = this.commonApi.BillingPriceLists.grandTotal;
                this.isInvoiced = this.commonApi.BillingPriceLists.isInvoiceGenerated;
                this.invoiceId = this.commonApi.BillingPriceLists.invoiceId;
                if (this.invoiceId != null) {
                  this.getInvoiceById(this.invoiceId);
                }
              });
        });
  }


  documentTypeWithDocuments : any;
  uploadedDocuments: any[] = [];
  async viewConsignmentDocumentsById(id) {
    const TOKEN = this.authService.getAuthToken();

    try {
      const response = await fetch(`${API_URL}/consignmentDocuments/` + id, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      });

      if (response.ok) {
        const json = await response.json();
        const consignmentData = json.data;

        // Group documents by consignmentDocumentTypeId
        const groupedDocuments = consignmentData.reduce((acc, document) => {
          const docType = document.consignmentDocumentTypeId;
          if (!acc[docType]) {
            acc[docType] = {
              documentType: {
                id: document.consignmentDocumentTypeId,
                name: document.name, // Add the actual property name for document type name
              },
              documents: [],
            };
          }
          if (document.fileName) { // Only add the document if fileName is not null
            acc[docType].documents.push({
              id: document.id,
              name: document.name,
              fileName: document.fileName,
              uploaded: true,
              downloadLink: document.fileLocation, // Assuming fileLocation is the download link
              // Add other properties as needed
            });
          }
          return acc;
        }, {});

        // Iterate through all document types
        const allDocumentTypes = Object.values(groupedDocuments).map((type) => type); // Get values from groupedDocuments
        this.uploadedDocuments = allDocumentTypes;
      } else {
        console.error('No consignment documents found.');
      }

      // Continue processing other consignment details as needed
    } catch (error) {
      // Handle exceptions
      // console.error("Exception fetching consignment details:", error);
    }
  }

fileType: String;
fileName: string;
docName: string;
consignmentDocumentTypeId;

async downloadDocument(documentId: number) {
  await this.getDocumentMetadata(documentId);

  try {
    const TOKEN = this.authService.getAuthToken();
    const downloadHeaders = new Headers();
    downloadHeaders.append('Authorization', `Bearer ${TOKEN}`);


    const downloadOptions: RequestInit = {
      method: 'GET',
      headers: downloadHeaders,
      redirect: 'follow',
    };

    // Fetch binary data from getDocumentsById API
    const downloadResponse = await fetch(API_URL + `/getDocumentsById?id=${documentId}&fileType=${this.fileType}`, downloadOptions);


    const documentBlob = await downloadResponse.blob();
    const documentUrl = window.URL.createObjectURL(documentBlob);
    await this.getDocumentMetadata(documentId);
    this.fileName = `${this.docName}_${this.consignmentlists?.data[0]?.consignmentNo}.${this.fileType}`;

    const a = document.createElement('a');
    a.href = documentUrl;
    a.download = this.fileName;
    a.click();
    window.URL.revokeObjectURL(documentUrl);
  } catch (error) {
    console.error('Error downloading document:', error);
  }
}

async getDocumentMetadata(documentId: number) {
  try {
    const TOKEN = this.authService.getAuthToken();
    const response = await fetch(`${API_URL}/consignmentDocument/` + documentId, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    });

    if (response.ok) {
      const json = await response.json();
      const consignmentData = json.data;
      this.fileType = consignmentData[0]?.fileType;
      this.fileName = consignmentData[0]?.fileName;
      this.consignmentDocumentTypeId = consignmentData[0]?.consignmentDocumentTypeId;
      this.getDocumentTypeName(this.consignmentDocumentTypeId);
    }
  } catch (error) {
    console.error('Error downloading document metadata:', error);
  }
}


// Function to get the document type name based on documentTypeId
getDocumentTypeName(documentTypeId) {
  switch (documentTypeId) {
    case 1:
      this.docName = 'Non-DG Declaration';
      return 'Non-DG Declaration';
    case 2:
      this.docName ='E-waybill';
      return 'E-waybill';
    case 3:
      this.docName ='Shipment Documents';
      return 'Shipment Documents';
  }
}


  requiredDocument: any = [];

  getFiles(event, documentTypeWithDocuments) {
    this.requiredDocument[0] = event.target.files[0];
    documentTypeWithDocuments.file = event.target.files[0];
    documentTypeWithDocuments.documents.fileOpen = true; // Assuming you want to open the upload section when a file is selected
  }


  consignment_sts_update={
    consignmentId: null,
    status: null,
    comment: '',
  }

  manifestation;
  consignment_track;
  consignment_status
  origin
  destination
  async getTrack() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/tracking/consignment/' + this.consignmentlists?.data[0]?.consignmentNo, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          this.consignmentlists = json;
          this.consignment_track= json.data[0].trackingId.trackingStatusComments;
          this.origin = json.data[0].originLocation?.city.name;
          this.destination = json.data[0].dropLocation?.city.name;
          this.consignment_status=json.statuses;
          this.consignment_sts_update.status=null;
          this.consignment_sts_update.comment='';
          this.consignment_track.sort((a, b) => a.id - b.id);
        });
  }


  InvoiceData;
  getInvoiceById(id) {
    const TOKEN = this.authService.getAuthToken();
    try {
      const response = fetch(API_URL + '/getInvoice/' + id, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
          .then((response) => response.json())
          .then(async (json) => {
            this.InvoiceData = json.data[0];
          });
    } catch (error) {
      console.error('Error fetching Invoice details: ', error);
    }
  }
  gotoBillingInfo(id) {
    this.commonApi.getBillingPriceByConsignmentId(id)
        .subscribe((response) => {
          this.router.navigate(['/billingInfoPage', id]);
        }, (error) => {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Billing info not created for this consignment',
            timer: 5000,
          });
        });
  }

  async getRecord(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/report/' + id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then(async (response) => {
          const filename = 'consignmentReport' + id;
          const blob = response.blob();
          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(await blob);
          a.click();
        },
        );
  }

  changeable_packages = [
    {
      id: 1,
      length: null,
      breadth: null,
      height: null,
      weight_per_package: null,
      total_weight: null,
      package_no: null,
      volume_weight: null,
      chargeable_weight: null,
    },
  ]

  consignment_details = {
    id: null,
    consignmentType: null,
    weightUnit: 1,
    currency: 1,
    weightDivisor: 1,
    consignmentNo: null,
    airlinesId: null,
    isStopover: null,
    totalTransitTime: null,
    senderIndividual: null,
    isIndividualCustomer: null,
    paymentCompletedAt: null,
    isXrayScreening: null,
    isXrayCertification: null,
    isDryIce: null,
    totalWeight: null,
    totalChargableWeight: null,
    planSelected: null,
    senderId: null,
    packages: [],
    receiverId: null,
    isDocumentUploaded: null,
    isInsured: false,
    totalShippingCost: null,
    paymentMode: null,
    isPaymentCompleted: null,
    isActive: null,
    createdAt: '',
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
    totalNoOfPackages: null,
    destinationLocation: null,
    consignmentSubType: null,
    originLocation: null,
    dimensionuUnit: null,
    insuranceCoverage: 0,
    totalConsignmentValue: 0,
    shippingCost: 0,
    invoiceCreatedDate: '',
    invoiceReferenceNo: null,
    productType: null,
    remarks: null,
  }


  async getimage1(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getImage1/'+ id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then((response) => response.blob())
        .then((result) =>{
          const objectURL = URL.createObjectURL(result);
          this.image_1 =this.sanitizer.bypassSecurityTrustUrl(objectURL);
        })
        .catch((error) => console.error('error', error));
  }
  async getimage2(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getImage2/'+ id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then((response) => response.blob())
        .then((result) =>{
          const objectURL = URL.createObjectURL(result);
          this.image_2 =this.sanitizer.bypassSecurityTrustUrl(objectURL);
        })
        .catch((error) => console.error('error', error));
  }
  async getimage3(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getImage3/'+ id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then((response) => response.blob())
        .then((result) =>{
          const objectURL = URL.createObjectURL(result);
          this.image_3 =this.sanitizer.bypassSecurityTrustUrl(objectURL);
        })
        .catch((error) => console.error('error', error));
  }
  async getimage4(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getImage4/'+ id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then((response) => response.blob())
        .then((result) =>{
          const objectURL = URL.createObjectURL(result);
          this.image_4 =this.sanitizer.bypassSecurityTrustUrl(objectURL);
        })
        .catch((error) => console.error('error', error));
  }
  async getpod(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getPod/'+ id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then((response) => response.blob())
        .then((result) =>{
          const objectURL = URL.createObjectURL(result);
          this.POD =this.sanitizer.bypassSecurityTrustUrl(objectURL);
        })
        .catch((error) => console.error('error', error));
  }
  async getvehiclecalibration() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getVehicleCalibrationFile/'+ this.consignmentlists.data[0].id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then(async (response) => {
          const filename = 'VehicleCalibrationFile' + this.consignmentlists.data[0].id;
          const blob = response.blob();
          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(await blob);
          a.click();
        })
        .catch((error) => console.error('error', error));
  }
  async getDatalogger() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getDataLoggerFile/'+ this.consignmentlists.data[0].id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then(async (response) => {
          const filename = 'DataloggerFile' + this.consignmentlists.data[0].id;
          const blob = response.blob();
          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(await blob);
          a.click();
        })
        .catch((error) => console.error('error', error));
  }
  async getotherdocuments() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getOtherDocumentsFile/'+ this.consignmentlists.data[0].id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then(async (response) => {
          const filename = 'OtherDocumentsFile' + this.consignmentlists.data[0].id;
          const blob = response.blob();
          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(await blob);
          a.click();
        })
        .catch((error) => console.error('error', error));
  }
  async gettemp() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/consignment/getTemperatureRecordFile/'+ this.consignmentlists.data[0].id, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then(async (response) => {
          const filename = 'TemperatureRecordFile' + this.consignmentlists.data[0].id;
          const blob = response.blob();
          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(await blob);
          a.click();
        })
        .catch((error) => console.error('error', error));
  }


  // async uploadimage1(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.image1 != null){
  //     alert("Upload Image 1")
  //   }else{
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("image1",this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateImage1", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         "Accept":"application/json"
  //       }
  //     })
  //       .then((response) => response.text())
  //       .then((result) => {
  //         //
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'Image 1 Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  // }
  // async uploadimage2(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.image2 != null){
  //     alert("Upload Image 2")
  //   }else{
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("image2",this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateImage2", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         "Accept":"application/json"
  //       }
  //     })
  //       .then((response) => response.text())
  //       .then((result) => {
  //         //
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'Image 2 Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  // }
  // async uploadimage3(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.image3 != null){
  //     alert("Upload Image 3")
  //   }else{
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("image3",this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateImage3", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         "Accept":"application/json"
  //       }
  //     })
  //       .then((response) => response.text())
  //       .then((result) => {
  //         //
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'Image 3 Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  // }
  // async uploadimage4(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.image4 != null){
  //     alert("Upload Image 4")
  //   }else{
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("image4",this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateImage4", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         "Accept":"application/json"
  //       }
  //     })
  //       .then((response) => response.text())
  //       .then((result) => {
  //         //
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'Image 4 Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  // }
  // async uploadPOD(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.pod != null){
  //     alert("Upload POD")
  //   }else{
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("POD",this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updatePod", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         // "Accept":"multipart/form-data"
  //       }
  //     })
  //       .then((response) => response.text())
  //       .then((result) => {
  //         //
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'POD Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  // }
  // async uploadDatafiles(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.data_logger_file != null){
  //     alert("Upload dataLoggerFile")
  //   }else{
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("DataLoggerFile",this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateDataLoggerFile", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         // "Accept":"multipart/form-data"
  //
  //       }
  //     })
  //
  //       .then((response) => response.text())
  //       .then((result) => {
  //         //
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'Data Logger Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  //
  // }
  // async uploadVechilefiles(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.vehicle_calibration_file != null){
  //     alert("Upload VehicleCalibrationFile")
  //   }else{
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("VehicleCalibrationFile",this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateVehicleCalibrationFile", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         // "Accept":"multipart/form-data"
  //       }
  //     })
  //
  //       .then((response) => response.text())
  //       .then((result) => {
  //         //
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'Vehicle Calibration Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  //
  // }
  // async uploadOthersfiles(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.other_documents_file != null){
  //     alert("Upload OtherDocumentsFile")
  //   }else{
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("OtherDocumentsFile",this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateOtherDocumentsFile", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         // "Accept":"multipart/form-data"
  //       }
  //     })
  //
  //       .then((response) => response.text())
  //       .then((result) => {
  //         //
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'OtherDocuments Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  //
  // }
  // async uploadTemp(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.temperature_record_file != null){
  //     alert("Upload TemperatureRecordFile")
  //   }else{
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("TemperatureRecordFile",this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateTemperatureRecordFile", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         // "Accept":"multipart/form-data"
  //       }
  //     })
  //
  //       .then((response) => response.text())
  //       .then((result) => {
  //         //
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'TemperatureRecord Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  //
  // }

  getTime(date) {
    if (date) {
      const date2= new Date(date);
      return (
        [
          date2.getFullYear(

          ),
          this.padTo2Digits(date2.getMonth() + 1),
          this.padTo2Digits(date2.getDate()),
        ].join('-') +
        ' ' +
        [
          this.padTo2Digits(date2.getHours()),
          this.padTo2Digits(date2.getMinutes()),
        ].join(':')
      );
    } else {
      return '-';
    }
  }
  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  formatDate(date) {
    const date2= new Date(date);
    return (
      [
        date2.getFullYear(),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date2.getHours()),
        this.padTo2Digits(date2.getMinutes()),
      ].join(':')
    );
  }
  open(content5, img) {
    this.modalService.open(content5, {
      size: 'lg',
    });
    this.largeImage = img;
  }

  gotoConsignmentTablePage() {
    this.router.navigate(['/consignmentTable']);
  }
}
