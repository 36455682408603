<h3>Send Notification</h3>
<!-- <app-mat-steps></app-mat-steps> -->
<div class="card">
    <div class="container p-3">
        <div class="row">
            <div class="col-md-7">
                <mat-form-field appearance="standard">
                    <mat-label>
                        Consignment ID
                    </mat-label>
                    <input type="text" placeholder="000000" aria-label="Number" matInput>
                </mat-form-field>
                <button class="btn btn-primary m-3">
                    Find
                </button>
                <mat-divider></mat-divider>
                <div class=" ">
                    <div class="row my-3">
                        <div class="col-md-4">
                            <div class="ft-width-700">
                                Origin Location:
                            </div>
                            HYD
                        </div>
                        
                        <div class="col-md-4">
                            <div class="ft-width-700">

                                Destination Location:
                            </div>
                            DEL
                        </div>
                        <div class="col-md-4">
                            <div class="ft-width-700">

                                Total Cost
                            </div>
                            Rs 1000
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="my-3 h5 ft-width-700 text-center">
                        Route Details
                    </div> 
                    <div class="row p-1">
                        <table class="m-auto">
                            <thead>
                                <tr>

                                    <th>
                                        Origin
                                    </th>
                                    <th>
                                        Route
                                    </th>
                                    <th>
                                        Airlines
                                    </th>
                                    <th>
                                        Flight No.
                                    </th>
                                
                                    <th>
                                        Destination
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Hyd
                                    <td>
                                        Road
                                    </td>
                                    <td>
                                        <div class="text-center">
                                            -
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-center">
                                            -
                                        </div>
                                    </td>
                                    
                                    <td>
                                        BLR
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        BLR
                                    <td>
                                        Flight
                                    </td>
                                    <td>
                                        Indigo
                                    </td>
                                    <td>
                                        FK7841
                                    </td>
                                    <td>
                                        DEL
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="my-3 h5 ft-width-700 text-center">
                        Package Details
                    </div> 
                    <div class="row p-1">
                        <table class="m-auto">
                            <thead>
                                <tr>

                                    <th>
                                        Packge Dimentions
                                    </th>
                                    <th>
                                        No of packges
                                    </th>
                                    <th>
                                        Per package weight
                                    </th>
                                    <th>
                                        Total weight
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        85 X 85 X 90
                                    </td>
                                    <td>
                                        10  
                                    </td>
                                    <td>
                                        5kg
                                    </td>
                                    <td>
                                        50kg
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        40 X 60 X 45
                                    </td>
                                    <td>
                                        5  
                                    </td>
                                    <td>
                                        10kg
                                    </td>
                                    <td>
                                        50kg
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="card p-3 bg-papayawhip">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field appearance="standard">
                                <mat-label for="consignment_type">
                                    User Type
                                </mat-label>
                                <mat-select id="consignment_type">
                                    <mat-option [value]="1">
                                        Orign Agent
                                    </mat-option>
                                    <mat-option [value]="2">
                                        Customer
                                    </mat-option>
                                    <mat-option [value]="3">
                                        Destination Agent
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">

                            <mat-form-field appearance="standard">
                                <mat-label for="consignment_type">
                                    User
                                </mat-label>
                                <mat-select id="consignment_type">
                                    <mat-option [value]="1">
                                        Munaif shaik
                                    </mat-option>
                                    <mat-option [value]="2">
                                        Shrinivas
                                    </mat-option>
                                    <mat-option [value]="4">
                                        Shireen
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        
                    </div>
                    <div class="row">
                        <mat-form-field appearance="standard">
                            <mat-label>
                                Subject
                            </mat-label>
                            <input type="text" placeholder="000000" aria-label="Number" matInput>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field>
                            <mat-label>
                                Body
                            </mat-label>
                            <textarea matInput placeholder="Body" rows=5></textarea>
                          </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="float-right ">

                            <button class="btn btn-primary btn-lg m-2">Send</button>
                            
                            <button class="btn btn-danger btn-lg">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>