import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-icl-dashboard',
  templateUrl: './icl-dashboard.component.html',
  styleUrls: ['./icl-dashboard.component.sass'],
})
export class IclDashboardComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
