import {HttpService} from './../../../services/http.service';
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {API_URL} from '../../../../../Global_API_URL';
import Swal from 'sweetalert2';
import {getCities, getCountries, getStates, getStatesById} from '../../getCities.js';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {MatTableDataSource} from '@angular/material/table';
import {saveAs} from 'file-saver';
import {ConfirmDialog} from '../../Components/confirmDialog/confirmDialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '../../../auth.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-user-mangement',
  templateUrl: './user-mangement.component.html',
  styleUrls: ['./user-mangement.component.sass'],
  animations: [
    trigger('rotate', [
      state('inactive', style({
        transform: 'rotate(0)',
      })),
      state('active', style({
        transform: 'rotate(360deg)',
      })),
      transition('inactive <=> active', animate('500ms ease-out')),
    ]),
  ],
})
export class UserMangementComponent implements OnInit {
  pageview = 'table';
  phoneNoExist = false;
  emailExist = false;
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };

  state_id;
  city_id;

  user_id = {
    id: null,
    first_name: null,
    middle_name: null,
    last_name: null,
    email: null,
    phone: null,
    whatsapp: null,
    address: null,
    office_location: null,
    user_type: null,
    country: null,
    state_id: null,
    city: null,
    zip_code: null,
  }

  newuser = {
    first_name: null,
    middle_name: null,
    last_name: null,
    avatar_url: null,
    email: null,
    phone: null,
    whatsapp: null,
    designation: null,
    address: null,
    city: {id: null},
    zip_code: null,
    user_type: null,
    office_location: null,
    is_super_user: false,
    user_name: null,
    password_hash: 'test123',
    last_logged_in_at: null,
    last_logged_in_ip: null,
    is_active: true,
    created_by: null,
    updated_by: null,
    customer_type: null,
    org_customer_id: null,
    customer_id: null,
    varification_id_type: null,
    verification_id: null,
    employment_no: null,
  }

  emailregex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
  emailFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.emailregex),
  ]);

  states = getStates();
  countries = getCountries();
  cities = getCities();
  OfficesList
  filterUsersList = [];
  selected: any = '';
  buttonactive = false;

  storedPage;
  pageSize;
  name: any;
  email: any;

  constructor(private _liveAnnouncer: LiveAnnouncer, private matDialog: MatDialog, private authService: AuthService, private httpService: HttpService, private router: Router) {
    this.authService.getAuthToken();
  }

  paginatorDetails = {
    pageNo: 0,
    pageSize: 0,
    totalElements: 0,
  }

  ngOnInit(): void {
    this.selected = this.user_id.user_type;
    this.getFilterUserList();
    this.getOfficeLocations();

    const savedPageState = JSON.parse(localStorage.getItem('pageState')) || {};
    this.storedPage = savedPageState.pageIndex || 0;
    this.pageSize = savedPageState.pageSize || 10;
  }

  onPaginateChange(event) {
    this.storedPage = event.pageIndex;
    this.pageSize = event.pageSize;
    localStorage.setItem('pageState', JSON.stringify({pageIndex: this.storedPage, pageSize: this.pageSize}));
  }

  async getOfficeLocations() {
    const TOKEN = this.authService.getAuthToken();
    this.httpService.get(API_URL + '/office_locations', null, null)
        .subscribe((json) => {
          this.OfficesList = json.data;
        });
    return this.OfficesList;
  }


  filterParams = '';
  pagenation = {
    col_name: 'id',
    page_size: 10,
    page_no: 0,
    sort_dir: 'DESC',
  }

  userid: string;

  viewchange(val, user) {
    this.pageview = val;
    this.user_id = user;
    this.userid = user;
    this.newuser.employment_no = null;
    // this.getFilterUserList();
  }


  pageSizeOptions = [5, 10, 30];


  filter() {
    this.filterParams = '?';
    this.filterParams = this.filterParams + 'page_no=' + this.pagenation.page_no + '&page_size=' + this.pagenation.page_size + '&col_name=' + this.pagenation.col_name + '&sort_dir=' + this.pagenation.sort_dir;
    return this.filterParams;
  }


  user_cities
  user_states
  user_pincodes
  user_countries
  user_sel_state
  user_sel_country
  user_sel_pincodes

  business_cities
  business_states
  business_pincodes = []
  business_sel_country
  business_sel_state


  formatDate(date) {
    const date2 = new Date(date);
    return (
      [
        date2.getFullYear(),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date2.getHours()),
        this.padTo2Digits(date2.getMinutes()),
      ].join(':')
    );
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  Email
  Phone
  Name
  // search2 is Name
  // search is Email
  // search1 is phone number
  userfilter() {
    let filterParams = '?';
    if (this.search2 != null && this.search2 != undefined && this.search2 != '') {
      filterParams = filterParams + '&first_name=' + this.search2;
    }
    if (this.search != null && this.search != undefined && this.search != '') {
      filterParams = filterParams + '&email=' + this.search;
    }
    if (this.search1 != null && this.search1 != undefined && this.search1 != '') {
      filterParams = filterParams + '&phone=' + this.search1;
    }
    return filterParams;
  }

  filteredData

  applyFilter() {
    this.filterParams = this.userfilter();
    const searchName = this.search2.toLowerCase();
    const searchPhone = this.search1.toLowerCase();
    const searchEmail = this.search.toLowerCase();
    // Filter the data based on the provided criteria
    this.filteredData = this.filterUsersList.filter((user) => {
      // Check if either first_name or phone contains the provided substring
      return user.firstName.toLowerCase().includes(searchName) && user.phone.includes(searchPhone) && user.email.toLowerCase().includes(searchEmail);
    });

    // Update MatTableDataSource with filtered data
    this.dataSource = new MatTableDataSource(this.filteredData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginatorDetails.pageNo = this.paginator.pageIndex as number;
    this.paginatorDetails.pageSize = this.paginator.pageSize;
    this.paginatorDetails.totalElements = this.filteredData.length;

  }

  reset() {
    this.Email = '';
    this.Phone = '';
    this.Name = '';
    this.search = '';
    this.search1 = '';
    this.search2 = '';
    this.getFilterUserList();
  }

  async getFilterUserList() {
    const TOKEN = this.authService.getAuthToken();
    this.loading = true;
    const users = [];
    let array = [];
    const params = this.userfilter();
    this.httpService.get(API_URL + '/usersfilters', params, null)
        .subscribe((json) => {
          array = Array.from(json.data);
          array.forEach((element) => {
            if (element.userTypes[0]?.id != 5) {
              users.push(element);
            }
          });
          json.data = [];
          json.data = users;
          this.filterUsersList = json.data.reverse();
          // this.dataSource = new MatTableDataSource(json.data.reverse());
          this.dataSource = new MatTableDataSource(this.filterUsersList);
          // this.dataSource = new MatTableDataSource(json.data.reverse());
          // this.paginator =
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.loading = false;
          // Update paginator details here
          if (this.paginator) {
            this.paginatorDetails.pageNo = this.paginator.pageIndex;
            this.paginatorDetails.pageSize = this.paginator.pageSize;
            this.paginatorDetails.totalElements = this.filterUsersList.length;
          }
        });
    return users;
  }

  displayedColumns = ['Name', 'UserRole', 'PhoneNo', 'Email', 'CreatedAt', 'loggedInAt', 'Active']
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  showFirstLastButtons;
  pageSizeOptionss = [10, 30];


  exportToCSV() {
    const csvData = [];
    const headers = ['Name', 'Role', ' Address', 'City', 'State', 'Country', 'Mobile', 'Email', 'Last Login At'];
    csvData.push(headers);
    this.dataSource.filteredData.forEach((row) => {
      const last_logged_in_at = this.formatDate(row.lastLoggedInAt);
      const data = [`"${row.firstName}"`, row.userTypes[0].name, `"${row?.billingAddress?.address}"`, row?.billingAddress?.pincodeId?.cityId?.name, row?.billingAddress?.pincodeId?.cityId?.state_id.name, row?.billingAddress?.pincodeId?.cityId?.state_id.country.name,
        row.phone, row.email, last_logged_in_at];
      csvData.push(data);
    });

    const csv = csvData.map((e) => e.join(',')).join('\n');
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
    saveAs(blob, 'UserDetails.csv');
  }

  async Deletedata(id) {
    this.makeFieldsNull();
    try {
      const TOKEN = this.authService.getAuthToken();
      const data = {
        'isActive': 0,
      };

      // Send PATCH request to deactivate user
      await this.httpService.patch(API_URL + '/app_users_isactive/' + id, data, null, null).toPromise();

      // Deactivation successful, show success message
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'User Deactivated Successfully',
        timer: 5000,
      });

      // Refresh user list after deactivation
      await this.getFilterUserList();
    } catch (error) {
      // Handle error if PATCH request fails or user list refresh fails
      console.error('Error deactivating user or refreshing user list:', error);
    }
  }

  async Restoredata(id) {
    this.makeFieldsNull();
    try {
      const TOKEN = this.authService.getAuthToken();
      const data = {
        'isActive': 1,
      };

      // Send PATCH request to activate user
      await this.httpService.patch(API_URL + '/app_users_isactive/' + id, data, null, null).toPromise();

      // Activation successful, show success message
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'User Activated Successfully',
        timer: 5000,
      });
      // Refresh user list after activation
      await this.getFilterUserList();
    } catch (error) {
      // Handle error if PATCH request fails or user list refresh fails
      console.error('Error activating user or refreshing user list:', error);
    }
  }

  onKeyDown(event: KeyboardEvent) {
    // Allow backspace key to execute normally
    if (event.key === 'Backspace') {
      return;
    }
    // Allow only numbers
    if (isNaN(Number(event.key))) {
      event.preventDefault();
    }
  }

  passwordFlag = false;
  retypePassword = '';
  newpassword = '';
  oldPassLengthCheck = false;

  checkNewPassword(): void {
    this.oldPassLengthCheck = this.newpassword.length < 6;
  }

  checkConfirmPassword(): void {
    this.passwordFlag = this.newpassword !== this.retypePassword;
  }

  makeFieldsNull() {
    this.search = '';
    this.search1 = '';
    this.search2 = '';
  }

  resetPassword(): Promise<void> {
    this.makeFieldsNull();
    this.checkConfirmPassword();
    if (this.newpassword == null || this.newpassword == '' || this.newpassword.trim() == '') {
      // Display error message for empty new password
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter New Password',
        timer: 5000,
      });
      return;
    } else if (this.retypePassword === null || this.retypePassword === '' || this.retypePassword.trim() === '') {
      // Display error message for empty retype password
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Re-Enter The New Password For Confirmation',
        timer: 5000,
      });
      return;
    } else if (this.newpassword?.length < 6) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Password should have minimum of 6 characters',
        timer: 5000,
      });
      return;
    } else if (this.passwordFlag) {
      // Display error message for mismatched passwords
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'New Password and Confirm Password should match',
        timer: 5000,
      });
      return;
    } else {
      this.loading = true;
      const requestBody = {
        email: this.userid,
        newpassword: this.newpassword,
      };
      this.httpService.post(API_URL + '/resetpassword', requestBody, null, null)
          .subscribe((result) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Password Changed Successfully',
              timer: 5000,
            });
            this.viewchange('table', this.user_id.id);
            this.getFilterUserList();
            this.resetForm();
            this.loading = false;
          }, (error) => {
            this.loading = false;
            console.error('Error Resetting Password:', error);
          },
          );
    }
  }

  search: any = '';


  search1: any = '';


  search2: any = '';


  DeleteUser(user) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to deactivate user ' + user.firstName + ' ?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.loading = true;
        this.Deletedata(user.id);
      }
    });
  }

  ActivateUser(user) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to activate user ' + user.firstName + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.loading = true;
        this.Restoredata(user.id);
      }
    });
  }

  resetForm() {
    this.newuser = {
      first_name: null,
      middle_name: null,
      last_name: null,
      avatar_url: null,
      email: null,
      phone: null,
      whatsapp: null,
      designation: null,
      address: null,
      city: {id: null},
      zip_code: null,
      user_type: null,
      office_location: null,
      is_super_user: false,
      user_name: null,
      password_hash: 'test123',
      last_logged_in_at: null,
      last_logged_in_ip: null,
      is_active: true,
      created_by: null,
      updated_by: null,
      customer_type: null,
      org_customer_id: null,
      customer_id: null,
      varification_id_type: null,
      verification_id: null,
      employment_no: null,
    };
    this.user_cities = '';
    this.user_states = '';
    this.user_pincodes = '';
    this.user_countries = '';
    this.user_sel_state = '';
    this.user_sel_country = '';
    this.user_sel_pincodes = '';
    this.emailExist = false;
    this.phoneNoExist = false;
    this.retypePassword = null;
    this.newpassword = null;
    this.passwordFlag = null;
  }


  // ******************************************************************************

  gotoUserCreatePage(){
    this.router.navigate(["/usermanagementCreate"]);
  }

  gotoUserEditPage(id){
    this.router.navigate(["/usermanagementEdit",id]);
  }
}
