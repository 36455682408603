import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {API_URL} from '../../../../../Global_API_URL.js';
import {getCitiesById, getCountries, getPincodesById, getStatesById} from '../../getCities.js';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import Swal from 'sweetalert2';
import {AuthService} from '../../../auth.service';
import {HttpService} from '../../../services/http.service';

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#d92550';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.sass'],
})
export class AddressComponent implements OnInit {
  @Input('user_id') userId ;
  @Input('user_name') userName = 'India';


  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };

  new_address = {
    address: '',
    appuser_id: null,
    pincode: {
      pin_number : ''
    },
  }
  address={
    address: '',
    country: '',
    state: '',
    city: '',
    zip_code: '',
  }

  sel_country
  sel_state
  sel_city

  address_created = false
  countriesList=null
  statesList=null
  citiesList=null
  pincodeList=null
  constructor(private authService : AuthService, private httpService: HttpService) {
    this.authService.getAuthToken();
  }

  ngOnInit(): void {
    this.countriesList = getCountries();
    // console.log(TOKEN);
    // console.log(this.countriesList);
  }


  async createAddress() {
    const TOKEN = this.authService.getAuthToken();
    this.new_address.appuser_id= this.userId;
    this.loading=true;
    if (this.new_address.address == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Address ',
        timer: 5000,

      });
    } /*else if (this.new_address.pincode == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select City ',
        timer: 5000,

      });
    }*/ else if (this.new_address.pincode.pin_number == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select Pincode ',
        timer: 5000,
      });
    } else {
      this.httpService.post(API_URL + '/address',
          this.new_address, null, null)
          .subscribe((response) => {
            this.address_created= true;
            this.loading=false;
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Address created Successfully',
              showConfirmButton: false,
              timer: 5000,
            });
          });
    }
    this.loading=false;
  }


  async getStates() {
    // console.log(this.sel_country);
    this.statesList = getStatesById(this.sel_country);
    this.sel_state = null;
    this.citiesList= null;
    this.sel_city = null;
  }

  async getCities() {
    this.citiesList = getCitiesById(this.sel_state);
    this.pincodeList =null;
    this.sel_city = null;
  }

  async getPincodes() {
    this.pincodeList=getPincodesById(this.sel_city);
    this.new_address.pincode.pin_number =null;
  }
}
