import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-quick-products',
  templateUrl: './quick-products.component.html',
  styleUrls: ['./quick-products.component.sass'],
})
export class QuickProductsComponent implements OnInit {
  constructor(private route: Router) { }

  ngOnInit(): void {
  }

  goto(filter: string) {
    this.route.navigate(['/quickonline'],
        {
          queryParams: {
            type: 'QUICK',
            mode: filter,
          },
        },
    );
  }
  gotoATD(filter: string) {
    this.route.navigate(['/quickATDonline'],
        {
          queryParams: {
            type: 'QUICK',
            mode: filter,
          },
        },
    );
  }

  gotoDTA(filter: string) {
    this.route.navigate(['/quickDTAonline'],
        {
          queryParams: {
            type: 'QUICK',
            mode: filter,
          },
        },
    );
  }
  gotoDTD(filter: string) {
    this.route.navigate(['/quickDTDonline'],
        {
          queryParams: {
            type: 'QUICK',
            mode: filter,
          },
        },
    );
  }
}
