<div *ngIf="loading" class="loader-container">
  <ngx-loading [show]="loading"
    [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
    [template]="loadingTemplate"></ngx-loading>
</div>
<div class="main-card mb-3 card">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-normal">
      <a><i class="fa fa-arrow-left" (click)="gotoConsignment()" style="cursor: pointer"></i></a>&nbsp;Edit
      Consignments
    </div>
  </div>
  <div class="container ">
    <div style="display: flex">
      <div>
        <h3>Consignment Number : &nbsp;&nbsp;&nbsp;</h3>
      </div>
      <div style="color: blue">
        <h3>{{consignment_details.consignmentNo}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">

        <mat-form-field style="width: 90%;" appearance="standard">
          <mat-label for="totalWeight">
            Invoice Reference No.<span class="text-danger">*</span>
          </mat-label>
          <input #input matInput [(ngModel)]="consignment_details.invoiceReferenceNo" maxlength="16">
          <mat-hint align="end">{{input.value.length}}/16</mat-hint>
        </mat-form-field>

      </div>
      <div class="col-md-2">

        <mat-form-field style="width: 90%;" appearance="standard">
          <mat-label>
            PO Invoice Date
          </mat-label>
          <input matInput [(ngModel)]="consignment_details.invoiceCreatedDate" [matDatepicker]="picker" disabled>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>

      </div>
      <div class="col-md-2">

        <mat-form-field style="width: 90%;" appearance="standard">
          <mat-label for="totalWeight">
            Consignment Value <span class="text-danger">*</span>
          </mat-label>
          <input id="Consignment Value" (keypress)="validateInput($event)" matInput
            [(ngModel)]="consignment_details.totalConsignmentValue">
        </mat-form-field>

      </div>
      <div class="col-md-2">
        <mat-form-field style="width: 90%;" appearance="standard">
          <mat-label>
            Weight Divisor <span class="text-danger">*</span>
          </mat-label>
          <input id="Weight Divisor" matInput
                 [(ngModel)]="consignment_details.weightDivisor" disabled>
        </mat-form-field>
      </div>
      <div class="col-md-2" *ngIf="consignment_details.tentative_pickup_time !=null">

        <mat-form-field style="width: 90%;" appearance="standard">
          <mat-label>
            Tentative Pickup Date
          </mat-label>
          <input matInput [(ngModel)]="consignment_details.tentative_pickup_time" [matDatepicker]="pickup" disabled>
          <mat-datepicker-toggle matSuffix [for]="pickup"></mat-datepicker-toggle>
          <mat-datepicker #pickup disabled="false"></mat-datepicker>
        </mat-form-field>

      </div>
      <div class="col-md-2" *ngIf="consignment_details.requiredTemperature !=null ">
        <mat-form-field appearance="standard">
          <mat-label for="Temprature">
            Temperature <span class="text-danger">*</span>
          </mat-label>
          <mat-select [(ngModel)]="consignment_details.requiredTemperature.id">
            <mat-option *ngFor="let temp of containerTempRangeOptions" [value]="temp.id">
              {{ temp.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">

        <mat-form-field style="width: 90%;" appearance="standard">
          <mat-label>
            Created Date
          </mat-label>
          <input id="createdAt" matInput [(ngModel)]="consignment_details.createdAt" [matDatepicker]="pickers" disabled>
          <mat-datepicker-toggle matSuffix [for]="pickers"></mat-datepicker-toggle>
          <mat-datepicker #pickers disabled="false"></mat-datepicker>
        </mat-form-field>

      </div>
    </div>
    <div class="row">
      <div class="col-md-8 row">
        <mat-form-field style="width: 90%;" appearance="standard">
          <mat-label for="totalWeight">
            Remarks
          </mat-label>
          <textarea id="Remarks" matInput [(ngModel)]="consignment_details.remarks">
                  </textarea>
        </mat-form-field>
      </div>
      <div class="col-md-4" *ngIf="consignment_details.package_type!= null">
        <mat-form-field appearance="standard">
          <mat-label for="package_type">
            Package Type
          </mat-label>
          <textarea id="package_type" matInput [(ngModel)]="consignment_details.package_type">
                  </textarea>
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-divider></mat-divider>
      <div>
        <div>
          <table>
            <tr>
              <th width="50px">S.No</th>
              <th>Package Dimensions <span class="text-danger">*</span></th>
              <th>No of Packages <span class="text-danger">*</span></th>
              <th>Weight per Package <span class="text-danger">*</span></th>
              <th>Volume Weight</th>
              <th>Total Packages Weight</th>
              <th>Chargeable Weight</th>
              <th></th>
            </tr>

            <tbody>
              <tr *ngFor="let package of add_packages; let i = index">
                <td>
                  {{i + 1}}
                </td>
                <td>
                  <div style="display: flex; width: 100%">
                    <div>
                      <input type="text" placeholder="L" aria-label="Number" matInput [(ngModel)]="package.length"
                        min="1" maxlength="5" (keypress)="validateInput1($event)"
                        (input)="addeachPackageCharged(package.id)">
                    </div>
                    <div>
                      <input type="text" placeholder="B" aria-label="Number" matInput [(ngModel)]="package.breadth"
                        min="1" maxlength="5" (keypress)="validateInput1($event)"
                        (input)="addeachPackageCharged(package.id)">
                    </div>
                    <div>
                      <input type="text" placeholder="H" aria-label="Number" matInput [(ngModel)]="package.height"
                        min="1" maxlength="5" (keypress)="validateInput1($event)"
                        (input)="addeachPackageCharged(package.id)">
                    </div>
                  </div>
                </td>
                <td>
                  <mat-form-field class="py-0" appearance="standard">
                    <input type="text" placeholder="No of Packages" aria-label="Number" matInput
                      [(ngModel)]="package.package_no" min="1" (input)="addeachPackageCharged(package.id)"
                      (keypress)="validateInput($event)">

                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="py-0" appearance="standard">
                    <input type="text" placeholder="package weight" aria-label="Number" matInput
                      [(ngModel)]="package.weight_per_package" min="1" (input)="addeachPackageCharged(package.id)"
                      (keypress)="validateInput1($event)">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="py-0" appearance="standard">
                    <input type="text" placeholder="Volume Weight" matInput disabled
                      [(ngModel)]="package.volume_weight">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="py-0" appearance="standard">
                    <input placeholder="Total Package Weight" matInput disabled [(ngModel)]="package.total_weight">

                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="py-0" appearance="standard">
                    <input placeholder="Chargeable Weight" matInput disabled [(ngModel)]="package.chargeable_weight">
                  </mat-form-field>
                </td>
                <td>
                  <button class="btn btn-danger" (click)="DeletePack(package.id, i+1)">
                    <i class="pe-7s-trash">
                    </i>
                  </button>
                </td>

              </tr>
              <tr>
                <td class="mx-w-150">
                </td>
                <td class="mx-w-150">
                </td>
                <th class="mx-w-150">
                  <mat-label for="totalWeight">
                    Total No. Packages
                  </mat-label>
                  <mat-form-field style="width: 90%;" appearance="standard">
                    <input id="total_no_packages" matInput disabled value="Total No. Packages"
                      [(ngModel)]="consignment_details.totalNoOfPackages">
                  </mat-form-field>
                </th>

                <td>
                </td>
                <td>

                </td>
                <th class="mx-w-150">
                  <mat-label for="totalWeight">
                    Total Weight
                  </mat-label>
                  <mat-form-field style="width: 90%;" appearance="standard">
                    <input id="totalWeight" matInput disabled value="Google"
                      [(ngModel)]="consignment_details.totalWeight">
                  </mat-form-field>
                </th>
                <th class="mx-w-200">
                  <mat-label for="totalWeight">
                    Total Chargeable Weight
                  </mat-label>
                  <mat-form-field style="width: 90%;" appearance="standard">
                    <input id="totalChargableWeight" matInput disabled value="Google"
                      [(ngModel)]="consignment_details.totalChargableWeight">
                  </mat-form-field>
                </th>
                <td class="mx-w-200 align-middle">
                  <button class="btn btn-primary" (click)="addPackage()">
                    <i class="fa fa-plus">
                    </i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 m-auto">
          <div class="btn btn-danger btn-lg m-2" (click)="gotoConsignment()">
            Cancel
          </div>
          <div class="btn btn-primary btn-lg m-2" (click)="saveChanges()">
            Save
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
