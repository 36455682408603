import { API_URL } from '../../../Global_API_URL'
export async function getConsignmentType() {
  const TOKEN = sessionStorage.getItem('accessToken')
  let consignment_types = [];
  await fetch(API_URL + "/consignment_type", {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Authorization": "Bearer " + TOKEN
    }
  })

    // Converting received data to JSON
    .then(response => response.json())
    .then(json => {
      consignment_types = json;
      // console.log(consignment_types);
    });
  return consignment_types.data;
}

export async function getConsignmentSubType() {
  const TOKEN = sessionStorage.getItem('accessToken')
  let consignment_sub_types = [];
  await fetch(API_URL + "/consignment_sub_types", {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Authorization": "Bearer " + TOKEN
    }
  })

    // Converting received data to JSON
    .then(response => response.json())
    .then(json => {
      consignment_sub_types = json;
      // console.log(consignment_sub_types);
    });
  return consignment_sub_types.data;
}

export async function getSubTypeByConsignemntType(id) {
  const TOKEN = sessionStorage.getItem('accessToken')
  let consignment_sub_types = [];
  await fetch(API_URL + "/consignment_type/" + id + "/consignment_sub_types", {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Authorization": "Bearer " + TOKEN
    }
  })

    // Converting received data to JSON
    .then(response => response.json())
    .then(json => {
      consignment_sub_types = json;
      // console.log(consignment_sub_types);
    });
  return consignment_sub_types.data;
}

export async function getOrderType() {
  const TOKEN = sessionStorage.getItem('accessToken')
  let orderTypes = [];
  await fetch(API_URL + "/getOrderTypes", {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Authorization": "Bearer " + TOKEN
    }
  })
    // Converting received data to JSON
    .then(response => response.json())
    .then(json => {
      orderTypes = json;
      // console.log(consignment_sub_types);
    });
  return orderTypes.data;
}
