import {Component, OnInit} from '@angular/core';
import {API_URL} from '../../../../../Global_API_URL';
import {AuthService} from '../../../auth.service';


let USERID;

@Component({
  selector: 'app-update-tracking-status',
  templateUrl: './update-tracking-status.component.html',
  styleUrls: ['./update-tracking-status.component.sass'],
})
export class UpdateTrackingStatusComponent implements OnInit {
  consignment_status=[
    {
      id: 1,
      status: 'BOOKED',
    }, {
      id: 2,
      status: 'PAYMENT DONE',
    }, {
      id: 3,
      status: 'REACHED AT ORIGIN AGENT',
    }, {
      id: 4,
      status: 'SENT TO ORIGIN AIRPORT',
    }, {
      id: 5,
      status: 'REACHED AT ORIGIN AIRPORT',
    }, {
      id: 11,
      status: 'READY FOR COLLECTION AT AIRPORT',
    }, {
      id: 12,
      status: 'DELIVERED',
    }]

    manifest_status=
    [{
      id: 6,
      status: 'MANIFESTED',
    }, {
      id: 7,
      status: 'AIRLINE ACCEPTED',
    }, {
      id: 8,
      status: 'FLIGHT DEPARTED',
    }, {
      id: 9,
      status: 'FLIGHT ARRIVED',
    }, {
      id: 10,
      status: 'MANIFEST DELIVERED TO DESTINATION AGENT',
    },
    ];


  consignment_sts_update={
    consignmentId: null,
    status: null,
    comment: '',
  }

  manifest_sts_update={
    manifestId: null,
    status: null,
    comment: '',
  }
  constructor(private authService : AuthService) {
    this.authService.getAuthToken();
  }

  ngOnInit(): void {
    USERID =JSON.parse(localStorage.getItem('userDetails')).id;
  }

  async update_con_status() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/trackByConsignment',
        {
          method: 'POST',
          body: JSON.stringify(this.consignment_sts_update),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+TOKEN,
          },
        })
    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          alert('Status Changed');
        });
  }
  async update_mani_status() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/trackByManifest',
        {
          method: 'POST',
          body: JSON.stringify(this.manifest_sts_update),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+TOKEN,
          },
        })
    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          alert('Status Changed');
        });
  }
}
