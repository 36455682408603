import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTabsModules} from './DemoPages/Material/Layout/mat-tabs/mat-tabs.module';
// import { ConsignmentTableModule } from './Layout/Consignment/consignment-table/consignment-table.module';
import {MatTabsModule} from '@angular/material/tabs';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DevToolsExtension, NgRedux, NgReduxModule} from '@angular-redux/store';
import {ArchitectUIState, rootReducer} from './ThemeOptions/store';
import {ConfigActions} from './ThemeOptions/store/config.actions';
import {AppRoutingModule} from './app-routing.module';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {MAT_DATE_FORMATS, MatNativeDateModule} from '@angular/material/core';

import {CommonModule, DatePipe, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';

// import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
// import { initializeKeycloak } from './init/keycloak-init.factory';
// BOOTSTRAP COMPONENTS
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {LaddaModule} from 'angular2-ladda';
import {NgxLoadingModule} from 'ngx-loading';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
// import {SweetAlert2Module} from '@toverux/ngx-sweetalert2';
import {ToastrModule} from 'ngx-toastr';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
// import { CountUpModule } from 'countup.js-angular2';
import {AgmCoreModule} from '@agm/core';
import {ImageCropperModule} from 'ngx-image-cropper';
import {NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {NouisliderModule} from 'ng2-nouislider';
import {NgSelectModule} from '@ng-select/ng-select';
// import { SelectDropDownModule } from 'ngx-select-dropdown';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {JwBootstrapSwitchNg2Module} from 'jw-bootstrap-switch-ng2';
import {AngularEditorModule} from '@kolkov/angular-editor';
// import { TextMaskModule } from 'angular2-text-mask';
import {ClipboardModule} from 'ngx-clipboard';
import {TextareaAutosizeModule} from 'ngx-textarea-autosize';
import {ColorPickerModule} from 'ngx-color-picker';
import {DropzoneConfigInterface, DropzoneModule} from 'ngx-dropzone-wrapper';
import {ChartsModule} from 'ng2-charts';

// ANGULAR MATERIAL COMPONENTS
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';

// LAYOUT
import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {AppsLayoutComponent} from './Layout/apps-layout/apps-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';
import {ThemeOptions} from './theme-options';
import {OptionsDrawerComponent} from './ThemeOptions/options-drawer/options-drawer.component';
// import {PageTitleComponent} from './Layout/Components/page-title/page-title.component';
// HEADER
import {HeaderComponent} from './Layout/Components/header/header.component';
import {DotsComponent} from './Layout/Components/header/elements/dots/dots.component';
import {SearchBoxComponent} from './Layout/Components/header/elements/search-box/search-box.component';
import {MegamenuComponent} from './Layout/Components/header/elements/mega-menu/mega-menu.component';
import {
  MegapopoverComponent
} from './Layout/Components/header/elements/mega-menu/elements/megapopover/megapopover.component';
import {UserBoxComponent} from './Layout/Components/header/elements/user-box/user-box.component';
import {DrawerComponent} from './Layout/Components/header/elements/drawer/drawer.component';

// SIDEBAR
import {SidebarComponent} from './Layout/Components/sidebar/sidebar.component';
import {LogoComponent} from './Layout/Components/sidebar/elements/logo/logo.component';

// FOOTER
import {FooterComponent} from './Layout/Components/footer/footer.component';
import {FooterDotsComponent} from './Layout/Components/footer/elements/footer-dots/footer-dots.component';
import {FooterMenuComponent} from './Layout/Components/footer/elements/footer-menu/footer-menu.component';

// Pages
import {ForgotPasswordComponent} from './DemoPages/UserPages/forgot-password/forgot-password.component';
import {
  ForgotPasswordBoxedComponent
} from './DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component';
import {LoginBoxedComponent} from './DemoPages/UserPages/login-boxed/login-boxed.component';
import {LoginComponent} from './DemoPages/UserPages/login/login.component';
import {RegisterBoxedComponent} from './DemoPages/UserPages/register-boxed/register-boxed.component';
import {RegisterComponent} from './DemoPages/UserPages/register/register.component';

// Components
import {NgbdSortableHeaderDirective} from './DemoPages/Tables/dynamic/demo/sortable.directive';

// Apex Charts
import {NgApexchartsModule} from 'ng-apexcharts';
// Gauges Charts
import {GaugeModule} from 'angular-gauge';
import {TrendModule} from 'ngx-trend';
import {IclDashboardComponent} from './Layout/Components/icl-dashboard/icl-dashboard.component';
import {ConsignmentTableComponent} from './Layout/Consignment/consignment-table/consignment-table.component';
import {AddConsignmentComponent} from './Layout/Consignment/add-consignment/add-consignment.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDividerModule} from '@angular/material/divider';
import {TooltipRoutingModule} from './DemoPages/Material/Layout/tooltip/tooltip-routing.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatListModule} from '@angular/material/list';
import {MatStepperModule} from '@angular/material/stepper';
import {NotificationComponent} from './Layout/Notifications/notification/notification.component';
import {SendNotificationComponent} from './Layout/Notifications/send-notification/send-notification.component';
import {TableCountriesComponent} from './Layout/Countries/table-countries/table-countries.component';
import {TableStatesComponent} from './Layout/States/table-states/table-states.component';
import {TableCitiesComponent} from './Layout/Cities/table-cities/table-cities.component';
import {TableAirportComponent} from './Layout/Airport/table-airport/table-airport.component';
import {TableAirlinesComponent} from './Layout/Airlines/table-airlines/table-airlines.component';
import {
  AirlinePricingScheduleComponent
} from './Layout/Airlines/airline-pricing-schedule/airline-pricing-schedule.component';
import {
  AirportPricingScheduleComponent
} from './Layout/Airport/airport-pricing-schedule/airport-pricing-schedule.component';
import {FlightScheduleComponent} from './Layout/Flights/flight-schedule/flight-schedule.component';
import {UserMangementComponent} from './Layout/AppUser/user-mangement/user-mangement.component';
import {ManifestationCreateComponent} from './Layout/Manifestation/manifestation-create/manifestation-create.component';
import {OfficeLocationComponent} from './Layout/OfficeLocations/office-location/office-location.component';
import {
  ConsignmentSubtypeComponent
} from './Layout/consignmentSubtype/consignment-subtype/consignment-subtype.component';
import {NotfoundComponent} from './DemoPages/notfound/notfound.component';
import {ManifestationListComponent} from './Layout/Manifestation/manifestation-list/manifestation-list.component';
import {BulkUploadComponent} from './Layout/BulkUpload/bulk-upload/bulk-upload.component';
import {TrackingViewComponent} from './Layout/Tracking/tracking-view/tracking-view.component';
import {IclProductsComponent} from './Layout/IclProducts/icl-products/icl-products.component';
import {EditUserComponent} from './Layout/AppUser/edit-user/edit-user.component';
import {UpdateTrackingStatusComponent} from './Layout/Tracking/update-tracking-status/update-tracking-status.component';
import {CorporateBookingComponent} from './Layout/Consignment/corporate-booking/corporate-booking.component';
import {CustomersComponent} from './Layout/AppUser/customers/customers.component';
import {CorporateListComponent} from './Layout/Consignment/corporate-list/corporate-list.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AddressComponent} from './Layout/Address/address/address.component';
import {TotalconsignmentsComponent} from './Layout/Consignment/totalconsignments/totalconsignments.component';
import {MatTableModule} from '@angular/material/table';
import {CustomerTableComponent} from './Layout/AppUser/customer-table/customer-table.component';
import {TablePincodesComponent} from './Layout/AppUser/table-pincodes/table-pincodes.component';
import {UpdatePasswordComponent} from './Layout/AppUser/update-password/update-password.component';
import {
  RepresentativeCustomerComponent
} from './Layout/AppUser/representative-customer/representative-customer.component';
import {FilterPipe} from './pipes/filter.pipe';
import {QuickStartComponent} from './Layout/Components/QuickStart/quick-start/quick-start.component';
import {ComingSoonComponent} from './Layout/Components/QuickStart/coming-soon/coming-soon.component';
import {BookingTypesComponent} from './Layout/Components/QuickStart/booking-types/booking-types.component';
import {QuickOnlineComponent} from './Layout/Components/QuickStart/quick-online/quick-online.component';
import {QuickProductsComponent} from './Layout/Components/QuickStart/quick-products/quick-products.component';
import {OrdersComponent} from './Layout/orders/orders.component';
import {DoorPricingComponent} from './Layout/BulkUpload/door-pricing/door-pricing.component';
import {ATDConsignmentComponent} from './Layout/Consignment/atd-consignment/atd-consignment.component';
import {DTAConsignmentComponent} from './Layout/Consignment/dta-consignment/dta-consignment.component';
import {DTDConsignmentComponent} from './Layout/Consignment/dtd-consignment/dtd-consignment.component';
import {ControlPanelComponent} from './Layout/control-panel/control-panel.component';
import {OperationaltimingspanelComponent} from './Layout/operationaltimingspanel/operationaltimingspanel.component';
import {QuickATDComponent} from './Layout/Components/QuickStart/quick-atd/quick-atd.component';
import {QuickDTAComponent} from './Layout/Components/QuickStart/quick-dta/quick-dta.component';
import {QuickDTDComponent} from './Layout/Components/QuickStart/quick-dtd/quick-dtd.component';
import {MatDialogModule} from '@angular/material/dialog';
import {JwtModule} from '@auth0/angular-jwt';
import {AuthInterceptorInterceptor} from './auth-interceptor.interceptor';
import {
  AddConsignmentSubtypeConsignmentTypeProductTypeDialog,
  AddConsignmentTypeProductTypeDialog,
  ProductTypeComponent,
} from './Layout/product-type/product-type.component';
import {MatCardModule} from '@angular/material/card';
import {CostCenterEditComponent} from './Layout/Consignment/cost-center-edit/cost-center-edit.component';
import {CostCenterComponent} from './Layout/Consignment/cost-center/cost-center.component';
import {BillingInfoEditComponent} from './Layout/Consignment/billing-info-edit/billing-info-edit.component';
import {InvoiceHistoryComponent} from './Layout/Invoice/invoice-history/invoice-history.component';
import {CreateInvoiceComponent, InvoicePreview} from './Layout/Invoice/create-invoice/create-invoice.component';
import {BookingsUploadComponent} from './Layout/Consignment/bookings-upload/bookings-upload.component';
import {ConfirmDialog} from './Layout/Components/confirmDialog/confirmDialog.component';
import {BillingInfoPageComponent} from './Layout/Consignment/billing-info-page/billing-info-page.component';
import {
  ConsignmentSubtypesComponent,
  ConsignmentTypesComponent,
  ProductTypesComponent,
} from './Layout/consignment-types-subtypes/consignment-types-subtypes.component';
import {CreateConsignmentTypeDialog} from './modules/consignmentTypes/views/createConsignmentType';
import {CreateConsignmentSubtypeDialog} from './modules/consignmentSubtypes/views/createConsignmentSubtype';
import {MatIconModule} from '@angular/material/icon';
import {ViewconsignmentComponent} from './Layout/Consignment/viewconsignment/viewconsignment.component';
import {TableRegionsComponent} from './Layout/Regions/table-regions/table-regions.component';
import {MatChipsModule} from '@angular/material/chips';
import {GroundservicesComponent} from './Layout/Ground-Services/groundservices/groundservices.component';
import {ShipmentIngestionComponent} from './Layout/shipment-ingestion/shipment-ingestion.component';
import {TallyIrnGenerateComponent} from './Layout/Invoice/tally-irn-generate/tally-irn-generate.component';
import {
  CustomerConsignmentViewPageComponent,
} from './Layout/AppUser/customer-consignment-view-page/customer-consignment-view-page.component';
import {ConsignmentEditComponent} from './Layout/Consignment/consignment-edit/consignment-edit.component';
import {CreateNewCustomerComponent} from './shared/create-new-customer/create-new-customer.component';
import {
  AirlinePromotionsComponent
} from './Layout/airline-promotions/airline-promotion-list/airline-promotions.component';
import {
  CreateAirlinePromotionComponent
} from './Layout/airline-promotions/create-airline-promotion/create-airline-promotion.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSortModule} from '@angular/material/sort';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {CreateNewRepresentativeComponent} from './shared/create-new-representative/create-new-representative.component';
import {PasswordModule} from 'primeng/password';
import {
  ViewAirlinePromotionComponent
} from './Layout/airline-promotions/view-airline-promotion/view-airline-promotion.component';
import {PaperWorkComponent} from './Layout/paper-work/paper-work.component';
import {PaperWorkAddComponent} from './Layout/paper-work-add/paper-work-add.component';
import {CustomerCreateComponent} from './Layout/AppUser/customer-create/customer-create.component';
import {CustomerEditComponent} from './Layout/AppUser/customer-edit/customer-edit.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {RetailCustomerTableComponent} from "./Layout/AppUser/retail-customer-table/retail-customer-table.component";
import {RetailCustomerCreateComponent} from "./Layout/AppUser/retail-customer-create/retail-customer-create.component";
import {NgOtpInputModule} from 'ng-otp-input';
import {CdTimerModule} from 'angular-cd-timer';
import {RetailCustomerEditComponent} from './Layout/AppUser/retail-customer-edit/retail-customer-edit.component';
import {RepresentativeCreateComponent} from './Layout/AppUser/representative-create/representative-create.component';
import {RepresentativeEditComponent} from './Layout/AppUser/representative-edit/representative-edit.component';
import {UserManagementCreateComponent} from './Layout/AppUser/user-management-create/user-management-create.component';
import {UserManagementEditComponent} from './Layout/AppUser/user-management-edit/user-management-edit.component';
import { BillingCostBulkUploadComponent } from './Layout/Consignment/billing-cost-bulk-upload/billing-cost-bulk-upload.component';
import { ContractsComponent } from './Layout/contracts/contracts.component';
import { CreateContractComponent } from './Layout/contracts/create-contract/create-contract.component';
import { CreateRegionComponent } from './Layout/Regions/create-region/create-region.component';
import { RegionsEditComponent } from './Layout/Regions/regions-edit/regions-edit.component';
import { RegionPricesComponent } from './Layout/contracts/region-prices/region-prices.component';
import { CityPricesComponent } from './Layout/contracts/city-prices/city-prices.component';

import {InfoComponent} from './Layout/info/info.component';
import {MatTreeModule} from '@angular/material/tree';
import {CsvFileFormatsComponent} from './Layout/file-formats/csv-file-formats/csv-file-formats.component';
import {CsvFileFormatsAddComponent} from './Layout/file-formats/csv-file-formats-add/csv-file-formats-add.component';
// Angular Material

import { TallyIrnGenerateViewComponent } from './Layout/Invoice/tally-irn-generate-view/tally-irn-generate-view.component';
import { AirlinePricingViewComponent } from './Layout/BulkUpload/airline-pricing-view/airline-pricing-view.component';
import { AirlinePricingEditComponent } from './Layout/BulkUpload/airline-pricing-edit/airline-pricing-edit.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*',
};

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY', // Define the format for parsing user input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // Define the format for displaying the date
    monthYearLabel: 'MMM YYYY', // Optional: Month and year format in the calendar header
    dateA11yLabel: 'LL', // Optional: Format for date accessibility label
    monthYearA11yLabel: 'MMMM YYYY', // Optional: Format for month and year accessibility labelo
  },
};

export function tokenGetter() {
  return localStorage.getItem('jwt');
}

@NgModule({
  declarations: [
    // COnsignment
    ConsignmentTableComponent,
    AddConsignmentComponent,
    TableCountriesComponent,
    TableStatesComponent,


    // LAYOUT
    AppComponent,
    BaseLayoutComponent,
    AppsLayoutComponent,
    PagesLayoutComponent,
    OptionsDrawerComponent,
    FilterPipe,

    // HEADER
    HeaderComponent,
    DotsComponent,
    SearchBoxComponent,
    MegamenuComponent,
    MegapopoverComponent,
    UserBoxComponent,
    DrawerComponent,


    // SIDEBAR
    SidebarComponent,
    LogoComponent,

    // FOOTER
    FooterComponent,
    FooterDotsComponent,
    FooterMenuComponent,

    // // User Pages
    ForgotPasswordComponent,
    ForgotPasswordBoxedComponent,
    LoginBoxedComponent,
    LoginComponent,
    RegisterBoxedComponent,
    RegisterComponent,

    // Tables
    NgbdSortableHeaderDirective,
    IclDashboardComponent,
    ConsignmentTableComponent,
    AddConsignmentComponent,
    NotificationComponent,
    SendNotificationComponent,
    TableStatesComponent,
    TableCitiesComponent,
    TableAirportComponent,
    TableAirlinesComponent,
    AirlinePricingScheduleComponent,
    AirportPricingScheduleComponent,
    FlightScheduleComponent,
    UserMangementComponent,
    ManifestationCreateComponent,
    OfficeLocationComponent,
    ConsignmentSubtypeComponent,
    NotfoundComponent,
    ManifestationListComponent,
    BulkUploadComponent,
    DoorPricingComponent,
    TrackingViewComponent,
    IclProductsComponent,
    EditUserComponent,
    UpdateTrackingStatusComponent,
    CorporateBookingComponent,
    CustomersComponent,
    CorporateListComponent,
    AddressComponent,
    TotalconsignmentsComponent,
    CustomerTableComponent,
    CustomerConsignmentViewPageComponent,
    TablePincodesComponent,
    UpdatePasswordComponent,
    RepresentativeCustomerComponent,
    QuickStartComponent,
    ComingSoonComponent,
    BookingTypesComponent,
    QuickOnlineComponent,
    QuickProductsComponent,
    OrdersComponent,
    ATDConsignmentComponent,
    DTAConsignmentComponent,
    DTDConsignmentComponent,
    ControlPanelComponent,
    OperationaltimingspanelComponent,
    QuickATDComponent,
    QuickDTAComponent,
    QuickDTDComponent,
    ProductTypeComponent,
    AddConsignmentTypeProductTypeDialog,
    AddConsignmentSubtypeConsignmentTypeProductTypeDialog,
    ConfirmDialog,
    CostCenterEditComponent,
    CostCenterComponent,
    BillingInfoEditComponent,
    InvoiceHistoryComponent,
    CreateInvoiceComponent,
    TallyIrnGenerateComponent,
    InvoicePreview,
    BookingsUploadComponent,
    BillingInfoPageComponent,
    ProductTypesComponent,
    ConsignmentTypesComponent,
    ConsignmentSubtypesComponent,
    CreateConsignmentTypeDialog,
    CreateConsignmentSubtypeDialog,
    ViewconsignmentComponent,
    ConsignmentEditComponent,
    TableRegionsComponent,
    GroundservicesComponent,
    ShipmentIngestionComponent,
    CreateNewCustomerComponent,
    CreateNewRepresentativeComponent,
    AirlinePromotionsComponent,
    CreateAirlinePromotionComponent,
    ViewAirlinePromotionComponent,
    PaperWorkComponent,
    PaperWorkAddComponent,
    BillingCostBulkUploadComponent,
    CustomerCreateComponent,
    CustomerEditComponent,
    RetailCustomerTableComponent,
    RetailCustomerCreateComponent,
    RetailCustomerEditComponent,
    RepresentativeCreateComponent,
    RepresentativeEditComponent,
    UserManagementCreateComponent,
    UserManagementEditComponent,
    RepresentativeEditComponent,
    RetailCustomerCreateComponent,
    InfoComponent,
    CsvFileFormatsComponent,
    CsvFileFormatsAddComponent,
    ContractsComponent,
    CreateContractComponent,
    CreateRegionComponent,
    RegionsEditComponent,
    RegionPricesComponent,
    CityPricesComponent,
    TallyIrnGenerateViewComponent,
    AirlinePricingViewComponent,
    AirlinePricingEditComponent,


  ],
  imports: [
    PdfViewerModule,
    PasswordModule,
    NgOtpInputModule,
    CdTimerModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    MatInputModule,
    MatDividerModule,
    MatStepperModule,
    MatExpansionModule,
    FormsModule,
    MatDialogModule,
    // ICL componenets
    MatAutocompleteModule,
    TooltipRoutingModule,
    MatSlideToggleModule,
    MatListModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatTooltipModule,
    MatChipsModule,
    // KeycloakAngularModule,
    MatSelectModule,
    // ConsignmentTableModule,
    MatButtonToggleModule,

    // Angular Bootstrap Components
    MatTableModule,
    MatTabsModules,
    MatTabsModule,
    MatPaginatorModule,
    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    LaddaModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    RoundProgressModule,
    ToastrModule.forRoot(),
    SlickCarouselModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    // CountUpModule,
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: '',
    }),
    ImageCropperModule,
    NouisliderModule,
    NgSelectModule,
    // SelectDropDownModule,
    NgMultiSelectDropDownModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    AngularEditorModule,
    HttpClientModule,
    // TextMaskModule,
    ClipboardModule,
    TextareaAutosizeModule,
    ColorPickerModule,
    DropzoneModule,

    // Charts

    ChartsModule,
    NgApexchartsModule,
    GaugeModule.forRoot(),
    TrendModule,

    // Angular Material Components

    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatIconModule,
    MatGridListModule,
    MatSortModule,
    MatProgressBarModule,
    MatTreeModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {
      provide:
      PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
      DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true,
    },
    DatePipe,
    ConfigActions,
    ThemeOptions,
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
              private devTool: DevToolsExtension) {
    this.ngRedux.configureStore(
        rootReducer,
      {} as ArchitectUIState,
      [],
      [this.devTool.isEnabled() ? this.devTool.enhancer() : (f) => f],
    );
  }
}
