import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {HttpClient} from '@angular/common/http';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {API_URL} from '../../../../../Global_API_URL.js';
import {getCitiesById, getCountries, getPincodesById, getStatesById} from '../../getCities.js';
import {getAirports} from '../../getAirportAirlines.js';
import {getConsignmentType, getSubTypeByConsignemntType} from '../../getConsignmentType.js';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {Airport, ConsignmentDocumentType, CustomerList, individuallist, Package} from '../../golbalInterfaces.js';
import {DimentionUnits, WeightUnits} from '../../globalconstants.js';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../auth.service';
import {ConsignmentTypeService} from '../../../modules/consignmentTypes/services/consignment-type.service';
import {ConsignmentTypesModel} from '../../../modules/consignmentTypes/models/consignment-types-model';
import {ConsignmentSubTypesModel} from '../../../modules/consignmentSubtypes/models/consignment-sub-types-model';
import {formatDate} from '@angular/common';
import {CreateNewCustomerComponent} from '../../../shared/create-new-customer/create-new-customer.component';
import {MatDialog} from '@angular/material/dialog';
import {HttpService} from 'src/app/services/http.service';
import {
  CreateNewRepresentativeComponent,
} from '../../../shared/create-new-representative/create-new-representative.component';
import {ViewConsignmentService} from "../viewconsignment/view-consignment.service";
import {ConsignmentViewService} from "../../AppUser/customer-consignment-view-page/consignment-view.service";

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#d92550';
const SecondaryBlue = '#3f6ad8';


interface CustomerShipper {
  'id': number,
  'firstName': String,
  'address1': String,
  'city1': number,
  'zipCode': String,
  'creditPeriod': number,
  'credit_value': number,
  'email1': String,
  'isCreditExtended': boolean,
  'isKycApproved': boolean,
  'kycApprovedAt': boolean,
  'lastName': String,
  'phone1': String,
  'customerTypeId': number,
  'kycApprovedBy': boolean,
  'isBusinessAccount': boolean,
  'pan': String,
  'gst': String,
  'Id_type': number,
  'Id_value': String,
  'otp_varified': Boolean
}

@Component({
  selector: 'app-corporate-booking',
  templateUrl: './corporate-booking.component.html',
  styleUrls: ['./corporate-booking.component.sass'],
})
export class CorporateBookingComponent implements OnInit {
  isLiner = false;
  erroraddress = false;
  errorReceiver = false;
  errorShipper = false;
  errorRepresentative = false;
  show = false;
  CustomerExist = false;
  phoneNoExist = false;
  emailExist = false;
  displayAdminandPDA = true;
  consignmentNoExist = false;
  isplans = false;
  consignment_with_id;
  customer_with_id;
  receiver_with_id;
  plan_with_id;
  cities;
  states;
  pincodes
  reprsentativeAddress;
  MODE;
  user_cities
  user_states
  user_pincodes
  user_countries
  user_sel_city
  user_sel_state
  user_sel_country
  user_sel_pincodes
  representativeList = [];
  business_cities
  business_states
  business_pincodes
  business_sel_country
  business_sel_state
  business_sel_pincodes
  selectedAddress = null;
  clicked = false;

  // countries: Countries[] = getCountries();
  countries = getCountries();
  selectedWeight: string = 'kg';
  selectedDimention: string = 'cm';
  faArrowRight = faArrowRight;
  myControl = new FormControl();
  myControl1 = new FormControl();
  myControl2 = new FormControl();
  customerList: CustomerList[];
  IndividualList: individuallist[];
  MyControl_retail = new FormControl();
  MyControl_corporate = new FormControl();
  filteredOptions: Observable<CustomerList[]>;
  filteredOption: Observable<individuallist[]>;
  dimensionuUnits_array: DimentionUnits[] = [{id: 1, name: 'cm'}];
  weightUnits_array: WeightUnits[] = [{id: 1, name: 'kg'}];
  paymentdone = false;
  weightDivisorList = [
    6000, 5000, 4500, 3600, 2700,
  ]
  consignment_details = {
    id: null,
    consignmentType: null,
    weightUnit: 1,
    currency: 1,
    weightDivisor: 6000,
    consignmentNo: null,
    airlinesId: null,
    isStopover: null,
    totalTransitTime: null,
    senderIndividual: null,
    isIndividualCustomer: null,
    paymentCompletedAt: null,
    isXrayScreening: null,
    isXrayCertification: null,
    isDryIce: null,
    totalWeight: null,
    totalChargableWeight: null,
    planSelected: null,
    senderId: null,
    pickupAddress: null,
    receiverId: null,
    isDocumentUploaded: null,
    isInsured: false,
    totalShippingCost: null,
    paymentMode: null,
    isPaymentCompleted: null,
    isActive: null,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
    totalNoOfPackages: null,
    destinationLocation: null,
    consignmentSubType: null,
    originLocation: null,
    dimensionuUnit: 1,
    insuranceCoverage: 0,
    totalConsignmentValue: null,
    shippingCost: 0,
    invoiceCreatedDate: '',
    invoiceReferenceNo: null,
    productType: null,
    remarks: null,
    commodityType: null,
  }
  packages: Package[] = [
    {
      id: 1,
      length: null,
      breadth: null,
      height: null,
      weight: null,
      total_weight: null,
      packageNo: null,
      volume_weight: null,
      chargeable_weight: null,
      totalvolumeweight_per_pack: null,
    },
  ]
  getAirportFromCity(city_id) {
    const airport = this.airports.filter((option) => {
      // validating is_active and ob_active
      if (option.isActive && option.ob_active) {
        return option.city.name;
      } else {
        return 'City Not Serviceable';
      }
    });
    for (let i = 0; i < airport.length; i++) {
      if (airport[i].id == city_id) {
        // console.log(airport[i]);
        return airport[i];
      }
    }
  }


  getAirportFromCityReceiver(city_id) {
    const airport = this.airports.filter((option) => {
      // validating is_active and ib_active
      if (option.isActive && option.ib_active) {
        return option.city.name;
      } else {
        return 'City Not Serviceable';
      }
    });
    for (let i = 0; i < airport.length; i++) {
      if (airport[i].id == city_id) {
        // console.log(airport[i]);
        return airport[i];
      }
    }
  }


  receiver = {
    id: null,
    firstName: null,
    lastName: null,
    address: null,
    city: null,
    zipCode: null,
    phone1: null,
    email1: null,
    companyName: '',
  }
  consignmentTax = 0;
  validShipper = true;
  val = 1;
  maxDate: Date;
  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };

  // alertfunc() {
  //   alert(this.consignment_details.weightDivisor)
  // }
  total_cost = 0;
  indiviudal_user = {
    id: null,
    firstName: '',
    middleName: '',
    lastName: '',
    avatarUrl: '',
    email: '',
    emailForMasterGST: '',
    phone: '',
    whatsapp: '',
    designation: null,
    employmentNo: null,
    billingAddress: {
      pincode: {
        pin_number: ''
      },
      address: ''
    },
    isSuperUser: null,
    userName: '',
    passwordHash: null,
    lastLoggedInAt: null,
    lastLoggedInIp: null,
    userType: null,
    customerType: 2,
    orgCustomerId: {id: null},
    aadhaarNumber: null,
    drivingLicense: null,
    gstNumber: null,
    panNumber: null,
    gstDetails: null,
    panDetails: null,
    aadhaarDetails: null,
    isActive: true,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
  }
  keyuser = ''
  customer_created = false;
  customer_shipper = {
    id: null,
    firstName: '',
    address_1: '',
    city_1: null,
    creditPeriod: null,
    credit_value: null,
    email_1: '',
    zip_code: '',
    isCreditExtended: null,
    isKycApproved: null,
    kycApprovedAt: null,
    office_location: null,
    lastName: '',
    phone_1: '',
    customerTypeId: 2,
    kycApprovedBy: null,
    isBusinessAccount: true,
    pan: '',
    gst: '',
    Id_type: null,
    Id_value: null,
    otp_varified: null,
    isSezCustomer: false,
  }
  change_business() {
    if (this.val == 1) {
      this.indiviudal_user.customerType = 2;
    } else {
      this.indiviudal_user.customerType = 1;
    }
  }
  AutomobileDocument: Array<ConsignmentDocumentType> = [
    {
      id: 1,
      name: 'Non-DG Declaration',
      description: 'Non-DG Declaration',
      is_mandatory: true,
      upload_later: false,
    },
    {
      id: 2,
      name: 'E-waybill',
      description: 'E-waybill',
      is_mandatory: false,
      upload_later: false,
    },
    {
      id: 3,
      name: 'Packing List',
      description: 'Packing List',
      is_mandatory: false,
      upload_later: false,
    },
  ]

  planList: any = {
    origin: {},
    destination: {},
    bestRoutes: [],
    count: 15,
    query_timestamp: '2022-04-21T10:30:00+5:30',
  }

  PharmaDocument: Array<ConsignmentDocumentType> = [
    {
      id: 1,
      name: 'MSDS or COA',
      description: 'MSDS or COA',
      is_mandatory: true,
      upload_later: false,
    },
    {
      id: 2,
      name: 'E-waybill',
      description: 'E-waybill',
      is_mandatory: false,
      upload_later: false,
    },
    {
      id: 3,
      name: 'Non-DG Declaration',
      description: 'Non-DG Declaration',
      is_mandatory: true,
      upload_later: false,
    },
  ]

  requiredDocument: any = []

  consignmentTypes: ConsignmentTypesModel[];
  // consignmentTypes = getConsignmentType();
  consignmentSubTypes: ConsignmentSubTypesModel[];
  // consignmentSubTypes: Observable<any>;
  filteredAirports: Observable<Airport[]>
  filteredAirports2: Observable<Airport[]>
  airports: Airport[] = getAirports();
  airports2: Airport[] = getAirports();


  emailregex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
  emailFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.emailregex),
  ]);

  panregex = /^[a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][0-9][0-9][0-9][0-9][a-zA-Z]$/
  PanFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.panregex),
  ]);
  gstregex = /^[0-9][0-9][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][0-9][0-9][0-9][0-9][a-zA-Z][0-9a-zA-Z][a-zA-Z][0-9a-zA-Z]$/
  gstFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.gstregex),
  ]);

  selectConsignmentType(consignmentType: ConsignmentTypesModel) {
    this.consignment_details.consignmentSubType = null;
    this.consignmentSubTypes = consignmentType.consignment_sub_types_list;
  }

  getDocumentTypes() {
    this.requiredDocument = [];
    let con_docs = [
      {
        id: 1,
        name: 'MSDS or COA',
        description: 'MSDS or COA',
        is_mandatory: true,
        upload_later: false,
      },
      {
        id: 2,
        name: 'E-waybill',
        description: 'E-waybill',
        is_mandatory: false,
        upload_later: false,
      },
      {
        id: 3,
        name: 'Non-DG Declaration',
        description: 'Non-DG Declaration',
        is_mandatory: true,
        upload_later: false,
      },
    ];

    if (this.consignmentSubTypes && this.consignment_details && this.consignment_details.consignmentSubType) {
      this.consignmentSubTypes.forEach((element) => {
        if (element.id == this.consignment_details.consignmentSubType) {
          con_docs = []; // Ensure fallback to an empty array if undefined
        }
      });
    } else {
      // Handle scenario where necessary data is missing or undefined
      console.error('Missing required data for document types');
      return;
    }

    for (let i = 0; i < con_docs.length; i++) {
      const document_req = {
        id: null,
        name: null,
        description: null,
        is_mandatory: false,
        upload_later: false,
        file: null,
      };

      document_req.id = con_docs[i].id;
      document_req.name = con_docs[i].name;
      document_req.is_mandatory = con_docs[i].is_mandatory;
      document_req.upload_later = con_docs[i].upload_later;
      this.requiredDocument.push(document_req);
    }
  }
  getFiles(event, id) {
    // alert(id);
    for (let i = 0; i < this.requiredDocument.length; i++) {
      if (this.requiredDocument[i].id == id) {
        // alert("here id ="+id)
        this.requiredDocument[i].file = event.target.files[0];
      }
    }
  }


  async findindividual() {
    const TOKEN = this.authService.getAuthToken();
    this.errorShipper = true;
    this.errorRepresentative = true;
    await fetch(API_URL + '/org_users/' + this.indiviudal_user.id,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
            'X-Active': 'true',

          },
        })
        .then((response) => response.json())
        .then((result) => {
          this.representativeList = result.data;
          this.getAddressList(this.indiviudal_user.id);
          this.show = true;
        });
  }
  async uploadDocument() {
    const TOKEN = this.authService.getAuthToken();
    for (let i = 0; i < this.requiredDocument.length; i++) {
      if (this.requiredDocument[i].upload_later) {
        alert('update later');
      } else {
        const head: any = {
          'Authorization': 'Bearer ' + TOKEN,
          'fileMd5Hash': '',
          'fileType': '',
          'name': '',
          'consignmentDocumentTypeId': this.requiredDocument[i].id,
          'consignmentId': 1,
        };
        const formdata = new FormData();
        formdata.append('file', this.requiredDocument[i].file);
        // console.log("file data --- " + formdata)

        await fetch(API_URL + '/consignmentDocuments',
            {
              method: 'POST',
              body: formdata,
              headers: head,
            })
            .then((response) => response.json())
            .then((result) => {
            //  fetch(API_URL + "users/" + result[0].id + "/upload", {
            //    method: "PUT",
            //    body: formData,
            //  })
            //    .then((res) => {
            //     alert(res)
            //    })
            //    .catch((error) => {
            //      // alert("ERROR" + error);
            //      console.error("Error", error);
            //      alert(error)
            //    });
            // console.log(result);
            })
            .catch((error) => {
              console.error('Error', error);
              alert(error);
            });
      }
    }
  }

  displayFn(user: CustomerList): String {
    return user && user.firstName ? user.firstName : '';
  }

  private _filter(name: String): CustomerList[] {
    const filterValue = name.toLowerCase();

    return this.customerList.filter((option) => option.firstName.toLowerCase().includes(filterValue));
  }

  displayFnss(user: individuallist): String {
    return user && user.firstName ? user.firstName : '';
  }
  private _filterss(name: String): individuallist[] {
    const filterValue = name.toLowerCase();
    return this.IndividualList.filter((option) => option.firstName.toLowerCase().includes(filterValue));
  }
  private _filter1(name: String): Airport[] {
    const filterValue = name.toLowerCase();

    return this.airports.filter((option) => option.city.name.toLowerCase().includes(filterValue));
  }
  private _filter2(name: String): Airport[] {
    const filterValue = name.toLowerCase();

    return this.airports2.filter((option) => option.city.name.toLowerCase().includes(filterValue));
  }
  displayFn1(airport: Airport): String {
    return airport && airport.city?.name ? airport.city?.name : '';
  }
  displayFn2(airport2: Airport): String {
    return airport2 && airport2.city?.name ? airport2.city?.name : '';
  }
  userDetails
  async ngOnInit() {
    this.route.params.subscribe((params) => {
      // Access the parameter
      this.PRODUCT_TYPE = params['productType'];
      this.currProductType = this.PRODUCT_TYPE.replace('1', '');
    });
    this.getOfficeLocations();
    this.commodityTypes = await this.getCommodityTypes();
    if (this.consignment_details.originLocation == null && this.consignment_details.destinationLocation == null) {
      this.myControl1.disable();
      this.myControl2.disable();
    } else if (this.consignment_details.originLocation != null && this.consignment_details.destinationLocation != null) {
      this.myControl1.disable();
      this.myControl2.disable();
    }

    // this.route.queryParams.subscribe(params => {
    //   console.log(params);


    //   console.log("this is type"+ this.MODE);
    // }
    // )
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name == 'Admin' || this.userDetails.userTypes[0].name == 'Supervisor') {
      this.displayAdminandPDA = false;
    }
    this.countries = getCountries();
    this.airports = await getAirports();
    this.airports2 = await getAirports();
    this.filteredAirports = this.myControl1.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter1(name) : this.airports.slice())),
    );
    this.filteredAirports2 = this.myControl2.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter2(name) : this.airports2.slice())),
    );
    this.customerList = await this.getCustomerList();
    this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : value?.name;
          // this.findbusiness()
          return name ? this._filter(name as string) : this.customerList.slice();
        }),
    );
    this.IndividualList = await this.getIndividualList();
    this.filteredOption = this.myControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : value?.name;
          return name ? this._filterss(name as string) : this.IndividualList.slice();
        }),
    );

    // this.route.params.subscribe(params => {
    //   // Access the parameter
    //   this.PRODUCT_TYPE = params['productType'];
    //   this.currProductType = this.PRODUCT_TYPE.replace('1', '');
    // });

    await this.consignmentTypeService.getConsignmentTypeByProductType(this.currProductType).subscribe((result) => {
      this.consignmentTypes = result.data;
    });
  }

  PRODUCT_TYPE: string;

  currProductType: string;


  async getConsignmentSubtype() {
    if (this.consignment_details.consignmentType != null) {
      this.consignmentSubTypes = await getSubTypeByConsignemntType(this.consignment_details.consignmentType);
      // console.log(this.consignmentSubTypes + "in blur function")
    }
  }


  addPackage() {
    const new_package = {
      id: this.packages.length + 1,
      length: null,
      breadth: null,
      height: null,
      weight: null,
      total_weight: null,
      packageNo: null,
      volume_weight: null,
      chargeable_weight: null,
      totalvolumeweight_per_pack: null,
    };
    this.packages.push(new_package);
    let j = 1;
    this.packages.forEach((element) => {
      element.id = j;
      j++;
    });
  }

  deletePackage(id) {
    for (let index = 0; index < this.packages.length; index++) {
      if (this.packages[index].id == id) {
        this.removeItemOnce(this.packages, index);
      }
    }
    let j = 1;
    this.packages.forEach((element) => {
      element.id = j;
      j++;
    });
    const length = this.packages.length;
    this.eachPackageCharged(length);
  }

  selected_dimentions() {
    for (let index = 0; index < this.dimensionuUnits_array.length; index++) {
      if (this.dimensionuUnits_array[index].id == this.consignment_details.dimensionuUnit) {
        this.selectedDimention = this.dimensionuUnits_array[index].name;
      }
    }
  }

  selected_weight() {
    for (let index = 0; index < this.weightUnits_array.length; index++) {
      if (this.weightUnits_array[index].id == this.consignment_details.weightUnit) {
        this.selectedWeight = this.weightUnits_array[index].name;
      }
    }
  }

  weightDivisorChange() {
    for (let i = 0; i < this.packages.length; i++) {
      this.eachPackageCharged(this.packages[i].id);
      // alert( "here")
    }
  }

  totalvolume_weight = null;
  eachPackageCharged(id) {
    this.consignment_details.totalNoOfPackages = 0;
    this.consignment_details.totalWeight = 0;
    this.consignment_details.totalChargableWeight = 0;
    for (let index = 0; index < this.packages.length; index++) {
      this.packages[index].total_weight = Number(this.packages[index].packageNo) * this.packages[index].weight;
      if (this.packages[index].id == id) {
        this.packages[index].volume_weight = (Number(this.packages[index].length) * Number(this.packages[index].breadth) * Number(this.packages[index].height) / Number(this.consignment_details.weightDivisor));

        if (this.packages[index].volume_weight > this.packages[index].weight) {
          this.packages[index].chargeable_weight = Number(this.packages[index].volume_weight) * Number(this.packages[index].packageNo);
        } else {
          this.packages[index].chargeable_weight = this.packages[index].total_weight;
        }
      }
      this.consignment_details.totalNoOfPackages = Number(this.consignment_details.totalNoOfPackages) + Number(this.packages[index].packageNo);
      this.consignment_details.totalWeight = this.consignment_details.totalWeight + Number(this.packages[index].total_weight);
      this.consignment_details.totalChargableWeight = Math.ceil(this.consignment_details.totalChargableWeight + this.packages[index].chargeable_weight);
      this.packages[index].totalvolumeweight_per_pack = Math.ceil((this.packages[index].volume_weight) * (this.packages[index].packageNo));
      this.totalvolumeweightCal(this.packages);
    }
  }

  totalvolumeweightCal(pac) {
    this.totalvolume_weight = 0;
    pac.forEach((element) => {
      this.totalvolume_weight = this.totalvolume_weight + element.totalvolumeweight_per_pack;
    });
    this.totalvolume_weight = Math.ceil(this.totalvolume_weight);
  }
  generate_awb() {
    // this.consignment_details.awb_no = 101010;
  }

  generate_ewaybill() {
    // this.consignment_details.e_waybill_no = 1234569870
    // let roles = this.keycloakService.logout();
    // alert(roles);
  }

  removeItemOnce(arr, value) {
    const index = value;
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }


  // add the get user detail api her if the user is existing


  async goToUpload() {
    const TOKEN = this.authService.getAuthToken();
    this.loadingThird = true;

    const sender_rev = {
      receiverId: null,
      pickupAddress: null,
    };
    sender_rev.pickupAddress = this.selectedAddress;
    sender_rev.receiverId = this.receiver_with_id.id;

    await fetch(API_URL + '/consignment/' + this.consignment_with_id.data[0].id,
        {
          method: 'PATCH',
          body: JSON.stringify(sender_rev),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .catch((error) => {
          console.error('Error', error);
          alert(error);
        });

    this.getDocumentTypes();

    this.loadingThird = false;
  }
  async createConsignment() {
    const TOKEN = this.authService.getAuthToken();
    if (this.selectedAddress == null || this.selectedAddress == undefined) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select PickUp Address ',
        timer: 5000,

      });
    } else {
      this.loading = true;
      const pac = {
        'packageNo': null,
        'shipment_id': null,
        'weight_per_package': null,
        'length': null,
        'breadth': null,
        'height': null,
        'total_weight': null,
        'chargeable_weight': null,
        'volume_weight': null,
      };
      const pacList = [];
      let valid_pack = true;
      for (let i = 0; i < this.packages.length; i++) {
        const pac = {
          'packageNo': null,
          'shipment_id': null,
          'weight_per_package': null,
          'length': null,
          'breadth': null,
          'height': null,
          'total_weight': null,
          'chargeable_weight': null,
          'volume_weight': null,
        };
        // if (this.packages[i].breadth > 90 || this.packages[i].length > 90 || this.packages[i].height > 90) {
        //   valid_pack = false;
        //   Swal.fire({
        //     icon: 'error',
        //     title: 'ERROR',
        //     text: 'Package dimetion should not exceed 90cm X 90cm X 90cm '
        //   });
        //   this.loading = false;
        //   break;
        // }
        // else
        if (this.packages[i].breadth < 1 || this.packages[i].length < 1 || this.packages[i].height < 1 || this.packages[i].breadth == null || this.packages[i].length == null || this.packages[i].height == null) {
          valid_pack = false;
          Swal.fire({
            icon: 'error',
            title: 'ERROR',
            text: 'Missing Package Dimension',
            timer: 5000,

          });
          this.loading = false;
          break;
        } else if (this.packages[i].packageNo <= 0 || this.packages[i].packageNo == null) {
          valid_pack = false;
          Swal.fire({
            icon: 'error',
            title: 'ERROR',
            text: 'Atleast one package is required',
            timer: 5000,

          });
          this.loading = false;
          break;
        } else if (this.packages[i].weight <= 0 || this.packages[i].weight == null) {
          valid_pack = false;
          Swal.fire({
            icon: 'error',
            title: 'ERROR',
            text: 'Weight per Package missing',
            timer: 5000,

          });
          this.loading = false;
          break;
        }
        pac.packageNo = this.packages[i].packageNo;
        pac.shipment_id = null;
        pac.weight_per_package = this.packages[i].weight;
        pac.length = this.packages[i].length;
        pac.breadth = this.packages[i].breadth;
        pac.height = this.packages[i].height;
        pac.total_weight = this.packages[i].total_weight;
        pac.chargeable_weight = this.packages[i].chargeable_weight;
        pac.volume_weight = this.packages[i].totalvolumeweight_per_pack;
        pacList.push(pac);
      }
      let formattedDate = '';
      if (this.consignment_details.invoiceCreatedDate) {
        formattedDate = formatDate(this.consignment_details.invoiceCreatedDate, 'yyyy-MM-dd', 'en-US');
      }
      const cons =
      {
        'consignmentType': this.consignment_details.consignmentType,
        'consignmentNo': this.consignment_details.consignmentNo,
        'dimensionuUnit': this.consignment_details.dimensionuUnit,
        'weightUnit': this.consignment_details.weightUnit,
        'currency': this.consignment_details.currency,
        'weightDivisor': this.consignment_details.weightDivisor,
        'details': 'string',
        'consignmentSubTypeId': this.consignment_details.consignmentSubType,
        'originLocation': this.consignment_details.originLocation,
        'destinationLocation': this.consignment_details.destinationLocation,
        'totalNoOfPackages': this.consignment_details.totalNoOfPackages,
        'totalWeight': this.consignment_details.totalWeight,
        'totalChargableWeight': this.consignment_details.totalChargableWeight,
        'packages': pacList,
        'isIndividualCustomer': true,
        'senderId': this.consignment_details.senderId,
        'pickupAddress': this.selectedAddress,
        'senderIndividual': this.consignment_details.senderIndividual,
        'totalConsignmentValue': this.consignment_details.totalConsignmentValue,
        'invoiceCreatedDate': formattedDate,
        'invoiceReferenceNo': this.consignment_details.invoiceReferenceNo,
        'isCoperateBooking': true,
        'remarks': this.consignment_details.remarks,
        'productType': this.consignment_details.productType,
        'receiverId': null,
        'paymentMode': this.consignment_details.paymentMode,
        'createdBy': this.userDetails.id,
        'commodity_type': this.consignment_details.commodityType,
      };
      // for (let i = 0; i < this.airports.length; i++) {
      //   if (this.customer_shipper.city1 == this.airports[i].city.id) {
      //     cons.originLocation = this.airports[i].id;
      //   }
      //   if (this.receiver.city == this.airports[i].city.id) {
      //     cons.destinationLocation = this.airports[i].id;
      //   }
      // }
      if (this.val == 1) {
        cons.isIndividualCustomer = false;
        // cons.senderIndividual=this.indiviudal_user.id
        // cons.senderId = this.indiviudal_user.org_customer_id.id
      } else {
        cons.isIndividualCustomer = true;
        cons.senderIndividual = this.indiviudal_user.id;
      }
      if (cons.senderIndividual == null) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Please enter the Customer ID',
          timer: 5000,
        });
        this.loading = false;
        return;
      } else if (this.receiver_with_id == null || this.receiver_with_id.id == null) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Please Enter Receiver Details',
          timer: 5000,
        });
        this.loading = false;
        return;
      } else if (cons.consignmentNo != null && cons.consignmentNo.length != 11) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Please enter 11 digit Consignment No.',
          timer: 5000,
        });
        this.loading = false;
        return;
      } else if (this.consignmentNoExist==true) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Consignment No. Exist',
          timer: 5000,
        });
        this.loading = false;
        return;
      }
      if (cons.consignmentType == null) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Consignment Type Missing',
          timer: 5000,
        });
        this.loading = false;
        return;
      }
      if (cons.consignmentSubTypeId == null) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Consignment Sub Type Missing',
          timer: 5000,
        });
        this.loading = false;
        return;
      } else if (cons.commodity_type == null) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Please Select Commodity Type',
          timer: 5000,
        });
        this.loading = false;
        return;
      } else if (cons.productType == null) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Please select Product Type',
          timer: 5000,
        });
        this.loading = false;
        return;
      } else if (cons.invoiceReferenceNo == null) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Please Enter Invoice Reference No.',
          timer: 5000,
        });
        this.loading = false;
        return;
      } else if (cons.paymentMode == null) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Please Select Payment Mode.',
          timer: 5000,
        });
        this.loading = false;
        return;
      } else if (cons.originLocation == null) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Please select Origin Location',
          timer: 5000,
        });
        this.loading = false;
        return;
      } else if (cons.destinationLocation == null) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Please select Destination Location',
          timer: 5000,
        });
        this.loading = false;
        return;
      } else if (valid_pack == true) {
        cons.originLocation = cons.originLocation.id;
        cons.destinationLocation = cons.destinationLocation.id;
        cons.receiverId = this.receiver_with_id.id;
        let mode = '';
        if (this.consignment_details.productType == 1) {
          mode = 'ATA';
        } else if (this.consignment_details.productType == 2) {
          mode = 'ATD';
        } else if (this.consignment_details.productType == 3) {
          mode = 'DTA';
        } else if (this.consignment_details.productType == 4) {
          mode = 'DTD';
        }
        if (this.consignment_details.totalConsignmentValue == null) {
          Swal.fire({
            icon: 'error',
            title: 'ERROR',
            text: 'Please Enter Consignment Value',
            timer: 5000,
          });
          this.loading = false;
          return;
        } else {
          await fetch(API_URL + '/consignment' + '?&type=' + this.PRODUCT_TYPE + '&mode=' + mode,
              {
                method: 'POST',
                body: JSON.stringify(cons),
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + TOKEN,
                },
              })
              // .then((response) => response.json())
              // .then((result) => {
                .then(async (response) => {
                  const result = await response.json();
                  
                  // Check if the response status is not OK 
                  if (!response.ok) {
                    // Throw an error with the backend error message
                    throw new Error(result.message || 'Something went wrong');
                  }
                this.consignment_with_id = result;
                this.paymentdone = true;
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Booking Done',
                  timer: 5000,
                });
                this.gotoConsignmentView(result.data[0]?.id, result.data[0]?.consignmentNo);
              })
              .catch((error) => {
                console.error('Error', error);
                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'WARNING',
                  text:error.message,
                  timer: 5000,
                });
              })
              .finally(() => {
                this.isplans = true;
                this.loading = false;
              });
            }
      }
    }
  }

  gotoConsignmentView(id: any, consignment_no: any): void {
    if (this.userDetails.customerType == 1 || this.userDetails.customerType == 2) {
      this.consignmentViewService.navigateToConsignment(id, '/consignmentTable');
    } else {
      this.viewConsignmentService.navigateToConsignment(id, '/consignment');
    }
  }

  async getIndividualList() {
    const TOKEN = this.authService.getAuthToken();
    let individualList: individuallist[];
    await fetch(API_URL + '/individual_user',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then(async (result) => {
          individualList = result.sort((a, b) => a.firstName.localeCompare(b.firstName));
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'User Not Found',

            timer: 5000,
          });
        });
    return individualList;
  }

  async getRecord() {
    const TOKEN = this.authService.getAuthToken();

    await fetch(API_URL + '/report/' + this.consignment_with_id.data[0].id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then(async (response) => {
          const filename = this.consignment_with_id.data[0].consignmentNo;
          const blob = response.blob();
          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(await blob);
          a.click();
        },
        );
  }
  async createCustomer() {
    const TOKEN = this.authService.getAuthToken();

    this.indiviudal_user.userName = this.indiviudal_user.email;
    this.indiviudal_user.userType = 5;
    // this.indiviudal_user.customer_type == 2
    if (this.customer_shipper.firstName == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company Name ',
        timer: 5000,

      });
    } else if (this.CustomerExist == true) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Customer Name Already Exists',
        timer: 5000,

      });
    } else if (this.customer_shipper.email_1 == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Email Address ',
        timer: 5000,

      });
    } else if (!(this.emailregex.test(this.indiviudal_user.email)) && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',

        text: 'Please Enter Email Address ',
        timer: 5000,

      });
    } else if (this.customer_shipper.phone_1 == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company Phone No. ',
        timer: 5000,

      });
    } else if ((this.customer_shipper.phone_1).length < 10 && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Valid Company Phone No.',
        timer: 5000,

      });
    } else if (isNaN(parseInt(this.customer_shipper.phone_1)) == true && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Valid Company Phone No.',
        timer: 5000,

      });
    } else if (this.customer_shipper.address_1 == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company Address',
        timer: 5000,

      });
    } else if (this.customer_shipper.city_1 == null && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company City',
        timer: 5000,

      });
    } else if (this.customer_shipper.zip_code == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company Zip Code',
        timer: 5000,

      });
    } else if (this.customer_shipper.office_location == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select the Billing Branch',
        timer: 5000,

      });
    }
    // else if (this.customer_shipper.gst == null && this.indiviudal_user.customerType == 2) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'ERROR',
    //     text: "Please Enter The Company GST No"
    //   });
    // }
    // else if (!(this.gstregex.test(this.customer_shipper.gst)) && this.indiviudal_user.customerType == 2) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'ERROR',
    //     text: "Please Enter Valid GST No"
    //   });
    // }
    // else if (this.customer_shipper.pan == null && this.indiviudal_user.customerType == 2) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'ERROR',
    //     text: "Please Enter The Company PAN No"
    //   });
    // }
    // else if (!(this.panregex.test(this.customer_shipper.pan)) && this.indiviudal_user.customerType == 2) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'ERROR',
    //     text: "Please Enter Valid PAN No"
    //   });
    // }


    else if (this.indiviudal_user.firstName == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s First Name ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.lastName == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Last Name ',
        timer: 5000,

      });
    } else if (this.emailExist == true) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Email Exists',
        timer: 5000,

      });
    } else if (this.indiviudal_user.email == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s email ',
        timer: 5000,

      });
    } else if (!(this.emailregex.test(this.indiviudal_user.email))) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',

        text: 'Please Enter Valid Email',
        timer: 5000,

      });
    } else if (this.phoneNoExist == true) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Phone No. Exists',
        timer: 5000,

      });
    } else if (this.indiviudal_user.phone == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Phone No. ',
        timer: 5000,

      });
    } else if (isNaN(parseInt(this.indiviudal_user.phone)) == true || this.indiviudal_user.phone.length < 10) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Valid User\'s Phone No.',
        timer: 5000,

      });
    } else if (this.indiviudal_user?.billingAddress?.address == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Address ',
        timer: 5000,

      });
    } /*else if (this.indiviudal_user.billingAddress.pincode == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select The User\'s City ',
        timer: 5000,

      });
    }*/ else if (this.indiviudal_user.billingAddress.pincode.pin_number == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Zip Code ',
        timer: 5000,

      });
    } else {
      this.loadingSecound = true;
      this.Representativeload = true;
      if (this.indiviudal_user.customerType == 2) {
        await fetch(API_URL + '/customer',
            {
              method: 'POST',
              body: JSON.stringify(this.customer_shipper),
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN,
              },
            })
            .then((response) => response.json())
            .then(async (result) => {
              this.customer_with_id = result.data[0];
              this.indiviudal_user.orgCustomerId.id = result.data[0].id;
              this.consignment_details.senderId = result.data[0].id;
              await fetch(API_URL + '/register',
                  {
                    method: 'POST',
                    body: JSON.stringify(this.indiviudal_user),
                    headers: {
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                      'Authorization': 'Bearer ' + TOKEN,
                    },
                  })
                  .then((response) => response.json())
                  .then((res) => {
                    Swal.fire({
                      icon: 'success',
                      title: 'Sender created',

                      timer: 5000,
                    });
                    this.Representativeload = false;
                    this.indiviudal_user = res.data[0];
                    this.consignment_details.senderIndividual = this.indiviudal_user.id;
                    this.customer_created = true;
                  });
            // console.log(result);
            })
            .catch((error) => {
              console.error('Error', error);
              this.Representativeload = false;
              alert(error);
            });
      } else {
        await fetch(API_URL + '/register',
            {
              method: 'POST',
              body: JSON.stringify(this.indiviudal_user),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + TOKEN,
              },
            })
            .then((response) => response.json())
            .then((result) => {
              Swal.fire({
                icon: 'success',
                title: 'Sender created',

                timer: 5000,
              });
              this.Representativeload = false;
              this.indiviudal_user = result.data[0];
              this.consignment_details.senderIndividual = this.indiviudal_user.id;
              this.customer_created = true;
              this.findbusiness();

            // console.log(result);
            })
            .catch((error) => {
              console.error('Error', error);
              this.Representativeload = false;
              alert(error);
            });
      }
      this.customerList = await this.getCustomerList();
      this.IndividualList = await this.getIndividualList();
      this.loadingSecound = false;
    }
  }


  // async createReceiver() {
  //   let TOKEN = this.authService.getAuthToken();
  //   this.loadingSecound = true;
  //   let sender_rev = {
  //     receiverId: null,
  //   }

  //   if (this.receiver.firstName == null) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Please Enter First Name',
  //
  //       timer: 5000
  //     });
  //   }
  //   else if (this.receiver.firstName.length < 1 || this.receiver.firstName.length > 60) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Fist Name should be in between 1 - 60',
  //
  //       timer: 5000
  //     });
  //   }
  //   else if (this.receiver.lastName == null) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Please Enter Last Name',
  //
  //       timer: 5000
  //     });
  //   }
  //   else if (this.receiver.lastName.length < 0 || this.receiver.lastName.length > 60) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Last Name should be in between 1 - 60',
  //
  //       timer: 5000
  //     });
  //   }
  //   else if (this.receiver.address == null) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Please Enter the Address',
  //
  //       timer: 5000
  //     });
  //   }
  //   else if (this.receiver.address.length < 2 || this.receiver.address.length > 255) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'address should be in between 1 - 255',
  //
  //       timer: 5000
  //     });
  //   }
  //   else if (this.receiver.city == null) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Please select City',
  //
  //       timer: 5000
  //     });
  //   }
  //   else if (this.receiver.zipCode == null) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Please Enter Zip code',
  //
  //       timer: 5000
  //     });
  //   }
  //   else if (this.receiver.phone1 == null) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Please Enter Phone No.',
  //
  //       timer: 5000
  //     });
  //   }
  //   else if ((this.receiver.phone1).length < 10) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       text: "Please Enter The Valid Phone No.",
  //       timer: 5000

  //     });
  //   }
  //   // else if (parseInt(this.receiver.phone1) != NaN) {
  //   //   Swal.fire({
  //   //     icon: 'error',
  //   //     title: 'Please Enter a valid Phone No.',
  //   //
  //   //     timer: 1500
  //   //   });
  //   // }
  //   else if (this.receiver.email1 == null) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Please Enter an  Email',
  //
  //       timer: 5000
  //     })
  //   }
  //   else if (!(this.emailregex.test(this.receiver.email1))) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //
  //       text: "Please Enter Valid Email",
  //       timer: 5000

  //     });
  //   }
  //   else {
  //     await fetch(API_URL + "/receiver",
  //       {
  //         method: "POST",
  //         body: JSON.stringify(this.receiver),
  //         headers: {
  //           "Content-Type": "application/json",
  //           "Authorization": "Bearer " + TOKEN
  //         }
  //       })
  //       .then((response) => response.json())
  //       .then((result) => {
  //         Swal.fire({
  //           icon: 'success',
  //           title: 'Receiver created',
  //
  //           timer: 5000
  //         });
  //         this.receiver_with_id = result.data[0];
  //         // console.log(result);
  //         this.clicked = true;
  //         sender_rev.receiverId = this.receiver_with_id.id
  //         this.consignment_details.destinationLocation = this.getAirportFromCityReceiver(this.receiver_with_id.city.ibAirport.id)
  //         // console.log("this is receiver",this.consignment_details.destinationLocation)
  //         if (sender_rev.receiverId != null || sender_rev.receiverId != undefined) {
  //           this.errorReceiver = true;
  //         }

  //       })
  //       .catch((error) => {
  //         console.error("Error", error);
  //         alert(error)
  //       });

  //   }
  //   this.loadingSecound = false;
  // }

  async updateReceiver() {
    const TOKEN = this.authService.getAuthToken();
    this.loadingSecound = true;
    const sender_rev = {
      receiverId: null,
    };
    // Validating receiver details
    if (!this.validateReceiverDetails()) {
      this.loadingSecound = false;
      return;
    }

    await fetch(API_URL + '/receiver', {
      method: 'POST',
      body: JSON.stringify(this.receiver),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
        .then((response) => response.json())
        .then((result) => {
          Swal.fire({
            icon: 'success',
            title: 'Receiver updated',

            timer: 5000,
          });
          this.receiver_with_id = result.data[0];
          this.clicked = true;
          sender_rev.receiverId = this.receiver_with_id.id;
          this.consignment_details.destinationLocation = this.getAirportFromCityReceiver(this.receiver_with_id.city.ibAirport.id);
          if (sender_rev.receiverId != null || sender_rev.receiverId != undefined) {
            this.errorReceiver = true;
          }
        })
        .catch((error) => {
          console.error('Error', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error,
            showConfirmButton: true,
          });
        });

    this.loadingSecound = false;
  }

  async createReceiver() {
    const TOKEN = this.authService.getAuthToken();
    this.loadingSecound = true;
    const sender_rev = {
      receiverId: null,
    };

    if (!this.validateReceiverDetails()) {
      this.loadingSecound = false;
      return;
    }

    await fetch(API_URL + '/receiver', {
      method: 'POST',
      body: JSON.stringify(this.receiver),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
        .then((response) => response.json())
        .then((result) => {
          Swal.fire({
            icon: 'success',
            title: 'Receiver created',

            timer: 5000,
          });
          this.receiver_with_id = result.data[0];
          this.clicked = true;
          sender_rev.receiverId = this.receiver_with_id.id;
          this.consignment_details.destinationLocation = this.getAirportFromCityReceiver(this.receiver_with_id.city.ibAirport.id);
          if (sender_rev.receiverId != null || sender_rev.receiverId != undefined) {
            this.errorReceiver = true;
          }
        })
        .catch((error) => {
          console.error('Error', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error,
            showConfirmButton: true,
          });
        });

    this.loadingSecound = false;
  }

  validateReceiverDetails(): boolean {
    if (!this.receiver.firstName) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter First Name',
        timer: 5000,
      });
      return;
    } else if (this.receiver.firstName.length < 1 || this.receiver.firstName.length > 60) {
      Swal.fire({
        icon: 'error',
        title: 'First Name should be between 1 - 60 characters',
        timer: 5000,
      });
      return;
    } else if (!this.receiver.lastName) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter Last Name',
        timer: 5000,
      });
      return;
    } else if (this.receiver.lastName.length < 1 || this.receiver.lastName.length > 60) {
      Swal.fire({
        icon: 'error',
        title: 'Last Name should be between 1 - 60 characters',
        timer: 5000,
      });
      return;
    } else if (!this.receiver.email1) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter an Email',
        timer: 5000,
      });
      return;
    } else if (!this.emailregex.test(this.receiver.email1)) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter a Valid Email',
        timer: 5000,
      });
      return;
    } else if (!this.receiver.phone1) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter Phone No.',
        timer: 5000,
      });
      return;
    } else if (this.receiver.phone1.length < 10) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter a Valid Phone No.',
        timer: 5000,
      });
      return;
    } else if (!this.receiver.address) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter the Address',
        timer: 5000,
      });
      return;
    } else if (this.receiver.address.length < 2 || this.receiver.address.length > 255) {
      Swal.fire({
        icon: 'error',
        title: 'Address should be between 2 - 255 characters',
        timer: 5000,
      });
      return;
    } else if (!this.recCon) {
      Swal.fire({
        icon: 'error',
        title: 'Please select Country',
        timer: 5000,
      });
      return;
    } else if (!this.recState) {
      Swal.fire({
        icon: 'error',
        title: 'Please select State',
        timer: 5000,
      });
      return;
    } else if (!this.receiver.city) {
      Swal.fire({
        icon: 'error',
        title: 'Please select City',
        timer: 5000,
      });
      return;
    } else if (!this.receiver.zipCode) {
      Swal.fire({
        icon: 'error',
        title: 'Please Select Zip code',
        timer: 5000,
      });
      return;
    }
    return true;
  }


  constructor(private router: Router, private http: HttpClient, private modalService: NgbModal, private route: ActivatedRoute, private consignmentTypeService: ConsignmentTypeService, private authService: AuthService, public dialog: MatDialog, private httpService: HttpService, private viewConsignmentService: ViewConsignmentService, private consignmentViewService: ConsignmentViewService) {
    this.maxDate = new Date();
  }


  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public Representativeload = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };

  closeResult: string;
  open(content5) {
    this.modalService.open(content5, {
      size: 'lg',
    });
  }
  async finduser() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/app_users/' + this.consignment_details.senderIndividual,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'User Found',

            timer: 5000,
          });

          this.indiviudal_user = result.data[0];

          if (this.indiviudal_user.customerType == 1) {
            this.val = 2;
          } else {
            this.val = 1;
            this.consignment_details.senderId = this.indiviudal_user.orgCustomerId.id;
          }
          this.customer_shipper = this.indiviudal_user.orgCustomerId.id;
          this.getAddressList(this.consignment_details.senderIndividual);
          this.consignment_details.senderIndividual = this.indiviudal_user.id;
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'User Not Found',

            timer: 5000,
          });
        });
  }

  async findbusiness() {
    const TOKEN = this.authService.getAuthToken();
    // alert("here");
    // await fetch(API_URL + "/customer/" + this.consignment_details.senderId,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Accept": "application/json",
    //       "Authorization": "Bearer " + TOKEN
    //     }
    //   })
    //   .then((response) => response.json())
    //   .then(async (result) => {
    //     Swal.fire({
    //       position: 'center',
    //       icon: 'success',
    //       title: 'User Found',
    //
    //       timer: 1500
    //     });

    //     this.customer_shipper = result.data[0];

    // this.customer_shipper= this.indiviudal_user.org_customer_id;
    this.consignment_details.senderId = this.customer_shipper.id;
    if (this.customer_shipper.id != null || this.customer_shipper.id != undefined) {
      this.errorShipper = true;
    }
    await fetch(API_URL + '/org_users/' + this.customer_shipper.id,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
            'X-Active': 'true',

          },
        })
        .then((response) => response.json())
        .then((result) => {
          this.representativeList = result.data;
          this.show = true;
        });
    // })
    // .catch((error) => {
    //   Swal.fire({
    //     position: 'center',
    //     icon: 'error',
    //     title: 'User Not Found',
    //
    //     timer: 1500
    //   });
    // });
  }

  async getCustomerList() {
    const TOKEN = this.authService.getAuthToken();
    let businessList: CustomerList[];
    await fetch(API_URL + '/totalcustomers',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then(async (result) => {
          businessList = result;
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'User Not Found',

            timer: 5000,
          });
        });
    return businessList;
  }


  async createRep() {
    const TOKEN = this.authService.getAuthToken();
    this.indiviudal_user.userName = this.indiviudal_user.email;
    this.indiviudal_user.orgCustomerId.id = this.consignment_details.senderId;
    if (this.indiviudal_user.firstName == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s First Name ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.lastName == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Last Name ',
        timer: 5000,

      });
    } else if (this.emailExist == true) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Email Alredy Exists ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.email == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s email ',
        timer: 5000,

      });
    } else if (!(this.emailregex.test(this.indiviudal_user.email))) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',

        text: 'Please Enter Valid Email',
        timer: 5000,

      });
    } else if (this.phoneNoExist == true) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Phone No. ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.phone == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Phone No. ',
        timer: 5000,

      });
    } else if (isNaN(parseInt(this.indiviudal_user.phone)) == true || this.indiviudal_user.phone.length < 10) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Valid User\'s Phone No.',
        timer: 5000,

      });
    } else if (this.indiviudal_user.billingAddress.address == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Address ',
        timer: 5000,

      });
    } else if (this.user_sel_city == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select The User\'s City ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.billingAddress.pincode?.pin_number == '' || this.indiviudal_user.billingAddress.pincode?.pin_number == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select The User\'s Zip Code ',
        timer: 5000,

      });
    } else {
      this.Representativeload = true;
      await fetch(API_URL + '/register',
          {
            method: 'POST',
            body: JSON.stringify(this.indiviudal_user),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + TOKEN,
            },
          })
          .then((response) => response.json())
          .then((result) => {
            Swal.fire({
              icon: 'success',
              title: 'Sender created',

              timer: 5000,
            });
            this.Representativeload = false;
            this.errorRepresentative = true;
            this.indiviudal_user = result.data[0];
            this.consignment_details.senderIndividual = this.indiviudal_user.id;
            this.customer_created = true;
            this.findbusiness();

          // console.log(result);
          });
    }
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  async getBusinessStates() {
    this.business_states = getStatesById(this.business_sel_country);
    this.business_sel_state = null;
    this.customer_shipper.city_1 = null;
  }

  async getBusinessCities() {
    this.business_cities = getCitiesById(this.business_sel_state);
    this.business_sel_pincodes = getPincodesById(this.customer_shipper.city_1);
    this.customer_shipper.city_1 = null;
  }
  async getBusinessPincodes() {
    this.business_pincodes = getPincodesById(this.customer_shipper.city_1);
    this.customer_shipper.zip_code = null;
  }

  recState = null;
  recCon = null;

  async getRecverStates() {
    this.states = getStatesById(this.recCon);
    this.recState = null;
    this.receiver.city = null;
    this.receiver.zipCode = '';
  }

  async getReceverCities() {
    this.cities = getCitiesById(this.recState);
    this.receiver.city = null;
    this.receiver.zipCode = '';
  }
  async getReceverPincodes() {
    this.pincodes = getPincodesById(this.receiver.city);
    this.receiver.zipCode = '';
  }

  async getIndividaulStates() {
    this.user_states = getStatesById(this.user_sel_country);
    this.user_sel_state = null;
    this.user_sel_city = null;
  }

  async getIndividaulCities() {
    this.user_cities = getCitiesById(this.user_sel_state);
    this.user_sel_pincodes = null;
    this.user_sel_city = null;
  }
  async getIndividualPincodes() {
    // this.user_pincodes = getPincodesById(this.indiviudal_user.city);
    this.indiviudal_user.billingAddress.pincode.pin_number = null;
  }

  async selectRep(id) {
    const TOKEN = this.authService.getAuthToken();
    this.consignment_details.senderIndividual = id;
    this.errorRepresentative = true;
    // console.log("this is selected" + id);
    await fetch(API_URL + '/representativeUser/' + this.consignment_details.senderIndividual,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Representative selected',
            timer: 5000,
          });

          this.indiviudal_user = result.data[0];

          this.getAddressList(id);
          this.customer_shipper = result.data[0].orgCustomerId;
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'User Not Found',

            timer: 5000,
          });
        });
  }
  async nameExist() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/customer/name/' + this.customer_shipper.firstName,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
        // console.log("User Exists");
          if (result.data.length == 0) {
            this.CustomerExist = false;
          } else {
            this.CustomerExist = true;
          }
        // alert("CustomerExist"+this.CustomerExist);
        })
        .catch((error) => {
          console.error('error', error);
        });
  }


  async emailAppuser() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/appuser/email/' + this.indiviudal_user.email,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => response.json())
        .then((result) => {
        // console.log("User Exists");
          if (result.data[0] == null) {
            this.emailExist = false;
          } else {
            this.emailExist = true;
          }
        // alert("CustomerExist "+this.emailExist);
        })
        .catch((error) => {
          console.error('error', error);
        });
  }

  async phoneNoExistAppuser() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/appuser/phone/' + this.indiviudal_user.phone,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => response.json())
        .then((result) => {
        // console.log("User Exists");
          if (result.data[0] == null) {
            this.phoneNoExist = false;
          } else {
            this.phoneNoExist = true;
          }
        // alert("CustomerExist "+this.phoneNoExist);
        })
        .catch((error) => {
          console.error('error', error);
        });
  }

  async consignmentNoExists() {
    const TOKEN = this.authService.getAuthToken();
    if (this.consignment_details.consignmentNo) {
      await fetch(API_URL + '/consignments/' + this.consignment_details.consignmentNo, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
      // Converting received data to JSON
          .then((response) => response.json())
          .then((result) => {
          // console.log("User Exists");
            if (result.data[0] == null) {
              this.consignmentNoExist = false;
            } else {
              this.consignmentNoExist = true;
            }
          })
          .catch((error) => {
            console.error('error', error);
          });
    }
  }


  async getAddressList(id) {
    const TOKEN = this.authService.getAuthToken();
    let addressList;
    if (id != null) {
      await fetch(API_URL + '/address/' + id,
          {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + TOKEN,
            },
          })
          .then((response) => response.json())
          .then(async (result) => {
            addressList = result.data;
          })
          .catch((error) => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'User Not Found',
              timer: 5000,
            });
          });
    }
    this.reprsentativeAddress = addressList;
  }

  selectAddress(id) {
    this.selectedAddress = id;
    const add = this.reprsentativeAddress.filter((option) => option.id == id);

    this.consignment_details.originLocation = this.getAirportFromCity(add[0].pincodeId?.cityId.obAirport.id);
    if (this.selectAddress != null || this.selectAddress != undefined) {
      this.erroraddress = true;
    }
  }


  consignmentDetailsForm() {
    this.consignment_details = {
      id: null,
      consignmentType: null,
      weightUnit: 1,
      currency: 1,
      weightDivisor: 6000,
      consignmentNo: null,
      airlinesId: null,
      isStopover: null,
      totalTransitTime: null,
      senderIndividual: null,
      isIndividualCustomer: null,
      paymentCompletedAt: null,
      isXrayScreening: null,
      isXrayCertification: null,
      isDryIce: null,
      totalWeight: null,
      totalChargableWeight: null,
      planSelected: null,
      senderId: null,
      pickupAddress: null,
      receiverId: null,
      isDocumentUploaded: null,
      isInsured: false,
      totalShippingCost: null,
      paymentMode: null,
      isPaymentCompleted: null,
      isActive: null,
      createdAt: null,
      updatedAt: null,
      createdBy: null,
      updatedBy: null,
      totalNoOfPackages: null,
      destinationLocation: null,
      consignmentSubType: null,
      originLocation: null,
      dimensionuUnit: null,
      insuranceCoverage: 0,
      totalConsignmentValue: 0,
      shippingCost: 0,
      invoiceCreatedDate: '',
      invoiceReferenceNo: null,
      productType: null,
      remarks: null,
      commodityType: null,
    };
    this.receiver = {
      id: null,
      firstName: null,
      lastName: null,
      address: null,
      city: null,
      zipCode: null,
      phone1: null,
      email1: null,
      companyName: '',
    };

    this.packages = [
      {
        id: 1,
        length: null,
        breadth: null,
        height: null,
        weight: null,
        total_weight: null,
        packageNo: null,
        volume_weight: null,
        chargeable_weight: null,
        totalvolumeweight_per_pack: null,
      },
    ];
  }

  resetform() {
    this.indiviudal_user = {
      id: null,
      firstName: '',
      middleName: '',
      lastName: '',
      avatarUrl: '',
      email: '',
      emailForMasterGST: '',
      phone: '',
      whatsapp: '',
      designation: null,
      employmentNo: null,
      billingAddress: {
        pincode: {
          pin_number: ''
        },
        address: ''
      },
      isSuperUser: null,
      userName: '',
      passwordHash: null,
      lastLoggedInAt: null,
      lastLoggedInIp: null,
      userType: 5,
      customerType: 2, // here 2 is for buisness user
      orgCustomerId: null,
      aadhaarNumber: null,
      drivingLicense: null,
      gstNumber: null,
      panNumber: null,
      gstDetails: null,
      panDetails: null,
      aadhaarDetails: null,
      isActive: true,
      createdAt: null,
      updatedAt: null,
      createdBy: null,
      updatedBy: null,
    }
    this.user_sel_country = null;
    this.user_sel_state = null;
    this.errorRepresentative = false;
    this.change_business();
  }

  createnewformreset() {
    this.indiviudal_user = {
      id: null,
      firstName: '',
      middleName: '',
      lastName: '',
      avatarUrl: '',
      email: '',
      emailForMasterGST: '',
      phone: '',
      whatsapp: '',
      designation: null,
      employmentNo: null,
      billingAddress: {
        pincode: {
          pin_number: ''
        },
        address: ''
      },
      isSuperUser: null,
      userName: '',
      passwordHash: null,
      lastLoggedInAt: null,
      lastLoggedInIp: null,
      userType: 5,
      customerType: 2, // here 2 is for buisness user
      orgCustomerId: null,
      aadhaarNumber: null,
      drivingLicense: null,
      gstNumber: null,
      panNumber: null,
      gstDetails: null,
      panDetails: null,
      aadhaarDetails: null,
      isActive: true,
      createdAt: null,
      updatedAt: null,
      createdBy: null,
      updatedBy: null,
    };
    this.customer_shipper = {
      id: null,
      firstName: '',
      address_1: '',
      city_1: '',
      creditPeriod: '',
      credit_value: null,
      email_1: '',
      zip_code: '',
      isCreditExtended: null,
      isKycApproved: null,
      kycApprovedAt: null,
      office_location: null,
      lastName: '',
      phone_1: '',
      customerTypeId: 2,
      kycApprovedBy: null,
      isBusinessAccount: true,
      pan: '',
      gst: '',
      Id_type: null,
      Id_value: '',
      otp_varified: null,
      isSezCustomer: false,
    };
    this.business_sel_country = null;
    this.business_sel_state = null;
    this.user_sel_country = null;
    this.user_sel_state = null;
    this.customer_created = false;
    this.errorShipper = false;
    this.change_business();
  }

  refresh = false;
  individualrefresh() {
    this.refresh = true;
  }
  corporefresh() {
    this.refresh = false;
  }


  myFunction() {
    this.createnewformreset();
    this.resetform();
    this.consignmentDetailsForm();
    this.reprsentativeAddress = null;
    this.isplans = false;
    this.countries = null;
    this.recCon = '';
    this.getRecverStates();
    this.getBusinessStates();
    this.getIndividaulStates();
    // this.states = null;
    this.ngOnInit();
    this.totalvolume_weight = 0;
  }

  OfficesList
  async getOfficeLocations() {
    try {
      const TOKEN = this.authService.getAuthToken();
      const response = await fetch(API_URL + '/office_locations', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      });
      if (response.ok) {
        const json = await response.json();
        this.OfficesList = json.data;
      } else {
      }
    } catch (error) {
      console.error('Error fetching office locations:', error);
    }
  }

  clearData() {
    this.customer_shipper = {
      id: null,
      firstName: '',
      address_1: '',
      city_1: '',
      creditPeriod: '',
      credit_value: null,
      email_1: '',
      zip_code: '',
      isCreditExtended: null,
      isKycApproved: null,
      kycApprovedAt: null,
      office_location: null,
      lastName: '',
      phone_1: '',
      customerTypeId: 2,
      kycApprovedBy: null,
      isBusinessAccount: true,
      pan: '',
      gst: '',
      Id_type: null,
      Id_value: '',
      otp_varified: null,
      isSezCustomer: false,
    };
  }

  validateInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^(\d*\.?\d*)$/.test(inputChar)) {
      event.preventDefault();
    }
  }
  validateInput1(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^\d*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(CreateNewCustomerComponent, {
      data: {val: this.val},
    });
  }
  gotoCreateNewCustomerPage() {
    this.openDialog();
  }

  openDialogForRepresentative() {
    const dialogRef = this.dialog.open(CreateNewRepresentativeComponent, {
      data: {customer: this.consignment_details.senderId},
    });
  }

  gotoCreateNewRepresentativePage() {
    this.openDialogForRepresentative();
  }

  commodityTypes: any[] = [];
  async getCommodityTypes() {
    const TOKEN = this.authService.getAuthToken();
    try {
      const response: any = await this.httpService.get(API_URL + '/commodityType', null, null).toPromise();
      return response.data;
    } catch (error) {
      console.error('Error fetching commodity types:', error);
      return [];
    }
  }

  getPinCodeByPinNumber(pincode){
    const pincodeData = this.httpService.get(API_URL + "/pincode/" + pincode, null, null);  // pincode means pin_number

  }
}

