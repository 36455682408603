import {PageEvent} from '@angular/material/paginator';
import {API_URL} from '../../../../../Global_API_URL';
import {getAirlines, getAirports,getAirportsSortOrder} from '../../getAirportAirlines.js';
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {getConsignmentType, getConsignmentSubType, getSubTypeByConsignemntType, getOrderType} from '../../getConsignmentType.js';
import Swal from 'sweetalert2';
import {Airlines, Airport, ConsignmentType, ConsignmentSubtypes, OrderType} from '../../golbalInterfaces';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {ConfirmDialog} from '../../Components/confirmDialog/confirmDialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '../../../auth.service';
import {ConsignmentTypesModel} from '../../../modules/consignmentTypes/models/consignment-types-model';
import {ConsignmentSubTypesModel} from '../../../modules/consignmentSubtypes/models/consignment-sub-types-model';
import {ConsignmentTypeService} from '../../../modules/consignmentTypes/services/consignment-type.service';
import {ProductTypeModel} from '../../../modules/productTypes/models/product-type-model';
import {ProductTypeService} from '../../../modules/productTypes/services/product-type.service';
import {Router, NavigationEnd} from '@angular/router';

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#d92550';
const SecondaryBlue = '#3f6ad8';
const FILTERS_STORAGE_KEY = 'bulk_upload_filters';
const PAGINATION_STORAGE_KEY = 'bulk_upload_pagination';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.sass'],
})
export class BulkUploadComponent implements OnInit {
  airline;
  csvfile;
  airlinesList = [];
  pageview = 'table';
  filterParams: string = '';
  origin1 = getAirportsSortOrder();
  destination1 = getAirportsSortOrder();
  airlines1 = getAirlines();
  airlines3 = getAirlines();
  consignmentType1 = getConsignmentType();
  consignmentType2 = getConsignmentType();
  consignmentSubType2 = getConsignmentSubType();
  productyType = getOrderType();
  consignmentTypes: ConsignmentTypesModel[];
  consignmentSubTypes: ConsignmentSubTypesModel[];
  airportssearch: Airport[] = getAirports();
  airportssearch2: Airport[] = getAirports();
  airports = getAirports();

  pagination: any = {
    pageNo: 0,
    pageSize: 10,
  };

  airprices = {
    id: null,
    airline: null,
    origin: null,
    destination: null,
    consignmentType: null,
    applicable_flights: null,
    applicable_commodity: null,
    airlineTariffMin: null,
    airlineTariffNRate: null,
    airlineTariffAbove45: null,
    airlineTariffAbove100: null,
    airlineTariffAbove300: null,
    airlineTariffAbove500: null,
    outboundTspPerkg: null,
    outboundTspMin: null,
    outboundCashTspPerkg: null,
    outboundCashTspMin: null,
    outboundUnitPerkg: null,
    outboundUnitMin: null,
    outboundHandlingChargesPerkg: null,
    outboundHandlingChargesMin: null,
    outboundXrayScreeningPerkg: null,
    outboundXrayScreeningMin: null,
    outboundXrayCertificationPerkg: null,
    outboundXrayCertificationMin: null,
    outboundOriginSccPerkg: null,
    outboundOriginSccMin: null,
    outboundTotalAhc: null,
    inboundTspPerkg: null,
    inboundTspMin: null,
    inboundCashTspPerkg: null,
    inboundCashTspMin: null,
    inboundHandlingChargesPerkg: null,
    inboundHandlingChargesMin: null,
    inboundUnitPerkg: null,
    inboundUnitMin: null,
    inboundTotalAhc: null,
    otherChargesSercurityPerkg: null,
    otherChargesSectorSurchargePerkg: null,
    otherChargesFlighSurchargePerkg: null,
    mawbStatDa: null,
    mawbDo: null,
    mawbMiscPerkg: null,
    mawbMiscFixed: null,
    mawbOtherCharge: null,
    iclscmHandlingPerkg: null,
    iclscmHandlingMin: null,
    freightAttachedConsignmentPerkg: null,
    freightAttachedConsignmentMin: null,
    humanRemainsPerkg: null,
    humanRemainsMin: null,
    livestockPerkg: null,
    livestockMin: null,
    livestockPkgCharge: null,
    shirmpsSeaFoodPerkg: null,
    shirmpsSeaFoodMin: null,
    perishableChargePerkg: null,
    perishableChargeMin: null,
    dryiceFixed: null,
    heavyCargoPerkg: null,
    heavyCargoMin: null,
    valuveCargoPerkg: null,
    valuveCargeMin: null,
    shipmentValuePerkg: null,
    shipmentValueMin: null,
    demuragePerkg: null,
    demurageMin: null,
    coldChainPerkg: null,
    coldChainMin: null,
    doorPickupPerkg: null,
    doorPickupMin: null,
    doorPickupOpa: null,
    doorDeliveryPerkg: null,
    doorDeliveryMin: null,
    doorDeliveryOda: null,
    dangerousGoodsPerkg: null,
    dangerousGoodsMin: null,
    specialCargoPerkg: null,
    specialCargoMin: null,
    cansoulChargesPerkg: null,
    cansoulChargesMin: null,
    airlineSurchargePerkg: null,
    airlineSurchargeMin: null,
    additionalSurchargePerkg: null,
    netRate100kgSlab: null,
    discountMin: null,
    discountPerkg: null,
    // is_active:null
  }

  addairprices = {
    id: null,
    airline: null,
    origin: null,
    destination: null,
    productType: null,
    consignmentType: null,
    consignmentSubtype: null,
    applicable_flights: 0,
    applicable_commodity: 0,
    airlineTariffMin: 0,
    airlineTariffNRate: 0,
    airlineTariffAbove45: 0,
    airlineTariffAbove100: 0,
    airlineTariffAbove300: 0,
    airlineTariffAbove500: 0,
    outboundTspPerkg: 0,
    outboundTspMin: 0,
    outboundCashTspPerkg: 0,
    outboundCashTspMin: 0,
    outboundUnitPerkg: 0,
    outboundUnitMin: 0,
    outboundHandlingChargesPerkg: 0,
    outboundHandlingChargesMin: 0,
    outboundXrayScreeningPerkg: 0,
    outboundXrayScreeningMin: 0,
    outboundXrayCertificationPerkg: 0,
    outboundXrayCertificationMin: 0,
    outboundOriginSccPerkg: 0,
    outboundOriginSccMin: 0,
    outboundTotalAhc: 0,
    inboundTspPerkg: 0,
    inboundTspMin: 0,
    inboundCashTspPerkg: 0,
    inboundCashTspMin: 0,
    inboundHandlingChargesPerkg: 0,
    inboundHandlingChargesMin: 0,
    inboundUnitPerkg: 0,
    inboundUnitMin: 0,
    inboundTotalAhc: 0,
    otherChargesSercurityPerkg: 0,
    otherChargesSectorSurchargePerkg: 0,
    otherChargesFlighSurchargePerkg: 0,
    mawbStatDa: 0,
    mawbDo: 0,
    mawbMiscPerkg: 0,
    mawbMiscFixed: 0,
    mawbOtherCharge: 0,
    iclscmHandlingPerkg: 0,
    iclscmHandlingMin: 0,
    freightAttachedConsignmentPerkg: 0,
    freightAttachedConsignmentMin: 0,
    humanRemainsPerkg: 0,
    humanRemainsMin: 0,
    livestockPerkg: 0,
    livestockMin: 0,
    livestockPkgCharge: 0,
    shirmpsSeaFoodPerkg: 0,
    shirmpsSeaFoodMin: 0,
    perishableChargePerkg: 0,
    perishableChargeMin: 0,
    dryiceFixed: 0,
    heavyCargoPerkg: 0,
    heavyCargoMin: 0,
    valuveCargoPerkg: 0,
    valuveCargeMin: 0,
    shipmentValuePerkg: 0,
    shipmentValueMin: 0,
    demuragePerkg: 0,
    demurageMin: 0,
    coldChainPerkg: 0,
    coldChainMin: 0,
    doorPickupPerkg: 0,
    doorPickupMin: 0,
    doorPickupOpa: 0,
    doorDeliveryPerkg: 0,
    doorDeliveryMin: 0,
    doorDeliveryOda: 0,
    dangerousGoodsPerkg: 0,
    dangerousGoodsMin: 0,
    specialCargoPerkg: 0,
    specialCargoMin: 0,
    cansoulChargesPerkg: 0,
    cansoulChargesMin: 0,
    airlineSurchargePerkg: 0,
    airlineSurchargeMin: 0,
    additionalSurchargePerkg: 0,
    netRate100kgSlab: 0,
    discountMin: 0,
    discountPerkg: 0,
    // is_active:null
  }

  Airpicinglist = null;
  constructor(private matDialog: MatDialog, private authService: AuthService, private consignmentTypeService: ConsignmentTypeService, private productTypeService: ProductTypeService, private router: Router) {
    this.authService.getAuthToken();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Load saved filters whenever navigation ends
        this.loadSavedFilters();
      }
    });
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem(FILTERS_STORAGE_KEY);
      localStorage.removeItem(PAGINATION_STORAGE_KEY);
    });
  }

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  productTypeList: ProductTypeModel[];
  selectedProductType: ProductTypeModel;
  public Loading = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };
  userDetails;
  displaytoAccountant=false;
  async ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name=='Accountant') {
      this.displaytoAccountant=true;
    }
    this.airlinesList = await getAirlines();
    await this.getAirpricingList1();
    this.Airpicinglist = this.getAirpricingList1();
    this.airports = getAirports();
    this.airlines3 = getAirlines();
    this.consignmentType1 = await getConsignmentType();
    this.origin1 = await getAirportsSortOrder();
    this.destination1 = await getAirportsSortOrder();
    this.airlines1 = await getAirlines();
    this.airportssearch = await getAirports();
    this.airportssearch2 = await getAirports();
    this.filteredAirports = this.myControl.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter(name) : this.airportssearch.slice())),
    );
    this.filteredAirports2 = this.myControl2.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter2(name) : this.airportssearch2.slice())),
    );

    this.productTypeService.getProductTypes().subscribe((result) => {
      this.productTypeList = result.data;
    });

    this.loadSavedFilters();
  }

  selectProductType() {
    this.consignmentTypeService.getConsignmentTypeByProductType(this.selectedProductType.name).subscribe((result) => {
      this.consignmentTypes = result.data;
      this.addairprices.productType = this.selectedProductType.id;
    });
  }

  selectConsignmentType(consignmentType: ConsignmentTypesModel) {
    // this.consignment_details.consignmentSubType = null;
    this.consignmentSubTypes = consignmentType.consignment_sub_types_list;
  }

  getFiles(event) {
    this.csvfile = event.target.files[0];
  }

  viewchange(val, con) {
    this.Airpicinglist.content.id = con.id;
    if (this.Airpicinglist.content.id != 0) {
      this.getPricingById();
    }
    this.airprices = con;
    this.pageview = val;
  }

  viewchange1(val) {
    this.getAirpricingList1();
    this.pageview = val;
  }

  viewchanges(val) {
    this.pageview = val;
  }

  viewchange2(val, users) {
    // console.log(users,"users")
    this.getAirpricingList1();
    this.airprices = users;
    // console.log("this is user details"+this.airprices)
    this.pageview = val;
  }

  goToAirlinePricingViewPage(id){
    this.router.navigate(['/airlinePricingView',id])
  }

  async uploadDocument() {
    const TOKEN = this.authService.getAuthToken();
    if (this.csvfile == null) {
      Swal.fire({
        title: 'File Missing',
        text: 'Please upload a file.',
        icon: 'error',
      });
    } else {
      this.Loading = true;
      const head: any = {
        'Authorization': 'Bearer ' + TOKEN,
        'Accept': 'application/json',
      };
      const formdata = new FormData();
      formdata.append('file', this.csvfile);
      formdata.append('airlineID', this.airline);
      // console.log("file data --- "+formdata)

      await fetch(API_URL + '/bulkUpload/upload',
          {
            method: 'POST',
            body: formdata,
            headers: head,
          })
          .then((response) => {
          // let msg = response.statusText;
            this.Loading = false;
            if (response.status == 200) {
              Swal.fire({
                title: 'Success',
                text: 'File uploaded successfully',
                icon: 'success',
              });
            } else {
              const data = response.json();
              Swal.fire({
                title: 'Error',
                text: 'File Upload Failed',
                icon: 'error',
              });
            }
            return response.json();
          }).then((response) => {
            if (response.status == 'failed') {
              Swal.fire({
                title: 'Error',
                text: response.message,
                icon: 'error',
              });
            }
          });
    }
  }

  applySavedFilters(savedFilters: any) {
    // console.log('Applying saved filters:', savedFilters);
    this.originsearch = savedFilters.originsearch;
    this.Destinationsearch = savedFilters.Destinationsearch;
    this.Airlinesearch = savedFilters.Airlinesearch;
    this.ProductSearch = savedFilters.ProductSearch;
    this.ConsignmentTypeSearch = savedFilters.ConsignmentTypeSearch;
    this.ConsignmentSubtypeSearch = savedFilters.ConsignmentSubtypeSearch;
  }

  saveFiltersAndPaginationToStorage() {
    const filters = {
      originsearch: this.originsearch,
      Destinationsearch: this.Destinationsearch,
      Airlinesearch: this.Airlinesearch,
      ProductSearch: this.ProductSearch,
      ConsignmentTypeSearch: this.ConsignmentTypeSearch,
      ConsignmentSubtypeSearch: this.ConsignmentSubtypeSearch,
    };
    localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(filters));
    localStorage.setItem(PAGINATION_STORAGE_KEY, JSON.stringify(this.pagination));
  }

  loadSavedFilters() {
    const savedFilters = JSON.parse(localStorage.getItem(FILTERS_STORAGE_KEY));
    const savedPagination = JSON.parse(localStorage.getItem(PAGINATION_STORAGE_KEY));
    // console.log('Saved Filters:', savedFilters);
    // console.log('Saved Pagination:', savedPagination);
    if (savedFilters) {
      this.applySavedFilters(savedFilters);
    }
    if (savedPagination) {
      this.pagination = savedPagination;
    }
  }

  async reset() {
    // console.log('Resetting...');
    this.originsearch = '';
    this.Destinationsearch = '';
    this.Airlinesearch = '';
    this.ProductSearch = '';
    this.ConsignmentTypeSearch = '';
    this.ConsignmentSubtypeSearch = '';
    this.pagination = {pageNo: 0, pageSize: 10};
    this.filterParams = '';
    await this.getAirpricingList1();
    this.saveFiltersAndPaginationToStorage();
    // console.log('Reset completed.');
  }

  airport1
  airport2
  airlines2
  filteredAirports: Observable<Airport[]>
  filteredAirports2: Observable<Airport[]>

  private _filter(name: String): Airport[] {
    const filterValue = name.toLowerCase();
    return this.airportssearch.filter((option) => option.city.name.toLowerCase().includes(filterValue));
  }

  private _filter2(name: String): Airport[] {
    const filterValue2 = name.toLowerCase();
    return this.airportssearch2.filter((option) => option.city.name.toLowerCase().includes(filterValue2));
  }

  displayFn(airport1: Airport): String {
    return airport1 && airport1?.city?.name ? airport1.city.name : '';
  }

  displayFn1(airport2: Airport): String {
    return airport2 && airport2?.city?.name ? airport2.city.name : '';
  }

  displayFn2(airlines2: Airlines): String {
    return airlines2?.name ? airlines2.name : '';
  }

  displayFn3(consignmentType: ConsignmentType): String {
    return consignmentType?.name ? consignmentType.name : '';
  }

  displayFn4(consignmentSubType: ConsignmentSubtypes): String {
    return consignmentSubType?.name ? consignmentSubType.name : '';
  }

  displayFn5(productType: OrderType): String {
    return productType?.name ? productType.name : '';
  }

  pageSizeOptions = [10];

  tablefilter() {
    this.pagination.pageNo = 0;
    this.getAirpricingList1();
  }

  myControl = new FormControl();
  myControl2 = new FormControl();

  originsearch: any = '';
  Destinationsearch: any = '';
  Airlinesearch: any = '';
  ProductSearch: any = '';
  ConsignmentTypeSearch: any = '';
  ConsignmentSubtypeSearch: any = '';

  filter() {
    this.filterParams = '?';

    if (this.originsearch != '') {
      this.filterParams = this.filterParams + 'origin=' + this.originsearch.id + '&';
    }
    if (this.Destinationsearch != '') {
      this.filterParams = this.filterParams + 'destination=' + this.Destinationsearch.id + '&';
    }
    if (this.Airlinesearch != '') {
      this.filterParams = this.filterParams + 'airline=' + this.Airlinesearch.id + '&';
    }
    if (this.ProductSearch != '') {
      this.filterParams = this.filterParams + 'productTypeId=' + this.ProductSearch.id + '&';
    }
    if (this.ConsignmentTypeSearch != '') {
      this.filterParams = this.filterParams + 'consignmentType=' + this.ConsignmentTypeSearch.id + '&';
    }
    if (this.ConsignmentSubtypeSearch != '') {
      this.filterParams = this.filterParams + 'applicableCommodity=' + this.ConsignmentSubtypeSearch.id + '&';
    }
    this.filterParams = this.filterParams + 'pageNo=' + this.pagination.pageNo + '&pageSize=' + this.pagination.pageSize;
    return this.filterParams;
  }

  handlePageEvent(event: PageEvent) {
    this.pagination.pageNo = event.pageIndex;
    this.pagination.pageSize = event.pageSize;
    this.getAirpricingList1(); // Make sure to fetch data after updating pagination
  }

  // async getAirpricingList1() {
  //   console.log('Fetching air pricing list...');
  //   this.Loading = true;
  //   let TOKEN = this.authService.getAuthToken();
  //   let api = "/airlinePricingPage"
  //   let params = this.filter()
  //   try {
  //     const response = await fetch(API_URL + api + params, {
  //       method: "GET",
  //       headers: {
  //         "Accept": "application/json",
  //         "Content-Type": "application/json",
  //         "Authorization": "Bearer " + TOKEN
  //       }
  //     });
  //     if (response.ok) {
  //       const json = await response.json();
  //       this.Airpicinglist = json;
  //       console.log('Air pricing list fetched:', this.Airpicinglist);
  //     } else {
  //       console.error('Failed to fetch data:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     this.Loading = false;
  //   }
  // }

  async getAirpricingList1() {
    // console.log('Fetching air pricing list...');
    this.Loading = true;
    const TOKEN = this.authService.getAuthToken();
    const api = '/airlinePricingPage';
    const params = this.filter(); // Get filter parameters
    try {
      const response = await fetch(API_URL + api + params, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      });
      if (response.ok) {
        const json = await response.json();
        this.Airpicinglist = json;
        // console.log('Air pricing list fetched:', this.Airpicinglist);
        this.saveFiltersAndPaginationToStorage(); // Save filters and pagination to local storage
      } else {
        console.error('Failed to fetch data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      this.Loading = false;
    }
  }

  async getPricingById() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/pricing/' + this.Airpicinglist.content.id,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((json) => {
          this.Airpicinglist = json;
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error',
            showConfirmButton: false,
            timer: 5000,
          });
        });
  }

  // async Deletedata(id) {
  //   let TOKEN = this.authService.getAuthToken();
  //   // console.log(id)
  //   var data = JSON.stringify({
  //     "is_active": 0
  //   });
  //   await fetch(API_URL + "/airline_pricing_inactive/" + id,
  //     {
  //       method: "PATCH",
  //       headers: {
  //         "Accept": "application/json",
  //         "Content-Type": "application/json",
  //         "Authorization": "Bearer " + TOKEN
  //       },
  //       body: data
  //     })

  //     // Converting received data to JSON
  //     .then(response => response.text())
  //   // .then(result => console.log(result))
  //   this.getAirpricingList1()
  //     .catch(error => console.error('error', error));
  // }

  async Deletedata(id) {
    const TOKEN = this.authService.getAuthToken();
    const data = JSON.stringify({
      'is_active': 0,
    });
    try {
      await fetch(API_URL + '/airline_pricing_inactive/' + id, {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: data,
      });
      this.getAirpricingList1(); // Refresh data after deletion
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  }

  displayedColumns = ['Origin', 'Destination', 'Airline', 'Email', 'ConsignmentType', 'Actions']

  async Restoredata(id) {
    const TOKEN = this.authService.getAuthToken();
    const data = JSON.stringify({
      'is_active': 1,
    });
    await fetch(API_URL + '/updateairline_pricing/' + id,
        {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
          body: data,
        })
        .then((response) => response.text());
    this.Airpicinglist()
        .catch((error) => console.error('error', error));
  }

  async editairpricing() {
    const TOKEN = this.authService.getAuthToken();
    const users = {
      'id': this.airprices.id,
      'airline': this.airprices.airline.id,
      'origin': this.airprices.origin.id,
      'destination': this.airprices.destination.id,
      'consignment_type': this.airprices.consignmentType.id,
      'applicable_flights': this.airprices.applicable_flights,
      'applicable_commodity': this.airprices.applicable_commodity,
      'airline_tariff_min': this.airprices.airlineTariffMin,
      'airline_tariff_n_rate': this.airprices.airlineTariffNRate,
      'airline_tariff_above_45': this.airprices.airlineTariffAbove45,
      'airline_tariff_above_100': this.airprices.airlineTariffAbove100,
      'airline_tariff_above_300': this.airprices.airlineTariffAbove300,
      'airline_tariff_above_500': this.airprices.airlineTariffAbove500,
      'outbound_tsp_perkg': this.airprices.outboundTspPerkg,
      'outbound_tsp_min': this.airprices.outboundTspMin,
      'outbound_cash_tsp_perkg': this.airprices.outboundCashTspPerkg,
      'outbound_cash_tsp_min': this.airprices.outboundCashTspMin,
      'outbound_unit_perkg': this.airprices.outboundUnitPerkg,
      'outbound_unit_min': this.airprices.outboundUnitMin,
      'outbound_handling_charges_perkg': this.airprices.outboundHandlingChargesPerkg,
      'outbound_handling_charges_min': this.airprices.outboundHandlingChargesMin,
      'outbound_xray_screening_perkg': this.airprices.outboundXrayScreeningPerkg,
      'outbound_xray_screening_min': this.airprices.outboundXrayScreeningMin,
      'outbound_xray_certification_perkg': this.airprices.outboundXrayCertificationPerkg,
      'outbound_xray_certification_min': this.airprices.outboundXrayCertificationMin,
      'outbound_origin_scc_perkg': this.airprices.outboundOriginSccPerkg,
      'outbound_origin_scc_min': this.airprices.outboundOriginSccMin,
      'outbound_total_ahc': this.airprices.outboundTotalAhc,
      'inbound_tsp_perkg': this.airprices.inboundTspPerkg,
      'inbound_tsp_min': this.airprices.inboundTspMin,
      'inbound_cash_tsp_perkg': this.airprices.inboundCashTspPerkg,
      'inbound_cash_tsp_min': this.airprices.inboundCashTspMin,
      'inbound_handling_charges_perkg': this.airprices.inboundHandlingChargesPerkg,
      'inbound_handling_charges_min': this.airprices.inboundHandlingChargesMin,
      'inbound_unit_perkg': this.airprices.inboundUnitPerkg,
      'inbound_unit_min': this.airprices.inboundUnitMin,
      'inbound_total_ahc': this.airprices.inboundTotalAhc,
      'other_charges_sercurity_perkg': this.airprices.otherChargesSercurityPerkg,
      'other_charges_sector_surcharge_perkg': this.airprices.otherChargesSectorSurchargePerkg,
      'other_charges_fligh_surcharge_perkg': this.airprices.otherChargesFlighSurchargePerkg,
      'mawb_stat_da': this.airprices.mawbStatDa,
      'mawb_do': this.airprices.mawbDo,
      'mawb_misc_perkg': this.airprices.mawbMiscPerkg,
      'mawb_misc_fixed': this.airprices.mawbMiscFixed,
      'mawb_other_charge': this.airprices.mawbOtherCharge,
      'iclscm_handling_perkg': this.airprices.iclscmHandlingPerkg,
      'iclscm_handling_min': this.airprices.iclscmHandlingMin,
      'freight_attached_consignment_perkg': this.airprices.freightAttachedConsignmentPerkg,
      'freight_attached_consignment_min': this.airprices.freightAttachedConsignmentMin,
      'human_remains_perkg': this.airprices.humanRemainsPerkg,
      'human_remains_min': this.airprices.humanRemainsMin,
      'livestock_perkg': this.airprices.livestockPerkg,
      'livestock_min': this.airprices.livestockMin,
      'livestock_pkg_charge': this.airprices.livestockPkgCharge,
      'shirmps_sea_food_perkg': this.airprices.shirmpsSeaFoodPerkg,
      'shirmps_sea_food_min': this.airprices.shirmpsSeaFoodMin,
      'perishable_charge_perkg': this.airprices.perishableChargePerkg,
      'perishable_charge_min': this.airprices.perishableChargeMin,
      'dryice_fixed': this.airprices.dryiceFixed,
      'heavy_cargo_perkg': this.airprices.heavyCargoPerkg,
      'heavy_cargo_min': this.airprices.heavyCargoMin,
      'valuve_cargo_perkg': this.airprices.valuveCargoPerkg,
      'valuve_carge_min': this.airprices.valuveCargeMin,
      'shipment_value_perkg': this.airprices.shipmentValuePerkg,
      'shipment_value_min': this.airprices.shipmentValueMin,
      'demurage_perkg': this.airprices.demuragePerkg,
      'demurage_min': this.airprices.demurageMin,
      'cold_chain_perkg': this.airprices.coldChainPerkg,
      'cold_chain_min': this.airprices.coldChainMin,
      'door_pickup_perkg': this.airprices.doorPickupPerkg,
      'door_pickup_min': this.airprices.doorPickupMin,
      'door_pickup_opa': this.airprices.doorPickupOpa,
      'door_delivery_perkg': this.airprices.doorDeliveryPerkg,
      'door_delivery_min': this.airprices.doorDeliveryMin,
      'door_delivery_oda': this.airprices.doorDeliveryOda,
      'dangerous_goods_perkg': this.airprices.dangerousGoodsPerkg,
      'dangerous_goods_min': this.airprices.dangerousGoodsMin,
      'special_cargo_perkg': this.airprices.specialCargoPerkg,
      'special_cargo_min': this.airprices.specialCargoMin,
      'cansoul_charges_perkg': this.airprices.cansoulChargesPerkg,
      'cansoul_charges_min': this.airprices.cansoulChargesMin,
      'airline_surcharge_perkg': this.airprices.airlineSurchargePerkg,
      'airline_surcharge_min': this.airprices.airlineSurchargeMin,
      'additional_surcharge_perkg': this.airprices.additionalSurchargePerkg,
      'net_rate_100kg_slab': this.airprices.netRate100kgSlab,
      'discount_min': this.airprices.discountMin,
      'discount_perkg': this.airprices.discountPerkg,
      'is_active': true,
    };
    await fetch(API_URL + '/airline_pricing1/' + users.id, {
      method: 'PATCH',
      body: JSON.stringify(users),
      headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
        .then((response) => response.json())
        .then((result) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Updated successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.viewchange('table', this.airprices.id);
          this.getAirpricingList1();
        })
        .catch((error) => console.error('error', error));
    return this.getAirpricingList1();
  }

  async addairpricing() {
    const TOKEN = this.authService.getAuthToken();
    const users1 = {
      'id': this.addairprices.id,
      'airline': this.addairprices.airline,
      'origin': this.addairprices.origin,
      'destination': this.addairprices.destination,
      'product_type': this.addairprices.productType,
      'consignment_type': this.addairprices.consignmentType,
      'consignment_subtype': this.addairprices.consignmentSubtype,
      'applicable_flights': this.addairprices.applicable_flights,
      'applicable_commodity': this.addairprices.applicable_commodity,
      'airline_tariff_min': this.addairprices.airlineTariffMin,
      'airline_tariff_n_rate': this.addairprices.airlineTariffNRate,
      'airline_tariff_above_45': this.addairprices.airlineTariffAbove45,
      'airline_tariff_above_100': this.addairprices.airlineTariffAbove100,
      'airline_tariff_above_300': this.addairprices.airlineTariffAbove300,
      'airline_tariff_above_500': this.addairprices.airlineTariffAbove500,
      'outbound_tsp_perkg': this.addairprices.outboundTspPerkg,
      'outbound_tsp_min': this.addairprices.outboundTspMin,
      'outbound_cash_tsp_perkg': this.addairprices.outboundCashTspPerkg,
      'outbound_cash_tsp_min': this.addairprices.outboundCashTspMin,
      'outbound_unit_perkg': this.addairprices.outboundUnitPerkg,
      'outbound_unit_min': this.addairprices.outboundUnitMin,
      'outbound_handling_charges_perkg': this.addairprices.outboundHandlingChargesPerkg,
      'outbound_handling_charges_min': this.addairprices.outboundHandlingChargesMin,
      'outbound_xray_screening_perkg': this.addairprices.outboundXrayScreeningPerkg,
      'outbound_xray_screening_min': this.addairprices.outboundXrayScreeningMin,
      'outbound_xray_certification_perkg': this.addairprices.outboundXrayCertificationPerkg,
      'outbound_xray_certification_min': this.addairprices.outboundXrayCertificationMin,
      'outbound_origin_scc_perkg': this.addairprices.outboundOriginSccPerkg,
      'outbound_origin_scc_min': this.addairprices.outboundOriginSccMin,
      'outbound_total_ahc': this.addairprices.outboundTotalAhc,
      'inbound_tsp_perkg': this.addairprices.inboundTspPerkg,
      'inbound_tsp_min': this.addairprices.inboundTspMin,
      'inbound_cash_tsp_perkg': this.addairprices.inboundCashTspPerkg,
      'inbound_cash_tsp_min': this.addairprices.inboundCashTspMin,
      'inbound_handling_charges_perkg': this.addairprices.inboundHandlingChargesPerkg,
      'inbound_handling_charges_min': this.addairprices.inboundHandlingChargesMin,
      'inbound_unit_perkg': this.addairprices.inboundUnitPerkg,
      'inbound_unit_min': this.addairprices.inboundUnitMin,
      'inbound_total_ahc': this.addairprices.inboundTotalAhc,
      'other_charges_sercurity_perkg': this.addairprices.otherChargesSercurityPerkg,
      'other_charges_sector_surcharge_perkg': this.addairprices.otherChargesSectorSurchargePerkg,
      'other_charges_fligh_surcharge_perkg': this.addairprices.otherChargesFlighSurchargePerkg,
      'mawb_stat_da': this.addairprices.mawbStatDa,
      'mawb_do': this.addairprices.mawbDo,
      'mawb_misc_perkg': this.addairprices.mawbMiscPerkg,
      'mawb_misc_fixed': this.addairprices.mawbMiscFixed,
      'mawb_other_charge': this.addairprices.mawbOtherCharge,
      'iclscm_handling_perkg': this.addairprices.iclscmHandlingPerkg,
      'iclscm_handling_min': this.addairprices.iclscmHandlingMin,
      'freight_attached_consignment_perkg': this.addairprices.freightAttachedConsignmentPerkg,
      'freight_attached_consignment_min': this.addairprices.freightAttachedConsignmentMin,
      'human_remains_perkg': this.addairprices.humanRemainsPerkg,
      'human_remains_min': this.addairprices.humanRemainsMin,
      'livestock_perkg': this.addairprices.livestockPerkg,
      'livestock_min': this.addairprices.livestockMin,
      'livestock_pkg_charge': this.addairprices.livestockPkgCharge,
      'shirmps_sea_food_perkg': this.addairprices.shirmpsSeaFoodPerkg,
      'shirmps_sea_food_min': this.addairprices.shirmpsSeaFoodMin,
      'perishable_charge_perkg': this.addairprices.perishableChargePerkg,
      'perishable_charge_min': this.addairprices.perishableChargeMin,
      'dryice_fixed': this.addairprices.dryiceFixed,
      'heavy_cargo_perkg': this.addairprices.heavyCargoPerkg,
      'heavy_cargo_min': this.addairprices.heavyCargoMin,
      'valuve_cargo_perkg': this.addairprices.valuveCargoPerkg,
      'valuve_carge_min': this.addairprices.valuveCargeMin,
      'shipment_value_perkg': this.addairprices.shipmentValuePerkg,
      'shipment_value_min': this.addairprices.shipmentValueMin,
      'demurage_perkg': this.addairprices.demuragePerkg,
      'demurage_min': this.addairprices.demurageMin,
      'cold_chain_perkg': this.addairprices.coldChainPerkg,
      'cold_chain_min': this.addairprices.coldChainMin,
      'door_pickup_perkg': this.addairprices.doorPickupPerkg,
      'door_pickup_min': this.addairprices.doorPickupMin,
      'door_pickup_opa': this.addairprices.doorPickupOpa,
      'door_delivery_perkg': this.addairprices.doorDeliveryPerkg,
      'door_delivery_min': this.addairprices.doorDeliveryMin,
      'door_delivery_oda': this.addairprices.doorDeliveryOda,
      'dangerous_goods_perkg': this.addairprices.dangerousGoodsPerkg,
      'dangerous_goods_min': this.addairprices.dangerousGoodsMin,
      'special_cargo_perkg': this.addairprices.specialCargoPerkg,
      'special_cargo_min': this.addairprices.specialCargoMin,
      'cansoul_charges_perkg': this.addairprices.cansoulChargesPerkg,
      'cansoul_charges_min': this.addairprices.cansoulChargesMin,
      'airline_surcharge_perkg': this.addairprices.airlineSurchargePerkg,
      'airline_surcharge_min': this.addairprices.airlineSurchargeMin,
      'additional_surcharge_perkg': this.addairprices.additionalSurchargePerkg,
      'net_rate_100kg_slab': this.addairprices.netRate100kgSlab,
      'discount_min': this.addairprices.discountMin,
      'discount_perkg': this.addairprices.discountPerkg,
      'is_active': true,
    };
    if (users1.origin == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Origin is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.destination == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Destination is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.airline == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Airline is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.consignment_type == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Consignment Type is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.airline_tariff_min == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Airline Tariff Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.airline_tariff_n_rate == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Airline TariffNRate is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.airline_tariff_above_45 == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Airline Tariff above 45 is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.airline_tariff_above_100 == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Airline Tariff above 100 is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.airline_tariff_above_300 == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Airline Tariff above 300 is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.airline_tariff_above_500 == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Airline Tariff above 500 is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.outbound_tsp_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Outbound TSP PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.outbound_tsp_min == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Outbound TSP Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.outbound_cash_tsp_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Outbound Cash TSP PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.outbound_cash_tsp_min == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Outbound Cash TSP Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.outbound_unit_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Outbound Unit PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.outbound_unit_min == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Outbound Unit Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.outbound_handling_charges_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Outbound Handling Charges PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.outbound_handling_charges_min == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Outbound Handling Charges Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.outbound_xray_screening_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Outbound X-Ray Screening PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.outbound_xray_screening_min == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Outbound X-Ray Screening Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.outbound_xray_certification_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Outbound X-Ray Certification PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.outbound_xray_certification_min == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Outbound X-Ray Certification Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.outbound_origin_scc_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Outbound Origin SCC PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.outbound_origin_scc_min == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Outbound Origin SCC Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.inbound_tsp_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Inbound TSP PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.inbound_tsp_min == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Inbound TSP Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.inbound_cash_tsp_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Inbound Cash TSP PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.inbound_cash_tsp_min == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Inbound Cash TSP Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.inbound_unit_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Inbound Unit PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.inbound_unit_min == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Inbound Unit Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.inbound_handling_charges_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Inbound Handling Charges PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.inbound_handling_charges_min == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Inbound Handling Charges Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.other_charges_sercurity_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Other Charges Security PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.other_charges_sector_surcharge_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Other Charges Sector Surcharge PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.other_charges_fligh_surcharge_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Other Charges Flight Surcharge PerKG Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.mawb_stat_da == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'MAWB Stat DA is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.mawb_do == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'MAWB DO is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.mawb_misc_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'MAWB Misc PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.mawb_misc_fixed == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'MAWB Misc Fixed is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.mawb_other_charge == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'MAWB Other Charge is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.iclscm_handling_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'ICLSCM Handling PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.iclscm_handling_min == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'ICLSCM Handling Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.airline_surcharge_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Airline Surcharge PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.airline_surcharge_min == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Airline Surcharge Min is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.additional_surcharge_perkg == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Additional Surcharge PerKG is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.product_type == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select a Product Type',
        timer: 5000,
      });
      this.Loading = false;
    } else if (users1.consignment_subtype == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please select a Consignment Subtype',
        timer: 5000,
      });
      this.Loading = false;
    } else {
      await fetch(API_URL + '/airline_pricing', {
        method: 'POST',
        body: JSON.stringify(users1),
        headers: {
          'Authorization': 'Bearer ' + TOKEN,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      })
          .then((response) => response.json())
          .then((result) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Created successfully',
              showConfirmButton: false,
              timer: 5000,
            });
            window.location.reload();
          })
          .catch((error) => console.error('error', error));
      return this.getAirpricingList1();
    }
  }

  DeleteAirlinePricing(airline) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to delete ' + airline.origin.iataCode + ' - ' + airline.destination.iataCode + ' (' + airline.airline.name + ')' + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.Deletedata(airline.id);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Deleted successfully',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    });
  }
  
  CancelCreating(){
    this.addairprices = {
      id: null,
      airline: null,
      origin: null,
      destination: null,
      productType: null,
      consignmentType: null,
      consignmentSubtype: null,
      applicable_flights: 0,
      applicable_commodity: 0,
      airlineTariffMin: 0,
      airlineTariffNRate: 0,
      airlineTariffAbove45: 0,
      airlineTariffAbove100: 0,
      airlineTariffAbove300: 0,
      airlineTariffAbove500: 0,
      outboundTspPerkg: 0,
      outboundTspMin: 0,
      outboundCashTspPerkg: 0,
      outboundCashTspMin: 0,
      outboundUnitPerkg: 0,
      outboundUnitMin: 0,
      outboundHandlingChargesPerkg: 0,
      outboundHandlingChargesMin: 0,
      outboundXrayScreeningPerkg: 0,
      outboundXrayScreeningMin: 0,
      outboundXrayCertificationPerkg: 0,
      outboundXrayCertificationMin: 0,
      outboundOriginSccPerkg: 0,
      outboundOriginSccMin: 0,
      outboundTotalAhc: 0,
      inboundTspPerkg: 0,
      inboundTspMin: 0,
      inboundCashTspPerkg: 0,
      inboundCashTspMin: 0,
      inboundHandlingChargesPerkg: 0,
      inboundHandlingChargesMin: 0,
      inboundUnitPerkg: 0,
      inboundUnitMin: 0,
      inboundTotalAhc: 0,
      otherChargesSercurityPerkg: 0,
      otherChargesSectorSurchargePerkg: 0,
      otherChargesFlighSurchargePerkg: 0,
      mawbStatDa: 0,
      mawbDo: 0,
      mawbMiscPerkg: 0,
      mawbMiscFixed: 0,
      mawbOtherCharge: 0,
      iclscmHandlingPerkg: 0,
      iclscmHandlingMin: 0,
      freightAttachedConsignmentPerkg: 0,
      freightAttachedConsignmentMin: 0,
      humanRemainsPerkg: 0,
      humanRemainsMin: 0,
      livestockPerkg: 0,
      livestockMin: 0,
      livestockPkgCharge: 0,
      shirmpsSeaFoodPerkg: 0,
      shirmpsSeaFoodMin: 0,
      perishableChargePerkg: 0,
      perishableChargeMin: 0,
      dryiceFixed: 0,
      heavyCargoPerkg: 0,
      heavyCargoMin: 0,
      valuveCargoPerkg: 0,
      valuveCargeMin: 0,
      shipmentValuePerkg: 0,
      shipmentValueMin: 0,
      demuragePerkg: 0,
      demurageMin: 0,
      coldChainPerkg: 0,
      coldChainMin: 0,
      doorPickupPerkg: 0,
      doorPickupMin: 0,
      doorPickupOpa: 0,
      doorDeliveryPerkg: 0,
      doorDeliveryMin: 0,
      doorDeliveryOda: 0,
      dangerousGoodsPerkg: 0,
      dangerousGoodsMin: 0,
      specialCargoPerkg: 0,
      specialCargoMin: 0,
      cansoulChargesPerkg: 0,
      cansoulChargesMin: 0,
      airlineSurchargePerkg: 0,
      airlineSurchargeMin: 0,
      additionalSurchargePerkg: 0,
      netRate100kgSlab: 0,
      discountMin: 0,
      discountPerkg: 0,
      // is_active:null
    }
    this.selectedProductType = new ProductTypeModel();
    this.viewchange1('table');
  }
}
