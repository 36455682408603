<!-- <p>operationaltimingspanel works!</p> -->
<div class="col-md-8">
    <container-element [ngSwitch]="pageview">
        <some-element *ngSwitchCase="'table'">
            <div class="card">
                <div class="card-header">

                    <div class="card-header-title font-size-lg text-capitalize fw-normal">
                        <b style="color: blue;">OPERATIONAL TIMINGS</b>
                    </div>
                </div>
                <!-- <div class="container">
                    <div class="row">
                        <div class="">
                            <div class="table-responsive">
                                <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Type</th>
                                            <th class="text-center">From Time</th>
                                            <th class="text-center">To Time</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let ot of OperationalTimingsList.data">
                                            <td class="text-center">
                                                {{ot.type}}
                                            </td>
                                            <td class="text-center">
                                                {{ot.fromTime}}
                                            </td>
                                            <td class="text-center">
                                                {{ot.toTime}}
                                            </td>
                                            <td class="text-center">
                                                <div role="group" class="btn-group-sm btn-group m-1">
                                                    <button class="btn-shadow btn btn-primary" matTooltip="Edit"
                                                        *ngIf="ot.isActive == 1" (click)="viewchange1('edit',ot)"><i
                                                            class="lnr-pencil"></i></button>
                                                </div>
                                                <div role="group" class="btn-group-sm btn-group ">
                                                    <button *ngIf="ot.is_active == 0" id="active"
                                                        class="btn-shadow btn btn-primary"
                                                        (click)="activeAirline(ot.id,1)"><i
                                                            class="fa fa-undo"></i></button>
                                                </div>
                                            </td>
                                    </tbody>
                                </table>
                                <mat-paginator (page)="handlePageEvent($event)" [length]="OperationalTimingsList?.totalElements"
                                [pageSize]="OperationalTimingsList?.numberOfElements" [showFirstLastButtons]="showFirstLastButtons"
                                [pageSizeOptions]="pageSizeOptions" [pageIndex]="OperationalTimingsList?.number" aria-label="Select page">
                              </mat-paginator>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="table-responsive">
					<table class="w-100" mat-table [dataSource]="dataSource" matSort
						(matSortChange)="announceSortChange($event)">
						<!-- Position Column -->
						<ng-container matColumnDef="type">
							<th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header
								sortActionDescription="Sort by type">Type</th>
							<td class="text-center" mat-cell *matCellDef="let element"> {{element.type}} </td>
						</ng-container>
						<ng-container matColumnDef="from_time">
							<th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header
								sortActionDescription="Sort by from_time">Start Time (HH:MM:SS)</th>
							<td class="text-center" mat-cell *matCellDef="let element">
								{{element.fromTime}} </td>
						</ng-container>
						<ng-container matColumnDef="to_time">
							<th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header
								sortActionDescription="Sort by to_time">End Time  (HH:MM:SS)</th>
							<td class="text-center" mat-cell *matCellDef="let element">
                                {{element.toTime}}</td>
						</ng-container>
						<ng-container matColumnDef="Active">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
							<td mat-cell *matCellDef="let element">
								<div class="d-flex">
									<div role="group" class="btn-group-sm btn-group m-1">
										<button class="btn-shadow btn btn-primary" matTooltip="Edit"
											*ngIf="element.isActive==true" (click)="viewchange1('edit',element)"><i
												class="lnr-pencil"></i></button>
									</div>
								</div>
							</td>
						</ng-container>
						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
						[ngClass]="row.isActive==false? 'red':'white'"></tr>
					</table>
					<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
					</mat-paginator>
				</div>
            </div>
        </some-element>
        <some-element *ngSwitchCase="'edit'">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title font-size-lg text-capitalize fw-normal">
                        <b style="color: blue;">EDIT OPERATIONAL TIMINGS</b>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field appearance="standard">
                                <mat-label>
                                    Type
                                </mat-label>
                                <input type="text" [(ngModel)]="newoperationaltimings.type" placeholder="Mode"
                                    aria-label="Number" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="standard">
                                <mat-label>
                                    Start Time  (HH:MM:SS)
                                </mat-label>
                                <input type="time" [(ngModel)]="newoperationaltimings.fromTime" placeholder="Type"
                                    aria-label="Number" matInput step="1">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="standard">
                                <mat-label>
                                    End Time  (HH:MM:SS)
                                </mat-label>
                                <input type="time" [(ngModel)]="newoperationaltimings.toTime" placeholder="Time"
                                    aria-label="Number" matInput step="1">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="btn-actions-pane-right p-2">
                        <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');tablefilter()">Cancel</div>
                        <div class="mb-2 me-2 btn btn-primary" (click)="editOperationalTimings()">Save</div>
                    </div>
                </div>
            </div>
        </some-element>
    </container-element>
</div>
