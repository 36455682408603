import {ConsignmentSubTypesModel} from '../../consignmentSubtypes/models/consignment-sub-types-model';

export class ConsignmentTypesModel {
  id: string;
  name: string;
  description: string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  consignment_sub_types_list: ConsignmentSubTypesModel[];


  constructor(id: string, name: string, description: string, is_active: boolean) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.is_active = is_active;
  }
}
