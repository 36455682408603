import {Component, OnInit} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {API_URL} from '../../../../../Global_API_URL';
import {AuthService} from '../../../auth.service';


@Component({
  selector: 'app-totalconsignments',
  templateUrl: './totalconsignments.component.html',
  styleUrls: ['./totalconsignments.component.sass'],
})
export class TotalconsignmentsComponent implements OnInit {
  constructor(private authService : AuthService) {
    this.authService.getAuthToken();
  }
  filterParams='';
  activestatus=false;
  pagenation= {
    col_name: 'id',
    page_size: 10,
    page_no: 0,
    sort_dir: 'DESC',
  }
  consignments: any;
  consignmentlists=null;
  showFirstLastButtons;
  pageSizeOptions=[5, 10, 30];
  ngOnInit(): void {
    this.consignments=this.getconsignmentsList();
  }
  tablefilter() {
    this.pagenation.page_no=0;
    this.pagenation.sort_dir='DESC';
    this.getconsignmentsList();
  }
  filter() {
    this.filterParams='?';
    this.filterParams= this.filterParams+'page_no='+this.pagenation.page_no+'&page_size='+this.pagenation.page_size+'&col_name='+this.pagenation.col_name+'&sort_dir='+this.pagenation.sort_dir;
    return this.filterParams;
  }
  handlePageEvent(event: PageEvent) {
    this.pagenation.page_no = event.pageIndex;
    this.pagenation.page_size=event.pageSize;
    this.getconsignmentsList();
  }
  async getconsignmentsList() {
    const TOKEN = this.authService.getAuthToken();
    // console.log(API_URL)
    const api= '/consignments';
    const params = this.filter();
    let consignment;
    await fetch(API_URL + api+params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          this.consignmentlists = json;
        });
    return this.consignmentlists;
  }
  formatDate(date) {
    const date2= new Date(date);
    return (
      [
        date2.getFullYear(),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-') +
     ' ' +
     [
       this.padTo2Digits(date2.getHours()),
       this.padTo2Digits(date2.getMinutes()),
     ].join(':')
    );
  }
  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  async Deletedata(id) {
    const TOKEN = this.authService.getAuthToken();
    // console.log(id)
    const data = JSON.stringify({
      'id': id,
    });
    await fetch(API_URL + '/consignment/active/'+this.activestatus,
        {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+TOKEN,
          },
          body: data,
        })

    // Converting received data to JSON
        .then((response) => response.text());
    this.consignments = this.getconsignmentsList()
        .catch((error) => console.error('error', error));
  }
}
