<div class="col-md-14">
    <container-element [ngSwitch]="pageview">
        <some-element *ngSwitchCase="'table'">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title font-size-lg text-capitalize fw-normal">
                        <b style="color:blue;">Ground Services</b>
                    </div>

                </div>
                <div class="table-responsive">
                    <table class="w-100" mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="City">
                            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header
                                sortActionDescription="Sort by pincode" style="width: 16%;">Cities/Products</th>
                            <td class="text-center" mat-cell *matCellDef="let element">{{element.name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="FTL">
                            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header
                                sortActionDescription="Sort by country">
                                FTL</th>
                            <td class="text-center" mat-cell *matCellDef="let element">
                                <mat-checkbox class="example-margin"></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="PTL">
                            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header
                                sortActionDescription="Sort by pincode">PTL</th>
                            <td class="text-center" mat-cell *matCellDef="let element">
                                <mat-checkbox class="example-margin"></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="KV">
                            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header
                                sortActionDescription="Sort by pincode">KV</th>
                            <td class="text-center" mat-cell *matCellDef="let element">
                                <mat-checkbox class="example-margin"></mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="KP">
                            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header
                                sortActionDescription="Sort by ooa">KP</th>
                            <td class="text-center" mat-cell *matCellDef="let element">
                                <mat-checkbox class="example-margin"></mat-checkbox>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
                    </mat-paginator>
                    <ngx-loading [show]="loading"
                        [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                        [template]="loadingTemplate"></ngx-loading>
                </div>

            </div>
        </some-element>


    </container-element>
</div>
