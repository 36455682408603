import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpService} from "../../../services/http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {API_URL} from "../../../../../Global_API_URL";
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from "ngx-loading";
import {CustomerVerificationService} from "../../../shared/customer-verification/customer-verification.service";
import Swal from "sweetalert2";
import {getCitiesById, getPincodesById, getStatesById} from "../../getCities.js";
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-retail-customer-edit',
  templateUrl: './retail-customer-edit.component.html',
  styleUrls: ['./retail-customer-edit.component.sass']
})
export class RetailCustomerEditComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  retailCustomerId;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public customerVerificationService: CustomerVerificationService, private httpService: HttpService, private sanitizer: DomSanitizer) {
    this.activatedRoute.params.subscribe((params) => {
      this.retailCustomerId = params['id'];
    })
  }

  ngOnInit(): void {
    this.customerVerificationService.resetRetailCustomerData(this.customer_shipper);
    this.getRetailCustomerById();

    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  selected = '';
  userEmail;

  customer_shipper = {
    id: null,
    firstName: '',
    middleName: '',
    lastName: '',
    avatarUrl: '',
    email: '',
    emailForMasterGST: '',
    phone: '',
    whatsapp: '',
    designation: null,
    employmentNo: null,
    billingAddress: {
      pincode: {
        pin_number: ''
      },
      address: ''
    },
    isSuperUser: null,
    userName: '',
    passwordHash: null,
    lastLoggedInAt: null,
    lastLoggedInIp: null,
    aadhaarNumber: null,
    drivingLicense: null,
    gstNumber: null,
    panNumber: null,
    gstDetails: null,
    panDetails: null,
    aadhaarDetails: null,
    customerVerified: true,
    isActive: true,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
  }


  sanitizeImage(image: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(image);
  }


  getRetailCustomerById() {
    this.httpService.get(API_URL + "/retailCustomer/" + this.retailCustomerId, null, null)
      .subscribe(response => {
        this.customer_shipper = response.data[0];
        if(this.customer_shipper.billingAddress==null) this.customer_shipper.billingAddress = {
          pincode: {
            pin_number: ''
          },
          address: ''
        }
        this.userEmail = this.customer_shipper.email;
        this.customerVerificationService.getPincode(this.customer_shipper.billingAddress?.pincode?.pin_number)
          .subscribe(response => {
            this.customerVerificationService.pincodeData = response.pincodeData
            this.customerVerificationService.user_sel_country = this.customerVerificationService.pincodeData.city.state_id.country.id;
            this.customerVerificationService.user_sel_state = this.customerVerificationService.pincodeData.city.state_id.id;
            this.customerVerificationService.user_sel_city = this.customerVerificationService.pincodeData.city.id;
            this.customer_shipper.billingAddress.pincode.pin_number = this.customerVerificationService.pincodeData.pin_number;
            this.customerVerificationService.user_states = getStatesById(this.customerVerificationService.user_sel_country);
            this.customerVerificationService.user_cities = getCitiesById(this.customerVerificationService.user_sel_state);
            this.customerVerificationService.user_pincodes = getPincodesById(this.customerVerificationService.user_sel_city);
          });

        if (response.data[0]?.gstDetails != null) {
          this.customerVerificationService.gstin = response.data[0]?.gstNumber;
          this.customerVerificationService.gstData = JSON.parse(response.data[0]?.gstDetails);
          this.selected = 'gst';
          this.customerVerificationService.GSTVerified = true;
          this.customerVerificationService.CustomerVerified = true;
        } else if (response.data[0]?.panDetails != null) {
          this.customerVerificationService.panData = JSON.parse(response.data[0]?.panDetails);
          this.customerVerificationService.panReqBody.number = response.data[0]?.panNumber;
          this.customerVerificationService.panReqBody.returnIndividualTaxComplianceInfo = this.customerVerificationService.panData?.individualTaxComplianceStatus;
          this.selected = 'pan';
          this.customerVerificationService.PANVerified = true;
          this.customerVerificationService.CustomerVerified = true;
        } else if (response.data[0]?.aadhaarDetails != null) {
          this.customerVerificationService.aadhaarData = JSON.parse(response.data[0]?.aadhaarDetails);
          this.customerVerificationService.aadhaarGenerateOtpReqBody.aadhaarNumber = this.customerVerificationService.aadhaarData?.aadhaar_number;
          this.customerVerificationService.customerImage = 'data:application/png;base64,' + this.customerVerificationService.aadhaarData?.profile_image;
          this.selected = 'aadhaar';
          this.customerVerificationService.AADHAARVerified = true;
          this.customerVerificationService.CustomerVerified = true;
        }
      })
  }


  async emailExistAppuser() {
    if (this.userEmail != this.customer_shipper.email) {
      await this.customerVerificationService.emailExistAppuser(this.customer_shipper.email);
    } else {
      this.customerVerificationService.emailExist = false;
    }
  }

  retailCustomerVerification() {
    if (this.selected == 'gst') {

      this.customerVerificationService.gstVerification(this.customer_shipper, "retail", "edit");

    } else if (this.selected == 'pan') {

      this.customerVerificationService.panVerification(this.customer_shipper, "edit");

    } else if (this.selected == 'aadhaar') {

      this.customerVerificationService.aadhaarVerification(this.customer_shipper, "edit");

    }
  }

  async editRetailCustomer() {
    if (this.customer_shipper.firstName == null || this.customer_shipper.firstName == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The First Name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.firstName.length != this.customer_shipper.firstName.trim().length || this.customer_shipper.firstName.trim().length == 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Remove the spaces at beginning or end of the first name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.lastName == null || this.customer_shipper.lastName == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Last Name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.lastName.length != this.customer_shipper.lastName.trim().length || this.customer_shipper.lastName.trim().length == 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Remove the spaces at beginning or end of the last name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.email == null || this.customer_shipper.email == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Email',
        timer: 5000,
      });
      return;
    } else if (!(this.customerVerificationService.emailregex.test(this.customer_shipper.email))) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter A Valid Email',
        timer: 5000,
      });
      return;
    } /*else if (await this.customerVerification.emailExistAppuser(this.customer_shipper.email)) {
      return;
    }*/
      else if (this.customerVerificationService.emailExist) {
        Swal.fire({
          icon: 'warning',
          title: 'WARNING',
          text: 'Email Already Exists',
          timer: 5000,
        });
        return;
    }
    else if (this.customer_shipper.phone == null || this.customer_shipper.phone == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Phone No.',
        timer: 5000,
      });
      return;
    } else if ((this.customer_shipper.phone).length < 10 || isNaN(parseInt(this.customer_shipper.phone)) == true) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Phone No.',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper?.whatsapp?.length > 0 && this.customer_shipper?.whatsapp?.length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Whatsapp No.',
      });
    } else if (this.customer_shipper.billingAddress.address == '' || this.customer_shipper.billingAddress.address == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Address',
        timer: 5000,
      });
      return;
    } else if (this.customerVerificationService.user_sel_country == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Country',
        timer: 5000,
      });
      return;
    } else if (this.customerVerificationService.user_sel_state == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The State',
        timer: 5000,
      });
      return;
    } else if (this.customerVerificationService.user_sel_city == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The City',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.billingAddress.pincode.pin_number == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Zip Code',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper?.aadhaarNumber?.length > 0 && this.customer_shipper?.aadhaarNumber?.length < 12) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Aadhaar No. must be 12 digits',
        timer: 5000,
      });
    } else if (this.customer_shipper?.panNumber?.length > 0 && !this.customerVerificationService.panregex.test(this.customer_shipper.panNumber)) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter Valid PAN No. ',
        timer: 5000,
      });
    } else {
      this.loading = true;
      this.customer_shipper.customerVerified = true;
      this.httpService.patch(API_URL + "/retailUser/" + this.retailCustomerId, this.customer_shipper, null, null)
        .subscribe(response => {
          this.loading = false;
          Swal.fire({
            icon: 'success',
            title: 'Customer Updated',
            timer: 5000,
          }).then(() => {
            this.goBackToRetailCustomerTable();
          });
        }, error => {
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: 'Failed to update customer',
            timer: 5000,
          });
        })
    }
  }


  goBackToRetailCustomerTable() {
    history.back();
  }

  transformPANoUpperCaseForCreate() {
    this.customer_shipper.panNumber = this.customer_shipper.panNumber.toUpperCase();
  }

}
