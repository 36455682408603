<div class="row">
    <div class="col-md-8">
      <div class="card mb-3">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            <b style="color: blue;">BOOKING TYPES</b>
          </div>
        </div>
        <div class="row">
            <div class="col-md-6 p-4">
              <a (click)="onlineRedirect()" class="card p-2 card-hover-product">
                <img matTooltip="Ready To Work" src="../../../../../assets/images/icl-products/Online.jpg"
                  class="">
              </a>
            </div>
            <div class="col-md-6 p-4">
              <a (click)="offlineRedirect()" class="card p-2 card-hover-product">
                <img matTooltip="Ready To Work" src="../../../../../assets/images/icl-products/Ofline.jpg"
                  class="">
              </a>
              <!-- D2A -->
            </div>
          </div>
      </div>
    </div>
</div>
