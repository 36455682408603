<div *ngIf="loading" class="loader-container">
  <ngx-loading [show]="loading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>

<div class="card mt-2">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
      <a><i class="fa fa-arrow-left" (click)="backToConsignmentView()" style="cursor: pointer"></i></a> View Billing Info
    </div>
    <div class="btn-actions-pane-right p-2 ">
      <button mat-raised-button color="primary" *ngIf="!displayToCustomer" (click)="getConsignmentByIdToBillingEdit()" [disabled]="commonApi.BillingPriceLists.isInvoiceGenerated">Edit</button>
    </div>
  </div>

  <div style="width: 80%; margin:4% 10%">
    <div class="table-responsive">
      <table class="table1" style="width:100%">
        <tr>
          <td class="left">Consignment No :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.consignment_no}}</td>
          <td class="left">Customer Name :</td>
          <td class="right">{{commonApi.BillingPriceLists?.consignment?.isRetailCustomer ? commonApi.BillingPriceLists?.consignment?.individual_name : commonApi.BillingPriceLists?.consignment?.customer_name}}</td>
        </tr>

        <tr>
          <td class="left">Origin :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.origin || commonApi.BillingPriceLists.consignment?.cityname }}</td>
          <td class="left">Destination :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.destination || commonApi.BillingPriceLists.consignment?.receivercity}}</td>
        </tr>

        <tr>
          <td class="left">No of packages :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.no_of_packages}}</td>
          <td class="left">Total chargeable weight :</td>
          <td class="right">{{commonApi.BillingPriceLists.consignment?.total_chargable_weight}} kg</td>
        </tr>
        <tr>
          <td class="left">Consignment Value :</td>
          <td class="right">INR {{(commonApi.BillingPriceLists.consignment?.consignment_value).toLocaleString('en-IN')}}</td>
        </tr>
      </table>
    </div>

    <hr style="margin-right:-12.5%; margin-left: -12.4%; border: 1px solid black">

    <div class="table-responsive">
      <table class="table" style="width:100%">
        <tr class="header">
          <th style="width: 43%">Line Items</th>
          <th style="width: 43%">Unit Price</th>
          <th style="width: 14%">Total</th>
        </tr>
        <ng-container *ngFor="let item of commonApi.BillingLineItemsLists">
          <tr height="50px" *ngIf="(commonApi.BillingPriceLists[item.value] != null && commonApi.BillingPriceLists[item.value] >0 && item.value != 'discountedAmount' && item.value != 'insurancePercentage' && item.value != 'insurance' && item.value != 'taxableAmount' && item.value != 'taxAmount' && item.value != 'taxablePercentage')">
            <td>{{item.name}}</td>
            <td>INR&nbsp;{{(commonApi.BillingPriceLists[item.value]).toLocaleString('en-IN')}}</td>
          </tr>
        </ng-container>
      </table>
    </div>

    <div style="display: flex; flex-direction:row">
      <div style="width: 86%; display: flex; flex-direction:row">
        <div>
          <b class="subTotal" style="float: left">Sub Total 1</b>
        </div>&nbsp;&nbsp;&nbsp;
        <div style="flex-grow: 1; border-top: 1px solid #ADADAD; margin-top:1.5%"></div>&nbsp;&nbsp;&nbsp;
      </div>
      <div>
        INR {{(commonApi.calculateTotalBillingInfoSum()).toLocaleString('en-IN')}}
      </div>
    </div>
    <div style="margin-top: 1%">
      <span style="float: left; width: 42.5%">Discount Amount</span> <span style="color: #0ba360">-INR {{commonApi.BillingPriceLists?.discountedAmount == null ? 0 : commonApi.BillingPriceLists?.discountedAmount?.toLocaleString('en-IN')}}</span>
    </div>

    <div style="margin-top: 3%; display: flex; flex-direction:row">
      <div style="width: 86%; display: flex; flex-direction:row">
        <div>
          <b class="subTotal" style="float: left">Sub Total 2</b>
        </div>&nbsp;&nbsp;&nbsp;
        <div style="flex-grow: 1; border-top: 1px solid #ADADAD; margin-top:1.5%"></div>&nbsp;&nbsp;&nbsp;
      </div>
      <div>
        INR {{(commonApi.calculateTotalBillingInfoSum() - commonApi.BillingPriceLists?.discountedAmount).toLocaleString('en-IN')}}
      </div>
    </div>
    <div style="margin-top: 1%">
      <span style="float: left; width: 42.5%">Insurance (FOV)</span>
      <span style="float: left" class="BillResp">+ {{commonApi.BillingPriceLists?.insurancePercentage == null ? 0 : commonApi.BillingPriceLists?.insurancePercentage}} %</span>
      <span>+INR {{commonApi.BillingPriceLists.insurance?.toLocaleString('en-IN')}}</span>
    </div>


    <div style="margin-top: 3%; display: flex; flex-direction:row">
      <div style="width: 86%; display: flex; flex-direction:row">
        <div>
          <b class="subTotal" style="float: left">Taxable Amount</b>
        </div>&nbsp;&nbsp;&nbsp;
        <div style="flex-grow: 1; border-top: 1px solid #ADADAD; margin-top:1.5%"></div>&nbsp;&nbsp;&nbsp;
      </div>
      <div>
        INR {{commonApi.BillTaxableAmount?.toLocaleString('en-IN')}}
      </div>
    </div>
    <div style="margin-top: 1%">
      <span style="float: left; width: 42.5%">Tax% ({{commonApi.BillingPriceLists?.isGst}})</span>
      <span style="float: left" class="BillResp">+ {{commonApi.BillTax}} %</span>
      <span>+INR {{commonApi.BillTaxAmount?.toLocaleString('en-IN')}}</span>
    </div>

    <div style="margin-top: 3%; display: flex; flex-direction:row">
      <div class="BillGrandTotalResp">
        <h3>Grand Total</h3>
      </div>
      <div>
        <h4>INR {{commonApi.GrandTotal?.toLocaleString('en-IN')}}</h4>
      </div>
    </div>

    <div style="width: 500px">
      <b>Remarks: </b> {{commonApi.BillingPriceLists?.remarks}}
    </div>

  </div>
</div>
