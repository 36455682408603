import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../auth.service';
import { API_URL } from '../../../../../Global_API_URL';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { HttpService } from '../../../services/http.service';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-tally-irn-generate',
  templateUrl: './tally-irn-generate.component.html',
  styleUrls: ['./tally-irn-generate.component.sass'],
})
export class TallyIrnGenerateComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  generatedAt;

  invoiceId;
  TOKEN;

  constructor(private ActivatedRoute: ActivatedRoute, private route: Router, private authService: AuthService, private http: HttpClient, private httpService: HttpService) {
    this.TOKEN = this.authService.getAuthToken();
    this.ActivatedRoute.params.subscribe((params) => {
      this.invoiceId = params['id'];
    });
  }

  userDetails;
  displayToAdmin = false;
  displayToSupervisor = false;

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0] == 'Admin') {
      this.displayToAdmin = true;
    }
    if (this.userDetails.userTypes[0] == 'Supervisor') {
      this.displayToSupervisor = true;
    }
    this.getInvoiceById(this.invoiceId);

    // setTimeout(() => {
    //   this.loading = false;
    // }, 2000);
  }

  invoiceData : any = {};

  getInvoiceById(id) {
    this.loading = true;
    this.httpService.get(API_URL + '/getInvoice/' + id, null, null)
      .subscribe((response) => {
        this.invoiceData = response.data[0];
        this.loading = false;
      });
  }

  IrnData = {
    'invoiceNo': '',
    'irnNumber': '',
    'acknowledgeNo': '',
    'qrCode': '',
    'createdAt': null,
    'remarks': ''
  }
  patchInvoieByID() {
    const requestBody = {
      remarks: this.invoiceData.remarks
    };
    this.loading = true;
    this.httpService.patch(API_URL + '/patchInvoice/' + this.invoiceId, requestBody, null, null)
      .subscribe(
        (response) => {
          this.loading = false;
          Swal.fire({
            icon: 'success',
            title: 'Invoice Updated Successfully',
            timer: 5000,
          });
          this.route.navigate(['/tallyIrnGenerateView',this.invoiceId]);
          this.loading = false;
        },
        (error) => {
          Swal.fire({
            icon: 'warning',
            title: error.error['message'],
            timer: 5000,
          });
          this.loading = false;
          console.error('Error:', error.error['message']);
        }
      );
  }

  validateInput(event: KeyboardEvent): void {
    const inputChar = String.fromCharCode(event.charCode);

    // Allow only digits and a dot (for decimal numbers)
    if (!/^[0-9a-zA-Z]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  backToInvoiceHistory() {
    this.route.navigate(['/invoicehistory']);
  }

  backToIRNGenerateView() {
    this.route.navigate(['/tallyIrnGenerateView', this.invoiceId]);
  }
  
}
