import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {API_URL} from '../../../../../Global_API_URL';
import {Cities} from '../../golbalInterfaces';
import {getCities} from '../../getCities.js';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {DatePipe} from '@angular/common';
import {FormControl, FormGroup} from '@angular/forms';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import Swal from 'sweetalert2';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {MatInput} from '@angular/material/input';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {saveAs} from 'file-saver';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../auth.service';
import {BillingService} from '../../../modules/billingInfo/service/billing.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';
const FILTERS_STORAGE_KEY = 'consignment_filters';
const PAGINATION_STORAGE_KEY = 'consignment_pagination';

@Component({
  selector: 'app-customer-table',
  templateUrl: './customer-table.component.html',
  styleUrls: ['./customer-table.component.sass'],
})
export class CustomerTableComponent implements OnInit {
  pipe: DatePipe;
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;
  constructor(private _liveAnnouncer: LiveAnnouncer, private http: HttpClient, private sanitizer: DomSanitizer, private modalService: NgbModal, private authService: AuthService, private route: Router, private ActivatedRoute: ActivatedRoute, public commonApi: BillingService) {
    this.authService.getAuthToken();
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Load saved filters whenever navigation ends
        this.loadSavedFilters();
      }
    });
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem(FILTERS_STORAGE_KEY);
      localStorage.removeItem(PAGINATION_STORAGE_KEY);
    });
  }
  filterParams = '';
  activestatus = false;
  pageview = 'table';
  customers = []
  airports = []
  pagination = {
    col_name: 'id',
    page_size: 10,
    page_no: 0,
    sort_dir: 'DESC',
  }

  consignment_id;
  sender_individual = '';
  pickup_location: any = '';
  drop_location: any = '';
  is_coperate_booking = '';
  search = '';
  image_1 = null;
  image_2 = null;
  image_3 = null;
  image_4 = null;
  POD = null;
  largeImage: any
  image1: File;
  image2: File;
  image3: File;
  image4: File;
  temperature_record_file: File;
  pod: File;
  data_logger_file: File;
  other_documents_file: File;
  vehicle_calibration_file: File;

  consignment_details = {
    id: null,
    consignmentType: null,
    weightUnit: 1,
    currency: 1,
    weightDivisor: 1,
    consignmentNo: null,
    airlinesId: null,
    isStopover: null,
    totalTransitTime: null,
    senderIndividual: null,
    isIndividualCustomer: null,
    paymentCompletedAt: null,
    isXrayScreening: null,
    isXrayCertification: null,
    isDryIce: null,
    totalWeight: null,
    totalChargableWeight: null,
    planSelected: null,
    senderId: null,
    packages: [],
    receiverId: null,
    isDocumentUploaded: null,
    isInsured: false,
    totalShippingCost: null,
    paymentMode: null,
    isPaymentCompleted: null,
    isActive: null,
    createdAt: '',
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
    totalNoOfPackages: null,
    destinationLocation: null,
    consignmentSubType: null,
    originLocation: null,
    dimensionuUnit: null,
    insuranceCoverage: 0,
    totalConsignmentValue: 0,
    shippingCost: 0,
    invoiceCreatedDate: '',
    invoiceReferenceNo: null,
    productType: null,
    remarks: null,
  }
  changeable_packages = [
    {
      id: 1,
      length: null,
      breadth: null,
      height: null,
      weight_per_package: null,
      total_weight: null,
      package_no: null,
      volume_weight: null,
      chargeable_weight: null,
    },
  ]
  consignmentlists: any = {
    id: '',
    consignment_no: '',
    consignmentType: '',
    weightUnit: 1,
    currency: 1,
    weightDivisor: 1,
    consignmentNo: '',
    airlinesId: '',
    isStopover: '',
    totalTransitTime: '',
    senderIndividual: '',
    isIndividualCustomer: '',
    paymentCompletedAt: '',
    isXrayScreening: '',
    isXrayCertification: '',
    isDryIce: '',
    totalWeight: '',
    totalChargableWeight: '',
    planSelected: '',
    senderId: '',
    receiverId: '',
    isDocumentUploaded: '',
    isInsured: false,
    totalShippingCost: '',
    paymentMode: '',
    isPaymentCompleted: '',
    isActive: '',
    createdAt: '',
    updatedAt: '',
    createdBy: '',
    updatedBy: '',
    totalNoOfPackages: '',
    destinationLocation: '',
    consignmentSubType: '',
    originLocation: '',
    dimensionuUnit: '',
    insuranceCoverage: '',
    totalConsignmentValue: '',
    shippingCost: '',
    invoiceCreatedDate: '',
    invoiceReferenceNo: '',
    productType: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    data_logger_file: '',
    vehicle_calibration_file: '',
    other_documents_file: '',
    temperature_record_file: '',
    pod: '',

  }
  packages = [
    {
      id: 1,
      length: null,
      breadth: null,
      height: null,
      weight_per_package: null,
      total_weight: null,
      packageNo: null,
      volume_weight: null,
      chargeable_weight: null,
    },
  ]

  open(content5, img) {
    this.modalService.open(content5, {
      size: 'lg',
    });
    this.largeImage = img;
  }

  // viewchanges(val,id){
  //   this.pageview=val;
  //   this.consignment_id=id;
  //   if(this.consignment_id!=0)
  //   this.getconsignmentById();
  // }
  // viewchange(val,id){
  //   if(val=="view"){
  //     this.viewconsignmentById(id);
  //   }else if(val=="table"){
  //     this.getconsignmentsList();
  //
  //   }
  //   this.consignment_id = id;
  //   this.pageview= val;
  //
  // }
  consignments: any;
  showFirstLastButtons;
  pageSizeOptions = [10, 30];
  @ViewChild('fromInput', {
    read: MatInput,
  }) fromInput: MatInput;

  @ViewChild('toInput', {
    read: MatInput,
  }) toInput: MatInput;
  ngOnInit(): void {
    const user_details: any = JSON.parse(localStorage.getItem('userDetails'));
    this.sender_individual = user_details.id;
    this.getconsignmentsList();
    this.getAllCustomers();
    this.getAirports();
    this.city = getCities();
    this.loadSavedFilters();
    const savedPageState = JSON.parse(localStorage.getItem('pageState')) || {};
    this.storedPage = savedPageState.pageIndex || 0;
    this.pageSize = savedPageState.pageSize || 10;
  }
  resetForm() {
    this.fromInput.value = '';
    this.toInput.value = '';
    this.is_coperate_booking = '';
    this.pickup_location = '';
    this.drop_location = '';
    this.consignment_id = '';
    this.search = '';
    this.getconsignmentsList();
    this.saveFiltersAndPaginationToStorage();
  }
  searchByCons(con) {
    this.dataSource = new MatTableDataSource(this.consignmentlists.filter((cons) => cons.consignment_no.includes(con)));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async tablefilter() {
    await this.getconsignmentsList();
    if (this.fromDate && this.toDate) {
      const fDate = new Date(this.fromDate.format('yyyy/MM/DD hh:mm:ss'));
      const tDate = new Date(this.toDate.format('yyyy/MM/DD hh:mm:ss'));
      tDate.setDate(tDate.getDate() + 1);
      tDate.setHours(0);
      tDate.setMinutes(0);
      this.dataSource.data = this.dataSource.data.filter((d) => {
        const created_at = new Date(d.created_at);
        return created_at >= fDate && created_at <= tDate;
      },
      );
    }
  }
  filter() {
    this.filterParams = '?';
    if (this.sender_individual != '') {
      this.filterParams = this.filterParams + 'sender_individual=' + this.sender_individual + '&';
    }
    if (this.pickup_location != '') {
      this.filterParams = this.filterParams + 'pickup_location=' + this.pickup_location.id + '&';
    }
    if (this.drop_location != '') {
      this.filterParams = this.filterParams + 'drop_location=' + this.drop_location.id + '&';
    }
    if (this.is_coperate_booking != '') {
      this.filterParams = this.filterParams + 'is_coperate_booking=' + this.is_coperate_booking + '&';
    }
    return this.filterParams;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  filterForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
  });

  get fromDate() {
    return this.filterForm.get('fromDate').value;
  }
  get toDate() {
    return this.filterForm.get('toDate').value;
  }
  // applyFilter1() {
  //   // this.pipe = new DatePipe('en');
  //     // console.log(this.fromDate.format('yyyy/MM/DD hh:mm'));
  //     var fDate = new Date(this.fromDate.format('yyyy/MM/DD hh:mm:ss'));
  //         var tDate = new Date(this.toDate.format('yyyy/MM/DD hh:mm:ss'));
  //         tDate.setDate(tDate.getDate()+1)
  //         tDate.setHours(0);
  //         tDate.setMinutes(0)
  //         // console.log(fDate, tDate)
  //         // this.dataSource.data = this
  //     this.dataSource.data = this.dataSource.data.filter(d => {
  //       // console.log(d.createdAt)
  //       d.createdAt = new Date(d.createdAt);
  //       // console.log(d.createdAt == fDate);
  //      return d.createdAt >= fDate && d.createdAt <= tDate
  //     }
  //       )
  //
  //       // return true;
  //     // }
  //   // console.log(this.dataSource.data);
  // }
  handlePageEvent(event: PageEvent) {
    this.pagination.page_no = event.pageIndex;
    this.pagination.page_size = event.pageSize;
    this.getconsignmentsList();
  }
  async getconsignmentsList() {
    const TOKEN = this.authService.getAuthToken();
    // console.log(API_URL)
    this.loading = true;
    const api = '/consignmentIndividualfilters';
    const params = this.filter();
    let consignment;
    await fetch(API_URL + api + params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          if (json.status == 400) {
            this.consignmentlists = [];
          } else {
            this.consignmentlists = json.data;
            this.dataSource = new MatTableDataSource(json.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.saveFiltersAndPaginationToStorage();
          }
        });
    this.loading = false;
    return this.consignmentlists;
  }

  applySavedFilters(savedFilters: any) {
    this.pickup_location = savedFilters.pickup_location;
    this.drop_location = savedFilters.drop_location;
    this.is_coperate_booking = savedFilters.is_coperate_booking;
  }

  saveFiltersAndPaginationToStorage() {
    const filters = {
      pickup_location: this.pickup_location,
      drop_location: this.drop_location,
      is_coperate_booking: this.is_coperate_booking,
    };
    localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(filters));
    localStorage.setItem(PAGINATION_STORAGE_KEY, JSON.stringify(this.pagination));
  }

  loadSavedFilters() {
    const savedFilters = JSON.parse(localStorage.getItem(FILTERS_STORAGE_KEY));
    const savedPagination = JSON.parse(localStorage.getItem(PAGINATION_STORAGE_KEY));
    if (savedFilters) {
      this.applySavedFilters(savedFilters);
    }
    if (savedPagination) {
      this.pagination = savedPagination;
    }
  }

  storedPage;
  pageSize: number
  onPaginateChange(event) {
    this.storedPage = event.pageIndex;
    this.pageSize = event.pageSize;
    localStorage.setItem('pageState', JSON.stringify({pageIndex: this.storedPage, pageSize: this.pageSize}));
  }

  formatDate(date) {
    const date2 = new Date(date);
    return (
      [
        date2.getFullYear(),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date2.getHours()),
        this.padTo2Digits(date2.getMinutes()),
      ].join(':')
    );
  }
  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  async Deletedata(id) {
    const TOKEN = this.authService.getAuthToken();
    this.loading = true;
    // console.log(id)
    const data = JSON.stringify({
      'id': id,
    });
    await fetch(API_URL + '/consignment/active/' + this.activestatus,
        {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
          body: data,
        })

    // Converting received data to JSON
        .then((response) => response.text());
    this.consignments = this.getconsignmentsList()
        .catch((error) => console.error('error', error));

    this.loading = false;
  }

  // billCreated;
  // billConsId;
  // billingPriceTaxableAmount;
  // billingPriceGrandTotal;
  // isInvoiced;
  // invoiceId;
  // async viewconsignmentById(id){
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/"+id, {
  //     method: "GET", headers: {
  //       "Accept": "application/json",
  //       "Content-Type":"application/json",
  //       "Authorization": "Bearer " + TOKEN
  //     }
  //   })
  //
  //     // Converting received data to JSON
  //     .then(response => response.json())
  //     .then(async json => {
  //       if (json.data[0].image1 != null) {
  //         this.getimage1(id)
  //       }
  //       if (json.data[0].image2 != null) {
  //         this.getimage2(id)
  //       }
  //       if (json.data[0].image3 != null) {
  //         this.getimage3(id)
  //       }
  //       if (json.data[0].image4 != null) {
  //         this.getimage4(id)
  //       }
  //       if (json.data[0].pod != null) {
  //         this.getpod(id)
  //       }
  //       this.consignmentlists = json;
  //       this.getTrack()
  //
  //
  //       this.billConsId = this.consignmentlists.data[0].id;
  //       await this.commonApi.getBillingPriceByConsignmentId(this.billConsId);
  //       this.billCreated = this.commonApi.isBillingCreated;
  //       this.billingPriceTaxableAmount = this.commonApi.BillingPriceLists.taxableAmount;
  //       this.billingPriceGrandTotal = this.commonApi.BillingPriceLists.grandTotal;
  //       this.isInvoiced = this.commonApi.BillingPriceLists.isInvoiceGenerated;
  //       this.invoiceId = this.commonApi.BillingPriceLists.invoiceId;
  //       this.getInvoiceById(this.invoiceId)
  //
  //     });
  //     // console.log(this.consignmentlists);
  //
  // }

  // InvoiceData;
  // getInvoiceById(id) {
  //   let TOKEN = this.authService.getAuthToken();
  //   try {
  //     const response = fetch(API_URL + "/getInvoice/" + id, {
  //       method: "GET",
  //       headers: {
  //         "Accept": "application/json",
  //         "Content-Type": "application/json",
  //         "Authorization": "Bearer " + TOKEN
  //       }
  //     })
  //       .then(response => response.json())
  //       .then(async json => {
  //         this.InvoiceData = json.data[0];
  //       })
  //   } catch (error) {
  //     console.error("Error fetching Invoice details: ", error);
  //   }
  // }

  // consignment_sts_update={
  //    consignmentId: null,
  //    status: null,
  //    comment: ""
  //  }
  // async update_con_status(){
  //   let TOKEN = this.authService.getAuthToken();
  //   // console.log(this.consignment_id);
  //   let sts_update={
  //     consignmentId: this.consignmentlists.data[0].consignmentNo,
  //     status:  this.consignment_sts_update.status,
  //     comment:  this.consignment_sts_update.comment
  //   }
  //   // this.consignment_sts_update.consignmentId=this.consignment_id;
  //   // this.consignment_sts_update.comment=;
  //   // console.log(sts_update);
  //   await fetch(API_URL + "/trackByConsignment",
  //   {
  //     method: "POST",
  //     body: JSON.stringify(sts_update),
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Accept": "application/json",
  //       "Authorization":"Bearer " + TOKEN
  //     }
  //   })
  //   // Converting received data to JSON
  //   .then(response => response.json())
  //   .then(json => {
  //     this.getTrack();
  //     // this.consignment_sts_update.status=null
  //     // this.consignment_sts_update.comment=null
  //     // alert("Status Changed")
  //     Swal.fire({
  //       position: 'center',
  //       icon: 'success',
  //       title: 'Status updated successfully',
  //       showConfirmButton: false,
  //       timer: 5000
  //     });
  //   });
  //   // console.log(this.consignment_sts_update.consignmentId)
  //
  // }
  //   manifestation;
  //   consignment_track;
  //   consignment_with_tracker;
  //   consignment_status
  //   origin
  //   destination
  //   async getTrack() {
  //     let TOKEN = this.authService.getAuthToken();
  //      await fetch(API_URL + "/tracking/consignment/" + this.consignmentlists.data[0].consignmentNo, {
  //       method: "GET",
  //       headers: {
  //         "Accept": "application/json",
  //         "Authorization": "Bearer " + TOKEN
  //       }
  //     })
  //       // Converting received data to JSON
  //       .then(response => response.json())
  //       .then(json => {
  //
  //         this.consignmentlists = json;
  //         this.consignment_track= json.data[0].trackingId.trackingStatusComments;
  //         this.origin = json.data[0].originLocation?.city.name
  //         this.destination = json.data[0].dropLocation?.city.name
  //         this.consignment_status=json.statuses
  //         this.consignment_sts_update.status=null
  //         this.consignment_sts_update.comment=""
  //         // console.log(this.consignment_track)
  //         this.consignment_track.sort((a, b) => a.id - b.id)
  //         // console.log(this.consignment_track)
  //       });
  //   }
  getTime(date) {
    if (date) {
      const date2 = new Date(date);
      return (
        [
          date2.getFullYear(

          ),
          this.padTo2Digits(date2.getMonth() + 1),
          this.padTo2Digits(date2.getDate()),
        ].join('-') +
        ' ' +
        [
          this.padTo2Digits(date2.getHours()),
          this.padTo2Digits(date2.getMinutes()),
        ].join(':')
      );
    } else {
      return '-';
    }
  }
  displayedColumns = ['consignmentNo', 'totalNoOfPackages', 'totalChargableWeight', 'orign', 'destination', 'status', 'isInvoiced', 'createdAt', 'Active']
  // viewchange(val,id){
  //   if(val=="view"){
  //     this.viewLoadById(id);
  //   }
  //   else if(val=="table"){
  //     this.getconsignmentsList();
  //   }
  // }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource
  // paginator: MatPaginator
  // ngAfterViewInit() {
  //   if(this.dataSource?.sort){
  //     this.dataSource.sort = this.sort;
  //   }
  //   if(this.dataSource?.paginator){
  //     this.dataSource.paginator = this.paginator;
  //   }
  // }
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  async getAllCustomers() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/totalcustomers/', {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })

    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          this.customers = json;
        });
  }

  sortedAirports
  async getAirports() {
    const TOKEN = this.authService.getAuthToken();
    // let airports = [];
    await fetch(API_URL + '/airport', {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
        .then((response) => response.json())
        .then((json) => {
          this.airports = json.data;
          this.sortedAirports = this.airports.sort((a, b) => a.city.name.localeCompare(b.city.name));
        });
  }

  async getRecord(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/report/' + id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then(async (response) => {
          const filename = 'consignmentReport' + id;
          const blob = response.blob();
          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(await blob);
          a.click();
        },
        );
  }

  // async saveChanges(){
  //   let TOKEN = this.authService.getAuthToken();
  //   let cons =
  //   {
  //     "totalNoOfPackages": this.consignment_details.totalNoOfPackages,
  //     "totalWeight": this.consignment_details.totalWeight,
  //     "totalChargableWeight": this.consignment_details.totalChargableWeight,
  //     "packages": this.changeable_packages,
  //     "totalConsignmentValue": this.consignment_details.totalConsignmentValue,
  //     "createdAt":this.consignment_details.createdAt,
  //     "invoiceCreatedDate": this.consignment_details.invoiceCreatedDate,
  //     "invoiceReferenceNo": this.consignment_details.invoiceReferenceNo,
  //     "remarks":this.consignment_details.remarks,
  //     "weightDivisor":this.consignment_details.weightDivisor
  //   }
  //
  //   await fetch(API_URL + "/consignment/"+this.consignment_id,
  //   {
  //     method: "PATCH",
  //     body: JSON.stringify(cons),
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": "Bearer " + TOKEN
  //     }
  //   })
  //   .then((response) => response.json())
  //   .then((result) => {
  //     Swal.fire({
  //       position: 'center',
  //       icon: 'success',
  //       title: 'Changes Saved',
  //       showConfirmButton: false,
  //       timer: 5000
  //     });
  //     // alert("Changes Saved");
  //     this.viewchange("table",0);
  //     // console.log(result);
  //   })
  //   .catch((error) => {
  //     Swal.fire({
  //       position: 'center',
  //       icon: 'error',
  //       title: 'Error',
  //       showConfirmButton: false,
  //       timer: 5000
  //     });
  //     console.error("Error", error);
  //     alert(error)
  //   });
  // }

  // async getconsignmentById() {
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/" + this.consignment_id,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": "Bearer " + TOKEN
  //       }
  //     })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       this.consignment_details = result.data[0];
  //       this.packages=this.consignment_details.packages
  //       this.changeable_packages=this.consignment_details.packages
  //      })
  //     .catch((error) => {
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'error',
  //         title: 'Error',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //     });
  //
  // }
  // requiredDocument: any = [];
  // getFiles(event) {
  //       this.requiredDocument[0] = event.target.files[0];
  //     }


  // async uploadimage1(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.image1 != null){
  //     alert("Upload Image 1")
  //   }else{
  //   var formdata = new FormData();
  //   formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //   formdata.append("image1",this.requiredDocument[0])
  //
  //   await fetch(API_URL + "/consignment/updateImage1", {
  //     method: "POST",
  //     body: formdata,
  //     headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "Accept":"application/json"
  //     }
  //   })
  //   .then((response) => response.text())
  //     .then((result) => {
  //       // console.log(result)
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Image 1 Uploaded successfully',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //     this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //   .catch(error => console.error('error', error));
  //     }
  // }
  // async uploadimage2(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.image2 != null){
  //     alert("Upload Image 2")
  //   }else{
  //   var formdata = new FormData();
  //   formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //   formdata.append("image2",this.requiredDocument[0])
  //
  //   await fetch(API_URL + "/consignment/updateImage2", {
  //     method: "POST",
  //     body: formdata,
  //     headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "Accept":"application/json"
  //     }
  //   })
  //   .then((response) => response.text())
  //     .then((result) => {
  //       // console.log(result)
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Image 2 Uploaded successfully',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //     this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //   .catch(error => console.error('error', error));
  //     }
  // }
  //
  // async uploadimage3(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.image3 != null){
  //     alert("Upload Image 3")
  //   }else{
  //   var formdata = new FormData();
  //   formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //   formdata.append("image3",this.requiredDocument[0])
  //
  //   await fetch(API_URL + "/consignment/updateImage3", {
  //     method: "POST",
  //     body: formdata,
  //     headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "Accept":"application/json"
  //     }
  //   })
  //   .then((response) => response.text())
  //     .then((result) => {
  //       // console.log(result)
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Image 3 Uploaded successfully',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //     this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //   .catch(error => console.error('error', error));
  //     }
  // }
  //
  // async uploadimage4(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.image4 != null){
  //     alert("Upload Image 4")
  //   }else{
  //   var formdata = new FormData();
  //   formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //   formdata.append("image4",this.requiredDocument[0])
  //
  //   await fetch(API_URL + "/consignment/updateImage4", {
  //     method: "POST",
  //     body: formdata,
  //     headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "Accept":"application/json"
  //     }
  //   })
  //   .then((response) => response.text())
  //     .then((result) => {
  //       // console.log(result)
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Image 4 Uploaded successfully',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //     this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //   .catch(error => console.error('error', error));
  //     }
  // }
  //
  //
  // async uploadPOD(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.pod != null){
  //     alert("Upload POD")
  //   }else{
  //   var formdata = new FormData();
  //   formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //   formdata.append("POD",this.requiredDocument[0])
  //
  //   await fetch(API_URL + "/consignment/updatePod", {
  //     method: "POST",
  //     body: formdata,
  //     headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       // "Accept":"multipart/form-data"
  //     }
  //   })
  //   .then((response) => response.text())
  //     .then((result) => {
  //       // console.log(result)
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'POD Uploaded successfully',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //     this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //   .catch(error => console.error('error', error));
  //     }
  // }
  // async uploadDatafiles(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.data_logger_file != null){
  //     alert("Upload dataLoggerFile")
  //   }else{
  //   var formdata = new FormData();
  //   formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //   formdata.append("DataLoggerFile",this.requiredDocument[0])
  //
  //   await fetch(API_URL + "/consignment/updateDataLoggerFile", {
  //     method: "POST",
  //     body: formdata,
  //     headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       // "Accept":"multipart/form-data"
  //
  //     }
  //   })
  //
  //   .then((response) => response.text())
  //     .then((result) => {
  //       // console.log(result)
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Data Logger Uploaded successfully',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //     this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //   .catch(error => console.error('error', error));
  // }
  //
  // }
  //
  // async uploadVechilefiles(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.vehicle_calibration_file != null){
  //     alert("Upload VehicleCalibrationFile")
  //   }else{
  //   var formdata = new FormData();
  //   formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //   formdata.append("VehicleCalibrationFile",this.requiredDocument[0])
  //
  //   await fetch(API_URL + "/consignment/updateVehicleCalibrationFile", {
  //     method: "POST",
  //     body: formdata,
  //     headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       // "Accept":"multipart/form-data"
  //     }
  //   })
  //
  //   .then((response) => response.text())
  //     .then((result) => {
  //       // console.log(result)
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Vehicle Calibration Uploaded successfully',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //     this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //   .catch(error => console.error('error', error));
  // }
  //
  // }
  //
  // async uploadOthersfiles(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.other_documents_file != null){
  //     alert("Upload OtherDocumentsFile")
  //   }else{
  //   var formdata = new FormData();
  //   formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //   formdata.append("OtherDocumentsFile",this.requiredDocument[0])
  //
  //   await fetch(API_URL + "/consignment/updateOtherDocumentsFile", {
  //     method: "POST",
  //     body: formdata,
  //     headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       // "Accept":"multipart/form-data"
  //     }
  //   })
  //
  //   .then((response) => response.text())
  //     .then((result) => {
  //       // console.log(result)
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'OtherDocuments Uploaded successfully',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //     this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //   .catch(error => console.error('error', error));
  // }
  //
  // }
  //
  // async uploadTemp(){
  //   let TOKEN = this.authService.getAuthToken();
  //   if(this.temperature_record_file != null){
  //     alert("Upload TemperatureRecordFile")
  //   }else{
  //   var formdata = new FormData();
  //   formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //   formdata.append("TemperatureRecordFile",this.requiredDocument[0])
  //
  //   await fetch(API_URL + "/consignment/updateTemperatureRecordFile", {
  //     method: "POST",
  //     body: formdata,
  //     headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       // "Accept":"multipart/form-data"
  //     }
  //   })
  //
  //   .then((response) => response.text())
  //     .then((result) => {
  //       // console.log(result)
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'TemperatureRecord Uploaded successfully',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //     this.viewchange('view',this.consignmentlists.data[0].id);
  //       })
  //   .catch(error => console.error('error', error));
  // }
  //
  // }
  // async getimage1(id){
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getImage1/"+ id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //   .then(response => response.blob())
  //   .then(result =>{
  //     let objectURL = URL.createObjectURL(result);
  //     // console.log(objectURL);
  //       this.image_1 =this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //       // console.log(this.image_1);
  //   })
  //   .catch(error => console.error('error', error));
  //
  // }
  //
  // async getimage2(id){
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getImage2/"+ id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //   .then(response => response.blob())
  //   .then(result =>{
  //     let objectURL = URL.createObjectURL(result);
  //       this.image_2 =this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //   })
  //   .catch(error => console.error('error', error));
  //
  // }
  // async getimage3(id){
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getImage3/"+ id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //   .then(response => response.blob())
  //   .then(result =>{
  //     let objectURL = URL.createObjectURL(result);
  //       this.image_3 =this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //   })
  //   .catch(error => console.error('error', error));
  //
  // }
  // async getimage4(id){
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getImage4/"+ id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //   .then(response => response.blob())
  //   .then(result =>{
  //     let objectURL = URL.createObjectURL(result);
  //       this.image_4 =this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //   })
  //   .catch(error => console.error('error', error));
  //
  // }
  // async getpod(id){
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getPod/"+ id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //   .then(response => response.blob())
  //   .then(result =>{
  //     let objectURL = URL.createObjectURL(result);
  //       this.POD =this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //   })
  //   .catch(error => console.error('error', error));
  //
  // }
  //
  // async getvehiclecalibration(){
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getVehicleCalibrationFile/"+ this.consignmentlists.data[0].id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //   .then(async (response) => {
  //     let filename = "VehicleCalibrationFile" + this.consignmentlists.data[0].id;
  //     let blob = response.blob();
  //     let a = document.createElement("a");
  //     a.download = filename;
  //     a.href = window.URL.createObjectURL(await blob);
  //     a.click();
  //   })
  //   .catch(error => console.error('error', error));
  //
  // }
  //
  // async getDatalogger(){
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getDataLoggerFile/"+ this.consignmentlists.data[0].id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //   .then(async (response) => {
  //     let filename = "DataloggerFile" + this.consignmentlists.data[0].id;
  //     let blob = response.blob();
  //     let a = document.createElement("a");
  //     a.download = filename;
  //     a.href = window.URL.createObjectURL(await blob);
  //     a.click();
  //   })
  //   .catch(error => console.error('error', error));
  //
  // }
  //
  // async getotherdocuments(){
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getOtherDocumentsFile/"+ this.consignmentlists.data[0].id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //   .then(async (response) => {
  //     let filename = "OtherDocumentsFile" + this.consignmentlists.data[0].id;
  //     let blob = response.blob();
  //     let a = document.createElement("a");
  //     a.download = filename;
  //     a.href = window.URL.createObjectURL(await blob);
  //     a.click();
  //   })
  //   .catch(error => console.error('error', error));
  //
  // }
  //
  // async gettemp(){
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getTemperatureRecordFile/"+ this.consignmentlists.data[0].id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //   .then(async (response) => {
  //     let filename = "TemperatureRecordFile" + this.consignmentlists.data[0].id;
  //     let blob = response.blob();
  //     let a = document.createElement("a");
  //     a.download = filename;
  //     a.href = window.URL.createObjectURL(await blob);
  //     a.click();
  //   })
  //   .catch(error => console.error('error', error));
  //
  // }
  // async CsvDownload(){
  //   let TOKEN = this.authService.getAuthToken();
  //   // console.log(API_URL)
  //   let loads=[];
  //   let api= "/consignmentfilters/csv"
  //   let params = this.filter()
  //   await fetch(API_URL + api+params, {
  //     method: "GET", headers: {
  //       "Content-Type":"text.csv",
  //       "Authorization": "Bearer " + TOKEN
  //     }
  //   })
  //
  //     .then(async (response) => {
  //       let filename = "ListOfConsignments" ;
  //       let blob :any = await response.blob();
  //       let a = document.createElement("a");
  //       a.download = filename;
  //       a.href = window.URL.createObjectURL(blob);
  //       a.click();
  //     })
  //     .catch(error => console.error('error', error));
  // }

  exportToCSV() {
    const csvData = [];
    const headers = ['Consignment No', 'Consignor Company', 'Consignor Name', 'Consignee Company', 'Consignee Name', 'Consignee Phone', 'Consignee Address', 'Origin', 'Destination',
      'Product Type', 'Product Mode', 'No.of Packages', 'Actual Weight(KGS)', 'Total Chargable Weight(KGS)', 'Status', 'POD', 'Temperature Range', 'Container Size', 'Airline', 'Consignment Value(INR)',
      'Weight Divisor', 'AWB Number', 'Pickup Date', 'Delivery Date', 'Is Insuranced',

      'freightCharges(INR)', 'ahcCharges(INR)', 'mawbCharges(INR)', 'iclCnCharges(INR)', 'pickupCharges(INR)', 'deliveryCharges', 'iclHandlingCharges(INR)',
      'peakHandlingCharges(INR)', 'loadingCharges(INR)', 'unloadingCharges(INR)', 'fuelSurcharges(INR)', 'packingMaterialCharges(INR)', 'dataLoggerCharges(INR)',
      'warehouseCharges(INR)', 'detentionCharges(INR)', 'dgCharges(INR)', 'demurrageCharges(INR)', 'miscellaneousCharges(INR)', 'discountAmount(INR)',
      'fovPercentage(%)', 'fov(INR)', 'taxableAmount(INR)', 'taxablePercentage(%)', 'taxAmount(INR)', 'grandTotal(INR)', 'remarks',

      'gst', 'is_invoiced', 'invoice_no', 'co2 emissions',
    ];
    csvData.push(headers);

    function formatForCSV(value) {
      if (value === null || value === undefined) {
        return '';
      }
      const escapedValue = value.toString().replace(/"/g, '""');
      if (escapedValue.includes(',') || escapedValue.includes('\n')) {
        return `"${escapedValue}"`;
      }
      return escapedValue;
    }

    this.dataSource.filteredData.forEach((row) => {
      let pod;
      let weightdivisor;
      let Temp;
      let containerSize;
      if (row.weight_divisor == 1) {
        weightdivisor = '6000';
      } else if (row.weightdivisor == 2) {
        weightdivisor = '5000';
      } else {
        weightdivisor = row.weight_divisor;
      }
      if (row.required_temperature == null) {
        Temp = '-';
      } else {
        Temp = row.required_temperature;
      }
      if (row.container_size == null) {
        containerSize = '-';
      } else {
        containerSize = row.container_size;
      }
      if (row.pod == null) {
        pod = 'No';
      } else {
        pod = 'Yes';
      }


      let pickUpDate;
      let deliveryDate;
      if (row.pickup_date) {
        pickUpDate = this.formatDate(row.pickup_date);
      } else {
        pickUpDate = '';
      }

      if (row.delivery_date) {
        deliveryDate = this.formatDate(row.delivery_date);
      } else {
        deliveryDate = '';
      }


      const data = [
        formatForCSV(row.consignment_no),
        formatForCSV(row.customer_name),
        formatForCSV(row.individual_name),
        formatForCSV(row.receiver_company),
        formatForCSV(row.receiver_name),
        formatForCSV(row.receiver_phone),
        formatForCSV(row.receiver_address),
        formatForCSV(row.cityname),
        formatForCSV(row.receivercity),
        formatForCSV(row.product_type),
        formatForCSV(row.product_mode),
        formatForCSV(row.no_of_packages),
        formatForCSV(row.totalWeight),
        formatForCSV(row.total_chargable_weight),
        formatForCSV(row.name),
        formatForCSV(pod),
        formatForCSV(Temp) + ' °C',
        formatForCSV(containerSize),
        formatForCSV(row.airline),
        formatForCSV(row.consignment_value),
        formatForCSV(weightdivisor),
        formatForCSV(row.awb_number),
        formatForCSV(pickUpDate),
        formatForCSV(deliveryDate),
        formatForCSV(row.is_insured),


        formatForCSV(row.billingFreightCharges),
        formatForCSV(row.billingAhcCharges),
        formatForCSV(row.billingMawbCharges),
        formatForCSV(row.billingIclCnCharges),
        formatForCSV(row.billingPickupCharges),
        formatForCSV(row.billingDeliveryCharges),
        formatForCSV(row.billingIclHandlingCharges),
        formatForCSV(row.billingPeakHandlingCharges),
        formatForCSV(row.billingLoadingCharges),
        formatForCSV(row.billingUnloadingCharges),
        formatForCSV(row.billingFuelSurcharges),
        formatForCSV(row.billingPackingMaterialCharges),
        formatForCSV(row.billingDataLoggerCharges),
        formatForCSV(row.billingWarehouseCharges),
        formatForCSV(row.billingDetentionCharges),
        formatForCSV(row.billingDgCharges),
        formatForCSV(row.billingDemurrageCharges),
        formatForCSV(row.billingMiscellaneousCharges),
        formatForCSV(row.billingDiscountedAmount),
        formatForCSV(row.billingInsurancePercentage),
        formatForCSV(row.billingFov),
        formatForCSV(row.billingTaxableAmount),
        formatForCSV(row.billingTaxablePercentage),
        formatForCSV(row.billingTaxAmount),
        formatForCSV(row.billingGrandTotal),
        formatForCSV(row.billingRemarks),

        formatForCSV(row.billingIsGst),
        formatForCSV(row.isInvoiced),
        formatForCSV(row.invoiceNo),
      ];
      csvData.push(data);
    });

    const csv = csvData.map((e) => e.join(',')).join('\n');
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
    const currentDate = new Date();
    const formattedDate = this.formatDateForFilename(currentDate);
    saveAs(blob, 'MIS - ' + formattedDate + '.csv');
  }

  formatDateForFilename(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}-${month}-${year} (${hours}.${minutes})`;
  }

  gotoConsignmentView(id: any, consignment_no: any) {
    this.route.navigate(['/consignmentView', id]);
  }

  refreshing = false;
  refresh() {
    this.refreshing = true;
    this.fromInput.value = '';
    this.toInput.value = '';
    this.is_coperate_booking = '';
    this.pickup_location = '';
    this.drop_location = '';
    this.consignment_id = '';
    this.search = '';
    this.getconsignmentsList();
    this.saveFiltersAndPaginationToStorage();
  }

  city

  displayFn(city: Cities): String {
    return city?.name ? city.name : '';
  }
  displayFn1(city: Cities): String {
    return city?.name ? city.name : '';
  }
}
