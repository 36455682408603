<div class="col-md-14">
  <container-element [ngSwitch]="pageview">
    <some-element *ngSwitchCase="'table'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            <b style="color:blue;">CITIES</b>
          </div>
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-primary" (click)="viewchange('add')" *ngIf="!displaytoAccountant">Add</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="navigateToGS()">CITY Mapping</div>
          </div>

        </div>
        <div class="row p-4">

          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>State Name</mat-label>
<!--              <mat-select matNativeControl [(ngModel)]="state_id">-->
<!--                <mat-option value="">-->
<!--                  All-->
<!--                </mat-option>-->
<!--                <mat-option *ngFor="let sta of state" [value]="sta.id" (click)="getCities1(sta.id)">-->
<!--                  {{ sta.name }}-->
<!--                </mat-option>-->
<!--              </mat-select>-->
              <input matInput placeholder="Search State" [(ngModel)]="state_id" [matAutocomplete]="auto3">
              <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayStateFn">
                <mat-option *ngFor="let sta of statesList | async | filter: state_id" [value]="sta" (click)="getCities1(sta.id)">
                  {{ sta.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>City</mat-label>
              <input matInput placeholder="Search City" [(ngModel)]="cityId" [matAutocomplete]="auto2">
              <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn2">
                <mat-option *ngFor="let city of citiesList | async | filter: cityId" [value]="city">
                  {{ city.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label> IB Airport</mat-label>
              <input type="text" placeholder="Inbound Airport" aria-label="Number" matInput
                     [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="ibAirport">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let cit of filteredAirports | async" [value]="cit">
                  {{ cit.city.name }} - {{ cit.iataCode }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-hint class="text-bold text-success" *ngIf="originsearch?.name != null"
                      style="position: absolute; margin-top: 55px; font-size: smaller;">
              {{ originsearch?.name }} - {{ originsearch?.iataCode }}
            </mat-hint>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label> OB Airport</mat-label>
              <input type="text" placeholder="Outbound Airport" aria-label="Number" matInput
                     [formControl]="myControl2" [matAutocomplete]="auto1" [(ngModel)]="obAirport">
              <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn1">
                <mat-option *ngFor="let cit of filteredAirports2 | async" [value]="cit">
                  {{ cit.city.name }} - {{ cit.iataCode }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-hint class="text-bold text-success" *ngIf="Destinationsearch?.name != null"
                      style="position: absolute; margin-top: 55px; font-size: smaller;">
              {{ Destinationsearch?.name }} - {{ Destinationsearch?.iataCode }}
            </mat-hint>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Door Supported</mat-label>
              <mat-select matNativeControl [(ngModel)]="is_door_supported">
                <mat-option value="">
                  All
                </mat-option>
                <mat-option value="1">
                  Active
                </mat-option>
                <mat-option value="0">
                  In Active
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-1">
            <button class="btn btn-primary mt-4" (click)="tablefilter()">Find</button>
          </div>
          <div class="col-md-1">
            <button class="btn btn-secondary mt-4" (click)="reset()">Reset</button>
          </div>
          <div class="col-md-1 row">
            <button class="btn btn-success m-auto mt-4" (click)="exportToCSV()">
              Report
            </button>
          </div>
        </div>
        <!-- <div class="container">
            <div class="row">
                <div class="">
                    <div class="table-responsive">
                        <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center">City Name</th>
                                    <th class="text-center">State</th>
                                    <th class="text-center">Country</th>
                                    <th class="text-center">Airport</th>
                                    <th class="text-center">Air Supported</th>
                                    <th class="text-center">Door Supported</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let con of CitiesList" [ngClass]="con.isAirportSupported == false && con.isDoorSupported==false? 'red':'white'">
                                    <td class="text-center">
                                        <a [routerLink]="" (click)="viewchange('view')">
                                            {{con.name}}
                                        </a>
                                    </td>
                                    <td class="text-center">
                                        {{con.state_id?.name}}
                                    </td>
                                    <td class="text-center">
                                        {{con.state_id.country.name}}
                                    </td>
                                    <td class="text-center">
                                        {{"Airpot Name"}}
                                    </td>
                                    <td class="text-center" *ngIf="con.isAirportSupported==true" >
                                        {{"Active"}}
                                    </td>
                                    <td class="text-center" *ngIf="con.isAirportSupported==false">
                                        {{"In Active"}}
                                    </td>
                                    <td class="text-center" *ngIf="con.isDoorSupported==true">
                                        {{"Active"}}
                                    </td>
                                    <td class="text-center" *ngIf="con.isDoorSupported==false">
                                        {{"In Active"}}
                                    </td>
                                    <td class="text-center">
                                        <div role="group" class="btn-group-sm btn-group m-1">
                                            <button class="btn-shadow btn btn-primary" matTooltip="Edit"
                                                (click)="viewchange1('edit',con)"><i
                                                    class="lnr-pencil"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <mat-paginator (page)="handlePageEvent($event)" [length]="CitiesList?.totalElements" [pageSize]="CitiesList?.numberOfElements"
                            [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
                            [pageIndex]="Cities?.number" aria-label="Select page">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="">
          <div *ngIf="!loading" style="overflow-x: auto">
            <ng-container *ngIf="CitiesList?.content?.length > 0; else noRecordsMessage">
              <table class="w-100" mat-table [dataSource]="CitiesList?.content" matSort
                     (matSortChange)="announceSortChange($event)">
                <ng-container matColumnDef="City">
                  <th class="text-center" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by pincode" style="width: 16%;">City
                  </th>
                  <td class="text-center" mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>

                <ng-container matColumnDef="state">
                  <th class="text-center" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by state">State
                  </th>
                  <td class="text-center" mat-cell *matCellDef="let element">
                    {{ element?.state_id?.name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="country">
                  <th class="text-center" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by country">
                    Country
                  </th>
                  <td class="text-center" mat-cell *matCellDef="let element">
                    {{ element?.state_id.country.name }}
                  </td>
                </ng-container>

                <!-- <ng-container matColumnDef="Airport">
            <th class="text-center" mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by pincode">Airport</th>
            <td class="text-center" mat-cell *matCellDef="let element">
                {{element?.supportedAirportId?.iataCode}}
            </td>
        </ng-container> -->
                <ng-container matColumnDef="ibairport">
                  <th class="text-center" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by pincode">IB Airport
                  </th>
                  <td class="text-center" mat-cell *matCellDef="let element">
                    {{ element?.ibAirport?.iataCode }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="obairport">
                  <th class="text-center" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by pincode">OB Airport
                  </th>
                  <td class="text-center" mat-cell *matCellDef="let element">
                    {{ element?.obAirport?.iataCode }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="originairport">
                  <th class="text-center" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by pincode">Origin Airport
                  </th>
                  <td class="text-center" mat-cell *matCellDef="let element">
                    {{ element?.originAirport?.iataCode }}
                  </td>
                </ng-container>
                <!-- <ng-container matColumnDef="Airsupported">
            <th class="text-center" mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by pincode">Air Supported</th>
            <td class="text-center" mat-cell *matCellDef="let element">
                <div *ngIf="element.isAirportSupported== true">Active</div>
                <div *ngIf="element.isAirportSupported== false">InActive</div>
            </td>
        </ng-container> -->
                <ng-container matColumnDef="Doorsupported">
                  <th class="text-center" mat-header-cell *matHeaderCellDef
                      sortActionDescription="Sort by ooa">Door Supported
                  </th>
                  <td class="text-center" mat-cell *matCellDef="let element">
                    <div *ngIf="element.isDoorSupported== true">Active</div>
                    <div *ngIf="element.isDoorSupported== false">InActive</div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Active">
                  <th mat-header-cell *matHeaderCellDef [style.display]="displaytoAccountant ? 'none' : 'table-cell'">
                    Action
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex">
                      <div role="group" class="btn-group-sm btn-group m-1" *ngIf="!displaytoAccountant">
                        <button class="btn-shadow btn btn-primary" matTooltip="Edit"
                                (click)="viewchange1('edit',element)"><i
                          class="lnr-pencil"></i></button>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    [ngClass]="row.isDoorSupported == false && row.isAirportSupported == false? 'red':'white'">
                </tr>
              </table>
            </ng-container>
          </div>
          <mat-paginator [length]="CitiesList?.totalElements" [pageIndex]="CitiesList?.number"
                         [pageSizeOptions]="[10, 20, 50]" [pageSize]="pageSize" (page)="handlePageEvent($event)"
                         showFirstLastButtons>
          </mat-paginator>
          <ng-template #noRecordsMessage>
            <h1 class="text-center fw-bold">No Records Available..!</h1>
          </ng-template>
          <ngx-loading [show]="loading"
                       [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                       [template]="loadingTemplate"></ngx-loading>
        </div>

      </div>
    </some-element>
    <some-element *ngSwitchCase="'add'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            <b style="color: blue;">ADD CITY</b>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="standard">
                <mat-label>
                  City Name<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="City Name" [(ngModel)]="editparams.cityName"
                       aria-label="Number" matInput (blur)="nameExistCreate()">
                <mat-hint class="text-danger fw-bold" *ngIf="nameExist==true">City Name Exist</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="standard">
                <mat-label>Country<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl [(ngModel)]="editparams.countryId">
                  <mat-option>SELECT</mat-option>
                  <mat-option *ngFor="let con of countries |async" [value]="con.id"
                              (click)="getRecverStates()">
                    {{ con.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="standard">
                <mat-label>State<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl [(ngModel)]="editparams.stateId">
                  <mat-option>SELECT</mat-option>
                  <mat-option *ngFor="let con of states1 |async" [value]="con.id">
                    {{ con.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <br>
            <!-- <div class="col-md-6">
                <mat-form-field appearance="standard">
                    <mat-label>Supported Airport<span class="text-danger">*</span></mat-label>
                    <mat-select matNativeControl  [(ngModel)]="editparams.supportedAirportId">
                        <mat-option>SELECT</mat-option>
                        <mat-option *ngFor="let air of airports |async" [value]="air.id">
                            {{air.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->

            <div class="col-md-3">
              <mat-form-field appearance="standard">
                <mat-label>IB Supported Airport<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl [(ngModel)]="editparams.ibAirport">
                  <mat-option>SELECT</mat-option>
                  <mat-option *ngFor="let air of airports |async" [value]="air.id">
                    {{ air.name }}-({{ air.iataCode }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="standard">
                <mat-label>OB Supported Airport<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl [(ngModel)]="editparams.obAirport">
                  <mat-option>SELECT</mat-option>
                  <mat-option *ngFor="let air of airports |async" [value]="air.id">
                    {{ air.name }}-({{ air.iataCode }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="standard">
                <mat-label>Origin Airport</mat-label>
                <mat-select matNativeControl [(ngModel)]="editparams.originAirport">
                  <mat-option>SELECT</mat-option>
                  <mat-option *ngFor="let air of airports |async" [value]="air.id">
                    {{ air.name }}-({{ air.iataCode }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- <div class="col-md-3">
                <h6><b>Air Supported<span class="text-danger">*</span></b></h6>
                <mat-radio-group aria-label="Select Air" [(ngModel)]="edit.isAirSupported">
                    <mat-radio-button value=1>Active</mat-radio-button>
                    <br>
                    <mat-radio-button value=0>In Active</mat-radio-button>
                </mat-radio-group>
            </div> -->
            <div class="col-md-3">
              <h6><b>Door Supported<span class="text-danger">*</span></b></h6>
              <mat-radio-group aria-label="Select Door" [(ngModel)]="edit.isDoorSupported">
                <mat-radio-button value=1>Active</mat-radio-button>
                <br>
                <mat-radio-button value=0>In Active</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-md-3">
              <h6><b>IB<span class="text-danger">*</span></b></h6>
              <mat-radio-group aria-label="Select IB" [(ngModel)]="edit.activeIb">
                <mat-radio-button value=1>Active</mat-radio-button>
                <br>
                <mat-radio-button value=0>In Active</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-md-3">
              <h6><b>OB<span class="text-danger">*</span></b></h6>
              <mat-radio-group aria-label="Select OB" [(ngModel)]="edit.activeOb">
                <mat-radio-button value=1>Active</mat-radio-button>
                <br>
                <mat-radio-button value=0>In Active</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');saveFiltersAndPaginationToStorage()">
              Cancel
            </div>
            <div class="mb-2 me-2 btn btn-primary" (click)="Createcity()">Add</div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'edit'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            <b style="color: blue;">EDIT CITY DETAILS</b>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="standard">
                <mat-label>
                  City Name<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="City Name" [(ngModel)]="editparams.cityName" (blur)="nameExistUpdate()"
                       aria-label="Number" matInput>
                       <mat-hint class="text-danger fw-bold" *ngIf="nameExist==true">City Name Exist</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="standard">
                <mat-label>Country<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl [(ngModel)]="editparams.countryId"
                            (ngModelChange)="getRecverStates1($event)">
                  <mat-option>SELECT</mat-option>
                  <mat-option *ngFor="let con of countries |async" [value]="con.id">
                    {{ con.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="standard">
                <mat-label>State<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl [(ngModel)]="editparams.stateId">
                  <mat-option>SELECT</mat-option>
                  <mat-option *ngFor="let con of states1 |async" [value]="con.id">
                    {{ con.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- <div class="col-md-6">
                <mat-form-field appearance="standard">
                    <mat-label>Supported Airport</mat-label>
                    <mat-select matNativeControl  [(ngModel)]="supporte_airport_id">
                        <mat-option>SELECT</mat-option>
                        <mat-option *ngFor="let air of airports |async" [value]="air.id">
                            {{air.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->

            <div class="col-md-3">
              <mat-form-field appearance="standard">
                <mat-label>IB Supported Airport<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl [(ngModel)]="ib_airport_id" required>
                  <mat-option [value]="null" disabled selected>SELECT</mat-option>
                  <mat-option *ngFor="let air of airports | async" [value]="air.id">
                    {{ air.name }}-({{ air.iataCode }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="standard">
                <mat-label>OB Supported Airport<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl [(ngModel)]="ob_airport_id" required>
                  <mat-option [value]="null" disabled selected>SELECT</mat-option>
                  <mat-option *ngFor="let air of airports | async" [value]="air.id">
                    {{ air.name }}-({{ air.iataCode }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-form-field appearance="standard">
                <mat-label>Origin Airport</mat-label>
                <mat-select matNativeControl [(ngModel)]="origin_airport">
                  <mat-option>SELECT</mat-option>
                  <mat-option *ngFor="let air of airports |async" [value]="air.id">
                    {{ air.name }}-({{ air.iataCode }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
<!--              <button mat-icon-button *ngIf="origin_airport!=null"-->
<!--                      (click)="origin_airport=null">-->
<!--                <mat-icon>close</mat-icon>-->
<!--              </button>-->
            </div>

            <!-- <div class="col-md-3">
                <h6><b>Air Supported</b></h6>
                <mat-radio-group aria-label="Select Air" [(ngModel)]="edit.isAirSupported">
                    <mat-radio-button value=1>Active</mat-radio-button>
                    <br>
                    <mat-radio-button value=0>In Active</mat-radio-button>
                </mat-radio-group>
            </div> -->
            <div class="col-md-3">
              <h6><b>Door Supported</b><span class="text-danger">*</span></h6>
              <mat-radio-group aria-label="Select Door" [(ngModel)]="edit.isDoorSupported">
                <mat-radio-button value=1>Active</mat-radio-button>
                <br>
                <mat-radio-button value=0>In Active</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-md-3">
              <h6><b>IB</b><span class="text-danger">*</span></h6>
              <mat-radio-group aria-label="Select IB" [(ngModel)]="edit.activeIb">
                <mat-radio-button value=1>Active</mat-radio-button>
                <br>
                <mat-radio-button value=0>In Active</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-md-3">
              <h6><b>OB</b><span class="text-danger">*</span></h6>
              <mat-radio-group aria-label="Select OB" [(ngModel)]="edit.activeOb">
                <mat-radio-button value=1>Active</mat-radio-button>
                <br>
                <mat-radio-button value=0>In Active</mat-radio-button>
              </mat-radio-group>
            </div>

          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');saveFiltersAndPaginationToStorage()">
              Cancel
            </div>
            <div class="mb-2 me-2 btn btn-primary" (click)="Updatecity()">Update</div>
          </div>
        </div>
      </div>
    </some-element>
    <!-- -------------------------------------------------------------------------------------------------------------- -->


    <some-element *ngSwitchCase="'view'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            View City
          </div>
        </div>
        <div class="container m-2">
          <div class="row">
            <div class="col-md-3 fw-bold">City Name</div>
            <div class="col-md-4"> Gujarat</div>
          </div>
          <div class="row ">
            <div class="col-md-3 fw-bold">Country Name</div>
            <div class="col-md-4"> India</div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');tablefilter()">Back</div>
          </div>
        </div>
      </div>
    </some-element>
  </container-element>
</div>
