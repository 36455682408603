<div class="col-md-8">
	<container-element [ngSwitch]="pageview">
		<some-element *ngSwitchCase="'table'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						<b style="color: blue;">COUNTRIES</b>
					</div>
					<div class="btn-actions-pane-right p-2" *ngIf="!displaytoAccountant">
						<div class="mb-2 me-2 btn btn-primary" (click)="viewchange('add')">Add</div>
					</div>
				</div>

				<div class="container">
					<div class="row">
						<div class="">
							<div class="table-responsive">
								<table class="align-middle text-truncate mb-0 table table-borderless table-hover" [dataSource]="dataSource">
									<thead>
										<tr>
											<th class="text-center">Country Name</th>
											<th class="text-center">Country Code</th>
											<th class="text-center">Currency</th>
											<th class="text-center" *ngIf="!displaytoAccountant">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let con of countries | async">
											<td class="text-center">
												<a [routerLink]="" (click)="viewsection('view',con)">
													{{con.name}}
												</a>  
											</td>
											<td class="text-center">
												{{con.code}}
											</td>
											<td class="text-center">
												{{con.currency}}
											</td>
											<td class="text-center" *ngIf="!displaytoAccountant">
												<div role="group" class="btn-group-sm btn-group">
													<button class="btn-shadow btn btn-primary" matTooltip="Edit" (click)="viewchange1('edit',con)"><i class="lnr-pencil"></i></button>
												</div>
												   
	                                        <div role="group" *ngIf="(con?.is_active != false)"class="btn-group-sm btn-group m-1">
		                                    <span class="material-icons btn-shadow btn btn-danger" matTooltip="DeactivateCountry" (click)="DeactivateCountry(con)">remove_circle</span>
	                                        </div>
	                                        <div role="group" *ngIf="(con?.is_active == false)"class="btn-group-sm btn-group m-1">
		                                    <span class="material-icons btn-shadow btn btn-success" matTooltip="ActivateCountry" (click)="ActivateCountry(con)"> add_circle </span>
	                                       </div>

												
											</td>
										</tr>
									</tbody>
								</table>
								<!-- <mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
									[showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
									[pageIndex]="pageIndex" aria-label="Select page">
								</mat-paginator> -->
								<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
								<!-- Add this to your template -->
<!-- <mat-paginator [length]="data" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)">
</mat-paginator> -->

							</div>
						</div>
					</div>
				</div>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'add'">
				<div class="card">
					<div class="card-header">

						<div class="card-header-title font-size-lg text-capitalize fw-normal">
							<b style="color: blue;">ADD COUNTRY</b>
						</div>
						
					</div>

					<div class="container">
						<div class="row">
							<div class="col-md-6">
								<mat-form-field appearance="standard">
									<mat-label>
										Country Name <span class="text-danger">*</span>
									</mat-label>
									<input type="text" placeholder="Country Name" aria-label="Number" matInput [(ngModel)]="addCountry.name" >

								</mat-form-field>
							</div>
							<div class="col-md-6">
								<mat-form-field appearance="standard">
									<mat-label>
										Currency <span class="text-danger">*</span>
									</mat-label>
									<input type="text" placeholder="Currency" aria-label="Number" matInput [(ngModel)]="addCountry.currency">

								</mat-form-field>
							</div>
							<div class="col-md-6">
								<mat-form-field appearance="standard">
									<mat-label>
										Country Code <span class="text-danger">*</span>
									</mat-label>
									<input type="text" placeholder="Country code" aria-label="Number" matInput [(ngModel)]="addCountry.code" >
								</mat-form-field>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<div class="btn-actions-pane-right p-2">
							<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
							<div class="mb-2 me-2 btn btn-primary" (click)="Createcountry()">Add</div>
						</div>
					</div>
				</div>
			</some-element>

			<some-element *ngSwitchCase="'edit'">
				<div class="card">
					<div class="card-header">

						<div class="card-header-title font-size-lg text-capitalize fw-normal">
							<b style="color:blue;">EDIT COUNTRY DETAILS</b>
						</div>
						
					</div>

					<div class="container">
						<div class="row">
							<div class="col-md-6">
								<mat-form-field appearance="standard">
									<mat-label>
										Country Name <span class="text-danger">*</span>
									</mat-label>
									<input type="text" placeholder="Country Name" aria-label="Number" matInput  [(ngModel)]="UpdateCountry.name" >

								</mat-form-field>
							</div>
							<div class="col-md-6">
								<mat-form-field appearance="standard">
									<mat-label>
										Currency <span class="text-danger">*</span>
									</mat-label>
									<input type="text" placeholder="Currency" aria-label="Number" matInput [(ngModel)]="UpdateCountry.currency">

								</mat-form-field>
							</div>
							<div class="col-md-6">
								<mat-form-field appearance="standard">
									<mat-label>
										Country Code <span class="text-danger">*</span>
									</mat-label>
									<input type="text" placeholder="Country Code" aria-label="Number" matInput [(ngModel)]="UpdateCountry.code">
								</mat-form-field>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<div class="btn-actions-pane-right p-2">
							<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
							<div class="mb-2 me-2 btn btn-primary" (click)="Updatecountry()">Save</div>
						</div>
					</div>
				</div>
			</some-element>
			<some-element *ngSwitchCase="'view'">
				<div class="card">
					<div class="card-header">
						<div class="card-header-title font-size-lg text-capitalize fw-normal">
							View Country
						</div>
					</div>
					<div class="container m-2">
						<div class="row">
							<div class="col-md-3 fw-bold">Country Name</div>
							<div class="col-md-4"> {{this.viewcountry.name}} </div>
						</div>
						<div class="row ">
							<div class="col-md-3 fw-bold">Country Code</div>
							<div class="col-md-4"> {{this.viewcountry.code}} </div>
						</div>
						<div class="row ">
							<div class="col-md-3 fw-bold">Currency</div>
							<div class="col-md-4"> {{this.viewcountry.currency}} </div>
						</div>
					</div>
					<div class="card-footer">
						<div class="btn-actions-pane-right p-2">
							<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Back</div>
						</div>
					</div>
				</div>
			</some-element>
	</container-element>
</div>