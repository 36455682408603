<div class="col-md-10">
	<container-element [ngSwitch]="pageview">
		<some-element *ngSwitchCase="'table'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						Airport Pricing Schedules
					</div>
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-primary" (click)="viewchange('add')">Add</div>
					</div>
				</div>

				<div class="container">
					<div class="row">
						<div class="">
							<div class="table-responsive">
								<table class="align-middle text-truncate mb-0 table table-borderless table-hover">
									<thead>
										<tr>
											<th class="text-center" rowspan="2">Airport</th>
											<th class="text-center" rowspan="2">Tariff Category</th>
											<th class="text-center" colspan="2">Outbound </th>
											<th class="text-center" colspan="2">Inbound </th>
											<th class="text-center" rowspan="2">Action</th>
										</tr>
										<tr>
											<th class="text-center">Rate</th>
											<th class="text-center">Minimum Charge</th>
											<th class="text-center">Rate</th>
											<th class="text-center">Minimum Charge</th>
										</tr>
									</thead>
									<tbody>
										<!-- *ngFor="let air of airports | async" -->
										<tr *ngFor="let con of airportPricing | async">
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{con.airportID.iataCode}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													Cargo Handling Charges (General Cargo) {{con.tariffCategory}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{con.inboundRate}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{con.inboundMinimumChanges}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{con.outboundRate}}
												</a>
											</td>
											<td class="text-center">
												<a [routerLink]="" (click)="viewchange('view')">
													{{con.outboundMinimumChanges}}
												</a>
											</td>
											<td class="text-center">
												<div role="group" class="btn-group-sm btn-group">
													<button class="btn-shadow btn btn-primary" (click)="viewchange('edit')"><i
															class="lnr-pencil"></i></button>
													<!-- <button class="btn-shadow btn btn-primary">Delete</button> -->
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
									[showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
									[pageIndex]="pageIndex" aria-label="Select page">
								</mat-paginator>
							</div>
						</div>
					</div>
				</div>
				<ngx-loading [show]="loading"
					[config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
					[template]="loadingTemplate"></ngx-loading>
			</div>

		</some-element>
		<some-element *ngSwitchCase="'add'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						Add Airport Pricing Schedules
					</div>

				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Airport</mat-label>
								<select matNativeControl id="" [(ngModel)]="newairlinePricing.airportID">
									<option *ngFor="let con of airports |async" [value]="con.id">
										{{con.name}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Tariff Category</mat-label>
								<select matNativeControl id="" [(ngModel)]="newairlinePricing.tariffCategory">
									<option *ngFor="let traiff of tarrifCategrory |async" [value]="traiff.id">
										{{traiff.name}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">


						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Outbound Rate
								</mat-label>
								<input type="text" placeholder="Outbound Rate" aria-label="Number" matInput
									[(ngModel)]="newairlinePricing.outboundRate">

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Outbound Minimum Charge
								</mat-label>
								<input type="text" placeholder="Outbound Minimum Charge" aria-label="Number" matInput
									[(ngModel)]="newairlinePricing.outboundMinimumChanges">

							</mat-form-field>
						</div>
						<div class="col-md-4">

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Inbound Rate
								</mat-label>
								<input type="text" placeholder="Inbound Rate" aria-label="Number" matInput
									[(ngModel)]="newairlinePricing.inboundRate">

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Inbound Minimum Charge
								</mat-label>
								<input type="text" placeholder="Inbound Minimum Charge" aria-label="Number" matInput
									[(ngModel)]="newairlinePricing.inboundMinimumChanges">

							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="createAirportPricing()">Add</div>
					</div>
				</div>
			</div>
		</some-element>

		<some-element *ngSwitchCase="'edit'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						Edit Airport Pricing Schedules
					</div>

				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Airport</mat-label>
								<select matNativeControl id="">
									<option *ngFor="let con of airports |async" [value]="con.id">
										{{con.name}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Tariff Category</mat-label>
								<select matNativeControl id="">
									<option *ngFor="let airport of airports |async" [value]="airport.id">
										{{airport.name}}
									</option>
								</select>
							</mat-form-field>

						</div>
						<div class="col-md-4">


						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Outbound Rate
								</mat-label>
								<input type="text" placeholder="Outbound Rate" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Outbound Minimum Charge
								</mat-label>
								<input type="text" placeholder="Outbound Minimum Charge" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Inbound Rate
								</mat-label>
								<input type="text" placeholder="Inbound Rate" aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Inbound Minimum Charge
								</mat-label>
								<input type="text" placeholder="Inbound Minimum Charge" aria-label="Number" matInput>

							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="viewchange('table')">Save</div>
					</div>
				</div>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'view'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						View Airport Pricing Schedules
					</div>

				</div>

				<div class="container m-2">
					<div class="row ">
						<div class="col-md-3 fw-bold">Airport</div>
						<div class="col-md-3"> HYD </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-3 fw-bold">Tariff Category</div>
						<div class="col-md-9"> Cargo Handling Charges (General Cargo) </div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-2 fw-bold">
							Outbound
						</div>
						<div class="col-md-10">

							<div class="row ">
								<div class="col-md-3 fw-bold">Rate</div>
								<div class="col-md-3"> 1.37 /KG </div>
							</div>
							<div class="row ">
								<div class="col-md-3 fw-bold">Minimum Charge</div>
								<div class="col-md-3"> 38.71 /KG </div>
							</div>
						</div>
					</div>
					<mat-divider class="m-1"></mat-divider>
					<div class="row ">
						<div class="col-md-2 fw-bold">
							Inbound
						</div>
						<div class="col-md-10">

							<div class="row ">
								<div class="col-md-3 fw-bold">Rate</div>
								<div class="col-md-3"> 0.99 /KG </div>
							</div>
							<div class="row ">
								<div class="col-md-3 fw-bold">Minimum Charge</div>
								<div class="col-md-3"> 37.81 /KG </div>
							</div>
						</div>
					</div>


				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Back</div>
					</div>
				</div>
			</div>
		</some-element>
	</container-element>


</div>