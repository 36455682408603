<div *ngIf="loading" class="loader-container">
  <ngx-loading [show]="loading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>

<div class="card">
  <div class="card-header">

    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
      <a (click)="goBackToUserTablePage()"><i class="fa fa-arrow-left"
                                              style="cursor: pointer"></i></a>&nbsp;<span
      style="color: blue; font-weight: bold; font-size: larger">EDIT USER</span>
    </div>

  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-3 row">
        <mat-form-field appearance="standard">
          <mat-label>
            First Name <span class="text-danger">*</span>
          </mat-label>
          <input type="text" placeholder="First Name" aria-label="text" matInput
                 [(ngModel)]="customer_shipper.firstName" maxlength="60"
                 (ngModelChange)="customer_shipper.firstName = $event.trim()">
          <mat-hint *ngIf="customer_shipper.firstName?.length === 60" align="end" class="text-danger">Maximum length
            reached
          </mat-hint>

        </mat-form-field>
      </div>
      <div class="col-md-3 row">
        <mat-form-field appearance="standard">
          <mat-label>
            Middle Name
          </mat-label>
          <input type="text" placeholder="Middle Name" aria-label="Number" matInput
                 [(ngModel)]="customer_shipper.middleName" maxlength="45"
                 (ngModelChange)="customer_shipper.middleName = $event.trim()">
          <mat-hint *ngIf="customer_shipper.middleName?.length === 45" align="end" class="text-danger">Maximum length
            reached
          </mat-hint>

        </mat-form-field>
      </div>
      <div class="col-md-3 row">
        <mat-form-field appearance="standard">
          <mat-label>
            Last Name <span class="text-danger">*</span>
          </mat-label>
          <input type="text" placeholder="Last Name" aria-label="Number" matInput
                 [(ngModel)]="customer_shipper.lastName" maxlength="45"
                 (ngModelChange)="customer_shipper.lastName = $event.trim()">
          <mat-hint *ngIf="customer_shipper.lastName?.length === 45" align="end" class="text-danger">Maximum length
            reached
          </mat-hint>

        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 row">

        <mat-form-field appearance="standard">
          <mat-label>
            Email<span class="text-danger">*</span>
          </mat-label>
          <input type="text" placeholder="Email" aria-label="Number" (blur)="emailExistAppuser()"
                 matInput [(ngModel)]="customer_shipper.email" [formControl]="customerVerification.emailFormControl"
                 (ngModelChange)="customer_shipper.email = $event ? $event.trim() : null">
          <mat-hint class="text-danger fw-normal" *ngIf="customerVerification.emailFormControl.hasError('pattern')">Invalid email
            address
          </mat-hint>
          <mat-hint class="text-danger fw-normal" *ngIf="customerVerification.emailExist">
            Email Already Exists
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-md-3 row">
        <mat-form-field appearance="standard">
          <mat-label>
            Phone No.<span class="text-danger">*</span>
          </mat-label>
          <input type="text" placeholder="Phone No."
                 onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="Number" matInput
                 #phone maxlength="10"
                 [(ngModel)]="customer_shipper.phone" (input)="phoneNoExistAppuser()">
          <mat-hint class="text-danger fw-normal" *ngIf="customerVerification.phoneNoExist">
            Phone No. Already Exists
          </mat-hint>
          <mat-hint align="end">{{ phone.value.length }}/10
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-md-3 row">
        <mat-form-field appearance="standard">
          <mat-label>
            Whatsapp No.
          </mat-label>
          <input type="text" placeholder="Whatsapp No."
                 onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="Number" matInput
                 maxlength="10" #whatsappnum
                 [(ngModel)]="customer_shipper.whatsapp">
          <mat-hint align="end">{{ whatsappnum.value.length }}/10</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 row">
        <mat-form-field appearance="standard">
          <mat-label>
            Address <span class="text-danger">*</span>
          </mat-label>
          <textarea type="text" placeholder="Address" rows="5" aria-label="Number" matInput
                    [(ngModel)]="customer_shipper.billingAddress.address"></textarea>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 row">
            <mat-form-field appearance="standard">
              <mat-label>
                Country <span class="text-danger">*</span>
              </mat-label>
              <mat-select matNativeControl
                          [(ngModel)]="customerVerification.user_sel_country">
                <!--                      <mat-option value="">SELECT</mat-option >-->
                <mat-option *ngFor="let con of customerVerification.countries |async" [value]="con.id" (click)="customerVerification.getIndividaulStates(customer_shipper,'user')">
                  {{ con.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6 row">
            <mat-form-field appearance="standard">
              <mat-label>
                State <span class="text-danger">*</span>
              </mat-label>
              <mat-select matNativeControl
                          [(ngModel)]="customerVerification.user_sel_state">
                <mat-option *ngFor="let sta of customerVerification.user_states |async" [value]="sta.id"
                            (click)="customerVerification.getIndividaulCities(customer_shipper, 'user')">
                  {{ sta.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 row">
            <mat-form-field appearance="standard">
              <mat-label>
                City <span class="text-danger">*</span>
              </mat-label>
              <mat-select matNativeControl
                          [(ngModel)]="customerVerification.user_sel_city">
                <mat-option *ngFor="let cit of customerVerification.user_cities |async" [value]="cit.id"
                            (click)="customerVerification.getIndividaulPincodes(customer_shipper, 'user')">
                  {{ cit.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6 row">
            <mat-form-field appearance="standard">
              <mat-label>
                Zip Code <span class="text-danger">*</span>
              </mat-label>
              <mat-select matNativeControl
                          [(ngModel)]="customer_shipper.billingAddress.pincode.pin_number">
                <mat-option *ngFor="let pin of customerVerification.user_pincodes |async" [value]="pin.pinNumber">
                  {{ pin.pinNumber }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 row">
        <mat-form-field appearance="standard">
          <mat-label>
            User Role <span class="text-danger">*</span>
          </mat-label>
          <mat-select matNativeControl [(ngModel)]="customer_shipper.userTypes[0].id">
            <mat-option value="">SELECT</mat-option>
            <mat-option *ngFor="let role of userRoles" [value]="role.id">
              {{ role.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3 row">
        <mat-form-field appearance="standard">
          <mat-label>
            Employment No.
          </mat-label>
          <input type="text" placeholder="Employment No." aria-label="Number" matInput
                 [(ngModel)]="customer_shipper.employmentNo">
        </mat-form-field>
      </div>
      <div class="col-md-3 row">
        <mat-form-field appearance="standard">
          <mat-label>
            Office Location <span class="text-danger">*</span>
          </mat-label>
          <mat-select matNativeControl [(ngModel)]="customer_shipper.officeLocation.id">
            <mat-option *ngFor="let office of customerVerification.officesList" [value]="office.id">
              {{ office.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="btn-actions-pane-right p-2">
      <div class="mb-2 me-2 btn btn-danger" (click)="goBackToUserTablePage()">Cancel</div>
      <div class="mb-2 me-2 btn btn-primary" (click)="editUser()">Add</div>
    </div>
  </div>
</div>
