<div class="col-md-12">
    <div class="card">
        <div class="card-header">
            <div class="card-header-title font-size-lg text-capitalize fw-bold">
                <b style="color: blue;">FILE FORMATS</b>
            </div>
            <div class="btn-actions-pane-right p-2">
                <div class="mb-2 me-2 btn btn-primary" (click)="navigateToAddCSVFileFormat()" *ngIf="displaytoCustomer">
                    Add
                </div>
            </div>
        </div>
        <div class="card-body">
            <div *ngIf="activeCSVFileFormats.length === 0" class="alert alert-info">No CSV Files available.</div>
            <div class="row">
                <div *ngFor="let csvFile of activeCSVFileFormats" class="col-md-3 mb-4">
                    <div class="square" style="position: relative; width: 100%; padding-bottom: 100%;">
                        <div class="square-content"
                            style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; display: flex; justify-content: center; align-items: center; overflow: hidden;">
                            <div class="square-inner"
                                style="padding: 1rem; background-color: #f8f9fa; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); display: flex; flex-direction: column; height: 100%; width: 100%; text-align: center;">
                                <h5 class="card-title" style="margin-bottom: 0.5rem;">{{ csvFile.contentDisposition }}
                                    <button class="btn btn-sm ms-2" (click)="showDescription(csvFile.description)"
                                        matTooltip="INFO">
                                        <i class="fa fa-info-circle"
                                            style="color: white; background-color: darkgray; font-size: initial; border-radius: 50%; padding: 0.1rem;"></i>
                                    </button>
                                </h5>
                                <div style="flex: 1; display: flex; justify-content: center; align-items: center;">
                                    <ng-container *ngIf="isPdfFile(csvFile.fileType)">
                                        <i class="fa fa-file-pdf"
                                            style="font-size: 80px; color: red; text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white; margin-bottom: 1rem;"></i>
                                    </ng-container>
                                    <ng-container *ngIf="isDocxOrDocFile(csvFile.fileType)">
                                        <i class="fa fa-file-word"
                                            style="font-size: 80px; color: blue; text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white; margin-bottom: 1rem;"></i>
                                    </ng-container>
                                    <ng-container *ngIf="isImageFile(csvFile.fileType)">
                                        <i class="fa fa-file-image"
                                            style="font-size: 80px; color: green; text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white; margin-bottom: 1rem;"></i>
                                    </ng-container>
                                    <ng-container *ngIf="isCsvFile(csvFile.fileType)">
                                        <i class="fas fa-file-csv"
                                            style="font-size: 80px; color: green; text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white; margin-bottom: 1rem;"></i>
                                    </ng-container>
                                    <ng-container *ngIf="isOtherFile(csvFile.fileType)">
                                        <i class="fa fa-file"
                                            style="font-size: 80px; color: gray; text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white; margin-bottom: 1rem;"></i>
                                    </ng-container>
                                </div>
                                <a (click)="downloadFileById(csvFile.id)" class="btn btn-primary mt-auto mb-2"
                                    style="margin-top: auto;">Download</a>
                                <a (click)="deleteFile(csvFile.id)" class="btn btn-danger mt-auto"
                                    *ngIf="displaytoCustomer" style="margin-top: auto;">Delete</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>