import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConsignmentTypesModel} from '../models/consignment-types-model';
import {ConsignmentTypeResponse} from '../models/consignment-type-response';
import {Observable} from 'rxjs';
import {API_URL} from '../../../../../Global_API_URL';

@Injectable({
  providedIn: 'root',
})
export class ConsignmentTypeService {
  constructor(private http: HttpClient) { }

  postConsignmentType(newConsignmentType: ConsignmentTypesModel): Observable<ConsignmentTypeResponse> {
    return this.http.post<ConsignmentTypeResponse>(`${API_URL}/consignment_type`, newConsignmentType);
  }

  patchConsignmentType(newConsignmentType: ConsignmentTypesModel): Observable<ConsignmentTypeResponse> {
    return this.http.patch<ConsignmentTypeResponse>(`${API_URL}/consignment_type/${newConsignmentType.id}`, newConsignmentType);
  }

  getConsignmentTypeByProductType(productType: string): Observable<ConsignmentTypeResponse> {
    const params: HttpParams = new HttpParams().set('productType', productType);
    return this.http.get<ConsignmentTypeResponse>(`${API_URL}/consignmentTypeByProductType`, {params});
  }
}
