<div *ngIf="isLoading" class="loader-container">
  <ngx-loading [show]="isLoading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>

<mat-card>

  <mat-card-title>
    <div style="display: flex; align-items: center">
      <button mat-icon-button (click)="goBackToAirlinePricingList()">
        <span class="material-icons" style="margin-top: -15px">arrow_back</span>
      </button>
      <div class="h4"> <b style="color: blue;">CREATE AIRLINE PROMOTION</b> </div>
    </div>
  </mat-card-title>

  <mat-divider></mat-divider>

  <!-- Airline Container-->
  <div class="custom-container" style="margin-top: 2rem">
    <div style="font-size: medium; text-align: center" class="m-auto">
      <p>Airline<span style="color: #dc1819">*</span></p>
    </div>
    <div>
      <mat-form-field id="airlines" appearance="outline">
        <mat-label>Select an airline</mat-label>
        <mat-select [(ngModel)]="selectedAirline" name="food">
          <mat-option *ngFor="let airline of airlines" [value]="airline.id">
            {{airline.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!--  Flights Container-->
  <div class="custom-container">
    <div style="font-size: medium; text-align: center;" class="mt-3">Flights<span style="color: #dc1819">*</span></div>
    <div style="display: flex; flex-direction: column">
      <mat-radio-group style="display:flex; flex-direction: column; margin: 15px 0; gap: 10px"
        aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="selectedFlightOption">
        <mat-radio-button class="example-radio-button" style="margin: 5px" value="All">
          All
        </mat-radio-button>

        <div style="display: flex; flex-direction: row; gap: 2rem; margin: auto 0; align-items: center">
          <mat-radio-button class="example-radio-button" style="margin: 5px; padding-bottom: 18px" value="Custom">
            Custom
          </mat-radio-button>
          <div style="width: 100%" *ngIf="selectedFlightOption=='Custom'">
            <mat-form-field style="width: 80px; margin-right: 20px">
              <mat-select [(value)]="selectedOption">
                <mat-option value="ONLY">Only</mat-option>
                <mat-option value="EXCEPT">Except</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 80%; background: white">
              <mat-label>Enter flight numbers</mat-label>
              <mat-chip-list #chipList aria-label="Fruit selection">
                <mat-chip *ngFor="let flights of flightsListInput" [selectable]="selectable" [removable]="removable"
                  (removed)="remove(flights)">
                  {{flights.name}}
                  <button matChipRemove *ngIf="removable">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input placeholder="New Flight No..." [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event)" (input)="onInput($event)">
              </mat-chip-list>
            </mat-form-field>
          </div>
        </div>
      </mat-radio-group>
    </div>
  </div>

  <div class="custom-container" style="display: flex; margin-top: 10px">
    <div>Time</div>

    <div>
      <mat-checkbox [(ngModel)]="startTimeEndTime"></mat-checkbox>
    </div>

    <div style="margin-top: -2%">
      <mat-form-field appearance="outline">
        <mat-label>
          Start Time (HH:MM)
        </mat-label>
        <input [disabled]="!startTimeEndTime" type="time" placeholder="Type" aria-label="Number" [(ngModel)]="startTime"
          matInput>
      </mat-form-field>
    </div>

    <div> - </div>

    <div style="margin-top: -2%">
      <mat-form-field appearance="outline">
        <mat-label>End Time (HH:MM)</mat-label>
        <div class="centered-content" >
        <input [disabled]="!startTimeEndTime" type="time" placeholder="Type" aria-label="Number" [(ngModel)]="endTime" matInput>
        </div>
      </mat-form-field>
    </div>

    <div style="margin-top: -2%">
      <!-- Everyday checkbox -->
      <mat-checkbox [checked]="selectedDays.length === 7" (change)="toggleEveryday($event.checked)"
        style="font-size: smaller;">Everyday</mat-checkbox>
      <br>

      <!-- Button toggle group for other days -->
      <mat-button-toggle-group [(ngModel)]="selectedDays" multiple>
        <mat-button-toggle value="0"
          [style.background-color]="selectedDays.includes('0') ? 'lightblue' : 'inherit'">Sunday</mat-button-toggle>
        <mat-button-toggle value="1"
          [style.background-color]="selectedDays.includes('1') ? 'lightblue' : 'inherit'">Monday</mat-button-toggle>
        <mat-button-toggle value="2"
          [style.background-color]="selectedDays.includes('2') ? 'lightblue' : 'inherit'">Tuesday</mat-button-toggle>
        <mat-button-toggle value="3"
          [style.background-color]="selectedDays.includes('3') ? 'lightblue' : 'inherit'">Wednesday</mat-button-toggle>
        <mat-button-toggle value="4"
          [style.background-color]="selectedDays.includes('4') ? 'lightblue' : 'inherit'">Thursday</mat-button-toggle>
        <mat-button-toggle value="5"
          [style.background-color]="selectedDays.includes('5') ? 'lightblue' : 'inherit'">Friday</mat-button-toggle>
        <mat-button-toggle value="6"
          [style.background-color]="selectedDays.includes('6') ? 'lightblue' : 'inherit'">Saturday</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

  </div>

  <!--  Duration Container-->
  <div style="display: flex; gap: 4rem; flex-wrap: wrap; margin-top: 20px">
    <div style="display: flex; gap: 1rem">
      <div style="font-size: medium; text-align: center;" class="mt-3">Valid From<span style="color: #dc1819">*</span>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Select a date</mat-label>
          <input matInput [(ngModel)]="selectedStartDate" (ngModelChange)="updateStartDate($event)" [matDatepicker]="startDate">
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker timezone="UTC" #startDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div style="display: flex;  gap: 1rem">
      <div style="font-size: medium; text-align: center;" class="mt-3">Valid Upto<span style="color: #dc1819">*</span>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Select a date</mat-label>
          <input matInput [(ngModel)]="selectedEndDate" [matDatepicker]="endDate" (ngModelChange)="updateEndDate($event)">
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="btn-actions-pane-right p-2">
    <div class="mb-2 me-2 btn btn-primary float-end" (click)="addDropDown()">Add row </div>
  </div>

  <div class="container">
    <div>
      <ng-container>
        <table class="w-100" style="table-layout: fixed; width: 100%">

          <colgroup>
            <col>
            <col style="width: 10%">
            <col style="width: 10%">
            <col style="width: 10%">
            <col style="width: 10%">
            <col style="width: 10%">
            <col style="width: 10%">
            <col style="width: 7%">
            <col style="width: 7%">
            <col style="width: 7%">
            <col style="width: 7%">
            <col style="width: 7%">
            <col style="width: 7%">
            <col style="width: 7%">
            <col style="width: 10%">
          </colgroup>

          <tr>
            <th style="width: 3%; text-align: center"> # </th>
            <th style="text-align: center"> Product <span style="color: #dc1819">*</span></th>
            <th style="text-align: center"> CN Type <span style="color: #dc1819">*</span></th>
            <th style="text-align: center"> CN Sub-type <span style="color: #dc1819">*</span></th>
            <th style="text-align: center"> Type <span style="color: #dc1819">*</span></th>
            <th style="text-align: center"> ORG <span style="color: #dc1819">*</span></th>
            <th style="text-align: center"> DST <span style="color: #dc1819">*</span></th>
            <th style="text-align: center"> N Rate </th>
            <th style="text-align: center"> +45 </th>
            <th style="text-align: center"> +100 </th>
            <th style="text-align: center"> +300 </th>
            <th style="text-align: center"> +500 </th>
            <th style="text-align: center"> +1000 </th>
            <th style="text-align: center"> Recommend </th>
            <th style="text-align: center"> Actions </th>
          </tr>

          <tr *ngFor="let row of selectedRows; let i = index" [ngStyle]="{'background-color': isFailedRow(i)}">
            <td style="border-radius: 5px 0px 0px 5px; text-align: center; margin-top: 0.2rem">{{i+1}}</td>

            <td style="text-align: center">
              <mat-form-field appearance="standard" style="width: 80%">
                <mat-select [(ngModel)]="selectedRows[i].productType" (selectionChange)="onProductTypeChange(i)">
                  <mat-option value="null">ALL</mat-option>
                  <mat-option *ngFor="let type of productTypes" [value]="type"
                    (click)="setCNTypes(i, type.consignment_types)">
                    {{type.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>

            <!-- Adjusted width for each dropdown -->
            <td style="text-align: center">
              <mat-form-field appearance="standard" style="width: 80%">
                <mat-select [(ngModel)]="selectedRows[i].consignmentType" (selectionChange)="onCNTypeChange(i)">
                  <mat-option value="null">ALL</mat-option>
                  <mat-option *ngFor="let type of selectedCNTypesSubtype[i].cnTypes" [value]="type"
                    (click)="setAllCNSubTypes(i, type.consignment_sub_types_list)">
                    {{type.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>

            <td style="text-align: center">
              <mat-form-field appearance="standard" style="width: 80%">
                <mat-select [(ngModel)]="selectedRows[i].consignmentSubtype">
                  <mat-option value="null">ALL</mat-option>
                  <mat-option *ngFor="let subType of selectedCNTypesSubtype[i].cnSubtypes" [value]="subType">
                    {{subType.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>

            <td style="text-align: center">
              <mat-form-field appearance="standard" style="width: 80%">
                <mat-select [(ngModel)]="selectedRows[i].type" value="discount">
                  <mat-option value="DISCOUNT"> Discount </mat-option>
                  <mat-option value="PREMIUM"> Premium </mat-option>
                </mat-select>
              </mat-form-field>
            </td>

            <td style="text-align: center">
              <mat-form-field appearance="standard" style="width: 80%">
                <mat-select [(ngModel)]="selectedRows[i].origin">
                  <mat-option value="null">ALL</mat-option>
                  <mat-option *ngFor="let origin of originAirports" [value]="origin">
                    {{origin.city.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!--              <mat-form-field appearance="outline">-->
              <!--                <input [formControl]="myControl" matInput type="text">-->
              <!--                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">-->
              <!--                  <mat-option *ngFor="let cit of airports | async" [value]="cit">-->
              <!--                    {{ cit.city.name }} - {{ cit.iataCode }}-->
              <!--                  </mat-option>-->
              <!--                </mat-autocomplete>-->
              <!--              </mat-form-field>-->
            </td>

            <td style="text-align: center">
              <mat-form-field appearance="standard" style="width: 80%">
                <mat-select [(ngModel)]="selectedRows[i].destination">
                  <mat-option value="null">ALL</mat-option>
                  <mat-option *ngFor="let destination of destinationAirports" [value]="destination">
                    {{destination.city.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>

            <td style="text-align: center">
              <mat-form-field appearance="standard" style="width: 80%">
                <input [(ngModel)]="selectedRows[i].airlineTarrifNRate" #airlineTarrifNRate (focus)="clearairlineTarrifNRateValue(airlineTarrifNRate, i)" (blur)="restoreairlineTarrifNRateValue(airlineTarrifNRate, i)" matInput>
              </mat-form-field>
            </td>

            <!-- Adjusted width for each input -->
            <td style="text-align: center">
              <mat-form-field appearance="standard" style="width: 80%">
                <input [(ngModel)]="selectedRows[i].airlineTarrifAbove45" #airlineTarrifAbove45 (focus)="clearairlineTarrifAbove45Value(airlineTarrifAbove45, i)" (blur)="restoreairlineTarrifAbove45Value(airlineTarrifAbove45, i)" matInput>
              </mat-form-field>
            </td>

            <td style="text-align: center">
              <mat-form-field appearance="standard" style="width: 80%">
                <input [(ngModel)]="selectedRows[i].airlineTarrifAbove100" #airlineTarrifAbove100 (focus)="clearairlineTarrifAbove100Value(airlineTarrifAbove100, i)" (blur)="restoreairlineTarrifAbove100Value(airlineTarrifAbove100, i)" matInput>
              </mat-form-field>
            </td>

            <td style="text-align: center">
              <mat-form-field appearance="standard" style="width: 80%">
                <input [(ngModel)]="selectedRows[i].airlineTarrifAbove300" #airlineTarrifAbove300 (focus)="clearairlineTarrifAbove300Value(airlineTarrifAbove300, i)" (blur)="restoreairlineTarrifAbove300Value(airlineTarrifAbove300, i)" matInput>
              </mat-form-field>
            </td>

            <td style="text-align: center">
              <mat-form-field appearance="standard" style="width: 80%">
                <input [(ngModel)]="selectedRows[i].airlineTarrifAbove500" #airlineTarrifAbove500 (focus)="clearairlineTarrifAbove500Value(airlineTarrifAbove500, i)" (blur)="restoreairlineTarrifAbove500Value(airlineTarrifAbove500, i)" matInput>
              </mat-form-field>
            </td>

            <td style="text-align: center">
              <mat-form-field appearance="standard" style="width: 80%">
                <input [(ngModel)]="selectedRows[i].airlineTarrifAbove1000" #airlineTarrifAbove1000 (focus)="clearairlineTarrifAbove1000Value(airlineTarrifAbove1000, i)" (blur)="restoreairlineTarrifAbove1000Value(airlineTarrifAbove1000, i)" matInput>
              </mat-form-field>
            </td>

            <td style="text-align: center">
              <input type="checkbox" [(ngModel)]="selectedRows[i].recommended" (change)="onRecommendedChange(i)">
            </td>

            <td style="text-align: center; border-radius: 0px 5px 5px 0px">
              <button [disabled]="selectedRows.length===1" mat-icon-button color="warn" aria-label="Example icon button with a heart icon" (click)="removeRow(i)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </td>
          </tr>

        </table>
      </ng-container>
    </div>
  </div>

  <div class="btn-actions-pane-right p-2" style="margin-top: 2%; margin-bottom: 2%; margin-right: 2%">
    <div class="mb-2 me-2 btn btn-primary float-end" (click)="saveAirlinePromotion()"> Create</div>
    <div class="mb-2 me-2 btn float-end" style="background-color: red; color: white"
      (click)="goBackToAirlinePricingList()"> Discard</div>
  </div>


</mat-card>
