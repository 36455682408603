<div class="row">
  <div class="col-md-12">
    <div class="card p-4">
      <div class="card-header">
        <div class="card-header-title font-size-lg text-capitalize fw-Bold">
          <b style="color: blue;">TRACKING</b>
        </div>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Consignment No. <span class="text-danger">*</span>
                  </mat-label>
                  <input #input placeholder="Enter Consignment No." onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="11" [(ngModel)]="consignment_id" matInput>
                  <mat-hint align="end">{{input.value.length}}/11</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-md-1 center-elem pl-2 m-4">
                <button class="btn btn-primary " (click)="getTrack()">Track</button>
              </div>
            </div>
            <div class="row" *ngIf="consignment_track && ConNoAvailable">
              <div class="col-md-6">
                <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                  <div class="vertical-timeline-item vertical-timeline-element"
                    *ngFor="let tra of consignment_track;let i=index">
                    <div *ngIf="tra.status.activeForCustomer == true || notdisplaytocustomer == true">
                      <span class="vertical-timeline-element-icon bounce-in">
                        <i class="badge badge-dot badge-dot-xl bg-success"
                          [ngClass]="tra.updatedAt == null?'bg-light':'bg-primary'"></i>
                      </span>
                      <div class="vertical-timeline-element-content bounce-in">
                        <h4 class="timeline-title">{{tra.status.name}}</h4>
                        <p>{{tra.comment}}</p>
                        <input matInput readonly [(ngModel)]="origin" *ngIf="tra.status.id>0 && tra.status.id<11">
                        <input matInput readonly [(ngModel)]="destination" *ngIf="tra.status.id>10 && tra.status.id<17">
                        <!-- <p class="">{{getTime(tra.updatedAt)}}</p> -->
                        <p class="">{{ tra.updatedAt | date:'dd-MM-yyyy HH:mm:ss' }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6" *ngIf="current_status_id!=18 && current_status_id!=36 && ConNoAvailable">
            <div *ngIf="consignment_status" class="col-md-6">
              <div class="card-body"*ngIf="notdisplaytocustomer">
                <div class="card-header-title font-size-lg text-capitalize fw-normal">
                  Update Tracking Status
                </div>
                <ngx-loading [show]="loading"
                  [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                  [template]="loadingTemplate"></ngx-loading>
                <div class="row">
                  <div class="col-md-6 row" style="width: 400px;">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        Status
                      </mat-label>
                      <select matNativeControl id="" [(ngModel)]="consignment_sts_update.status">
                        <option>Select status</option>
                        <ng-container *ngFor="let cons_st of consignment_status">
                          <option [value]="cons_st?.id" *ngIf="shouldShowOption(cons_st)">
                            {{ cons_st?.name }}
                          </option>
                        </ng-container>
                      </select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 row" style="width: 400px;">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        Text
                      </mat-label>
                      <input placeholder="Text" matInput [(ngModel)]="consignment_sts_update.comment">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md- center-elem pl-0">
                    <button class="btn btn-primary " (click)="update_con_status()">Update Status</button>
                  </div>
                </div>
              </div>
              <div class="card-body" *ngIf="current_status_id ==16">
                <div class="fw-bold h4 margin-h-center pt-4 text-center">
                  <b style="color: rgb(11, 11, 242);">POD</b>
                </div>
                <div>
                  <img [src]='POD' style="height: 200px; width: 200px;" alt="Image" class="rounded mx-auto d-block p-1" alt="Responsive image">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
