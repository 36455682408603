import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-quick-start',
  templateUrl: './quick-start.component.html',
  styleUrls: ['./quick-start.component.sass'],
})
export class QuickStartComponent implements OnInit {
  displaytocustomer = true;
  constructor(private route: Router) { }

  userDetails;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name == 'Customer') {
      this.displaytocustomer = false;
    } else {
      this.displaytocustomer = true;
    }
  }
  goto(filter: string) {
    this.route.navigate(['/orders'],
        {
          queryParams: {
            type: filter,
          },
        },
    );
  }

  gotoQuick(filter: string) {
    this.route.navigate(['/quickproducts'],
        {
          queryParams: {
            type: filter,
          },
        },
    );
  }

  gotoAce() {
    this.route.navigate(['/iclproducts'],
        {
          queryParams: {
            type: 'Ace',
          },
        },
    );
  }

  gotoPrudent() {
    this.route.navigate(['/iclproducts'],
        {
          queryParams: {
            type: 'Prudent',
          },
        },
    );
  }

  gotoGem() {
    this.route.navigate(['/iclproducts'],
        {
          queryParams: {
            type: 'Gem',
          },
        },
    );
  }
}
