
<div class="h-100">
  <div class="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center col-md-8 col-lg-7" style="margin-left: 20%">
    <mat-card style="box-shadow: 0 0 10px 0 #9b9b9b, 0 1px 1px 0 #9b9b9b, 0 1px 3px 0 #9b9b9b;">
        <mat-card-header>
          <div style="display: flex; flex-direction: column; width: 100%">
            <div>
              <img src="./assets/images/icl-logo.png" style="width: 150px;"/>
              <h1 style="float:right; color: #004697; font-weight: bold; margin-right: 33%"> Welcome to Sign-up</h1>
            </div>
            <div style="margin-top: -19px; text-align: end">
              <h6>
                <span style="margin-right: 33%">It only takes a <span class="text-success">few seconds</span> to create your account</span>
              </h6>
            </div>
          </div>
        </mat-card-header>
      <mat-card-content>
        <div class="mx-auto app-login-box col-sm-8 col-md-8 col-lg-8">
          <!-- <img src="./assets/images/iclLogo1.png" style="width: 150px;"/> -->

          <div>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    First Name<span class="text-danger">*</span>
                  </mat-label>
                  <input type="text" placeholder="First Name" aria-label="Number" matInput
                         [(ngModel)]="indiviudal_user.firstName">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Middle Name
                  </mat-label>
                  <input type="text" placeholder="Middle Name" aria-label="Number" matInput
                         [(ngModel)]="indiviudal_user.middleName">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Last Name<span class="text-danger">*</span>
                  </mat-label>
                  <input type="text" placeholder="Last Name" aria-label="Number" matInput
                         [(ngModel)]="indiviudal_user.lastName">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Email<span class="text-danger">*</span>
                  </mat-label>
                  <input type="text" placeholder="Email" aria-label="text" matInput [formControl]="emailFormControl"
                         [(ngModel)]="indiviudal_user.email"(blur)="emailAppuser()">
                  <mat-hint class="text-danger fw-bold" *ngIf="emailExist==true">Email
                    Exist</mat-hint>
                  <mat-hint class="text-danger fw-normal" *ngIf="emailFormControl.hasError('pattern')">Invalid
                    email address</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Phone No.<span class="text-danger">*</span>
                  </mat-label>
                  <input type="text" placeholder="Phone No" #phone onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="10" aria-label="Number" matInput
                         [(ngModel)]="indiviudal_user.phone">
                  <mat-hint align="end">{{phone.value.length}}/10</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Whatsapp No.
                  </mat-label>
                  <input type="text" placeholder="Whatsapp No." #whatsapp onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="10" aria-label="Number" matInput
                         [(ngModel)]="indiviudal_user.whatsapp">
                  <mat-hint align="end">{{whatsapp.value.length}}/10</mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="standard" style="margin-top: -15px">
                  <mat-label>
                    Address<span class="text-danger">*</span>
                  </mat-label>
                  <textarea type="text" placeholder="Address" aria-label="text" matInput
                            [(ngModel)]="indiviudal_user.billingAddress.address"></textarea>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Country<span class="text-danger">*</span>
                  </mat-label>
                  <mat-select  (blur)="getstates()" [(ngModel)]="country_id">
                    <mat-option selected>SELECT</mat-option>
                    <mat-option *ngFor="let con of constriesList | async" [value]="con.id">
                      {{con.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    State<span class="text-danger">*</span>
                  </mat-label>
                  <mat-select (blur)="getcity()" [(ngModel)]="state_id">
                    <mat-option selected>SELECT</mat-option>
                    <mat-option *ngFor="let sta of stateList" [value]="sta.id">
                      {{sta.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-13">
<!--                <div class="row">-->
<!--                  <div class="col-md-4">-->
<!--                    <mat-form-field appearance="standard">-->
<!--                      <mat-label>-->
<!--                        Country<span class="text-danger">*</span>-->
<!--                      </mat-label>-->
<!--                      <mat-select id="dimensionuUnits" (blur)="getstates()" [(ngModel)]="country_id">-->
<!--                        <mat-option selected>SELECT</mat-option>-->
<!--                        <mat-option *ngFor="let con of constriesList | async" [value]="con.id">-->
<!--                          {{con.name}}-->
<!--                        </mat-option>-->
<!--                      </mat-select>-->
<!--                    </mat-form-field>-->
<!--                  </div>-->
<!--                  <div class="col-md-4">-->
<!--                    <mat-form-field appearance="standard">-->
<!--                      <mat-label>-->
<!--                        State<span class="text-danger">*</span>-->
<!--                      </mat-label>-->
<!--                      <mat-select id="dimensionuUnits" (blur)="getcity()" [(ngModel)]="state_id">-->
<!--                        <mat-option selected>SELECT</mat-option>-->
<!--                        <mat-option *ngFor="let sta of stateList" [value]="sta.id">-->
<!--                          {{sta.name}}-->
<!--                        </mat-option>-->
<!--                      </mat-select>-->
<!--                    </mat-form-field>-->
<!--                  </div>-->
<!--                </div>-->
                <div class="row">
                  <div class="col-md-4">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        City<span class="text-danger">*</span>
                      </mat-label>
                      <mat-select id="dimensionuUnits" (blur)="getPincodes()" [(ngModel)]="city_id">
                        <mat-option selected>SELECT</mat-option>
                        <mat-option *ngFor="let cit of cityList" [value]="cit.id">
                          {{cit.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        Zip Code <span class="text-danger">*</span>
                      </mat-label>
                      <mat-select [(ngModel)]="indiviudal_user.billingAddress.pincode.pin_number">
                        <mat-option selected>SELECT</mat-option>
                        <mat-option *ngFor="let pin of user_pincodes" [value]="pin.pinNumber">
                          {{pin.pinNumber}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="custom-control custom-checkbox form-check">
                <input id="exampleCheck" type="checkbox" name="check" autocomplete="off" class="form-check-input" [(ngModel)]="indiviudal_user.valid"
                       value="true">
                <label for="exampleCheck" class="form-label form-check-label">
                  Accept our <a [routerLink]="" (click)="pdf()">Terms and Conditions</a>.
                </label>
              </div>


              <ngx-loading [show]="loading"
                           [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                           [template]="loadingTemplate"></ngx-loading>
            </div>
          </div>
        </div>
        <!-- <div class="d-lg-flex d-xs-none col-lg-5">
            <div class="slider-light">
              <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig2">
                <div ngxSlickItem>
                  <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div class="slider-content text-light">
                      <h3>Perfect Balance</h3>
                      <p>
                        ArchitectUI is like a dream. Some think it's too good to be true! Extensive collection of
                        unified Vue Bootstrap Components and Elements.
                      </p>
                    </div>
                  </div>
                </div>
              </ngx-slick-carousel>
            </div>
          </div> -->
      </mat-card-content>
      <mat-card-footer style="text-align: center">
          <button type="button" class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-success btn-lg"
                  (click)="createCustomer()">Create Account
          </button>
        <br/><br/>
        <h6>
          Already have an account?
          <a [routerLink]="" (click)="goToLogin()" class="text-primary">Sign in</a>
        </h6>
    </mat-card-footer>
    </mat-card>

</div>
</div>
