<container-element [ngSwitch]="pageview">
  <some-element *ngSwitchCase="'table'">
    <div class="main-card mb-3 card">
      <div class="card-header">
        <div class="card-header-title font-size-lg text-capitalize fw-normal">
          Corporate Consignments
        </div>
        <div class="btn-actions-pane-right p-2">
          <a [routerLink]="['/corporatebooking']" class="mb-2 me-2 btn btn-primary">Add Consignment</a>
          <!-- <button type="button" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm m-1">
            Actions Menu
            <span class="ps-2 align-middle opactiy-7">
              <i class="fa fa-angle-down"></i>
            </span>
          </button> -->
        </div>
      </div>
      <div class="table-responsive">
        <table class="align-middle text-truncate mb-0 table table-borderless table-hover" *ngIf="consignmentlist">
          <thead>
            <tr>
              <th class="text-center">Consignment No.</th>
              <!-- <th class="text-center">Avatar</th> -->
              <th class="text-center">Customer</th>
              <th class="text-center">No of Packages</th>
              <th class="text-center">Chargeable Weight</th>
              <th class="text-center">Origin Location</th>
              <th class="text-center">Destination Location</th>
              <th class="text-center">Consignment Created At</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let con of consignmentlist.content" [ngClass]="con.isActive == false ? 'red':'white'">
              <td class="text-center text-muted" style="width: 80px;">{{con.consignmentNo}}</td>
              <!-- <td class="text-center" style="width: 80px;">
                <img width="40" class="rounded-circle" src="./assets/images/avatars/4.jpg" alt="" />
              </td> -->
              <td class="text-center" style="max-width: 120px; overflow:hidden"><a *ngIf="con.senderId" [routerLink]=""
                  matTooltip="{{con.senderId.firstName}}">{{con.senderId.firstName}}</a></td>
              <td class="text-center">{{con.totalNoOfPackages}}</td>
              <td class="text-center">{{con.totalChargableWeight}} kg</td>
              <td class="text-center">
                <div *ngIf="con.originLocation">
                  {{con.originLocation.iataCode}}
                </div>
              </td>
              <td class="text-center">
                <div *ngIf="con.destinationLocation">
                  {{con.destinationLocation.iataCode}}
                </div>
              </td>
              <td class="text-center">
                <span class="pe-2 opacity-6">
                  <i class="fa fa-business-time"></i>
                </span>
                {{formatDate(con.createdAt)}}
              </td>
              <td class="text-cente">
                <div role="group" class="btn-group-sm btn-group m-1">
                  <button class="btn-shadow btn btn-primary" (click)="viewchange('edit',con.id)"><i
                      class="lnr-pencil"></i></button>
                </div>
                <div role="group" class="btn-group-sm btn-group m-1">
                  <button class="btn-shadow btn btn-primary" (click)="getRecord(con.id)"><i
                      class="pe-7s-download"></i></button>
                  <!-- <button class="btn-shadow btn btn-primary">Delete</button> -->
                </div>
                <div role="group" *ngIf="con.isActive!=false" class="btn-group-sm btn-group m-1">
                  <button class="btn-shadow btn btn-danger" (click)="Deletedata(con.id)"><i
                      class="pe-7s-trash"></i></button>
                  <!-- <button class="btn-shadow btn btn-primary">Delete</button> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <mat-paginator (page)="handlePageEvent($event)" [length]="consignmentlist.totalElements"
          [pageSize]="consignmentlist.numberOfElements" [showFirstLastButtons]="showFirstLastButtons"
          [pageSizeOptions]="pageSizeOptions" [pageIndex]="consignmentlist.number" aria-label="Select page">
        </mat-paginator>
      </div>

      <!-- <div class="d-block p-4 text-center card-footer">
        <button class="btn-pill btn-shadow btn-wide fsize-1 btn btn-dark btn-lg">
          <span class="me-2 opacity-7">
            <i class="fa fa-cog fa-spin"></i>
          </span>
          <span class="me-1">View Complete Report</span>
        </button>
      </div> -->
    </div>
  </some-element>
  <some-element *ngSwitchCase="'edit'">
    <div class="main-card mb-3 card">
      <div class="card-header">
        <div class="card-header-title font-size-lg text-capitalize fw-normal">
          Edit Consignments
        </div>
      </div>
      <div class="container ">
        <div class="row">
          <div class="col-md-2 fw-bold h4">Consignment Number</div>
          <div class="col-md-2 h4 text-primary">{{consignment_details.consignmentNo}}</div>
        </div>
        <div class="row">
          <div class="col-md-2">

            <mat-form-field style="width: 90%;" appearance="standard">
              <mat-label for="totalWeight">
                Invoice Reference No.<span class="text-danger">*</span>
              </mat-label>
              <input id="total_no_packages" matInput [(ngModel)]="consignment_details.invoiceReferenceNo">
            </mat-form-field>

          </div>
          <div class="col-md-2">

            <mat-form-field style="width: 90%;" appearance="standard">
              <mat-label>
                Invoice Date
              </mat-label>
              <input id="total_no_packages" matInput [(ngModel)]="consignment_details.invoiceCreatedDate"
                [max]="maxDate" [matDatepicker]="picker" disabled>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>

          </div>
          <div class="col-md-2">

            <mat-form-field style="width: 90%;" appearance="standard">
              <mat-label for="totalWeight">
                Consignment Value
              </mat-label>
              <input id="Consignment Value" matInput [(ngModel)]="consignment_details.totalConsignmentValue">
            </mat-form-field>

          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">

              <mat-label for="dimensionuUnits">
                Weight Divisor<span class="text-danger">*</span>
              </mat-label>
              <mat-select id="dimensionuUnits" [(ngModel)]="consignment_details.weightDivisor"
                (ngModelChange)="weightDivisorChange()">

                <mat-option *ngFor="let w_div of weightDivisorList " [value]="w_div">
                  {{w_div}}
                </mat-option>
                <!-- <mat-option value=1>
                  USD
                </mat-option> -->
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 row">
            <mat-form-field style="width: 90%;" appearance="standard">
              <mat-label for="totalWeight">
                Remarks
              </mat-label>
              <textarea id="Remarks" matInput [(ngModel)]="consignment_details.remarks">
								</textarea>
            </mat-form-field>

          </div>
        </div>
        <div class="row" style="width: 100%;">
          <mat-divider></mat-divider>
          <div class="table-responsive">
            <div class="table-responsive">
              <table class="table p-2 ">
                <thead>
                  <tr>

                    <th>
                      #
                    </th>
                    <th style="min-width: 150px;">
                      Package Dimentions
                    </th>
                    <th class="mx-w-150">
                      No of Packages
                    </th>
                    <th class="mx-w-150">
                      Weight per Package
                    </th>
                    <th class="mx-w-150">
                      Volume Weight
                    </th>
                    <th class="mx-w-200">
                      Total Packages Weight
                    </th>
                    <th class="mx-w-200">
                      Chargeable Weight
                    </th>
                    <th>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let package of changeable_packages">
                    <td>
                      {{package.id}}
                    </td>
                    <td class="mx-w-150">
                      <div class="row">

                        <!-- <mat-form-field class="m-0" style="width: 25%;" appearance="standard"> -->
                        <div class="col-md-4">

                          <input type="text" placeholder="L" aria-label="Number" matInput [(ngModel)]="package.length"
                            min="1" (blur)="eachPackageCharged(package.id)">
                          <span matSuffix>{{selectedDimention}}</span>
                        </div>
                        <div class="col-md-4">

                          <!-- </mat-form-field> -->
                          <!-- <mat-form-field class="p-1" style="width: 25%;" appearance="standard"> -->
                          <input type="text" placeholder="B" aria-label="Number" matInput [(ngModel)]="package.breadth"
                            min="1" (blur)="eachPackageCharged(package.id)">
                          <span matSuffix>{{selectedDimention}}</span>
                        </div>
                        <div class="col-md-4">

                          <!-- </mat-form-field> -->
                          <!-- <mat-form-field class="p-1" style="width: 25%;" appearance="standard"> -->

                          <input type="text" placeholder="H" aria-label="Number" matInput [(ngModel)]="package.height"
                            min="1" (blur)="eachPackageCharged(package.id)">
                          <span matSuffix>{{selectedDimention}}</span>
                          <!-- </mat-form-field> -->
                        </div>
                      </div>
                    </td>
                    <td class="mx-w-150">
                      <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                        <input type="text" placeholder="No of Packages" aria-label="Number" matInput
                          [(ngModel)]="package.package_no" min="1" (blur)="eachPackageCharged(package.id)">

                      </mat-form-field>
                    </td>
                    <td class="mx-w-150">

                      <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                        <input type="text" placeholder="package weight" aria-label="Number" matInput
                          [(ngModel)]="package.weight_per_package" min="1" (blur)="eachPackageCharged(package.id)">
                        <span matSuffix>{{ selectedWeight}}</span>
                      </mat-form-field>
                    </td>
                    <td class="mx-w-150">
                      <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                        <input type="text" placeholder="Volume Weight" matInput disabled
                          [(ngModel)]="package.volume_weight">
                      </mat-form-field>
                    </td>
                    <td class="mx-w-200">
                      <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                        <input placeholder="Total Package Weight" matInput disabled [(ngModel)]="package.total_weight">
                        <span matSuffix>{{ selectedWeight}}</span>

                      </mat-form-field>
                    </td>
                    <td class="mx-w-200">
                      <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                        <input placeholder="Chargeable Weight" matInput disabled
                          [(ngModel)]="package.chargeable_weight">
                        <span matSuffix>{{ selectedWeight}}</span>

                      </mat-form-field>
                    </td>
                    <!-- <td class="mx-w-150 align-middle">
                      <button class="btn btn-danger" (click)="deletePackage(package.id)">
                        <i class="pe-7s-trash">
                        </i>
                      </button>
                    </td> -->
                  </tr>
                  <tr>
                    <td class="mx-w-150">
                    </td>
                    <td class="mx-w-150">
                    </td>
                    <th class="mx-w-150">
                      <mat-label for="totalWeight">
                        Total No. Packages
                      </mat-label>
                      <mat-form-field style="width: 90%;" appearance="standard">
                        <input id="total_no_packages" matInput disabled value="Total No. Packages"
                          [(ngModel)]="consignment_details.totalNoOfPackages">
                      </mat-form-field>
                    </th>


                    <td>
                    </td>
                    <td>

                    </td>
                    <th class="mx-w-150">
                      <mat-label for="totalWeight">
                        Total Weight
                      </mat-label>
                      <mat-form-field style="width: 90%;" appearance="standard">
                        <input id="totalWeight" matInput disabled value="Google"
                          [(ngModel)]="consignment_details.totalWeight">
                        <span matSuffix>{{ selectedWeight}}</span>

                      </mat-form-field>
                    </th>
                    <th class="mx-w-200">
                      <mat-label for="totalWeight">
                        Total Chargeable Weight
                      </mat-label>
                      <mat-form-field style="width: 90%;" appearance="standard">
                        <input id="total_charged" matInput disabled value="Google"
                          [(ngModel)]="consignment_details.totalChargableWeight">
                        <span matSuffix>{{ selectedWeight}}</span>

                      </mat-form-field>
                    </th>
                    <!-- <td class="mx-w-200 align-middle">
                      <button class="btn btn-primary" (click)="addPackage()">
                        <i class="fa fa-plus">
                        </i>
                      </button>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row p-2">
            <div class="col-md-2 m-auto">
              <div class="btn btn-primary btn-lg m-2" (click)="saveChanges()">
                Save
              </div>
              <div class="btn btn-danger btn-lg m-2" (click)="viewchange('table',0)">
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="d-block p-4 text-center card-footer">
        <button class="btn-pill btn-shadow btn-wide fsize-1 btn btn-dark btn-lg">
          <span class="me-2 opacity-7">
            <i class="fa fa-cog fa-spin"></i>
          </span>
          <span class="me-1">View Complete Report</span>
        </button>
      </div> -->
    </div>
  </some-element>
</container-element>