<div *ngIf="Loading" class="loader-container">
  <ngx-loading [show]="Loading"
    [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '5px'}"
    [template]="loadingTemplate"></ngx-loading>
</div>
<div class="col-md-12">
  <container-element [ngSwitch]="pageview">
    <some-element *ngSwitchCase="'table'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            <b style="color: blue;">AIRLINE PRICES</b>
          </div>
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-primary" (click)="viewchange1('addindividual')">Add</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="viewchanges('add')">Bulk Upload</div>
          </div>
        </div>
        <div class="row p-4">
          <div class="col-md-3 row">
            <mat-form-field appearance="standard">
              <mat-label>
                Origin Location
              </mat-label>
              <input type="text" placeholder="Origin Location" aria-label="Number" matInput [formControl]="myControl"
                [matAutocomplete]="auto" [(ngModel)]="originsearch">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let cit of filteredAirports | async" [value]="cit">
                  {{cit.city.name}} - {{cit.iataCode}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-hint class="text-bold text-success" *ngIf="originsearch?.name != null"
              style="position: absolute; margin-top: 55px; font-size: smaller;">
              {{originsearch?.name}} - {{originsearch?.iataCode}}
            </mat-hint>
          </div>

          <div class="col-md-3 row">
            <mat-form-field appearance="standard">
              <mat-label>
                Destination Location
              </mat-label>
              <input type="text" placeholder="Destination Location" aria-label="Number" matInput
                [formControl]="myControl2" [matAutocomplete]="auto1" [(ngModel)]="Destinationsearch">
              <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn1">
                <mat-option *ngFor="let cit of filteredAirports2 | async" [value]="cit">
                  {{cit.city.name}} - {{cit.iataCode}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-hint class="text-bold text-success" *ngIf="Destinationsearch?.name != null"
              style="position: absolute; margin-top: 55px; font-size: smaller;">
              {{Destinationsearch?.name}} - {{Destinationsearch?.iataCode}}
            </mat-hint>
          </div>

          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Airline</mat-label>
              <input matInput placeholder="Search Airline" [(ngModel)]="Airlinesearch" [matAutocomplete]="auto2">
              <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn2">
                <mat-option *ngFor="let airlines2 of airlines3 | async | filter: Airlinesearch" [value]="airlines2">
                  {{airlines2.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Product</mat-label>
              <input matInput placeholder="Search Product" [(ngModel)]="ProductSearch" [matAutocomplete]="auto5">
              <mat-autocomplete #auto5="matAutocomplete" [displayWith]="displayFn5">
                <mat-option *ngFor="let ProductSearch of productyType | async | filter: ProductSearch"
                  [value]="ProductSearch">
                  {{ProductSearch.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Consignment Type</mat-label>
              <input matInput placeholder="Search Consignment Type" [(ngModel)]="ConsignmentTypeSearch"
                [matAutocomplete]="auto3">
              <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayFn3">
                <mat-option *ngFor="let consignmentType of consignmentType2 | async | filter: ConsignmentTypeSearch"
                  [value]="consignmentType">
                  {{consignmentType.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Consignment SubType</mat-label>
              <input matInput placeholder="Search Consignment SubType" [(ngModel)]="ConsignmentSubtypeSearch"
                [matAutocomplete]="auto4">
              <mat-autocomplete #auto4="matAutocomplete" [displayWith]="displayFn4">
                <mat-option
                  *ngFor="let consignmentSubType of consignmentSubType2 | async | filter: ConsignmentSubtypeSearch"
                  [value]="consignmentSubType">
                  {{consignmentSubType.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="col-md-1 ">
            <button class="btn btn-primary m-3" (click)="tablefilter()">Find</button>
          </div>
          <div class="col-md-1">
            <button class="btn btn-secondary m-3" (click)="reset()">Reset</button>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="">
              <div class="table-responsive">
                <!--                <ng-container>-->
                <table class="align-middle text-truncate mb-0 table table-borderless table-hover"
                  *ngIf="Airpicinglist?.content?.length > 0; else noRecordsMessage">
                  <thead>
                    <tr>
                      <th class="text-center">Origin</th>
                      <th class="text-center">Destination</th>
                      <th class="text-center">Airline</th>
                      <th class="text-center">Product Type</th>
                      <th class="text-center">Consignment Type</th>
                      <th class="text-center">Consignment Subtype</th>
                      <th class="text-center"> Action</th>
                      <!-- <th class="text-center">Additional SurchargePerkg</th>
                    <th class="text-center">SurchargeMin</th>
                    <th class="text-center">SurchargePerkg</th>
                    <th class="text-center">TariffAbove45</th>
                    <th class="text-center">TariffAbove100</th>
                    <th class="text-center">TariffAbove300</th>
                    <th class="text-center">TariffAbove500</th>
                    <th class="text-center">TariffMin</th>
                    <th class="text-center">TariffNRate</th>
                    <th class="text-center">ApplicableCommodity</th>
                    <th class="text-center">ApplicableFlights</th>
                    <th class="text-center">CansoulChargesMin</th>
                    <th class="text-center">CansoulChargesPerkg</th>
                    <th class="text-center">ColdChainMin</th>
                    <th class="text-center">ColdChainPerkg</th>
                    <th class="text-center">DangerousGoodsMin</th>
                    <th class="text-center">dangerousGoodsPerkg</th>
                    <th class="text-center">DemurageMin</th>
                    <th class="text-center">DemuragePerkg</th>
                    <th class="text-center">DiscountMin</th>
                    <th class="text-center">DiscountPerkg</th>
                    <th class="text-center">DoorDeliveryMin</th>
                    <th class="text-center">DoorDeliveryOda</th>
                    <th class="text-center">DoorDeliveryPerkg</th>
                    <th class="text-center">DoorPickupMin</th>
                    <th class="text-center">DoorPickupOpa</th>
                    <th class="text-center">DoorPickupPerkg</th>
                    <th class="text-center">DryiceFixed</th>
                    <th class="text-center">FreightAttachedConsignmentMin</th>
                    <th class="text-center">FreightAttachedConsignmentPerkg</th>
                    <th class="text-center">HeavyCargoMin</th>
                    <th class="text-center">HeavyCargoPerkg</th>
                    <th class="text-center">HumanRemainsMin</th>
                    <th class="text-center">HumanRemainsPerkg</th>
                    <th class="text-center">IclscmHandlingMin</th>
                    <th class="text-center">IclscmHandlingPerkg</th>
                    <th class="text-center">InboundCashTspMin</th>
                    <th class="text-center">InboundCashTspPerkg</th>
                    <th class="text-center">InboundHandlingChargesMin</th>
                    <th class="text-center">InboundHandlingChargesPerkg</th>
                    <th class="text-center">InboundTotalAhc</th>
                    <th class="text-center">InboundTspMin</th>
                    <th class="text-center">InboundTspPerkg</th>
                    <th class="text-center">InboundUnitMin</th>
                    <th class="text-center">inboundUnitPerkg</th>
                    <th class="text-center">LivestockMin</th>
                    <th class="text-center">LivestockPerkg</th>
                    <th class="text-center">LivestockPkgCharge</th>
                    <th class="text-center">MAWBDo</th>
                    <th class="text-center">mawbMiscFixed</th>
                    <th class="text-center">mawbMiscPerkg</th>
                    <th class="text-center">mawbOtherCharge</th>
                    <th class="text-center">mawbStatDa</th>
                    <th class="text-center">netRate100kgSlab</th>
                    <th class="text-center">OtherChargesFlighSurchargePerkg</th>
                    <th class="text-center">OtherChargesSectorSurchargePerkg</th>
                    <th class="text-center">OtherChargesSercurityPerkg</th>
                    <th class="text-center">OutboundCashTspMin</th>
                    <th class="text-center">OutboundCashTspPerkg</th>
                    <th class="text-center">OutboundHandlingChargesMin</th>
                    <th class="text-center">OutboundHandlingChargesPerkg</th>
                    <th class="text-center">OutboundOriginSccMin</th>
                    <th class="text-center">OutboundOriginSccPerkg</th>
                    <th class="text-center">OutboundTotalAhc</th>
                    <th class="text-center">OutboundTspMin</th>
                    <th class="text-center">OutboundTspPerkg</th>
                    <th class="text-center">OutboundUnitMin</th>
                    <th class="text-center">OutboundUnitPerkg</th>
                    <th class="text-center">OutboundXrayCertificationMin</th>
                    <th class="text-center">OutboundXrayCertificationPerkg</th>
                    <th class="text-center">OutboundXrayScreeningMin</th>
                    <th class="text-center">OutboundXrayScreeningPerkg</th>
                    <th class="text-center">PerishableChargeMin</th>
                    <th class="text-center">PerishableChargePerkg</th>
                    <th class="text-center">ShipmentValueMin</th>
                    <th class="text-center">ShipmentValuePerkg</th>
                    <th class="text-center">ShirmpsSeaFoodMin</th>
                    <th class="text-center">ShirmpsSeaFoodPerkg</th>
                    <th class="text-center">SpecialCargoMin</th>
                    <th class="text-center">SpecialCargoPerkg</th>
                    <th class="text-center">ValuveCargeMin</th>
                    <th class="text-center">ValuveCargoPerkg</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let con of Airpicinglist?.content" [ngClass]="con.isActive != false ? 'red':'white'">
                      <td class="text-center">
                        {{con.origin.name}}-{{con.origin.iataCode}}
                      </td>
                      <td class="text-center">
                        {{con.destination.name}}-{{con.destination.iataCode}}
                      </td>
                      <td class="text-center">
                        {{con.airline.name}}
                      </td>
                      <td class="text-center">
                        {{con.productType.name}}
                      </td>
                      <td class="text-center">
                        {{con.consignmentType.name}}
                      </td>
                      <td class="text-center">
                        {{con.applicableCommodity.name}}
                      </td>
                      <!-- <td class="text-center">
                      {{con.additionalSurchargePerkg}}
                    </td>
                    <td class="text-center">
                      {{con.airlineSurchargeMin}}
                    </td>
                    <td class="text-center">
                      {{con.airlineSurchargePerkg}}
                    </td>
                    <td class="text-center">
                      {{con.airlineTariffAbove45}}
                    </td>
                    <td class="text-center">
                      {{con.airlineTariffAbove100}}
                    </td>
                    <td class="text-center">
                      {{con.airlineTariffAbove300}}
                    </td>
                    <td class="text-center">
                      {{con.airlineTariffAbove500}}
                    </td>
                    <td class="text-center">
                      {{con.airlineTariffMin}}
                    </td>
                    <td class="text-center">
                      {{con.airlineTariffNRate}}
                    </td>
                    <td class="text-center">
                      {{con.applicableCommodity}}
                    </td>
                    <td class="text-center">
                      {{con.applicableFlights}}
                    </td>
                    <td class="text-center">
                      {{con.cansoulChargesMin}}
                    </td>
                    <td class="text-center">
                      {{con.cansoulChargesPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.coldChainMin}}
                    </td>
                    <td class="text-center">
                      {{con.coldChainPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.dangerousGoodsMin}}
                    </td>
                    <td class="text-center">
                      {{con.dangerousGoodsPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.demurageMin}}
                    </td>
                    <td class="text-center">
                      {{con.demuragePerkg}}
                    </td>
                    <td class="text-center">
                      {{con.discountMin}}
                    </td>
                    <td class="text-center">
                      {{con.discountPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.doorDeliveryMin}}
                    </td>
                    <td class="text-center">
                      {{con.doorDeliveryOda}}
                    </td>
                    <td class="text-center">
                      {{con.doorDeliveryPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.doorPickupMin}}
                    </td>
                    <td class="text-center">
                      {{con.doorPickupOpa}}
                    </td>
                    <td class="text-center">
                      {{con.doorPickupPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.dryiceFixed}}
                    </td>
                    <td class="text-center">
                      {{con.freightAttachedConsignmentMin}}
                    </td>
                    <td class="text-center">
                      {{con.freightAttachedConsignmentPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.heavyCargoMin}}
                    </td>
                    <td class="text-center">
                      {{con.heavyCargoPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.humanRemainsMin}}
                    </td>
                    <td class="text-center">
                      {{con.humanRemainsPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.iclscmHandlingMin}}
                    </td>
                    <td class="text-center">
                      {{con.iclscmHandlingPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.inboundCashTspMin}}
                    </td>
                    <td class="text-center">
                      {{con.inboundCashTspPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.inboundHandlingChargesMin}}
                    </td>
                    <td class="text-center">
                      {{con.inboundHandlingChargesPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.inboundTotalAhc}}
                    </td>
                    <td class="text-center">
                      {{con.inboundTspMin}}
                    </td>
                    <td class="text-center">
                      {{con.inboundTspPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.inboundUnitMin}}
                    </td>
                    <td class="text-center">
                      {{con.inboundUnitPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.livestockMin}}
                    </td>
                    <td class="text-center">
                      {{con.livestockPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.livestockPkgCharge}}
                    </td>
                    <td class="text-center">
                      {{con.mawbDo}}
                    </td>
                    <td class="text-center">
                      {{con.mawbMiscFixed}}
                    </td>
                    <td class="text-center">
                      {{con.mawbMiscPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.mawbOtherCharge}}
                    </td>
                    <td class="text-center">
                      {{con.mawbStatDa}}
                    </td>
                    <td class="text-center">
                      {{con.netRate100kgSlab}}
                    </td>
                    <td class="text-center">
                      {{con.otherChargesFlighSurchargePerkg}}
                    </td>
                    <td class="text-center">
                      {{con.otherChargesSectorSurchargePerkg}}
                    </td>
                    <td class="text-center">
                      {{con.otherChargesSercurityPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.outboundCashTspMin}}
                    </td>
                    <td class="text-center">
                      {{con.outboundCashTspPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.outboundHandlingChargesMin}}
                    </td>
                    <td class="text-center">
                      {{con.outboundHandlingChargesPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.outboundOriginSccMin}}
                    </td>
                    <td class="text-center">
                      {{con.outboundOriginSccPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.outboundTotalAhc}}
                    </td>
                    <td class="text-center">
                      {{con.outboundTspMin}}
                    </td>
                    <td class="text-center">
                      {{con.outboundTspPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.outboundUnitMin}}
                    </td>
                    <td class="text-center">
                      {{con.outboundUnitPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.outboundXrayCertificationMin}}
                    </td>
                    <td class="text-center">
                      {{con.outboundXrayCertificationPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.outboundXrayScreeningMin}}
                    </td>
                    <td class="text-center">
                      {{con.outboundXrayScreeningPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.perishableChargeMin}}
                    </td>
                    <td class="text-center">
                      {{con.perishableChargePerkg}}
                    </td>
                    <td class="text-center">
                      {{con.shipmentValueMin}}
                    </td>
                    <td class="text-center">
                      {{con.shipmentValuePerkg}}
                    </td>
                    <td class="text-center">
                      {{con.shirmpsSeaFoodMin}}
                    </td>
                    <td class="text-center">
                      {{con.shirmpsSeaFoodPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.specialCargoMin}}
                    </td>
                    <td class="text-center">
                      {{con.specialCargoPerkg}}
                    </td>
                    <td class="text-center">
                      {{con.valuveCargeMin}}
                    </td>
                    <td class="text-center">
                      {{con.valuveCargoPerkg}}
                    </td> -->
                      <td class="text-center">
                        <!-- <div role="group" class="btn-group-sm btn-group m-1">
                      <button class="btn-shadow btn btn-primary" matTooltip="Edit"
                        (click)="viewchange('edit',con)"><i class="lnr-pencil"></i></button>
                      </div> -->
                        <div role="group" class="btn-group-sm btn-group m-1">
                          <button class="btn-shadow btn btn-primary" matTooltip="View"
                            (click)="goToAirlinePricingViewPage(con.id)"><i class="lnr-eye"></i></button>
                        </div>
                        <div *ngIf="!displaytoAccountant" role="group" class="btn-group-sm btn-group m-1">
                          <span class="material-icons btn-shadow btn btn-danger" matTooltip="Delete"
                            (click)="DeleteAirlinePricing(con)"><i class="pe-7s-trash"></i></span>
                        </div>
                        <!-- <div role="group" *ngIf="(element.is_active == false)"
                        class="btn-group-sm btn-group m-1">
                        <span class="material-icons btn-shadow btn btn-success" matTooltip="Activate User" (click)="Restoredata(element.id)"> person_add_alt_1 </span>
                      </div> -->
                      </td>

                    </tr>
                  </tbody>

                </table>
                <!--                </ng-container>-->
                <!-- <mat-paginator (page)="handlePageEvent($event)" [length]="Airpicinglist?.totalElements"
                               [pageSize]="Airpicinglist?.numberOfElements"
                               [showFirstLastButtons]="showFirstLastButtons"
                               [pageSizeOptions]="pageSizeOptions" [pageIndex]="Airpicinglist?.number"
                               aria-label="Select page">
                </mat-paginator> -->
                <mat-paginator [length]="Airpicinglist?.totalElements" [pageIndex]="Airpicinglist?.number"
                  [pageSizeOptions]="[10, 20, 50]" [pageSize]="pageSize" (page)="handlePageEvent($event)"
                  showFirstLastButtons>
                </mat-paginator>

                <ng-template #noRecordsMessage>
                  <h1 class="text-center fw-bold">No Records Available..!</h1>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'add'">
      <div class="card p-4">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            <b style="color: blue;">UPLOAD AIRLINE PRICING</b>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-6 row">
              <mat-form-field appearance="standard">
                <mat-label>
                  Airline
                </mat-label>
                <mat-select id="dimention_units" [(ngModel)]="airline">
                  <mat-option *ngFor="let airl of airlinesList " [value]="airl.id">
                    {{airl.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-10 center p-1">
              <input class="form-control" type="file" (change)="getFiles($event)">

            </div>
          </div>
          <div class="row">
            <div class="col-md-2 float-right m-auto">
              <button class="btn btn-primary btn-lg" (click)="uploadDocument()">Upload</button>
              <div class="mb-2 me-2  btn-lg btn btn-danger m-2" (click)="viewchanges('table');">Cancel</div>
            </div>
          </div>
        </div>
      </div>

    </some-element>
    <some-element *ngSwitchCase="'view'">
      <div class="card col-md-12">

        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            <b style="color: blue;">VIEW AIRLINE PRICING</b>
          </div>
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-primary" (click)="viewchanges('edit')">Edit</div>
          </div>

        </div>

        <div class="container">
          <div class="col-md-12">
            <div *ngFor="let con of Airpicinglist?.data">
              <div class="row">
                <div class="col-md-12 my-3">
                  <h4><strong>Airline Pricing</strong></h4>
                </div>
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">TariffMin</div>
                <div class="col-md-1"> {{con.airlineTariffMin}} </div>
                <div class="col-md-3 fw-bold">TariffNRate</div>
                <div class="col-md-1"> {{con.airlineTariffNRate}} </div>
                <div class="col-md-3 fw-bold">TariffAbove45</div>
                <div class="col-md-1"> {{con.airlineTariffAbove45}} </div>
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">TariffAbove100</div>
                <div class="col-md-1"> {{con.airlineTariffAbove100}} </div>
                <div class="col-md-3 fw-bold">TariffAbove300</div>
                <div class="col-md-1"> {{con.airlineTariffAbove300}} </div>
                <div class="col-md-3 fw-bold">TariffAbove500</div>
                <div class="col-md-1"> {{con.airlineTariffAbove500}} </div>

                <!-- <div class="col-md-2 fw-bold">CansoulChargesMin</div>
                <div class="col-md-1"> {{con.cansoulChargesMin}} </div>
                <div class="col-md-2 fw-bold">CansoulChargesPerkg</div>
                <div class="col-md-1"> {{con.cansoulChargesPerkg}} </div>
                <div class="col-md-2 fw-bold">ColdChainMin</div>
                <div class="col-md-1"> {{con.coldChainMin}} </div> -->
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-12 my-3">
                  <h4><strong>Outbound Charges</strong></h4>
                </div>
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">OutboundTspPerkg</div>
                <div class="col-md-1"> {{con.outboundTspPerkg}} </div>
                <div class="col-md-3 fw-bold">OutboundTspMin</div>
                <div class="col-md-1"> {{con.outboundTspMin}} </div>
                <div class="col-md-3 fw-bold">OutboundCashTspPerkg</div>
                <div class="col-md-1"> {{con.outboundCashTspPerkg}} </div>
                <!-- <div class="col-md-2 fw-bold">ColdChainPerkg</div>
                <div class="col-md-1"> {{con.coldChainPerkg}} </div>
                <div class="col-md-2 fw-bold">DangerousGoodsMin</div>
                <div class="col-md-1"> {{con.dangerousGoodsMin}} </div>
                <div class="col-md-2 fw-bold">dangerousGoodsPerkg</div>
                <div class="col-md-1"> {{con.dangerousGoodsPerkg}} </div>
                <div class="col-md-2 fw-bold">DemurageMin</div>
                <div class="col-md-1"> {{con.demurageMin}} </div> -->
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">OutboundCashTspMin</div>
                <div class="col-md-1"> {{con.outboundCashTspMin}} </div>
                <div class="col-md-3 fw-bold">OutboundUnitPerkg</div>
                <div class="col-md-1"> {{con.outboundUnitPerkg}} </div>
                <div class="col-md-3 fw-bold">OutboundUnitMin</div>
                <div class="col-md-1"> {{con.outboundUnitMin}} </div>
                <!-- <div class="col-md-2 fw-bold">DemuragePerkg</div>
                <div class="col-md-1"> {{con.demuragePerkg}} </div>
                <div class="col-md-2 fw-bold">DiscountMin</div>
                <div class="col-md-1"> {{con.discountMin}} </div>
                <div class="col-md-2 fw-bold">DiscountPerkg</div>
                <div class="col-md-1"> {{con.discountPerkg}} </div>
                <div class="col-md-2 fw-bold">DoorDeliveryMin</div>
                <div class="col-md-1"> {{con.doorDeliveryMin}} </div> -->
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">OutboundHandlingChargesPerkg</div>
                <div class="col-md-1"> {{con.outboundHandlingChargesPerkg}} </div>
                <div class="col-md-3 fw-bold">OutboundHandlingChargesMin</div>
                <div class="col-md-1"> {{con.outboundHandlingChargesMin}} </div>
                <div class="col-md-3 fw-bold">OutboundXrayScreeningPerkg</div>
                <div class="col-md-1"> {{con.outboundXrayScreeningPerkg}} </div>
                <!-- <div class="col-md-2 fw-bold">DoorDeliveryOda</div>
                <div class="col-md-1"> {{con.doorDeliveryOda}} </div>
                <div class="col-md-2 fw-bold">DoorDeliveryPerkg</div>
                <div class="col-md-1"> {{con.doorDeliveryPerkg}} </div>
                <div class="col-md-2 fw-bold">DoorPickupMin</div>
                <div class="col-md-1"> {{con.doorPickupMin}} </div>
                <div class="col-md-2 fw-bold">DoorPickupOpa</div>
                <div class="col-md-1"> {{con.doorPickupOpa}} </div> -->
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">OutboundXrayScreeningMin</div>
                <div class="col-md-1"> {{con.outboundXrayScreeningMin}} </div>
                <div class="col-md-3 fw-bold">OutboundXrayCertificationPerkg</div>
                <div class="col-md-1"> {{con.outboundXrayCertificationPerkg}} </div>
                <div class="col-md-3 fw-bold">OutboundXrayCertificationMin</div>
                <div class="col-md-1"> {{con.outboundXrayCertificationMin}} </div>
                <!-- <div class="col-md-2 fw-bold">DoorPickupPerkg</div>
                <div class="col-md-1"> {{con.doorPickupPerkg}} </div>
                <div class="col-md-2 fw-bold">DryiceFixed</div>
                <div class="col-md-1"> {{con.dryiceFixed}} </div>
                <div class="col-md-2 fw-bold">FreightAttachedConsignmentMin</div>
                <div class="col-md-1"> {{con.freightAttachedConsignmentMin}} </div>
                <div class="col-md-2 fw-bold">FreightAttachedConsignmentPerkg</div>
                <div class="col-md-1"> {{con.freightAttachedConsignmentPerkg}} </div> -->
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">OutboundOriginSccPerkg</div>
                <div class="col-md-1"> {{con.outboundOriginSccPerkg}} </div>
                <div class="col-md-3 fw-bold">OutboundOriginSccMin</div>
                <div class="col-md-1"> {{con.outboundOriginSccMin}} </div>
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-12 my-3">
                  <h4><strong>Inbound Charges</strong></h4>
                </div>
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">InboundTspPerkg</div>
                <div class="col-md-1"> {{con.inboundTspPerkg}} </div>
                <div class="col-md-3 fw-bold">InboundTspMin</div>
                <div class="col-md-1"> {{con.inboundTspMin}} </div>
                <div class="col-md-3 fw-bold">InboundCashTspPerkg</div>
                <div class="col-md-1"> {{con.inboundCashTspPerkg}} </div>
                <!-- <div class="col-md-2 fw-bold">HeavyCargoMin</div>
                <div class="col-md-1"> {{con.heavyCargoMin}} </div>
                <div class="col-md-2 fw-bold">HeavyCargoPerkg</div>
                <div class="col-md-1"> {{con.heavyCargoPerkg}} </div>
                <div class="col-md-2 fw-bold">HumanRemainsMin</div>
                <div class="col-md-1"> {{con.humanRemainsMin}} </div>
                <div class="col-md-2 fw-bold">HumanRemainsPerkg</div>
                <div class="col-md-1"> {{con.humanRemainsPerkg}} </div> -->
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">InboundCashTspMin</div>
                <div class="col-md-1"> {{con.inboundCashTspMin}} </div>
                <div class="col-md-3 fw-bold">inboundUnitPerkg</div>
                <div class="col-md-1"> {{con.inboundUnitPerkg}} </div>
                <div class="col-md-3 fw-bold">InboundUnitMin</div>
                <div class="col-md-1"> {{con.inboundUnitMin}} </div>
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">InboundHandlingChargesPerkg</div>
                <div class="col-md-1"> {{con.inboundHandlingChargesPerkg}} </div>
                <div class="col-md-3 fw-bold">InboundHandlingChargesMin</div>
                <div class="col-md-1"> {{con.inboundHandlingChargesMin}} </div>
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-12 my-3">
                  <h4><strong>Other Charges</strong></h4>
                </div>
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">OtherChargesSecurityPerkg</div>
                <div class="col-md-1"> {{con.otherChargesSercurityPerkg}} </div>
                <div class="col-md-3 fw-bold">OtherChargesSectorSurchargePerkg</div>
                <div class="col-md-1"> {{con.otherChargesSectorSurchargePerkg}} </div>
                <div class="col-md-3 fw-bold">OtherChargesFlighSurchargePerkg</div>
                <div class="col-md-1"> {{con.otherChargesFlighSurchargePerkg}} </div>
              </div>
              <!-- <div class="row">
                <div class="col-md-2 fw-bold">InboundTotalAhc</div>
                <div class="col-md-1"> {{con.inboundTotalAhc}} </div>
              </div> -->
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-12 my-3">
                  <h4><strong>MAWB Charges</strong></h4>
                </div>
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">mawbStatDa</div>
                <div class="col-md-1"> {{con.mawbStatDa}} </div>
                <div class="col-md-3 fw-bold">MAWBDo</div>
                <div class="col-md-1"> {{con.mawbDo}} </div>
                <div class="col-md-3 fw-bold">mawbMiscPerkg</div>
                <div class="col-md-1"> {{con.mawbMiscPerkg}} </div>
                <!-- <div class="col-md-2 fw-bold">LivestockPerkg</div>
                <div class="col-md-1"> {{con.livestockPerkg}} </div>
                <div class="col-md-2 fw-bold">LivestockPkgCharge</div>
                <div class="col-md-1"> {{con.livestockPkgCharge}} </div> -->
              </div>
              <!-- <div class="row">
                <div class="col-md-2 fw-bold">LivestockMin</div>
                <div class="col-md-1"> {{con.livestockMin}} </div>
              </div> -->
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">mawbMiscFixed</div>
                <div class="col-md-1"> {{con.mawbMiscFixed}} </div>
                <div class="col-md-3 fw-bold">mawbOtherCharge</div>
                <div class="col-md-1"> {{con.mawbOtherCharge}} </div>
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-12 my-3">
                  <h4><strong>ICLSCM Charges</strong></h4>
                </div>
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">IclscmHandlingPerkg</div>
                <div class="col-md-1"> {{con.iclscmHandlingPerkg}} </div>
                <div class="col-md-3 fw-bold">IclscmHandlingMin</div>
                <div class="col-md-1"> {{con.iclscmHandlingMin}} </div>
                <!-- <div class="col-md-2 fw-bold">netRate100kgSlab</div>
                <div class="col-md-1"> {{con.netRate100kgSlab}} </div> -->
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-12 my-3">
                  <h4><strong>Airline Surcharges</strong></h4>
                </div>
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">SurchargeMin</div>
                <div class="col-md-1"> {{con.airlineSurchargeMin}} </div>
                <div class="col-md-3 fw-bold">SurchargePerkg</div>
                <div class="col-md-1"> {{con.airlineSurchargePerkg}} </div>
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-12 my-3">
                  <h4><strong>Additional Surcharges</strong></h4>
                </div>
              </div>
              <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-3 fw-bold">Additional SurchargePerkg</div>
                <div class="col-md-1"> {{con.additionalSurchargePerkg}} </div>
              </div>
              <!-- <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-2 fw-bold">OutboundTotalAhc</div>
                <div class="col-md-1"> {{con.outboundTotalAhc}} </div>
              </div>
              <mat-divider class="m-1"></mat-divider> -->
              <!-- <div class="row"></div> -->
              <!-- <mat-divider class="m-1"></mat-divider>
              <div class="row">
                <div class="col-md-2 fw-bold">PerishableChargeMin</div>
                <div class="col-md-1"> {{con.perishableChargeMin}} </div>
                <div class="col-md-2 fw-bold">PerishableChargePerkg</div>
                <div class="col-md-1"> {{con.perishableChargePerkg}} </div>
              </div>
              <mat-divider class="m-1"></mat-divider> -->
              <!-- <div class="row">
                <div class="col-md-2 fw-bold">ShipmentValueMin</div>
                <div class="col-md-1"> {{con.shipmentValueMin}} </div>
                <div class="col-md-2 fw-bold">ShipmentValuePerkg</div>
                <div class="col-md-1"> {{con.shipmentValuePerkg}} </div>
                <div class="col-md-2 fw-bold">ShirmpsSeaFoodMin</div>
                <div class="col-md-1"> {{con.shirmpsSeaFoodMin}} </div>
                <div class="col-md-2 fw-bold">ShirmpsSeaFoodPerkg</div>
                <div class="col-md-1"> {{con.shirmpsSeaFoodPerkg}} </div>
              </div> -->
              <!-- <mat-divider class="m-1"></mat-divider> -->
              <!-- <div class="row">
                <div class="col-md-2 fw-bold">SpecialCargoMin</div>
                <div class="col-md-1"> {{con.specialCargoMin}} </div>
                <div class="col-md-2 fw-bold">SpecialCargoPerkg</div>
                <div class="col-md-1"> {{con.specialCargoPerkg}} </div>
                <div class="col-md-2 fw-bold">ValuveCargeMin</div>
                <div class="col-md-1"> {{con.valuveCargeMin}} </div>
                <div class="col-md-2 fw-bold">ValuveCargoPerkg</div>
                <div class="col-md-1"> {{con.valuveCargoPerkg}} </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange1('table');">Cancel</div>
            <!-- <div class="mb-2 me-2 btn btn-primary" (click)="edituser()">Save</div> -->
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'edit'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            <b style="color: blue;">EDIT AIRLINE PRICING</b>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>Airline Pricing</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <!-- <div class="row"> -->
          <!-- <div class="col-md-2">
            <mat-form-field appearance="standard">
              <mat-label>
                Origin
              </mat-label>
              <input type="text" placeholder="Origin" aria-label="text" matInput [(ngModel)]="airprices.origin.name">
            </mat-form-field>
          </div> -->
          <!-- <Below is the commented code for pre-populating> -->
          <!-- <div class="col-md-2">
          <mat-form-field appearance="standard">
          <mat-label>Origin<span class="text-danger">*</span></mat-label>
          <mat-select matNativeControl id="origin" [(ngModel)]="airprices.origin.id">
            <mat-option *ngFor="let air of origin1" [value]="air.id">
            {{air.name}}-{{air.iataCode}}
          </mat-option>
          </mat-select>
          </mat-form-field>
          </div>  -->
          <!-- <div class="col-md-2 ">
            <mat-form-field appearance="standard">
              <mat-label>
                Destination
              </mat-label>
              <input type="text" placeholder="Destination" aria-label="text" matInput
                [(ngModel)]="airprices.destination.name">
            </mat-form-field>
          </div> -->
          <!-- <div class="col-md-2">
            <mat-form-field appearance="standard">
             <mat-label>Destination<span class="text-danger">*</span></mat-label>
              <mat-select matNativeControl id="destination" [(ngModel)]="airprices.destination.id">
              <mat-option *ngFor="let air of destination1" [value]="air.id">
                {{air.name}}-{{air.iataCode}}
              </mat-option>
            </mat-select>
            </mat-form-field>
          </div>  -->
          <!-- <div class="col-md-2 ">
            <mat-form-field appearance="standard">
              <mat-label>
                Airline
              </mat-label>
              <input type="text" placeholder="Airline" aria-label="text" matInput
              [(ngModel)]="airprices.airline.name">
            </mat-form-field>
          </div> -->
          <!-- <div class="col-md-2">
          <mat-form-field appearance="standard">
          <mat-label>Airline<span class="text-danger">*</span></mat-label>
          <mat-select matNativeControl id="airline" [(ngModel)]="airprices.airline.id">
          <mat-option *ngFor="let air of airlines1" [value]="air.id">
            {{air.name}}
          </mat-option>
          </mat-select>
          </mat-form-field>
        </div>  -->
          <!-- <div class="col-md-2">
            <mat-form-field appearance="standard">
              <mat-label>
                ConsignmentType
              </mat-label>
              <input type="text" placeholder=" ConsignmentType" aria-label="text" matInput
              [(ngModel)]="airprices.consignmentType.name">
            </mat-form-field>
          </div> -->
          <!-- <div class="col-md-2">
          <mat-form-field appearance="standard">
          <mat-label>ConsignmentType<span class="text-danger">*</span></mat-label>
          <mat-select matNativeControl id="consignment_type" [(ngModel)]="airprices.consignmentType.id">
          <mat-option *ngFor="let air of consignmentType1" [value]="air.id">
            {{air.name}}
          </mat-option>
          </mat-select>
          </mat-form-field>
        </div>  -->
          <!-- </div> -->
          <div class="row">
            <div class="col-md-4 ">
              <mat-form-field appearance="standard">
                <mat-label>
                  TariffMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder=" TariffMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.airlineTariffMin">
              </mat-form-field>
            </div>
            <div class="col-md-4 ">
              <mat-form-field appearance="standard">
                <mat-label>
                  TariffNRate<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder=" TariffNRate"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.airlineTariffNRate">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  TariffAbove45<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder=" TariffAbove45"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.airlineTariffAbove45">
              </mat-form-field>
            </div>
            <div class="col-md-4 ">
              <mat-form-field appearance="standard">
                <mat-label>
                  TariffAbove100<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder=" TariffAbove100"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.airlineTariffAbove100">
              </mat-form-field>
            </div>
            <div class="col-md-4 ">
              <mat-form-field appearance="standard">
                <mat-label>
                  TariffAbove300<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder=" TariffAbove300"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.airlineTariffAbove300">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  TariffAbove500<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder=" TariffAbove500"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.airlineTariffAbove500">
              </mat-form-field>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>Outbound Charges</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundTspPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundTspPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.outboundTspPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundTspMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundTspMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.outboundTspMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundCashTspPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundCashTspPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.outboundCashTspPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundCashTspMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundCashTspMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.outboundCashTspMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundUnitPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundUnitPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.outboundUnitPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundUnitMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundUnitMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.outboundUnitMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundHandlingChargesPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundHandlingChargesPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.outboundHandlingChargesPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundHandlingChargesMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundHandlingChargesMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.outboundHandlingChargesMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundXrayScreeningPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundXrayScreeningPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.outboundXrayScreeningPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundXrayScreeningMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundXrayScreeningMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.outboundXrayScreeningMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundXrayCertificationPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundXrayCertificationPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.outboundXrayCertificationPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundXrayCertificationMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundXrayCertificationMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.outboundXrayCertificationMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundOriginSccPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundOriginSccPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.outboundOriginSccPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundOriginSccMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundOriginSccMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.outboundOriginSccMin">
              </mat-form-field>
            </div>
            <!-- <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundTotalAhc
                </mat-label>
                <input type="text" placeholder="OutboundTotalAhc" aria-label="text" matInput
            [(ngModel)]="airprices.outboundTotalAhc">
              </mat-form-field>
            </div> -->
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>Inbound Charges</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundTspPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="InboundTspPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.inboundTspPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundTspMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="InboundTspMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.inboundTspMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundCashTspPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="InboundCashTspPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.inboundCashTspPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundCashTspMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="InboundCashTspMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.inboundCashTspMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundUnitPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="inboundUnitPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.inboundUnitPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundUnitMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="InboundUnitMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.inboundUnitMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundHandlingChargesPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="InboundHandlingChargesPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.inboundHandlingChargesPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundHandlingChargesMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="InboundHandlingChargesMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.inboundHandlingChargesMin">
              </mat-form-field>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>Other Charges</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OtherChargesSecurityPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OtherChargesSecurityPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.otherChargesSercurityPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OtherChargesSectorSurchargePerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OtherChargesSectorSurchargePerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.otherChargesSectorSurchargePerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OtherChargesFlightSurchargePerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OtherChargesFlightSurchargePerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.otherChargesFlighSurchargePerkg">
              </mat-form-field>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>MAWB Charges</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  mawbStatDa<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="mawbStatDa"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.mawbStatDa">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  mawbDo<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="MAWBDo" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  aria-label="text" matInput [(ngModel)]="airprices.mawbDo">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  mawbMiscPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="mawbMiscPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.mawbMiscPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  mawbMiscFixed<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="mawbMiscFixed"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.mawbMiscFixed">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  mawbOtherCharge<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="mawbOtherCharge"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.mawbOtherCharge">
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="row">
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    InboundTotalAhc
                  </mat-label>
                  <input type="text" placeholder="InboundTotalAhc" aria-label="text" matInput
          [(ngModel)]="airprices.inboundTotalAhc">
                </mat-form-field>
              </div>
          <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    LivestockMin
                  </mat-label>
                  <input type="text" placeholder="LivestockMin" aria-label="text" matInput
          [(ngModel)]="airprices.livestockMin">
                </mat-form-field>
              </div>
            </div> -->
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>ICLSCM Charges</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  IclscmHandlingPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="IclscmHandlingPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.iclscmHandlingPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  IclscmHandlingMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="IclscmHandlingMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.iclscmHandlingMin">
              </mat-form-field>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>Airline Surcharges</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  AirlineSurchargePerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="AirlineSurchargePerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.airlineSurchargePerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  AirlineSurchargeMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="AirlineSurchargeMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.airlineSurchargeMin">
              </mat-form-field>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>Additional Surcharges</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Additional SurchargePerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder=" Additional SurchargePerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="airprices.additionalSurchargePerkg">
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="row">
                  <div class="col-md-2">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        CansoulChargesMin
                      </mat-label>
                      <input type="text" placeholder=" CansoulChargesMin" aria-label="text" matInput
              [(ngModel)]="airprices.cansoulChargesMin">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        CansoulChargesPerkg
                      </mat-label>
                      <input type="text" placeholder=" CansoulChargesPerkg" aria-label="text" matInput
              [(ngModel)]="airprices.cansoulChargesPerkg">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        ColdChainMin
                      </mat-label>
                      <input type="text" placeholder="ColdChainMin" aria-label="text" matInput
              [(ngModel)]="airprices.coldChainMin">
                    </mat-form-field>
                  </div>
            <div class="col-md-2">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        ColdChainPerkg
                      </mat-label>
                      <input type="text" placeholder="ColdChainPerkg" aria-label="text" matInput
              [(ngModel)]="airprices.coldChainPerkg">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        DangerousGoodsMin
                      </mat-label>
                      <input type="text" placeholder="DangerousGoodsMin" aria-label="text" matInput
              [(ngModel)]="airprices.dangerousGoodsMin">
                    </mat-form-field>
                  </div>
                </div> -->
          <!-- <div class="row">

                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        dangerousGoodsPerkg
                      </mat-label>
                      <input type="text" placeholder="dangerousGoodsPerkg" aria-label="text" matInput
              [(ngModel)]="airprices.dangerousGoodsPerkg">
                    </mat-form-field>
                  </div>
            <div class="col-md-2">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        DemurageMin
                      </mat-label>
                      <input type="text" placeholder="DemurageMin" aria-label="text" matInput
              [(ngModel)]="airprices.demurageMin">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        DemuragePerkg
                      </mat-label>
                      <input type="text" placeholder="DemuragePerkg" aria-label="text" matInput
              [(ngModel)]="airprices.demuragePerkg">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        DiscountMin
                      </mat-label>
                      <input type="text" placeholder="DiscountMin" aria-label="text" matInput
              [(ngModel)]="airprices.discountMin">
                    </mat-form-field>
                  </div>
            <div class="col-md-2">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        DiscountPerkg
                      </mat-label>
                      <input type="text" placeholder="DiscountPerkg" aria-label="text" matInput
              [(ngModel)]="airprices.discountPerkg">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        DoorDeliveryMin
                      </mat-label>
                      <input type="text" placeholder="DoorDeliveryMin" aria-label="text" matInput
              [(ngModel)]="airprices.doorDeliveryMin">
                    </mat-form-field>
                  </div>
                </div> -->
          <!-- <div class="row">

                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        DoorDeliveryOda
                      </mat-label>
                      <input type="text" placeholder="DoorDeliveryOda" aria-label="text" matInput
              [(ngModel)]="airprices.doorDeliveryOda">
                    </mat-form-field>
                  </div>
            <div class="col-md-2">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        DoorDeliveryPerkg
                      </mat-label>
                      <input type="text" placeholder="DoorDeliveryPerkg" aria-label="text" matInput
              [(ngModel)]="airprices.doorDeliveryPerkg">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        DoorPickupMin
                      </mat-label>
                      <input type="text" placeholder="DoorPickupMin" aria-label="text" matInput
              [(ngModel)]="airprices.doorPickupMin">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        DoorPickupOpa
                      </mat-label>
                      <input type="text" placeholder="DoorPickupOpa" aria-label="text" matInput
              [(ngModel)]="airprices.doorPickupOpa">
                    </mat-form-field>
                  </div>
            <div class="col-md-2">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        DoorPickupPerkg
                      </mat-label>
                      <input type="text" placeholder="DoorPickupPerkg" aria-label="text" matInput
              [(ngModel)]="airprices.doorPickupPerkg">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        DryiceFixed
                      </mat-label>
                      <input type="text" placeholder="DryiceFixed" aria-label="text" matInput
              [(ngModel)]="airprices.dryiceFixed">
                    </mat-form-field>
                  </div>
                </div> -->
          <!-- <div class="row">

                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        FreightAttachedConsignmentMin
                      </mat-label>
                      <input type="text" placeholder="FreightAttachedConsignmentMin" aria-label="text" matInput
              [(ngModel)]="airprices.freightAttachedConsignmentMin">
                    </mat-form-field>
                  </div>
            <div class="col-md-2">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        FreightAttachedConsignmentPerkg
                      </mat-label>
                      <input type="text" placeholder="FreightAttachedConsignmentPerkg" aria-label="text" matInput
              [(ngModel)]="airprices.freightAttachedConsignmentPerkg">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        HeavyCargoMin
                      </mat-label>
                      <input type="text" placeholder="HeavyCargoMin" aria-label="text" matInput
              [(ngModel)]="airprices.heavyCargoMin">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        HeavyCargoPerkg
                      </mat-label>
                      <input type="text" placeholder="HeavyCargoPerkg" aria-label="text" matInput
              [(ngModel)]="airprices.heavyCargoPerkg">
                    </mat-form-field>
                  </div>
            <div class="col-md-2">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        HumanRemainsMin
                      </mat-label>
                      <input type="text" placeholder="HumanRemainsMin" aria-label="text" matInput
              [(ngModel)]="airprices.humanRemainsMin">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        HumanRemainsPerkg
                      </mat-label>
                      <input type="text" placeholder="HumanRemainsPerkg" aria-label="text" matInput
              [(ngModel)]="airprices.humanRemainsPerkg">
                    </mat-form-field>
                  </div>
                </div> -->
          <!-- <div class="row">
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        LivestockPerkg
                      </mat-label>
                      <input type="text" placeholder="LivestockPerkg" aria-label="text" matInput
              [(ngModel)]="airprices.livestockPerkg">
                    </mat-form-field>
                  </div>
            <div class="col-md-2">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        LivestockPkgCharge
                      </mat-label>
                      <input type="text" placeholder="LivestockPkgCharge" aria-label="text" matInput
              [(ngModel)]="airprices.livestockPkgCharge">
                    </mat-form-field>
                  </div>
                </div> -->
          <!-- <div class="row">
            <div class="col-md-2">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        netRate100kgSlab
                      </mat-label>
                      <input type="text" placeholder="netRate100kgSlab" aria-label="text" matInput
              [(ngModel)]="airprices.netRate100kgSlab">
                    </mat-form-field>
                  </div>
                </div> -->
          <!-- <div class="row">
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        PerishableChargeMin
                      </mat-label>
                      <input type="text" placeholder="PerishableChargeMin" aria-label="text" matInput
              [(ngModel)]="airprices.perishableChargeMin">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        PerishableChargePerkg
                      </mat-label>
                      <input type="text" placeholder="PerishableChargePerkg" aria-label="text" matInput
              [(ngModel)]="airprices.perishableChargePerkg">
                    </mat-form-field>
                  </div>
            <div class="col-md-2">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        ShipmentValueMin
                      </mat-label>
                      <input type="text" placeholder="ShipmentValueMin" aria-label="text" matInput
              [(ngModel)]="airprices.shipmentValueMin">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        ShipmentValuePerkg
                      </mat-label>
                      <input type="text" placeholder="ShipmentValuePerkg" aria-label="text" matInput
              [(ngModel)]="airprices.shipmentValuePerkg">
                    </mat-form-field>
                  </div>
                </div> -->
          <!-- <div class="row">
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        ShirmpsSeaFoodMin
                      </mat-label>
                      <input type="text" placeholder="ShirmpsSeaFoodMin" aria-label="text" matInput
              [(ngModel)]="airprices.shirmpsSeaFoodMin">
                    </mat-form-field>
                  </div>
            <div class="col-md-2">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        ShirmpsSeaFoodPerkg
                      </mat-label>
                      <input type="text" placeholder="ShirmpsSeaFoodPerkg" aria-label="text" matInput
              [(ngModel)]="airprices.shirmpsSeaFoodPerkg">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        SpecialCargoMin
                      </mat-label>
                      <input type="text" placeholder="SpecialCargoMin" aria-label="text" matInput
              [(ngModel)]="airprices.specialCargoMin">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        SpecialCargoPerkg
                      </mat-label>
                      <input type="text" placeholder="SpecialCargoPerkg" aria-label="text" matInput
              [(ngModel)]="airprices.specialCargoPerkg">
                    </mat-form-field>
                  </div>
            <div class="col-md-2">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        ValuveCargeMin
                      </mat-label>
                      <input type="text" placeholder="ValuveCargeMin" aria-label="text" matInput
              [(ngModel)]="airprices.valuveCargeMin">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 ">
                    <mat-form-field appearance="standard">
                      <mat-label>
                        ValuveCargoPerkg
                      </mat-label>
                      <input type="text" placeholder="ValuveCargoPerkg" aria-label="text" matInput
              [(ngModel)]="airprices.valuveCargoPerkg">
                    </mat-form-field>
                  </div>
                </div> -->
        </div>

        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange1('table');">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="editairpricing()">Save</div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'addindividual'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            <b style="color: blue;">ADD AIRLINE PRICING DETAILS</b>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>Origin<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl id="origin" [(ngModel)]="addairprices.origin">
                  <mat-option *ngFor="let air of origin1" [value]="air.id">
                    {{air.name}}-{{air.iataCode}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>Destination<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl id="destination" [(ngModel)]="addairprices.destination">
                  <mat-option *ngFor="let air of destination1" [value]="air.id">
                    {{air.name}}-{{air.iataCode}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>Airline<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl id="airline" [(ngModel)]="addairprices.airline">
                  <mat-option *ngFor="let air of airlines1" [value]="air.id">
                    {{air.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>Product Type<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl [(ngModel)]="selectedProductType">
                  <mat-option (click)="selectProductType()" *ngFor="let productType of productTypeList"
                    [value]="productType">
                    {{productType.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>ConsignmentType<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl id="consignment_type" [(ngModel)]="addairprices.consignmentType">
                  <mat-option (click)="selectConsignmentType(consignmentType)"
                    *ngFor="let consignmentType of consignmentTypes" [value]="consignmentType.id">
                    {{consignmentType.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>Consignment Subtype<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl [(ngModel)]="addairprices.consignmentSubtype">
                  <mat-option *ngFor="let consignmentSubType of consignmentSubTypes" [value]="consignmentSubType.id">
                    {{consignmentSubType.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>Airline Pricing</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4 ">
              <mat-form-field appearance="standard">
                <mat-label>
                  TariffMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder=" TariffMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.airlineTariffMin">
              </mat-form-field>
            </div>
            <div class="col-md-4 ">
              <mat-form-field appearance="standard">
                <mat-label>
                  TariffNRate<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder=" TariffNRate"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.airlineTariffNRate">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  TariffAbove45<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder=" TariffAbove45"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.airlineTariffAbove45">
              </mat-form-field>
            </div>
            <div class="col-md-4 ">
              <mat-form-field appearance="standard">
                <mat-label>
                  TariffAbove100<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder=" TariffAbove100"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.airlineTariffAbove100">
              </mat-form-field>
            </div>
            <div class="col-md-4 ">
              <mat-form-field appearance="standard">
                <mat-label>
                  TariffAbove300<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder=" TariffAbove300"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.airlineTariffAbove300">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  TariffAbove500<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder=" TariffAbove500"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.airlineTariffAbove500">
              </mat-form-field>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>Outbound Charges</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundTspPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundTspPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.outboundTspPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundTspMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundTspMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.outboundTspMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundCashTspPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundCashTspPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.outboundCashTspPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundCashTspMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundCashTspMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.outboundCashTspMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundUnitPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundUnitPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.outboundUnitPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundUnitMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundUnitMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.outboundUnitMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundHandlingChargesPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundHandlingChargesPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.outboundHandlingChargesPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundHandlingChargesMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundHandlingChargesMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.outboundHandlingChargesMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundXrayScreeningPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundXrayScreeningPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.outboundXrayScreeningPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundXrayScreeningMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundXrayScreeningMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.outboundXrayScreeningMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundXrayCertificationPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundXrayCertificationPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.outboundXrayCertificationPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundXrayCertificationMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundXrayCertificationMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.outboundXrayCertificationMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundOriginSccPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundOriginSccPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.outboundOriginSccPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OutboundOriginSccMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OutboundOriginSccMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.outboundOriginSccMin">
              </mat-form-field>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>Inbound Charges</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundTspPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="InboundTspPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.inboundTspPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundTspMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="InboundTspMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.inboundTspMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundCashTspPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="InboundCashTspPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.inboundCashTspPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundCashTspMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="InboundCashTspMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.inboundCashTspMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundUnitPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="inboundUnitPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.inboundUnitPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundUnitMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="InboundUnitMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.inboundUnitMin">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundHandlingChargesPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="InboundHandlingChargesPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.inboundHandlingChargesPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  InboundHandlingChargesMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="InboundHandlingChargesMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.inboundHandlingChargesMin">
              </mat-form-field>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>Other Charges</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OtherChargesSecurityPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OtherChargesSecurityPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.otherChargesSercurityPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OtherChargesSectorSurchargePerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OtherChargesSectorSurchargePerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.otherChargesSectorSurchargePerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OtherChargesFlightSurchargePerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OtherChargesFlightSurchargePerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.otherChargesFlighSurchargePerkg">
              </mat-form-field>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>MAWB Charges</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  mawbStatDa<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="mawbStatDa"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.mawbStatDa">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  mawbDo<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="MAWBDo" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  aria-label="text" matInput [(ngModel)]="addairprices.mawbDo">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  mawbMiscPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="mawbMiscPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.mawbMiscPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  mawbMiscFixed<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="mawbMiscFixed"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.mawbMiscFixed">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  mawbOtherCharge<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="mawbOtherCharge"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.mawbOtherCharge">
              </mat-form-field>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>ICLSCM Charges</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  IclscmHandlingPerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="IclscmHandlingPerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.iclscmHandlingPerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  IclscmHandlingMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="IclscmHandlingMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.iclscmHandlingMin">
              </mat-form-field>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>Airline Surcharges</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  AirlineSurchargePerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="AirlineSurchargePerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.airlineSurchargePerkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  AirlineSurchargeMin<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="AirlineSurchargeMin"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.airlineSurchargeMin">
              </mat-form-field>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-12 my-3">
              <h4><strong>Additional Surcharges</strong></h4>
            </div>
          </div>
          <mat-divider class="m-1"></mat-divider>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Additional SurchargePerkg<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder=" Additional SurchargePerkg"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="text" matInput
                  [(ngModel)]="addairprices.additionalSurchargePerkg">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="CancelCreating();">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="addairpricing()">Save</div>
          </div>
        </div>
      </div>
    </some-element>
    <!-- <some-element *ngSwitchCase="'addindividual'">
        <div class="card">
          <div class="card-header">
            <div class="card-header-title font-size-lg text-capitalize fw-Bold">
              <b style="color: blue;">ADD AIRPRICING DETAILS</b>
            </div>
          </div>
      <div class="container">
      <div class="row">
              <div class="col-md-2">
        <mat-form-field appearance="standard">
          <mat-label>Origin<span class="text-danger">*</span></mat-label>
          <mat-select matNativeControl id="origin" [(ngModel)]="airprices.origin">
            <mat-option *ngFor="let air of origin1" [value]="air.id">
              {{air.name}}-{{air.iataCode}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
            <div class="col-md-2">
        <mat-form-field appearance="standard">
          <mat-label>Destination<span class="text-danger">*</span></mat-label>
          <mat-select matNativeControl id="destination" [(ngModel)]="airprices.destination">
            <mat-option *ngFor="let air of destination1" [value]="air.id">
              {{air.name}}-{{air.iataCode}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
            <div class="col-md-2">
        <mat-form-field appearance="standard">
          <mat-label>Airline<span class="text-danger">*</span></mat-label>
          <mat-select matNativeControl id="airline" [(ngModel)]="airprices.airline">
            <mat-option *ngFor="let air of airlines1" [value]="air.id">
              {{air.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field appearance="standard">
          <mat-label>ConsignmentType<span class="text-danger">*</span></mat-label>
          <mat-select matNativeControl id="consignment_type" [(ngModel)]="airprices.consignmentType">
            <mat-option *ngFor="let air of consignmentType1" [value]="air.id">
              {{air.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Additional SurchargePerkg
                  </mat-label>
                  <input type="text" placeholder=" Additional SurchargePerkg" aria-label="text" matInput
          [(ngModel)]="airprices.additionalSurchargePerkg">
                </mat-form-field>
            </div>
            <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    SurchargeMin
                  </mat-label>
                  <input type="text" placeholder="SurchargeMin" aria-label="text" matInput
          [(ngModel)]="airprices.airlineSurchargeMin">
                </mat-form-field>
              </div>
            </div>
      <div class="row">
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    SurchargePerkg
                  </mat-label>
                  <input type="text" placeholder=" SurchargePerkg" aria-label="text" matInput
          [(ngModel)]="airprices.airlineSurchargePerkg">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    TariffAbove45
                  </mat-label>
                  <input type="text" placeholder=" TariffAbove45" aria-label="text" matInput
          [(ngModel)]="airprices.airlineTariffAbove45">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    TariffAbove100
                  </mat-label>
                  <input type="text" placeholder=" TariffAbove100" aria-label="text" matInput
          [(ngModel)]="airprices.airlineTariffAbove100">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    TariffAbove300
                  </mat-label>
                  <input type="text" placeholder=" TariffAbove300" aria-label="text" matInput
          [(ngModel)]="airprices.airlineTariffAbove300">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    TariffAbove500
                  </mat-label>
                  <input type="text" placeholder=" TariffAbove500" aria-label="text" matInput
          [(ngModel)]="airprices.airlineTariffAbove500">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    TariffMin
                  </mat-label>
                  <input type="text" placeholder=" TariffMin" aria-label="text" matInput
          [(ngModel)]="airprices.airlineTariffMin">
                </mat-form-field>
              </div>
            </div>
      <div class="row">
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    TariffNRate
                  </mat-label>
                  <input type="text" placeholder=" TariffNRate" aria-label="text" matInput
          [(ngModel)]="airprices.airlineTariffNRate">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    CansoulChargesMin
                  </mat-label>
                  <input type="text" placeholder=" CansoulChargesMin" aria-label="text" matInput
          [(ngModel)]="airprices.cansoulChargesMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    CansoulChargesPerkg
                  </mat-label>
                  <input type="text" placeholder=" CansoulChargesPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.cansoulChargesPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    ColdChainMin
                  </mat-label>
                  <input type="text" placeholder="ColdChainMin" aria-label="text" matInput
          [(ngModel)]="airprices.coldChainMin">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    ColdChainPerkg
                  </mat-label>
                  <input type="text" placeholder="ColdChainPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.coldChainPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    DangerousGoodsMin
                  </mat-label>
                  <input type="text" placeholder="DangerousGoodsMin" aria-label="text" matInput
          [(ngModel)]="airprices.dangerousGoodsMin">
                </mat-form-field>
              </div>
            </div>
      <div class="row">
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    dangerousGoodsPerkg
                  </mat-label>
                  <input type="text" placeholder="dangerousGoodsPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.dangerousGoodsPerkg">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    DemurageMin
                  </mat-label>
                  <input type="text" placeholder="DemurageMin" aria-label="text" matInput
          [(ngModel)]="airprices.demurageMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    DemuragePerkg
                  </mat-label>
                  <input type="text" placeholder="DemuragePerkg" aria-label="text" matInput
          [(ngModel)]="airprices.demuragePerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    DiscountMin
                  </mat-label>
                  <input type="text" placeholder="DiscountMin" aria-label="text" matInput
          [(ngModel)]="airprices.discountMin">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    DiscountPerkg
                  </mat-label>
                  <input type="text" placeholder="DiscountPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.discountPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    DoorDeliveryMin
                  </mat-label>
                  <input type="text" placeholder="DoorDeliveryMin" aria-label="text" matInput
          [(ngModel)]="airprices.doorDeliveryMin">
                </mat-form-field>
              </div>
            </div>
      <div class="row">
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    DoorDeliveryOda
                  </mat-label>
                  <input type="text" placeholder="DoorDeliveryOda" aria-label="text" matInput
          [(ngModel)]="airprices.doorDeliveryOda">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    DoorDeliveryPerkg
                  </mat-label>
                  <input type="text" placeholder="DoorDeliveryPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.doorDeliveryPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    DoorPickupMin
                  </mat-label>
                  <input type="text" placeholder="DoorPickupMin" aria-label="text" matInput
          [(ngModel)]="airprices.doorPickupMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    DoorPickupOpa
                  </mat-label>
                  <input type="text" placeholder="DoorPickupOpa" aria-label="text" matInput
          [(ngModel)]="airprices.doorPickupOpa">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    DoorPickupPerkg
                  </mat-label>
                  <input type="text" placeholder="DoorPickupPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.doorPickupPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    DryiceFixed
                  </mat-label>
                  <input type="text" placeholder="DryiceFixed" aria-label="text" matInput
          [(ngModel)]="airprices.dryiceFixed">
                </mat-form-field>
              </div>
            </div>
      <div class="row">
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    FreightAttachedConsignmentMin
                  </mat-label>
                  <input type="text" placeholder="FreightAttachedConsignmentMin" aria-label="text" matInput
          [(ngModel)]="airprices.freightAttachedConsignmentMin">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    FreightAttachedConsignmentPerkg
                  </mat-label>
                  <input type="text" placeholder="FreightAttachedConsignmentPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.freightAttachedConsignmentPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    HeavyCargoMin
                  </mat-label>
                  <input type="text" placeholder="HeavyCargoMin" aria-label="text" matInput
          [(ngModel)]="airprices.heavyCargoMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    HeavyCargoPerkg
                  </mat-label>
                  <input type="text" placeholder="HeavyCargoPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.heavyCargoPerkg">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    HumanRemainsMin
                  </mat-label>
                  <input type="text" placeholder="HumanRemainsMin" aria-label="text" matInput
          [(ngModel)]="airprices.humanRemainsMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    HumanRemainsPerkg
                  </mat-label>
                  <input type="text" placeholder="HumanRemainsPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.humanRemainsPerkg">
                </mat-form-field>
              </div>
            </div>
      <div class="row">
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    IclscmHandlingMin
                  </mat-label>
                  <input type="text" placeholder="IclscmHandlingMin" aria-label="text" matInput
          [(ngModel)]="airprices.iclscmHandlingMin">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    IclscmHandlingPerkg
                  </mat-label>
                  <input type="text" placeholder="IclscmHandlingPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.iclscmHandlingPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    InboundCashTspMin
                  </mat-label>
                  <input type="text" placeholder="InboundCashTspMin" aria-label="text" matInput
          [(ngModel)]="airprices.inboundCashTspMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    InboundCashTspPerkg
                  </mat-label>
                  <input type="text" placeholder="InboundCashTspPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.inboundCashTspPerkg">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    InboundHandlingChargesMin
                  </mat-label>
                  <input type="text" placeholder="InboundHandlingChargesMin" aria-label="text" matInput
          [(ngModel)]="airprices.inboundHandlingChargesMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    InboundHandlingChargesPerkg
                  </mat-label>
                  <input type="text" placeholder="InboundHandlingChargesPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.inboundHandlingChargesPerkg">
                </mat-form-field>
              </div>
            </div>
      <div class="row">
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    InboundTotalAhc
                  </mat-label>
                  <input type="text" placeholder="InboundTotalAhc" aria-label="text" matInput
          [(ngModel)]="airprices.inboundTotalAhc">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    InboundTspMin
                  </mat-label>
                  <input type="text" placeholder="InboundTspMin" aria-label="text" matInput
          [(ngModel)]="airprices.inboundTspMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    InboundTspPerkg
                  </mat-label>
                  <input type="text" placeholder="InboundTspPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.inboundTspPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    InboundUnitMin
                  </mat-label>
                  <input type="text" placeholder="InboundUnitMin" aria-label="text" matInput
          [(ngModel)]="airprices.inboundUnitMin">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    inboundUnitPerkg
                  </mat-label>
                  <input type="text" placeholder="inboundUnitPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.inboundUnitPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    LivestockMin
                  </mat-label>
                  <input type="text" placeholder="LivestockMin" aria-label="text" matInput
          [(ngModel)]="airprices.livestockMin">
                </mat-form-field>
              </div>
            </div>
      <div class="row">
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    LivestockPerkg
                  </mat-label>
                  <input type="text" placeholder="LivestockPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.livestockPerkg">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    LivestockPkgCharge
                  </mat-label>
                  <input type="text" placeholder="LivestockPkgCharge" aria-label="text" matInput
          [(ngModel)]="airprices.livestockPkgCharge">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    MAWBDo
                  </mat-label>
                  <input type="text" placeholder="MAWBDo" aria-label="text" matInput
          [(ngModel)]="airprices.mawbDo">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    mawbMiscFixed
                  </mat-label>
                  <input type="text" placeholder="mawbMiscFixed" aria-label="text" matInput
          [(ngModel)]="airprices.mawbMiscFixed">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    mawbMiscPerkg
                  </mat-label>
                  <input type="text" placeholder="mawbMiscPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.mawbMiscPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    mawbOtherCharge
                  </mat-label>
                  <input type="text" placeholder="mawbOtherCharge" aria-label="text" matInput
          [(ngModel)]="airprices.mawbOtherCharge">
                </mat-form-field>
              </div>
            </div>
      <div class="row">
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    mawbStatDa
                  </mat-label>
                  <input type="text" placeholder="mawbStatDa" aria-label="text" matInput
          [(ngModel)]="airprices.mawbStatDa">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    netRate100kgSlab
                  </mat-label>
                  <input type="text" placeholder="netRate100kgSlab" aria-label="text" matInput
          [(ngModel)]="airprices.netRate100kgSlab">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OtherChargesFlighSurchargePerkg
                  </mat-label>
                  <input type="text" placeholder="OtherChargesFlighSurchargePerkg" aria-label="text" matInput
          [(ngModel)]="airprices.otherChargesFlighSurchargePerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OtherChargesSectorSurchargePerkg
                  </mat-label>
                  <input type="text" placeholder="OtherChargesSectorSurchargePerkg" aria-label="text" matInput
          [(ngModel)]="airprices.otherChargesSectorSurchargePerkg">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OtherChargesSercurityPerkg
                  </mat-label>
                  <input type="text" placeholder="OtherChargesSercurityPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.otherChargesSercurityPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundCashTspMin
                  </mat-label>
                  <input type="text" placeholder="OutboundCashTspMin" aria-label="text" matInput
          [(ngModel)]="airprices.outboundCashTspMin">
                </mat-form-field>
              </div>
            </div>
      <div class="row">
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundCashTspPerkg
                  </mat-label>
                  <input type="text" placeholder="OutboundCashTspPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.outboundCashTspPerkg">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundHandlingChargesMin
                  </mat-label>
                  <input type="text" placeholder="OutboundHandlingChargesMin" aria-label="text" matInput
          [(ngModel)]="airprices.outboundHandlingChargesMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundHandlingChargesPerkg
                  </mat-label>
                  <input type="text" placeholder="OutboundHandlingChargesPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.outboundHandlingChargesPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundOriginSccMin
                  </mat-label>
                  <input type="text" placeholder="OutboundOriginSccMin" aria-label="text" matInput
          [(ngModel)]="airprices.outboundOriginSccMin">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundOriginSccPerkg
                  </mat-label>
                  <input type="text" placeholder="OutboundOriginSccPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.outboundOriginSccPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundTotalAhc
                  </mat-label>
                  <input type="text" placeholder="OutboundTotalAhc" aria-label="text" matInput
          [(ngModel)]="airprices.outboundTotalAhc">
                </mat-form-field>
              </div>
            </div>
      <div class="row">
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundTspMin
                  </mat-label>
                  <input type="text" placeholder="OutboundTspMin" aria-label="text" matInput
          [(ngModel)]="airprices.outboundTspMin">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundTspPerkg
                  </mat-label>
                  <input type="text" placeholder="OutboundTspPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.outboundTspPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundUnitMin
                  </mat-label>
                  <input type="text" placeholder="OutboundUnitMin" aria-label="text" matInput
          [(ngModel)]="airprices.outboundUnitMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundUnitPerkg
                  </mat-label>
                  <input type="text" placeholder="OutboundUnitPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.outboundUnitPerkg">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundXrayCertificationMin
                  </mat-label>
                  <input type="text" placeholder="OutboundXrayCertificationMin" aria-label="text" matInput
          [(ngModel)]="airprices.outboundXrayCertificationMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundXrayCertificationPerkg
                  </mat-label>
                  <input type="text" placeholder="OutboundXrayCertificationPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.outboundXrayCertificationPerkg">
                </mat-form-field>
              </div>
            </div>
      <div class="row">
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundXrayScreeningMin
                  </mat-label>
                  <input type="text" placeholder="OutboundXrayScreeningMin" aria-label="text" matInput
          [(ngModel)]="airprices.outboundXrayScreeningMin">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    OutboundXrayScreeningPerkg
                  </mat-label>
                  <input type="text" placeholder="OutboundXrayScreeningPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.outboundXrayScreeningPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    PerishableChargeMin
                  </mat-label>
                  <input type="text" placeholder="PerishableChargeMin" aria-label="text" matInput
          [(ngModel)]="airprices.perishableChargeMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    PerishableChargePerkg
                  </mat-label>
                  <input type="text" placeholder="PerishableChargePerkg" aria-label="text" matInput
          [(ngModel)]="airprices.perishableChargePerkg">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    ShipmentValueMin
                  </mat-label>
                  <input type="text" placeholder="ShipmentValueMin" aria-label="text" matInput
          [(ngModel)]="airprices.shipmentValueMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    ShipmentValuePerkg
                  </mat-label>
                  <input type="text" placeholder="ShipmentValuePerkg" aria-label="text" matInput
          [(ngModel)]="airprices.shipmentValuePerkg">
                </mat-form-field>
              </div>
            </div>
      <div class="row">
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    ShirmpsSeaFoodMin
                  </mat-label>
                  <input type="text" placeholder="ShirmpsSeaFoodMin" aria-label="text" matInput
          [(ngModel)]="airprices.shirmpsSeaFoodMin">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    ShirmpsSeaFoodPerkg
                  </mat-label>
                  <input type="text" placeholder="ShirmpsSeaFoodPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.shirmpsSeaFoodPerkg">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    SpecialCargoMin
                  </mat-label>
                  <input type="text" placeholder="SpecialCargoMin" aria-label="text" matInput
          [(ngModel)]="airprices.specialCargoMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    SpecialCargoPerkg
                  </mat-label>
                  <input type="text" placeholder="SpecialCargoPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.specialCargoPerkg">
                </mat-form-field>
              </div>
        <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>
                    ValuveCargeMin
                  </mat-label>
                  <input type="text" placeholder="ValuveCargeMin" aria-label="text" matInput
          [(ngModel)]="airprices.valuveCargeMin">
                </mat-form-field>
              </div>
              <div class="col-md-2 ">
                <mat-form-field appearance="standard">
                  <mat-label>
                    ValuveCargoPerkg
                  </mat-label>
                  <input type="text" placeholder="ValuveCargoPerkg" aria-label="text" matInput
          [(ngModel)]="airprices.valuveCargoPerkg">
                </mat-form-field>
              </div>
            </div>
      </div>
          <div class="card-footer">
            <div class="btn-actions-pane-right p-2">
              <div class="mb-2 me-2 btn btn-danger" (click)="viewchange1('table')">Cancel</div>
              <div class="mb-2 me-2 btn btn-primary" (click)="addairpricing()">Save</div>
            </div>
          </div>
        </div>
      </some-element> -->

  </container-element>
</div>