import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {getCitiesById, getCountries, getPincodesById, getStatesById} from '../../../Layout/getCities.js';
import {API_URL} from '../../../../../Global_API_URL.js';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {FormControl, Validators} from '@angular/forms';
import {HttpService} from "../../../services/http.service";

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: [],
})
export class RegisterComponent implements OnInit {
  indiviudal_user = {
    firstName: null,
    middleName: null,
    lastName: null,
    avatarUrl: null,
    email: null,
    phone: null,
    whatsapp: null,
    designation: null,
    billingAddress: {
      pincode: {
        pin_number: ''
      },
      address: ''
    },
    userName: null,
    valid: null,
    isActive: false
  }
  country_id;
  state_id;
  city_id;
  phoneNoExist = false;
  emailExist = false;
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };


  constructor(private router: Router, private route: ActivatedRoute, private httpService: HttpService) {
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      document.title = data.title || 'Signup - ICLTSCM';
    });
  }

  constriesList = getCountries();

  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: false,
  };

  stateList = [];
  cityList = [];
  user_pincodes = [];

  emailregex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
  emailFormControl = new FormControl('', [
    // Validators.required,
    Validators.pattern(this.emailregex),
  ]);

  async getstates() {
    if (this.country_id) {
      this.stateList = await getStatesById(this.country_id);
    }
    this.state_id = null;
    this.city_id = null;
    this.indiviudal_user.billingAddress.pincode.pin_number = null;
  }

  async getcity() {
    if (this.state_id) {
      this.cityList = await getCitiesById(this.state_id);
    }
    this.city_id = null;
    this.indiviudal_user.billingAddress.pincode.pin_number = null;
  }

  async getPincodes() {
    if (this.city_id) {
      this.user_pincodes = await getPincodesById(this.city_id);
    }
    this.indiviudal_user.billingAddress.pincode.pin_number = null;
  }

  async createCustomer() {
    this.indiviudal_user.userName = this.indiviudal_user.email;

    if (this.indiviudal_user.firstName == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The User\'s First Name ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.lastName == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The User\'s Last Name ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.email == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The User\'s Email ',
        timer: 5000,

      });
    } else if (!(this.emailregex.test(this.indiviudal_user.email))) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter a Valid User\'s Email ',
        timer: 5000,

      });
    } else if (this.emailExist) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'User\'s Email Already Exists',
        timer: 5000,

      });
    } else if (this.indiviudal_user.phone == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The User\'s Phone Number ',
        timer: 5000,

      });
    } else if (isNaN(this.indiviudal_user.phone) == true || this.indiviudal_user.phone.length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid User\'s Phone Number',
        timer: 5000,

      });
    } else if (this.indiviudal_user?.whatsapp?.length > 0 && this.indiviudal_user?.whatsapp?.length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid User\'s Whatsapp Number',
        timer: 5000,

      });
    } else if (this.indiviudal_user.billingAddress.address == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The User\'s Address ',
        timer: 5000,

      });
    } else if (this.country_id == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The User\'s Country ',
        timer: 5000,

      });
    } else if (this.state_id == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The User\'s State ',
        timer: 5000,

      });
    } else if (this.city_id == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The User\'s City ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.billingAddress.pincode.pin_number == null || this.indiviudal_user.billingAddress.pincode.pin_number == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The User\'s Zip Code ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.valid!= true) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Accept Terms And Conditons ',
        timer: 5000,
      });
    } else {
      this.loading = true;
      this.httpService.post(API_URL + '/register', this.indiviudal_user, null, null)
        .subscribe(response => {
          this.loading = false;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'A mail has been sent to you with the login credentials',
            timer: 5000,
          }).then(() => {
            this.goToLogin();
          });
        },error => {
          this.loading = false;
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Something went wrong',
            timer: 5000,
          })
        })
    }
  }


  goToLogin() {
    this.router.navigateByUrl('');
  }

  pdf() {
    Swal.fire({
      title: '<h1 style=\'color:blue\'><b>Terms and Conditions</b></h1>',
      html: `<iframe src="../../../../assets/images/Conditions of Carriage - ICL SCM - Terms and conditions.pdf" frameborder="25" width="100%" height="550"></iframe>`,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  async emailAppuser() {
    await fetch(API_URL + '/appuser/email/' + this.indiviudal_user.email,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          // "Authorization": "Bearer " + TOKEN,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((result) => {
        // console.log("User Exist");
        if (result.data[0] == null) {
          this.emailExist = false;
        } else {
          this.emailExist = true;
        }
        // alert("CustomerExist "+this.emailExist);
      })
      .catch((error) => {
        console.error('Error' + error);
      });
  }

  async phoneNoExistAppuser() {
    await fetch(API_URL + '/appuser/phone/' + this.indiviudal_user.phone,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          // "Authorization": "Bearer " + TOKEN,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((result) => {
        // console.log("User Exist");
        if (result.data[0] == null) {
          this.phoneNoExist = false;
        } else {
          this.phoneNoExist = true;
        }
        // alert("CustomerExist "+this.phoneNoExist);
      })
      .catch((error) => {
        console.error('Error' + error);
      });
  }
}
