import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {getCities, getCountries, getStates} from '../../getCities.js';

import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {getAirports} from '../../getAirportAirlines.js';
import {API_URL} from '../../../../../Global_API_URL';
import Swal from 'sweetalert2';
import {AuthService} from '../../../auth.service';
import {HttpService} from '../../../services/http.service';

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#d92550';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-airport-pricing-schedule',
  templateUrl: './airport-pricing-schedule.component.html',
  styleUrls: ['./airport-pricing-schedule.component.sass'],
})
export class AirportPricingScheduleComponent implements OnInit {
  constructor(private authService : AuthService, private httpService: HttpService) {
    this.authService.getAuthToken();
  }

  loading = false;
  pageview = 'table';

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  // airlines = getAirlines();
  states = getStates();
  countries = getCountries();
  cities = getCities();
  tarrifCategrory = this.gettarrifCategrory()
  airports = getAirports();
  airportPricing = this.getairportPricing();
  ngOnInit(): void {
  }

  newairlinePricing = {
    airportID: null,
    tariffCategory: null,
    outboundRate: null,
    outboundMinimumChanges: null,
    inboundRate: null,
    inboundMinimumChanges: null,
  }


  async createAirportPricing() {
    const TOKEN = this.authService.getAuthToken();
    this.loading = true;
    if (this.newairlinePricing.airportID == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please select an Airport',
        showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else if (this.newairlinePricing.tariffCategory == null) {
      Swal.fire({
        icon: 'error',
        title: 'Please select Tariff Category',
        showConfirmButton: false,
        timer: 5000,
      });
      this.loading = false;
    } else {
      this.httpService.post(API_URL + '/airport_pricing_schedules', this.newairlinePricing, null, null)
          .subscribe((result) => {
          // console.log(result);
            this.airportPricing= this.getairportPricing();
            this.viewchange('table');
          }, (error) => {
            console.error('Error', error);
          });
    }
    this.loading=false;
  }
  async gettarrifCategrory() {
    return [{
      'id': 1, 'name': 'General Cargo',
    },
    {
      'id': 2, 'name': 'HANDLING CHARGES',
    },
    ];
  }


  async getairportPricing() {
    const TOKEN = this.authService.getAuthToken();
    let airportps: any = [];
    this.httpService.get(API_URL + '/airport_pricing_schedules', null, null)
    // Converting received data to JSON
        .subscribe((json) => {
          airportps = json;
        });
    return airportps.data;
  }

  viewchange(val) {
    this.pageview = val;
  }
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
}

