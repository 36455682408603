import {Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {getCities, getCitiesById, getCountries, getPincodesById, getStates, getStatesById} from '../../Layout/getCities';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../auth.service';
import Swal from 'sweetalert2';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {API_URL} from '../../../../Global_API_URL';
import {HttpService} from '../../services/http.service';
import {CustomerVerificationService} from "../customer-verification/customer-verification.service";

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';
@Component({
  selector: 'app-create-new-representative',
  templateUrl: './create-new-representative.component.html',
  styleUrls: ['./create-new-representative.component.sass'],
})
export class CreateNewRepresentativeComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };

  constructor(public dialogRef: MatDialogRef<CreateNewRepresentativeComponent>,
              private httpService: HttpService,
              public customerVerification: CustomerVerificationService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.customer_shipper.orgCustomerId.id = this.data.customer;
  }
  async ngOnInit() {
    this.customerVerification.resetRetailCustomerData(this.customer_shipper);
    this.customerVerification.getCompaniesList();
    this.customerVerification.emailExist = false;
  }


  customer_shipper = {
    id: null,
    firstName: '',
    middleName: '',
    lastName: '',
    avatarUrl: '',
    email: '',
    phone: '',
    whatsapp: '',
    designation: null,
    billingAddress: {
      pincode: {
        pin_number: ''
      },
      address: ''
    },
    employmentNo: null,
    isSuperUser: null,
    userName: '',
    passwordHash: null,
    lastLoggedInAt: null,
    lastLoggedInIp: null,
    orgCustomerId: {id: null},
    aadhaarNumber: null,
    drivingLicense: null,
    panNumber: null,
    isActive: true,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
  }

  createRepresentative() {
    if (this.customer_shipper.firstName == null || this.customer_shipper.firstName == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The First Name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.lastName == null || this.customer_shipper.lastName == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Last Name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.email == null || this.customer_shipper.email == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Email',
        timer: 5000,
      });
      return;
    } else if (!(this.customerVerification.emailregex.test(this.customer_shipper.email))) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter A Valid Email',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.emailExist) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Email Already Exists',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.phone == null || this.customer_shipper.phone == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Phone No.',
        timer: 5000,
      });
      return;
    } else if ((this.customer_shipper.phone).length < 10 || isNaN(parseInt(this.customer_shipper.phone)) == true) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Phone No.',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.whatsapp.length > 0 && this.customer_shipper.whatsapp.length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Whatsapp No.',
      });
    } else if (this.customer_shipper.billingAddress.address == '' || this.customer_shipper.billingAddress.address == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Address',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_country == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Country',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_state == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The State',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_city == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The City',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.billingAddress.pincode.pin_number == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Zip Code',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper?.aadhaarNumber?.length > 0 && this.customer_shipper?.aadhaarNumber?.length < 12) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Aadhaar No. must be 12 digits',
        timer: 5000,
      });
    } else if (this.customer_shipper?.panNumber?.length > 0 && !this.customerVerification.panregex.test(this.customer_shipper.panNumber)) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter Valid PAN No. ',
        timer: 5000,
      });
    } else {
      this.loading = true;
      this.httpService.post(API_URL + '/representativeUser', this.customer_shipper, null, null)
        .subscribe(response => {
          this.loading = false;
          Swal.fire({
            icon: 'success',
            title: 'Representative Created',
            timer: 5000,
          }).then(() => {
            this.onNoClick();
          });
          // this.filtersrep();
          // this.viewchange('table', 0);
          // this.getRepIndividualList();
        }, error => {
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: 'Failed to create representative',
            timer: 5000,
          });
        })
    }
  }

  transformPANoUpperCaseForCreate(){
    this.customer_shipper.panNumber = this.customer_shipper.panNumber.toUpperCase();
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
