import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpService} from "../../../services/http.service";
import {CustomerVerificationService} from "../../../shared/customer-verification/customer-verification.service";
import Swal from "sweetalert2";
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from "ngx-loading";
import {API_URL} from "../../../../../Global_API_URL";
import {getCitiesById, getPincodesById, getStatesById} from "../../getCities.js";
import {ActivatedRoute} from "@angular/router";

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';
@Component({
  selector: 'app-user-management-edit',
  templateUrl: './user-management-edit.component.html',
  styleUrls: ['./user-management-edit.component.sass']
})
export class UserManagementEditComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  userId;

  constructor(private httpService: HttpService, public customerVerification: CustomerVerificationService, public activatedroute: ActivatedRoute) {
    this.activatedroute.params.subscribe(params => {
      this.userId = params['id']
    })
  }

  ngOnInit(): void {
    this.resetUser();
    this.getUserById();
    this.getuserRoles();
    this.customerVerification.getOfficeLocations();
    setTimeout(() => {
      this.loading = false; // Set loading to false after 2 seconds
    }, 2000);
  }


  userRoles: any;
  userEmail;
  userPhone;

  customer_shipper = {
    id: null,
    firstName: '',
    middleName: '',
    lastName: '',
    avatarUrl: '',
    email: '',
    phone: '',
    whatsapp: '',
    designation: null,
    userTypes: [{id: null}],
    employmentNo: null,
    officeLocation: {id: null},
    billingAddress: {
      pincode: {
        pin_number: ''
      },
      address: ''
    },
    isSuperUser: null,
    userName: '',
    passwordHash: null,
    lastLoggedInAt: null,
    lastLoggedInIp: null,
    isActive: true,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
  }

  getUserById(){
    this.httpService.get(API_URL + "/app_users/" + this.userId, null,null)
      .subscribe(response => {
        this.customer_shipper = response.data[0];

        this.userEmail = this.customer_shipper.email;
        this.userPhone = this.customer_shipper.phone;

        this.customerVerification.getPincode(this.customer_shipper.billingAddress?.pincode?.pin_number)
          .subscribe(response =>{
            this.customerVerification.pincodeData = response.pincodeData
            this.customerVerification.user_sel_country = this.customerVerification.pincodeData.city.state_id.country.id;
            this.customerVerification.user_sel_state = this.customerVerification.pincodeData.city.state_id.id;
            this.customerVerification.user_sel_city = this.customerVerification.pincodeData.city.id;
            this.customer_shipper.billingAddress.pincode.pin_number = this.customerVerification.pincodeData.pin_number;
            this.customerVerification.user_states = getStatesById(this.customerVerification.user_sel_country);
            this.customerVerification.user_cities = getCitiesById(this.customerVerification.user_sel_state);
            this.customerVerification.user_pincodes = getPincodesById(this.customerVerification.user_sel_city);
          });
      })
  }

  editUser() {
    if (this.customer_shipper.firstName == null || this.customer_shipper.firstName == '' || this.customer_shipper.firstName.trim().length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The First Name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.lastName == null || this.customer_shipper.lastName == '' || this.customer_shipper.lastName.trim().length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Last Name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.email == null || this.customer_shipper.email == '' || this.customer_shipper.email.trim().length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Email',
        timer: 5000,
      });
      return;
    } else if (!(this.customerVerification.emailregex.test(this.customer_shipper.email))) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter A Valid Email',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.emailExist) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Email Already Exists',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.phone == null || this.customer_shipper.phone == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Phone No.',
        timer: 5000,
      });
      return;
    } else if ((this.customer_shipper.phone).length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Phone No.',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.phoneNoExist) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Phone No. Already Exists',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper?.whatsapp?.length > 0 && this.customer_shipper?.whatsapp?.length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Whatsapp No.',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.billingAddress.address == null || this.customer_shipper.billingAddress.address == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Address',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_country == null || this.customerVerification.user_sel_country == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Country',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_state == null || this.customerVerification.user_sel_state == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The State',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_city == null || this.customerVerification.user_sel_city == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The City',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.billingAddress.pincode.pin_number == null || this.customer_shipper.billingAddress.pincode.pin_number == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Zip Code',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.userTypes[0].id == null || this.customer_shipper.userTypes[0].id == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The User Role',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.officeLocation.id == null || this.customer_shipper.officeLocation.id == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Office Location',
        timer: 5000,
      });
      return;
    } else {
      this.loading = true;
      this.httpService.patch(API_URL + '/user/' + this.userId, this.customer_shipper, null, null)
        .subscribe((response) => {
          this.loading = false;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'User Updated Successfully',
            timer: 5000,
          }).then(() => {
            this.goBackToUserTablePage();
          });
        }, (error) => {
          this.loading = false;
          console.error('Error', error);
        });
    }
  }

  emailExistAppuser(){
    if (this.userEmail != this.customer_shipper.email) {
      this.customerVerification.emailExistAppuser(this.customer_shipper.email);
    } else{
      this.customerVerification.emailExist = false;
    }
  }

  phoneNoExistAppuser(){
    if (this.userPhone != this.customer_shipper.phone) {
      this.customerVerification.phoneNoExistAppuser(this.customer_shipper.phone);
    } else{
      this.customerVerification.phoneNoExist = false;
    }
  }

  getuserRoles() {
    let userRoles;
    let array = [];
    this.httpService.get(API_URL + '/users_types', null, null)
      .subscribe(response => {
        array = Array.from(response.data);
        userRoles = response;
        userRoles.data = [];
        array.forEach((element) => {
          if (element.id != 5) {
            userRoles.data.push(element);
          }
        });
        this.userRoles = userRoles.data;
      })
  }

  resetUser(){
    this.customerVerification.emailExist = false;
    this.customerVerification.phoneNoExist = false;
    this.customerVerification.officesList = null;
    this.userRoles = null;
    this.customerVerification.user_sel_pincodes = null;
    this.customerVerification.user_sel_city = null;
    this.customerVerification.user_sel_state = null;
    this.customerVerification.user_sel_country = null;
    this.customer_shipper = {
      id: null,
      firstName: '',
      middleName: '',
      lastName: '',
      avatarUrl: '',
      email: '',
      phone: '',
      whatsapp: '',
      designation: null,
      userTypes: [{id: null}],
      employmentNo: null,
      officeLocation: {id: null},
      billingAddress: {
        pincode: {
          pin_number: ''
        },
        address: ''
      },
      isSuperUser: null,
      userName: '',
      passwordHash: null,
      lastLoggedInAt: null,
      lastLoggedInIp: null,
      isActive: true,
      createdAt: null,
      updatedAt: null,
      createdBy: null,
      updatedBy: null,
    }
  }

  goBackToUserTablePage(){
    history.back();
  }
}
