<h1 mat-dialog-title>Assign Consignment Sub-Type</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Select Consignment Subtype</mat-label>
    <mat-select [(ngModel)]="selectedConsignmentSubType">
      <mat-option *ngFor="let consignmentSubType of data; let i = index;" [value]="consignmentSubType">
        {{consignmentSubType.name}}
      </mat-option>
    </mat-select>
<!--    <mat-hint *ngIf="showWarning===true" class="text-danger" >Please Select a Consignment Type</mat-hint>-->
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="warn" style="color: white" (click)="onNoClick()">Cancel</button>
  <button mat-raised-button color="primary" [disabled]="selectedConsignmentSubType==null" style="color: white" [mat-dialog-close]="selectedConsignmentSubType" cdkFocusInitial>Confirm</button>
</div>
