<div>
  <h2 mat-dialog-title>Create new Consignment Type</h2>
  <div mat-dialog-content>
    <form [formGroup]="consignmentForm">
      <mat-form-field style="width: 15rem" appearance="fill">
        <mat-label>Enter Consignment Type Name</mat-label>
        <input matInput type="text" formControlName="name">
        <mat-hint class="text-danger" *ngIf="nameWarning" >Please enter a name</mat-hint>
      </mat-form-field>
      <mat-form-field style="width: 20rem" appearance="fill">
        <mat-label>Enter Consignment Type Details</mat-label>
        <textarea matInput type="text-box" formControlName="details"></textarea>
      </mat-form-field>

<!--        <mat-label>Enter Consignment Type Name</mat-label>-->
<!--        <input matInput type="text" formControlName="name">-->

    </form>
  </div>
</div>
<div mat-dialog-actions style="float: right">
  <button mat-raised-button color="warn" *ngIf="action==='create'" style="color: white" (click)="onCancelClick()">Cancel</button>
  <button mat-raised-button color="warn" *ngIf="action==='patch'" style="color: white" (click)="onCancelClick()">Discard</button>
  <button mat-raised-button color="primary" *ngIf="action==='create'" (click)="onSubmitClick()" cdkFocusInitial>Create</button>
  <button mat-raised-button color="primary" *ngIf="action==='patch'" (click)="onSubmitClick()" cdkFocusInitial>Save Changes</button>
</div>
