<div class="col-md-12">
  <container-element [ngSwitch]="pageview">
    <some-element *ngSwitchCase="'table'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            <b style="color: blue;">DOOR PRICES</b>
          </div>
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-primary" (click)="viewchange('createDoorPricing')">Create</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="viewchange('add')">Bulk Upload</div>
          </div>
        </div>

        <div class="row p-4">
          <div class="col-md-2">

            <mat-form-field class="py-0" appearance="standard">
              <mat-label>City<span class="text-danger">*</span></mat-label>
              <mat-select matNativeControl id="city_id" [(ngModel)]="city">
                <mat-option *ngFor="let city of Citynames" [value]="city.id">
                  {{city.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-1 ">
            <button class="btn btn-primary m-3" (click)="tablefilter()">Find</button>
          </div>
          <div class="col-md-1">
            <button class="btn btn-secondary m-3" (click)="reset()">Reset</button>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="">
              <div class="table-responsive">
                <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
                  <thead>
                    <tr>
                      <th class="text-center">City</th>
                      <th class="text-center">Inbound Min</th>
                      <th class="text-center">Inbound Perkg</th>
                      <th class="text-center">OOA Inbound Min</th>
                      <th class="text-center">OOA Inbound Perkg</th>
                      <th class="text-center">OOA Outbound Min</th>
                      <th class="text-center">OOA Outbound Perkg</th>
                      <th class="text-center">Outbound Min</th>
                      <th class="text-center">Outbound Perkg</th>
                      <th class="text-center">Action</th>


                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let con of DoorPricingList?.content">
                      <td class="text-center">
                        {{con.city_id.name}}
                      </td>
                      <td class="text-center">
                        {{con.inbound_min}}
                      </td>
                      <td class="text-center">
                        {{con.inbound_perkg}}
                      </td>
                      <td class="text-center">
                        {{con.oaa_inbound_min}}
                      </td>
                      <td class="text-center">
                        {{con.oaa_inbound_perkg}}
                      </td>
                      <td class="text-center">
                        {{con.oaa_outbound_min}}
                      </td>
                      <td class="text-center">
                        {{con.oaa_outbound_perkg}}
                      </td>
                      <td class="text-center">
                        {{con.outbound_min}}
                      </td>
                      <td class="text-center">
                        {{con.outbound_perkg}}
                      </td>
                      <td class="text-center">
                        <div role="group" class="btn-group-sm btn-group m-1">
                          <button class="btn-shadow btn btn-primary" matTooltip="Edit"
                            (click)="viewchange('edit',con)"><i class="lnr-pencil"></i></button>
                        </div>
                        <div role="group" *ngIf="(con.is_active == true)" class="btn-group-sm btn-group m-1">
                          <span class="material-icons btn-shadow btn btn-danger" matTooltip="Delete"
                            (click)="DeleteDoorPricing(con)"><i class="pe-7s-trash"></i></span>
                        </div>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                      [ngClass]="con.is_active == false ? 'red':'white'"></tr>
                    </td>
                    </tr>
                  </tbody>
                </table>
                <mat-paginator (page)="handlePageEvent($event)" [length]="DoorPricingList?.totalElements"
                  [pageSize]="DoorPricingList?.numberOfElements" [showFirstLastButtons]="showFirstLastButtons"
                  [pageSizeOptions]="pageSizeOptions" [pageIndex]="DoorPricingList?.number" aria-label="Select page">
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>



      </div>
    </some-element>
    <some-element *ngSwitchCase="'add'">
      <div class="card p-4">
        <div class="card-header">

          <div class="card-header-title font-size-lg text-capitalize fw-normal">
            <b style="color: blue;">UPLOAD DOOR PRICING</b>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-10 center p-5">
              <input class="form-control" type="file" (change)="getFiles($event)">

            </div>
          </div>
          <div class="row">
            <div class="col-md-2 float-right m-auto">
              <button class="btn btn-primary btn-lg" (click)="uploadDocument()">Upload</button>
              <div class="mb-2 me-2  btn-lg btn btn-danger m-2" (click)="viewchange('table')">Cancel</div>
            </div>
          </div>
        </div>
      </div>

      <ngx-loading [show]="Loading"
        [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
        [template]="loadingTemplate"></ngx-loading>
    </some-element>
    <some-element *ngSwitchCase="'edit'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            <b style="color: blue;">EDIT DOOR PRICING</b>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-4">

              <mat-form-field class="py-0" appearance="standard">
                <mat-label>City<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl id="city_id" [(ngModel)]="editDoorPricing.city_id.id" disabled>
                  <mat-option *ngFor="let city of Citynames" [value]="city.id">
                    {{city.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- <div class="col-md-4">
                <mat-form-field appearance="standard">
    <mat-label>
      City
    </mat-label>
                    <input type="text" placeholder="City" aria-label="text" matInput
                    [(ngModel)]="editDoorPricing.city_id.name">

  </mat-form-field>
            </div> -->
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Inbound Min<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Inbound Min"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="editDoorPricing.inbound_min">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Inbound PerKG<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Inbound PerKG"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="editDoorPricing.inbound_perkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OOA Inbound Min<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OOA Inbound Min"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="editDoorPricing.oaa_inbound_min">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OOA Inbound PerKG<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OAA Inbound PerKG"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="editDoorPricing.oaa_inbound_perkg">
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OOA Outbound Min<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OOA Outbound Min"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="editDoorPricing.oaa_outbound_min">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OOA Outbound PerKG<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OOA Outbound PerKG"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="editDoorPricing.oaa_outbound_perkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Outbound Min<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Outbound Min"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="editDoorPricing.outbound_min">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Outbound PerKG<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Outbound PerKG"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="editDoorPricing.outbound_perkg">
              </mat-form-field>
            </div>

          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="updateDoorPricing()">Save</div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'createDoorPricing'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            <b style="color: blue;">ADD DOOR PRICING</b>
          </div>
          <div class="btn-actions-pane-right p-2">
            <!-- <div class="mb-2 me-2 btn btn-primary" (click)="viewchange('table')">Back</div> -->
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-4">

              <mat-form-field class="py-0" appearance="standard">
                <mat-label>City<span class="text-danger">*</span></mat-label>
                <mat-select matNativeControl id="city_id" [(ngModel)]="addDoorPricing.city_id">
                  <mat-option *ngFor="let city of Citynames" [value]="city.id">
                    {{city.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- <div class="col-md-4">
                <mat-form-field appearance="standard">
    <mat-label>
      City
    </mat-label>
                    <input type="text" placeholder="City" aria-label="text" matInput
                    [(ngModel)]="editDoorPricing.city_id.name">

  </mat-form-field>
            </div> -->
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Inbound Min<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Inbound Min"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="addDoorPricing.inbound_min">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Inbound PerKG<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Inbound PerKG"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="addDoorPricing.inbound_perkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OAA Inbound Min<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OAA Inbound Min"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="addDoorPricing.oaa_inbound_min">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OAA Inbound PerKG<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OAA Inbound PerKG"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="addDoorPricing.oaa_inbound_perkg">
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OAA Outbound Min<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OAA Outbound Min"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="addDoorPricing.oaa_outbound_min">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  OAA Outbound PerKG<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="OAA Outbound PerKG"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="addDoorPricing.oaa_outbound_perkg">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Outbound Min<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Outbound Min"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="addDoorPricing.outbound_min">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="standard">
                <mat-label>
                  Outbound PerKG<span class="text-danger">*</span>
                </mat-label>
                <input type="text" placeholder="Outbound PerKG"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="8" aria-label="text"
                  matInput [(ngModel)]="addDoorPricing.outbound_perkg">
              </mat-form-field>
            </div>

          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table')">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="createDoorPricing()">Save</div>
          </div>
        </div>
      </div>
    </some-element>
  </container-element>
</div>