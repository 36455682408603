<mat-card>
        <mat-card-header>
          <mat-card-title ><h3 class="title">REGIONS</h3></mat-card-title>
          <div class="btn-actions-pane-right p-2">
            <button mat-raised-button color="primary" (click)="toggleEditMode()">
              Edit
            </button>
          </div>
        </mat-card-header>
        <div class="container">
          <div class="row">
            <div class="">
              <div class="table-responsive">
                <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
                  <thead>
                  <tr>
                    <th class="text-left">Regions</th>
                    <th class="text-left">States</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let region of regions; let regionIndex = index">
                    <td class="text-left">
                        {{ region.name }}

                    </td>
                    <td class="text-left">


                          <mat-chip-list>
                            <mat-chip *ngFor="let state of region.states">
                              {{ state.name }}
                              <!-- <mat-icon matChipRemove>cancel</mat-icon> -->
                            </mat-chip>
                          </mat-chip-list>


                    </td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
</mat-card>
