<!-- <p>bookings-upload works!</p> -->
<div class="card-header" style="display: flex; justify-content: space-between; align-items: center;">
  <div class="card-header-title font-size-lg text-capitalize fw-bold">
    <i class="fa fa-arrow-left" (click)="goBack()" style="cursor: pointer; color: blue; margin-right: 10px;"></i>
    <b style="color: blue;">BOOKINGS UPLOAD</b>
  </div>
  <button mat-button color="primary" class="download-btn" (click)="downloadCSVFormat()">
    <mat-icon>download</mat-icon>
    Bookings Upload Format
  </button>
</div>
<div *ngIf="Loading" class="loader-container">
  <ngx-loading [show]="Loading"
    [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
    [template]="loadingTemplate"></ngx-loading>
</div>
<container-element [ngSwitch]="pageview">
  <some-element *ngSwitchCase="'table'">
    <div class="card">
      <!-- <mat-horizontal-stepper [linear]="isLiner" #stepper="matHorizontalStepper">
        <mat-step label="Shipper"> -->
      <br>
      <table style="width: 100%;">
        <tr>
          <td style="width: 65%; vertical-align: top;">
            <div class="container ">
              <div class="row">
                <!-- <div class="col-md-3">
                      <mat-form-field appearance="standard">
                        <mat-label> Select Shipper <span class="text-danger">*</span> </mat-label>
                        <mat-select matNativeControl  (blur)="change_business()" [(ngModel)]="val">
                          <mat-option [value]="1"> Corporate User </mat-option>
                          <mat-option [value]="2"> Retail User </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div> -->
                <div class="col-md-3">
                  <mat-form-field appearance="standard">
                    <mat-label> Select Shipper <span class="text-danger">*</span></mat-label>
                    <input matInput value="Corporate User" disabled>
                  </mat-form-field>
                </div>
                <div class="col-md-5">
                  <ng-template #content4 let-c="close" let-d="dismiss">
                    <div class="modal-header">
                      <h4 class="modal-title"><b>Find Shipper</b></h4>
                      <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                      </button>
                    </div>
                    <div class="modal-body">
                      <div *ngIf="indiviudal_user.customerType == 2; else notbusinessuser">
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field class="example-full-width" appearance="standard" style="width: 400px;">
                              <mat-label>Corporate User Name<span class="text-danger">*</span></mat-label>
                              <span matSuffix> <i class="fa fa-times" aria-hidden="true" (click)="clearData()"
                                  style="font-size: larger; cursor: pointer"></i> </span>
                              <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto"
                                [(ngModel)]="customer_shipper">
                              <mat-autocomplete class="over" #auto="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let option of filteredOptions | async" (click)="findbusiness()"
                                  [value]="option" matTooltip="{{option.firstName}}">
                                  {{ option.firstName }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <ng-template #content6 let-c="close" let-d="dismiss">
                              <div class="modal-header">
                                <h4 class="modal-title"> Create Representative </h4>
                                <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                                </button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-4">
                                    <mat-form-field class="py-0" appearance="standard">
                                      <mat-label> First Name<span class="text-danger">*</span></mat-label>
                                      <input type="text" placeholder="First Name" aria-label="Number" matInput
                                        [(ngModel)]="indiviudal_user.firstName">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-md-4">
                                    <mat-form-field class="py-0" appearance="standard">
                                      <mat-label> Middle Name</mat-label>
                                      <input type="text" placeholder="Middle Name" aria-label="Number" matInput
                                        [(ngModel)]="indiviudal_user.middleName">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-md-4">
                                    <mat-form-field class="py-0" appearance="standard">
                                      <mat-label> Last Name <span class="text-danger">*</span></mat-label>
                                      <input type="text" placeholder="Last Name" aria-label="Number" matInput
                                        [(ngModel)]="indiviudal_user.lastName">
                                    </mat-form-field>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">
                                    <mat-form-field appearance="standard">
                                      <mat-label> E-mail <span class="text-danger">*</span></mat-label>
                                      <input type="text" placeholder="Email" aria-label="Number" matInput
                                        [(ngModel)]="indiviudal_user.email" (blur)="emailAppuser()"
                                        [formControl]="emailFormControl">
                                      <mat-hint class="text-danger fw-normal"
                                        *ngIf="emailFormControl.hasError('pattern')">Invalid
                                        email address
                                      </mat-hint>
                                      <mat-hint class="text-danger fw-bold" *ngIf="emailExist==true">Email
                                        Exist
                                      </mat-hint>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-md-4">
                                    <mat-form-field appearance="standard">
                                      <mat-label> Phone No .<span class="text-danger">*</span></mat-label>
                                      <input type="text" placeholder="Phone Number"
                                        oninput="if(!this.value.match('^[0-9]*$'))this.value='';" aria-label="Number"
                                        matInput #phone maxlength="10" [(ngModel)]="indiviudal_user.phone"
                                        (blur)="phoneNoExistAppuser()">
                                      <mat-hint class="text-danger fw-bold" *ngIf="phoneNoExist==true">Phone No.
                                        Exist
                                      </mat-hint>
                                      <mat-hint align="end"> {{ phone.value.length }}/10</mat-hint>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-md-4">
                                    <mat-form-field appearance="standard">
                                      <mat-label> Whatsapp No.</mat-label>
                                      <input type="text" placeholder="Whatsapp No." aria-label="Number" matInput
                                        [(ngModel)]="indiviudal_user.whatsapp">
                                    </mat-form-field>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">
                                    <mat-form-field class="example-full-width" appearance="standard">
                                      <mat-label> Address <span class="text-danger">*</span></mat-label>
                                      <textarea matInput placeholder="Address"
                                        [(ngModel)]="indiviudal_user.billingAddress.address"></textarea>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-md-4">
                                    <mat-form-field appearance="standard">
                                      <mat-label> Country <span class="text-danger">*</span></mat-label>
                                      <mat-select matNativeControl [(ngModel)]="user_sel_country">
                                        <mat-option *ngFor="let con of countries |async" (click)="getIndividaulStates()"
                                          [value]="con.id">
                                          {{ con.name }}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-md-4">
                                    <mat-form-field appearance="standard">
                                      <mat-label> State <span class="text-danger">*</span></mat-label>
                                      <mat-select matNativeControl [(ngModel)]="user_sel_state">
                                        <mat-option *ngFor="let sta of user_states |async"
                                          (click)="getIndividaulCities()" [value]="sta.id">
                                          {{ sta.name }}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">
                                    <mat-form-field appearance="standard">
                                      <mat-label> City <span class="text-danger">*</span></mat-label>
                                      <mat-select matNativeControl [(ngModel)]="user_sel_city">
                                        <mat-option *ngFor="let cit of user_cities |async"
                                          (click)="getIndividualPincodes()" [value]="cit.id">
                                          {{ cit.name }}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-md-4">
                                    <mat-form-field appearance="standard">
                                      <mat-label> Zip Code</mat-label>
                                      <mat-select matNativeControl
                                        [(ngModel)]="indiviudal_user.billingAddress.pincode.pin_number">
                                        <mat-option *ngFor="let cit of user_pincodes |async" [value]="cit.pinNumber">
                                          {{ cit.pinNumber }}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <mat-form-field class="py-0" appearance="standard">
                                        <mat-label> Aadhaar Number</mat-label>
                                        <input type="text" placeholder="Aadhaar Number"
                                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                          aria-label="Number" matInput maxlength="12" #aadhaarnum1
                                          [(ngModel)]="indiviudal_user.aadhaarNumber">
                                        <mat-hint align="">{{ aadhaarnum1.value.length }}/12</mat-hint>
                                      </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                      <mat-form-field appearance="standard">
                                        <mat-label> Driving License</mat-label>
                                        <input type="text" placeholder="Driving License" aria-label="text" matInput
                                          [(ngModel)]="indiviudal_user.drivingLicense">
                                      </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                      <mat-form-field class="py-0" appearance="standard">
                                        <mat-label> PAN Number</mat-label>
                                        <input type="text" placeholder="PAN Number" aria-label="Number" matInput
                                          [(ngModel)]="indiviudal_user.panNumber">
                                      </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                      <mat-form-field class="py-0" appearance="standard">
                                        <mat-label> GST Number</mat-label>
                                        <input type="text" placeholder="GST Number" aria-label="Number" matInput
                                          [(ngModel)]="indiviudal_user.gstNumber">
                                      </mat-form-field>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close
                                </button>
                                <button *ngIf="customer_created==false" type="button" class="btn btn-primary"
                                  (click)="createRep()">Create
                                </button>
                              </div>
                              <ngx-loading [show]="Representativeload"
                                [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                                [template]="loadingTemplate"></ngx-loading>
                            </ng-template>
                          </div>
                        </div>
                        <div *ngIf="customer_shipper.id && show">
                          <div class="row">
                            <div class="col-md-3 fw-bold"> Business Name</div>
                            <div class="col-md-4"> {{ customer_shipper.firstName }}</div>
                          </div>
                          <div class="row">
                            <div class="col-md-3 fw-bold"> Address</div>
                            <div class="col-md-4"> {{ customer_shipper.address_1 }}, {{ customer_shipper.zip_code }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-3 fw-bold"> Email</div>
                            <div class="col-md-4"> {{ customer_shipper.email_1 }}</div>
                          </div>
                          <div class="row">
                            <div class="col-md-3 fw-bold"> Phone</div>
                            <div class="col-md-4"> {{ customer_shipper.phone_1 }}</div>
                          </div>
                          <div class="h4"> Representative List
                            <button class="btn btn-primary m-1" (click)="open(content6);resetform()">
                              Create Representative
                            </button>
                          </div>
                          <div class="row overflow-auto">
                            <div style="max-height: 200px; overflow: auto;">
                              <table
                                class="align-middle text-truncate figure-caption mb-0 table table-borderless table-hover ">
                                <thead>
                                  <tr>
                                    <th class="text-center">Representative Id</th>
                                    <th class="text-center">Name</th>
                                    <th class="text-center">Address</th>
                                    <th class="text-center">City</th>
                                    <th class="text-center">Email</th>
                                    <th class="text-center">Phone</th>
                                    <th class="text-center"></th>
                                  </tr>
                                </thead>
                                <tbody *ngIf="representativeList!=null">
                                  <tr *ngFor="let rep of representativeList"
                                    [ngStyle]="{'background-color': (rep.id==consignment_details.senderIndividual) ? '#abd8ff' : '#ffffff'}">
                                    <td class="text-center text-muted" style="width: 10%;"> #{{ rep.id }}</td>
                                    <td class="text-center text-muted" style="width: 20%;">
                                      {{ rep?.firstName }}
                                    </td>
                                    <td class="text-center text-muted" style="width: 30%;">
                                      <div style="white-space: pre-wrap;word-wrap: break-word;">
                                        {{ rep.billingAddress?.address }}
                                      </div>
                                    </td>
                                    <td class="text-center text-muted" style="width: 10%;">
                                      <div *ngIf="rep?.billingAddress?.pincodeId?.cityId?.name"
                                        style="white-space: pre-wrap;word-wrap: break-word;">
                                        {{ rep?.billingAddress?.pincodeId?.cityId?.name }}
                                      </div>
                                    </td>
                                    <td class="text-center text-muted" style="width: 15%;">
                                      <div style="white-space: pre-wrap;word-wrap: break-word;">
                                        {{ rep.email }}
                                      </div>
                                    </td>
                                    <td class="text-center text-muted" style="width: 10%;">
                                      {{ rep.phone }}
                                    </td>
                                    <td class="text-center text-muted" style="width: 5%;">
                                      <button type="button" class="btn btn-success"
                                        (click)="selectRep(rep.id); d('Cross click')">
                                        Select
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ng-template #notbusinessuser>
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field class="example-full-width" appearance="standard" style="width: 400px;">
                              <mat-label>Individual Name</mat-label>
                              <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto"
                                [(ngModel)]="indiviudal_user">
                              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnss">
                                <mat-option *ngFor="let option of filteredOption | async" [value]="option"
                                  matTooltip="{{option.first_name}}">
                                  {{ option.first_name }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <button class="btn btn-primary m-1 float-end" (click)="findindividual()">
                              <i class="pe-7s-search"> </i>
                            </button>
                          </div>
                        </div>
                        <div *ngIf="indiviudal_user.id && show">
                          <div class="row">
                            <div class="col-md-3 fw-bold"> Name</div>
                            <div class="col-md-3"> {{ indiviudal_user.firstName }} {{ indiviudal_user.lastName }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-3 fw-bold"> Address</div>
                            <div class="col-md-3"> {{ indiviudal_user.billingAddress.address }}
                              , {{ indiviudal_user.billingAddress.pincode.pin_number }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-3 fw-bold"> Email</div>
                            <div class="col-md-3"> {{ indiviudal_user.email }}</div>
                          </div>
                          <div class="row">
                            <div class="col-md-3 fw-bold"> Phone</div>
                            <div class="col-md-3"> {{ indiviudal_user.phone }}</div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
                    </div>
                  </ng-template>
                  <button class="btn btn-primary m-1" (click)="open(content4);createnewformreset()"> Find
                    <i class="pe-7s-search"></i>
                  </button>
                  <ng-template #content5 let-c="close" let-d="dismiss">
                    <div class="modal-header">
                      <h4 class="modal-title">Create Customer</h4>
                      <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-4">
                          <mat-form-field appearance="standard">
                            <mat-label> Customer</mat-label>
                            <mat-select matNativeControl (blur)="change_business()" [(ngModel)]="val">
                              <mat-option [value]="1"> Corporate User</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-4">
                          <button class="btn btn-primary m-3">Select</button>
                        </div>
                      </div>
                      <div *ngIf="indiviudal_user.customerType == 2; else notbusinessuser">
                        <div class="h4"> Corporate Customer Details</div>
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field class="py-0" appearance="standard">
                              <mat-label> Name <span class="text-danger">*</span></mat-label>
                              <input type="text" placeholder="Name" aria-label="Number" matInput
                                [(ngModel)]="customer_shipper.firstName" (blur)="nameExist()">
                              <mat-hint class="text-danger fw-bold" *ngIf="CustomerExist==true">Name
                                Exist
                              </mat-hint>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> E-mail</mat-label>
                              <input type="text" placeholder="Email" aria-label="Number" matInput
                                [(ngModel)]="customer_shipper.email_1" [formControl]="emailFormControl">
                              <mat-hint class="text-danger fw-normal"
                                *ngIf="emailFormControl.hasError('pattern')">Invalid Email Address
                              </mat-hint>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> Phone No.</mat-label>
                              <input type="text" placeholder="Phone No."
                                oninput="if(!this.value.match('^[0-9]*$'))this.value='';" aria-label="Number" matInput
                                #phone1 maxlength="10" [(ngModel)]="customer_shipper.phone_1">
                              <mat-hint align="end">{{ phone1.value.length }}/10
                              </mat-hint>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field class="example-full-width" appearance="standard">
                              <mat-label> Address <span class="text-danger">*</span></mat-label>
                              <textarea matInput placeholder="Address"
                                [(ngModel)]="customer_shipper.address_1"></textarea>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> Country <span class="text-danger">*</span></mat-label>
                              <mat-select matNativeControl [(ngModel)]="business_sel_country">
                                <mat-option *ngFor="let con of countries |async" [value]="con.id"
                                  (click)="getBusinessStates()">
                                  {{ con.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> State <span class="text-danger">*</span></mat-label>
                              <mat-select matNativeControl [(ngModel)]="business_sel_state">
                                <mat-option *ngFor="let sta of business_states |async" (click)="getBusinessCities()"
                                  [value]="sta.id">
                                  {{ sta.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> City <span class="text-danger">*</span></mat-label>
                              <mat-select matNativeControl [(ngModel)]="customer_shipper.city_1">
                                <mat-option *ngFor="let cit of business_cities |async" (click)="getBusinessPincodes()"
                                  [value]="cit.id">
                                  {{ cit.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> Zip Code <span class="text-danger">*</span></mat-label>
                              <mat-select matNativeControl [(ngModel)]="customer_shipper.zip_code">
                                <mat-option *ngFor="let cit of business_pincodes |async" [value]="cit.pin_number">
                                  {{ cit.pin_number }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> Billing Branch <span class="text-danger">*</span></mat-label>
                              <mat-select matNativeControl [(ngModel)]="customer_shipper.office_location">
                                <mat-option *ngFor="let role of OfficesList" [value]="role.id">
                                  {{ role.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> GST No. <span class="text-danger">*</span></mat-label>
                              <input type="text" placeholder="GST" aria-label="Number" matInput #gst maxlength="15"
                                [(ngModel)]="customer_shipper.gst" [formControl]="gstFormControl">
                              <mat-hint class="text-danger fw-normal" *ngIf="gstFormControl.hasError('pattern')">Invalid
                                GST No.
                              </mat-hint>
                              <mat-hint align="end">{{ gst.value.length }}/15
                              </mat-hint>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> PAN No. <span class="text-danger">*</span></mat-label>
                              <input type="text" placeholder="PAN" aria-label="Number" matInput #pan maxlength="10"
                                [(ngModel)]="customer_shipper.pan" [formControl]="PanFormControl">
                              <mat-hint class="text-danger fw-normal" *ngIf="PanFormControl.hasError('pattern')">Invalid
                                PAN No.
                              </mat-hint>
                              <mat-hint align="end">{{ pan.value.length }}/10
                              </mat-hint>
                            </mat-form-field>
                          </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="h4"> Corporate Representative Details</div>
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field class="py-0" appearance="standard">
                              <mat-label> First Name <span class="text-danger">*</span></mat-label>
                              <input type="text" placeholder="First Name" aria-label="Number" matInput
                                [(ngModel)]="indiviudal_user.firstName">
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field class="py-0" appearance="standard">
                              <mat-label> Middle Name</mat-label>
                              <input type="text" placeholder="Middle Name" aria-label="Number" matInput
                                [(ngModel)]="indiviudal_user.middleName">
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field class="py-0" appearance="standard">
                              <mat-label> Last Name <span class="text-danger">*</span></mat-label>
                              <input type="text" placeholder="Last Name" aria-label="Number" matInput
                                [(ngModel)]="indiviudal_user.lastName">
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> E-mail <span class="text-danger">*</span></mat-label>
                              <input type="text" placeholder="Email" aria-label="Number" matInput
                                [(ngModel)]="indiviudal_user.email" (blur)="emailAppuser()"
                                [formControl]="emailFormControl">
                              <mat-hint class="text-danger fw-normal"
                                *ngIf="emailFormControl.hasError('pattern')">Invalid Email Address
                              </mat-hint>
                              <mat-hint class="text-danger fw-bold" *ngIf="emailExist==true">Email Exist</mat-hint>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> Phone No. <span class="text-danger">*</span></mat-label>
                              <input type="text" placeholder="Phone Number"
                                oninput="if(!this.value.match('^[0-9]*$'))this.value='';" aria-label="Number" matInput
                                #phone maxlength="10" [(ngModel)]="indiviudal_user.phone"
                                (blur)="phoneNoExistAppuser()">
                              <mat-hint class="text-danger fw-bold" *ngIf="phoneNoExist==true">Phone No.
                                Exist
                              </mat-hint>
                              <mat-hint align="end">{{ phone.value.length }}/10
                              </mat-hint>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> Whatsapp No.</mat-label>
                              <input type="text" placeholder="Whatsapp No." aria-label="Number" matInput
                                [(ngModel)]="indiviudal_user.whatsapp">
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field class="example-full-width" appearance="standard">
                              <mat-label> Address <span class="text-danger">*</span></mat-label>
                              <textarea matInput placeholder="Address"
                                [(ngModel)]="indiviudal_user.billingAddress.address"></textarea>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> Country <span class="text-danger">*</span></mat-label>
                              <mat-select matNativeControl [(ngModel)]="user_sel_country">
                                <mat-option *ngFor="let con of countries |async" [value]="con.id"
                                  (click)="getIndividaulStates()">
                                  {{ con.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> State <span class="text-danger">*</span></mat-label>
                              <mat-select matNativeControl [(ngModel)]="user_sel_state">
                                <mat-option *ngFor="let sta of user_states |async" [value]="sta.id"
                                  (click)="getIndividaulCities()">
                                  {{ sta.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> City <span class="text-danger">*</span></mat-label>
                              <mat-select matNativeControl [(ngModel)]="user_sel_city">
                                <mat-option *ngFor="let cit of user_cities |async" [value]="cit.id"
                                  (click)="getIndividualPincodes()">
                                  {{ cit.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> Zip Code</mat-label>
                              <mat-select matNativeControl
                                [(ngModel)]="indiviudal_user.billingAddress.pincode.pin_number">
                                <mat-option *ngFor="let cit of user_pincodes |async" [value]="cit.pinNumber">
                                  {{ cit.pinNumber }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="row">
                            <div class="col-md-4">
                              <mat-form-field class="py-0" appearance="standard">
                                <mat-label> Aadhaar Number</mat-label>
                                <input type="text" placeholder="Aadhaar Number"
                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="Number"
                                  matInput maxlength="12" #aadhaarnum1 [(ngModel)]="indiviudal_user.aadhaarNumber">
                                <mat-hint align="">{{ aadhaarnum1.value.length }}/12</mat-hint>
                              </mat-form-field>
                            </div>
                            <div class="col-md-4">
                              <mat-form-field appearance="standard">
                                <mat-label> Driving License</mat-label>
                                <input type="text" placeholder="Driving License" aria-label="text" matInput
                                  [(ngModel)]="indiviudal_user.drivingLicense">
                              </mat-form-field>
                            </div>
                            <div class="col-md-4">
                              <mat-form-field class="py-0" appearance="standard">
                                <mat-label> PAN Number</mat-label>
                                <input type="text" placeholder="PAN Number" aria-label="Number" matInput
                                  [(ngModel)]="indiviudal_user.panNumber">
                              </mat-form-field>
                            </div>
                            <div class="col-md-4">
                              <mat-form-field class="py-0" appearance="standard">
                                <mat-label> GST Number</mat-label>
                                <input type="text" placeholder="GST Number" aria-label="Number" matInput
                                  [(ngModel)]="indiviudal_user.gstNumber">
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ng-template #notbusinessuser>
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field class="py-0" appearance="standard">
                              <mat-label> First Name <span class="text-danger">*</span></mat-label>
                              <input type="text" placeholder="First Name" aria-label="Number" matInput
                                [(ngModel)]="indiviudal_user.firstName">
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field class="py-0" appearance="standard">
                              <mat-label> Middle Name</mat-label>
                              <input type="text" placeholder="Middle Name" aria-label="Number" matInput
                                [(ngModel)]="indiviudal_user.middleName">
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field class="py-0" appearance="standard">
                              <mat-label> Last Name <span class="text-danger">*</span></mat-label>
                              <input type="text" placeholder="Last Name" aria-label="Number" matInput
                                [(ngModel)]="indiviudal_user.lastName">
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> E-mail <span class="text-danger">*</span></mat-label>
                              <input type="text" placeholder="Email" aria-label="Number" matInput
                                [(ngModel)]="indiviudal_user.email" (blur)="emailAppuser()"
                                [formControl]="emailFormControl">
                              <mat-hint class="text-danger fw-normal"
                                *ngIf="emailFormControl.hasError('pattern')">Invalid Email Address
                              </mat-hint>
                              <mat-hint class="text-danger fw-bold" *ngIf="emailExist==true">Email Exist</mat-hint>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> Phone No. <span class="text-danger">*</span></mat-label>
                              <input type="text" placeholder="Phone Number"
                                oninput="if(!this.value.match('^[0-9]*$'))this.value='';" aria-label="Number" matInput
                                #phone maxlength="10" [(ngModel)]="indiviudal_user.phone"
                                (blur)="phoneNoExistAppuser()">
                              <mat-hint class="text-danger fw-bold" *ngIf="phoneNoExist==true">Phone
                                No. Exist
                              </mat-hint>
                              <mat-hint align="end">{{ phone.value.length }}/10
                              </mat-hint>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field class="example-full-width" appearance="standard">
                              <mat-label> Address <span class="text-danger">*</span></mat-label>
                              <textarea matInput placeholder="Address"
                                [(ngModel)]="indiviudal_user.billingAddress.address"></textarea>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> Country <span class="text-danger">*</span></mat-label>
                              <mat-select matNativeControl (blur)="getIndividaulStates()"
                                [(ngModel)]="user_sel_country">
                                <mat-option *ngFor="let con of countries |async" [value]="con.id">
                                  {{ con.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> State <span class="text-danger">*</span></mat-label>
                              <mat-select matNativeControl (blur)="getIndividaulCities()" [(ngModel)]="user_sel_state">
                                <mat-option *ngFor="let sta of user_states |async" [value]="sta.id">
                                  {{ sta.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> City <span class="text-danger">*</span></mat-label>
                              <mat-select matNativeControl [(ngModel)]="user_sel_city" (blur)="getIndividualPincodes()">
                                <mat-option *ngFor="let cit of user_cities |async" [value]="cit.id">
                                  {{ cit.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="standard">
                              <mat-label> Zip Code</mat-label>
                              <mat-select matNativeControl
                                [(ngModel)]="indiviudal_user.billingAddress.pincode.pin_number">
                                <mat-option *ngFor="let cit of user_pincodes |async" [value]="cit.pinNumber">
                                  {{ cit.pinNumber }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="row">
                            <div class="row">
                              <div class="col-md-4">
                                <mat-form-field class="py-0" appearance="standard">
                                  <mat-label> Aadhaar Number</mat-label>
                                  <input type="text" placeholder="Aadhaar Number"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="Number"
                                    matInput maxlength="12" #aadhaarnum1 [(ngModel)]="indiviudal_user.aadhaarNumber">
                                  <mat-hint align="">{{ aadhaarnum1.value.length }}/12</mat-hint>
                                </mat-form-field>
                              </div>
                              <div class="col-md-4">
                                <mat-form-field appearance="standard">
                                  <mat-label> Driving License</mat-label>
                                  <input type="text" placeholder="Driving License" aria-label="text" matInput
                                    [(ngModel)]="indiviudal_user.drivingLicense">
                                </mat-form-field>
                              </div>
                              <div class="col-md-4">
                                <mat-form-field class="py-0" appearance="standard">
                                  <mat-label> PAN Number</mat-label>
                                  <input type="text" placeholder="PAN Number" aria-label="Number" matInput
                                    [(ngModel)]="indiviudal_user.panNumber">
                                </mat-form-field>
                              </div>
                              <div class="col-md-4">
                                <mat-form-field class="py-0" appearance="standard">
                                  <mat-label> GST Number</mat-label>
                                  <input type="text" placeholder="GST Number" aria-label="Number" matInput
                                    [(ngModel)]="indiviudal_user.gstNumber">
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
                      <button *ngIf="customer_created==false" type="button" class="btn btn-primary"
                        (click)="createCustomer()">Create
                      </button>
                    </div>
                    <ngx-loading [show]="Representativeload"
                      [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                      [template]="loadingTemplate"></ngx-loading>
                  </ng-template>
                  <!--                  <button class="btn btn-success m-1" (click)="open(content5);createnewformreset()"-->
                  <!--                    *ngIf="!displayAdminandPDA"> Create New<i class="fa fa-fw">  </i>-->
                  <!--                  </button>-->
                  <div class="row d-flex float-end">
                    <p class="float-end text-danger fw-bold mt-2"
                      *ngIf="errorRepresentative==false && errorShipper ==true">
                      Select Corporate Representative Details</p>
                    <p class="float-end text-danger fw-bold fa-1x mt-2" *ngIf="!errorShipper">
                      Please Select Shipper </p>
                  </div>
                  <ng-template #createAddress let-c="close" let-d="dismiss">
                    <div class="modal-header">
                      <h4 class="modal-title">Create Address</h4>
                      <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                      </button>
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="d('Cross click');getAddressList(indiviudal_user.id);">
                      </button>
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="d('Cross click');getAddressList(consignment_details.senderIndividual);">
                      </button>
                    </div>
                    <div class="modal-body">
                      <app-address [user_id]="indiviudal_user.id"
                        [user_name]="indiviudal_user.firstName+' '+indiviudal_user.lastName">
                      </app-address>
                    </div>
                  </ng-template>
                </div>
                <div class="font-size-md fa-2x fw-bold" *ngIf="indiviudal_user.id && errorShipper">
                  <h6><b style="color: rgb(53, 68, 231);">{{ indiviudal_user.firstName }}
                      {{ indiviudal_user.lastName }}</b></h6>
                </div>
              </div>
              <br>
              <div class="row"></div>
              <div class="row">
                <div class="col-md-6" style="width: 700px">
                  <div class="row">
                    <div class="col-md-4">
                      <h3><strong>Shipper</strong></h3>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <br>
                  <div class="row">
                    <div class="col-md-6 mb-6">
                      <div *ngIf="indiviudal_user.customerType == 2; else notbusinessuser">
                        <div class="h4"> Corporate Customer Details</div>
                        <!-- <div class="row">
                              <div class="col-md-6 fw-bold"> Business ID : </div>
                              <div class="col-md-6" *ngIf="errorShipper && errorRepresentative"> {{customer_shipper.id}}
                              </div>
                            </div>  -->
                        <br>
                        <div class="row">
                          <div class="col-md-6 fw-bold"> Company Name :</div>
                          <div class="col-md-6" *ngIf="errorShipper && errorRepresentative">
                            {{ customer_shipper.firstName }}
                          </div>
                        </div>
                        <br>
                        <div class="row">
                          <div class="col-md-6 fw-bold"> E-mail :</div>
                          <div class="col md-6" *ngIf="errorShipper && errorRepresentative">
                            {{ customer_shipper.email_1 }}
                          </div>
                        </div>
                        <br>
                        <div class="row">
                          <div class="col-md-6 fw-bold"> Phone No. :</div>
                          <div class="col-md-6" *ngIf="errorShipper  && errorRepresentative">
                            {{ customer_shipper.phone_1 }}
                          </div>
                        </div>
                        <br>
                        <div class="row">
                          <div class="col-md-6 fw-bold"> Address :</div>
                          <div class="col-md-6" *ngIf="errorShipper && errorRepresentative">
                            {{ customer_shipper.address_1 }} , {{ customer_shipper.zip_code }}
                            <br />
                            <div *ngIf="customer_shipper.city_1!= null">
                              <div *ngIf="customer_shipper.city_1.id!= null">
                                {{ customer_shipper.city_1.name }},
                                {{ customer_shipper.city_1.state_id.name }},
                                {{ customer_shipper.city_1.state_id.country.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <br>
                        <div class="row">
                          <div class="col-md-6 fw-bold"> GST No :</div>
                          <div class="col-md-6" *ngIf="errorShipper && errorRepresentative">
                            {{ customer_shipper.gst }}
                          </div>
                        </div>
                        <!-- <br> <mat-divider> </mat-divider> <br> -->
                      </div>
                    </div>
                    <div class="col-md-6 mb-6">
                      <div *ngIf="indiviudal_user.customerType == 2; else notbusinessuser">
                        <div class="h4"> Corporate Representative Details</div>
                        <!-- <div class="row">
                              <div class="col-md-6 fw-bold"> Representative ID : </div>
                              <div class="col-md-6"> {{indiviudal_user.id}} </div>
                            </div>  -->
                        <br>
                        <div class="row">
                          <div class="col-md-6 fw-bold"> Name :</div>
                          <div class="col-md-6">
                            {{ indiviudal_user.firstName }} {{ indiviudal_user.middleName }}
                            {{ indiviudal_user.lastName }}
                          </div>
                        </div>
                        <br>
                        <div class="row">
                          <div class="col-md-6 fw-bold"> E-mail :</div>
                          <div class="col-md-6"> {{ indiviudal_user.email }}</div>
                        </div>
                        <br>
                        <div class="row">
                          <div class="col-md-6  fw-bold"> Phone No. :</div>
                          <div class="col-md-6"> {{ indiviudal_user.phone }}</div>
                        </div>
                        <br>
                        <div class="row">
                          <div class="col-md-6 fw-bold"> Whatsapp No. :</div>
                          <div class="col-md-6"> {{ indiviudal_user.whatsapp }}</div>
                        </div>
                        <br>
                        <div class="h4" style="display: flex; flex-direction: row; width: 700px">
                          <div> Choose an Address <span class="text-danger me-4">*</span></div>
                          <div>
                            <button class="btn btn-primary me-2" (click)="open(createAddress)">
                              Add Address<i class="fa fa-fw">
                              </i>
                            </button>
                            <button class="btn btn-secondary m-1" matTooltip="Refresh"
                              (click)="getAddressList(consignment_details.senderIndividual)"> Refresh
                              <i class="pe-7s-refresh-2 btn-icon-wrapper"> </i>
                            </button>
                          </div>
                        </div>
                        <div style="max-height: 200px; overflow-y: auto; overflow-x: hidden; width: 700px">
                          <div *ngIf="reprsentativeAddress!= null">
                            <div *ngFor="let add of reprsentativeAddress " class="flex-row ">
                              <div class="widget-content p-0">
                                <div class="border-bottom border-bottom-3 border-top row"
                                  [ngStyle]="{'background-color': (add.id==selectedAddress) ? '#abd8ff' : '#ffffff'}">
                                  <div class="col-md-4 fw-bold opacity-7"> {{ add.address }}</div>
                                  <div class="col-md-3 ">
                                    <div class="widget-heading text-dark opacity-7"> {{ add.pincodeId?.pinNumber }}
                                    </div>
                                    <div class="widget-subheading opacity-10"> {{ add.pincodeId?.cityId?.name }}</div>
                                  </div>
                                  <div class="col-md-3 ">
                                    <div class="widget-heading text-dark opacity-7">
                                      {{ add.pincodeId?.cityId?.state_id.name }}
                                    </div>
                                    <div class="widget-subheading opacity-10 ps-2">
                                      {{ add.pincodeId?.cityId?.state_id.country.name }}
                                    </div>
                                  </div>
                                  <div class="col-md-1">
                                    <div class="md-2 me-2 btn btn-primary" (click)="selectAddress(add.id)">
                                      Select
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--                        <div class="row">-->
                        <!--                          <div class="col-md-3 fw-bold">-->
                        <!--                            <div *ngIf="indiviudal_user.varification_id_type == 1"> AADHAR </div>-->
                        <!--                            <div *ngIf="indiviudal_user.varification_id_type == 2"> PAN </div>-->
                        <!--                          </div>-->
                        <!--                          <div class="col-md-3"> {{indiviudal_user.verification_id}} </div>-->
                        <!--                        </div>-->
                      </div>
                    </div>
                    <ng-template #notbusinessuser>
                      <div class="row">
                        <div class="h4"> Retail Customer Details<span class="text-danger">*</span></div>
                        <div class="col-md-3 fw-bold"> Customer ID</div>
                        <div class="col-md-9" *ngIf="errorShipper"> {{ indiviudal_user.id }}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 fw-bold"> Name</div>
                        <div class="col-md-9" *ngIf="errorShipper">
                          {{ indiviudal_user.firstName }} {{ indiviudal_user.middleName }}
                          {{ indiviudal_user.lastName }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 fw-bold"> E-mail</div>
                        <div class="col-md-6" *ngIf="errorShipper"> {{ indiviudal_user.email }}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-3  fw-bold"> Phone No.</div>
                        <div class="col-md-3" *ngIf="errorShipper"> {{ indiviudal_user.phone }}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 fw-bold"> Whatsapp No.</div>
                        <div class="col-md-3" *ngIf="errorShipper"> {{ indiviudal_user.whatsapp }}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 fw-bold"> Address</div>
                        <div class="col-md-9" *ngIf="errorShipper">
                          {{ indiviudal_user.billingAddress.address }}
                          , {{ indiviudal_user.billingAddress.pincode.pin_number }}
                          <br />
                          <!--                          <div *ngIf="indiviudal_user.city!= null">-->
                          <!--                            <div *ngIf="indiviudal_user.city.id!= null">-->
                          <!--                              {{indiviudal_user.city.name}},-->
                          <!--                              {{indiviudal_user.city.state_id.name}},-->
                          <!--                              {{indiviudal_user.city.state_id.country.name}}-->
                          <!--                            </div>-->
                          <!--                          </div>-->
                        </div>
                        <div class="h4"> Choose An Address <span class="text-danger me-4">*</span>
                          <button class="btn btn-primary m-1" (click)="open(createAddress);">
                            Add Address<i class="fa fa-fw">
                            </i>
                          </button>
                          <button class="btn btn-secondary m-1" matTooltip="Refresh"
                            (click)="getAddressList(indiviudal_user.id)"> Refresh
                            <i class="pe-7s-refresh-2 btn-icon-wrapper"> </i>
                          </button>
                        </div>
                        <div style="max-height: 200px; overflow: auto;">
                          <div *ngIf="reprsentativeAddress!= null">
                            <div *ngFor="let add of reprsentativeAddress " class="flex-row ">
                              <div class="widget-content p-0">
                                <div class="border-bottom border-bottom-3 border-top row"
                                  [ngStyle]="{'background-color': (add.id==selectedAddress) ? '#abd8ff' : '#ffffff'}">
                                  <div class="col-md-4 fw-bold opacity-7"> {{ add.address }}</div>
                                  <div class="col-md-3 ">
                                    <div class="widget-heading text-dark opacity-7"> {{ add.pincodeId?.pin_number }}
                                    </div>
                                    <div class="widget-subheading opacity-10"> {{ add.cityId?.name }}</div>
                                  </div>
                                  <div class="col-md-3 ">
                                    <div class="widget-heading text-dark opacity-7"> {{ add.cityId?.state_id.name }}
                                    </div>
                                    <div class="widget-subheading opacity-10 ps-2">
                                      {{ add.cityId?.state_id.country.name }}
                                    </div>
                                  </div>
                                  <div class="col-md-1">
                                    <div class="md-2 me-2 btn btn-primary" (click)="selectAddress(add.id)">
                                      Select
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--                      <div class="row">-->
                      <!--                        <div class="col-md-3 fw-bold">-->
                      <!--                          <div *ngIf="indiviudal_user.varification_id_type == 1"> AADHAR </div>-->
                      <!--                          <div *ngIf="indiviudal_user.varification_id_type == 2"> PAN </div>-->
                      <!--                        </div>-->
                      <!--                        <div class="col-md-3"> {{indiviudal_user.verification_id}} </div>-->
                      <!--                      </div>-->
                    </ng-template>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
            </div>
          </td>
          <td style="width: 35%; vertical-align: top;">
            <div class="card p-4" *ngIf="selectedAddress" style="margin-left: -100px; margin-right: 40px">
              <div class="card-header">
                <div class="card-header-title font-size-lg text-capitalize fw-normal">
                  <b style="color: blue;">UPLOAD OFFLINE BOOKINGS</b>
                </div>
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-md-10 center p-1">
                    <input class="form-control" type="file" (change)="getCorporateFiles($event)">
                  </div>
                </div>
                <div class="row" style="margin-top: 10px">
                  <div class="col-md-2 float-right m-auto">
                    <button class="btn btn-primary btn-lg" (click)="uploadCorporateDocument()">Upload</button>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
      <ngx-loading [show]="loadingSecound"
        [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
        [template]="loadingTemplate"></ngx-loading>
      <br>
      <!-- </mat-step>
      </mat-horizontal-stepper> -->
    </div>
  </some-element>
</container-element>