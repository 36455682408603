<ngx-loading [show]="isLoading"
             [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: '#0000ff', secondaryColour: '#00f', backdropBorderRadius: '3px'}"
             [template]="loadingTemplate"></ngx-loading>



<mat-card>

  <mat-card-title class="title-container">
    <div>
      <h3 class="title-text">Customer Contract</h3>
    </div>
    <div class="btn-actions-pane-right p-2">
      <button class="float-end" mat-raised-button color="primary" (click)="goToCreateNew()">Create New</button>
    </div>
  </mat-card-title>

<!--  <mat-card-title class="title-container">-->
<!--    <div class="left-section">-->
<!--      <button mat-icon-button>-->
<!--        <mat-icon (click)="goBack()">arrow_back</mat-icon>-->
<!--      </button>-->
<!--      <h4 class="title-text">CREATE CUSTOMER CONTRACT</h4>-->
<!--    </div>-->
<!--    <button mat-button color="primary" class="download-btn">-->
<!--      <mat-icon>download</mat-icon>-->
<!--      Download CSV-->
<!--    </button>-->
<!--  </mat-card-title>-->

  <mat-divider></mat-divider>
  <!--  <mat-form-field style="width: 30%">-->
  <!--    <mat-label>Filter</mat-label>-->
  <!--    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>-->
  <!--  </mat-form-field>-->

  <ng-container *ngIf="contractsTableDataSource?.data?.length > 0; else noRecordsMessage">

    <!--    <div class="mat-elevation-z8">-->
    <mat-table [dataSource]="contractsTableDataSource" style="width: 100%" matSort (matSortChange)="sortData($event)">

      <!-- S.No. Column -->
      <ng-container matColumnDef="S.No.">
        <mat-header-cell *matHeaderCellDef mat-sort-header> S.No.</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index"> {{ paginator?.pageIndex * paginator?.pageSize + i + 1 }}
        </mat-cell>
      </ng-container>

      <!-- Customer Name Column -->
      <ng-container matColumnDef="customerName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Customer</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.customer.firstName }}</mat-cell>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Description</mat-header-cell>
        <!-- <mat-cell *matCellDef="let element"> {{ element.description }}</mat-cell> -->
        <mat-cell *matCellDef="let element" [matTooltip]="element.description" matTooltipPosition="above">
          {{ element.description | slice:0:10 }}{{element.description?.length > 10 ? '...' : ''}}
        </mat-cell>
      </ng-container>


      <!-- Valid From Column -->
      <ng-container matColumnDef="validFrom">
        <mat-header-cell *matHeaderCellDef> Valid From</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.validFrom | date: 'dd/MM/yyyy' }}</mat-cell>
      </ng-container>

      <!-- Valid Upto Column -->
      <ng-container matColumnDef="validUpto">
        <mat-header-cell *matHeaderCellDef> Valid Upto</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.validUpto | date: 'dd/MM/yyyy' }}</mat-cell>
      </ng-container>

      <!-- Prices Column -->
      <ng-container matColumnDef="prices">
        <mat-header-cell *matHeaderCellDef> Prices </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-raised-button class="btn-shadow btn btn-success" (click)="goToRegionPrices(element)">Regions</button>&nbsp;&nbsp;&nbsp;
          <button mat-raised-button class="btn-shadow btn btn-primary" (click)="goToCityPrices(element)">Cities</button>
        </mat-cell>
      </ng-container>

      <!--      &lt;!&ndash; Actions Column &ndash;&gt;-->
      <!--      <ng-container matColumnDef="actions">-->
      <!--        <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>-->
      <!--        <mat-cell *matCellDef="let element; let i = index">-->
      <!--          <button mat-icon-button color="primary" aria-label="Example icon button with a heart icon"-->
      <!--                  (click)="viewAirlinePromotion(element.id)">-->
      <!--            <mat-icon>visibility</mat-icon>-->
      <!--          </button>-->
      <!--          <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon"-->
      <!--                  (click)="deleteAirlinePromotion(element.id, paginator.pageIndex * paginator.pageSize + i + 1)">-->
      <!--            <mat-icon>delete_outline</mat-icon>-->
      <!--          </button>-->
      <!--        </mat-cell>-->
      <!--      </ng-container>-->

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </mat-table>
    <mat-paginator [length]="paginatorDetails?.totalElements" [showFirstLastButtons]="true" [pageSize]="paginatorDetails?.pageSize" [pageIndex]="paginatorDetails?.pageNo"
                   [pageSizeOptions]="[5, 10, 20, 30]" (page)="pageEvent = $event; onPaginateChange($event)">
    </mat-paginator>

    <!--    </div>-->

  </ng-container>
  <ng-template #noRecordsMessage>
    <h1 class="text-center ">No Records Available..!</h1>
  </ng-template>


</mat-card>
