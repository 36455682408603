import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import Swal from 'sweetalert2';
import {API_URL} from '../../../../../Global_API_URL';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthService} from '../../../auth.service';


let USERID;

@Component({
  selector: 'app-tracking-view',
  templateUrl: './tracking-view.component.html',
  styleUrls: ['./tracking-view.component.sass'],
})
export class TrackingViewComponent implements OnInit {
  consignment_sts_update = {
    consignmentId: null,
    status: null,
    comment: '',
  }
  userDetails;
  notdisplaytocustomer = true;
  trackCustomerConsignment = false;
  senderIndividualId;

  constructor(private sanitizer: DomSanitizer, private authService: AuthService) {
    this.authService.getAuthToken();
  }

  ngOnInit(): void {
    USERID = JSON.parse(localStorage.getItem('userDetails')).id;
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name == 'Customer') {
      this.notdisplaytocustomer = false;
      this.trackCustomerConsignment = true;
      this.senderIndividualId = this.userDetails.id;
    }
  }

  consignment_id;
  manifestation;
  consignment_track;
  consignment_status
  POD;
  id;
  current_status_id;
  PrimaryWhite = '#fff';
  SecondaryGrey = '#ccc';
  PrimaryRed = '#d92550';
  SecondaryBlue = '#3f6ad8';
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = this.SecondaryBlue;
  public secondaryColour = this.SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };
  origin
  destination

  ConNoAvailable: Boolean = true;
  async getTrack() {
    const TOKEN = this.authService.getAuthToken();
    if (this.consignment_id == null || this.consignment_id == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Consignment Number',
        timer: 5000,

      });
    } else if (this.consignment_id.length < 11) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'The Consignment Number should be 11 digits',
        timer: 5000,

      });
    }
    let apiUrl = API_URL + '/tracking/consignment/';
    if (!this.notdisplaytocustomer) {
      apiUrl += this.senderIndividualId + '/';
    }
    apiUrl += this.consignment_id;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      });
      if (response.ok) {
        this.ConNoAvailable = true;
        const json = await response.json();
        this.consignment_track = json.data[0].trackingId.trackingStatusComments;
        this.current_status_id = json.data[0].trackingId.status.id;
        this.id = json.data[0].id;
        const updatedStatus = json.statuses.filter((eachStatus) => {
          const selectedStatus = this.consignment_track.map((each) => each.status.id);
          let highOrder = 0;
          this.consignment_track.forEach((each) => {
            highOrder = highOrder < each.status.order ? each.status.order : highOrder;
          });
          if (eachStatus.order < highOrder && eachStatus.id != 19 && eachStatus.id != 35 && eachStatus.id != 18 && eachStatus.id != 36) {
            return false;
          }
          // if (eachStatus.id === 36) {
          //   return false;
          // }
          if (selectedStatus.includes(eachStatus.id) && eachStatus.id != 19 && eachStatus.id != 35 && eachStatus.id != 18 && eachStatus.id != 36) {
            return false;
          } else {
            return true;
          }
        });
        this.consignment_status = updatedStatus;
        this.consignment_sts_update.status = null;
        this.consignment_sts_update.comment = '';
        // console.log(this.consignment_track)
        this.consignment_track.sort((a, b) => a.id - b.id);
        // console.log(this.consignment_track)

        if (json.data[0].series_ref.id != 13 && json.data[0].series_ref.id != 14 && json.data[0].series_ref.id != 15 && json.data[0].series_ref.id != 16) {
          this.origin = json.data[0].originLocation.city.name;
          this.destination = json.data[0].dropLocation.city.name;
        }

        this.getpod(json.data[0].id);
      } else {
        this.ConNoAvailable = false;
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Please Enter A valid Consignment Number',
          timer: 5000,

        });
      }
    } catch (error) {
      console.error('Error while fetching tracking details: ', error);
    }
  }

  getTime(date) {
    if (date) {
      const date2 = new Date(date);
      return (
        [
          date2.getFullYear(

          ),
          this.padTo2Digits(date2.getMonth() + 1),
          this.padTo2Digits(date2.getDate()),
        ].join('-') +
        ' ' +
        [
          this.padTo2Digits(date2.getHours()),
          this.padTo2Digits(date2.getMinutes()),
        ].join(':')
      );
    } else {
      return '-';
    }
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  async update_con_status() {
    const TOKEN = this.authService.getAuthToken();
    if (this.consignment_sts_update.status == null) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Select Any Status',
        showConfirmButton: false,
        timer: 5000,
      });
    } else {
      this.loading = true;
      const sts_update = {
        consignmentId: this.consignment_id,
        status: this.consignment_sts_update.status,
        comment: this.consignment_sts_update.comment,
      };
      await fetch(API_URL + '/trackByConsignment',
          {
            method: 'POST',
            body: JSON.stringify(sts_update),
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + TOKEN,
            },
          })
          .then((response) => response.json())
          .then((json) => {
            this.getTrack();
            this.getpod(json.data[0].id);
            this.loading = false;
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Status updated successfully',
              showConfirmButton: false,
              timer: 5000,
            });
          });
    }
  }

  async getpod(id) {
    const TOKEN = this.authService.getAuthToken();
    const con = parseInt(id);
    await fetch(API_URL + '/consignment/getPod/' + con, {
      method: 'GET', headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'responseType': 'blob',
      },
    })
        .then((response) => response.blob())
        .then((result) => {
          const objectURL = URL.createObjectURL(result);
          this.POD = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        // this.POD = this.POD.changingThisBreaksApplicationSecurity
        })
        .catch((error) => console.error('error', error));
  }

  shouldShowOption(cons_st: any): boolean {
    if (this.userDetails.userTypes[0].name == 'Pickup Delivery Agent' || this.userDetails.userTypes[0].name == 'Processing Agent') {
      return !(cons_st?.name == 'Cancelled' || cons_st?.name == 'Shipment Abandoned');
    } else {
      return true;
    }
  }
}
