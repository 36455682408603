import {ConsignmentTypesModel} from '../../consignmentTypes/models/consignment-types-model';
import {Time} from '@angular/common';

export class ProductTypeModel {
  active: boolean;
  id: string;
  name: string;
  series_no: string;
  consignment_types: ConsignmentTypesModel[];
  is_active : boolean;
  created_at: Time;
  updated_at: Time;
}
